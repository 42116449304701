import React from "react";
import "./Navbar.css";
import { Link, NavLink } from "react-router-dom";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import PersonIcon from "@material-ui/icons/Person";
import TKonnect1 from "../../assets/logo/TKonnect1.png";
import VendorIcon from "../../assets/Icons/VendorIcon.svg"
// import HomeIcon from "@material-ui/icons/HomeIcon";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { Profile, Vendors } from "./MoreT";
import ProfileIcon from "../../assets/Icons/ProfileIcon.svg"
import { Tooltip } from "@mui/material";

const CommonTravelAgent = () => {
    const logout = () => {
      localStorage.clear();
      sessionStorage.clear();
    };
    return (
      <div className="navbars" style={{ height: '8%' }}>
        <div className="logo">
          <img src={TKonnect1} alt="logo" style={{ width: '45%' }} />
        </div>
        <div className="subHeader">
            <div className="rightMenu">
            <Link to="/calenderView" className="more">
                <div className="item">
                <img src={VendorIcon} width="20px" height="20px" />
                <span className="caption">Property</span>
                </div>
            </Link>
            </div>
            <div className="rightMenu">            
                <Link to="/" onClick={logout} className="more">
                    <div className="item">
                    <img src={ProfileIcon} width="20px" height="20px" />
                    <span className="caption">Logout</span>
                    </div>
                </Link>
            </div>
        </div>
      </div>
    );
};

export default CommonTravelAgent;