import React, { useState, useEffect } from "react"
import { Grid, Paper, Stack } from "@mui/material"
import { twnButtonStyles } from "../../utils/townoStyle"
import { formatter } from "../../utils/formatNumber"
import _ from "lodash"
import Api, { UpdateInterval } from "../../Service/Api"
import "./agentDashboard.css"
import { currencyFormatter } from "../../B2C_Konnect/utility/currencyFormatter"

// COUNT BOX FILTER STYLE
const FilterStyle = {
  backgroundColor: "#fff",
  width: "100%",
  textAlign: "center",
  fontSize: "1em",
  cursor: "pointer",
  border: "1px solid #e1e1e1",
}
const FilterSelectedStyle = {
  backgroundColor: "#f46d25",
  color: "#fff",
  fontWeight: "bold",
  width: "100%",
  textAlign: "center",
  fontSize: "1em",
  cursor: "pointer",
}

export default function AgentCountBox() {
  const Auth = localStorage.getItem("auth")
  const uniqueId = localStorage.getItem("unique_id")
  const hasAdmin = localStorage.getItem("role")
  const notAdmin = _.indexOf(["Agent Admin"], hasAdmin, 0) === -1

  // COUNT BOX VARIABLES
  const [countBoxFilter, setCountBoxFilter] = useState("week")
  const [countBoxData, setCountBoxData] = useState({
    totalRevenue: "",
    totalProfit: "",
    newLeads: "",
    lostLeads: "",
    closedLeads: "",
  })

  // Api for CountBox starts
  const countBoxApiFun = () => {
    var url = `TravelAgentDashboardCount/${uniqueId}/${countBoxFilter}`
    if (notAdmin) {
      url = `TravelAgentStaffDashboardCountNew/${uniqueId}/${countBoxFilter}/${Auth}`
    }
    Api.get(url).then((res) => {
      setCountBoxData(res.data)
    })
  }

  useEffect(() => {
    let interval = setInterval(() => {
      console.log("This will run every 1 second!")
      countBoxApiFun()
    }, UpdateInterval)
    return () => clearInterval(interval)
  }, [countBoxFilter])

  useEffect(() => {
    console.log("This will run every counter box filter change!")
    countBoxApiFun()
  }, [countBoxFilter])
  // Api for CountBox ends

  return (
    <Grid container spacing={2} style={{ maginBottom: "3px" }}>
      <Grid item sm={11} md={11}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Paper style={twnButtonStyles.paperStyle}>
            <span style={twnButtonStyles.labelStyle}>Total Revenue</span>
            <br />
            <span style={twnButtonStyles.valueStyle}>
              {currencyFormatter(countBoxData?.totalRevenue)}
            </span>
          </Paper>
          <Paper style={twnButtonStyles.paperStyle}>
            <span style={twnButtonStyles.labelStyle}>Total Profit</span>
            <br />
            <span style={twnButtonStyles.valueStyle}>
              {currencyFormatter(countBoxData?.totalProfit)}
            </span>
          </Paper>
          <Paper style={twnButtonStyles.paperStyle}>
            <span style={twnButtonStyles.labelStyle}>New Leads</span>
            <br />
            <span style={twnButtonStyles.valueStyle}>
              {countBoxData?.newLeads}
            </span>
          </Paper>
          <Paper style={twnButtonStyles.paperStyle}>
            <span style={twnButtonStyles.labelStyle}>Lost Leads</span>
            <br />
            <span style={twnButtonStyles.valueStyle}>
              {countBoxData?.lostLeads}
            </span>
          </Paper>
          <Paper style={twnButtonStyles.paperStyle}>
            <span style={twnButtonStyles.labelStyle}>Closed Leads</span>
            <br />
            <span style={twnButtonStyles.valueStyle}>
              {countBoxData?.closedLeads}
            </span>
          </Paper>
        </Stack>
      </Grid>
      <Grid item sm={1} md={1}>
        <Stack spacing={0.75} justifyContent="center" alignItems="center">
          <Paper
            onClick={() => setCountBoxFilter("today")}
            style={
              countBoxFilter === "today" ? FilterSelectedStyle : FilterStyle
            }
          >
            Today
          </Paper>
          <Paper
            onClick={() => setCountBoxFilter("week")}
            style={
              countBoxFilter === "week" ? FilterSelectedStyle : FilterStyle
            }
          >
            Week
          </Paper>
          <Paper
            onClick={() => setCountBoxFilter("month")}
            style={
              countBoxFilter === "month" ? FilterSelectedStyle : FilterStyle
            }
          >
            Month
          </Paper>
        </Stack>
      </Grid>
    </Grid>
  )
}
