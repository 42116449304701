import DateFnsUtils from "@date-io/date-fns"
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { Grid } from "@mui/material"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { TimePicker } from "formik-material-ui-pickers"
import moment from "moment"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import * as Yup from "yup"
import Api from "../../../Service/Api"
import {
  propertyBasicData,
  propertyContactCreate,
  propertyContactData,
  propertyContactUpdate,
  propertyDetialCreate,
  propertyDetialUpdate,
  propertyTypeList,
  timezoneList,
} from "../../../redux/actions/propertyAction"

const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontSize: "17px",
    fontWeight: "bold",
    paddingBottom: "7px",
  },
  innerSectionStyles: {
    border: "1px solid #C4C4C4",
    padding: "10px",
    borderRadius: "5px",
  },
  outerSectionStyles: {
    marginTop: "19px",
  },
  labelProps: {
    color: "#000000",
  },
  inputProps: {
    height: "40px",
  },
  fieldStyle: {
    width: "100%",
  },
  menuItemStyles: {
    height: "300px",
    fontSize: "14px",
  },
  selectStyle: {
    height: "40px",
    width: "100%",
    "& .MuiSelect-icon": {
      color: "#f46d25",
    },
  },
  selectLabelProps: {
    backgroundColor: "white",
    color: "#000000",
    padding: "0px 5px 5px 5px",
    borderRadius: "5px",
  },
  checkboxStyling: {
    "& .MuiSvgIcon-root": {
      color: "#f46d25",
    },
  },
  menuStyling: {
    fontSize: "14px",
  },
  error: {
    color: "red",
  },
}))

const popertyDetailsCheckBoxes = [
  { name: "checkIn24", label: "24 Hour Check-in" },
  { name: "couplefriendly", label: "Couple Friendly" },
  { name: "petFriendly", label: "Pet Friendly" },
  { name: "beachnearby", label: "Beach Nearby" },
  { name: "swimingpool", label: "Swimming Pool" },
  { name: "conferencehall", label: "Banquet Hall" },
  { name: "kidsplayarea", label: "Kids Play Area" },
  { name: "designatorforwedding", label: "Wedding Destination" },
  { name: "isspa", label: "Spa" },
]

const propertyClassicDropdown = [
  { label: "Towno Hotel Network", value: "townoHotelNetwork" },
  { label: "Towno BnB Stays", value: "townoBnbStays" },
  { label: "Towno Affliate Hotel Network", value: "townoAffliateHotelNetwork" },
]

const PropertyDetails = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const unique_id = localStorage.getItem("unique_id")
  const propertyTypeLists = useSelector(
    ({ propertyTypeList }) => propertyTypeList.propertyTypeLists
  )
  const timeZoneLists = useSelector(
    ({ timeZoneList }) => timeZoneList.timeZoneLists
  )

  const [accountManagerList, setAccountManagerList] = useState(() => [])

  const fetchManagerList = async () => {
    const { data } = await Api.get(`leadassignfor/${unique_id}`)
    console.log(data)
    setAccountManagerList(data)
  }

  useEffect(() => {
    dispatch(propertyTypeList())
    dispatch(timezoneList())
    fetchManagerList()
  }, [])

  const { propertyId } = useParams()
  const propertyBasicDatas = useSelector(
    (state) => state.propertyBasicData.propertyBasicDatas
  )
  const propertyContactDatas = useSelector(
    (state) => state.propertyContactData.propertyContactDatas
  )

  const fetchPropertyBasicAndContactValue = () => {
    dispatch(propertyBasicData(propertyId))
    dispatch(propertyContactData(propertyId))
  }

  useEffect(() => {
    if (propertyId) {
      fetchPropertyBasicAndContactValue()
      return () => fetchPropertyBasicAndContactValue()
    }
  }, [propertyId])
  const [statusCode, setStatusCode] = useState()

  const fetchContactStatusData = async () => {
    const res = await Api.get(`propertybasiccontactvalue/${propertyId}`)
    console.log(res)

    setStatusCode(res)
  }

  useEffect(() => {
    if (propertyId) {
      fetchContactStatusData()
    }
  }, [propertyId])

  const initialValues = {
    id: "",
    propertyName: "",
    displayName: "",
    description: "",
    propertyType: "",
    starRating: "",
    chainName: "",
    builtYear: "",
    noofRestaurant: "",
    noofRooms: "",
    noofFloors: "",
    checkinTime: null,
    checkoutTime: null,
    timezone: "Asia/Calcutta, GMT+05:30",
    propertyAddress: "",
    streetAddress: "",
    country: "",
    state: "",
    city: "",
    locality: "",
    zipcode: "",
    landmark: "",
    nearestrailway: "",
    nearestbusstop: "",
    nearestairport: "",
    accountManager: "",
    propertyClassic: "townoHotelNetwork",
    checkIn24: false,
    couplefriendly: false,
    petFriendly: false,
    beachnearby: false,
    swimingpool: false,
    conferencehall: false,
    kidsplayarea: false,
    designatorforwedding: false,
    isspa: false,
  }

  const contactInitialValue = {
    hotelPhone: "",
    hotelMobile: "",
    hotelEmail: "",
    propertyCode: "",
    phoneList: "",
    emailList: "",
    websiteList: "",
    ccEmailList: "-",
    fromEmail: "-",
    image: "",
  }

  const validationSchema = Yup.object({
    propertyName: Yup.string().required("Property Name Required"),
    displayName: Yup.string().required("DisplayName Required").nullable(),
    propertyType: Yup.string().required("PropertyType Required").nullable(),
    starRating: Yup.number().required("StarRating Required").nullable(),
    noofRestaurant: Yup.string()
      .required("No of Restaurant Required")
      .nullable(),
    noofRooms: Yup.string().required("No of Rooms Required").nullable(),
    noofFloors: Yup.string().required("No of Floors Required").nullable(),
    propertyAddress: Yup.string()
      .required("PropertyAddress Required")
      .nullable(),
    streetAddress: Yup.string().required("StreetAddress Required").nullable(),
    country: Yup.string().required("Country Required").nullable(),
    state: Yup.string().required("State Required").nullable(),
    city: Yup.string().required("City Required").nullable(),
    locality: Yup.string().required("Locality Required").nullable(),
    zipcode: Yup.string().required("Pincode Required").nullable(),
  })

  const contactFormValidationSchema = Yup.object({
    hotelPhone: Yup.number("Invalid Phone Number")
      .required("Phone Number is Required")
      .nullable(),
    hotelMobile: Yup.string()
      .required("Mobile Number is required")
      .length(10, "Enter Valid Phone Number")
      .nullable(),
    hotelEmail: Yup.string()
      .email("Enter correct Format")
      .strict()
      .trim()
      .required("Email is Required")
      .nullable(),
  })

  let [propertyNameError, setPropertyNameError] = useState(false)
  const [propertyNameValid, setPropertyNameValid] = useState(false)
  const validCheck = async (values) => {
    if (values !== "" && values !== undefined && values !== null) {
      const valid = { propertyName: values, id: propertyBasicDatas.id }
      Api.post(
        "propertybasicpropertydetailsallcheck/" +
          localStorage.getItem("unique_id"),
        valid
      ).then((res) => {
        // eslint-disable-next-line eqeqeq
        if (res.data == "Invalid") {
          setPropertyNameError(true)
        } else {
          setPropertyNameError(false)
        }
      })
    }
    const nameField = { propertyName: values }
    const isValid = await validationSchema.isValid(nameField)
    setPropertyNameValid(isValid)
  }

  const validatePropertyCode = async (value) => {
    let errorMessage
    const codeExist = await Api.get(
      `checkPropertyCodeExistInEdit/${propertyContactDatas.id}/${
        value || propertyContactDatas.propertyCode
      }`
    )
    if (
      value !== "" ||
      value !== null ||
      propertyContactDatas.propertyCode === value
    ) {
      if (propertyContactDatas.propertyCode && codeExist.data === true) {
        return
      }
    }

    if (!value || propertyContactDatas.propertyCode === null) {
      errorMessage = codeExist
        ? "This code is already taken."
        : "Property ID is required"
    } else {
      try {
        const response = await Api.get(`/checkPropertyCodeExist/${value}`)
        if (response.data === false) {
          errorMessage = "This code is already taken."
        }
      } catch (error) {
        errorMessage = "Error occurred while validating property id"
      }
    }

    return errorMessage
  }

  const [loading, setLoading] = useState(() => false)
  const generateLoginCreds = async () => {
    setLoading(true)
    try {
      const { data } = await Api.get(
        `sendLoginCredsThroughEmail/${propertyContactDatas.propertyId}`
      )
      console.log(data)
      setLoading(false)
      toast.success("Login Details sent to registered mail id")
    } catch (error) {
      toast.error(error)
      setLoading(false)
    }
  }

  let createdBy = localStorage.getItem("auth")

  const create = {
    uniqueId: unique_id,
    createdBy: createdBy,
    propertyId: propertyId,
  }

  function createProperty(fields) {
    setLoading(true)
    fields.checkinTime = moment(fields.checkinTime).format("YYYY-MM-DD hh:mm A")
    fields.checkoutTime = moment(fields.checkoutTime).format(
      "YYYY-MM-DD hh:mm A"
    )
    console.log("hello")

    let createData = { ...fields, ...create }
    dispatch(propertyDetialCreate(createData, history))

    setLoading(false)
  }

  function updateProperty(fields) {
    setLoading(true)

    fields.updateBy = createdBy
    fields.checkinTime = moment(fields.checkinTime).format("YYYY-MM-DD hh:mm A")
    fields.checkoutTime = moment(fields.checkoutTime).format(
      "YYYY-MM-DD hh:mm A"
    )
    dispatch(propertyDetialUpdate(fields))
    console.log(fields)
    setLoading(false)
  }

  const onSubmitBasicPropertyDetails = (fields, { setStatus }) => {
    const id = propertyBasicDatas.id
    setStatus()
    if (propertyId) {
      updateProperty(fields)
    } else {
      createProperty(fields)
      console.log("new")
    }
  }

  function onSubmitContactData(fields, { setStatus }) {
    setLoading(true)
    console.log(fields)
    const id = propertyContactDatas.id
    setStatus()
    if (id) {
      updateContact(fields)
    } else {
      createContact(fields)
    }
  }

  function createContact(fields) {
    console.log(propertyId)
    console.log(fields)
    const formData = new FormData()
    formData.append("uniqueId", unique_id)
    formData.append("createdBy", createdBy)
    formData.append("propertyId", propertyId)

    // Loop through the fields and append them to the formData
    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        formData.append(key, fields[key])
      }
    }

    console.log(formData)
    dispatch(propertyContactCreate(formData))
    setLoading(false)
  }

  function updateContact(fields) {
    fields.updateBy = createdBy
    console.log(fields)
    const formData = new FormData()

    // Loop through the fields and append them to the formData
    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        formData.append(key, fields[key])
      }
    }
    console.log(formData)
    dispatch(propertyContactUpdate(formData))
    setLoading(false)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Formik
        initialValues={propertyId ? propertyBasicDatas : initialValues}
        onSubmit={onSubmitBasicPropertyDetails}
        enableReinitialize
        validateOnMount
        validationSchema={validationSchema}
      >
        {({ values, isValid, setFieldValue }) => (
          <Form autoComplete="off">
            <section className={classes.outerSectionStyles}>
              <header className={classes.sectionHeader}>Basic Details</header>
              <section className={classes.innerSectionStyles}>
                <Grid container spacing={2}>
                  <Grid item container spacing={2}>
                    <Grid item xs={3}>
                      <Field
                        name="propertyName"
                        as={TextField}
                        type="text"
                        value={values.propertyName}
                        onBlur={() => validCheck(values.propertyName)}
                        variant="outlined"
                        label="Property Name"
                        InputProps={{ className: classes.inputProps }}
                        autoFocus
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labelProps,
                        }}
                        className={classes.fieldStyle}
                        required
                      />
                      {propertyNameValid && (
                        <span className={classes.error}>
                          Property Name Required
                        </span>
                      )}
                      {propertyNameError && (
                        <span className={classes.error} id="errormessage">
                          Property Name Already Exists!
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="displayName"
                        as={TextField}
                        type="text"
                        value={values.displayName}
                        variant="outlined"
                        label="Display Name"
                        InputProps={{ className: classes.inputProps }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labelProps,
                        }}
                        className={classes.fieldStyle}
                        required
                      />
                      <ErrorMessage name="displayName">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="description"
                        as={TextField}
                        type="text"
                        value={values.description}
                        variant="outlined"
                        label="Description"
                        InputProps={{ className: classes.inputProps }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labelProps,
                        }}
                        className={classes.fieldStyle}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        as={FormControl}
                        variant="outlined"
                        style={{ width: "100%" }}
                        required
                      >
                        <InputLabel className={classes.selectLabelProps} shrink>
                          Property Type
                        </InputLabel>
                        <Field
                          as={Select}
                          name="propertyType"
                          value={values.propertyType}
                          className={classes.selectStyle}
                          MenuProps={{
                            className: classes.menuItemStyles,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {propertyTypeLists.map((property) => (
                            <MenuItem
                              className={classes.menuStyling}
                              value={property}
                            >
                              {property}
                            </MenuItem>
                          ))}
                        </Field>
                      </Field>
                      <ErrorMessage name="propertyType">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        as={FormControl}
                        variant="outlined"
                        style={{ width: "100%" }}
                        required
                      >
                        <InputLabel className={classes.selectLabelProps} shrink>
                          Star Rating
                        </InputLabel>
                        <Field
                          as={Select}
                          name="starRating"
                          className={classes.selectStyle}
                          value={values.starRating}
                          MenuProps={{
                            className: classes.menuItemStyles,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {Array.from({ length: 5 }, (_, i) => (
                            <MenuItem
                              className={classes.menuStyling}
                              value={i + 1}
                            >
                              {i + 1}
                            </MenuItem>
                          ))}
                        </Field>
                      </Field>
                      <ErrorMessage name="starRating">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="chainName"
                        as={TextField}
                        type="text"
                        value={values.chainName}
                        variant="outlined"
                        label="Chain Name"
                        InputProps={{ className: classes.inputProps }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labelProps,
                        }}
                        className={classes.fieldStyle}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="builtYear"
                        as={TextField}
                        type="number"
                        value={values.builtYear}
                        variant="outlined"
                        label="Build Year"
                        InputProps={{ className: classes.inputProps }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labelProps,
                        }}
                        className={classes.fieldStyle}
                        onWheel={(e) => e.target.blur()}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="noofRestaurant"
                        as={TextField}
                        type="number"
                        value={values.noofRestaurant}
                        variant="outlined"
                        label="No of Restaurant"
                        InputProps={{ className: classes.inputProps }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labelProps,
                        }}
                        className={classes.fieldStyle}
                        onWheel={(e) => e.target.blur()}
                        required
                      />
                      <ErrorMessage name="noofRestaurant">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="noofRooms"
                        as={TextField}
                        type="number"
                        value={values.noofRooms}
                        variant="outlined"
                        label="No of Rooms"
                        InputProps={{ className: classes.inputProps }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labelProps,
                        }}
                        className={classes.fieldStyle}
                        onWheel={(e) => e.target.blur()}
                        required
                      />
                      <ErrorMessage name="noofRooms">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="noofFloors"
                        as={TextField}
                        type="number"
                        value={values.noofFloors}
                        variant="outlined"
                        label="No of Floors"
                        InputProps={{ className: classes.inputProps }}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labelProps,
                        }}
                        className={classes.fieldStyle}
                        onWheel={(e) => e.target.blur()}
                        required
                      />
                      <ErrorMessage name="noofFloors">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={TimePicker}
                        label="Check-In Time"
                        name="checkinTime"
                        value={values.checkinTime || null}
                        inputVariant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labelProps,
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={TimePicker}
                        label="Check-Out Time"
                        name="checkoutTime"
                        inputVariant="outlined"
                        value={values.checkoutTime || null}
                        size="small"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labelProps,
                        }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        as={FormControl}
                        variant="outlined"
                        style={{ width: "100%" }}
                      >
                        <InputLabel className={classes.selectLabelProps} shrink>
                          Time Zone
                        </InputLabel>
                        <Field
                          as={Select}
                          name="timezone"
                          className={classes.selectStyle}
                          value={values.timezone}
                          MenuProps={{
                            className: classes.menuItemStyles,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          {timeZoneLists.map((timeZone) => (
                            <MenuItem
                              className={classes.menuStyling}
                              value={timeZone}
                            >
                              {timeZone}
                            </MenuItem>
                          ))}
                        </Field>
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        as={FormControl}
                        variant="outlined"
                        style={{ width: "100%" }}
                      >
                        <InputLabel className={classes.selectLabelProps} shrink>
                          Account Manager
                        </InputLabel>
                        <Field
                          as={Select}
                          name="accountManager"
                          className={classes.selectStyle}
                          MenuProps={{
                            className: classes.menuItemStyles,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                          value={values.accountManager}
                        >
                          {accountManagerList.map(({ name, username }) => (
                            <Field
                              as={MenuItem}
                              className={classes.menuStyling}
                              value={name}
                            >
                              {username}
                            </Field>
                          ))}
                        </Field>
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="propertyClassic"
                        as={Autocomplete}
                        value={propertyClassicDropdown.find(
                          (option) => option.value == values.propertyClassic
                        )}
                        options={propertyClassicDropdown}
                        getOptionLabel={(option) => option.label}
                        onChange={(e, option) => {
                          console.log(option.value)
                          setFieldValue("propertyClassic", option.value)
                        }}
                        size="small"
                        renderInput={(params) => (
                          <Field
                            as={TextField}
                            label="Property Category"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={2}>
                    {popertyDetailsCheckBoxes.map(({ name, label }) => (
                      <Grid item xs={2}>
                        <Field
                          as={FormControlLabel}
                          control={
                            <Field
                              as={Checkbox}
                              name={name}
                              className={classes.checkboxStyling}
                              checked={values[name]}
                            />
                          }
                          label={label}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </section>
            </section>
            <section className={classes.outerSectionStyles}>
              <header className={classes.sectionHeader}>Address</header>
              <section className={classes.innerSectionStyles}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Field
                      name="propertyAddress"
                      as={TextField}
                      type="text"
                      value={values.propertyAddress}
                      variant="outlined"
                      label="Property Address"
                      InputProps={{ className: classes.inputProps }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                      required
                    />
                    <ErrorMessage name="propertyAddress">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="streetAddress"
                      as={TextField}
                      type="text"
                      value={values.streetAddress}
                      variant="outlined"
                      label="Street Address"
                      InputProps={{ className: classes.inputProps }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                      required
                    />
                    <ErrorMessage name="streetAddress">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="country"
                      as={TextField}
                      type="text"
                      value={values.country}
                      variant="outlined"
                      label="Country"
                      InputProps={{ className: classes.inputProps }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                      required
                    />
                    <ErrorMessage name="country">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="state"
                      as={TextField}
                      type="text"
                      value={values.state}
                      variant="outlined"
                      label="State"
                      InputProps={{ className: classes.inputProps }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                      required
                    />
                    <ErrorMessage name="state">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="city"
                      as={TextField}
                      type="text"
                      value={values.city}
                      variant="outlined"
                      label="City"
                      InputProps={{ className: classes.inputProps }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                      required
                    />
                    <ErrorMessage name="city">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="locality"
                      as={TextField}
                      type="text"
                      value={values.locality}
                      variant="outlined"
                      label="Locality"
                      InputProps={{ className: classes.inputProps }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                      required
                    />
                    <ErrorMessage name="locality">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="zipcode"
                      as={TextField}
                      type="number"
                      value={values.zipcode}
                      variant="outlined"
                      label="Pincode"
                      InputProps={{ className: classes.inputProps }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                      required
                      onWheel={(e) => e.target.scroll()}
                    />
                    <ErrorMessage name="zipcode">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>

                  <Grid item xs={3}>
                    <Field
                      name="landmark"
                      as={TextField}
                      type="text"
                      value={values.landmark}
                      variant="outlined"
                      label="Landmark"
                      InputProps={{ className: classes.inputProps }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="nearestrailway"
                      as={TextField}
                      type="text"
                      value={values.nearestrailway}
                      variant="outlined"
                      label="Nearest Railway Stn."
                      InputProps={{ className: classes.inputProps }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="nearestbusstop"
                      as={TextField}
                      type="text"
                      value={values.nearestbusstop}
                      variant="outlined"
                      label="Nearest Bus Stop"
                      InputProps={{ className: classes.inputProps }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="nearestairport"
                      as={TextField}
                      type="text"
                      value={values.nearestairport}
                      variant="outlined"
                      label="Nearest Airport"
                      InputProps={{ className: classes.inputProps }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                    />
                  </Grid>
                </Grid>
              </section>
            </section>

            <center>
              <Button style={{ height: "30px", margin: "10px" }} type="submit">
                Save
              </Button>
            </center>
          </Form>
        )}
      </Formik>
      <Formik
        initialValues={propertyContactDatas || contactInitialValue}
        enableReinitialize
        validateOnMount
        validationSchema={contactFormValidationSchema}
        onSubmit={onSubmitContactData}
      >
        {({ isValid, values, setFieldValue, isSubmitting }) => (
          <Form autoComplete="off">
            <section className={classes.outerSectionStyles}>
              <header className={classes.sectionHeader}>Contact Details</header>
              <section className={classes.innerSectionStyles}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Field
                      name="hotelPhone"
                      as={TextField}
                      type="number"
                      value={values.hotelPhone}
                      imputMode="numeric"
                      variant="outlined"
                      label="Hotel Phone"
                      InputProps={{ className: classes.inputProps }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                      onWheel={(e) => e.target.blur()}
                      required
                    />
                    <ErrorMessage name="hotelPhone">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="hotelMobile"
                      as={TextField}
                      type="text"
                      value={values.hotelMobile}
                      imputMode="numeric"
                      variant="outlined"
                      label="Hotel Mobile"
                      InputProps={{ className: classes.inputProps }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                      required
                    />
                    <ErrorMessage name="hotelMobile">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="hotelEmail"
                      as={TextField}
                      type="email"
                      value={values.hotelEmail}
                      imputMode="numeric"
                      variant="outlined"
                      label="Hotel Email"
                      InputProps={{ className: classes.inputProps }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                      onWheel={(e) => e.target.blur()}
                      required
                    />
                    <ErrorMessage name="hotelEmail">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="propertyCode"
                      as={TextField}
                      type="text"
                      value={values.propertyCode}
                      variant="outlined"
                      label="Property Code"
                      InputProps={{ className: classes.inputProps }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                      onWheel={(e) => e.target.blur()}
                      required
                      validateOnBlur
                      validate={validatePropertyCode}
                    />
                    <ErrorMessage
                      name="propertyCode"
                      component="div"
                      className={classes.error}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="emailList"
                      as={TextField}
                      type="text"
                      value={values.emailList}
                      variant="outlined"
                      label="Email List"
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                      onWheel={(e) => e.target.blur()}
                      InputProps={{ style: { padding: "5px" } }}
                      helperText="Multiple values to be separated by comma"
                      minRows={2}
                      maxRows={6}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="phoneList"
                      as={TextField}
                      type="text"
                      value={values.phoneList}
                      variant="outlined"
                      label="Phone List"
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                      onWheel={(e) => e.target.blur()}
                      InputProps={{ style: { padding: "5px" } }}
                      helperText="Multiple values to be separated by comma"
                      minRows={2}
                      maxRows={6}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="websiteList"
                      as={TextField}
                      type="text"
                      value={values.websiteList}
                      variant="outlined"
                      label="Website List"
                      InputProps={{ style: { padding: "5px" } }}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      className={classes.fieldStyle}
                      onWheel={(e) => e.target.blur()}
                      helperText="Multiple values to be separated by comma"
                      minRows={2}
                      maxRows={6}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <>
                      <input
                        name="image"
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          console.log(e.target.files)
                          setFieldValue("image", e.target.files[0])
                        }}
                        onClick={(e) => (e.target.value = null)}
                        style={{ display: "none" }}
                        id="hotelLogo"
                      />
                      <label
                        htmlFor="hotelLogo"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Field
                          value={values.image}
                          as={TextField}
                          // className={classes.fieldStyle}
                          variant="outlined"
                          placeholder="Upload Image"
                          InputProps={{
                            readOnly: true,
                            className: classes.inputProps,
                          }}
                          label="Upload Property Logo"
                          InputLabelProps={{
                            shrink: true,
                            className: classes.labelProps,
                          }}
                        />
                        <Button
                          variant="contained"
                          component="span"
                          color="primary"
                        >
                          Upload
                        </Button>
                      </label>
                    </>
                  </Grid>
                  {propertyId !== null &&
                    propertyId !== "" &&
                    statusCode != null && (
                      <Grid item xs={3}>
                        <Button onClick={generateLoginCreds}>
                          Generate Login Details
                        </Button>
                      </Grid>
                    )}
                </Grid>
              </section>
            </section>
            <center>
              <Button
                type="submit"
                style={{ margin: "10px" }}
                disabled={!isValid || isSubmitting}
              >
                Save
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </MuiPickersUtilsProvider>
  )
}

export default PropertyDetails
