import Directory from "../../components/Directory/Directory.component"
import styles from "./TermsAndConditions.module.css"
import PhoneIcon from "../../assets/icons/contact-icons/phone.svg"
import MailIcon from "../../assets/icons/contact-icons/mail.svg"
import QueryForm from "../../components/QueryForm/QueryForm.component"
import ContactInfo from "../../components/ContactInfo/ContactInfo.component"

const content1 = [
  {
    main: "The Primary Customer in whose name the booking has been made should be above 18 years of age",
    sub: [],
  },
  {
    main: "ID Card",
    sub: [
      "Customer is required to carry valid ID Proof for everyone to be shown at the hotel at the time of check in.",
      "Pan Card and Government ID's are not valid.",
    ],
  },
  {
    main: "If payment is made through credit card, the customer is requested to carry the same for verification by the hotel.",
    sub: [],
  },
  {
    main: "Extra's",
    sub: [
      "ID Proof to be submitted on arrival for everyone.",
      "The Number of adults will be as mentioned on the voucher, any additional adults/children not informed are to be charged on arrival at the time of check in as per actual rates.",
      "Guest has to pay the extra charges for services not mentioned in the tour voucher that is availed during the stay and shall be paid directly to the hotel.",
    ],
  },
  {
    main: "Payment and Check In",
    sub: [
      "Early Check In & late Check Out is subject to the availability.",
      "Unless mentioned and confirmed on voucher not applicable.",
      "Full payment has to be cleared strictly on arrival at the time of check in",
      "Extra Charges may Apply for Early/Late Check out",
    ],
  },
  {
    main: "Please note we have limited parking available on first come basis.",
    sub: [],
  },
  {
    main: "Towno is a Registered Offline Sales Office/Partner of the Resort/Hotel.",
    sub: [],
  },
]

const content2 = [
  "Customers will be screened on arrival with non contact thermometer.",
  "Customers who have fever will be denied entry and will have to call local authorities to confirm covid test to be done/further action to be taken.",
  "Request the customers to follow social distancing and use masks at all times.",
  "Hotel has a right to deny the entry to a customer who shows signs and symptoms of infection and call local authorities for further action. No refund in any such case/scenario.",
  "Food would be served at the table/fixed menu if any as per hotel norms.",
  "Any changes time to time will be implemented for safety of the guests and hotel staff and request guests to follow for safety.",
  "Hotel/Reservations/Sales/Towno and service team is not responsible for an illness that a guest would contract if any during the stay",
  "Local covid and health regulations as given by the govt will be applicable and will be required to be  adhered by the guests",
  "Cancellation's as per policy.",
]

const content3 = [
  {
    main: "Food & TDH Menu/Fixed Menu",
    sub: [
      "Meal Plan as per TDH/Fixed menu unless specified.",
      "Starters and Ala Carte Items are not a part of the Menu and will be charged directly as per actuals.",
      "Breakfast if included as per plan is not on the day of arrival.",
    ],
  },
]

const TermsAndConditions = () => {
  return (
    <>
      <section className={styles.section}>
        <header className={styles.header}>Terms and Conditions</header>
        <div className={styles.body}>
          <main className={styles.main}>
            <ul className={styles["content-container"]}>
              {content1.map((c, i) => (
                <li key={i} className={styles.li}>
                  <>{c.main}</>
                  <ul>
                    {c.sub.map((s, idx) => (
                      <li className={styles.li}>{s}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>

            <div style={{ marginTop: "4rem" }}>
              <header
                style={{
                  color: "#f46d25",
                  fontWeight: "600",
                }}
              >
                Covid Rules and Regulations
              </header>
              <ul className={styles["content-container"]}>
                {content2.map((c, i) => (
                  <li key={i} className={styles.li}>
                    <>{c}</>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <header className={styles.header}>Meal Plan Policy</header>
              <ul className={styles["content-container"]}>
                {content3.map((c, i) => (
                  <li key={i} className={styles.li}>
                    <>{c.main}</>
                    <ul>
                      {c.sub.map((s, idx) => (
                        <li className={styles.li}>{s}</li>
                      ))}
                    </ul>
                  </li>
                ))}
                <li style={{ listStyle: "none", padding: "1rem" }}>
                  <div>
                    Breakfast can be TDH/Fixed menu/Buffet as per hotel policy.
                  </div>
                </li>
              </ul>
            </div>
          </main>
          <aside className={styles.aside}>
            <ContactInfo />
            <QueryForm />
          </aside>
        </div>
      </section>
    </>
  )
}

export default TermsAndConditions
