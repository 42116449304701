import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  makeStyles,
} from "@material-ui/core"
import {
  AddCircleOutlineRounded,
  DeleteForeverOutlined,
} from "@material-ui/icons"
import AddAPhoto from "@material-ui/icons/AddAPhoto"
import { Autocomplete, Box, Grid, Typography } from "@mui/material"
import { Field, FieldArray, Form, Formik } from "formik"
import { Fragment, useReducer, useState } from "react"
import Thumb from "../Staff/Thumb"

const useStyles = makeStyles(() => ({
  container: {
    padding: "4rem 1.6rem",
  },
  title: {
    fontWeight: 700,
    color: "#f46d25",
    fontSize: "2rem",
  },
  iconButton: {
    padding: "0",
  },
  box: {
    width: "100%",
    height: "fit-content",
    border: "1px solid red",
    boxSizing: "border-box !important",
    padding: "1rem",
  },
  card: {
    height: "10rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
    border: "1px solid #C4C4C4",
    backgroundColor: "rgba(196, 196, 196, 0.1)",
  },
}))

const destinationPageOptions = [
  {
    label: "Uttarakhand",
    value: "uttarakhand",
    subDestination: [{ label: "Nainital", value: "nainital" }],
  },
]

const pages = [
  { label: "Tourism", value: "tourism" },
  { label: "Things To Do", value: "thingsToDo" },
  { label: "Places To Visit", value: "placesToVisit" },
  { label: "Tour Packages", value: "tourPackages" },
  { label: "Best Time To Visit", value: "bestTimeToVisit" },
  { label: "How To Reach", value: "howToReach" },
  { label: "Key Facts", value: "keyFacts" },
  { label: "Shopping And Entertainment", value: "shoppingAndEntertainment" },
  { label: "Cuisine", value: "cuisine" },
  { label: "Things To Carry", value: "thingsToCarry" },
  { label: "Festival and Events", value: "festivalAndEvents" },
]

const ActionsTypes = {
  SELECT_DESTINATION: "SELECT_DESTINATION",
  SELECT_SUB_DESTINATION: "SELECT_SUB_DESTINATION",
  SELECT_PAGE: "SELECT_PAGE",
  SET_HEADER_SECTION: "SET_HEADER_SECTION",
}

const contentReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case ActionsTypes.SELECT_DESTINATION: {
      return {
        ...state,
        destination: payload.value,
        subDestinationOptions: payload.options,
      }
    }

    case ActionsTypes.SELECT_SUB_DESTINATION: {
      return { ...state, subDestination: payload.value }
    }
    case ActionsTypes.SELECT_PAGE: {
      return { ...state, page: payload.value }
    }

    default:
      return state
  }
}

const AddB2CContent = () => {
  const styles = useStyles()

  const [state, dispatch] = useReducer(contentReducer, {
    destination: "",
    subDestinationOptions: [],
    subDestination: "",
    page: "",
  })

  const [headerSection, setHeaderSection] = useState({
    pageDescription: "",
  })

  const [pageContent, setPageContent] = useState([
    {
      id: null,
      title: "",
      content: "",
      imgSource: [],
      addSubContent: false,
      subContent: [{ id: null, title: "", content: "", imgSource: [] }],
    },
  ])

  const [addAdditionalSection, setAddAdditionalSection] = useState(false)

  const handleUploadImage = (e, index, values, setFieldValue) => {
    setFieldValue(`pageContent[${index}].imgSource`, [
      ...values.pageContent[index].imgSource,
      e.target.files[0],
    ])
  }

  const handleSubmit = async (fields, { setSubmitting }) => {
    setSubmitting(true)
    const postBody = {
      id: null,
      destination: state.destination,
      subDestination: state.subDestination,
      page: state.page,
      ...fields,
    }

    // try {
    //   const data = await API.post("addwebsiteContent", postBody)
    //   console.log({ data })
    // } catch (error) {
    //   console.log(error)
    // } finally {
    //   setTimeout(() => {
    //     setSubmitting(false)
    //     console.log({ postBody })
    //   }, 10000)
    // }
    console.log({ postBody })
  }

  return (
    <div className={styles.container}>
      <Grid container md={12} spacing={3}>
        <Grid xs={12} item className={styles.title}>
          B2C Content Builder
        </Grid>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={3}>
            <Autocomplete
              disablePortal
              fullWidth
              options={destinationPageOptions}
              onChange={(event, option) =>
                dispatch({
                  type: ActionsTypes.SELECT_DESTINATION,
                  payload: {
                    value: option.value,
                    options: option.subDestination,
                  },
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Destination"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  required
                />
              )}
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              disablePortal
              fullWidth
              options={state.subDestinationOptions}
              onChange={(e, option) =>
                dispatch({
                  type: ActionsTypes.SELECT_SUB_DESTINATION,
                  payload: { value: option.value },
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sub-destination (If any)"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                />
              )}
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              disablePortal
              fullWidth
              options={pages}
              onChange={(e, option) =>
                dispatch({
                  type: ActionsTypes.SELECT_PAGE,
                  payload: { value: option.value },
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Page"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                />
              )}
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>

      <Formik
        initialValues={{ ...headerSection, pageContent, addAdditionalSection }}
        onSubmit={handleSubmit}
        render={({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid container md={12} spacing={1} marginTop="1rem">
              <Grid item md={12}>
                <Typography variant="h4" color="#f46d25">
                  Page Description
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Field
                  as={CKEditor}
                  editor={ClassicEditor}
                  type="inline"
                  data={values.pageBody}
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "blockQuote",
                      "link",
                      "numberedList",
                      "bulletedList",
                      "mediaEmbed",
                      "|",
                      "undo",
                      "redo",
                    ],
                  }}
                  name="pageDescription"
                  onChange={(event, editor) => {
                    const data = editor.getData()
                    setFieldValue("pageDescription", data)
                  }}
                  onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        "height",
                        "10rem",
                        editor.editing.view.document.getRoot()
                      )
                    })
                  }}
                />
              </Grid>
            </Grid>

            <Grid container md={12} marginTop="2rem">
              <Typography variant="h4" color="#f46d25">
                Page Content Builder
              </Typography>
              <FieldArray
                name="pageContent"
                render={(arrayHelpers) => (
                  <Grid item container md={12} spacing={4}>
                    {values?.pageContent?.map((page, index) => (
                      <Fragment key={`page-${index}`}>
                        <Grid item container md={12} spacing={2}>
                          <Grid item container md={12}>
                            <Grid item md={8}>
                              <Typography variant="h5" color="#f46d25">
                                Content {index + 1}
                              </Typography>
                            </Grid>
                            <Grid item md={4} textAlign="end">
                              {values?.pageContent?.length - 1 == index && (
                                <IconButton
                                  className={styles.iconButton}
                                  onClick={() =>
                                    arrayHelpers.push({
                                      id: null,
                                      title: "",
                                      content: "",
                                      imgSource: [],
                                      addSubContent: false,
                                      subContent: [
                                        {
                                          id: null,
                                          title: "",
                                          content: "",
                                          imgSource: [],
                                        },
                                      ],
                                    })
                                  }
                                >
                                  <AddCircleOutlineRounded color="primary" />
                                </IconButton>
                              )}
                              {values?.pageContent.length > 1 && (
                                <IconButton
                                  className={styles.iconButton}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <DeleteForeverOutlined color="primary" />
                                </IconButton>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item md={5}>
                            <Field
                              as={TextField}
                              variant="outlined"
                              name={`pageContent[${index}].title`}
                              size="small"
                              label="Enter Content Title"
                              required
                              fullWidth
                              placeholder="Title"
                            />
                          </Grid>
                          <Grid item md={12}>
                            <Field
                              as={CKEditor}
                              editor={ClassicEditor}
                              type="inline"
                              data={page?.content}
                              config={{
                                toolbar: [
                                  "heading",
                                  "|",
                                  "bold",
                                  "italic",
                                  "blockQuote",
                                  "link",
                                  "numberedList",
                                  "bulletedList",
                                  "mediaEmbed",
                                  "|",
                                  "undo",
                                  "redo",
                                ],
                              }}
                              name={`pageContent[${index}].content`}
                              onChange={(event, editor) => {
                                const data = editor.getData()
                                setFieldValue(
                                  `pageContent[${index}].content`,
                                  data
                                )
                              }}
                              onReady={(editor) => {
                                editor.editing.view.change((writer) => {
                                  writer.setStyle(
                                    "height",
                                    "11rem",
                                    editor.editing.view.document.getRoot()
                                  )
                                })
                              }}
                            />
                          </Grid>
                          {/* <Grid
                            item
                            container
                            md={12}
                            spacing={1}
                            key={`upload-image-${index}`}
                          >
                            <Box className={styles.box}>
                              <Grid item container md={12} gap="1rem">
                                <Grid item md={2}>
                                  <Card className={styles.card}>
                                    <input
                                      type="file"
                                      accept="image/*"
                                      id="upload-image"
                                      style={{ display: "none" }}
                                      onChange={(e) =>
                                        handleUploadImage(
                                          e,
                                          index,
                                          values,
                                          setFieldValue
                                        )
                                      }
                                    />
                                    <label htmlFor="upload-image">
                                      <IconButton
                                        variant="raised"
                                        component="span"
                                      >
                                        <AddAPhoto color="primary" />
                                      </IconButton>
                                    </label>
                                  </Card>
                                </Grid>

                                {values?.pageContent[index]?.imgSource.map(
                                  (src, imgIdx) => (
                                    <Grid item md={2} key={imgIdx}>
                                      <Thumb
                                        file={src}
                                        width="100%"
                                        height="100%"
                                      />
                                    </Grid>
                                  )
                                )}
                              </Grid>
                            </Box>
                          </Grid> */}
                          <Grid item md={4}>
                            <Field
                              as={FormControlLabel}
                              control={
                                <Field
                                  as={Checkbox}
                                  checked={page?.addSubContent}
                                  color="primary"
                                />
                              }
                              label="Add Sub Content"
                              name={`pageContent[${index}].addSubContent`}
                            />
                          </Grid>

                          {values?.pageContent[index]?.addSubContent && (
                            <FieldArray
                              name={`pageContent[${index}].subContent`}
                              render={(arrayHelpers) => (
                                <Grid item container md={9} spacing={3}>
                                  {values?.pageContent[index]?.subContent?.map(
                                    (sub, subIndex) => (
                                      <Grid
                                        item
                                        container
                                        md={12}
                                        spacing={2}
                                        key={`subContent-${subIndex}`}
                                      >
                                        <Grid item container md={12}>
                                          <Grid item md={8}>
                                            <Typography
                                              variant="h5"
                                              color="#f46d25"
                                            >
                                              Sub-Content{" "}
                                              {`${index + 1}.${subIndex + 1}`}
                                            </Typography>
                                          </Grid>
                                          <Grid item md={4} textAlign="end">
                                            {values?.pageContent[index]
                                              ?.subContent?.length -
                                              1 ==
                                              subIndex && (
                                              <IconButton
                                                className={styles.iconButton}
                                                onClick={() =>
                                                  arrayHelpers.push({
                                                    id: null,
                                                    title: "",
                                                    content: "",
                                                    imgSource: [],
                                                  })
                                                }
                                              >
                                                <AddCircleOutlineRounded color="primary" />
                                              </IconButton>
                                            )}
                                            {values?.pageContent[index]
                                              ?.subContent?.length > 1 && (
                                              <IconButton
                                                className={styles.iconButton}
                                                onClick={() =>
                                                  arrayHelpers.remove(index)
                                                }
                                              >
                                                <DeleteForeverOutlined color="primary" />
                                              </IconButton>
                                            )}
                                          </Grid>
                                        </Grid>
                                        <Grid item md={5}>
                                          <Field
                                            as={TextField}
                                            variant="outlined"
                                            name={`pageContent[${index}].subContent[${subIndex}].title`}
                                            size="small"
                                            label="Enter Sub-Content Title"
                                            required
                                            fullWidth
                                            placeholder="Title"
                                          />
                                        </Grid>
                                        <Grid item md={12}>
                                          <Field
                                            as={CKEditor}
                                            editor={ClassicEditor}
                                            type="inline"
                                            data={sub?.content}
                                            config={{
                                              toolbar: [
                                                "heading",
                                                "|",
                                                "bold",
                                                "italic",
                                                "blockQuote",
                                                "link",
                                                "numberedList",
                                                "bulletedList",
                                                "mediaEmbed",
                                                "|",
                                                "undo",
                                                "redo",
                                              ],
                                            }}
                                            name={`pageContent[${index}].subContent[${subIndex}].content`}
                                            onChange={(event, editor) => {
                                              const data = editor.getData()
                                              setFieldValue(
                                                `pageContent[${index}].subContent[${subIndex}].content`,
                                                data
                                              )
                                            }}
                                            onReady={(editor) => {
                                              editor.editing.view.change(
                                                (writer) => {
                                                  writer.setStyle(
                                                    "height",
                                                    "11rem",
                                                    editor.editing.view.document.getRoot()
                                                  )
                                                }
                                              )
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    )
                                  )}
                                </Grid>
                              )}
                            />
                          )}
                        </Grid>
                        {index < pageContent.length && (
                          <hr
                            style={{
                              width: "100%",
                              background: "#f46d25",
                              height: "0.2rem",
                            }}
                          />
                        )}
                      </Fragment>
                    ))}
                  </Grid>
                )}
              />
              {/* <Grid item md={4}>
                <Field
                  as={FormControlLabel}
                  control={
                    <Field
                      as={Checkbox}
                      checked={values.addAdditionalSection}
                      color="primary"
                    />
                  }
                  label="Add More Section"
                  name="addAdditionalSection"
                />
              </Grid> */}
            </Grid>

            {/* <FieldArray name="additionalSection" /> */}

            <Button type="submit" disabled={isSubmitting}>
              Click
            </Button>
          </Form>
        )}
      />
    </div>
  )
}

export default AddB2CContent
