import { Button, Grid, Paper, makeStyles } from "@material-ui/core";
import { format, parseISO } from "date-fns";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import jsPDF from "jspdf";

const useStyles = makeStyles({
  root: {
    padding: "25% 3%",
    paddingBottom: "20%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  createButton: {
    background: "#343A40",
    fontSize: "18px",
    height: "50%",
  },
  searchField: {
    width: "100%",
  },
  select: {
    "&:before": {
      border: "none",
    },
    "&:after": {
      border: "none",
    },
  },
});

export const createPDF = async (pdfName) => {
  const pdf = new jsPDF("portrait", "cm", [
    window.innerWidth,
    window.innerHeight,
  ]);
  const pdfData = document.querySelector(".transfer-pdf");

  await pdf.html(pdfData).then(() => {
    pdf.save(`${pdfName}.pdf`);
  });
};

const ViewTransferMobileUI = ({
  data,
  flightTempTotalAmount,
  busTempTotalAmount,
  trainTempTotalAmount,
  cabTempTotalAmount,
  isLoading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  console.log(data);

  return (
    <div className={classes.root}>
      <Button
        onClick={() => history.goBack()}
        style={{ marginTop: "-5%", marginBottom: "3%" }}
      >
        Go Back
      </Button>
      <div className="transfer-pdf">
        <div
          style={{
            display: "flex",
            background: "#343A40",
            height: "fit-content",
            padding: "2%",
            borderRadius: "5px",
            marginBottom: "4%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontSize: "20px", color: "white", fontWeight: "bold" }}>
            Transfer
          </div>
          <div
            style={{
              fontSize: "15px",
              background: "white",
              color: "#f46d25",
              borderRadius: "10px",
              padding: "1%",
              fontWeight: "bold",
            }}
          >
            {data.transferId}
          </div>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <div
            style={{
              color: "white",
              fontSize: "20px",
              fontWeight: "bold",
              background: "#f46d25",
              padding: "2%",
              borderRadius: "10px 10px 0px 0px",
            }}
          >
            Basic Details
          </div>
          {data.basicDetails.map((data) => (
            <Paper style={{ padding: "2%", borderRadius: "0px 0px 10px 10px" }}>
              <Grid container>
                <Grid item xs={5}>
                  Client Name
                </Grid>
                <Grid item xs={2} style={{ color: "#f46d25" }}>
                  :
                </Grid>
                <Grid item xs={5}>
                  {data.clientName}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5}>
                  Client No.
                </Grid>
                <Grid item xs={2} style={{ color: "#f46d25" }}>
                  :
                </Grid>
                <Grid item xs={5}>
                  {data.clientMobileNo}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5}>
                  Alt Contact No.
                </Grid>
                <Grid item xs={2} style={{ color: "#f46d25" }}>
                  :
                </Grid>
                <Grid item xs={5}>
                  {data.clientAltNo}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5}>
                  Email ID
                </Grid>
                <Grid item xs={2} style={{ color: "#f46d25" }}>
                  :
                </Grid>
                <Grid item xs={5} style={{ wordWrap: "break-word" }}>
                  {data.clientEmail}
                </Grid>
              </Grid>
            </Paper>
          ))}
        </div>
        {flightTempTotalAmount && (
          <div style={{ marginBottom: "10px" }}>
            <div
              style={{
                color: "white",
                fontSize: "20px",
                fontWeight: "bold",
                background: "#f46d25",
                padding: "2%",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              Flight Details
            </div>
            <Paper style={{ padding: "2%", borderRadius: "0px 0px 10px 10px" }}>
              {data.flightDetails.map((flight, idx) => (
                <>
                  <Grid container>
                    <Grid item xs={5}>
                      Trip
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {flight.flightTrip}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      To
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {flight.flightTo}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      From
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {flight.flightFrom}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Departure Date
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {format(parseISO(flight.flightDepartDate), "d MMMM,yyyy")}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Flight Name
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {flight.flightName}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      PNR No.
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {flight.flightPnr}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Adults
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {flight.flightAdults}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Children
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {flight.flightChild}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Amount
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      Rs. {flight.flightAmount}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Commission
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      Rs. {flight.flightComission}
                    </Grid>
                  </Grid>
                  {idx < data.flightDetails.length - 1 && (
                    <hr
                      style={{
                        border: "none",
                        borderTop: "1px solid #f46d25",
                      }}
                    />
                  )}
                </>
              ))}
            </Paper>
          </div>
        )}

        {busTempTotalAmount && (
          <div style={{ marginBottom: "10px" }}>
            <div
              style={{
                color: "white",
                fontSize: "20px",
                fontWeight: "bold",
                background: "#f46d25",
                padding: "2%",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              Bus Details
            </div>
            <Paper style={{ padding: "2%", borderRadius: "0px 0px 10px 10px" }}>
              {data.busDetails.map((bus) => (
                <>
                  <Grid container>
                    <Grid item xs={5}>
                      To
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {bus.busTo}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      From
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {bus.busFrom}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Bus Name
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {bus.busName}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Bus Seat No.
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {bus.busSeatNo}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Adults
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {bus.busAdults}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Children
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {bus.busChild}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Amount
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      Rs. {bus.busAmount}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Commission
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      Rs. {bus.busComission}
                    </Grid>
                  </Grid>
                </>
              ))}
            </Paper>
          </div>
        )}

        {cabTempTotalAmount && (
          <div style={{ marginBottom: "10px" }}>
            <div
              style={{
                color: "white",
                fontSize: "20px",
                fontWeight: "bold",
                background: "#f46d25",
                padding: "2%",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              Cab Details
            </div>
            <Paper style={{ padding: "2%", borderRadius: "0px 0px 10px 10px" }}>
              {data.cabDetails.map((cab) => (
                <>
                  <Grid container>
                    <Grid item xs={5}>
                      Trip
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {cab.cabTrip}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      To
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {cab.cabTo}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      From
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {cab.cabFrom}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Cab Type
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {cab.cabType}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Vehicle
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {cab.cabVehicle}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Adults
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {cab.cabAdults}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Children
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {cab.cabChild}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Amount
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      Rs. {cab.cabAmount}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Commission
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      Rs. {cab.cabComission}
                    </Grid>
                  </Grid>
                </>
              ))}
            </Paper>
          </div>
        )}
        {trainTempTotalAmount && (
          <div style={{ marginBottom: "10px" }}>
            <div
              style={{
                color: "white",
                fontSize: "20px",
                fontWeight: "bold",
                background: "#f46d25",
                padding: "2%",
                borderRadius: "10px 10px 0px 0px",
              }}
            >
              Train Details
            </div>
            <Paper style={{ padding: "2%", borderRadius: "0px 0px 10px 10px" }}>
              {data.trainDetails.map((train) => (
                <>
                  <Grid container>
                    <Grid item xs={5}>
                      To
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {train.trainTo}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      From
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {train.trainFrom}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Train Name
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {train.trainName}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Departure Date
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {format(parseISO(train.trainDepartDate), "d MMMM,yyyy")}
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={5}>
                      Travel Class
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {train.traintravelClass}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      PNR No.
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {train.trainPnr}
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={5}>
                      Adults
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {train.trainAdults}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Children
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {train.trainChild}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Inclusion
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      {train.trainInclusion}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Amount
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      Rs. {train.trainAmount}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5}>
                      Commission
                    </Grid>
                    <Grid item xs={2} style={{ color: "#f46d25" }}>
                      :
                    </Grid>
                    <Grid item xs={5}>
                      Rs. {train.trainComission}
                    </Grid>
                  </Grid>
                </>
              ))}
            </Paper>
            {/* <Button onClick={() => createPDF(data.transferId)}>Download</Button> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewTransferMobileUI;
