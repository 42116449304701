/* eslint-disable eqeqeq */
import React, { useState, useRef, useEffect } from "react";
import "./ClientVoucher.css";
import RatingMail from "./RatingMail";
import { Button } from "@material-ui/core";
import { useParams, Link } from "react-router-dom";
import Api from "../../Service/Api";
import { format } from "date-fns";
import moment from "moment";
import parse from "html-react-parser";
import _ from "lodash";
import { formatter } from "../../utils/formatNumber";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  hotelLoginCancellationPolicy,
  hotelLoginTermsAndConditions,
} from "../../redux/actions/hotelLoginAction";
import { hotelDetailsInitial } from "../../redux/actions/hotelViewAction";
import { propertyContactData } from "../../redux/actions/propertyAction";

const HotelLoginHotelVoucher = ({ id }) => {
  const tableRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState("copy");
  const { bookingId } = useParams();
  const uniqueid = localStorage.getItem("unique_id");
  const [bookingDetails, setBookingDetails] = useState({});
  const [cancelationpolicy, setCancelationpolicy] = useState({});
  const [termsandcondition, setTermsandcondition] = useState({});
  const [stayInclusion, setStayInclusion] = useState([]);
  const [roomInclusion, setRoomInclusion] = useState([]);
  const [roomOccupied, setroomOccupied] = useState([]);
  const [property, setProperty] = useState({});
  const [filtered, setFiltered] = useState([]);
  const [passenger, setPassenger] = useState([]);
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();
  const { termsAndConditions, cancellationPolicy } = useSelector(
    ({ hotelLogin }) => hotelLogin
  );

  const [rooms, setRooms] = useState("");
  const [noOfAdults, setNoOfAdults] = useState("");
  const [noOfChildren, setNoOfChildren] = useState("");

  const { propertyContactDatas } = useSelector(
    ({ propertyContactData }) => propertyContactData
  );

  useEffect(() => {
    dispatch(propertyContactData(uniqueid));
    console.log(propertyContactDatas);
  }, [uniqueid]);

  useEffect(() => {
    dispatch(hotelLoginTermsAndConditions(uniqueid));
    dispatch(hotelLoginCancellationPolicy(uniqueid));
    Api.post("propertybasicpropertydetailsvalue", {
      propertyId: uniqueid,
    }).then((res) => {
      setProperty(res.data);
    });
    console.log(termsAndConditions);
  }, [uniqueid]);

  const fetchBookingDetils = async () => {
    const response = await Api.get(
      `getHotelBookingDetails/${uniqueid}/${bookingId}`
    );
    setBookingDetails(response.data);
    setCancelationpolicy(cancellationPolicy);
    setTermsandcondition(termsAndConditions);

    setPassenger(response.data.hotelBookingLeads);
    setroomOccupied(response.data.hotelBookingRoom);

    const noOfRooms = response.data.hotelBookingRoom.reduce(
      (totalRooms, curr) => parseInt(totalRooms) + parseInt(curr.noOfRooms),
      0
    );
    setRooms(noOfRooms);

    const noOfAdults = response.data.hotelBookingRoom.reduce(
      (totalAdults, curr) => parseInt(totalAdults) + parseInt(curr.noOfAdults),
      0
    );
    setNoOfAdults(noOfAdults);

    const noOfChildren = response.data.hotelBookingRoom.reduce(
      (totalChildren, curr) =>
        parseInt(totalChildren) + parseInt(curr.noOfChildren),
      0
    );
    setNoOfChildren(noOfChildren);

    const RoomData = _.uniqBy(response.data.hotelBookingRoom, "roomType");
    setFiltered(RoomData);
    RoomData.map((item) =>
      Api.get(`stayroominclusion/${uniqueid}/${item.roomType}`).then((res) => {
        console.log("Hotel Voucher", res);
        setRoomInclusion((prevState) => [...prevState, ...res.data]);
      })
    );

    const stayInclusionData = await Api.get(`stayinclusion/${uniqueid}`);

    setStayInclusion(stayInclusionData.data);
  };

  useEffect(() => {
    fetchBookingDetils();
  }, [uniqueid, bookingId]);

  const selectedCheckin = moment(bookingDetails.checkIn).toDate();
  const selectedCheckout = moment(bookingDetails.checkOut).toDate();
  const copyToClip = async () => {
    let range = document.createRange();
    range.selectNodeContents(tableRef.current);
    let sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    document.execCommand("Copy");
    sel.removeAllRanges();
    //paste the copied data to mailto body
    document.addEventListener("paste", function (event) {
      var clipText = event.clipboardData.getData("Text");
      window.location = `mailto:?subject=I wanted you to see this site&body=${clipText}`;
    });
    await setCopySuccess("Copy");
    await toast.success("Copied Successfully");
  };

  return (
    <div id={id} style={{ margin: "20px 0px" }}>
      <div
        ref={tableRef}
        style={{
          maxWidth: "50%",
          margin: "0 auto",
          width: "100%",
          fontSize: "14px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#000",
              width: "100%",
            }}
          >
            <table style={{ width: "100%", padding: "10px" }}>
              <tbody>
                <tr>
                  <td style={{ width: "50%" }}>
                    <img
                      src="https://crmtowno.s3.ap-south-1.amazonaws.com/towno_white.png"
                      alt="Logo"
                      style={{ width: "100px" }}
                    />
                    <br />
                    <b style={{ color: "#fff", padding: "2px 0px" }}>
                      {rooms} Rooms | {bookingDetails.noOfNight}
                      Nights
                    </b>
                  </td>
                  <td
                    style={{
                      width: "50%",
                      color: "#fff",
                      textAlign: "end",
                      fontSize: "20px",
                    }}
                  >
                    <b>{bookingDetails.bookingId}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            style={{
              textAlign: "center",
              backgroundColor: "rgb(242, 242, 242)",
              width: "100%",
              color: "#000",
            }}
          >
            <table
              style={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <tbody>
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      width: "100%",
                      padding: "7px 0px",
                    }}
                  >
                    <b>Your Booking is Confirmed</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              width: "100%",
              color: "#000",
            }}
          >
            <div style={{ flex: "100%", padding: "10px", fontSize: "20px" }}>
              <b>{property.propertyName}</b>{" "}
              <RatingMail rating={property.starRating} />
            </div>
            <div style={{ flex: "100%", padding: "0px 10px 10px 10px" }}>
              <b>Address : </b>
              {`${property.propertyAddress}${property.state}, `}
              {propertyContactDatas.hotelMobile}
            </div>
          </div>
          <div
            style={{
              height: "2px",
              backgroundColor: "#f46d25",
              borderColor: "#f46d25",
            }}
          ></div>
        </div>

        <div
          style={{
            backgroundColor: "rgb(242, 242, 242)",
            width: "100%",
            color: "#000",
            marginTop: "10px",
          }}
        >
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgb(172, 167, 167)",
                    width: "100%",
                    color: "#000",
                    padding: "10px",
                  }}
                >
                  <b>Customer Details</b>
                </td>
              </tr>
            </tbody>
          </table>
          <table style={{ width: "100%", padding: "10px" }}>
            <tbody>
              <tr>
                <td style={{ width: "49%" }}>Name</td>
                <td style={{ width: "2%" }}>:</td>
                <td style={{ width: "49%", textTransform: "capitalize" }}>
                  {bookingDetails.clientName}
                </td>
              </tr>
              <tr>
                <td style={{ width: "49%" }}>Contact Number</td>
                <td style={{ width: "2%" }}>:</td>
                <td style={{ width: "49%" }}>{bookingDetails.clientMobile}</td>
              </tr>
              <tr>
                <td style={{ width: "49%" }}>Room</td>
                <td style={{ width: "2%" }}>:</td>
                <td style={{ width: "49%" }}>{rooms}</td>
              </tr>
              <tr>
                <td style={{ width: "49%" }}>Board Type</td>
                <td style={{ width: "2%" }}>:</td>
                <td style={{ width: "49%", textTransform: "uppercase" }}>
                  {console.log("roomOccupied", roomOccupied)}
                  {_.uniqBy(roomOccupied, "mealPlan").map((item) => (
                    <span key={item.id}>{item.mealPlan}</span>
                  ))}
                </td>
              </tr>
              <tr>
                <td style={{ width: "49%" }}>Adults</td>
                <td style={{ width: "2%" }}>:</td>
                <td style={{ width: "49%" }}>{noOfAdults}</td>
              </tr>
              <tr>
                <td style={{ width: "49%" }}>Children</td>
                <td style={{ width: "2%" }}>:</td>
                <td style={{ width: "49%" }}>{noOfChildren}</td>
              </tr>
              <tr>
                <td style={{ width: "49%" }}>Room Type</td>
                <td style={{ width: "2%" }}>:</td>
                <td style={{ width: "49%" }}>
                  {_.uniqBy(roomOccupied, "boardBasic").map((item) => (
                    <span key={item.id}>
                      {item.boardBasic}
                      <br />
                    </span>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgb(172, 167, 167)",
                    color: "#000",
                    textAlign: "center",
                    width: "40%",
                    padding: "10px",
                  }}
                >
                  <b>Check-in</b> <br />
                  {format(selectedCheckin, "d MMM yy")} |{" "}
                  {_.slice(property.checkinTime, 10)}
                  {""}(IST)
                </td>
                <td
                  style={{
                    backgroundColor: "rgb(172, 167, 167)",
                    color: "#000",
                    textAlign: "center",
                    width: "10%",
                    padding: "10px",
                  }}
                >
                  <b>Duration</b> <br />
                  {bookingDetails.noOfNight} Nights
                </td>
                <td
                  style={{
                    backgroundColor: "rgb(172, 167, 167)",
                    color: "#000",
                    textAlign: "center",
                    width: "40%",
                    padding: "10px",
                  }}
                >
                  <b>Check-out </b>
                  <br />
                  {format(selectedCheckout, "d MMM yy")} |{" "}
                  {_.slice(property.checkoutTime, 10)}
                  {""}(IST)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          style={{
            backgroundColor: "rgb(242, 242, 242)",
            width: "100%",
            color: "#000",
            marginTop: "10px",
          }}
        >
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgb(172, 167, 167)",
                    width: "100%",
                    color: "#000",
                    padding: "10px",
                  }}
                >
                  <b>Price Summary</b>
                </td>
              </tr>
            </tbody>
          </table>
          <table style={{ width: "100%", padding: "10px" }}>
            <tbody>
              <tr>
                <td style={{ width: "49%" }}>Total</td>
                <td style={{ width: "2%" }}>:</td>
                <td style={{ width: "49%" }}>
                  Rs. {formatter.format(bookingDetails.totalBookingAmount)}
                </td>
              </tr>
              <tr>
                <td style={{ width: "49%" }}>Towno to Pay</td>
                <td style={{ width: "2%" }}>:</td>
                <td style={{ width: "49%" }}>
                  Rs. {formatter.format(bookingDetails.partialPayment)}
                </td>
              </tr>
              <tr>
                <td style={{ width: "49%" }}>
                  Balance Payable on arrival at Check In
                </td>
                <td style={{ width: "2%" }}>:</td>
                <td style={{ width: "49%" }}>
                  Rs. {formatter.format(bookingDetails.balPayOnArrival)}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>**All prices are inclusive of taxes, unless mentioned</tr>
            </tfoot>
          </table>
        </div>
        {_.isEmpty(passenger) ? null : (
          <div
            style={{
              backgroundColor: "#fff",
              width: "100%",
              color: "#000",
              marginTop: "10px",
            }}
          >
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgb(172, 167, 167)",
                      width: "100%",
                      color: "#000",
                      padding: "10px",
                    }}
                  >
                    <b>Lead Pax</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <table style={{ width: "100%", padding: "10px" }}>
              <tbody>
                <tr>
                  <th>Sl No.</th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>Email</th>
                </tr>
                {passenger.map((passenger, idx) => (
                  <>
                    <tr>
                      <td style={{ textAlign: "center" }}>{idx + 1}</td>
                      <td style={{ textAlign: "center" }}>
                        {passenger.leadPaxName}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {passenger.leadPaxMobileNo}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {passenger.leadPaxEmail}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {!_.isEmpty(stayInclusion) ? (
          <div style={{ width: "100%", marginTop: "10px" }}>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgb(172, 167, 167)",
                      width: "100%",
                      color: "#000",
                      padding: "10px",
                    }}
                  >
                    <b>Stay Inclusion</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                width: "100%",

                textTransform: "capitalize",
              }}
            >
              <tr>
                {stayInclusion.map((stay) => (
                  <>
                    <ul
                      key={stay.id}
                      style={{ marginBottom: "0px", marginTop: "3px" }}
                    >
                      <li>
                        {stay.type1} {stay.type2}{" "}
                        {stay.name == "Parking"
                          ? "Parking (Subject to Availability)"
                          : stay.name}{" "}
                        {_.isEmpty(stay.type1) && _.isEmpty(stay.type2)
                          ? "Available"
                          : null}
                      </li>
                    </ul>
                  </>
                ))}
              </tr>
            </table>
          </div>
        ) : null}
        {!_.isEmpty(roomInclusion) ? (
          <div style={{ width: "100%" }}>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      backgroundColor: "rgb(172, 167, 167)",
                      width: "100%",
                      color: "#000",
                      padding: "10px",
                    }}
                  >
                    <b>Room Inclusion</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                width: "100%",

                textTransform: "capitalize",
              }}
            >
              <tbody>
                {filtered.map((item) => (
                  <>
                    <tr>
                      <td style={{ paddingLeft: "10px" }}>
                        <b>{item.roomType} </b>
                      </td>
                    </tr>
                    <tr>
                      <ul style={{ marginBottom: "0px", marginTop: "3px" }}>
                        {roomInclusion.map((room) => (
                          <>
                            {room.aname && item.roomType == room.room ? (
                              <li>
                                {room.type1} {room.type2} {room.name}{" "}
                                {_.isEmpty(room.type1) && _.isEmpty(room.type2)
                                  ? "Available"
                                  : null}
                              </li>
                            ) : null}
                          </>
                        ))}
                      </ul>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        <div style={{ width: "100%" }}>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgb(172, 167, 167)",
                    color: "#000",
                    width: "100%",

                    padding: "10px",
                  }}
                >
                  <b>Cancellation Policy</b>
                </td>
              </tr>
            </tbody>
          </table>
          <table style={{ width: "100%", padding: "0px 10px" }}>
            <tbody>
              <tr>
                <td>
                  {_.isEmpty(cancellationPolicy)
                    ? null
                    : parse(cancellationPolicy)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ width: "100%" }}>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td
                  style={{
                    backgroundColor: "rgb(172, 167, 167)",
                    color: "#000",
                    width: "100%",
                    padding: "10px",
                  }}
                >
                  <b>Terms & Conditions</b>
                </td>
              </tr>
            </tbody>
          </table>
          <table style={{ width: "100%", padding: "0px 10px" }}>
            <tbody>
              <tr>
                <td>
                  {_.isEmpty(termsAndConditions)
                    ? null
                    : parse(termsAndConditions)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ width: "100%" }}>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td
                  style={{
                    backgroundColor: "#F6F7F8",
                    width: "100%",
                    textAlign: "center",
                    padding: "10px",
                  }}
                >
                  © Copyright: Towno 2023. Towno is the authorised/registered
                  sales office of the hotel.
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    backgroundColor: "#F6F7F8",
                    width: "100%",
                    textAlign: "center",
                    padding: "10px 10px 10px 10px",
                  }}
                >
                  By accepting this booking, the customer agrees to abide by
                  Towno's terms & conditions. Visit:{" "}
                  <Link
                    to={{ pathname: "https://towno.in/terms" }}
                    target="_blank"
                  >
                    Click Here
                  </Link>
                </td>{" "}
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {document.queryCommandSupported("copy") && (
        <div style={{ marginTop: "30px", textAlign: "center" }}>
          <Button color="primary" onClick={copyToClip}>
            {copySuccess}
          </Button>
        </div>
      )}
    </div>
  );
};

export default HotelLoginHotelVoucher;
