import { ActionTypes } from "./actionTypes"

export const handleOpenVideoPlayerModal = () => (dispatch) => {
  dispatch({ type: ActionTypes.TOGGLE_VIDEO_PLAYER_MODAL })
}

export const handleCloseVideoPlayerModal = () => (dispatch) => {
  dispatch({ type: ActionTypes.TOGGLE_VIDEO_PLAYER_MODAL })
  dispatch({ type: ActionTypes.SET_VIDEO_URL_TO_NULL })
}

export const setVideoUrl = (url) => (dispatch) => {
  dispatch({ type: ActionTypes.SET_VIDEO_URL, payload: { url } })
}
