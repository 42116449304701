import styles from "./ImagesAndContent.module.css"

const ImageAndContentCard = ({ children }) => { 
  return <div className={styles.container}>{children}</div>
}

const Image = ({ src, props }) => {
  return (
    <div className={styles.imagePlaceHolder}>
      <img src={src} {...props} />
    </div>
  )
}

const Content = ({ children }) => {
  return <div className={styles.content}>{children}</div>
}

const Title = ({ children }) => {
  return <h3 className={styles.title}>{children}</h3>
}

ImageAndContentCard.Image = Image
ImageAndContentCard.Content = Content
ImageAndContentCard.Title = Title

export default ImageAndContentCard
