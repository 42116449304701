import styles from "./RefundAndCancellationPolicy.module.css"
import PhoneIcon from "../../assets/icons/contact-icons/phone.svg"
import MailIcon from "../../assets/icons/contact-icons/mail.svg"
import QueryForm from "../../components/QueryForm/QueryForm.component"
import ContactInfo from "../../components/ContactInfo/ContactInfo.component"
const RefundAndCancellationPolicy = () => {
  return (
    <section className={styles.section}>
      <div className={styles.mainBody}>
        <article>
          <header className={styles.header}>Refund Policy</header>
          <div className={styles.refundPolicyContainer}>
            <div className={styles.refundPolicyQuestion}>
              1. In which scenarios does Towno issue refunds?
            </div>
            <div className={styles.refundPolicy}>
              Refunds are issued by Towno only when:
              <ol className={styles.orderList}>
                <li>
                  The order is cancelled by the customer and refund is as per
                  refund policy after deduction if the customer is eligible for
                  the same.
                </li>
                <li>
                  Credit note if issued, will be used for further bookings only.
                  Credit notes are not eligible for any refunds.
                </li>
                <li>
                  If the Package order is not fulfilled, Towno holds the right
                  to cancel and refund under policy/terms and conditions as
                  applicable.
                </li>
              </ol>
            </div>
          </div>

          <div className={styles.refundPolicyContainer}>
            <div className={styles.refundPolicyQuestion}>
              2. What is the amount that is Refunded to me?
            </div>
            <div className={styles.refundPolicy}>
              If a customer cancels and requests for a refund, the amount
              refunded would be as per the cancellation policy. (See
              cancellation policy as per voucher emailed)
            </div>
          </div>

          <div className={styles.refundPolicyContainer}>
            <div className={styles.refundPolicyQuestion}>
              3. How can I track the status of my refund after cancellation?
            </div>
            <div className={styles.refundPolicy}>
              Once we have received the returned product( Copied?), our team
              will verify the nature of refund. After validation, we will send
              you an acknowledgement via SMS to your registered mobile number
              and email to your registered email id. To check the updated status
              of your refund you can call our customer care number at
              +918999248050 (10.00 a.m. to 6.00 p.m. / 7 days a week) or email
              support@towno.in. All emails will be responded to within 24 hours.
            </div>
          </div>

          <div className={styles.refundPolicyContainer}>
            <div className={styles.refundPolicyQuestion}>
              4. I've still not received the refund in my account even though
              Towno customer care says that they have processed the refund. Why?
            </div>
            <div className={styles.refundPolicy}>
              If you have received an email /SMS from us confirming your refund
              request, then be rest assured that we have initiated your refund
              request and are following up with the financial organization for
              the same. Sometimes, financial organizations & banks take longer
              than the promised time to process the refund request internally.
              If this is the case, then kindly follow up with them for your
              pending refund after providing them the refund reference number
              given by Towno customer care.
            </div>
          </div>

          <div className={styles.refundPolicyContainer}>
            <div className={styles.refundPolicyQuestion}>
              5. How long will it take to process my refund if I opt for a
              refund after cancellation?
            </div>
            <div className={styles.refundPolicy}>
              We will process your refund after receipt of the confirmation of
              cancellation or from our Business Partner / Seller. You will get
              an e-mail/SMS from Towno when your return is accepted and your
              refund is processed. The refund will be processed within 72 hrs of
              the confirmation by our team.
            </div>
          </div>

          <div className={styles.refundPolicyContainer}>
            <div className={styles.refundPolicyQuestion}>
              6. How and where will I receive my refund amount?
            </div>
            <div className={styles.refundPolicy}>
              Refunds on Towno will be given through various payment modes
              including NEFT, UPI or any other digital mode.
            </div>
          </div>

          <div className={styles.refundPolicyContainer}>
            <div className={styles.refundPolicyQuestion}>
              7. Can I get a refund for a part of my order?
            </div>
            <div className={styles.refundPolicy}>
              Yes. You can request refund for a part of your order if the order
              contains multiple sub-orders, subject to our confirmation from our
              business partners or tour operators subject to terms and
              conditions applied.
            </div>
          </div>
        </article>

        <article className={styles.cancellationPolicy}>
          <header className={styles.header}>Cancellation Policy</header>
          <div className={styles.refundPolicyContainer}>
            <div className={styles.refundPolicyQuestion}>
              For Domestic Bookings
            </div>

            <ol className={styles.orderList}>
              <li>0-5 days prior to day of arrival is non-refundable.</li>
              <li>
                Booking Date to 6 days prior to date of arrival check in Date is
                30% retention.
              </li>
            </ol>
          </div>

          <div className={styles.refundPolicyContainer}>
            <div className={styles.refundPolicyQuestion}>
              For Domestic Bookings (Groups, 5 Rooms and above)
            </div>

            <ol className={styles.orderList}>
              <li>On Booking 5 rooms and above is 0-15 days non-refundable.</li>
              <li>50% retention on cancellation prior to that.</li>
            </ol>
          </div>

          <div className={styles.refundPolicyContainer}>
            <div className={styles.refundPolicyQuestion}>
              For Peak/Super Peak Periods
            </div>

            <ol className={styles.orderList}>
              <li>22nd December to 4th January.</li>
              <li>Non Refundable, Non Amendable.</li>
            </ol>
          </div>
          <div className={styles.importantNotesContainer}>
            <header className={styles.important}>IMPORTANT</header>
            <div className={styles.importantPoints}>
              <ol className={styles.orderList}>
                <li>
                  Bookings cannot be canceled once in the Cancellation Phase or
                  at the Time of arrival/check-in or Once Checked in.
                </li>
                <li>
                  No Refunds will be issued for Bookings in the Cancellation
                  Phase.
                </li>
                <li>
                  No Refunds in case of Natural Disasters, Calamities, Act of
                  God cancellations.
                </li>
                *Terms and Conditions Apply.
              </ol>
            </div>
          </div>
        </article>
      </div>
      <aside className={styles.aside}>
        <ContactInfo />
        <QueryForm />
      </aside>
    </section>
  )
}

export default RefundAndCancellationPolicy
