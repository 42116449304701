import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import "./index.css"
import reportWebVitals from "./reportWebVitals"

import { ThemeProvider } from "@material-ui/core/styles"
import { PersistGate } from "redux-persist/integration/react"
import { persistor, store } from "./redux/store.js"
import { theme } from "./theme.js"
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary.component.jsx"
import { ErrorFallback } from "./components/ErrorFallback/ErrorFallback.component.jsx"

ReactDOM.render(
  <ErrorBoundary fallback={<ErrorFallback />}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </ErrorBoundary>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
