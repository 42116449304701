/**styles import */
import "./header.style.css"

/**assets import */
import { ReactComponent as FacebookIcon } from "../../assets/icons/header-icons/facebook.svg"
import { ReactComponent as InstagramIcon } from "../../assets/icons/header-icons/instagram.svg"
import { ReactComponent as TwitterIcon } from "../../assets/icons/header-icons/twitter.svg"
import { ReactComponent as YoutubeIcon } from "../../assets/icons/header-icons/youtube.svg"
import { Link, NavLink } from "react-router-dom/cjs/react-router-dom.min"

/**icons array */
const headerIconsSrc = [
  {
    id: 1,
    src: FacebookIcon,
    alt: "facebook",
    link: "https://www.facebook.com/ExploreWithTowno",
  },
  {
    id: 2,
    src: InstagramIcon,
    alt: "instagram",
    link: "https://www.instagram.com/explorewithtowno/",
  },
  {
    id: 3,
    src: TwitterIcon,
    alt: "twitter",
    link: "https://www.twitter.com/",
  },
  {
    id: 4,
    src: YoutubeIcon,
    alt: "youtube",
    link: "https://www.youtube.com/@explorewithtowno",
  },
]

const Header = () => (
  <header className="header">
    <ul className="header-icons">
      {/* {headerIconsSrc.map((icon) => (
        <li key={icon.id}>
          <icon.src
            // src={icon.src}
            // alt={icon.alt}
            // title={icon.alt}
            onClick={() => window.open(icon.link, "_blank")}
            className="media-icons"
          />
        </li>
      ))} */}
    </ul>

    <div className="query-links">
      <h5>
        <NavLink
          to="/contactUs"
          style={{ textDecoration: "none", color: "white" }}
        >
          Group Enquiry?
        </NavLink>
      </h5>
      <h5>
        <a
          href="https://gotowno.com/"
          target="_blank"
          style={{ textDecoration: "none", color: "white" }}
        >
          Partner with us?
        </a>
      </h5>
    </div>
  </header>
)

export default Header
