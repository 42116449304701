import styles from "./InlineImageAndContentCard.module.css"

const InlineImageAndContentCard = ({ children }) => {
  return <div className={styles.cardContainer}>{children}</div>
}

const Image = ({ src, size, props }) => {
  return <img src={src} className={styles[size]} {...props} />
}

const Content = ({ title, children }) => {
  return (
    <div className={styles.content}>
      <h3 className={styles.title}>{title}</h3>
      <> {children}</>
    </div>
  )
}

InlineImageAndContentCard.Image = Image
InlineImageAndContentCard.Content = Content

export default InlineImageAndContentCard
