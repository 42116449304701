import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core";
import { Formik } from "formik";
import Photo from "../../../Property/Rooms/Amenities/Photo/Photo";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const PropertyRoomPhoto = ({
  classes,
  handleChangePanel,
  expanded,
  setExpanded,
}) => {
  const handleAccordionChange = () => setExpanded("panel6");

  return (
    <Accordion
      square
      expanded={expanded === "panel5"}
      onChange={handleChangePanel("panel5")}
      className={classes.accordionStyles}
    >
      <AccordionSummary
        expandIcon={
          <ArrowDropDownIcon
            style={{
              color: "#f46d25",
              background: "white",
              borderRadius: "5px",
            }}
          />
        }
        className={classes.accordionSummaryStyles}
      >
        Photo
      </AccordionSummary>
      <AccordionDetails className={classes.outerSectionStyles}>
        <Photo />
      </AccordionDetails>
      <center>
        <Button onClick={handleAccordionChange} size="small">
          Save and Next
        </Button>
      </center>
    </Accordion>
  );
};

export default PropertyRoomPhoto;
