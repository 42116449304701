import { ActionTypes } from "../actions/actionTypes";

const INITIAL_STATE = {
  openCloneDialog: false,
  openT_and_C_Dialog: false,
};

export const clonePropertyAmenitiesReducer = (
  state = INITIAL_STATE,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.TOGGLE_CLONE_DIALOG:
      return {
        ...state,
        openCloneDialog: !state.openCloneDialog,
      };
    case ActionTypes.TOGGLE_CLONE_T_AND_C:
      return {
        ...state,
        openT_and_C_Dialog: !state.openT_and_C_Dialog,
      };

    default:
      return state;
  }
};
