import styles from "./DestinationHome.styles.module.css"
import "./slider.styles.css"

import DestinationCardV2 from "../../../../components/DestinationNew/DestinationCard_V2/DestinationCardV2.component"

import { useContext, useLayoutEffect, useRef, useState } from "react"

import Chardham from "../../../../assets/illustrations/destination/pilgrims_card/chardham.png"
import Haridwar from "../../../../assets/illustrations/destination/pilgrims_card/haridwar.png"
import Rishikesh from "../../../../assets/illustrations/destination/pilgrims_card/rishikesh.png"
import ThingsTodoBanner from "../../../../assets/illustrations/destination/things_todo/things-todo.png"
import PlanningNextTripBanner from "../../../../components/DestinationNew/PlanningNextTripBanner/PlanningNextTripBanner.component"

import { Link, useRouteMatch } from "react-router-dom/cjs/react-router-dom.min"
import Slider from "react-slick"
import { DestinationLayoutContext } from "../../../../layout/DestinationLayout/Destination.layout"

import SlideImage1 from "../../../../assets/illustrations/destination/slider-images/Haridwar2.jpg"
import SlideImage2 from "../../../../assets/illustrations/destination/slider-images/Monsoon in Uttarakhand.jpg"
import SlideImage3 from "../../../../assets/illustrations/destination/slider-images/Nainital1.jpg"
import SlideImage4 from "../../../../assets/illustrations/destination/slider-images/Paragliding.jpg"
import SlideImage5 from "../../../../assets/illustrations/destination/slider-images/Rishikesh1.jpg"
import SlideImage6 from "../../../../assets/illustrations/destination/slider-images/Rishikesh2.jpg"
import SlideImage7 from "../../../../assets/illustrations/destination/slider-images/Tungnath-Temple-by-Abhinav-Tunait-www.flickr.comphotos103602929@N08.jpg"
import SlideImage8 from "../../../../assets/illustrations/destination/slider-images/rafting.jpg"

import MunsyariImg from "../../../../assets/illustrations/destination/where-you-headed/Chopta Chandrashila Trek3.JPG"
import DhanaultiImg from "../../../../assets/illustrations/destination/where-you-headed/Dhanaulti_2.jpg"
import HaridwarImg from "../../../../assets/illustrations/destination/where-you-headed/Haridwar.jpg"
import MussoorieImg from "../../../../assets/illustrations/destination/where-you-headed/Mussoorie.jpg"
import RishikeshImg from "../../../../assets/illustrations/destination/where-you-headed/Rishikesh2.jpg"
import AuliImg from "../../../../assets/illustrations/destination/where-you-headed/auli.jpg"
import NainitalImg from "../../../../assets/illustrations/destination/where-you-headed/nanital.jpg"
import ChamoliImg from "../../../../assets/illustrations/destination/where-you-headed/valley-of-flowers-5353172.jpg"

import { Button, IconButton } from "@material-ui/core"
import BungeeJumping from "../../../../assets/illustrations/destination/things_todo/Bungee jumping in Rishikesh5.jpg"
import KrmptyFalls from "../../../../assets/illustrations/destination/things_todo/Visit To Kempty Falls in Mussoorie1.jpg"
import RajajiNationalPark from "../../../../assets/illustrations/destination/things_todo/Visit to Rajaji National Park2.jpg"
import ValleyOfFlowers from "../../../../assets/illustrations/destination/things_todo/Visit to the Valley of Flowers National Park3.jpg"

import { ArrowForwardIos, DoneOutlined } from "@material-ui/icons"
import LaunchIcon from "@material-ui/icons/Launch"
import ReactPlayer from "react-player/lazy"
import { useDispatch } from "react-redux"
import {
  handleOpenVideoPlayerModal,
  setVideoUrl,
} from "../../../../../redux/actions/videoPlayerModal.action"
import { ReactComponent as YoutubeIcon } from "../../../../assets/icons/youtube-custom.svg"
import Festival2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Home/Copy of Events & Festivals in Uttarakhand.jpeg"
import Festival4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Home/International Yoga Festival.jpg"
import Festival3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Home/Tehri Lake Festival.jpg"
import KumbhMela from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Home/kumbh mela.jpg"

import Image2 from "../../../../assets/illustrations/destination/Places to visit/Link (1).png"
import Image3 from "../../../../assets/illustrations/destination/Places to visit/Link (2).png"
import Image4 from "../../../../assets/illustrations/destination/Places to visit/Link (3).png"
import Image5 from "../../../../assets/illustrations/destination/Places to visit/Link (4).png"
import Image1 from "../../../../assets/illustrations/destination/Places to visit/Link (5).png"
import Image6 from "../../../../assets/illustrations/destination/Places to visit/Link.png"

import HowToReachModes from "../../../../assets/illustrations/destination/howToReach/Frame 15.png"
import HowToReachBG from "../../../../assets/illustrations/destination/howToReach/layer.png"

import ClothingIcon from "../../../../assets/illustrations/destination/thingsToCarry/Clip path groupclothing.png"
import DocumentIcon from "../../../../assets/illustrations/destination/thingsToCarry/Vectordocument.png"
import HeartRateIcon from "../../../../assets/illustrations/destination/thingsToCarry/VectorheartRate.png"
import MoneyIcon from "../../../../assets/illustrations/destination/thingsToCarry/money.png"
import ThingToCarryBG from "../../../../assets/illustrations/destination/thingsToCarry/tourist-with-thumb-up-PhotoRoom 2.png"

import StateMap from "../../../../assets/illustrations/destination/shopping/image 25stateMap.png"
import PackageImage from "../../../../assets/illustrations/destination/tour_Packages/pexels-godisable-jacob-871495-removebg-preview 2.png"

import BaraBazaar from "../../../../assets/illustrations/destination/shopping/locations/coverbara_Bazar.png"
import ChowkBazaar from "../../../../assets/illustrations/destination/shopping/locations/coverchowk_Bazar.png"
import MallRoad from "../../../../assets/illustrations/destination/shopping/locations/covermall_road.png"
import PaltanBazaar from "../../../../assets/illustrations/destination/shopping/locations/placeholderpaltan Bazaar.png"

import { Grid } from "@mui/material"
import htmlParser from "html-react-parser"

const Keyfacts = [
  {
    id: "",
    label: "Poplulation",
    content: "10,086,292+",
  },
  {
    id: "",
    label: "Area",
    content: "53,483 km<sup>2</sup>",
  },
  {
    id: "",
    label: "Density",
    content: "189/km<sup>2</sup>",
  },

  { label: "Text", content: "00+" },
]

const sliderImages = [
  { src: SlideImage1 },
  { src: SlideImage2 },
  { src: SlideImage3 },
  { src: SlideImage4 },
  { src: SlideImage5 },
  { src: SlideImage6 },
  { src: SlideImage7 },
  { src: SlideImage8 },
]

const whereYouHeadedSrc = [
  {
    src: HaridwarImg,
    name: "Haridwar",
    link: "haridwar",
    videoUrl: "https://www.youtube.com/watch?v=29JUdDsXCtY",
  },
  // {
  //   src: DehradunImg,
  //   name: "Dehradun",
  //   link: "Dehradun",
  //   videoUrl: "https://www.youtube.com/watch?v=J3pupDbmLg8",
  // },
  // {
  //   src: MussoorieImg,
  //   name: "Mussoorie",
  //   link: "haridwar",
  //   videoUrl: "https://www.youtube.com/watch?v=J3pupDbmLg8",
  // },
  {
    src: RishikeshImg,
    name: "Rishikesh",
    link: "haridwar",
    videoUrl: "https://www.youtube.com/watch?v=oTTNZvnZkSk",
  },
  // {
  //   src: TungnathTempleImg,
  //   name: "Tungnath Temple",
  //   link: "haridwar",
  //   videoUrl: "https://www.youtube.com/watch?v=oTTNZvnZkSk",
  // },
  // {
  //   src: UkhimathImg,
  //   name: "Ukhimath",
  //   link: "haridwar",
  //   videoUrl: "https://www.youtube.com/watch?v=oTTNZvnZkSk",
  // },
  // {
  //   src: AuliImg,
  //   name: "Auli",
  //   link: "haridwar",
  //   videoUrl: "https://www.youtube.com/watch?v=J3pupDbmLg8",
  // },
  {
    src: NainitalImg,
    name: "Nainital",
    link: "haridwar",
    videoUrl: "https://www.youtube.com/watch?v=J3pupDbmLg8",
  },
]

const hillStationsSrc = [
  { src: MussoorieImg, name: "Mussoorie", link: "haridwar" },
  { src: AuliImg, name: "Auli", link: "haridwar" },
  { src: NainitalImg, name: "Nainital", link: "haridwar" },
  { src: DhanaultiImg, name: "Dhanaulti", link: "haridwar" },
  { src: ChamoliImg, name: "Chamoli", link: "haridwar" },
  { src: MunsyariImg, name: "Munsyari", link: "haridwar" },
  { src: MussoorieImg, name: "Mussoorie", link: "haridwar" },
  { src: AuliImg, name: "Auli", link: "haridwar" },
]

const thingsTodoSource = [
  {
    src: BungeeJumping,
    name: "Bungee Jumping",
    link: "haridwar",
    subText: "Rishikesh",
  },
  {
    src: KrmptyFalls,
    name: "Kempty Falls",
    link: "haridwar",
    subText: "Mussoorie",
  },
  {
    src: RajajiNationalPark,
    name: "Rajaji National Park",
    link: "haridwar",
    subText: "Shiwalik Ranges",
  },
  {
    src: ValleyOfFlowers,
    name: "Valley Of Flowers",
    link: "haridwar",
    subText: "Chamoli",
  },
]

const festivalsAndEvents = [
  {
    src: KumbhMela,
    name: "Bungee Jumping",
    link: "haridwar",
    subText: "Rishikesh",
  },
  {
    src: Festival2,
    name: "Event In Uttarakhand",
    link: "haridwar",
    subText: "Mussoorie",
  },
  {
    src: Festival3,
    name: "Tehri Lake Festival",
    link: "haridwar",
    subText: "Shiwalik Ranges",
  },
  {
    src: Festival4,
    name: "International Yoga Festival",
    link: "haridwar",
    subText: "Chamoli",
  },
]

const NavigateButton = (props) => {
  return (
    <IconButton {...props} style={{ padding: "0rem" }}>
      <LaunchIcon style={{ color: "#fff" }} />
    </IconButton>
  )
}

const DestinationHome = () => {
  const { path } = useRouteMatch()
  // console.log({ path })
  const dispatch = useDispatch()
  const { setBanner } = useContext(DestinationLayoutContext)

  const [currentPackage, setCurrentPackage] = useState(1)

  let sliderRef = useRef(null)

  const play = () => {
    sliderRef.slickPlay()
  }
  const pause = () => {
    sliderRef.slickPause()
  }

  useLayoutEffect(() => {
    setBanner("home")
  }, [])

  let settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: false,
  }

  const [pilgrimsCardContent, setPilgrimsCardContent] = useState([
    // {
    //   header: "Badrinath",
    //   imageSrc: Badrinath,
    //   videoUrl: "https://www.youtube.com/watch?v=oTTNZvnZkSk",
    // },
    {
      autoPlay: true,
      header: "Rishikesh",
      imageSrc: Rishikesh,
      videoUrl: "https://www.youtube.com/watch?v=oTTNZvnZkSk",
    },
    {
      autoPlay: true,
      header: "Haridwar",
      imageSrc: Haridwar,
      videoUrl: "https://www.youtube.com/watch?v=29JUdDsXCtY",
    },
    {
      autoPlay: true,
      header: "Chardham",
      imageSrc: Chardham,
      videoUrl: "https://www.youtube.com/watch?v=J3pupDbmLg8",
    },
  ])

  const handleAutoPlay = (idx) => {
    let updatedPilgrimList = pilgrimsCardContent.map((p, i) => {
      if (idx == i) {
        return { ...p, autoPlay: true }
      }
      return p
    })

    setPilgrimsCardContent(updatedPilgrimList)
  }

  return (
    <>
      <section className={styles.destinationPageContainer}>
        <article className={styles.contentWithCarousel}>
          <div className={styles.contentCard}>
            <header className={styles.topSectionHeader}>
              Explore The <span>Unexplored</span>
            </header>
            <div>
              From a flavour of culture to a dash of adventure, Uttarakhand
              tourism offers something for everyone. A truly diverse state in
              the north of India, whether you are looking to ignite your
              spiritual fire, meander in its lush green meadows or explore the
              indigenous wildlife, Uttarakhand is the perfect destination for
              you.
            </div>
          </div>

          <div className="slider-container">
            <Slider
              {...settings}
              className="slider"
              ref={(slider) => (sliderRef = slider)}
            >
              {sliderImages.map((image, idx) => (
                <div className="slider-img-container" key={idx}>
                  <img {...image} />
                </div>
              ))}
            </Slider>
          </div>
        </article>
      </section>

      <section className={styles.whereYouHeaded}>
        <header onClick={() => window.open(`${path}/destination`, "_blank")}>
          Where You Headed In <span>Uttarakhand</span>{" "}
        </header>
        <article className={styles.whereYouHeadedCardContainer}>
          {whereYouHeadedSrc.map((location, idx) => (
            <DestinationCardV2
              className={styles.whereYouHeadedCard}
              key={idx}
              onClick={() => {
                dispatch(handleOpenVideoPlayerModal())
                dispatch(setVideoUrl(location.videoUrl))
              }}
            >
              <DestinationCardV2.Image src={location.src} />
              <DestinationCardV2.Footer>
                {location.name}
              </DestinationCardV2.Footer>
            </DestinationCardV2>
          ))}
          {/* <IconButton
            color="primary"
            className={styles.navigatorArrow}
            onClick={() => window.open("/uttarakhand/destination", "_blank")}
          >
            <ArrowForwardIosIcon style={{ color: "#fff" }} />
          </IconButton> */}
        </article>
      </section>

      <section className={styles.hillStationContainer}>
        <header className={styles.hillStationHeader}>
          Hills Stations in <span>Uttarakhand</span>
        </header>
        <div className={styles.hillStationDescription}>
          Uttarakhand, a region famed for its breathtaking hill stations that
          have everything needed for the ideal touching destinations, is dotted
          with lengthy mountain ranges and sparkling gems that grace the globe
          map. Wonderful views of the snow-capped Himalayas, the surrounding
          mountains, the meandering slopes, the pure natural appeal, and the
          uphill routes bordered by thick flora.
        </div>

        <div className={styles.hillStationCardList}>
          <div
            style={{
              width: "800px",
              background: "#fff",
              aspectRatio: "16/9",
              padding: "0.5rem",
              borderRadius: "0.5rem",
            }}
          >
            <ReactPlayer
              url={"https://www.youtube.com/watch?v=7Bk-l43VZR8"}
              width="100% !important"
              height="100%"
              light={false}
              playing={false}
              style={{ width: "100%", borderRadius: "0.5rem !important" }}
              playIcon={
                <YoutubeIcon
                  style={{
                    width: "4rem",
                    boxSizing: "border-box",
                  }}
                />
              }
            />
          </div>
        </div>
      </section>

      {/* <section className={styles.thingsTodoContainer}>
        <header onClick={() => window.open("/uttarakhand/tourism", "_blank")}>
          Tourism In <span>Uttarakhand</span>{" "}
        </header>
        <div
          className={styles.pilgrimsDescription}
          style={{ textAlign: "left", padding: "0rem" }}
        >
          From a flavour of culture to a dash of adventure, Uttarakhand tourism
          offers something for everyone. A truly diverse state in the north of
          India, whether you are looking to ignite your spiritual fire, meander
          in its lush green meadows or explore the indigenous wildlife,
          Uttarakhand is the perfect destination for you.
        </div>
        <article className={styles.thingsTodoCardContainer}>
          {thingsTodoSource.map((things, idx) => (
            <DestinationCardV2 className={styles.thingsTodoCard} key={idx}>
              <DestinationCardV2.Image src={things.src} />
              <DestinationCardV2.Footer>
                {things.name} <p>{things.subText}</p>
              </DestinationCardV2.Footer>
            </DestinationCardV2>
          ))}
    
        </article>
      </section> */}
      <section className={styles.pilgrimsContainer}>
        <header className={styles.pilgrimsHeader}>
          Religious Places in <span>Uttarakhand</span>
        </header>
        <div className={styles.pilgrimsDescription}>
          Uttarakhand, whose historic temples grace misty alpine views, provides
          pilgrims with a heavenly haven amidst the majesty and peace of the
          natural world. Every step you take echoes with the sounds of
          dedication and reverence, from the sacred waters of the Ganges in
          Haridwar to the hallowed sanctuaries of Char Dham.
        </div>

        <div className={styles.pilgrimsCardList}>
          {pilgrimsCardContent.map((p, idx) => (
            <DestinationCardV2
              key={`${p.header}-${idx}`}
              className={styles.pilgrimsCard}
              onClick={(e) => {
                e.stopPropagation()
                dispatch(handleOpenVideoPlayerModal())
                dispatch(setVideoUrl(p.videoUrl))
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  pointerEvents: "none",
                }}
                key={p.videoUrl}
              >
                <ReactPlayer
                  url={p.videoUrl}
                  width="100%"
                  height="100%"
                  light={p.autoPlay}
                  playing={false}
                  playIcon={
                    <YoutubeIcon
                      style={{
                        width: "4rem",
                        boxSizing: "border-box",
                      }}
                    />
                  }
                />
              </div>
              {/* <DestinationCardV2.Footer>{p.header}</DestinationCardV2.Footer> */}
            </DestinationCardV2>
          ))}
          {/* <DestinationCardV2 className={styles.pilgrimsCard}>
            <DestinationCardV2.Image src={Rishikesh} />
            <DestinationCardV2.Footer>Rishikesh</DestinationCardV2.Footer>
          </DestinationCardV2>
          <DestinationCardV2 className={styles.pilgrimsCard}>
            <DestinationCardV2.Image src={Haridwar} />
            <DestinationCardV2.Footer>Haridwar</DestinationCardV2.Footer>
          </DestinationCardV2>
          <DestinationCardV2 className={styles.pilgrimsCard}>
            <DestinationCardV2.Image src={Chardham} />
            <DestinationCardV2.Footer>Chardham</DestinationCardV2.Footer>
          </DestinationCardV2> */}
        </div>
      </section>

      <PlanningNextTripBanner />

      <section className={styles.thingsTodoContainer}>
        <div className={styles.thingsTodoBanner}>
          <img src={ThingsTodoBanner} />
        </div>
        <main className={styles.thingsTodoMain}>
          <header
            onClick={() => window.open("/uttarakhand/thingsToDo", "_blank")}
          >
            Things To Do In <span>Uttarakhand</span>{" "}
          </header>
          <div style={{ color: "#FFF" }}>
            <>
              Uttarakhand offers diverse activities, from thrilling adventures
              like rafting and trekking to serene experiences like yoga and
              evening aartis, these are things to do in uttarakhand. Wildlife
              enthusiasts can explore Jim Corbett and Rajaji National Parks,
              while cultural sites like the Char Dham Yatra and Beatles Ashram
              add to its appeal.
            </>
            <ul className={styles.thingsTodoList}>
              <li>
                <DoneOutlined
                  style={{
                    background: "rgba(46, 179, 36, 1)",
                    borderRadius: "50%",
                    width: "1rem",
                    height: "1rem",
                  }}
                />
                <span>Rafting</span>
              </li>
              <li>
                <DoneOutlined
                  style={{
                    background: "rgba(46, 179, 36, 1)",
                    borderRadius: "50%",
                    width: "1rem",
                    height: "1rem",
                  }}
                />
                <span>Trekking</span>
              </li>
              <li>
                <DoneOutlined
                  style={{
                    background: "rgba(46, 179, 36, 1)",
                    borderRadius: "50%",
                    width: "1rem",
                    height: "1rem",
                  }}
                />
                <span>Bungee Jumping</span>
              </li>
              <li>
                <DoneOutlined
                  style={{
                    background: "rgba(46, 179, 36, 1)",
                    borderRadius: "50%",
                    width: "1rem",
                    height: "1rem",
                  }}
                />
                <span>Camping</span>
              </li>
              <li>
                <DoneOutlined
                  style={{
                    background: "rgba(46, 179, 36, 1)",
                    borderRadius: "50%",
                    width: "1rem",
                    height: "1rem",
                  }}
                />
                <span>Jungle Safari</span>
              </li>
              <li>
                <DoneOutlined
                  style={{
                    background: "rgba(46, 179, 36, 1)",
                    borderRadius: "50%",
                    width: "1rem",
                    height: "1rem",
                  }}
                />
                <span>Water Sports</span>
              </li>
            </ul>
          </div>
          <Button
            size="small"
            style={{
              alignSelf: "flex-start",
              background:
                "linear-gradient(110deg,hsla(57, 100%, 50%, 1),hsla(21, 90%, 55%, 1))",
            }}
            onClick={() => window.open("/uttarakhand/thingsToDo", "_blank")}
          >
            See More
          </Button>
        </main>

        {/* <div
          className={styles.pilgrimsDescription}
          style={{ textAlign: "left", padding: "0rem" }}
        >
          Uttarakhand, whose historic temples grace misty alpine views, provides
          pilgrims with heavenly haven amidst the majesty and peace of the
          antural world. Every step you take echoes with the sounds of
          dedication and reverence, in Haridwar to teh hallowed sancturies of
          Char Dham.
        </div>
        <article className={styles.thingsTodoCardContainer}>
          {thingsTodoSource.map((things, idx) => (
            <DestinationCardV2 className={styles.thingsTodoCard} key={idx}>
              <DestinationCardV2.Image src={things.src} />
              <DestinationCardV2.Footer>
                {things.name} <p>{things.subText}</p>
              </DestinationCardV2.Footer>
            </DestinationCardV2>
          ))}
          <IconButton
            color="primary"
            className={styles.navigatorArrow}
            onClick={() => window.open("/uttarakhand/thingsToDo", "_blank")}
          >
            <ArrowForwardIosIcon style={{ color: "#fff" }} />
          </IconButton>
        </article> */}
      </section>

      <section className={styles.bestPlacesToVisit}>
        {/* <header onClick={() => window.open(`${path}/placesToStay`, "_blank")}>
          Best Places <span>To Visit</span>{" "}
        </header>
        <article className={styles.bestPlacestoVisitCardContainer}>
          {thingsTodoSource.map((item, idx) => (
            <DestinationCardV2
              className={styles.bestPlacestoVisitCard}
              key={`d-${idx}`}
            >
              <DestinationCardV2.Image src={item.src} />
              <DestinationCardV2.Footer>
                {item.name} <p>{item.subText}</p>
              </DestinationCardV2.Footer>
            </DestinationCardV2>
          ))}
          <IconButton
            color="primary"
            className={styles.navigatorArrow}
            onClick={() => window.open("/uttarakhand/placesToStay", "_blank")}
          >
            <ArrowForwardIosIcon style={{ color: "#fff" }} />
          </IconButton>
        </article> */}

        <div className={styles.bestPlacesToVisitContent}>
          <header>
            Best Places To Visit In <span>Uttarakhand</span>
          </header>
          <div>
            Uttarakhand offers diverse tourist attractions, including the
            bustling capital Dehradun, the picturesque hill stations of
            Mussoorie, Nainital, and Ranikhet, & the adventure-filled Rishikesh,
            the best places to visit in uttarakhand. Scenic spots like Auli,
            Almora, & the Valley of Flowers, along with lesser-known gems like
            Dhanaulti & Kausani, provide a perfect mix of adventure, relaxation,
            & cultural experiences.
            <p>
              <Link
                to="/uttarakhand/placesToStay"
                target="blank"
                style={{
                  color: "#f46d25",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                See More {">"}
              </Link>
            </p>
          </div>
        </div>

        <Grid container spacing={2}>
          <Grid item md={3}>
            <img src={Image1} style={{ width: "100%" }} />
          </Grid>
          <Grid item container md={3} rowSpacing={2}>
            <Grid item md={12}>
              <img style={{ width: "100%" }} src={Image6} />
            </Grid>
            <Grid item md={12}>
              <img style={{ width: "100%" }} src={Image2} />
            </Grid>
          </Grid>
          <Grid item container md={6} spacing={2}>
            <Grid item md={12}>
              <img style={{ width: "100%" }} src={Image3} />
            </Grid>
            <Grid item container md={12} spacing={2}>
              <Grid item md={6}>
                <img style={{ width: "100%" }} src={Image4} />
              </Grid>
              <Grid item md={6}>
                <img style={{ width: "100%" }} src={Image5} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>

      <section className={styles.keyfactsContainer}>
        <article className={styles.keyFactsArticle}>
          <header>
            Key Facts About <span>Uttarakhand</span>
          </header>
          <div style={{ color: "#fff", boxSizing: "border-box" }}>
            Uttarakhand, known for its rich natural beauty and cultural
            significance, became a state in 2000 and spans 53,483 km², with 86%
            mountainous terrain and 65% forest cover. Dehradun is the capital,
            and the key facts about Uttarakhand state features UNESCO World
            Heritage sites like Nanda Devi and Valley of Flowers National Park.
            It has a population of over 10 million, primarily Hindu, and
            experiences diverse weather.
          </div>
          <div className={styles.keyFactsCardContainer}>
            {Keyfacts.map((fact, index) => (
              <div className={styles.keyFactsCard} key={index}>
                <div className={styles.factHeader}>{fact.label}</div>
                <div className={styles.factContent}>
                  {htmlParser(fact.content)}
                </div>
              </div>
            ))}
            <div className={styles.keyFactsSeeAll}>
              <Link
                to="uttarakhand/keyFacts"
                target="blank"
                style={{ color: "inherit" }}
              >
                See All
              </Link>
            </div>
          </div>
        </article>
      </section>

      <section className={styles.howToReach}>
        <div className={styles.howToReachContent}>
          <div className={styles.sectionSectionOne}>
            <header>
              How To Reach <span>UttaraKhand</span>
            </header>
            <div>
              Uttarakhand is easily accessible by air, road, and rail, with two
              major airports in Dehradun and Pithoragarh, well-connected
              highways, and numerous railway stations linking major Indian
              cities how to reach in uttarakhand. Travelers from Delhi, Mumbai,
              Bangalore, and Hyderabad can reach Uttarakhand via direct flights,
              trains, or buses, with Delhi offering the most convenient and
              quickest options.
            </div>

            <Button
              // size="small"
              style={{
                boxShadow: "0rem 0rem 0rem rgb(0,0,0,0.1)",
                alignSelf: "flex-start",
                background:
                  "linear-gradient(110deg,rgba(255, 243, 0, 1),rgba(244, 109, 37, 1))",
                backdropFilter: "blur(1rem)",
              }}
              onClick={() => window.open("/uttarakhand/howToReach", "_blank")}
            >
              Learn More
            </Button>
          </div>

          <div className={styles.sectionSectionTwo}>
            <img src={HowToReachBG} />
            <img src={HowToReachModes} />
          </div>
        </div>
      </section>

      <section className={styles.thingsToCarry}>
        <div className={styles.thingsToCarrySectionOne}>
          <img src={ThingToCarryBG} />
          <div className={styles.bubbleOne}></div>
          <div className={styles.bubbleTwo}></div>
          <div className={styles.bubbleThree}></div>
          <div className={styles.bubbleFour}></div>
        </div>
        <div className={styles.thingsToCarrySectionTwo}>
          <header>
            Things To Carry In <span>Uttarakhand</span>
          </header>
          <div>
            When packing for a trip to Uttarakhand, essentials include valid
            identification documents, weather-appropriate clothing (cotton for
            summers, woolens for winters),  Additionally,things to carry in
            uttarakhand personal hygiene items, electronics with chargers,
            bottled water, and cash.
          </div>
          <ul className={styles.thingsToCarryItems}>
            <li>
              <div className={styles.thingsToCarryCard}>
                <img src={DocumentIcon} />
                <p>Documents</p>
              </div>
            </li>
            <li>
              <div className={styles.thingsToCarryCard}>
                <img src={ClothingIcon} />
                <p>Clothing</p>
              </div>
            </li>
            <li>
              <div className={styles.thingsToCarryCard}>
                <img src={MoneyIcon} />
                <p>Cash</p>
              </div>
            </li>
            <li>
              <div className={styles.thingsToCarryCard}>
                <img src={HeartRateIcon} />
                <p>Health & Hygiene Products</p>
              </div>
            </li>
          </ul>

          <Button
            // size="small"
            style={{
              boxShadow: "0rem 0rem 0rem rgb(0,0,0,0.1)",
              alignSelf: "flex-start",
              background:
                "linear-gradient(110deg,rgba(255, 243, 0, 1),rgba(244, 109, 37, 1))",
              backdropFilter: "blur(1rem)",
            }}
            onClick={() => window.open("/uttarakhand/thingsToCarry", "_blank")}
          >
            See More
          </Button>
        </div>
      </section>

      <section className={styles.festivalsAndEvents}>
        <header>
          Festivals & Events In <span>Uttarakhand</span>
        </header>
        <div className={styles.festivalsContent}>
          Uttarakhand hosts a vibrant array of festivals, including the Nanda
          Devi Mela, Kumbh Mela, Ganga Dussehra, International Yoga Festival,
          Kanwar Yatra, Magh Mela, and Holi. These  festivals & events in
          Uttarakhand celebrate spirituality, culture, and tradition, offering
          visitors a glimpse into the rich heritage of the region.
        </div>

        <div className={styles.festivalsCardContainer}>
          <div className={styles.festivalCard}>
            <img src={Festival2} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <center>
              {" "}
              <Button
                // size="small"
                style={{
                  boxShadow: "0rem 0rem 0rem rgb(0,0,0,0.1)",

                  background:
                    "linear-gradient(110deg,rgba(255, 243, 0, 1),rgba(244, 109, 37, 1))",
                  backdropFilter: "blur(1rem)",
                }}
                onClick={() =>
                  window.open("/uttarakhand/festivalsAndEvents", "_blank")
                }
              >
                See More
              </Button>
            </center>
            <div className={styles.festivalCard}>
              <img src={Festival3} />
            </div>
          </div>
          <div className={styles.festivalCard}>
            <img src={Festival4} />
          </div>
        </div>
      </section>

      <section className={styles.shoppingAndEntertainment}>
        <div className={styles.shoppingContentContainer}>
          {" "}
          <div className={styles.shoppingAndEntertainmentContent}>
            <header>
              Shopping & Entertainment In <span>Uttarakhand</span>
            </header>
            <div>
              Uttarakhand offers diverse shopping & entertainment in
              Uttarakhand, from Paltan Bazar in Dehradun for hand-woven products
              to Mall Road in Mussoorie for local fabrics and electronics. Bara
              Bazar in Nainital is renowned for woollens and candles, while
              Chowk Bazar in Almora is famous for its copperware.
            </div>
          </div>
          <Button
            // size="small"
            style={{
              boxShadow: "0rem 0rem 0rem rgb(0,0,0,0.1)",

              background:
                "linear-gradient(110deg,rgba(255, 243, 0, 1),rgba(244, 109, 37, 1))",
              backdropFilter: "blur(1rem)",
              alignSelf: "flex-start",
            }}
            onClick={() => window.open("/uttarakhand/tourPackages", "_blank")}
          >
            See More
          </Button>
        </div>

        <div className={styles.mapContainer}>
          <img src={StateMap} />
          <div className={styles.locationCard}>
            <div>
              <div>Paltan Bazaar</div>
              <small>DehraDun</small>
            </div>

            <img src={PaltanBazaar} />
          </div>
        </div>

        <div className={styles.locationCard}>
          <div>
            <div>Mall Road</div>
            <small>Massoorie</small>
          </div>
          <img src={MallRoad} />
        </div>

        <div className={styles.locationCard}>
          <div>
            <div>Chowk Bazaar</div>
            <small>Almora</small>
          </div>
          <img src={ChowkBazaar} />
        </div>

        <div className={styles.locationCard}>
          <div>
            <div>Bara Bazaar</div>
            <small>Nainital</small>
          </div>
          <img src={BaraBazaar} />
        </div>
      </section>

      <section className={styles.tourPackages}>
        <header>
          Tour Packages <span>Uttarakhand</span>
        </header>
        <div style={{ width: "70%", textAlign: "center" }}>
          Uttarakhand tour packages offer a diverse travel experience with
          scenic hill stations, culturally rich pilgrimage sites, and thrilling
          adventure activities. Whether exploring Nainital's lakes, trekking in
          the Himalayas, or rafting in Rishikesh, these packages cater to all
          types of travelers.
        </div>
        <div className={styles.tourPackagesContent}>
          <div
            className={styles.tourPackageCard}
            data-currentPackage={currentPackage == 0}
            onMouseOver={() => setCurrentPackage(0)}
          >
            <img src={PackageImage} />
            <div className={styles.packageCardContent}>
              <div style={{ padding: "1rem 0rem" }}>
                <small>3 Night 4 Days</small>
                <p style={{ margin: "0rem", fontWeight: "600" }}>
                  Mussoorie Packages
                </p>
              </div>
              <small>
                Choose a plan and get onboard in minutes. Then get ₹1000 credits
                for your next payment.
              </small>

              <IconButton
                size="small"
                style={{ alignSelf: "flex-start", padding: "0rem" }}
              >
                <ArrowForwardIos style={{ width: "1rem" }} />
              </IconButton>
            </div>
          </div>

          <div
            className={styles.tourPackageCard}
            data-currentPackage={currentPackage == 1}
            onMouseOver={() => setCurrentPackage(1)}
          >
            <img src={PackageImage} />
            <div className={styles.packageCardContent}>
              <div style={{ padding: "1rem 0rem" }}>
                <small>3 Night 4 Days</small>
                <p style={{ margin: "0rem", fontWeight: "600" }}>
                  Mussoorie Packages
                </p>
              </div>
              <small>
                Choose a plan and get onboard in minutes. Then get ₹1000 credits
                for your next payment.
              </small>

              <IconButton
                size="small"
                style={{ alignSelf: "flex-start", padding: "0rem" }}
              >
                <ArrowForwardIos style={{ width: "1rem" }} />
              </IconButton>
            </div>
          </div>

          <div
            className={styles.tourPackageCard}
            data-currentPackage={currentPackage == 2}
            onMouseOver={() => setCurrentPackage(2)}
          >
            <img src={PackageImage} />
            <div className={styles.packageCardContent}>
              <div style={{ padding: "1rem 0rem" }}>
                <small>3 Night 4 Days</small>
                <p style={{ margin: "0rem", fontWeight: "600" }}>
                  Mussoorie Packages
                </p>
              </div>
              <small>
                Choose a plan and get onboard in minutes. Then get ₹1000 credits
                for your next payment.
              </small>

              <IconButton
                size="small"
                style={{ alignSelf: "flex-start", padding: "0rem" }}
              >
                <ArrowForwardIos style={{ width: "1rem" }} />
              </IconButton>
            </div>
          </div>
        </div>
        <center style={{ marginTop: "3rem" }}>
          <Button
            // size="small"
            style={{
              boxShadow: "0rem 0rem 0rem rgb(0,0,0,0.1)",

              background:
                "linear-gradient(110deg,rgba(255, 243, 0, 1),rgba(244, 109, 37, 1))",
              backdropFilter: "blur(1rem)",
            }}
            onClick={() => window.open("/uttarakhand/tourPackages", "_blank")}
          >
            See More
          </Button>
        </center>
      </section>
    </>
  )
}

export default DestinationHome
