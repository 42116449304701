import { Link } from "react-router-dom"
import HotelCard from "../HotelCard/HotelCard.component"
import "./MostPopularHotels.styles.css"

/**Image Import */
import SantegoBeach from "../../assets/illustrations/hotels_mp/Santego Beach Resort.png"
import GiriVihar from "../../assets/illustrations/hotels_mp/Giri Vihar.png"
import GoldenTulip from "../../assets/illustrations/hotels_mp/Golden Tulip.png"
import MontegoBay from "../../assets/illustrations/hotels_mp/Montego Bay.png"
const locationImages = [
  {
    imageUrl: SantegoBeach,
    location: "Goa",
    hotelName: "Santana Beach Resort",
    rating: "3.5",
    reviews: "100",
    price: 5800,
  },
  {
    imageUrl: MontegoBay,
    location: "Goa",
    hotelName: "Montego Bay",
    rating: "4.5",
    reviews: "90",
    price: 7000,
  },
  {
    imageUrl: GoldenTulip,
    location: "Candolim",
    hotelName: "Hotel Golden Tulip",
    rating: "3.5",
    reviews: "81",
    price: 5500,
  },
  {
    imageUrl: GiriVihar,
    location: "Lonavala",
    hotelName: "Hotel Giri Vihar",
    rating: "4.5",
    reviews: "1000",
    price: 4700,
  },
]

const MostPopularHotels = () => {
  return (
    <section className="section-most-popular-hotels">
      <header>
        <small>Selling Out Fast</small>
        <div className="title-container">
          <div className="primary-text">
            Most Popular <span className="secondary-text">Hotels</span>
          </div>
          <Link>View All</Link>
        </div>
      </header>
      <main>
        <div className="most-popular-hotels-container-home">
          {locationImages.map((l, i) => (
            <HotelCard key={`${l.location}${i}`} {...l} />
          ))}
        </div>
      </main>
    </section>
  )
}
export default MostPopularHotels
