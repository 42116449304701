import "./Hotels.styles.css"
import HotelBanner from "../../assets/illustrations/cropped_hotel_banner.png"
import LocationIcon from "../../assets/icons/input-prefix/location.svg"
import CalenderIcon from "../../assets/icons/input-prefix/calendar.svg"
import UserIcon from "../../assets/icons/input-prefix/user-square.svg"
import Directory from "../../components/Directory/Directory.component"

import MockImage from "../../assets/illustrations/mockImages/GOA.jpg"
import DestinationCard from "../../components/DestinationCard/DestinationCard.component"
import PlaceCard from "../../components/PlaceToStayCard/PlaceToStayCard.component"
import ItemDirectory from "../../components/ItemDirectory/ItemDirectory.component"

import PImage1 from "../../assets/illustrations/hotels_mp/MP_Image_1.png"
import PImage2 from "../../assets/illustrations/hotels_mp/MP_Image_2.png"
import PImage3 from "../../assets/illustrations/hotels_mp/MP_Image_3.png"
import PImage4 from "../../assets/illustrations/hotels_mp/MP_Image_4.png"
import PImage5 from "../../assets/illustrations/hotels_mp/MP_Image_5.png"
import PImage6 from "../../assets/illustrations/hotels_mp/MP_Image_6.png"
import PImage7 from "../../assets/illustrations/hotels_mp/MP_Image_7.png"
import HotelCard from "../../components/HotelCard/HotelCard.component"
import { useContext } from "react"
import { ContactModalContext } from "../../context/contactModal.context"

const hotelSearchFields = [
  {
    name: "location",
    placeholder: "Where are you going?",
    type: "text",
    prefix: LocationIcon,
  },
  {
    name: "month",
    placeholder: "Month",
    type: "text",
    prefix: CalenderIcon,
  },

  {
    name: "duration",
    placeholder: "How Long ?",
    type: "text",
    prefix: CalenderIcon,
  },
  {
    name: "guests",
    placeholder: "Guests",
    type: "text",
    prefix: UserIcon,
  },
]

const mostPopularHotels = [
  {
    imageUrl: PImage1,
    location: "Goa",
    hotelName: "Santana Beach Resort",
    rating: "3.5",
    reviews: "100",
  },
  {
    imageUrl: PImage2,
    location: "Goa",
    hotelName: "Montego Bay",
    rating: "4.5",
    reviews: "90",
  },
  {
    imageUrl: PImage3,
    location: "Candolim",
    hotelName: "Hotel Golden Tulip",
    rating: "3.5",
    reviews: "81",
  },
]

const mostPopularVillas = [
  {
    imageUrl: PImage7,
    location: "Goa",
    hotelName: "Santana Beach Resort",
    rating: "3.5",
    reviews: "100",
  },
  {
    imageUrl: PImage5,
    location: "Goa",
    hotelName: "Montego Bay",
    rating: "4.5",
    reviews: "90",
  },
  {
    imageUrl: PImage4,
    location: "Candolim",
    hotelName: "Hotel Golden Tulip",
    rating: "3.5",
    reviews: "81",
  },
]

const mostPopularResorts = [
  {
    imageUrl: PImage2,
    location: "Goa",
    hotelName: "Santana Beach Resort",
    rating: "3.5",
    reviews: "100",
  },
  {
    imageUrl: PImage3,
    location: "Goa",
    hotelName: "Montego Bay",
    rating: "4.5",
    reviews: "90",
  },
  {
    imageUrl: PImage5,
    location: "Candolim",
    hotelName: "Hotel Golden Tulip",
    rating: "3.5",
    reviews: "81",
  },
]

const Hotels = () => {
  const { toggleModal } = useContext(ContactModalContext)
  return (
    <section className="hotel-page-container">
      <article className="illustration-container">
        <img src={HotelBanner} className="hotels-page-banner" />
        <div className="hotel-banner-info-container">
          <div className="hotel-banner-header">
            Experience the Best Luxury Hotels
          </div>
          <div className="hotel-search-fields">
            {hotelSearchFields.map((field) => (
              <span className="search-field" key={field.name}>
                <img src={field.prefix} height="auto" />
                <input {...field} />
              </span>
            ))}
            <button className="btn-search-hotels" onClick={toggleModal}>
              Search
            </button>
          </div>
        </div>
      </article>
      <article className="hotels-body-container">
        <Directory />
        <div className="hotel-description-now">
          Looking for the best hotels in Goa, Uttarakhand, Himachal, Dubai, and
          Thailand at unbeatable rates? Look no further! Towno hotel network
          grants you exclusive access to a curated selection of top-tier
          accommodations in these sought-after destinations. Whether you're
          craving the serene mountains of Uttarakhand, the pristine beaches of
          Goa, or the cosmopolitan vibes of Dubai, we've got you covered. With
          our extensive network, finding the perfect stay is a breeze. From
          luxury resorts to cozy boutique hotels, discover unparalleled comfort
          and convenience. Book now and experience the ultimate in hospitality
          while enjoying the best hotel rates available!
        </div>
      </article>
      <article className="most-popular-destinations">
        <header className="most-popular-destination-header">
          Most Popular Destinations
        </header>
        <div className="most-pop-img-container">
          {Array.from({ length: 7 }, (_, i) => (
            <DestinationCard src={MockImage} destination={"Goa"} />
          ))}
        </div>
      </article>

      <article className="places-to-stay-container">
        <header className="places-to-stay-header">Places To Stay</header>
        <div className="places-to-stay-items">
          <PlaceCard img={MockImage} place={"Hotel"} />
          <PlaceCard img={MockImage} place={"Hotel"} />
          <PlaceCard img={MockImage} place={"Hotel"} />
          <PlaceCard img={MockImage} place={"Hotel"} />
        </div>
      </article>
      <ItemDirectory
        primaryHeader={"Most Popular"}
        secondaryHeader={"Hotels"}
        Component={HotelCard}
        data={mostPopularHotels}
      />
      <ItemDirectory
        primaryHeader={"Most Popular"}
        secondaryHeader={"Villas"}
        Component={HotelCard}
        data={mostPopularVillas}
      />
      <ItemDirectory
        primaryHeader={"Most Popular"}
        secondaryHeader={"Resorts"}
        Component={HotelCard}
        data={mostPopularResorts}
      />

      <ItemDirectory
        primaryHeader={"Most Popular"}
        secondaryHeader={"Hostels"}
        Component={HotelCard}
        data={mostPopularHotels}
      />
    </section>
  )
}

export default Hotels
