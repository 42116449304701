import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { useEffect, useState } from "react";

import { format } from "date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MultiLineChartHotelLogin = ({
  hotelResponseData,
  townoResponseData,
  type,
}) => {
  console.log("hotelData", hotelResponseData, townoResponseData);

  let data = {
    // labels,
    datasets: [
      {
        label: type === "nights" ? "Hotel Room Nights" : "Hotel Revenue",
        data: hotelResponseData,
        borderColor: "#f46d25",
        backgroundColor: "#f46d2555",
        yAxisID: "y",
      },
      {
        label: type === "nights" ? "Towno Room Nights" : "Towno Revenue",
        data: townoResponseData,
        borderColor: "#00aa00",
        backgroundColor: "#00aa0055",
        yAxisID: "y",
      },
    ],
  };

  const options = {
    responsive: true,
    elements: {
      line: {
        tension: 0.5, // disables bezier curves
      },
    },
    maintainAspectRatio: false,
    interaction: {
      // mode: 'index' as const,
      mode: "index",
      intersect: false,
    },
    stacked: false,
    scales: {
      y: {
        //   type: 'linear' as const,
        type: "linear",
        display: true,
        // position: 'left' as const,
        position: "left",
        ticks: {
          color: "#f46d25",
        },
      },
      y1: {
        // type: 'linear' as const,
        type: "linear",
        display: true,
        // position: 'right' as const,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          color: "#00aa00",
        },

        rtl: true,
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default MultiLineChartHotelLogin;
