import { useContext } from "react"
import styles from "./NavIcon.module.css"
import { SideNavbarContext } from "../../context/sideNavBarContext"

const NavIcon = () => {
  const { openSideNavbar, toggleNavBar } = useContext(SideNavbarContext)

  return (
    <button
      className={styles.iconContainer}
      onClick={toggleNavBar}
      data-isOpen={openSideNavbar}
    >
      <div className={styles.bar}></div>
      <div className={styles.bar}></div>
      <div className={styles.bar}></div>
    </button>
  )
}

export default NavIcon
