import { useContext } from "react"
import styles from "./PlaceToStayCard.module.css"
import { ContactModalContext } from "../../context/contactModal.context"

const PlaceCard = ({ img, place, className }) => {
  const { toggleModal } = useContext(ContactModalContext)
  return (
    <div className={`${styles.placeCard} ${className}`} onClick={toggleModal}>
      <img src={img} className={styles.cardImage} />
      <div className={styles.content}>
        <span className={styles.place}>{place}</span>
      </div>
    </div>
  )
}

export default PlaceCard
