import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Edit, EditAttributes, SearchOutlined } from "@material-ui/icons";
import { Grid } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import API from "../../Service/Api";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0px",
    padding: "60px 20px 20px 25px ",

    "& .MuiOutlinedInput-root": {
      width: "100%",
      "&.Mui-focused fieldset": {
        border: "1px solid black",
        width: "100%",
        transition: "width 4s",
      },
    },
  },
}));

const tableHead = [
  "Sl No.",
  "Itinerary Name",
  "Destination",
  "Created At",
  "Created By",
  "Action",
];

const ItineraryList = () => {
  const classes = useStyles();
  const history = useHistory();
  const uniqueId = localStorage.getItem("unique_id");
  const [loading, setLoading] = useState(false);
  const [itineraryList, setItineraryList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { currentUser } = useSelector(({ userReducer }) => userReducer);

  console.log(currentUser);

  const fetchItineraryList = async () => {
    setLoading(true);
    try {
      const { data } = await API.get(`getitinerary/${uniqueId}`);
      console.log(data);
      setItineraryList(data);
      setLoading(false);
    } catch (error) {
      let errorMessage;
      const { message } = error;
    }
  };

  useEffect(() => {
    fetchItineraryList();
  }, []);

  const handleItineraryRoutes = (itineraryId, path) => () => {
    history.push(`/${path}/${itineraryId}`);
  };

  const filteredItineraryList = itineraryList?.filter((itinerary) =>
    itinerary?.itineraryName?.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <section className={classes.root}>
      <Grid container margin="20px 0px" justifyContent="space-between">
        <Grid item xs={8}>
          <Typography
            color="primary"
            variant="h4"
            style={{ fontWeight: "bold" }}
          >
            Itinerary List
          </Typography>
        </Grid>
        <Grid item container xs={4} spacing={3} alignItems="center">
          <Grid item xs={8} textAlign="right">
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search by Itinerary Name"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              onClick={() => history.push("/itineraryBuilder")}
              size="small"
            >
              Build New Itinerary
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead style={{ background: "#f46d25" }}>
            <TableRow>
              {tableHead.map((header, idx) => (
                <TableCell align="left" style={{ color: "white" }} key={idx}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItineraryList.map((itinerary) => (
              <TableRow>
                <TableCell>{itinerary.id}</TableCell>
                <TableCell>{itinerary.itineraryName}</TableCell>
                <TableCell>{itinerary.itineraryDestination}</TableCell>
                <TableCell>
                  {format(new Date(itinerary.createdAt), "dd MMMM, yyyy")}
                </TableCell>
                <TableCell>{itinerary.createdBy}</TableCell>
                <TableCell>
                  <Grid container>
                    <Grid item xs={3}>
                      <Tooltip title="Edit Itinerary">
                        <IconButton
                          style={{ padding: "0px" }}
                          size="small"
                          onClick={handleItineraryRoutes(
                            itinerary.id,
                            "itineraryBuilder"
                          )}
                        >
                          <Edit color="primary" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                      <Tooltip title="Edit Itinerary">
                        <IconButton
                          style={{ padding: "0px" }}
                          size="small"
                          onClick={handleItineraryRoutes(
                            itinerary.id,
                            "itineraryVoucher"
                          )}
                        >
                          <EditAttributes color="primary" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {(itineraryList.length < 1 || loading == true) && (
        <Grid container textAlign="center" padding="10px">
          <Paper style={{ width: "100%", padding: "5px", fontWeight: "bold" }}>
            No Records Found !!!
          </Paper>
        </Grid>
      )}
    </section>
  );
};

export default ItineraryList;
