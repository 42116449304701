import DateFnsUtils from "@date-io/date-fns"
import { IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { SearchTwoTone } from "@material-ui/icons"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { styles } from "@material-ui/pickers/views/Calendar/Calendar"
import {
  Box,
  Dialog,
  Grid,
  InputAdornment,
  Slide,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material"
import { format } from "date-fns"
import PropTypes from "prop-types"
import React, { useEffect, useReducer, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Api from "../../Service/Api"
import { getPaymentModeOptions } from "../../redux/actions/commonFeatureDropdown.action"
import { twnButtonStyles } from "../../utils/townoStyle"
import AgentFlightForm from "./AgentFlightForm"
import AgentTrainForm from "./AgentTrainForm"
import BasicDetailPage from "./BasicDetailPage"
import FlightForm from "./FlightForm"
import PreviewPage from "./PreviewPage"
import RoadForm from "./RoadForm"
import TermsCancelConditions from "./TermsCancelConditions"
import TrainForm from "./TrainForm"
import TransactionSummary from "./TransactionSummary"
import TransactionSummaryCopy from "./TransactionSummary copy"
import TransferClientForm from "./TransferClientForm"
import TransferFormMobileUI from "./TransferFormMobileUI"
import TransferRefund from "./TransferRefund"
import _ from "lodash"
// import HotelDetailsForm from "../Transfer_2.0/HotelDetailsForm"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1, m: -1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value,
  }
}

const useStyles = makeStyles(() => ({
  root: {
    // padding: "100px 0px",
    // margin: "0px 30px",
    padding: "5% 0.5% 1% 1.5%",
    "@media (max-width: 767px)": {
      margin: "0px 10px",
    },
  },
  icon: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    position: "absolute",
    right: "15px",
    top: "5px",
    color: "#f46d25",
  },
  plus: {
    cursor: "pointer",
    color: "#f46d25",
    position: "relative",
    top: "7px",
    "@media (max-width: 767px)": {
      fontSize: "18px",
    },
  },
  title: {
    fontWeight: "600",
    fontSize: "18px",
    color: "#f46d25",
    margin: "0px",
  },
  tabStyle: {
    borderBottom: 1,
    borderColor: "#f46d25",
  },
  selectedTab: {
    color: "#fff",
    backgroundColor: "#f46d25",
    fontWeight: "bold",
    borderRadius: "5px",
    boxShadow: "1px 1px 7px rgb(96 43 15 /0",
  },
  nextBtn: {
    fontSize: "15px",
    fontWeight: "bold",
    background: "#f46d25",
    border: 0,
    borderColor: "#f46d25",
    cursor: "pointer",
    borderRadius: "10px",
    borderWidth: " thin",
    color: "white",
    height: "30px",
    width: "120px",
    textAlign: "center",
    paddingTop: "0.6em",
  },
  cancelBtn: {
    fontSize: "15px",
    fontWeight: "bold",
    background: "#111",
    border: 0,
    borderColor: "#111",
    cursor: "pointer",
    borderRadius: "10px",
    borderWidth: " thin",
    color: "#fff",
    height: "30px",
    width: "120px",
    textAlign: "center",
    paddingTop: "0.6em",
  },
}))

const travelType = (flightInput, trainInput, cabInput, busInput) => {
  var flightTempAmountArray = flightInput.map((x, i) => {
    return /^ ?\+?\d+$/.test(x.flightAmount) ? x.flightAmount : 0
  })
  var flightTempTotalAmount = flightTempAmountArray.reduce((total, num) => {
    return total + num / 1
  }, 0)
  var trainTempAmountArray = trainInput.map((x, i) => {
    return /^ ?\+?\d+$/.test(x.trainAmount) ? x.trainAmount : 0
  })
  var trainTempTotalAmount = trainTempAmountArray.reduce((total, num) => {
    return total + num / 1
  }, 0)
  var cabTempAmountArray = cabInput.map((x, i) => {
    return /^ ?\+?\d+$/.test(x.cabAmount) ? x.cabAmount : 0
  })
  var cabTempTotalAmount = cabTempAmountArray.reduce((total, num) => {
    return total + num / 1
  }, 0)
  var busTempAmountArray = busInput.map((x, i) => {
    return /^ ?\+?\d+$/.test(x.busAmount) ? x.busAmount : 0
  })
  var busTempTotalAmount = busTempAmountArray.reduce((total, num) => {
    return total + num / 1
  }, 0)
  // console.log("diffAmount: ", flightTempTotalAmount, flightTempTotalAmount > 0);
  var type = ""
  console.log("travelType", type)
  if (flightTempTotalAmount > 0) {
    type += "Flight, "
  }
  if (trainTempTotalAmount > 0) {
    type += " Train, "
  }
  if (cabTempTotalAmount > 0) {
    type += " Cab, "
  }
  if (busTempTotalAmount > 0) {
    type += " Bus"
  }
  return type
}

const checkFields = [
  "clientName",
  "clientMobileNo",
  "flightFrom",
  "flightTo",
  "flightAdults",
  "flightAmount",
  "busCommission",
  "cabCommission",
  "trainComission",
  "flightComission",
  "trainFrom",
  "trainTo",
  "trainAdults",
  "trainAmount",
  "cabFrom",
  "cabTo",
  "cabAdults",
  "cabAmount",
  "busFrom",
  "busTo",
  "busAdults",
  "busAmount",
]

const objectReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value,
  }
}

export default function TravelForm() {
  const dispatch = useDispatch()
  var uniqueid = localStorage.getItem("unique_id")
  let hasAdmin = localStorage.getItem("role")
  let checkAgent = localStorage.getItem("agent")
  var createdBy = localStorage.getItem("auth")
  const history = useHistory()

  let { transferId } = useParams()
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)
  const [openPreview, setOpenPreview] = useState(false)
  const [totalAmount, setTotalAmount] = useState(0)
  const [commission, setCommission] = useState(0)
  const [open, setOpen] = useState(false)
  const [selectedId] = useState(0)
  const [clientLists, setClientLists] = useState([])
  const [isClicked, setIsClicked] = useState(false)
  const clientListFromReducer = useSelector(
    ({ clientList }) => clientList.clientLists
  )
  const [bookingId, setBookingId] = useState("")

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }
  const [formData, setFormData] = useReducer(formReducer, {
    id: "",
    uniqueId: uniqueid,
    transferId: "",
    totalAmount: "",
    commission: "",
    createdBy: createdBy,
    createdAt: format(new Date(), "yyyy-MM-dd hh:mm:ss"),
    updateBy: null,
    updateAt: null,
    transferPaymentDetails: [],
  })

  const [basicInput, setBasicInput] = useState([
    {
      id: "",
      clientName: " ",
      clientMobileNo: " ",
      clientEmail: " ",
      clientAltNo: " ",
    },
  ])
  const [basicInputError, setBasicInputError] = useState([
    { id: "", clientName: " " },
  ])

  const [flightInput, setFlightInput] = useState([
    {
      id: "",
      flightTrip: " ",
      flightName: " ",
      flightFrom: " ",
      flightTo: " ",
      flightDepartDate: "",
      flightReturnDate: "",
      flightPnr: " ",
      flightAdults: " ",
      flightChild: " ",
      flightInclusion: " ",
      flightAmount: "",
      flightComission: "",
    },
  ])

  const [flightInputError, setFlightInputError] = useState([
    {
      id: "",
      flightTrip: " ",
      flightName: " ",
      flightFrom: " ",
      flightTo: " ",
      flightDepartDate: "",
      flightReturnDate: "",
      flightPnr: " ",
      flightAdults: " ",
      flightChild: " ",
      flightInclusion: " ",
      flightAmount: "",
      flightComission: "",
    },
  ])
  const [trainInput, setTrainInput] = useState([
    {
      id: "",
      traintravelClass: " ",
      trainName: " ",
      trainFrom: " ",
      trainTo: " ",
      trainDepartDate: "",
      trainPnr: " ",
      trainAdults: " ",
      trainChild: " ",
      trainInclusion: " ",
      trainAmount: "",
      trainComission: "",
    },
  ])
  const [trainInputError, setTrainInputError] = useState([
    {
      id: "",
      traintravelClass: " ",
      trainName: " ",
      trainFrom: " ",
      trainTo: " ",
      trainDepartDate: "",
      trainPnr: " ",
      trainAdults: " ",
      trainChild: " ",
      trainInclusion: " ",
      trainAmount: "",
      trainComission: "",
    },
  ])
  const [cabInput, setCabInput] = useState([
    {
      id: "",
      cabFrom: " ",
      cabTo: "",
      cabTrip: " ",
      cabType: " ",
      cabVehicle: " ",
      cabAdults: " ",
      cabChild: " ",
      cabAmount: "",
      cabCommission: "",
    },
  ])
  const [cabInputError, setCabInputError] = useState([
    {
      id: "",
      cabFrom: " ",
      cabTo: "",
      cabTrip: " ",
      cabType: " ",
      cabVehicle: " ",
      cabAdults: " ",
      cabChild: " ",
      cabAmount: "",
      cabCommission: "",
    },
  ])
  const [busInput, setBusInput] = useState([
    {
      id: "",
      busName: "",
      busFrom: " ",
      busTo: " ",
      busSeatNo: " ",
      busAdults: " ",
      busChild: " ",
      busAmount: "",
      busCommission: "",
    },
  ])
  const [busInputError, setBusInputError] = useState([
    {
      id: "",
      busName: "",
      busFrom: " ",
      busTo: " ",
      busSeatNo: " ",
      busAdults: " ",
      busChild: " ",
      busAmount: "",
      busCommission: "",
    },
  ])

  const [hotelDetails, setHotelDetails] = useState([
    {
      id: null,
      hotelName: "",
      hotelDestination: "",
      hotelAdults: 0,
      hotelChild: 0,
      hotelCheckIn: null,
      hotelCheckOut: null,
      hotelVendorAmount: 0,
      hotelAmount: 0,
      hotelComission: 0,
    },
  ])

  // transaction summary starts
  const [totalAmountPaid, setTotalAmountPaid] = useState(0)
  const [transferPaymentDetails, setTransferPaymentDetails] = useReducer(
    objectReducer,
    { id: "", paidAmount: "", paymentMode: "", paymentRefNo: "" }
  )
  // transaction summary ends

  //t&c,cancellation
  const [pkgTrmCndData, setPkgTrmCndData] = useReducer(objectReducer, {
    id: "",
    teamCondition: "",
    cancellationPolicy: "",
  })
  //t&c,cancellation

  const handleRemoveClickBasic = (index) => {
    const list = [...basicInput]
    list.splice(index, 1)
    setBasicInput(list)

    const listError = [...basicInputError]
    listError.splice(index, 1)
    setBasicInputError(listError)
  }

  const handleAddClickBasic = () => {
    setBasicInput([
      ...basicInput,
      {
        id: "",
        clientName: " ",
        clientMobileNo: " ",
        clientEmail: " ",
        clientAltNo: " ",
      },
    ])

    setBasicInputError([...basicInputError, { id: "", clientName: " " }])
  }

  const handleBasicChange = (e, index) => {
    const { name, value } = e.target
    const list = [...basicInput]
    list[index][name] = value
    setBasicInput(list)
  }

  // total payment for transaction summary
  useEffect(() => {
    let previousPaidAmt = formData.transferPaymentDetails.reduce(
      (target, each) => {
        return target + each.paidAmount / 1
      },
      0
    )
    setTotalAmountPaid(previousPaidAmt + transferPaymentDetails.paidAmount / 1)
    return () => {}
  }, [transferPaymentDetails])

  useEffect(() => {
    let tempTotalAmount = 0
    let flightAmountArray = flightInput.map((x, i) => {
      return /^ ?\+?\d+$/.test(x.flightAmount) ? x.flightAmount : 0
    })

    let flightTotalAmount = flightAmountArray.reduce((total, num) => {
      return total + parseFloat(num)
    }, 0)

    let flightInclusionAmountArray = flightInput.map((x, i) => {
      return /^ ?\+?\d+$/.test(x.flightInclusion) ? x.flightInclusion : 0
    })

    let flightTotalInclusion = flightInclusionAmountArray.reduce(
      (total, inclusionAmount) => total + parseFloat(inclusionAmount),
      0
    )

    console.log("Hello", flightInput)
    tempTotalAmount += flightTotalAmount + flightTotalInclusion

    let trainAmountArray = trainInput.map((x, i) => {
      return /^ ?\+?\d+$/.test(x.trainAmount) ? x.trainAmount : 0
    })
    let trainTotalAmount = trainAmountArray.reduce((total, num) => {
      return total + parseFloat(num)
    }, 0)

    let trainInclusionAmountArray = trainInput.map((x, i) => {
      return /^ ?\+?\d+$/.test(x.trainInclusion) ? x.trainInclusion : 0
    })

    let trainTotalInclusion = trainInclusionAmountArray.reduce(
      (total, inclusionAmount) => total + parseFloat(inclusionAmount),
      0
    )

    tempTotalAmount += trainTotalAmount + trainTotalInclusion

    let cabAmountArray = cabInput.map((x, i) => {
      return /^ ?\+?\d+$/.test(x.cabAmount) ? x.cabAmount : 0
    })
    let cabTotalAmount = cabAmountArray.reduce((total, num) => {
      return total + num / 1
    }, 0)
    tempTotalAmount += cabTotalAmount

    let busAmountArray = busInput.map((x, i) => {
      return /^ ?\+?\d+$/.test(x.busAmount) ? x.busAmount : 0
    })
    let busTotalAmount = busAmountArray.reduce((total, num) => {
      return total + num / 1
    }, 0)
    tempTotalAmount += busTotalAmount

    setTotalAmount(tempTotalAmount)

    let tempCommission = 0
    let flightCommissionArray = flightInput.map((x, i) => {
      return /^ ?\+?\d+$/.test(x.flightComission) ? x.flightComission : 0
    })
    let flightTotalCommission = flightCommissionArray.reduce((total, num) => {
      return total + num / 1
    }, 0)
    tempCommission += flightTotalCommission

    let trainCommissionArray = trainInput.map((x, i) => {
      return /^ ?\+?\d+$/.test(x.trainComission) ? x.trainComission : 0
    })
    let trainTotalCommission = trainCommissionArray.reduce((total, num) => {
      return total + num / 1
    }, 0)
    tempCommission += trainTotalCommission

    let cabCommissionArray = cabInput.map((x, i) => {
      return /^ ?\+?\d+$/.test(x.cabCommission) ? x.cabCommission : 0
    })
    let cabTotalCommission = cabCommissionArray.reduce((total, num) => {
      return total + num / 1
    }, 0)
    tempCommission += cabTotalCommission

    let busCommissionArray = busInput.map((x, i) => {
      return /^ ?\+?\d+$/.test(x.busCommission) ? x.busCommission : 0
    })
    let busTotalCommission = busCommissionArray.reduce((total, num) => {
      return total + num / 1
    }, 0)
    tempCommission += busTotalCommission

    setCommission(tempCommission)
  }, [flightInput, trainInput, cabInput, busInput])

  const handleSubmit = async () => {
    setIsClicked(true)
    let postData = {
      ...formData,
      bookingId,
      basicDetails: basicInput,
      flightDetails: flightInput,
      trainDetails: trainInput,
      cabDetails: cabInput,
      busDetails: busInput,
      type: travelType(flightInput, trainInput, cabInput, busInput),
      clientName: basicInput[0].clientName,
      clientPhoneNumber: basicInput[0].clientMobileNo,
      totalAmount: totalAmount,
      commission: commission,
      transferTeamCondition: pkgTrmCndData,
      transferPaymentDetails: [
        ...formData.transferPaymentDetails,
        transferPaymentDetails,
      ],
      amountPaid: totalAmountPaid,
    }
    if (transferId) {
      postData = { ...postData, transferId: transferId }
    }
    console.log("TransferForm|handleSubmit|cabType: ", postData)

    let url = hasAdmin === "Hotel Admin" ? "hotelTransferData" : "transferData"

    Api.post(url, postData).then(async (res) => {
      if (res.status === 200) {
        transferId
          ? toast.success("Transfer Details Updated Successfully")
          : toast.success("Transfer Details Created Successfully")

        console.log("TransferForm|res.data", res.data)
        // await setTimeout(() => {
        //   window.open("/transferList", "_self");
        // }, 2000);
        history.push("/konnect/transferList")
      } else {
        console.log(res)
        toast.error("Transfer Details Unsuccessfull ", res.statusText)
      }
    })
  }

  const handleChangeClient = (index, option) => {
    console.log("option>>>", option)
    // const list = [...basicInput];
    // list[index]["clientName"] = option.value;
    // list[index]["clientMobileNo"] = option.clientMobileNo;
    // list[index]["clientEmail"] = option.clientEmail;
    const updatedInfo = basicInput.map((input) => ({
      ...input,
      clientName: option?.value,
      clientMobileNo: option?.clientMobileNo,
      clientEmail: option?.clientEmail,
    }))
    setBasicInput(updatedInfo)
    console.log("updated>>>", updatedInfo)
    // console.log("list>>>", list);
  }

  //default client values and passing it as a prop to the BasicDetails component
  const [defaultValue, setDeFaultValue] = useState({})
  useEffect(async () => {
    console.log(sessionStorage.getItem("mobile"))
    if (
      sessionStorage.getItem("mobile") !== null &&
      (await clientLists.length) > 0
    ) {
      let found = await Clientoptions.find(
        (client, index) =>
          client.clientMobileNo === sessionStorage.getItem("mobile")
      )
      console.log(found)
      setDeFaultValue(found)
    }
  }, [clientLists])

  useEffect(() => {
    if (transferId) {
      Api.get(`/transferDetail/${uniqueid}/${transferId}`).then((res) => {
        console.log(
          "TransferForm|TransferDetails|EditEffect res.data: ",
          res.data
        )

        // setFormData(res.data);
        // setting all form data values
        Object.entries(res.data).forEach(([key, value]) => {
          setFormData({ name: key, value: value })
        })

        let arr = []
        for (let i = 0; i < res.data.basicDetails.length; i++) {
          arr.push({ id: `${i}`, clientName: " " })
        }
        setBasicInputError(arr)
        setBasicInput(res.data.basicDetails)
        setBookingId(res.data.bookingId)
        arr = []
        for (let i = 0; i < res.data.flightDetails.length; i++) {
          arr.push({
            id: "",
            flightTrip: " ",
            flightName: " ",
            flightFrom: " ",
            flightTo: " ",
            flightDepartDate: "",
            flightReturnDate: "",
            flightPnr: " ",
            flightAdults: " ",
            flightChild: " ",
            flightInclusion: " ",
            flightAmount: "",
            flightComission: "",
          })
        }
        setFlightInputError(arr)
        setFlightInput(res.data.flightDetails)
        arr = []
        for (let i = 0; i < res.data.trainDetails.length; i++) {
          arr.push({
            id: "",
            traintravelClass: " ",
            trainName: " ",
            trainFrom: " ",
            trainTo: " ",
            trainDepartDate: "",
            trainPnr: " ",
            trainAdults: " ",
            trainChild: " ",
            trainInclusion: " ",
            trainAmount: "",
            trainComission: "",
          })
        }
        setTrainInputError(arr)
        setTrainInput(res.data.trainDetails)
        arr = []
        for (let i = 0; i < res.data.cabDetails.length; i++) {
          arr.push({
            id: "",
            cabFrom: " ",
            cabTo: "",
            cabTrip: " ",
            cabType: " ",
            cabVehicle: " ",
            cabAdults: " ",
            cabChild: " ",
            cabAmount: "",
            cabCommission: "",
          })
        }
        setCabInputError(arr)
        setCabInput(res.data.cabDetails)
        console.log("cab details response data: ", res.data.cabDetails)
        arr = []
        for (let i = 0; i < res.data.busDetails.length; i++) {
          arr.push({
            id: "",
            busName: "",
            busFrom: " ",
            busTo: " ",
            busSeatNo: " ",
            busAdults: " ",
            busChild: " ",
            busAmount: "",
            busCommission: "",
          })
        }
        setBusInputError(arr)
        setBusInput(res.data.busDetails)

        Object.entries(res.data.transferTeamCondition).forEach(
          ([key, value]) => {
            setPkgTrmCndData({ target: { name: key, value: value } })
          }
        )
        setAmountRefunded(res.data.amountRefunded)
        setAmountRetained(res.data.amountRetained)
        setRevisedCommission(res.data.revisedCommission)
        if (res.data.amountRefunded > 0) {
          setShowRefund(true)
        }
        setTotalAmountPaid(
          res.data.transferPaymentDetails.reduce((target, each) => {
            return target + each.paidAmount / 1
          }, 0)
        )

        let tempTotalAmount = 0
        let flightAmountArray = res.data.flightDetails.map((x, i) => {
          return x.flightAmount
        })
        let flightTotalAmount = flightAmountArray.reduce((total, num) => {
          return total + parseFloat(num)
        }, 0)

        let flightInclusionAmountArray = res.data.flightDetails.map((x, i) => {
          return x.flightInclusion
        })

        let flightTotalInclusion = flightInclusionAmountArray.reduce(
          (total, inclusionAmount) => total + parseFloat(inclusionAmount),
          0
        )

        tempTotalAmount += flightTotalAmount + flightTotalInclusion

        let trainAmountArray = res.data.trainDetails.map((x) => {
          return x.trainAmount
        })
        let trainTotalAmount = trainAmountArray.reduce((total, num) => {
          return total + parseFloat(num)
        }, 0)

        let trainInclusionAmount = res.data.trainDetails.map((x) => {
          return x.trainInclusion
        })

        let trainTotalInclusion = trainInclusionAmount.reduce(
          (total, inclusionAmount) => total + parseFloat(inclusionAmount),
          0
        )

        tempTotalAmount += trainTotalAmount + trainTotalInclusion

        let cabAmountArray = res.data.cabDetails.map((x, i) => {
          return x.cabAmount
        })
        let cabTotalAmount = cabAmountArray.reduce((total, num) => {
          return total + num / 1
        }, 0)
        tempTotalAmount += cabTotalAmount

        let busAmountArray = res.data.busDetails.map((x, i) => {
          return x.busAmount
        })
        let busTotalAmount = busAmountArray.reduce((total, num) => {
          return total + num / 1
        }, 0)
        tempTotalAmount += busTotalAmount

        setTotalAmount(parseFloat(res.data.totalAmount))

        var tempCommission = 0
        var flightCommissionArray = res.data.flightDetails.map((x, i) => {
          return x.flightComission
        })
        tempCommission += flightCommissionArray.reduce((total, num) => {
          return total + num / 1
        }, 0)

        var trainCommissionArray = res.data.trainDetails.map((x, i) => {
          return x.trainComission
        })
        tempCommission += trainCommissionArray.reduce((total, num) => {
          return total + num / 1
        }, 0)

        var cabCommissionArray = res.data.cabDetails.map((x, i) => {
          return x.cabCommission
        })
        tempCommission += cabCommissionArray.reduce((total, num) => {
          return total + num / 1
        }, 0)

        var busCommissionArray = res.data.busDetails.map((x, i) => {
          return x.busCommission
        })
        tempCommission += busCommissionArray.reduce((total, num) => {
          return total + num / 1
        }, 0)

        setCommission(tempCommission)

        // temp changes
      })
    } else {
      Api.get(
        `agenthoteltermandcondition/${uniqueid}/transferTermCondition`
      ).then((res) => {
        setPkgTrmCndData({
          target: { name: "teamCondition", value: res.data },
        })
      })
      Api.get(
        `agenthoteltermandcondition/${uniqueid}/transferCancellationPolicy`
      ).then((res) => {
        setPkgTrmCndData({
          target: { name: "cancellationPolicy", value: res.data },
        })
      })
    }
  }, [transferId])

  useEffect(() => {
    Api.get(`/clientall/${uniqueid}`).then((res) => {
      setClientLists(res.data)
    })
  }, [open])

  const Clientoptions =
    clientLists &&
    clientLists.map((client) => {
      let combine = `${client.firstName} ${client.lastName} (${client.mail})`
      let combine1 = `${client.firstName} ${client.lastName}`
      return {
        label: combine,
        value: combine1,
        id: client.id,
        clientMobileNo: client.mobile,
        clientEmail: client.mail,
      }
    })

  const validateAllFields = (arr, errorArr, setErrorArr) => {
    console.log("arr, errorArr, setErrorArr: ", arr, errorArr, setErrorArr)

    let eachVaid = arr.map((each, i) => {
      let valid = true
      let formDataCheck = Object.entries(each)
      formDataCheck = formDataCheck.filter((e) => {
        return checkFields.includes(e[0])
      })
      // console.log("formDataCheck: ", formDataCheck);
      Object.values(formDataCheck).forEach((val) => {
        if (val[1] !== null && val[1].toString().trim().length <= 0) {
          valid = false
          let temp = errorArr
          temp[i][val[0]] = "Required"
          setErrorArr(temp)
        }
      })
      return valid
    })
    console.log(arr, "eachValid:,", eachVaid)
    return eachVaid.every(Boolean)
  }

  const validatePreview = () => {
    let validFlight = flightInput.filter((e) => {
      return (
        e.flightFrom.trim() !== "" ||
        e.flightTo.trim() !== "" ||
        e.flightAdults?.toString().trim() ||
        e.flightAmount?.toString().trim()
      )
    })
    console.log("validFlight: ", validFlight, validFlight.length)
    let validTrain = trainInput.filter((e) => {
      return (
        e.trainFrom.trim() !== "" ||
        e.trainTo.trim() !== "" ||
        e.trainAdults?.toString().trim() ||
        e.trainAmount?.toString().trim()
      )
    })
    console.log("validTrain: ", validTrain, validTrain.length)
    let validCab = cabInput.filter((e) => {
      return (
        e.cabFrom.trim() !== "" ||
        e.cabTo.trim() !== "" ||
        e.cabAdults?.toString().trim() ||
        e.cabAmount?.toString().trim()
      )
    })
    console.log("validCab: ", validCab, validCab.length)
    let validBus = busInput.filter((e) => {
      return (
        e.busFrom.trim() !== "" ||
        e.busTo.trim() !== "" ||
        e.busAdults?.toString().trim() ||
        e.busAmount?.toString().trim()
      )
    })
    console.log("validBus: ", validBus, validBus.length)

    console.log("flightInput:", validFlight, validFlight.length)

    console.log("basicInput", basicInput)
    if (
      basicInput &&
      basicInput.length > 0 &&
      validateAllFields(basicInput, basicInputError, setBasicInputError) ===
        false
    ) {
      alert("Please fill all the manditory fields of Basic.")
      //   setTabValue(0);
    } else if (
      !validFlight.length &&
      !validTrain.length &&
      !validBus.length &&
      !validCab.length
    ) {
      alert("Please Fill All The Transfer Fields")
    } else if (
      validFlight.length !== 0 &&
      validateAllFields(flightInput, flightInputError, setFlightInputError) ===
        false
    ) {
      alert("Please fill all the manditory fields of Flights.")
      setTabValue(0)
    } else if (
      validTrain.length !== 0 &&
      validateAllFields(trainInput, trainInputError, setTrainInputError) ===
        false
    ) {
      alert("Please fill all the manditory fields of Train.")
      setTabValue(1)
    } else if (
      validCab.length !== 0 &&
      validateAllFields(cabInput, cabInputError, setCabInputError) === false
    ) {
      alert("Please fill all the manditory fields of Cab.")
      setTabValue(2)
    } else if (
      validBus.length !== 0 &&
      validateAllFields(busInput, busInputError, setBusInputError) === false
    ) {
      alert("Please fill all the manditory fields of Bus.")
      setTabValue(2)
    } else {
      if (width <= 786) {
        handleSubmit()
      } else {
        setOpenPreview(true)
      }
    }
  }

  // refund starts
  const [amountRefunded, setAmountRefunded] = useState(0)
  const [amountRetained, setAmountRetained] = useState(0)
  const [revisedCommission, setRevisedCommission] = useState(0)
  const [showRefund, setShowRefund] = useState(false)
  const [openRefund, setOpenRefund] = useState(false)

  const handleRefund = () => {
    const postData = {
      uniqueId: uniqueid,
      packageId: transferId,
      amountRefunded: amountRefunded,
      amountRetained: amountRetained,
      revisedCommission: revisedCommission,
    }
    console.log("postdata|refund: ", postData)
    setOpenRefund(false)
    setShowRefund(true)
    Api.post("cancelTransferDetails", postData).then((res) => {
      toast.success("Cancellation Success Updated")
    })
  }
  // refund ends

  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [width])

  const { paymentModeOptions } = useSelector(
    ({ paymentModeOptionsReducer }) => paymentModeOptionsReducer
  )

  useEffect(() => {
    dispatch(getPaymentModeOptions(uniqueid))
  }, [])

  const handleSearch = async () => {
    let url

    switch (hasAdmin) {
      case "Hotel Admin": {
        url = "bookingDetailsForHotelLogin"
        break
      }
      case "Agent Admin": {
        url = "agentBookingById"
        break
      }
      default: {
        url = "bookingdetails"
        break
      }
    }
    try {
      const { data } = await Api.get(`${url}/${uniqueid}/${bookingId}`)
      console.log({ data })

      if (_.isEmpty(data) || _.isNull(data))
        return toast.error("Invalid Booking ID")

      const res = await Api.get(`checkTransferBookingId/${bookingId}`)

      if (res.data == "Already Added")
        return toast.warn("Transfer ID already exists for this Booking ID")

      const alteredClientName = data?.clientName?.replace(/\.|\t/g, " ").trim()
      console.log({ alteredClientName })
      setBasicInput([
        {
          clientName: alteredClientName,
          clientEmail: data.clientEmail,
          clientMobileNo: data.clientMobile,
        },
      ])
    } catch (error) {
      if (_.isEqual(error.status == 500))
        return toast.error("Invalid Booking Id")
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (bookingId === "") {
      setBasicInput([
        {
          clientAltNo: "",
          clientName: "",
          clientEmail: "",
          clientMobileNo: "",
        },
      ])
    }
  }, [bookingId])
  return (
    <>
      {width <= 768 ? (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <TransferFormMobileUI
            tabValue={tabValue}
            setOpen={setOpen}
            Clientoptions={Clientoptions}
            basicInput={basicInput}
            basicInputError={basicInputError}
            handleRemoveClickBasic={handleRemoveClickBasic}
            handleAddClickBasic={handleAddClickBasic}
            handleBasicChange={handleBasicChange}
            handleChangeClient={handleChangeClient}
            transferId={transferId}
            clientLists={clientLists}
            defaultvalue={defaultValue}
            flightInput={flightInput}
            setFlightInput={setFlightInput}
            flightInputError={flightInputError}
            setFlightInputError={setFlightInputError}
            trainInput={trainInput}
            setTrainInput={setTrainInput}
            trainInputError={trainInputError}
            setTrainInputError={setTrainInputError}
            cabInput={cabInput}
            setCabInput={setCabInput}
            busInput={busInput}
            setBusInput={setBusInput}
            cabInputError={cabInputError}
            setCabInputError={setCabInputError}
            busInputError={busInputError}
            setBusInputError={setBusInputError}
            totalAmount={totalAmount}
            commission={commission}
            totalAmountPaid={totalAmountPaid}
            classes={classes}
            transferPaymentDetails={transferPaymentDetails}
            setTransferPaymentDetails={setTransferPaymentDetails}
            formData={formData}
            setPkgTrmCndData={setPkgTrmCndData}
            pkgTrmCndData={pkgTrmCndData}
            isClicked={isClicked}
            handleSubmit={handleSubmit}
            validatePreview={validatePreview}
            validateAllFields={validateAllFields}
            open={open}
            setDeFaultValue={setDeFaultValue}
            paymentModeOptions={paymentModeOptions}
          />
          <TransferClientForm
            open={open}
            onClose={() => {
              setOpen(false)
            }}
            selectedId={selectedId}
            BookingLocal={"yes"}
          />
        </MuiPickersUtilsProvider>
      ) : (
        <div
          style={{
            padding: "4.2% 1%",
            height: "auto",
            fontSize: "14px",
            backgroundColor: "#F7F7F7",
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Typography
              style={{
                ...twnButtonStyles.xlFonts,
                color: "#F46D25",
                paddingTop: "17px",
              }}
            >
              Transfer Details
            </Typography>
            <Grid container>
              <Grid item container xs={5} alignItems="center" margin="10px 0px">
                <Grid item xs={8}>
                  <TextField
                    name="bookingId"
                    type="search"
                    label="Booking ID"
                    value={bookingId}
                    onChange={(e) => setBookingId(e.target.value)}
                    fullWidth
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Booking ID"
                    InputProps={{
                      style: {
                        height: "38px",
                        borderRadius: "5px 20px 20px 5px",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleSearch}
                            style={{ padding: "5px", background: "#f46d25" }}
                          >
                            <SearchTwoTone
                              style={{
                                color: "white",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    autoFocus
                    disabled={transferId}
                  />
                </Grid>
              </Grid>
              <div>
                {/* Basic Details Fields  */}
                <BasicDetailPage
                  tabValue={tabValue}
                  setOpen={setOpen}
                  Clientoptions={Clientoptions}
                  basicInput={basicInput}
                  basicInputError={basicInputError}
                  handleRemoveClickBasic={handleRemoveClickBasic}
                  handleAddClickBasic={handleAddClickBasic}
                  handleBasicChange={handleBasicChange}
                  handleChangeClient={handleChangeClient}
                  transferId={transferId}
                  clientLists={clientLists}
                  defaultvalue={defaultValue}
                  bookingId={bookingId}
                />
                <br />
                <span
                  style={{
                    float: "right",
                    fontSize: "12px",
                    color: "#f46d25",
                    fontWeight: "bold",
                  }}
                >
                  Note: Fill details of at least one Travel Mode
                </span>
                <Box classes={styles.tabStyle}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="#f46d25"
                  >
                    <Tab label="Flight" {...a11yProps(0)} classes="abcd123" />
                    <Tab
                      label="Train"
                      {...a11yProps(1)}
                      classes={styles.selectedTab}
                    />
                    <Tab
                      label="Road Transport"
                      {...a11yProps(2)}
                      classes={styles.selectedTab}
                    />
                    <Tab
                      label="Hotel Details"
                      {...a11yProps(3)}
                      classes={styles.selectedTab}
                    />
                  </Tabs>
                </Box>
                {/* flight form starts */}
                <TabPanel value={tabValue} index={0}>
                  {hasAdmin === "Agent Admin" || checkAgent === "Agent" ? (
                    <AgentFlightForm
                      flightInput={flightInput}
                      setFlightInput={setFlightInput}
                      flightInputError={flightInputError}
                      setFlightInputError={setFlightInputError}
                      classes={classes}
                    />
                  ) : (
                    <FlightForm
                      flightInput={flightInput}
                      setFlightInput={setFlightInput}
                      flightInputError={flightInputError}
                      setFlightInputError={setFlightInputError}
                      classes={classes}
                    />
                  )}
                  {/* Buttons Starts */}
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{
                      backgroundColor: "#eee",
                      width: "auto",
                      fontWeight: "bold",
                      border: "1px solid #eee",
                    }}
                  >
                    <span
                      style={{
                        color: "#f46d25",
                        padding: "0.5em 1em",
                        width: "50%",
                      }}
                    >
                      Total Transfer Booking Amount
                    </span>
                    {transferId && (
                      <span
                        style={{
                          color: "#f46d25",
                          padding: "0.5em 1em",
                          width: "50%",
                        }}
                        disabled
                      >
                        Total Amount Paid
                      </span>
                    )}
                    <span
                      style={{
                        color: "#1eaf1e",
                        padding: "0.5em 1em",
                        width: "50%",
                      }}
                    >
                      Commission
                    </span>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{
                      width: "auto",
                      fontWeight: "bold",
                      border: "1px solid #eee",
                    }}
                  >
                    <span style={{ padding: "0.5em 1.2em", width: "50%" }}>
                      {totalAmount}
                    </span>
                    {transferId && (
                      <span style={{ padding: "0.5em 1.2em", width: "50%" }}>
                        {totalAmountPaid}
                      </span>
                    )}
                    <span style={{ padding: "0.5em 1.2em", width: "50%" }}>
                      {commission}
                    </span>
                  </Stack>
                  <br />
                  {/* Buttons Ends*/}

                  {/* Transaction Summary Starts */}
                  <TransactionSummary
                    transferPaymentDetails={transferPaymentDetails}
                    setTransferPaymentDetails={setTransferPaymentDetails}
                    formData={formData}
                    tabValue={tabValue}
                    paymentModeOptions={paymentModeOptions}
                  />
                  {/* Transaction Summary Ends */}
                  <br />
                </TabPanel>
                {/* flight form ends */}
                {/* train form starts */}
                <TabPanel value={tabValue} index={1}>
                  {hasAdmin === "Agent Admin" || checkAgent === "Agent" ? (
                    <AgentTrainForm
                      trainInput={trainInput}
                      setTrainInput={setTrainInput}
                      trainInputError={trainInputError}
                      setTrainInputError={setTrainInputError}
                      classes={classes}
                    />
                  ) : (
                    <TrainForm
                      trainInput={trainInput}
                      setTrainInput={setTrainInput}
                      trainInputError={trainInputError}
                      setTrainInputError={setTrainInputError}
                      classes={classes}
                    />
                  )}
                  {/* Buttons Starts */}
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{
                      backgroundColor: "#eee",
                      width: "auto",
                      fontWeight: "bold",
                      border: "1px solid #eee",
                    }}
                  >
                    <span
                      style={{
                        color: "#f46d25",
                        padding: "0.5em 1em",
                        width: "50%",
                      }}
                    >
                      Total Transfer Booking Amount
                    </span>
                    {transferId && (
                      <span
                        style={{
                          color: "#f46d25",
                          padding: "0.5em 1em",
                          width: "50%",
                        }}
                        disabled
                      >
                        Total Amount Paid
                      </span>
                    )}
                    <span
                      style={{
                        color: "#1eaf1e",
                        padding: "0.5em 1em",
                        width: "50%",
                      }}
                    >
                      Commission
                    </span>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{
                      width: "auto",
                      fontWeight: "bold",
                      border: "1px solid #eee",
                    }}
                  >
                    <span style={{ padding: "0.5em 1.2em", width: "50%" }}>
                      {totalAmount}
                    </span>
                    {transferId && (
                      <span style={{ padding: "0.5em 1.2em", width: "50%" }}>
                        {totalAmountPaid}
                      </span>
                    )}
                    <span style={{ padding: "0.5em 1.2em", width: "50%" }}>
                      {commission}
                    </span>
                  </Stack>
                  <br />
                  {/* Buttons Ends*/}

                  {/* Transaction Summary Starts */}
                  <TransactionSummary
                    transferPaymentDetails={transferPaymentDetails}
                    setTransferPaymentDetails={setTransferPaymentDetails}
                    formData={formData}
                    tabValue={tabValue}
                    paymentModeOptions={paymentModeOptions}
                  />
                  {/* Transaction Summary Ends */}
                  <br />
                </TabPanel>
                {/* train form ends */}
                {/* road form starts */}
                <TabPanel value={tabValue} index={2}>
                  <RoadForm
                    cabInput={cabInput}
                    setCabInput={setCabInput}
                    busInput={busInput}
                    setBusInput={setBusInput}
                    cabInputError={cabInputError}
                    setCabInputError={setCabInputError}
                    busInputError={busInputError}
                    setBusInputError={setBusInputError}
                    classes={classes}
                  />
                  {/* Buttons Starts */}
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{
                      backgroundColor: "#eee",
                      width: "auto",
                      fontWeight: "bold",
                      border: "1px solid #eee",
                    }}
                  >
                    <span
                      style={{
                        color: "#f46d25",
                        padding: "0.5em 1em",
                        width: "50%",
                      }}
                    >
                      Total Transfer Booking Amount
                    </span>
                    {transferId && (
                      <span
                        style={{
                          color: "#f46d25",
                          padding: "0.5em 1em",
                          width: "50%",
                        }}
                        disabled
                      >
                        Total Amount Paid
                      </span>
                    )}
                    <span
                      style={{
                        color: "#1eaf1e",
                        padding: "0.5em 1em",
                        width: "50%",
                      }}
                    >
                      Commission
                    </span>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{
                      width: "auto",
                      fontWeight: "bold",
                      border: "1px solid #eee",
                    }}
                  >
                    <span style={{ padding: "0.5em 1.2em", width: "50%" }}>
                      {totalAmount}
                    </span>
                    {transferId && (
                      <span style={{ padding: "0.5em 1.2em", width: "50%" }}>
                        {totalAmountPaid}
                      </span>
                    )}
                    <span style={{ padding: "0.5em 1.2em", width: "50%" }}>
                      {commission}
                    </span>
                  </Stack>
                  <br />
                  {/* Buttons Ends*/}

                  {/* Transaction Summary Starts */}
                  <TransactionSummaryCopy
                    transferPaymentDetails={transferPaymentDetails}
                    setTransferPaymentDetails={setTransferPaymentDetails}
                    formData={formData}
                    tabValue={tabValue}
                  />
                  {/* Transaction Summary Ends */}
                  <br />
                </TabPanel>
                {/* road form ends */}
                {/*Hotel Details form starts*/}
                <TabPanel value={tabValue} index={3}>
                  {/* <HotelDetailsForm hotelDetails={hotelDetails} /> */}
                  {/* Buttons Starts */}
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{
                      backgroundColor: "#eee",
                      width: "auto",
                      fontWeight: "bold",
                      border: "1px solid #eee",
                    }}
                  >
                    <span
                      style={{
                        color: "#f46d25",
                        padding: "0.5em 1em",
                        width: "50%",
                      }}
                    >
                      Total Transfer Booking Amount
                    </span>
                    {transferId && (
                      <span
                        style={{
                          color: "#f46d25",
                          padding: "0.5em 1em",
                          width: "50%",
                        }}
                        disabled
                      >
                        Total Amount Paid
                      </span>
                    )}
                    <span
                      style={{
                        color: "#1eaf1e",
                        padding: "0.5em 1em",
                        width: "50%",
                      }}
                    >
                      Commission
                    </span>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{
                      width: "auto",
                      fontWeight: "bold",
                      border: "1px solid #eee",
                    }}
                  >
                    <span style={{ padding: "0.5em 1.2em", width: "50%" }}>
                      {totalAmount}
                    </span>
                    {transferId && (
                      <span style={{ padding: "0.5em 1.2em", width: "50%" }}>
                        {totalAmountPaid}
                      </span>
                    )}
                    <span style={{ padding: "0.5em 1.2em", width: "50%" }}>
                      {commission}
                    </span>
                  </Stack>
                  <br />
                  {/* Buttons Ends*/}

                  {/* Transaction Summary Starts */}
                  <TransactionSummaryCopy
                    transferPaymentDetails={transferPaymentDetails}
                    setTransferPaymentDetails={setTransferPaymentDetails}
                    formData={formData}
                    tabValue={tabValue}
                  />
                  {/* Transaction Summary Ends */}
                  <br />
                </TabPanel>
                {/*Hotel Details form ends*/}
                {/* Terms and Conditions starts  */}
                <TermsCancelConditions
                  setPkgTrmCndData={setPkgTrmCndData}
                  pkgTrmCndData={pkgTrmCndData}
                />
                {/* Terms and Conditions ends  */} <br />
                {/* Refund Details Starts  */}
                {showRefund ? (
                  <>
                    <Stack style={{ width: "100%", marginTop: "30px" }}>
                      {/* <Typography variant='h6' style={{ color: '#f46d25', paddingTop: "1%", lineHeight: '0' }}>Refund</Typography> */}
                      <Typography
                        variant="subtitle1"
                        style={{
                          backgroundColor: "#eee",
                          fontWeight: "bold",
                          color: "#f46d25",
                          padding: "0.5em 1em",
                          width: "auto",
                        }}
                      >
                        Refund Details
                      </Typography>
                      <Stack direction="row">
                        <Grid container spacing={2}>
                          <Grid item lg={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              style={{
                                color: "#138128",
                                borderColor: "#deffde",
                              }}
                              InputLabelProps={{
                                shrink: false,
                                style: {
                                  color: "#f46d25",
                                },
                              }}
                              label={"Amount Refunded"}
                              disabled
                            />
                          </Grid>
                          <Grid item lg={6}>
                            <TextField
                              name="amountRefunded"
                              variant="outlined"
                              fullWidth
                              style={{
                                backgroundColor: "#ffe2d7",
                                marginLeft: "-20px",
                              }}
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                              value={amountRefunded}
                              disabled
                              autoComplete="off"
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color: "#fff",
                                  background: "#f46d25",
                                  borderRadius: "4px",
                                  padding: "2px 4px",
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item lg={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              style={{
                                color: "#138128",
                                borderColor: "#deffde",
                              }}
                              InputLabelProps={{
                                shrink: false,
                                style: {
                                  color: "#f46d25",
                                },
                              }}
                              label={"Amount Retained"}
                              disabled
                            />
                          </Grid>
                          <Grid item lg={6}>
                            <TextField
                              name="amountRetained"
                              value={amountRetained}
                              disabled={true}
                              variant="outlined"
                              fullWidth
                              style={{
                                backgroundColor: "#ffe2d7",
                                marginLeft: "-20px",
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                              autoComplete="off"
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color: "#fff",
                                  background: "#f46d25",
                                  borderRadius: "4px",
                                  padding: "2px 4px",
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item lg={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              style={{
                                color: "#138128",
                                borderColor: "#deffde",
                              }}
                              InputLabelProps={{
                                shrink: false,
                                style: {
                                  color: "rgb(19, 129, 40)",
                                },
                              }}
                              label={"Revised Commission"}
                              disabled
                            />
                          </Grid>
                          <Grid item lg={6}>
                            <TextField
                              name="revisedCommission"
                              value={revisedCommission}
                              disabled
                              variant="outlined"
                              fullWidth
                              style={{
                                backgroundColor: "#deffde",
                                marginLeft: "-20px",
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                              autoComplete="off"
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color: "#fff",
                                  background: "#f46d25",
                                  borderRadius: "4px",
                                  padding: "2px 4px",
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Stack>
                    </Stack>
                  </>
                ) : null}
                {/* Refund Details Ends  */}
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="center"
                  style={{ marginTop: "20px" }}
                >
                  {showRefund === false && (
                    <a
                      href="#"
                      style={twnButtonStyles.linkOrangeBtn}
                      onClick={validatePreview}
                    >
                      Preview
                    </a>
                  )}
                  {transferId && showRefund === false && (
                    <button
                      style={twnButtonStyles.orangeBtn}
                      onClick={() => {
                        setOpenRefund(true)
                      }}
                    >
                      Cancel Transfer
                    </button>
                  )}
                  <Link
                    to={{ pathname: "/konnect/transferList" }}
                    style={twnButtonStyles.linkBlackBtn}
                  >
                    Back
                  </Link>
                </Stack>
              </div>

              <TransferClientForm
                open={open}
                onClose={() => {
                  setOpen(false)
                }}
                selectedId={selectedId}
                BookingLocal={"yes"}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
      )}

      {/* preview dialog starts */}
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        sx={{ maxHeight: "100vh" }}
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        TransitionComponent={Transition}
      >
        <div>
          <PreviewPage
            basicInput={basicInput}
            flightInput={flightInput}
            trainInput={trainInput}
            cabInput={cabInput}
            busInput={busInput}
            totalAmount={totalAmount}
            commission={commission}
            packageTeamCondition={pkgTrmCndData}
            transferPaymentDetails={transferPaymentDetails}
          />
        </div>
        <div style={{ backgroundColor: "#eee" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            style={{ margin: "1%" }}
          >
            {isClicked ? (
              <button style={twnButtonStyles.disabledBtn} disabled>
                <i className="fa fa-refresh fa-spin" />
                {transferId ? "Update Now" : "Book Now"}
              </button>
            ) : (
              <button onClick={handleSubmit} style={twnButtonStyles.orangeBtn}>
                {transferId ? "Update Now" : "Book Now"}
              </button>
            )}
            <button
              onClick={() => setOpenPreview(false)}
              style={twnButtonStyles.blackBtn}
            >
              Edit Booking
            </button>
          </Stack>
        </div>
      </Dialog>
      {/* preview dialog ends */}

      {/* refund starts */}
      <TransferRefund
        openRefund={openRefund}
        closeFn={() => setOpenRefund(false)}
        handleRefund={handleRefund}
        totalGrossAmount={formData.totalAmount}
        totalAmountPaid={totalAmountPaid}
        comission={formData.commission}
        amountRefunded={amountRefunded}
        setAmountRefunded={setAmountRefunded}
        amountRetained={amountRetained}
        setAmountRetained={setAmountRetained}
        revisedCommission={revisedCommission}
        setRevisedCommission={setRevisedCommission}
      />
      {/* refund ends */}
    </>
  )
}
