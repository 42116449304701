import React from "react";
import {
  Typography,
  Tabs,
  Tab,
  Box,
  Stack,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Link } from "react-router-dom";
import { DatePicker } from "@material-ui/pickers";
import Select, { components } from "react-select";

const TrainClass = [
  { label: "1AC", value: "1AC" },
  { label: "2AC", value: "2AC" },
  { label: "3AC", value: "3AC" },
  { label: "Sleeper", value: "Sleeper" },
];

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

export default function AgentTrainForm({
  trainInput,
  setTrainInput,
  trainInputError,
  setTrainInputError,
  classes,
}) {
  const validateField = (name, value) => {
    var error = "";

    switch (name) {
      case "trainFrom":
      case "trainTo":
        error = value.length < 3 ? "Required" : "";
        break;
      case "trainAdults":
        error = /^\+?\d+$/.test(value) ? "" : "Enter a valid positive no.";
        break;
      case "trainAmount":
        error = /^\+?\d+$/.test(value) ? "" : "Enter valid amount";
        break;
    }
    return error;
  };

  const handleRemoveClickTrain = (index) => {
    const list = [...trainInput];
    list.splice(index, 1);
    setTrainInput(list);

    const listError = [...trainInputError];
    listError.splice(index, 1);
    setTrainInputError(listError);
  };

  const handleAddClickTrain = () => {
    setTrainInput([
      ...trainInput,
      {
        id: "",
        traintravelClass: "",
        trainName: "",
        trainFrom: "",
        trainTo: "",
        trainDepartDate: "",
        trainPnr: "",
        trainAdults: "",
        trainChild: "",
        trainInclusion: "",
        trainAmount: "",
        trainComission: "",
      },
    ]);

    setTrainInputError([
      ...trainInputError,
      {
        id: "",
        traintravelClass: "",
        trainName: "",
        trainFrom: "",
        trainTo: "",
        trainDepartDate: "",
        trainPnr: "",
        trainAdults: "",
        trainChild: "",
        trainInclusion: "",
        trainAmount: "",
        trainComission: "",
      },
    ]);
  };

  const handleTrainChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...trainInput];
    list[index][name] = value;
    setTrainInput(list);

    const listError = [...trainInputError];
    const error = validateField(name, value.trim());
    listError[index][name] = error;
    setTrainInputError(listError);
  };

  const handleTrainSelectChange = (name, value, index) => {
    const list = [...trainInput];
    list[index][name] = value;
    setTrainInput(list);
  };

  return (
    <>
      <Stack style={{ padding: "0.8em 0.5em" }}>
        {trainInput.map((x, i) => (
          <>
            <Grid container columnSpacing={1.5} rowSpacing={2} key={i}>
              <Grid item sm={6}>
                {i == 0 && (
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "0",
                      color: "#f46d25",
                      fontWeight: "500",
                    }}
                  >
                    Train Details
                  </p>
                )}
              </Grid>
              <Grid item sm={6} style={{ textAlign: "end" }}>
                {trainInput.length !== 1 && (
                  <DeleteIcon
                    onClick={() => handleRemoveClickTrain(i)}
                    className={classes.plus}
                  />
                )}
                {trainInput.length - 1 === i && (
                  <AddCircleOutlineIcon
                    onClick={handleAddClickTrain}
                    size="small"
                    className={classes.plus}
                  />
                )}
              </Grid>
              <div
                style={{
                  width: "2%",
                  textAlign: "center",
                  marginTop: "1em",
                  fontSize: "14px",
                }}
              >
                {i + 1 + "."}
              </div>
              <div style={{ width: "98%", marginTop: "1em" }}>
                <Stack direction="row" width="100%" spacing={2}>
                  <div style={{ width: "100%" }}>
                    <CustomTextInlineField
                      name="trainName"
                      label="Train Name"
                      value={x.trainName}
                      onChange={(e) => {
                        handleTrainChange(e, i);
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <CustomTextInlineField
                      name="trainFrom"
                      label="From*"
                      value={x.trainFrom}
                      onChange={(e) => {
                        handleTrainChange(e, i);
                      }}
                      error={trainInputError[i]["trainFrom"]}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <CustomTextInlineField
                      name="trainTo"
                      label="To*"
                      value={x.trainTo}
                      onChange={(e) => {
                        handleTrainChange(e, i);
                      }}
                      error={trainInputError[i]["trainTo"]}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <DatePicker
                      label="Departure Date"
                      size="small"
                      autoOk
                      required
                      format="dd-MM-yyyy"
                      inputVariant="outlined"
                      fullWidth
                      // disablePast={true}
                      disablePast={x.trainDepartDate ? false : true}
                      variant="inline"
                      value={x.trainDepartDate ? x.trainDepartDate : null}
                      onChange={(e) =>
                        handleTrainSelectChange("trainDepartDate", e, i)
                      }
                      minDate={
                        x.trainDepartDate ? x.trainDepartDate : new Date()
                      }
                    />
                  </div>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  width="100%"
                  marginTop="15px"
                >
                  <div style={{ width: "100%" }}>
                    <Select
                      name="traintravelClass"
                      placeholder="Travel Class"
                      value={TrainClass.filter(
                        (i) => i.value === x.traintravelClass
                      )}
                      options={TrainClass}
                      onChange={(e) =>
                        handleTrainSelectChange("traintravelClass", e.value, i)
                      }
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                        control: (base, state) => ({
                          ...base,
                          "&:hover": { borderColor: "#f46d25" },
                          borderColor: "#f46d25",
                          boxShadow: "none",
                          width: "100%",
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top:
                            state.hasValue || state.selectProps.inputValue
                              ? -4
                              : "50%",
                          background: "#fff",
                          padding: "0px 5px",
                          transition: "top 0.1s, font-size 0.1s",
                          fontSize: "12px",
                        }),
                        container: (provided) => ({
                          ...provided,
                        }),
                        valueContainer: (provided) => ({
                          ...provided,
                          overflow: "visible",
                        }),
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <CustomTextInlineField
                      name="trainPnr"
                      label="PNR No"
                      value={x.trainPnr}
                      onChange={(e) => {
                        handleTrainChange(e, i);
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <CustomTextInlineField
                      name="trainAdults"
                      label="Adults*"
                      value={x.trainAdults}
                      onChange={(e) => {
                        handleTrainChange(e, i);
                      }}
                      error={trainInputError[i]["trainAdults"]}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <CustomTextInlineField
                      name="trainChild"
                      label="Children"
                      style={{ backgroundColor: "#fff" }}
                      value={x.trainChild}
                      onChange={(e) => {
                        handleTrainChange(e, i);
                      }}
                    />
                  </div>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  width="100%"
                  marginTop="15px"
                >
                  <div style={{ width: "100%" }}>
                    <CustomTextInlineField
                      name="trainInclusion"
                      label="Inclusion"
                      style={{ backgroundColor: "#fff" }}
                      value={x.trainInclusion}
                      onChange={(e) => {
                        handleTrainChange(e, i);
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <CustomTextInlineField
                      name="trainAmount"
                      label="Amount *"
                      style={{ backgroundColor: "#fff" }}
                      value={x.trainAmount}
                      onChange={(e) => {
                        handleTrainChange(e, i);
                      }}
                      error={trainInputError[i]["trainAmount"]}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    {/* <CustomTextInlineField name="trainComission" label='Commission' style={{ backgroundColor: '#fff' }} value={x.value={x.trainComission}} onChange={(e) => { handleTrainChange(e, i) }} /> */}
                    <TextField
                      name="trainComission"
                      fullWidth
                      label="Commission"
                      error={trainInputError[i]["trainComission"]}
                      // style={{borderColor:"#deffde"}}
                      value={x.trainComission != "" ? x.trainComission : " "}
                      defaultValue=" "
                      variant="outlined"
                      size="small"
                      onChange={(e) => {
                        handleTrainChange(e, i);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-input": { padding: "9px 14px" },
                        "& .MuiInputBase-input": { height: 1 },
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          { border: "1.5px solid rgb(30, 175, 30)" },
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <Grid container></Grid>
                  </div>
                </Stack>
              </div>
            </Grid>
          </>
        ))}
      </Stack>
      <br />
    </>
  );
}

function CustomTextInlineField(props) {
  console.log("props.name: ", props.name, props.value);
  return (
    <Stack>
      <TextField
        name={props.name}
        fullWidth
        label={props.label}
        value={props.value != "" ? props.value : " "}
        defaultValue=" "
        variant="outlined"
        size="small"
        // style={{ backgroundColor: '#fff', color: "#f46d25" }}
        onChange={props.onChange}
        sx={{
          "& .MuiOutlinedInput-input": { padding: "10px 14px" },
          "& .MuiInputBase-input": { height: 1 },
        }}
      />
      {props.error.length > 0 && (
        <span style={{ color: "#ff0000" }}>{props.error}</span>
      )}
    </Stack>
  );
}

CustomTextInlineField.defaultProps = {
  error: "",
};
