import { ActionTypes } from "../actions/actionTypes"

const INITIAL_STATE = {
  openModal: false,
  url: null,
}

export const videoPlayerModalReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action

  switch (type) {
    case ActionTypes.TOGGLE_VIDEO_PLAYER_MODAL: {
      return { ...state, openModal: !state.openModal }
    }
    case ActionTypes.SET_VIDEO_URL: {
      return { ...state, url: payload.url }
    }

    case ActionTypes.SET_VIDEO_URL_TO_NULL: {
      return { ...state, url: null }
    }
    default:
      return state
  }
}
