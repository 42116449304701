import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  makeStyles,
} from "@material-ui/core"
import { useEffect, useState } from "react"
import PropertyBasicAmenities from "./propertyBasicAmenities.component"
import CommonAreaAmenities from "./propertyCommonAreaAmenities.component"
import EntertainmentAmenitites from "./propertyEntertainmentAmenities.component"
import FoodAndDrinks from "./propertyFoodAndDrinksAmenities.component"
import GeneralServicesAmenities from "./propertyGeneralServicesAmenities.component"
import HealthAndBeautyAmenities from "./propertyHealthAndBeauty.component"
import PaymentServices from "./propertyPaymentServices.component"
import SafetyAndHygiene from "./propertySafetyAndHygiene.component"
import Security from "./propertySecurityAmenities.component"
import TransferAmenities from "./propertyTransferAmenities.component"
import { toast } from "react-toastify"
import API from "../../../Service/Api"
import { switchClonePropertyDialogOpen } from "../../../redux/actions/clonePropertyAmenities.action"
import { useDispatch, useSelector } from "react-redux"
import { CloseOutlined } from "@material-ui/icons"
import Select from "react-select"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import ScreenLoader from "../../../components/loadingScreen/screenLoader.component"

const useStyles = makeStyles((theme) => ({
  accordionSummaryStyles: {
    background: "#f46D25",
    color: "white",
    fontSize: "17px",
    maxHeight: "42px",
    minHeight: "42px",
    borderRadius: "5px 5px 0px 0px",
    overflow: "hidden",

    "&.Mui-expanded": {
      maxHeight: "42px",
      minHeight: "42px",
    },
  },
  accordionStyles: {
    boxShadow: "none",
    border: "1px solid #D2D2D2",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  outerSectionStyles: {
    marginTop: "19px",
  },
}))

const PropertyAmenitiesForm = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState("panel1")
  const [propertyDropDown, setPropertyDropDown] = useState([])
  const [propertyOption, setPropertyOption] = useState(null)
  const [cloning, setCloning] = useState(false)
  const { propertyId } = useParams()

  const { openCloneDialog } = useSelector(
    ({ clonePropertyAmenitiesReducer }) => clonePropertyAmenitiesReducer
  )

  const unique_id = localStorage.getItem("unique_id")
  const auth = localStorage.getItem("auth")

  const fetchPropertyDropdown = async () => {
    try {
      const { data } = await API.get(
        `propertydisplaynameall/${localStorage.getItem("unique_id")}`
      )
      const options = data?.map((option) => ({
        label: option.displayName,
        value: option?.propertyId,
      }))
      setPropertyDropDown(options)
    } catch (error) {
      toast.error("unable to fetch the property list")
    }
  }

  useEffect(() => {
    fetchPropertyDropdown()
  }, [])

  const handleChangePanel = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  const fetchAndCloneBasicAmenitiesData = async () => {
    try {
      const res = await API.get(
        `amenitiesbasicfacilitiesvalue/${propertyOption.value}`
      )
      const { data } = await API.get(
        `amenitiesbasicfacilitiesstarredvalue/${propertyOption.value}`
      )

      const mergedAmenityData = {
        ...res.data,
        ...data,
        propertyId: propertyId,
        id: "",
        uniqueId: unique_id,
        createdBy: auth,
        createdAt: "",
        updateBy: "",
        updateAt: "",
      }

      return mergedAmenityData
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchAndCloneGeneralServicesData = async () => {
    try {
      const normalData = await API.get(
        `AmenitiesGeneralServicevalue/${propertyOption.value}`
      )
      const switchData = await API.get(
        `AmenitiesGeneralServiceStarredvalue/${propertyOption.value}`
      )

      const mergedAmenityData = {
        ...normalData.data,
        ...switchData.data,
        propertyId: propertyId,
        id: "",
        uniqueId: unique_id,
        createdBy: auth,
        createdAt: "",
        updateBy: "",
        updateAt: "",
      }
      return mergedAmenityData
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchAndCloneCommonAreaData = async () => {
    try {
      const normalData = await API.get(
        `amenitiescommonareavalue/${propertyOption.value}`
      )
      const switchData = await API.get(
        `AmenitiesCommonAreaStarredvalue/${propertyOption.value}`
      )

      const mergedAmenityData = {
        ...normalData.data,
        ...switchData.data,
        propertyId: propertyId,
        id: "",
        uniqueId: unique_id,
        createdBy: auth,
        createdAt: "",
        updateBy: "",
        updateAt: "",
      }
      return mergedAmenityData
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchAndCloneFoodAndDrinks = async () => {
    try {
      const normalData = await API.get(
        `AmenitiesFoodAndDrinksvalue/${propertyOption.value}`
      )
      const switchData = await API.get(
        `AmenitiesFoodAndDrinksStarredvalue/${propertyOption.value}`
      )

      const mergedAmenityData = {
        ...normalData.data,
        ...switchData.data,
        propertyId: propertyId,
        id: "",
        uniqueId: unique_id,
        createdBy: auth,
        createdAt: "",
        updateBy: "",
        updateAt: "",
      }
      return mergedAmenityData
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchAndCloneHealthAndBeauty = async () => {
    try {
      const normalData = await API.get(
        `AmenitiesHealthAndBeautyvalue/${propertyOption.name}`
      )
      const switchData = await API.get(
        `AmenitiesHealthAndBeautyStarredvalue/${propertyOption.name}`
      )

      const mergedAmenityData = {
        ...normalData.data,
        ...switchData.data,
        propertyId: propertyId,
        id: "",
        uniqueId: unique_id,
        createdBy: auth,
        createdAt: "",
        updateBy: "",
        updateAt: "",
      }
      return mergedAmenityData
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchAndClonePaymentService = async () => {
    try {
      const normalData = await API.post(`AmenitiesPaymentServicevalue`, {
        propertyId: propertyOption.value,
      })

      const switchData = await API.get(
        `AmenitiesPaymentServiceStarredvalue/${propertyOption.value}`
      )

      const mergedAmenityData = {
        ...normalData.data,
        ...switchData.data,
        propertyId: propertyId,
        id: "",
        uniqueId: unique_id,
        createdBy: auth,
        createdAt: "",
        updateBy: "",
        updateAt: "",
      }
      return mergedAmenityData
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchAndCloneEntertainmentAmenities = async () => {
    try {
      const normalData = await API.get(
        `AmenitiesEntertainmentvalue/${propertyOption.value}`
      )
      const switchData = await API.get(
        `AmenitiesEntertainmentStarredvalue/${propertyOption.value}`
      )

      const mergedAmenityData = {
        ...normalData.data,
        ...switchData.data,
        propertyId: propertyId,
        id: "",
        uniqueId: unique_id,
        createdBy: auth,
        createdAt: "",
        updateBy: "",
        updateAt: "",
      }
      return mergedAmenityData
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchAndCloneSafetyAndHygiene = async () => {
    try {
      const normalData = await API.get(
        `AmenitiesSafetyAndHygienevalue/${propertyOption.value}`
      )
      const switchData = await API.get(
        `AmenitiesSafetyAndHygieneStarredvalue/${propertyOption.value}`
      )

      const mergedAmenityData = {
        ...normalData.data,
        ...switchData.data,
        propertyId: propertyId,
        id: "",
        uniqueId: unique_id,
        createdBy: auth,
        createdAt: "",
        updateBy: "",
        updateAt: "",
      }
      return mergedAmenityData
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchAndCloneSecurity = async () => {
    try {
      const normalData = await API.get(
        `AmenitiesSecurityvalue/${propertyOption.value}`
      )
      const switchData = await API.get(
        `AmenitiesSecurityStarredvalue/${propertyOption.value}`
      )

      const mergedAmenityData = {
        ...normalData.data,
        ...switchData.data,
        propertyId: propertyId,
        id: "",
        uniqueId: unique_id,
        createdBy: auth,
        createdAt: "",
        updateBy: "",
        updateAt: "",
      }
      return mergedAmenityData
    } catch (error) {
      toast.error(error.message)
    }
  }

  const fetchAndCloneTransferData = async () => {
    try {
      const normalData = await API.get(
        `AmenitiesTransfersvalue/${propertyOption.value}`
      )
      const switchData = await API.get(
        `AmenitiesTransfersStarredvalue/${propertyOption.value}`
      )

      const mergedAmenityData = {
        ...normalData.data,
        ...switchData.data,
        propertyId: propertyId,
        id: "",
        uniqueId: unique_id,
        createdBy: auth,
        createdAt: "",
        updateBy: "",
        updateAt: "",
      }
      return mergedAmenityData
    } catch (error) {
      toast.error(error.message)
    }
  }

  const postCloneBasicAmenities = async (cloneData) => {
    const basicPostData = await cloneData
    function createStarred(fields) {
      API.post("amenitiesbasicfacilitiesstarred", fields)
    }
    console.log({ basicPostData })
    API.post("amenitiesbasicfacilities", basicPostData).then((res) => {
      if (res.status == 200) {
        createStarred(basicPostData)
      }
    })
  }

  const postCloneGenralServicesAmenities = async (cloneData) => {
    const genServicesPostData = await cloneData
    function createStarred(fields) {
      API.post("AmenitiesGeneralServiceStarred", fields)
    }
    console.log({ genServicesPostData })
    API.post("AmenitiesGeneralService", genServicesPostData).then((res) => {
      if (res) {
        createStarred(genServicesPostData)
      }
    })
  }

  const postCloneCommonAreaAmenities = async (cloneData) => {
    const commonAreaPostData = await cloneData
    function createStarred(fields) {
      API.post("AmenitiesCommonAreaStarred", fields)
    }
    console.log({ commonAreaPostData })
    API.post("amenitiescommonarea", commonAreaPostData).then((res) => {
      if (res) {
        createStarred(commonAreaPostData)
      }
    })
  }

  const postCloneFoodAndDrinksAmenities = async (cloneData) => {
    const foodAndDrinksPostData = await cloneData
    function createStarred(fields) {
      API.post("AmenitiesFoodAndDrinksStarred", fields)
    }
    console.log({ foodAndDrinksPostData })
    API.post("AmenitiesFoodAndDrinks", foodAndDrinksPostData).then((res) => {
      if (res) {
        createStarred(foodAndDrinksPostData)
      }
    })
  }

  const postCloneHealthAndBeautyAmenities = async (cloneData) => {
    const healthAndBeautyPostData = await cloneData
    function createStarred(fields) {
      API.post("AmenitiesHealthAndBeautyStarred", fields)
    }
    console.log({ healthAndBeautyPostData })
    API.post("AmenitiesHealthAndBeauty", healthAndBeautyPostData).then(
      (res) => {
        if (res) {
          createStarred(healthAndBeautyPostData)
        }
      }
    )
  }

  const postClonePaymentServicesAmenities = async (cloneData) => {
    const paymentServicesPostData = await cloneData
    function createStarred(fields) {
      API.post("AmenitiesPaymentServiceStarred", fields)
    }
    console.log({ paymentServicesPostData })
    API.post("AmenitiesPaymentService", paymentServicesPostData).then((res) => {
      if (res) {
        createStarred(paymentServicesPostData)
      }
    })
  }

  const postCloneEntertainmentAmenities = async (cloneData) => {
    const entertainmentPostData = await cloneData
    function createStarred(fields) {
      API.post("AmenitiesEntertainmentStarred", fields)
    }
    console.log({ entertainmentPostData })
    API.post("AmenitiesEntertainment", entertainmentPostData).then((res) => {
      if (res) {
        createStarred(entertainmentPostData)
      }
    })
  }

  const postCloneSafetyAndHygieneAmenities = async (cloneData) => {
    const safetyAndHygienePostData = await cloneData
    function createStarred(fields) {
      API.post("AmenitiesSafetyAndHygieneStarred", fields)
    }
    console.log({ safetyAndHygienePostData })
    API.post("AmenitiesSafetyAndHygiene", safetyAndHygienePostData).then(
      (res) => {
        if (res) {
          createStarred(safetyAndHygienePostData)
        }
      }
    )
  }

  const postCloneSecurityAmenities = async (cloneData) => {
    const securityPostData = await cloneData
    function createStarred(fields) {
      API.post("AmenitiesSecurityStarred", fields)
    }
    console.log({ securityPostData })
    API.post("AmenitiesSecurity", securityPostData).then((res) => {
      if (res) {
        createStarred(securityPostData)
      }
    })
  }

  const postCloneTransferAmenities = async (cloneData) => {
    const transferPostData = await cloneData
    function createStarred(fields) {
      API.post("AmenitiesTransfersStarred", fields)
    }
    console.log({ transferPostData })
    API.post("AmenitiesTransfers", transferPostData).then((res) => {
      if (res) {
        createStarred(transferPostData)
      }
    })
  }

  const cloneAmenitiesFn = async () => {
    console.log("cloning")
    setCloning(true)
    try {
      await postCloneBasicAmenities(fetchAndCloneBasicAmenitiesData())
      await postCloneGenralServicesAmenities(fetchAndCloneGeneralServicesData())
      await postCloneCommonAreaAmenities(fetchAndCloneCommonAreaData())
      await postCloneFoodAndDrinksAmenities(fetchAndCloneFoodAndDrinks())
      await postCloneHealthAndBeautyAmenities(fetchAndCloneHealthAndBeauty())
      await postClonePaymentServicesAmenities(fetchAndClonePaymentService())
      await postCloneEntertainmentAmenities(
        fetchAndCloneEntertainmentAmenities()
      )
      await postCloneSafetyAndHygieneAmenities(fetchAndCloneSafetyAndHygiene())
      await postCloneSecurityAmenities(fetchAndCloneSecurity())
      await postCloneTransferAmenities(fetchAndCloneTransferData())
    } catch (error) {
    } finally {
      setCloning(false)
      await setTimeout(() => {
        dispatch(switchClonePropertyDialogOpen())
      }, 2000)

      window.open(`/konnect/property/${propertyId}/propertyAmenities`, "_self")
    }
  }

  useEffect(() => {
    if (openCloneDialog) {
      fetchAndCloneBasicAmenitiesData()
      fetchAndCloneGeneralServicesData()
      fetchAndCloneCommonAreaData()
      fetchAndCloneFoodAndDrinks()
      fetchAndCloneHealthAndBeauty()
      fetchAndClonePaymentService()
      fetchAndCloneEntertainmentAmenities()
      fetchAndCloneSafetyAndHygiene()
      fetchAndCloneSecurity()
      fetchAndCloneTransferData()
    }
  }, [propertyOption])

  return (
    <>
      <section className={classes.outerSectionStyles}>
        <PropertyBasicAmenities
          classes={classes}
          handleChangePanel={handleChangePanel}
          expanded={expanded}
          setExpanded={setExpanded}
        />
        <GeneralServicesAmenities
          classes={classes}
          handleChangePanel={handleChangePanel}
          expanded={expanded}
          setExpanded={setExpanded}
        />
        <CommonAreaAmenities
          classes={classes}
          handleChangePanel={handleChangePanel}
          expanded={expanded}
          setExpanded={setExpanded}
        />
        <FoodAndDrinks
          classes={classes}
          handleChangePanel={handleChangePanel}
          expanded={expanded}
          setExpanded={setExpanded}
        />
        <HealthAndBeautyAmenities
          classes={classes}
          handleChangePanel={handleChangePanel}
          expanded={expanded}
          setExpanded={setExpanded}
        />
        <PaymentServices
          classes={classes}
          handleChangePanel={handleChangePanel}
          expanded={expanded}
          setExpanded={setExpanded}
        />
        <EntertainmentAmenitites
          classes={classes}
          handleChangePanel={handleChangePanel}
          expanded={expanded}
          setExpanded={setExpanded}
        />
        <SafetyAndHygiene
          classes={classes}
          handleChangePanel={handleChangePanel}
          expanded={expanded}
          setExpanded={setExpanded}
        />
        <Security
          classes={classes}
          handleChangePanel={handleChangePanel}
          expanded={expanded}
          setExpanded={setExpanded}
        />
        <TransferAmenities
          classes={classes}
          handleChangePanel={handleChangePanel}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </section>
      <Modal
        open={openCloneDialog}
        onClose={() => dispatch(switchClonePropertyDialogOpen())}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card style={{ width: "400px", height: "auto" }}>
          <CardHeader
            title={
              <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                Select property to clone .
              </div>
            }
            action={
              <IconButton
                title="close dialog"
                size="small"
                style={{ border: "1px solid #f46d25" }}
                onClick={() => dispatch(switchClonePropertyDialogOpen())}
              >
                <CloseOutlined
                  color="primary"
                  style={{ height: "15px", width: "15px" }}
                />
              </IconButton>
            }
            subheader={<small>**clones amenities of selected property</small>}
          />
          <CardContent>
            <Select
              placeholder="Select Any Property"
              value={propertyOption}
              options={propertyDropDown}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  width: "350px",
                }),
                container: (provided, state) => ({
                  ...provided,
                  position: "absolute",
                }),
              }}
              onChange={(option) => setPropertyOption(option)}
            />
            <center style={{ position: "relative", marginTop: "40px" }}>
              <Button
                size="small"
                style={{ marginTop: "20px", height: "30px" }}
                disabled={
                  propertyOption == "null" || propertyOption == undefined
                }
                onClick={cloneAmenitiesFn}
              >
                Clone Amenities
              </Button>
            </center>
          </CardContent>
        </Card>
      </Modal>
      <ScreenLoader open={cloning} />
    </>
  )
}

export default PropertyAmenitiesForm
