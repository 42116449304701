import {
  FormControlLabel,
  Switch,
  Checkbox,
  makeStyles,
} from "@material-ui/core"
import { Grid } from "@mui/material"
import FormikSingleValueSelectField from "../customSelectFieldComponents/singleValueFormikSelectField.component"
import FormikMultipleValueSelectField from "../customSelectFieldComponents/muiltipleValueFormikSelectField.component"
import { Field } from "formik"
import { useEffect } from "react"

const useStyles = makeStyles((theme) => ({
  checkboxStyling: {
    "& .MuiSvgIcon-root": {
      color: "#f46d25",
      height: "18px",
      width: "18px",
    },
  },
  menuStyling: {
    fontSize: "14px",
  },
  selectLabelProps: {
    backgroundColor: "white",
    color: "#000000",
    padding: "0px 5px 5px 5px",
    borderRadius: "5px",
  },
  menuItemStyles: {
    height: "300px",
    fontSize: "14px",
  },
  selectStyle: {
    height: "35px",
    width: "100%",
    "& .MuiSelect-icon": {
      color: "#f46d25",
    },
  },
  checkBoxLabelStyles: {
    fontSize: "14px",
  },
  chipStyling: {
    margin: theme.spacing(0.5),
    height: "15px",
    fontSize: "13px",
  },
}))

const InlineFormikFieldWithCheckSelectAndSwitch = ({
  amenity,
  values,
  setFieldValue,
}) => {
  const classes = useStyles()

  return (
    <Grid item container xs={9} alignItems="center" spacing={3}>
      <Grid item xs={3}>
        <Field
          as={FormControlLabel}
          control={
            <Field
              as={Checkbox}
              checked={values[amenity.name]}
              name={amenity.name}
              className={classes.checkboxStyling}
            />
          }
          label={
            <span className={classes.checkBoxLabelStyles}>{amenity.label}</span>
          }
        />
      </Grid>
      {values[amenity.name] && amenity.select && (
        <>
          <Grid item xs={6}>
            {amenity.selectType === "singleValue" ? (
              <FormikSingleValueSelectField amenity={amenity} values={values} />
            ) : (
              <FormikMultipleValueSelectField
                amenity={amenity}
                values={values}
                setFieldValue={setFieldValue}
              />
            )}
          </Grid>
          <Grid item xs={3}>
            <Field
              name={amenity.switchName}
              as={Switch}
              color="primary"
              checked={values[amenity.switchName]}
              size="small"
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default InlineFormikFieldWithCheckSelectAndSwitch
