import React from "react";
import "./Rating.css";

export default function RatingMail(props) {
  const { rating, style } = props;
  return (
    <div className="rating" style={style}>
      {rating >= 1 ? <span style={{ color: "#f0c040" }}>&#9733;</span> : null}
      {rating >= 2 ? <span style={{ color: "#f0c040" }}>&#9733;</span> : null}
      {rating >= 3 ? <span style={{ color: "#f0c040" }}>&#9733;</span> : null}
      {rating >= 4 ? <span style={{ color: "#f0c040" }}>&#9733;</span> : null}
      {rating >= 5 ? <span style={{ color: "#f0c040" }}>&#9733;</span> : null}
    </div>
  );
}
