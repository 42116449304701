import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import Api from "../../../Service/Api";
import FormikInlineCheckboxAndSwitchOnly from "../../../components/amenityComponents/inlineCheckBoxAndSwitchOnly.component";
import InlineFormikFieldWithCheckSelectAndSwitch from "../../../components/amenityComponents/inlineCheckBoxWithSelectAndSwitch.component";
import ScreenLoader from "../../../components/loadingScreen/screenLoader.component";

const initialValues = {
  safe: false,
  safeType: "",
  safeStarred: false,
  security: false,
  securityStarred: false,
  securityType: "",
  cctv: false,
  cctvStarred: false,
  fireExtinguishers: false,
  fireExtinguishersStarred: false,
  smokeAlarms: false,
  smokeAlarmsStarred: false,
  fireAlarms: false,
  fireAlarmsStarred: false,
};

const securitySwitchOnly = [
  {
    name: "cctv",
    label: "CCTV",
    switchName: "cctvStarred",
  },

  {
    name: "fireExtinguishers",
    label: "Fire Extinguisher",
    switchName: "fireExtinguishersStarred",
  },

  {
    name: "smokeAlarms",
    label: "Smoke Alarms",
    switchName: "smokeAlarmsStarred",
  },

  {
    name: "fireAlarms",
    label: "Fire Alarms",
    switchName: "fireAlarmsStarred",
  },
];

const Security = ({ classes, handleChangePanel, expanded, setExpanded }) => {
  const [securityAmenities, setSecurityAmenities] = useState(() => {});
  const [safes, setSafes] = useState([]);
  const [securitys, setSecuritys] = useState([]);
  const { propertyId } = useParams();
  const [loading, setLoading] = useState(() => false);
  const uniqueid = localStorage.getItem("unique_id");
  const createdBy = localStorage.getItem("auth");
  const create = { uniqueId: uniqueid, createdBy: createdBy };

  const fetchSecurity = async () => {
    setLoading(true);
    try {
      const normalData = await Api.get(`AmenitiesSecurityvalue/${propertyId}`);
      const switchData = await Api.get(
        `AmenitiesSecurityStarredvalue/${propertyId}`
      );
      console.log(normalData.data);
      console.log(switchData.data);
      setSecurityAmenities({ ...normalData.data, ...switchData.data });
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (propertyId && expanded === "panel9") {
      fetchSecurity();
    }
  }, [expanded]);

  const Safe = async () => {
    const SafeData = {
      type: "Safe",
    };
    await Api.post("productdropdownonly", SafeData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setSafes(response.data);
    });
  };
  const Security = async () => {
    const SecurityData = {
      type: "Security",
    };
    await Api.post("productdropdownonly", SecurityData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setSecuritys(response.data);
    });
  };

  useEffect(() => {
    if (propertyId && expanded === "panel9") {
      Safe();
      Security();
    }
  }, [expanded]);

  const securitySelectOnly = [
    {
      name: "safe",
      label: "Safe",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "safeType",
      switchName: "safeStarred",
      dropdDownOptions: safes.map((option) => ({
        label: option,
        value: option,
      })),
    },
    {
      name: "security",
      label: "Security",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "securityType",
      switchName: "securityStarred",
      dropdDownOptions: securitys.map((option) => ({
        label: option,
        value: option,
      })),
    },
  ];

  const handleChangeAccordion = () => {
    setExpanded("panel10");
  };

  function onSubmit(fields, { setStatus }) {
    const id = securityAmenities.id;

    if (fields.safe == false) {
      fields.safeType = "";
      fields.safeStarred = false;
    }
    if (fields.securityStarred == false) {
      fields.security = false;
      fields.securityType = "";
    }
    if (fields.cctv == false) {
      fields.cctvStarred = false;
    }
    if (fields.fireExtinguishers == false) {
      fields.fireExtinguishersStarred = false;
    }
    if (fields.smokeAlarms == false) {
      fields.smokeAlarmsStarred = false;
    }
    if (fields.fireAlarms == false) {
      fields.fireAlarmsStarred = false;
    }
    setStatus();
    if (id) {
      updateSecurity(fields);
    } else {
      createSecurity(fields);
    }
  }

  function createStarred(fields) {
    fields.propertyId = propertyId;
    const newData = { ...fields, ...create };
    Api.post("AmenitiesSecurityStarred", newData);
  }

  function updateStarred(fields) {
    fields.updateBy = createdBy;
    Api.put("AmenitiesSecurityStarredupdate", fields);
  }

  function createSecurity(fields) {
    setLoading(true);
    fields.propertyId = propertyId;
    const newData = { ...fields, ...create };
    Api.post("AmenitiesSecurity", newData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      if (res.status === 200) {
        createStarred(fields);
        toast.success("Successfully Created");
        handleChangeAccordion();
        setLoading(false);
      }
    });
  }

  function updateSecurity(fields) {
    setLoading(true);
    fields.updateBy = createdBy;
    Api.put("AmenitiesSecurityupdate", fields, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      if (res.status === 200) {
        updateStarred(fields);
        toast.success("Successfully Updated");
        handleChangeAccordion();
        setLoading(false);
      }
    });
  }

  return (
    <Formik
      initialValues={
        !_.isEmpty(securityAmenities) ? securityAmenities : initialValues
      }
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values }) =>
        loading ? (
          <ScreenLoader open={loading} />
        ) : (
          <Form autoComplete="off">
            <Accordion
              square
              expanded={expanded === "panel9"}
              onChange={handleChangePanel("panel9")}
              className={classes.accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#f46d25",
                      background: "white",
                      borderRadius: "5px",
                    }}
                  />
                }
                className={classes.accordionSummaryStyles}
              >
                Security
              </AccordionSummary>
              <AccordionDetails className={classes.outerSectionStyles}>
                <Grid container spacing={1}>
                  {securitySelectOnly.map((amenity) => (
                    <InlineFormikFieldWithCheckSelectAndSwitch
                      amenity={amenity}
                      values={values}
                    />
                  ))}

                  {/**Security switch only */}
                  {securitySwitchOnly.map((amenity) => (
                    <FormikInlineCheckboxAndSwitchOnly
                      amenity={amenity}
                      values={values}
                    />
                  ))}
                </Grid>
              </AccordionDetails>
              <center>
                <Button
                  style={{ height: "30px", margin: "10px 0px" }}
                  type="submit"
                >
                  Save and Next
                </Button>
              </center>
            </Accordion>
          </Form>
        )
      }
    </Formik>
  );
};

export default Security;
