import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import Api from "../../../Service/Api";
import FormikInlineCheckboxAndSwitchOnly from "../../../components/amenityComponents/inlineCheckBoxAndSwitchOnly.component";
import InlineFormikFieldWithCheckSelectAndSwitch from "../../../components/amenityComponents/inlineCheckBoxWithSelectAndSwitch.component";
import ScreenLoader from "../../../components/loadingScreen/screenLoader.component";
import {
  butlerInitial,
  childcareServiceInitial,
  sightSeeingInitial,
  speciallyInitial,
} from "../../../redux/actions/amenitiesDropDownAction";

const initialValues = {
  luggageassistance: false,
  luggageassistanceType: "",
  luggageassistanceStarred: false,
  speciallyabledassistance: false,
  speciallyabledassistanceType: [],
  speciallyabledassistanceStarred: false,
  wakeupCallService: false,
  wakeupCallServiceStarred: false,
  electricalSockets: false,
  electricalSocketsStarred: false,
  electricalSocketsType: "",
  postalservices: false,
  postalservicesStarred: false,
  butlerServices: false,
  butlerServicesType: [],
  butlerServicesStarred: false,
  concierge: false,
  conciergeStarred: false,
  poolBeachtowels: false,
  poolBeachtowelsStarred: false,
  sightSeeing: false,
  sightSeeingType: [],
  sightSeeingStarred: false,
  childcareService: false,
  childcareServiceType: [],
  childcareServiceStarred: false,
};

const generalSwitchOnly = [
  {
    name: "concierge",
    label: "Concierge",
    switchName: "conciergeStarred",
  },
  {
    name: "luggageassistance",
    label: "Luggage Assistance",
    switchName: "luggageassistanceStarred",
  },

  {
    name: "wakeupCallService",
    label: "Wakeup Call",
    switchName: "wakeupCallServiceStarred",
  },
  {
    name: "electricalSockets",
    label: "Electrical Sockets",
    switchName: "electricalSocketsStarred",
  },

  {
    name: "postalservices",
    label: "Postal Service",
    switchName: "postalservicesStarred",
  },
  {
    name: "poolBeachtowels",
    label: "Pool Beach Towels",
    switchName: "poolBeachtowelsStarred",
  },
];

const GeneralServicesAmenities = ({
  classes,
  handleChangePanel,
  expanded,
  setExpanded,
}) => {
  const [generalServices, setGeneralServices] = useState({});
  const { propertyId } = useParams();
  const dispatch = useDispatch();
  const childCareServiceLists = useSelector(
    (state) => state.childCareServiceList.childCareServiceLists
  );
  const sightSeeingLists = useSelector(
    (state) => state.sightSeeingList.sightSeeingLists
  );
  const butlerLists = useSelector((state) => state.butlerList.butlerLists);
  const speciallyAbledLists = useSelector(
    (state) => state.speciallyAbledList.speciallyAbledLists
  );

  const fetchGeneralServices = async () => {
    setLoading(true);
    try {
      console.log(propertyId);
      const normalData = await Api.get(
        `AmenitiesGeneralServicevalue/${propertyId}`
      );
      const switchData = await Api.get(
        `AmenitiesGeneralServiceStarredvalue/${propertyId}`
      );
      console.log(normalData.data);
      console.log(switchData.data);
      setGeneralServices({ ...normalData.data, ...switchData.data });
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(true);
    }
  };

  useEffect(() => {
    if (propertyId && expanded == "panel2") fetchGeneralServices();
  }, [expanded]);

  useEffect(() => {
    if (propertyId && expanded == "panel2") {
      dispatch(sightSeeingInitial());
      dispatch(butlerInitial());
      dispatch(speciallyInitial());
      dispatch(childcareServiceInitial());
    }
  }, [expanded]);

  const generalServicesWithSelectField = [
    {
      name: "speciallyabledassistance",
      label: "Specially Abled Assistance",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "speciallyabledassistanceType",
      switchName: "speciallyabledassistanceStarred",
      dropdDownOptions: speciallyAbledLists,
    },
    {
      name: "butlerServices",
      label: "Butler Services",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "butlerServicesType",
      switchName: "butlerServicesStarred",
      dropdDownOptions: butlerLists,
    },
    {
      name: "sightSeeing",
      label: "Sight Seeing",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "sightSeeingType",
      switchName: "sightSeeingStarred",
      dropdDownOptions: sightSeeingLists,
    },
    {
      name: "childcareService",
      label: "Child Care Services",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "childcareServiceType",
      switchName: "childcareServiceStarred",
      dropdDownOptions: childCareServiceLists,
    },
  ];

  const data = { propertyId: propertyId };
  const uniqueid = localStorage.getItem("unique_id");
  const createdBy = localStorage.getItem("auth");
  const create = { uniqueId: uniqueid, createdBy: createdBy };
  const [loading, setLoading] = useState(() => false);

  function onSubmit(fields, { setStatus }) {
    const id = generalServices.id;

    setStatus();
    if (fields.luggageassistance == false) {
      fields.luggageassistanceStarred = false;
    }

    if (fields.speciallyabledassistance == false) {
      fields.speciallyabledassistanceType = [];
      fields.speciallyabledassistanceStarred = false;
    }
    if (fields.wakeupCallService == false) {
      fields.wakeupCallServiceStarred = false;
    }
    if (fields.electricalSockets == false) {
      fields.electricalSocketsStarred = false;
    }
    if (fields.postalservices == false) {
      fields.postalservicesStarred = false;
    }
    if (fields.butlerServices == false) {
      fields.butlerServicesType = [];
      fields.butlerServicesStarred = false;
    }
    if (fields.concierge == false) {
      fields.conciergeStarred = false;
    }
    if (fields.poolBeachtowels == false) {
      fields.poolBeachtowelsStarred = false;
    }
    if (fields.sightSeeing == false) {
      fields.sightSeeingType = [];
      fields.sightSeeingStarred = false;
    }
    if (fields.childcareService == false) {
      fields.childcareServiceType = [];
      fields.childcareServiceStarred = false;
    }

    if (id) {
      updateGeneralService(fields);
    } else {
      createGeneralService(fields);
    }
  }

  function createStarred(fields) {
    fields.propertyId = propertyId;
    const newData = { ...data, ...fields, ...create };
    Api.post("AmenitiesGeneralServiceStarred", newData);
  }
  function updateStarred(fields) {
    fields.updateBy = createdBy;
    Api.put("AmenitiesGeneralServiceStarredupdate", fields);
  }

  const handleAccordionChange = () => {
    setExpanded("panel3");
  };

  function createGeneralService(fields) {
    console.log(fields);
    setLoading(true);
    fields.propertyId = propertyId;
    const newData = { ...data, ...fields, ...create };
    console.log(newData);
    Api.post("AmenitiesGeneralService", newData).then((res) => {
      if (res) {
        createStarred(fields);
        toast.success("General Services Created");
        handleAccordionChange();
        setLoading(false);
      }
    });
  }

  function updateGeneralService(fields) {
    setLoading(true);
    fields.updateBy = createdBy;
    Api.put("AmenitiesGeneralServiceupdate", fields).then((res) => {
      if (res) {
        updateStarred(fields);
        toast.success("General Services Updated");
        handleAccordionChange();
        setLoading(false);
      }
    });
  }

  return (
    <Formik
      initialValues={generalServices || initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue }) =>
        loading ? (
          <ScreenLoader open={loading} />
        ) : (
          <Form autoComplete="off">
            <Accordion
              square
              expanded={expanded === "panel2"}
              onChange={handleChangePanel("panel2")}
              className={classes.accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#f46d25",
                      background: "white",
                      borderRadius: "5px",
                    }}
                  />
                }
                className={classes.accordionSummaryStyles}
              >
                General Services
              </AccordionSummary>
              <AccordionDetails className={classes.outerSectionStyles}>
                <Grid container spacing={3}>
                  {generalServicesWithSelectField.map((amenity) => (
                    <InlineFormikFieldWithCheckSelectAndSwitch
                      amenity={amenity}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ))}

                  {/**Switch only amenities */}
                  {generalSwitchOnly.map((amenity) => (
                    <FormikInlineCheckboxAndSwitchOnly
                      amenity={amenity}
                      values={values}
                    />
                  ))}
                </Grid>
              </AccordionDetails>
              <center>
                <Button
                  style={{ height: "30px", margin: "10px 0px" }}
                  type="submit"
                >
                  Save and Next
                </Button>
              </center>
            </Accordion>
          </Form>
        )
      }
    </Formik>
  );
};

export default GeneralServicesAmenities;
