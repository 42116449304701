// import { Grid, InputAdornment, Stack, Table, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Dialog, Slide, Stack, Table, TableBody, TableRow, TableHead, Tooltip, IconButton, Alert, Typography } from '@mui/material';
import React, { useState } from 'react';
import { twnButtonStyles } from '../../utils/townoStyle';
import { DualTextField } from '../CreatePackage/AgentCreatePackage';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#f46d25',
        color: "#fff",
        fontSize: 14,
        // fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        backgroundColor: '#fff',
        boxShadow: 'none',
        borderRadius: '5px',
        fontWeight: 'bold'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    border: "1px solid #eee",
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function AgentBookingRefund(props) {
    const { openRefund, closeFn, handleRefund, totalBookingAmount, totalAmountPaid, profit, amountRefunded, setAmountRefunded, amountRetained, setAmountRetained, revisedCommission, setRevisedCommission } = props
    const [amtRefundedError, setAmountRefundedError] = useState("");
    const [revisedCommissionError,setRevisedCommissionError]=useState("");

    const onChangeAmtRefunded = (event) => {
        if ((event.target.value / 1) > totalAmountPaid) {
            setAmountRefundedError("Can't be more that Total Paid Amount");
        } else { setAmountRefundedError("") }

        setAmountRefunded(event.target.value);
        setAmountRetained(totalAmountPaid - (event.target.value / 1));

    }

    const onChangeRevComm = (event)=>{
        if((event.target.value/1)>amountRetained){
            setRevisedCommissionError("Can't be more that Amount Retained");
        }else { setRevisedCommissionError("") }

        setRevisedCommission(event.target.value);
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            sx={{ maxHeight: '75vh', marginTop: "8%" }}
            open={openRefund}
            onClose={closeFn}
            TransitionComponent={Transition}
        >
            <div>
                <Typography variant="subtitle2" component="h6" style={{ ...twnButtonStyles.xlFonts, padding: "10px 0px 0px 10px" }}>Cancel Booking</Typography>
                <br />
                <Table size="small" style={{ "width": "94%", marginLeft: "2.5%" }}>
                    <TableHead style={{ height: '25px', backgroundColor: '#F46D25', color: '#fff', fonstSize: '12px' }}>
                        <TableRow>
                            <StyledTableCell style={{ borderTopLeftRadius: '5px' }}>Total Gross Amt.</StyledTableCell>
                            <StyledTableCell>Total Paid Amt.</StyledTableCell>
                            <StyledTableCell style={{ borderTopRightRadius: '5px' }}>Comission</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">{totalBookingAmount}</StyledTableCell>
                            <StyledTableCell component="th" scope="row">{totalAmountPaid}</StyledTableCell>
                            <StyledTableCell component="th" scope="row">{profit}</StyledTableCell>
                        </StyledTableRow>
                    </TableBody>

                </Table>

                <Stack style={{ padding: "15px 0px 10px 15px" }} spacing={2}>
                    <DualTextField label="Amount Refunded *" name={"amountRefunded"} value={amountRefunded} changeFn={onChangeAmtRefunded} />
                    {amtRefundedError && <Typography variant="subtitle2" style={{ margin: "0 3%", color: "#ff0000",textAlign:'right' }}>{amtRefundedError}</Typography>}
                    <DualTextField label="Amount Retained *" name={"amountRetained"} value={amountRetained} changeFn={() => { }} disabled={true} />
                    {/* <DualTextField label="Revised Commission *" name={"revisedCommission"} value={revisedCommission} changeFn={(event) => { setRevisedCommission(event.target.value) }} /> */}
                    <DualTextField label="Revised Commission *" name={"revisedCommission"} value={revisedCommission} changeFn= {onChangeRevComm}/>
                    {revisedCommissionError && <Typography variant="subtitle2" style={{ margin: "0 3%", color: "#ff0000",textAlign:'right' }}>{revisedCommissionError}</Typography>}
                </Stack>
            </div>
            <div style={{ backgroundColor: '#fff' }}>
                <Stack direction='row' spacing={2} justifyContent='center' style={{ margin: '1%' }}>
                    {(amountRefunded > 0 && amountRetained > 0 && revisedCommission > 0 && amountRefunded< totalAmountPaid && revisedCommission< amountRetained) ?
                        <button style={twnButtonStyles.orangeBtn} onClick={handleRefund}>Refund</button>
                        :
                        <button style={twnButtonStyles.disabledBtn}>Refund</button>
                    }
                    <button style={twnButtonStyles.blackBtn} autoFocus color="inherit" onClick={closeFn}>
                        Back
                    </button>
                </Stack>
            </div>
            <br />
        </Dialog>
    )
}