import { CaptchContext } from "../../context/captcha.context"
import styles from "./Captcha.module.css"
import { useContext, useEffect, useRef, useState } from "react"
import AutorenewIcon from "@material-ui/icons/Autorenew"
import { toast } from "react-toastify"
import { CheckCircle, CloseRounded } from "@material-ui/icons"

const Captcha = () => {
  const {
    captchaText,
    setCaptchaText,
    setUserInput,
    userInput,
    valid,
    setValid,
  } = useContext(CaptchContext)
  const canvasRef = useRef(null)
  const [isValidating, setIsValidating] = useState(false)

  useEffect(() => {
    const canvas = canvasRef.current
    const ctx = canvas.getContext("2d")
    initializeCaptcha(ctx)
  }, [])

  const generateRandomChar = (min, max) =>
    String.fromCharCode(Math.floor(Math.random() * (max - min + 1) + min))

  const generateCaptchaText = () => {
    let captcha = ""
    for (let i = 0; i < 2; i++) {
      captcha += generateRandomChar(65, 90)
      captcha += generateRandomChar(97, 122)
      captcha += generateRandomChar(48, 57)
    }
    return captcha
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("")
  }

  const drawCaptchaOnCanvas = (ctx, captcha) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
    const textColors = ["#f46d25", "rgb(130,130,130)"]
    const letterSpace = 150 / captcha.length
    for (let i = 0; i < captcha.length; i++) {
      const xInitialSpace = 25
      ctx.font = "20px Roboto Mono"
      ctx.fillStyle = textColors[Math.floor(Math.random() * 2)]
      ctx.fillText(
        captcha[i],
        xInitialSpace + i * letterSpace,

        // Randomize Y position slightly
        Math.floor(Math.random() * 16 + 25),
        100
      )
    }
  }

  const initializeCaptcha = (ctx) => {
    setUserInput("")
    const newCaptcha = generateCaptchaText()
    setCaptchaText(newCaptcha)
    drawCaptchaOnCanvas(ctx, newCaptcha)
  }

  const handleUserInputChange = (e) => {
    console.log(e.target.value)
    setUserInput(e.target.value)
  }

  const handleCaptchaSubmit = () => {
    setIsValidating(true)
    console.log({ userInput })
    console.log({ captchaText })
    if (userInput === captchaText) {
      setValid(true)
      toast.success("Verified")
    } else {
      toast.error("Invalid Captcha")
      setValid(false)
      const canvas = canvasRef.current
      const ctx = canvas.getContext("2d")
      initializeCaptcha(ctx)
    }
    setIsValidating(false)
  }
  return (
    <div className={styles.captchaContainer}>
      <div className={styles.wrapper}>
        <canvas ref={canvasRef} width="200" height="50"></canvas>
        <button
          className={styles.reloadButton}
          onClick={() => {
            initializeCaptcha(canvasRef.current.getContext("2d"))
          }}
        >
          <AutorenewIcon color="primary" />
        </button>
      </div>
      <>
        <input
          type="text"
          className={styles.userInput}
          placeholder="Enter the text in the image"
          value={userInput}
          onChange={handleUserInputChange}
        />

        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <button onClick={handleCaptchaSubmit} type="button">
            Verify
          </button>
        </div>
      </>
    </div>
  )
}

export default Captcha
