import { useContext, useReducer, useState } from "react"
import Captcha from "../Captcha/Captcha.component"
import styles from "./QueryForm.module.css"
import { CaptchContext } from "../../context/captcha.context"
import { toast } from "react-toastify"
import API from "../../../Service/Api"
import { ContactModalContext } from "../../context/contactModal.context"
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grow,
  Modal,
} from "@material-ui/core"

import ErrorIcon from "@material-ui/icons/Error"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"

const ActionTypes = {
  HANDLE_CHANGE: "HANDLE_CHANGE",
  RESET_FORM: "RESET_FORM",
}

const formReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case ActionTypes.HANDLE_CHANGE:
      return { ...state, [payload.key]: payload.value }
    case ActionTypes.RESET_FORM:
      return { name: "", number: "", email: "", message: "" }
    default:
      return state
  }
}

const QueryForm = () => {
  const [state, dispatch] = useReducer(formReducer, {}, () => ({
    name: "",
    number: null,
    email: "",
    message: "",
  }))

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [openStatusModal, setOpenStatusOpenModal] = useState(false)

  const [formStatus, setFormStatus] = useState(0)

  const { open, onClose } = useContext(ContactModalContext)

  const { userInput, captchaText, valid, setUserInput, setValid } =
    useContext(CaptchContext)

  const handleInputChange = (e) => {
    const { name, value } = e.target

    // console.log(value)
    dispatch({
      type: ActionTypes.HANDLE_CHANGE,
      payload: { key: name, value: value },
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (userInput !== captchaText) {
      // toast.error("Invalid Captcha")
      return
    }

    try {
      setIsSubmitting(true)
      const res = await API.post("sendEnquiry", state)
      if (res.status === 200) {
        // toast.success(`Our team will soon be in touch with you`)
        dispatch({ type: ActionTypes.RESET_FORM })
        setUserInput("")
        setOpenStatusOpenModal(true)
        setFormStatus(200)
        return
      }
      return Promise.reject(res)
    } catch (error) {
      if (error.name == "TypeError") {
        setOpenStatusOpenModal(true)
      }
    } finally {
      setIsSubmitting(false)
      setValid(false)
      // if (open) {
      //   onClose()
      // }
    }
  }
  return (
    <>
      <div className={styles.formGroup}>
        <div className={styles.formHeader}>
          Want to book a trip or inquiry ?
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <input
            name="name"
            type="text"
            value={state.name}
            className={styles.inputFields}
            onChange={handleInputChange}
            placeholder="Name"
          />
          <input
            name="number"
            value={state.number}
            type="number"
            className={styles.inputFields}
            onChange={handleInputChange}
            placeholder="Phone Number"
          />
          <input
            name="email"
            type="email"
            value={state.email}
            className={styles.inputFields}
            onChange={handleInputChange}
            placeholder="Mail Id"
          />
          <textarea
            name="message"
            type="text"
            value={state.message}
            className={styles.inputFields}
            onChange={handleInputChange}
            placeholder="Message"
          />
          <Captcha />
          <button
            className={styles.submitBtn}
            disabled={!valid || isSubmitting}
            type="submit"
          >
            {isSubmitting ? (
              <i className="fa fa-circle-o-notch fa-spin" />
            ) : (
              " Submit"
            )}
          </button>
        </form>
      </div>
      <Modal
        open={openStatusModal}
        onClose={() => {
          setOpenStatusOpenModal(false)
          onClose()
        }}
        className={styles.statusModal}
        closeAfterTransition
        BackdropProps={{
          style: { background: "none", backdropFilter: "blur(0.5rem)" },
          timeout: 500,
        }}
      >
        <Grow in={openStatusModal}>
          <Card className={styles.statusModalBody}>
            <CardContent className={styles.cardContent}>
              <Grow in={openStatusModal} style={{ transitionDelay: "500ms" }}>
                {formStatus === 200 ? (
                  <CheckCircleOutlineIcon style={{ color: "green" }} />
                ) : (
                  <ErrorIcon color="error" />
                )}
              </Grow>
              <p
                style={{
                  padding: "0",
                  margin: "0",
                  fontWeight: "bold",
                  color: formStatus === 200 ? "green" : "red",
                }}
              >
                {formStatus === 200 ? "Success" : "Error"}
              </p>
              {formStatus === 200 ? (
                <em>
                  <b>We'll get back to you shortly.</b>
                </em>
              ) : (
                <em>
                  <b>Try after sometime.</b>
                </em>
              )}
            </CardContent>
            <CardActionArea
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                onClick={() => {
                  setOpenStatusOpenModal(false)
                  onClose()
                }}
              >
                Close
              </Button>
            </CardActionArea>
          </Card>
        </Grow>
      </Modal>
    </>
  )
}

export default QueryForm
