/* eslint-disable eqeqeq */
import { filter } from "lodash"
import { useEffect, useRef, useState } from "react"
// material
import {
  Button,
  Card,
  Grid,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import _ from "lodash"
import { Link } from "react-router-dom"
import XLSX from "xlsx"
import { TableListHead } from "../../components/tabel"

import DateFnsUtils from "@date-io/date-fns"
import { makeStyles } from "@material-ui/core/styles"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { format } from "date-fns"
import { useHistory } from "react-router-dom"
import Api from "../../Service/Api"
import MaterialSelect from "../../components/Select/MaterialSelect"
import { formatter } from "../../utils/formatNumber"
import Loader from "./../../components/Loader/Loader"
import "./BookingList.scss"

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  if (query) {
    return filter(
      array,
      (_designation) =>
        _designation.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    )
  }
  return stabilizedThis.map((el) => el[0])
}

const TABLE_HEAD_CONST = [
  { id: "bookingId", label: "Booking ID", alignRight: false },
  { id: "clientName", label: "Client Name", alignRight: false },
  { id: "hotelName", label: "Hotel Name", alignRight: false },
  { id: "agentName", label: "Agent Name", alignRight: false },
  { id: "checkout", label: "CheckIn", alignRight: false },
  { id: "noofRooms", label: "Rooms", alignRight: false },
]

export default function BookingList() {
  const [tableHead, setTableHead] = useState(TABLE_HEAD_CONST)

  const classes = useStyles()
  const initalValue = {
    bookingId: "",
    clientName: "",
    email: "",
    contactno: "",
    bookingstatus: "",
    agentName: "",
  }
  const history = useHistory()
  const [bookingList, setBookingList] = useState([])

  console.log("bookingList: ", bookingList)
  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("name")
  const [rowsPerPage, setRowsPerPage] = useState(10)
  var uniqueid = localStorage.getItem("unique_id")
  const [checkin, setCheckin] = useState(null)
  const [checkout, setCheckout] = useState(null)
  const [searchData, setSearchData] = useState(initalValue)
  const [bookingDateFrom, setBookingDateFrom] = useState(null)
  const [bookingDateTo, setBookingDateTo] = useState(null)
  const [errorFrom, setErrorFrom] = useState(false)
  const [errorTo, setErrorTo] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [bookingstatus, setBookingstatus] = useState(null)
  const [hotelName, setHotelName] = useState(null)
  const [agentName, setAgentName] = useState(null)
  const [hotelList, setHotelList] = useState([])
  const [agentList, setAgentList] = useState([])
  const [loading, setLoading] = useState(false)
  const [pdfloading, setPdfloading] = useState(false)
  const { bookingId, clientName, email, contactno } = searchData
  const [width, setWidth] = useState(window.innerWidth)
  const [searchName, setSearchName] = useState("")
  const [searchList, setSearchList] = useState(null)
  const [pageNumber, setPageNumber] = useState(0)
  const DataPerPage = 10
  const [mobileUIData, setMobileUIData] = useState([])
  let hasAdmin = localStorage.getItem("role")

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const pagesVisited = pageNumber * DataPerPage

  const pageCount = Math.ceil(bookingList.length / DataPerPage)

  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  const handleChangeSearchList = (selectedOption) => {
    setSearchList(selectedOption)
  }

  const handleChangePayment = (selectedOption) => {
    setPaymentStatus(selectedOption)
  }

  const handleChangeHotel = (option) => {
    setHotelName(option)
  }
  const handleChangeAgent = (option) => {
    setAgentName(option)
  }

  const handleChangeBookingstatus = (selectedOption) => {
    setBookingstatus(selectedOption)
  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target
    setSearchData({ ...searchData, [name]: value })
  }
  const Submit = (e) => {
    e.preventDefault()
    if (bookingDateFrom !== null) {
      if (bookingDateTo == null) {
        setErrorTo(true)
      } else {
        setErrorTo(false)
      }
    }
    if (bookingDateTo !== null) {
      if (bookingDateFrom == null) {
        setErrorFrom(true)
      } else {
        setErrorFrom(false)
      }
    }
    const data = {
      checkIn: checkin !== null ? format(checkin, "yyyy-MM-dd") : "",
      checkOut: checkout !== null ? format(checkout, "yyyy-MM-dd") : "",
      from:
        bookingDateFrom !== null ? format(bookingDateFrom, "yyyy-MM-dd") : "",
      to: bookingDateTo !== null ? format(bookingDateTo, "yyyy-MM-dd") : "",
      uniqueId: uniqueid,
      paymentstatus: paymentStatus !== null ? paymentStatus.value : "",
      bookingstatus: bookingstatus !== null ? bookingstatus.value : "",
      hotelname: hotelName !== null ? hotelName.value : "",
      agentName: agentName !== null ? agentName.value : "",
    }

    console.log("postData", data)

    let url =
      localStorage.getItem("role") === "Admin" ||
      localStorage.getItem("role") === "Super Admin" ||
      localStorage.getItem("role") === "Finance Team"
        ? `adminbookingsearch`
        : `staffbookingsearch`
    if (bookingId !== "") {
      // url += "bookingId";
    }
    if (bookingId == "") {
      if (clientName !== "") {
        // url += "clientName";
      }

      if (email !== "") {
        // url += "clientEmail";
      }

      if (contactno !== "") {
        // url += "clientMobile";
      }

      if (hotelName !== null) {
        // url += "hotelName";
      }

      if (bookingstatus !== null) {
        // url += "bookingStatus";
      }

      if (agentName !== "") {
        // url += "createdBy";
      }

      if (checkin !== null) {
        // url += "checkIn";
      }

      if (checkout !== null) {
        // url += "checkOut";
      }

      if (paymentStatus !== null) {
        // url += "paymentStatus";
      }

      if (bookingDateFrom !== null) {
        // url += "from";
      }

      if (bookingDateTo !== null) {
        // url += "to";
      }
    }
    const QueryValue = { ...searchData, ...data }
    localStorage.getItem("role") === "Admin" ||
    localStorage.getItem("role") === "Super Admin" ||
    localStorage.getItem("role") === "Finance Team"
      ? Api.post(`${url}`, QueryValue).then((res) => {
          console.log(res.data)
          let sorted = _.orderBy(res.data, ["bookingId"], ["desc"])
          setBookingList(sorted)
        })
      : Api.post(`${url}/${localStorage.getItem("auth")}`, QueryValue).then(
          (res) => {
            let sorted = _.orderBy(res.data, ["bookingId"], ["desc"])
            setBookingList(sorted)
          }
        )
  }

  const MobileSubmit = (e) => {
    e.preventDefault()

    let url =
      localStorage.getItem("role") === "Admin" ||
      localStorage.getItem("role") === "Super Admin" ||
      localStorage.getItem("role") === "Finance Team"
        ? `adminbookingsearch`
        : `staffbookingsearch/`
    if (searchList.value == "bookingId") {
      // url += "bookingId";
    }

    if (searchList.value == "clientName") {
      // url += "clientName";
    }

    if (searchList.value == "email") {
      // url += "clientEmail";
    }

    if (searchList.value == "contactno") {
      // url += "clientMobile";
    }
    if (searchList.value == "agentName") {
      // url += "createdBy";
    }

    const obj = { [searchList.value]: searchName }
    const unique = { uniqueId: uniqueid }
    const QueryValue = { ...obj, ...unique }
    localStorage.getItem("role") === "Admin" ||
    localStorage.getItem("role") === "Super Admin" ||
    localStorage.getItem("role") === "Finance Team"
      ? Api.post(`${url}`, QueryValue).then((res) => {
          let sorted = _.orderBy(res.data, ["bookingId"], ["desc"])
          setBookingList(sorted)
        })
      : Api.post(`${url}/${localStorage.getItem("auth")}`, QueryValue).then(
          (res) => {
            let sorted = _.orderBy(res.data, ["bookingId"], ["desc"])
            setBookingList(sorted)
          }
        )
  }
  const resetData = (e) => {
    setSearchData(initalValue)
    setCheckin(null)
    setCheckout(null)
    setBookingDateFrom(null)
    setBookingDateTo(null)
    setPaymentStatus(null)
    setBookingstatus(null)
    BookingListData()
    setErrorFrom(false)
    setErrorTo(false)
  }

  useEffect(() => {
    BookingListData()
    hotelFetch()
    agentFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const BookingListData = () => {
    setLoading(true)

    localStorage.getItem("role") === "Admin" ||
    localStorage.getItem("role") === "Super Admin" ||
    localStorage.getItem("role") === "Finance Team"
      ? Api.get(`bookingall/${uniqueid}`).then((res) => {
          console.log(res.data)
          let sorted = _.orderBy(res.data, ["createdAt"], ["desc"])
          setBookingList(sorted)
          setLoading(false)
        })
      : Api.get(
          `staffbookinglist/${uniqueid}/${localStorage.getItem("auth")}`
        ).then((res) => {
          let sorted = _.orderBy(res.data, ["createdAt"], ["desc"])
          setBookingList(sorted)
          setLoading(false)
        })
  }
  const hotelFetch = () => {
    Api.get(`propertydisplaynameall/${uniqueid}`).then((res) => {
      setHotelList(res.data)
    })
  }
  const agentFetch = () => {
    Api.get(`agentlistforsearch/${uniqueid}`).then((res) => {
      setAgentList(res.data)
      console.log(res.data)
    })
  }

  /*const Download = (data) => {
    setPdfloading(true);
    const data1 = data.bookingId;
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = `${data1}.pdf`;
    Api.get(`genpdf/${data1}.pdf/${data.uniqueId}/${data.bookingId}`, {
        responseType: "blob",
      })
      .then((res) => {
        link.href = URL.createObjectURL(new Blob([res.data], { type: "pdf" }));
        link.click();
        setPdfloading(false);
      });
  };*/

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const downloadExcel = () => {
    const newData = bookingList.map((row) => {
      delete row.id
      delete row.uniqueId
      delete row.createdAt
      delete row.updatedBy
      delete row.updatedAt
      delete row.status
      return row
    })
    const workSheet = XLSX.utils.json_to_sheet(newData)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, "Branch List")
    // const buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
    XLSX.writeFile(workBook, "BookingList.xlsx")
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - bookingList.length) : 0

  const filteredBookingList = applySortFilter(
    bookingList,
    getComparator(order, orderBy)
  )

  //pagination
  const totalDataLength = filteredBookingList.length
  const dataPerPage = 5
  let noOfPages = Math.ceil(totalDataLength / dataPerPage)
  console.log("totalDataLength", totalDataLength, noOfPages)
  const [currentPageNo, setCurrentPageNo] = useState(1)
  let tempDataIndex = currentPageNo * dataPerPage //variable for min and max data range for page
  let lastDataIndex = Math.min(tempDataIndex, totalDataLength)
  let firstDataIndex = 0
  console.log("first and last indices of data:", firstDataIndex, lastDataIndex)

  const changePageNo = (value) => {
    let tempPageNo = currentPageNo + value //value can be 1 or -1 depending upon arrow clicked
    if (tempPageNo >= 1 && tempPageNo <= noOfPages) {
      setCurrentPageNo(tempPageNo)
    }
  }

  const [newTable, setNewTable] = useState([
    { label: "Booking Date", id: "createdAt", type: "date", selected: false },
    { label: "Nights", id: "night", type: "normal", selected: false },
    { label: "Gross", id: "totalGrossPrice", type: "price", selected: false },
    { label: "Net", id: "totalNetPrice", type: "price", selected: false },
    { label: "Profit", id: "profitTax", type: "price", selected: false },
    {
      label: "Payable To Towno",
      id: "townoPending",
      type: "price",
      selected: false,
    },
    {
      label: "Pay At Hotel",
      id: "hotelPending",
      type: "price",
      selected: false,
    },
    {
      label: "Amount Received",
      id: "paidAmount",
      type: "price",
      selected: false,
    },
  ])

  const [headValues, setHeadValues] = useState([
    { label: "Booking Date", id: "createdAt", type: "date", selected: false },
    { label: "Nights", id: "night", type: "normal", selected: false },
    { label: "Gross", id: "totalGrossPrice", type: "price", selected: false },
    { label: "Net", id: "totalNetPrice", type: "price", selected: false },
    { label: "Profit", id: "profitTax", type: "price", selected: false },
    {
      label: "Payable To Towno",
      id: "townoPending",
      type: "price",
      selected: false,
    },
    {
      label: "Pay At Hotel",
      id: "hotelPending",
      type: "price",
      selected: false,
    },
    {
      label: "Amount Received",
      id: "paidAmount",
      type: "price",
      selected: false,
    },
  ])

  const handleChange = (i) => {
    console.log(":Index:", i)
    const values = [...headValues]
    values[i].selected = !values[i].selected
    console.log(values)
    setHeadValues(values)
  }

  const currentPage = useRef(1)
  const loaderRef = useRef(null)
  const [showButton, setShowButton] = useState(false)

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement
    const scrollPosition = window.pageYOffset

    // if (windowHeight + scrollPosition >= documentHeight) {
    //   setPage((prevPage) => prevPage + 1);
    // }

    console.log(
      "scroll height",
      Math.ceil(window.innerHeight + document.documentElement.scrollTop)
    )

    console.log("offsetHeight", document.documentElement.offsetHeight)

    scrollPosition > 300 ? setShowButton(true) : setShowButton(false)

    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) >=
      document.documentElement.offsetHeight
    ) {
      setLoading(true)

      setTimeout(() => {
        setLoading(false)
        changePageNo(1)
      }, 2000)
      console.log("ok")
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    width <= 768 && window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [firstDataIndex, lastDataIndex, currentPageNo, handleScroll])

  return (
    <div style={{ padding: "5.9% 1% 1% 1%" }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            backgroundColor: "#000",
            color: "#fff",
            textAlign: "center",
            padding: 4,
          }}
        >
          <Typography variant="h6">Column Settings</Typography>
        </div>
        <DialogContent>
          <Grid container spacing={2}>
            {headValues.map((item, i) => (
              <>
                <Grid item xs={3}>
                  <Typography variant="body1" sx={{ fontWeight: 800 }}>
                    {item.label}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Switch
                    checked={item.selected}
                    onChange={() => handleChange(i)}
                    // name="checkedB"
                    color="primary"
                  />
                </Grid>
              </>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              const values = headValues.filter((item) => item.selected === true)
              // const columns=JSON.parse(JSON.stringify(headValues))
              const columns = _.cloneDeep(headValues)
              setTableHead([...TABLE_HEAD_CONST, ...values])
              setNewTable(columns)
              handleClose()
            }}
            color="primary"
          >
            Submit
          </Button>
          <Button
            onClick={() => {
              setHeadValues(_.cloneDeep(newTable))
              handleClose()
            }}
            color="secondary"
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={pdfloading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading && width > 768 ? (
        <Loader />
      ) : width <= 768 ? (
        <div style={{ padding: "15% 0%", margin: "0px 20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MaterialSelect
                options={[
                  { value: "bookingId", label: "Booking Id" },
                  { value: "clientName", label: "Client Name" },
                  { value: "email", label: "Email" },
                  { value: "contactno", label: "Contact No" },
                  { value: "agentName", label: "Agent Name" },
                ]}
                placeholder="Search By"
                value={searchList}
                onChange={handleChangeSearchList}
                isClearable={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="searchName"
                value={searchName}
                variant="outlined"
                size="small"
                label="Search"
                fullWidth
                onChange={(e) => {
                  setSearchName(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <center>
                <Button onClick={MobileSubmit}>Submit</Button>
                <Button
                  type="reset"
                  onClick={resetData}
                  style={{ background: "#121212", marginLeft: "10px" }}
                >
                  Reset
                </Button>
              </center>
            </Grid>
          </Grid>

          {showButton && (
            <IconButton
              style={{
                position: "fixed",
                top: "87%",
                left: "80%",
                rotate: "-90deg",
                backgroundColor: "black",
                color: "#f46d25",
                height: "30px",
                width: "30px",
                zIndex: 1000,
              }}
              onClick={scrollToTop}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          )}

          {filteredBookingList
            .slice(firstDataIndex, lastDataIndex)
            .map((row) => {
              const {
                id,
                bookingId,
                clientName,
                hotelName,
                checkout,
                checkin,
                night,
                noofRooms,
                totalGrossPrice,
                totalNetPrice,
                paidAmount,
                townoPending,
                hotelPending,
                profit,
              } = row
              return (
                <div key={id}>
                  <div className="bookingcard" variant="outlined">
                    <div className="title">
                      <h2
                        onClick={() =>
                          localStorage.getItem("role") !== "ViewOnly" &&
                          history.push(
                            `/konnect/bookingvoucher/${row.bookingId}`
                          )
                        }
                        className={classes.link}
                      >
                        {bookingId}
                      </h2>
                      <p>
                        {format(new Date(checkin), "d MMM yy")} -{" "}
                        {format(new Date(checkout), "d MMM yy")}
                      </p>
                    </div>
                    <div className="bookingContent">
                      <h2>{clientName}</h2>
                      <p>
                        <span className="circle"></span>Nights : <b>{night}</b>
                      </p>
                    </div>
                    <div className="bookingContent">
                      <h2>{hotelName}</h2>
                      <p>
                        <span className="circle"></span>Rooms :{" "}
                        <b>{noofRooms}</b>
                      </p>
                    </div>
                    <div className="bookingContent1">
                      <p>Gross : {totalGrossPrice}</p>
                      <p>Net : {totalNetPrice}</p>
                      <p> Amount Paid : {paidAmount} </p>
                      <p> Payable to Towno: {townoPending}</p>
                      <p>Pay at Hotel: {hotelPending}</p>
                    </div>
                    <div className="bookingContent">
                      <h2>Profit : {profit}</h2>
                    </div>
                  </div>
                </div>
              )
            })}
          {/* <Pagination
              count={pageCount}
              color="primary"
              onPageChange={changePage}
            /> */}
          <div ref={loaderRef} />
          {loading && (
            <div
              style={{
                top: 0,
                width: "100%",
                height: "5%",
                padding: "0",
                margin: "0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Loader />
            </div>
          )}
        </div>
      ) : (
        <div>
          <Paper variant="outlined" className={classes.paper}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <form onSubmit={Submit}>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <TextField
                      name="bookingId"
                      variant="outlined"
                      size="small"
                      label="Booking Id"
                      fullWidth
                      value={bookingId}
                      onChange={handleChangeInput}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      name="clientName"
                      value={clientName}
                      variant="outlined"
                      size="small"
                      label="Client Name"
                      fullWidth
                      onChange={handleChangeInput}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      name="email"
                      value={email}
                      variant="outlined"
                      size="small"
                      label="Email Id"
                      onChange={handleChangeInput}
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextField
                      name="contactno"
                      value={contactno}
                      variant="outlined"
                      size="small"
                      label="Contact No"
                      fullWidth
                      onChange={handleChangeInput}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <MaterialSelect
                      placeholder="Hotels"
                      options={hotelList.map((hotel) => ({
                        label: hotel.displayName,
                        value: hotel.displayName,
                      }))}
                      value={hotelName}
                      onChange={handleChangeHotel}
                      isClearable={true}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <MaterialSelect
                      options={[
                        { value: "compeleted", label: "Compeleted" },
                        { value: "pending", label: "Pending" },
                        { value: "cancel", label: "Cancel" },
                      ]}
                      placeholder="Booking Status"
                      value={bookingstatus}
                      onChange={handleChangeBookingstatus}
                      isClearable={true}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <MaterialSelect
                      options={[
                        { value: "paid", label: "Paid" },
                        { value: "pending", label: "Pending" },
                      ]}
                      placeholder="Payment Status"
                      value={paymentStatus}
                      onChange={handleChangePayment}
                      isClearable={true}
                    />
                  </Grid>
                  <Grid item md={3}>
                    {/* <TextField
                      name="agentName"
                      value={agentName}
                      variant="outlined"
                      size="small"
                      label="Agent Name"
                      fullWidth
                      onChange={handleChangeInput}
                    /> */}
                    <MaterialSelect
                      placeholder="Agent Name"
                      options={agentList.map((agent) => ({
                        label: agent,
                        value: agent,
                      }))}
                      value={agentName}
                      onChange={handleChangeAgent}
                      isClearable={true}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <div style={{ position: "relative" }}>
                      <DatePicker
                        label="Check-In"
                        inputVariant="outlined"
                        fullWidth
                        size="small"
                        animateYearScrolling
                        format="dd/MM/yyyy"
                        variant="inline"
                        autoOk="true"
                        value={checkin}
                        onChange={(e) => setCheckin(e)}
                      />
                      <CalendarTodayIcon className={classes.icon} />
                    </div>
                  </Grid>

                  <Grid item md={3}>
                    <div style={{ position: "relative" }}>
                      <DatePicker
                        label="Check-Out"
                        inputVariant="outlined"
                        fullWidth
                        size="small"
                        animateYearScrolling
                        format="dd/MM/yyyy"
                        variant="inline"
                        autoOk="true"
                        value={checkout}
                        onChange={(date) => setCheckout(date)}
                      />
                      <CalendarTodayIcon className={classes.icon} />
                    </div>
                  </Grid>

                  <Grid item md={3}>
                    <div style={{ position: "relative" }}>
                      <DatePicker
                        label="Booking Date From"
                        inputVariant="outlined"
                        fullWidth
                        size="small"
                        animateYearScrolling
                        format="dd/MM/yyyy"
                        variant="inline"
                        autoOk="true"
                        value={bookingDateFrom}
                        onChange={(e) => setBookingDateFrom(e)}
                      />
                      <CalendarTodayIcon className={classes.icon} />
                    </div>
                    {errorFrom ? (
                      <div style={{ color: "red" }}>
                        Booking Date From Required
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item md={3}>
                    <div style={{ position: "relative" }}>
                      <DatePicker
                        label="Booking Date To"
                        inputVariant="outlined"
                        size="small"
                        fullWidth
                        format="dd/MM/yyyy"
                        animateYearScrolling
                        variant="inline"
                        autoOk="true"
                        value={bookingDateTo}
                        onChange={(e) => setBookingDateTo(e)}
                      />
                      <CalendarTodayIcon className={classes.icon} />
                    </div>
                    {errorTo ? (
                      <div style={{ color: "red" }}>
                        Booking Date To Required
                      </div>
                    ) : null}
                  </Grid>
                  <Grid item md={12}>
                    <center>
                      <Button type="submit">Search</Button>
                      <Button
                        type="reset"
                        onClick={resetData}
                        style={{ background: "#121212", marginLeft: "10px" }}
                      >
                        Reset
                      </Button>
                    </center>
                  </Grid>
                </Grid>
              </form>
            </MuiPickersUtilsProvider>
          </Paper>
          <div style={{ textAlign: "end", marginBottom: "15px" }}>
            <Button
              onClick={() => {
                handleClickOpen()
              }}
              style={{ marginLeft: "10px" }}
              size="small"
            >
              Filter
            </Button>
            <Button
              component={Link}
              to={
                hasAdmin === "Hotel Admin"
                  ? "/konnect/hotelNewBooking"
                  : "/konnect/newBooking"
              }
              style={{ marginLeft: "10px" }}
              size="small"
            >
              New Booking
            </Button>
            <Button
              size="small"
              onClick={downloadExcel}
              style={{ marginLeft: "10px" }}
              color="secondary"
            >
              Excel
            </Button>
          </div>

          <Card>
            <TableContainer>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={tableHead}
                  rowCount={bookingList.length}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {filteredBookingList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        bookingId,
                        createdAt,
                        clientName,
                        hotelName,
                        createdBy,
                        // checkout,
                        checkin,
                        night,
                        noofRooms,
                        totalGrossPrice,
                        totalNetPrice,
                        paidAmount,
                        townoPending,
                        hotelPending,
                        bookingStatus,
                        profit,
                      } = row
                      return (
                        <TableRow
                          key={id}
                          tabIndex={-1}
                          className={
                            bookingStatus == "Cancel" ? classes.tableRow : null
                          }
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            onClick={() =>
                              history.push(
                                `/konnect/bookingvoucher/${row.bookingId}`
                              )
                            }
                            className={
                              bookingStatus == "Cancel"
                                ? classes.links
                                : classes.link
                            }
                          >
                            {bookingId}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ textTransform: "capitalize" }}
                          >
                            {clientName}
                          </TableCell>
                          <TableCell align="left">{hotelName}</TableCell>
                          <TableCell align="left">{createdBy}</TableCell>
                          <TableCell align="left">
                            <span>{format(new Date(checkin), "d MMM yy")}</span>{" "}
                          </TableCell>
                          <TableCell align="left">{noofRooms}</TableCell>

                          {newTable
                            .filter?.((item) => item.selected === true)
                            ?.map((val, i) =>
                              val.type === "normal" ? (
                                <TableCell align="left" key={i}>
                                  {row?.[val.id]}
                                </TableCell>
                              ) : val.type === "date" ? (
                                <TableCell>
                                  <span>
                                    {format(
                                      new Date(row?.[val.id]),
                                      "d MMM yy"
                                    )}
                                  </span>
                                </TableCell>
                              ) : val.type === "price" ? (
                                <TableCell>
                                  {formatter.format(row?.[val.id])}
                                </TableCell>
                              ) : null
                            )}
                        </TableRow>
                      )
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={9} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={bookingList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    background: "#F46D25",
    color: "#fff",
    "&:hover": {
      background: "#F46D25",
    },
  },
  main: {
    padding: "100px 20px 0px 20px",
  },
  link: {
    textDecoration: "underline",
    color: "#F46D25",
    cursor: "pointer",
  },
  links: {
    textDecoration: "underline",
    color: "black",
    cursor: "pointer",
  },
  paper: {
    background: "#F4F4F4",
    padding: "15px 25px",
    marginBottom: "15px",
  },
  icon: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    position: "absolute",
    right: "15px",
    top: "5px",
    color: "#f46d25",
  },
  tableRow: {
    backgroundColor: "#F4F4F4",
  },
}))
