/* eslint-disable eqeqeq */
import { Button, Dialog, Grid, TextField } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto"
import { ErrorMessage, Field, Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import Select, { components } from "react-select"
import { toast } from "react-toastify"
import * as Yup from "yup"
import Api from "../../../Service/Api"
import { baseurl } from "../../../Service/httpCommon"
import Thumb from "../../Staff/Thumb"

const { ValueContainer, Placeholder } = components

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  )
}

const useStyles = makeStyles(() => ({
  paper: {
    padding: "10px 20px",
    boxShadow: "0px 1px 4px 1px rgba(52, 58, 64, 0.1)",
    borderRadius: "4px",
    background: "#F4F4F4",
    marginTop: "10px",
    "@media (max-width: 767px)": {
      boxShadow: "none",
      padding: "10px",
    },
  },
  heading: {
    margin: "10px",
    "@media (max-width: 767px)": {
      display: "none",
    },
  },
  dialogPaper: {
    minHeight: "100%",
    minWidth: "85%",
    position: "absolute",
    margin: "0px",
    right: "0",
    zIndex: "1000",
    padding: "20px",
    "@media (max-width: 767px)": {
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      height: "100%",
      overflowY: "scroll",
      maxWidth: "100%",
      minHeight: "95%",
    },
  },
  error: {
    color: "red",
  },
}))

export default function LastMinuteDealForm(props) {
  const classes = useStyles()
  var uniqueid = localStorage.getItem("unique_id")
  var createdBy = localStorage.getItem("auth")
  const [hotelList, setHotelList] = useState([])
  const [lastMinute, setLastMinute] = useState({
    propertyId: "",
    propertyName: "",
    starRate: "",
    city: "",
    minmumprice: "",
    image: "",
    offerPercentage: "",
    offerVaild: "",
    offerLabel: "",
    orderNo: "",
    topLeftLabel: "",
  })
  const create = { uniqueId: uniqueid, createdBy: createdBy }
  const { onClose, selectedId, open } = props

  useEffect(() => {
    AllHotel()
    if (selectedId) {
      Api.get(`agentlastminutedeal/${selectedId}`).then((res) => {
        res.data.propertyName = {
          label: res.data.propertyName,
          value: res.data.propertyId,
        }
        setLastMinute(res.data)
        console.log("New data--->", res.data)
      })
    }
  }, [selectedId])

  // Hotel Name List get
  const AllHotel = async () => {
    await Api.get("propertyNameAll").then((res) => {
      setHotelList(res.data)
    })
  }

  function onSubmit(fields, { setStatus }) {
    const id = selectedId
    setStatus()
    if (id) {
      updateLastMinute(fields)
    } else {
      createLastMinute(fields)
    }
  }

  function createLastMinute(fields) {
    if (fields.propertyName) {
      fields.propertyName = fields.propertyName.label
    }
    const formData = new FormData()
    formData.append("id", 0)
    formData.append("uniqueId", uniqueid)
    formData.append("createdBy", createdBy)
    formData.append("image", fields.image)
    formData.append("propertyId", fields.propertyId)
    formData.append("propertyName", fields.propertyName)
    formData.append("starRate", fields.starRate)
    formData.append("city", fields.city)
    formData.append("minmumprice", fields.minmumprice)
    formData.append("orderNo", fields.orderNo)
    formData.append("topLeftLabel", fields.topLeftLabel)
    formData.append("offerPercentage", fields.offerPercentage)
    formData.append("offerVaild", fields.offerVaild)
    formData.append("offerLabel", fields.offerLabel)
    Api.post("agentlastminutedeal", formData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      onClose(true)
      toast.success("Added Successfully")
      setLastMinute({
        propertyId: "",
        propertyName: "",
        starRate: "",
        city: "",
        minmumprice: "",
        image: "",
      })
    })
  }

  function updateLastMinute(fields) {
    if (fields.propertyName) {
      fields.propertyName = fields.propertyName.label
    }
    const formData = new FormData()
    formData.append("id", lastMinute.id)
    formData.append("uniqueId", uniqueid)
    formData.append("createdBy", createdBy)
    formData.append("image", fields.image)
    formData.append("propertyId", fields.propertyId)
    formData.append("propertyName", fields.propertyName)
    formData.append("starRate", fields.starRate)
    formData.append("city", fields.city)
    formData.append("minmumprice", fields.minmumprice)
    formData.append("orderNo", fields.orderNo)
    formData.append("topLeftLabel", fields.topLeftLabel)
    formData.append("offerPercentage", fields.offerPercentage)
    formData.append("offerVaild", fields.offerVaild)
    formData.append("offerLabel", fields.offerLabel)
    Api.post("agentlastminutedeal", formData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      onClose(true)
      toast.success("Update Successfull")
      setLastMinute({
        propertyId: "",
        propertyName: "",
        starRate: "",
        city: "",
        minmumprice: "",
        image: "",
      })
    })
  }

  const handleClose = () => {
    onClose(true)
    setLastMinute({
      propertyId: "",
      propertyName: "",
      starRate: "",
      city: "",
      minmumprice: "",
      image: "",
      topLeftLabel: "",
    })
  }

  const Hoteloptions =
    hotelList &&
    hotelList.map((hotel) => {
      return { label: hotel.displayName, value: hotel.propertyId }
    })

  const validationSchema = Yup.object({
    image: Yup.mixed()
      .test(
        "fileSize",
        "File size must be less than or equal to 600KB",
        (value) => {
          return value && value.size <= 614400 // 600KB in bytes
        }
      )
      .required("Image Required"),
    starRate: Yup.string().required("Star Rating is Required"),
    propertyName: Yup.object().required("Property Name is Required"),
    city: Yup.string().required("City is Required").max(25),
    minmumprice: Yup.string().required("Minmum Price is Required"),
    offerPercentage: Yup.number()
      .max(100, "Percentage cannot be more than 100")
      .required("Offer Percentage is Required"),
    orderNo: Yup.number().min(1).required("Order No is Required"),
    offerLabel: Yup.string().max(25).required("Offer Label is Required"),
    offerVaild: Yup.string().max(25).required("Offer Vaild is Required"),
    topLeftLabel: Yup.string().max(25).required("Label is Required"),
  })

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <Formik
          initialValues={lastMinute}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ values, isValid, setFieldValue, isSubmitting }) => {
            return (
              <Form autoComplete="off">
                <div className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <div className="avatarhotel">
                        {typeof values.image == "object" ? (
                          <Thumb file={values.image} />
                        ) : (
                          <>
                            {lastMinute.image !== "" &&
                              lastMinute.image !== null &&
                              lastMinute.image !== undefined && (
                                <img
                                  src={`${baseurl}getimage/${lastMinute.image}`}
                                />
                              )}
                          </>
                        )}

                        <span>
                          <AddAPhotoIcon
                            style={{ color: "#F46D25" }}
                          ></AddAPhotoIcon>
                          <p>Change</p>

                          <input
                            type="file"
                            id="file_up"
                            name="image"
                            onChange={(event) => {
                              setFieldValue(
                                "image",
                                event.currentTarget.files[0]
                              )
                            }}
                            accept="image/*"
                          />
                        </span>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        Minimum 400 kb Max 600kb file 300*300 width & Height
                      </div>

                      <ErrorMessage name="image">
                        {(error) => (
                          <div style={{ color: "red", textAlign: "center" }}>
                            {error}
                          </div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <Select
                        name="propertyName"
                        className={classes.select}
                        value={values.propertyName}
                        onChange={(value) => {
                          setFieldValue("propertyName", value)
                          setFieldValue("propertyId", value.value)
                          Api.get(`propertydetails/${value.value}`).then(
                            (res) => {
                              setFieldValue("starRate", res.data.rating)
                              setFieldValue("city", res.data.city)
                            }
                          )
                        }}
                        placeholder="Select Property *"
                        options={Hoteloptions}
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        styles={{
                          container: (provided) => ({
                            ...provided,
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            overflow: "visible",
                          }),
                          placeholder: (provided, state) => ({
                            ...provided,
                            position: "absolute",
                            top:
                              state.hasValue || state.selectProps.inputValue
                                ? -4
                                : "50%",
                            background: "#fff",
                            padding: "0px 5px",
                            transition: "top 0.1s, font-size 0.1s",
                            fontSize:
                              (state.hasValue ||
                                state.selectProps.inputValue) &&
                              13,
                          }),
                          control: (base, state) => ({
                            ...base,
                            "&:hover": { borderColor: "#f46d25" },
                            borderColor: "#f46d25",
                            boxShadow: "none",
                          }),
                        }}
                      />
                      <ErrorMessage name="propertyName">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <Field
                        name="starRate"
                        label="Star Rating *"
                        value={values.starRate}
                        type="text"
                        fullWidth
                        variant="outlined"
                        as={TextField}
                        size="small"
                      />
                      <ErrorMessage name="starRate">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>

                    <Grid item md={4} xs={6}>
                      <Field
                        name="city"
                        label="City * (25)"
                        value={values.city}
                        type="text"
                        fullWidth
                        variant="outlined"
                        size="small"
                        as={TextField}
                      />
                      <ErrorMessage name="city">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <Field
                        name="minmumprice"
                        label="Minimum Price *"
                        value={values.minmumprice}
                        type="text"
                        fullWidth
                        variant="outlined"
                        size="small"
                        as={TextField}
                      />
                      <ErrorMessage name="minmumprice">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <Field
                        name="orderNo"
                        label="Order No *"
                        value={values.orderNo}
                        type="text"
                        fullWidth
                        variant="outlined"
                        size="small"
                        as={TextField}
                      />
                      <ErrorMessage name="orderNo">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <Field
                        name="offerPercentage"
                        label="Offer Percentage (25) *"
                        value={values.offerPercentage}
                        type="text"
                        fullWidth
                        variant="outlined"
                        size="small"
                        as={TextField}
                      />
                      <ErrorMessage name="offerPercentage">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <Field
                        name="offerVaild"
                        label="Offer Valid (25) *"
                        value={values.offerVaild}
                        type="text"
                        fullWidth
                        variant="outlined"
                        size="small"
                        as={TextField}
                      />
                      <ErrorMessage name="offerVaild">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <Field
                        name="offerLabel"
                        label="Offer Label (25) *"
                        value={values.offerLabel}
                        type="text"
                        fullWidth
                        variant="outlined"
                        size="small"
                        as={TextField}
                      />
                      <ErrorMessage name="offerLabel">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <Field
                        name="topLeftLabel"
                        label="Top Left Label (25) *"
                        value={values.topLeftLabel}
                        type="text"
                        fullWidth
                        variant="outlined"
                        size="small"
                        as={TextField}
                      />
                      <ErrorMessage name="topLeftLabel">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                  </Grid>
                </div>

                <Grid
                  item
                  sm={12}
                  style={{ textAlign: "center", marginBottom: "10px" }}
                  xs={12}
                >
                  <Button
                    type="submit"
                    className={classes.button}
                    disabled={!isValid || isSubmitting}
                  >
                    {isSubmitting && (
                      <i
                        className="fa fa-circle-o-notch fa-spin"
                        style={{ marginRight: "1rem" }}
                      />
                    )}
                    Submit
                  </Button>
                  <Button
                    onClick={handleClose}
                    style={{
                      background: "#121212",
                      color: "#fff",
                      margin: "10px",
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </Dialog>
    </>
  )
}
