export const formatter = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
})

export const formatCurrency = (money) => {
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(money)
}
