import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core"
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import API from "../../Service/Api"
import TownoLogo from "../../assets/logo/towno_white.png"
import { Grid } from "@mui/material"
import { format } from "date-fns"
import { formatCurrency } from "../../utils/formatNumber"
import _ from "lodash"
import parse from "html-react-parser"

const useStyles = makeStyles(() => ({
  root: {
    margin: "0px",
    padding: "5rem 1.7rem",
  },
  main: {
    width: "100%",
    fontSize: "14px",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  header: {
    background: "#000",
    color: "#fff",
    fontWeight: "bold",
    padding: "1rem 1.5rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    height: "1.5rem",
  },
  artilcleHeader: {
    background: "#f46d25",
    fontSize: "1.5rem",
    color: "#fff",
    padding: "0.5rem 1.5rem",
    fontWeight: "bold",
  },
  tableContainer: {
    padding: "1rem 0rem",
  },
  tableHead: {
    borderBottom: "1px solid #f46d25",
  },
  tableCell: {
    color: "#f46d25",
    border: "none",
  },
}))

const DownloadActivityVoucher = ({
  activityData,
  cancelationpolicy,
  termsandcondition,
}) => {
  const classes = useStyles()
  const activityVoucherRef = useRef(null)

  return (
    <main className={classes.main} ref={activityVoucherRef}>
      <header className={classes.header}>
        <>
          <img src={TownoLogo} className={classes.logo} />
        </>
        <>{activityData?.activityId}</>
      </header>
      <article>
        <header className={classes.artilcleHeader}>Basic Details</header>
        <Grid container padding="0.5rem 1.5rem" spacing={3}>
          <Grid item container md={12}>
            <Grid item md={4} fontWeight={"bold"}>
              Client Name
            </Grid>
            <Grid item md={1} fontWeight={"bold"}>
              :
            </Grid>
            <Grid item md={7}>
              {activityData?.clientName}
            </Grid>
          </Grid>

          <Grid item container md={12}>
            <Grid item md={4} fontWeight={"bold"}>
              Mobile No.
            </Grid>
            <Grid item md={1} fontWeight={"bold"}>
              :
            </Grid>
            <Grid item md={7}>
              {activityData?.clientNumber}
            </Grid>
          </Grid>

          <Grid item container md={12}>
            <Grid item md={4} fontWeight={"bold"}>
              Email
            </Grid>
            <Grid item md={1} fontWeight={"bold"}>
              :
            </Grid>
            <Grid item md={7}>
              {activityData?.clientEmail}
            </Grid>
          </Grid>
        </Grid>
      </article>

      {/* <article>
        <header className={classes.artilcleHeader}>Lead Pax Details</header>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center" className={classes.tableCell}>
                  S No.
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  Name
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  Mobile No.
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  Alt Mobile No.
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  Email
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activityData?.activityBookingLeadPaxs?.map((pax, i) => (
                <TableRow key={pax.id}>
                  <TableCell align="center">{i + 1}</TableCell>
                  <TableCell align="center" style={{ wordBreak: "break-word" }}>
                    {pax?.name}
                  </TableCell>
                  <TableCell align="center">{pax?.mobile}</TableCell>
                  <TableCell align="center">{pax?.altMobile}</TableCell>
                  <TableCell align="center" style={{ wordBreak: "break-word" }}>
                    {pax?.email}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </article> */}

      <article>
        <header className={classes.artilcleHeader}>Activity Details</header>
        <TableContainer className={classes.tableContainer}>
          <Table>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell align="center" className={classes.tableCell}>
                  S No.
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  Name
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  Type
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  Date Of Activity
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  No Of Pax
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activityData?.activityBookingActivityDetails?.map(
                (activity, i) => (
                  <TableRow key={activity.id}>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell
                      align="center"
                      style={{ wordBreak: "break-word" }}
                    >
                      {activity?.activityName}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ wordBreak: "break-word" }}
                    >
                      {activity?.activityType}
                    </TableCell>
                    <TableCell align="center">
                      {format(
                        new Date(activity?.dateOfActivity),
                        "do MMM, yyyy"
                      )}
                    </TableCell>
                    <TableCell align="center">{activity?.noOfPax}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </article>

      <article>
        <header className={classes.artilcleHeader}>Payment Details</header>
        <Grid container padding="0.5rem 1.5rem" spacing={3}>
          <Grid item container md={12}>
            <Grid item md={4} fontWeight={"bold"}>
              Total Amount
            </Grid>
            <Grid item md={1} fontWeight={"bold"}>
              :
            </Grid>
            <Grid item md={7}>
              {formatCurrency(activityData?.totalAmount)}
            </Grid>
          </Grid>

          <Grid item container md={12}>
            <Grid item md={4} fontWeight={"bold"}>
              Amount Paid
            </Grid>
            <Grid item md={1} fontWeight={"bold"}>
              :
            </Grid>
            <Grid item md={7}>
              {formatCurrency(activityData?.amountReceived)}
            </Grid>
          </Grid>

          <Grid item container md={12}>
            <Grid item md={4} fontWeight={"bold"}>
              Payment Mode
            </Grid>
            <Grid item md={1} fontWeight={"bold"}>
              :
            </Grid>
            <Grid item md={7}>
              {activityData?.paymentMode}
            </Grid>
          </Grid>

          <Grid item container md={12}>
            <Grid item md={4} fontWeight={"bold"}>
              Bal Payable to Towno
            </Grid>
            <Grid item md={1} fontWeight={"bold"}>
              :
            </Grid>
            <Grid item md={7}>
              {formatCurrency(activityData?.bpah)}
            </Grid>
          </Grid>

          <Grid item container md={12}>
            <Grid item md={4} fontWeight={"bold"}>
              Reference Number
            </Grid>
            <Grid item md={1} fontWeight={"bold"}>
              :
            </Grid>
            <Grid item md={7}>
              {activityData?.refNumber}
            </Grid>
          </Grid>
        </Grid>
      </article>
      <article>
        <header className={classes.artilcleHeader}>Terms And Conditions</header>
        <Grid
          container
          margin="0.5rem 1.5rem"
          padding={"1rem 0rem"}
          spacing={3}
        >
          {_.isEmpty(termsandcondition) ? "-" : parse(termsandcondition)}
        </Grid>
      </article>

      <article>
        <header className={classes.artilcleHeader}>Cancellation Policy</header>
        <Grid
          container
          margin="0.5rem 1.5rem"
          padding={"1rem 0rem"}
          spacing={3}
        >
          {_.isEmpty(cancelationpolicy) ? "-" : parse(cancelationpolicy)}
        </Grid>
      </article>
    </main>
  )
}

export default DownloadActivityVoucher
