import styles from "./PrivacyPolicy.module.css"
import ContactInfo from "../../components/ContactInfo/ContactInfo.component"
import QueryForm from "../../components/QueryForm/QueryForm.component"

const PrivacyPolicy = () => {
  return (
    <div className={styles.privacyPolicyContainer}>
      <header className={styles.header}>Privacy Policy</header>
      <div className={styles.section}>
        <main className={styles.mainBody}>
          <p className={styles.paragraph}>
            Towno has developed this Privacy Statement (Policy) to express our
            confirm commitment to aid our users better understand what
            information we collect about them and what may happen to that
            information.
          </p>
          <p className={styles.paragraph}>
            This Terms of Use agreement was last updated: February 28th, 2016.
            This Terms of Use agreement is effective as of: April 30th, 2016.
            (“Towno") values your privacy. In this Privacy Policy ("Policy"), we
            describe the information that we collect about you when you visit
            our website, www. Towno.in (the "Website") and use the services
            available on the Website ("Services"), and how we use and disclose
            that information. If you have any questions or comments about the
            Privacy Policy, please contact us at support@ Towno.in. This Policy
            is incorporated into and is subject to the Towno Terms of Use, which
            can be accessed at www. Towno/terms. Your use of the Website and/or
            Services and any personal information you provide on the Website
            remains subject to the terms of the Policy and Towno's Terms of Use.
            In case of grievance for complaint may be intimated to Grievance
            Officer. The complaint shall be addressed based on the request.
          </p>
          <article className={styles.article}>
            <header className={styles.articleHeader}>
              COLLECTION OF PERSONAL INFORMATION
            </header>
            <p className={styles.articleSummary}>
              Personal information” is defined to include information that
              whether on its own or in combination with other information may be
              used to readily identify or contact you such as: name, address,
              email address, phone number etc. We collect personal information
              from Service Professionals offering their products and services.
              This information is partially or completely accessible to all
              visitors using Towno’s website or mobile application, either
              directly or by submitting a request for a service. Service
              Professionals and customers are required to create an account to
              be able to access certain portions of our Website, such as to
              submit questions, participate in polls or surveys, to request a
              quote, to submit a bid in response to a quote, and request
              information. – Service. Professionals, if and when they create an
              account with Towno, will be required to disclose information
              including personal contact details, agree to participation in
              polls or surveys or feedbacks or any other information that can
              help customer in their needs. The type of personal information
              that we collect from you varies based on your particular
              interaction with our Website or mobile application.
            </p>
          </article>
          <article className={styles.article}>
            <header className={styles.articleHeader}>Users</header>
            <p className={styles.articleSummary}>
              During the Account registration process, we will collect
              information such as your name, postal code, telephone email
              address and other personal information. You also may provide us
              with your, mailing address, and demographic information (e.g.,
              gender, age, preference, education and other information relevant
              to user surveys and/or offers). We may also collect personal
              information that you post in your Offer, Profile, Wants, or
              Feedback, and any comments or discussions you post in any blog,
              chat room, or other correspondence site on the Website or mobile
              application, or any comments you provide during dispute resolution
              with other users of the Website or mobile application
            </p>
          </article>
          <article className={styles.article}>
            <header className={styles.articleHeader}>
              Sellers and Service Professionals
            </header>
            <p className={styles.articleSummary}>
              If you are a vendor or a Service Professional and would like to
              post any information about yourself, we will require you to
              register for an Account. During the account registration process,
              we will collect your business name, Telephone number, address, zip
              code, travel preferences, a description of your services, a
              headline for your profile, first and last name, and email address.
              In addition, you may, but are not required to, provide other
              content or information about your business, including photographs
              and videos. We also may collect payment information, such as
              credit card information, from you. If we deem it necessary, in our
              sole and absolute discretion, we may also ask for and collect
              supplemental information from third parties, such as information
              about your credit from a credit bureau (to the extent permitted by
              law), or information to verify any identification details you
              provide during registration.
            </p>
          </article>
          <article className={styles.article}>
            <header className={styles.articleHeader}>
              COLLECTION OF PERSONAL INFORMATION FROM SOCIAL NETWORKING SITES
            </header>
            <p className={styles.articleSummary}>
              You may log into our Website through your Facebook account. If you
              do so, you must enter the email address and password that you use
              for your Facebook account. We will ask that you grant us
              permission to access and collect your Facebook basic information
              (this includes your name, profile picture, gender, networks, user
              IDs, list of friends, date of birth, email address, and any other
              information you have set to public on your Facebook account). If
              you allow us to have access to this information, then we will have
              access to this information even if you have chosen not to make
              that information public. We store the information that we receive
              from Facebook with other information that we collect from you or
              receive about you. Facebook controls the information it collects
              from you. For information about how Facebook may use and disclose
              your information, including any information you make public,
              please consult Facebook's privacy policy. We have no control over
              how any third party site uses or discloses the personal
              information it collects about you.
            </p>
          </article>
          <article className={styles.article}>
            <header className={styles.articleHeader}>
              COLLECTION OF AUTOMATIC INFORMATION, USE OF COOKIES AND OTHER
              TRACKING DEVICES
            </header>
            <p className={styles.articleSummary}>
              We and our third party service providers, which include ad
              networks, use cookies, web beacons, and other tracking
              technologies to collect information about your use of our Website
              and Services, such as your browser type, your ISP or operating
              system, your domain name, your access time, the URL of the
              previous website you visited, your page views, your IP address,
              and the type of device that you use. We also track how frequently
              you visit our Website and use our Services. We use this
              information (including the information collected by our third
              party service providers) for Website analytics (including to
              determine which portions of our Website are used most frequently,
              what our users like/do not like), to assist us in determining
              relevant advertising (both on and off our Website), to evaluate
              the success of our advertising campaigns, and as otherwise
              described in this policy. Currently, we do not honor browser
              requests not to be tracked. We may obtain your device ID, which is
              sent to Towno's servers and used in fraud prevention efforts. We
              and our third party service providers collect information from you
              by using cookies. A cookie is a small file stored on user's
              computer hard drive containing information about the user. The
              cookie helps us analyze web traffic or informs you about your use
              of a particular website. Cookies allow web applications to respond
              to you as an individual, tailoring its operations to your needs,
              likes and dislikes by gathering and remembering information about
              your preferences. When you visit the Website, we may send one or
              more cookies (i.e., a small text file containing a string of
              alphanumeric characters) to your computer that identifies your
              browser. Some of these cookies may be connected to third-party
              companies or websites. The terms of use of such cookies are
              governed by this Policy and the privacy policy of the relevant
              third party company or website. For example, Google measures the
              performance of advertisements by placing cookies on your computer
              when you click on ads. If you visit the Website when you have such
              cookies on your computer, we and Google will be able to tell that
              you saw the ad delivered by Google. The terms of use of these
              cookies are governed by this Policy and Google's Privacy Policy.
              Disabling Cookies. You can choose to accept or decline cookies.
              Most web browsers automatically accept cookies, but you can
              usually modify your browser setting to decline cookies if you
              prefer. If you disable cookies you may be prevented from taking
              full advantage of the Website because it may not function properly
              if the ability to accept cookies is disabled. Clear GIFs, pixel
              tags and other technologies. Clear GIFs are tiny graphics with a
              unique identifier, similar in function to cookies. In contrast to
              cookies, which are stored on your computer's hard drive, clear
              GIFs are embedded invisibly on web pages. We may use clear GIFs
              (a.k.a. web beacons, web bugs or pixel tags), in connection with
              our Website to, among other things, track the activities of
              Website visitors, help us manage content, and compile statistics
              about Website usage. We and our third party service providers also
              use clear GIFs in HTML emails to our customers, to help us track
              email response rates, identify when our emails are viewed, and
              track whether our emails are forwarded.
            </p>
          </article>
          <article className={styles.article}>
            <header className={styles.articleHeader}>
              USAGE OF PERSONAL INFORMATION
            </header>
            <p className={styles.articleSummary}>
              We collect your personal information and aggregate information
              about the use of our Website and Services to better understand
              your needs and to provide you with a better Website experience.
              Specifically, we may use your personal information for any of the
              following reasons:-
              <ol>
                <li>
                  To provide our Services to you, including registering you for
                  our Services, verifying your identity and authority to use our
                  Services, and to otherwise enable you to use our Website and
                  our Services.
                </li>
                <li>For customer support and to respond to your inquiries.</li>
                <li>
                  For internal record-keeping purposes, process billing and
                  payment, including sharing with third party payment gateways
                  in connection with Website and/or Towno's products and
                  Services
                </li>
                <li>
                  To improve and maintain our Website and our Services (for
                  example, we track information entered through the "Search"
                  function this helps us determine which areas of our Website
                  users like best and areas that we may want to enhance; we also
                  will use for trouble-shooting purposes, where applicable)
                </li>
                <li>
                  To periodically send promotional emails to the email address
                  you provide regarding new products from Towno, special offers
                  from Towno or other information about Towno that we think you
                  may find interesting
                </li>
                <li>
                  To contact you via email, telephone, facsimile or mail, or,
                  where requested, by text message, to deliver certain services
                  or information you have requested for.
                </li>
                <li>
                  Towno's market research purposes, including, but not limited
                  to, the customization of the Website according to your
                  interest.
                </li>
                <li>
                  We may use your demographic information (i.e., age, postal
                  code, residential and commercial addresses, and other various
                  data) to more effectively facilitate the promotion of goods
                  and services to appropriate target audiences and for other
                  research and analytical purposes
                </li>
                <li>
                  To resolve disputes, to protect ourselves and other users of
                  our Website and Services, and to enforce our Terms of Use.
                </li>
                <li>
                  We also may compare personal information collected through the
                  Website and Services to verify its accuracy with personal
                  information collected from third parties.
                </li>
                <li>
                  We may combine aggregate data with the personal information we
                  collect about you. From time to time, Towno may use personal
                  information for new and unanticipated uses not previously
                  disclosed in our Privacy Policy. If our information practices
                  change regarding information previously collected, Towno shall
                  make reasonable efforts to provide notice.
                </li>
                <li>
                  At our sole discretion, Towno may offer any of the following
                  free services on the Website, which you may select to use or
                  receive at your option. Certain of the following services may
                  require you to provide additional personal information.
                </li>
                <li>
                  We may offer a free electronic newsletter to users. We will
                  gather the email addresses of users who sign up for Towno for
                  the newsletter mailing list. Users may remove themselves from
                  this mailing list by opting out of receiving newsletters
                  during the registration process.
                </li>
              </ol>
              <p>
                We may offer interactive polls to users so they can easily share
                their opinions with other users and see what our audience thinks
                about important issues, Services, and/or the Website. Opinions
                or other responses to polls are aggregated and are not
                identifiable to any particular user. We may use a system to
                "tag" users after they have voted, so they can vote only once on
                a particular question. This tag is not correlated with
                information about individual users
              </p>
              <p>
                We may conduct user surveys from time to time to better target
                our content to our Website users. We will not share individual
                responses from these surveys with any third party. We will share
                aggregate data with third party service providers, partners, and
                other third parties. We also will post aggregate data containing
                survey responses on our
              </p>
              <p>
                We employ procedural and technological security measures, which
                are reasonably designed to help protect your personal
                information from unauthorized access or disclosure. Towno may
                use encryption, passwords, and physical security measures to
                help protect your personal information against unauthorized
                access and disclosure. No security measures, however, are 100%
                complete. Therefore, we do not promise and cannot guarantee, and
                thus you should not expect, that your personal information or
                private communications will not be collected and used by others.
                You should take steps to protect against unauthorized access to
                your password, phone, and computer by, among other things,
                signing off after using a shared computer, choosing a robust
                password that nobody else knows or can easily guess, and keeping
                your log-in and password private. Towno is not responsible for
                the unauthorized use of your information or for any lost,
                stolen, compromised passwords, or for any activity on your
                Account via unauthorized password activity
              </p>
            </p>
          </article>
          <article className={styles.article}>
            <header className={styles.articleHeader}>DISCLOSURE</header>
            <p className={styles.articleSummary}>
              <p>
                We may share the information that we collect about you,
                including your personal information, as follows
              </p>
              <p>
                We may disclose your information including Personal Information
                if:
              </p>
              <p>
                Towno reasonably believes that disclosure is necessary in order
                to comply with a legal process (such as a court order, search
                warrant, etc.) or other legal requirement of any governmental
                authority, disclosure would potentially mitigate our liability
                in an actual or potential lawsuit, (iii) reasonably necessary to
                enforce this Privacy Policy, our Terms of Use etc. (iv)
                disclosure is intended to help investigate or prevent
                unauthorized transactions or other illegal activities, or
                necessary or appropriate to protect our rights or property, or
                the rights or property of any person or entity
              </p>
            </p>
          </article>
          <article className={styles.article}>
            <header className={styles.articleHeader}>
              INFORMATION DISCLOSED TO THIRD PARTY SERVICE PROVIDERS AND
              BUSINESS PARTNERS
            </header>
            <p className={styles.articleSummary}>
              We may contract with various third parties for the provision and
              maintenance of the Website, Services and our business operations,
              and Towno may need to share your personal information and data
              generated by cookies and aggregate information (collectively,
              "information") with these vendors and service agencies. For
              example, we may provide your information to a credit card
              processing company to process your payment. The vendors and
              service agencies will not receive any right to use your personal
              information beyond what is necessary to perform its obligations to
              provide the Services to you. If you complete a survey, we also may
              share your information with the survey provider; if we offer a
              survey in conjunction with another entity, we also will disclose
              the results to that entity
            </p>
          </article>
          <article className={styles.article}>
            <header className={styles.articleHeader}>
              DISCLOSURE TO NON-AFFILIATED THIRD PARTIES IN FURTHERANCE OF YOUR
              REQUEST
            </header>
            <p className={styles.articleSummary}>
              Your request for services may be shared with third party websites
              with whom we have a contractual relationship in order to provide
              your request with maximum exposure. The post on the third party
              website will include the details of your request, including your
              location, and other contact details. Interested bidders, however,
              will be able to click on your request on such third party site,
              and will be directed to our Website where they will have access to
              your contact details (Partial or complete), as would any other
              service provider on our Website interested in bidding on your
              request
            </p>
          </article>
          <article className={styles.article}>
            <header className={styles.articleHeader}>
              DISCLOSURE TO OTHER USERS OF OUR WEBSITE
            </header>
            <p className={styles.articleSummary}>
              If you are a Service Professional, the information that you post
              (other than your payment information) is available to other users
              of our Website and our Services. Comments that users post to our
              Website also will be available to other visitors to our Website
              (see our discussion of User Generated Content below). In addition,
              we will post the results (in aggregate form) of surveys to our
              Website. If you are a consumer, name, and location, as well as the
              details of your request, are available to all visitors to our
              Website. Bidding professionals also will be permitted to see the
              consumer's full name, telephone number, email address and the
              location
            </p>
          </article>
          <article className={styles.article}>
            <header className={styles.articleHeader}>
              INFORMATION DISCLOSED TO LAW ENFORCEMENT OR GOVERNMENT OFFICIALS
            </header>
            <p className={styles.articleSummary}>
              We will disclose your information, including, without limitation,
              your name, city, state, telephone number, email address, user ID
              history, quoting and listing history, and fraud complaints, to law
              enforcement or other government officials if we are required to do
              so by law, regulation or other government authority or otherwise
              in cooperation with an investigation of a governmental authority.
            </p>
          </article>
          <article className={styles.article}>
            <header className={styles.articleHeader}>
              IN THE EVENT OF A CHANGE OF CONTROL OR BANKRUPTCY
            </header>
            <p className={styles.articleSummary}>
              <p>
                In the event that Towno undergoes a change in control,
                including, without limitation, a merger or sale of all or
                substantially all of Towno's assets to which this Website elates
                or other corporate reorganization in which Towno participates,
                and is thus merged with or acquired by a third party entity (a
                "Successor"), Towno hereby reserves the right to transfer the
                information we have collected from the users of the Website
                and/or Services to such Successor.
              </p>
              <p>
                In addition, in the event of Towno's bankruptcy, reorganization,
                receivership, or assignment for the benefit of creditors, or the
                application or laws or equitable principles affecting creditors'
                rights generally, Towno may not be able to control how your
                information is transferred, used, or treated and reserves the
                right to transfer the information we have collected from the
                users of the Website and/or Services to non-affiliated third
                parties in such event
              </p>
            </p>
          </article>
          <article className={styles.article}>
            <header className={styles.articleHeader}>
              INFORMATION DISCLOSED AT YOUR REQUEST
            </header>
            <p className={styles.articleSummary}>
              We may share your personal information with other Registered Users
              to whom you explicitly ask us to send your information or if you
              explicitly consent to such disclosure upon receipt of a specific
              Service. For instance, when you contract for a specific Service
              with another Registered User, Towno will send that Registered User
              a notice that includes the personal information that you have
              chosen to allow Towno to reveal to users with whom you contract.
            </p>
          </article>{" "}
          <article className={styles.article}>
            <header className={styles.articleHeader}>
              Links to External Websites
            </header>
            <p className={styles.articleSummary}>
              The Website may contain links to other websites or resources over
              which Towno does not have any control. Such links do not
              constitute an endorsement by Towno of those external websites. You
              acknowledge that Towno is providing these links to you only as a
              convenience, and further agree that Towno is not responsible for
              the content of such external websites. We are not responsible for
              the protection and privacy of any information which you provide
              while visiting such external websites and such sites are not
              governed by this Policy. Your use of any external website is
              subject to the terms of use and privacy policy located on the
              linked to external website
            </p>
          </article>
          <article className={styles.article}>
            <header className={styles.articleHeader}>
              Updating, Deleting and Correcting Your Personal Information
            </header>
            <p className={styles.articleSummary}>
              You may choose to restrict the collection or use of your personal
              information. You can review, correct and delete your personal
              information by logging into the Website and navigating to your
              preferences page in "Edit Profile." You must promptly update your
              personal information if it changes or is inaccurate. Typically, we
              will not manually alter your personal information because it is
              very difficult to verify your identity remotely. Nonetheless, upon
              your request we will close your Account and remove your personal
              information from view as soon as reasonably possible based on your
              Account activity and in accordance with applicable law. We do
              retain information from closed Accounts in order to comply with
              the law, prevent fraud, collect any fees owed, resolve disputes,
              troubleshoot problems, assist with any investigations of any
              Registered User, enforce our Terms of Use, and take any other
              actions otherwise permitted by law that we deem necessary in our
              sole and absolute discretion. You should understand, however, that
              once you publicly post a Request, Offer, Want, Feedback, or any
              other information onto the Website, you may not be able to change
              or remove it. Once we have deleted or removed your Account, you
              agree that Towno shall not be responsible for any personal
              information that was not included within your deleted and/or
              removed Account that remains on the Website. We may send periodic
              promotional or informational emails to you. You may opt-out of
              such communications by following the opt-out instructions
              contained in the email. Please note that it may take up to 10
              business days for us to process opt-out requests. If you opt-out
              of receiving emails about recommendations or other information we
              think may interest you, we may still send you emails about your
              Account or any Services you have requested or received from us.
              Third-Party Ad Networks. We participate in third party ad networks
              that may display advertisements on other websites based on your
              visits to our Site as well as other websites. This enables us and
              these third parties to target advertisements by displaying ads for
              products and services in which you might be interested. Third
              party ad network providers, advertisers, sponsors and/or traffic
              measurement services may use cookies, JavaScript, web beacons
              (including clear GIFs), Flash LSOs and other technologies to
              measure the effectiveness of their ads and to personalize
              advertising content to you. These third party cookies and other
              technologies are governed by each third party's specific privacy
              policy, and not by Towno’s Policy. We may provide these
              third-party advertisers with information about your usage of our
              Site and our services, as well as aggregate or non-personally
              identifiable information about visitors to our Site and users of
              our service. Your Full Name and Use of Towno Information. As a
              Registered User of the Website you will select a Full Name during
              the registration process. All of your activities on the Website
              will be Traceable to your Full Name. Certain other people,
              including other Registered Users with whom you have transacted
              business via the Website, can see a large part of your activity on
              the Website. If you book a service with a Registered User, cancel
              a scheduled service with a Registered User, receive an offer on
              your posted service from a Registered User, or have posted a
              service, Towno may send a notice to you or the appropriate
              Registered User that includes your Full Name. Thus, if you
              associate your real name with your Full Name, the people to whom
              you have revealed your name may be able to personally identify
              your Website activities
            </p>
          </article>
          <article className={styles.article}>
            <header className={styles.articleHeader}>
              USING INFORMATION FROM TOWNO
            </header>
            <p className={styles.articleSummary}>
              The Website facilitates your sharing of personal information with
              others in order to negotiate, provide, and use the Services. If
              you agree to contract for a service with another Registered User,
              you may need to reveal your name, email, phone number, or personal
              address to that individual so that the service may be performed.
              Please respect the privacy of others. You agree to use the
              information of other users solely for the following purposes: (a)
              Towno-transaction-related purposes and (b) using Services offered
              through the Website
            </p>
          </article>
          <article className={styles.article}>
            <header className={styles.articleHeader}>ACCOUNT PROTECTION</header>
            <p className={styles.articleSummary}>
              Your password is the key to your Account. When creating your
              password you should use unique numbers, letters, special
              characters, and combinations thereof. In addition, DO NOT disclose
              your password to anyone. If you do share your password or your
              personal information with others, you are solely responsible for
              all actions taken via your Account. If you lose control of your
              password, you may lose substantial control over your personal
              information and be subject to legally binding actions taken on
              your behalf.
            </p>
          </article>
          <p className={styles.paragraph}>
            THIS POLICY IS CURRENT AS OF THE EFFECTIVE DATE SET FORTH ABOVE.
            TOWNO MAY, IN ITS SOLE AND ABSOLUTE DISCRETION, CHANGE THIS POLICY
            FROM TIME TO TIME BY UPDATING THIS DOCUMENT. TOWNO WILL POST ITS
            UPDATED POLICY ON THE WEBSITE ON THIS PAGE. TOWNO ENCOURAGES YOU TO
            REVIEW THIS POLICY REGULARLY FOR ANY CHANGES. YOUR CONTINUED USE OF
            THIS WEBSITE AND/OR CONTINUED PROVISION OF PERSONAL INFORMATION TO
            US WILL BE SUBJECT TO THE TERMS OF THE THEN-CURRENT POLICY.
          </p>
        </main>
        <aside className={styles.aside}>
          <ContactInfo />
          <QueryForm />
        </aside>
      </div>
    </div>
  )
}
export default PrivacyPolicy
