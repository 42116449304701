import { applyMiddleware, compose, createStore } from "redux"
import { persistReducer, persistStore } from "redux-persist"
import sessionStorage from "redux-persist/es/storage/session"
import thunk from "redux-thunk"

import localStorage from "redux-persist/es/storage"
import rootReducer from "./reducers/root-reducer"

const persistConfig = {
  key: "root",
  storage: localStorage,
  whitelist: ["userReducer", "leadCountReducer"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
)

export const persistor = persistStore(store)
