import { Card, Grid, Stack, Typography } from "@mui/material"
import React, { useState, useEffect } from "react"
import Api from "../../Service/Api"
import { useParams } from "react-router-dom"
import { format, parse } from "date-fns"
import { Link } from "react-router-dom"
import { twnButtonStyles } from "../../utils/townoStyle"
import { formatter } from "../../utils/formatNumber"
import ViewTransferMobileUI from "./ViewTransferMobileUI"
import { jsPDF } from "jspdf"

const cardLabel = { padding: "0.5em 1em", color: "#111", fontWeight: "500" }
const cardValue = { padding: "0.5em 1em", color: "#111" }

export default function ViewTransfer(props) {
  var uniqueid = localStorage.getItem("unique_id")
  var [data, setData] = useState({
    id: "",
    type: "",
    transferId: "",
    createdAt: "",
    basicDetails: [],
    flightDetails: [],
    trainDetails: [],
    cabDetails: [],
    busDetails: [],
  })
  let { transferId } = useParams()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    Api.get(`/transferDetail/${uniqueid}/${transferId}`).then((res) => {
      setIsLoading(true)
      console.log("transferData :", res.data)
      setData(res.data)
      setIsLoading(false)
    })
  }, [])

  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [width])

  var flightTempAmountArray = data.flightDetails.map((x, i) => {
    return /^\+?\d+$/.test(x.flightAmount) ? x.flightAmount : 0
  })
  var flightTempTotalAmount = flightTempAmountArray.reduce((total, num) => {
    return total + num / 1
  }, 0)
  var trainTempAmountArray = data.trainDetails.map((x, i) => {
    return /^\+?\d+$/.test(x.trainAmount) ? x.trainAmount : 0
  })
  var trainTempTotalAmount = trainTempAmountArray.reduce((total, num) => {
    return total + num / 1
  }, 0)
  var cabTempAmountArray = data.cabDetails.map((x, i) => {
    return /^\+?\d+$/.test(x.cabAmount) ? x.cabAmount : 0
  })
  var cabTempTotalAmount = cabTempAmountArray.reduce((total, num) => {
    return total + num / 1
  }, 0)
  var busTempAmountArray = data.busDetails.map((x, i) => {
    return /^\+?\d+$/.test(x.busAmount) ? x.busAmount : 0
  })
  var busTempTotalAmount = busTempAmountArray.reduce((total, num) => {
    return total + num / 1
  }, 0)

  return (
    <>
      {width <= 768 ? (
        <ViewTransferMobileUI
          data={data}
          flightTempTotalAmount={flightTempTotalAmount}
          busTempTotalAmount={busTempTotalAmount}
          trainTempTotalAmount={trainTempTotalAmount}
          cabTempTotalAmount={cabTempTotalAmount}
        />
      ) : (
        <div style={{ ...twnButtonStyles.allPages, paddingTop: "75px" }}>
          <Typography
            variant="h5"
            component="h5"
            style={twnButtonStyles.xlFonts}
          >
            Travel Transfer Details
          </Typography>

          {/* basic details starts */}
          <Card style={{ marginTop: "9px", boxShadow: "none" }}>
            <Typography
              style={{
                ...twnButtonStyles.headerStyle,
                padding: "10px 0 0 5px",
                height: "32px",
              }}
            >
              Basic Details
            </Typography>
            {data.basicDetails.map((each, index) => {
              return (
                <>
                  <Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      style={{ lineHeight: "0.3", padding: "0.5em" }}
                    >
                      <Grid container>
                        <Grid item xs={3} style={cardLabel}>
                          Name
                        </Grid>
                        <Grid item xs={9} style={cardValue}>
                          : {each.clientName}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Mobile No.
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.clientMobileNo}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Alt No
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.clientAltNo}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={2} style={cardLabel}>
                          Email
                        </Grid>
                        <Grid item xs={10} style={cardValue}>
                          : {each.clientEmail}
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </>
              )
            })}
          </Card>
          {/* basic details ends */}

          {/* flight details starts */}
          {flightTempTotalAmount > 0 ? (
            <>
              <Card style={{ marginTop: "9px" }}>
                <Typography
                  style={{
                    ...twnButtonStyles.headerStyle,
                    padding: "10px 0 0 5px",
                    height: "32px",
                  }}
                >
                  Flight Details
                </Typography>
                {data.flightDetails.map((each, index) => {
                  return (
                    <>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{
                          lineHeight: "0.3",
                          padding: "0.5em 0.5em 0 0.5em",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Trip
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.flightTrip}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Name
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.flightName}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Adults
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.flightAdults}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5} style={cardLabel}>
                            Departure Date
                          </Grid>
                          {/* <Grid item xs={7} style={cardValue}>: {each.flightDepartDate ?format(parse(each.flightDepartDate,'dd/MM/yyyy',new Date()),"dd MMM yy"):null}</Grid> */}
                          <Grid item xs={7} style={cardValue}>
                            :{" "}
                            {each.flightDepartDate
                              ? format(
                                  new Date(each.flightDepartDate),
                                  "dd MMM yy"
                                )
                              : null}
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ lineHeight: "0.3", padding: "0 0.5em" }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            To
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.flightTo}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            From
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.flightFrom}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Pnr
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.flightPnr}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5} style={cardLabel}>
                            Return Date
                          </Grid>
                          {/* <Grid item xs={7} style={cardValue}>: {each.flightReturnDate ? format(parse(each.flightReturnDate,'dd/MM/yyyy',new Date()),"dd MMM yy") : null}</Grid> */}
                          <Grid item xs={7} style={cardValue}>
                            :{" "}
                            {each.flightReturnDate
                              ? format(
                                  new Date(each.flightReturnDate),
                                  "dd MMM yy"
                                )
                              : null}
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{
                          lineHeight: "0.3",
                          padding: "0 0.5em 0.5em 0.5em",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Children
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.flightChild}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Amount
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {formatter.format(each.flightAmount)}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Commission
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {formatter.format(each.flightComission)}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}></Grid>
                        </Grid>
                      </Stack>
                    </>
                  )
                })}
              </Card>
            </>
          ) : null}
          {/* flight details ends */}

          {/* train details starts */}
          {trainTempTotalAmount > 0 ? (
            <>
              <Card style={{ marginTop: "9px" }}>
                <Typography
                  style={{
                    ...twnButtonStyles.headerStyle,
                    padding: "10px 0 0 5px",
                    height: "32px",
                  }}
                >
                  Train Details
                </Typography>
                {data.trainDetails.map((each, index) => {
                  return (
                    <>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{
                          lineHeight: "0.3",
                          padding: "0.5em 0.5em 0 0.5em",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Class
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.traintravelClass}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Name
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.trainName}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Adults
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.trainAdults}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5} style={cardLabel}>
                            From
                          </Grid>
                          <Grid item xs={7} style={cardValue}>
                            : {each.trainFrom}
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ lineHeight: "0.3", padding: "0 0.5em" }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            To
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.trainTo}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Pnr
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.trainPnr}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Children
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.trainChild}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5} style={cardLabel}>
                            Departure Date
                          </Grid>
                          {/* <Grid item xs={7} style={cardValue}>: {each.trainDepartDate ? format(parse(each.trainDepartDate,'dd/MM/yyyy',new Date()), 'dd-MM-yyyy') : null}</Grid> */}
                          <Grid item xs={7} style={cardValue}>
                            :{" "}
                            {each.trainDepartDate
                              ? format(
                                  new Date(each.trainDepartDate),
                                  "dd-MM-yyyy"
                                )
                              : null}
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{
                          lineHeight: "0.3",
                          padding: "0 0.5em 0.5em 0.5em",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Amount
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {formatter.format(each.trainAmount)}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Commission
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {formatter.format(each.trainComission)}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}></Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}></Grid>
                        </Grid>
                      </Stack>
                    </>
                  )
                })}
              </Card>
            </>
          ) : null}
          {/* train details ends */}

          {/* cab details starts */}
          {cabTempTotalAmount > 0 ? (
            <>
              <Card style={{ marginTop: "9px" }}>
                <Typography
                  style={{
                    ...twnButtonStyles.headerStyle,
                    padding: "10px 0 0 5px",
                    height: "32px",
                  }}
                >
                  Cab Details
                </Typography>
                {data.cabDetails.map((each, index) => {
                  return (
                    <>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{
                          lineHeight: "0.3",
                          padding: "0.5em 0.5em 0 0.5em",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            From
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.cabFrom}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            To
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.cabTo}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Trip
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.cabTrip}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Type
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.cabType}
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ lineHeight: "0.3", padding: "0 0.5em" }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Vehicle
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.cabVehicle}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Adults
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.cabAdults}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Children
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.cabChild}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Amount
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {formatter.format(each.cabAmount)}
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{
                          lineHeight: "0.3",
                          padding: "0 0.5em 0.5em 0.5em",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Amount
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {formatter.format(each.cabCommission)}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}></Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}></Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}></Grid>
                        </Grid>
                      </Stack>
                    </>
                  )
                })}
              </Card>
            </>
          ) : null}
          {/* cab details ends */}

          {/* bus details starts */}
          {busTempTotalAmount > 0 ? (
            <>
              <Card style={{ marginTop: "9px" }}>
                <Typography
                  style={{
                    ...twnButtonStyles.headerStyle,
                    padding: "10px 0 0 5px",
                    height: "32px",
                  }}
                >
                  Bus Details
                </Typography>
                {data.busDetails.map((each, index) => {
                  return (
                    <>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{
                          lineHeight: "0.3",
                          padding: "0.5em 0.5em 0 0.5em",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Name
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.busName}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Seat No.
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.busSeatNo}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            From
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.busFrom}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            To
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.busTo}
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{
                          lineHeight: "0.3",
                          padding: "0 0.5em 0.5em 0.5em",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Adults
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.busAdults}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Children
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.busChild}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Amount
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {formatter.format(each.busAmount)}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Commission
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {formatter.format(each.busCommission)}
                          </Grid>
                        </Grid>
                      </Stack>
                    </>
                  )
                })}
              </Card>
            </>
          ) : null}
          {/* bus details ends */}

          {/* buttons start */}
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
            style={{ margin: "1.2em 0 -2em 0" }}
          >
            <Link
              to={{ pathname: "/konnect/transferList" }}
              style={{ ...twnButtonStyles.linkOrangeBtn, width: "80px" }}
            >
              Back
            </Link>
            {/* <Link to={{ pathname: `/copyTransferPage/${data.transferId}` }} style={{ color: "#fff", background: "#111", padding: '1em', borderRadius: '10px', width: '6em', textAlign: 'center' }}>Next</Link> */}
            {/* <span onClick={() => { window.open(`/copyTransferPage/${data.transferId}`) }} style={twnButtonStyles.linkBlackBtn}>Copy Transfer</span> */}
          </Stack>
          {/* buttons end */}
        </div>
      )}
    </>
  )
}
