import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import { Grid } from "@mui/material";

import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import Api from "../../Service/Api";
import { baseurl } from "../../Service/httpCommon";
import { format } from "date-fns";
import Loader from "../../components/Loader/Loader";
import TownoGstInfoCard from "../../components/gstCard/townoGstInfoCard";
import { useDispatch, useSelector } from "react-redux";
import { hotelDetailsInitial } from "../../redux/actions/hotelViewAction";
import OtherLoginInvoiceCard from "../../components/gstCard/otherLoginInvoiceCard";
import { useRef } from "react";
import jsPDF from "jspdf";

import { twnButtonStyles } from "../../utils/townoStyle";
import { formatter } from "../../utils/formatNumber";

const useStyles = makeStyles({
  root: {
    padding: " 8% 1.5% 5% 1.5%",
    margin: "0",
  },
  tableCell: {
    borderBottom: "none",
  },
});

const tableHeader = [
  "Item",
  "HSN/SASC",
  "Rate",
  "Quantity",
  "Taxable Amt",
  "IGST",
  "CGST",
  "SGST",
  "Amount (Inc Gst)",
];

const GstInvoiceVoucher = () => {
  const classes = useStyles();
  let unique_id = localStorage.getItem("unique_id");
  const { invoiceId } = useParams();
  const [invoiceData, setInvoiceData] = useState(() => {});
  const [totalRate, setTotalRate] = useState("");
  const [totalQuantity, setTotalQuantity] = useState("");
  const [totalIgst, setTotalIgst] = useState("");
  const [totalCgst, setTotalCgst] = useState("");
  const [totalSgst, setTotalSgst] = useState("");

  const [loading, setLoading] = useState(() => false);
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();

  const { hotelDetails } = useSelector(({ hotelDetail }) => hotelDetail);
  const [voucherDetails, setVoucherDetails] = useState(() => "");
  const pdfRef = useRef(null);

  const downloadPdf = () => {
    const content = pdfRef.current;
    const doc = new jsPDF("portrait", "px", "A4");
    doc.html(content, { callback: () => doc.save(`${invoiceId}.pdf`) });
  };

  const fetchInvoiceData = async () => {
    setLoading(true);
    const { data } = await Api.get(
      `${baseurl}gstdetails/${unique_id}/${invoiceId}`
    );
    console.log(data);
    const totalRate = data.gstBillingInformation.reduce(
      (prev, curr) => (prev += parseFloat(curr.rate)),
      0
    );

    const totalQuantity = data.gstBillingInformation.reduce(
      (prev, curr) => (prev += parseFloat(curr.quantity)),
      0
    );

    const totalIgst =
      data.gstBilledBy.state.toLowerCase() !==
      data.gstBilledTo.clientState.toLowerCase()
        ? data.gstBillingInformation.reduce(
            (prev, curr) => (prev += parseFloat(curr.igst)),
            0
          )
        : "-";

    const totalCgst =
      data.gstBilledBy.state.toLowerCase() ===
      data.gstBilledTo.clientState.toLowerCase()
        ? data.gstBillingInformation.reduce(
            (prev, curr) => (prev += parseFloat(curr.cgst)),
            0
          )
        : "-";

    const totalSgst =
      data.gstBilledBy.state.toLowerCase() ===
      data.gstBilledTo.clientState.toLowerCase()
        ? data.gstBillingInformation.reduce(
            (prev, curr) => (prev += parseFloat(curr.sgst)),
            0
          )
        : "-";
    console.log(totalIgst);

    setInvoiceData(data);
    setTotalRate(totalRate);
    setTotalQuantity(totalQuantity);
    setTotalIgst(totalIgst);
    setTotalCgst(totalCgst);
    setTotalSgst(totalSgst);
    setLoading(false);
  };

  // useEffect(() => {
  //   fetchInvoiceData();
  // }, [invoiceId]);

  const currentLogo = {
    Admin: <TownoGstInfoCard />,
    "Hotel Admin": (
      <OtherLoginInvoiceCard
        src={voucherDetails && `${baseurl}getimage/${voucherDetails.image}`}
        title={voucherDetails && voucherDetails.gstBilledBy.businessName}
      />
    ),
    Sales: <TownoGstInfoCard />,
    "Agent Admin": (
      <OtherLoginInvoiceCard
        src={voucherDetails && `${baseurl}getimage/${voucherDetails.image}`}
        title={voucherDetails && voucherDetails.gstBilledBy.businessName}
      />
    ),
  }[role];

  const adminGstDetails = {
    gstNo: "GSTIN7858758",
    panNo: "CNHOY6774G",
    state: "Uttarakhand",
  };

  const otherLoginGstDetails = {
    gstNo: voucherDetails && voucherDetails.gstNo,
    panNo: voucherDetails && voucherDetails.gstBilledBy.panNo,
    state: voucherDetails && voucherDetails.gstBilledBy.state,
  };

  const companyGstDetails = {
    Admin: adminGstDetails,
    "Hotel Admin": otherLoginGstDetails,
    Sales: adminGstDetails,
    "Agent Admin": otherLoginGstDetails,
  }[role];

  const fetchVoucherDetails = async () => {
    setLoading(true);
    let voucherUrl =
      role === "Agent Admin"
        ? "gstDetailsForAgencyVoucher"
        : "gstDetailsForVoucher";

    const { data } = await Api.get(`${voucherUrl}/${unique_id}/${invoiceId}`);

    const totalRate = data.gstBillingInformation.reduce(
      (prev, curr) => (prev += parseFloat(curr.rate)),
      0
    );

    const totalQuantity = data.gstBillingInformation.reduce(
      (prev, curr) => (prev += parseFloat(curr.quantity)),
      0
    );

    const totalIgst =
      data.gstBilledBy.state.toLowerCase() !==
      data.gstBilledTo.clientState.toLowerCase()
        ? data.gstBillingInformation.reduce(
            (prev, curr) => (prev += parseFloat(curr.igst)),
            0
          )
        : "-";

    const totalCgst =
      data.gstBilledBy.state.toLowerCase() ===
      data.gstBilledTo.clientState.toLowerCase()
        ? data.gstBillingInformation.reduce(
            (prev, curr) => (prev += parseFloat(curr.cgst)),
            0
          )
        : "-";

    const totalSgst =
      data.gstBilledBy.state.toLowerCase() ===
      data.gstBilledTo.clientState.toLowerCase()
        ? data.gstBillingInformation.reduce(
            (prev, curr) => (prev += parseFloat(curr.sgst)),
            0
          )
        : "-";
    console.log(totalIgst);

    setVoucherDetails(data);
    console.log("invoiceDetails>>>", data);
    setTotalRate(totalRate);
    setTotalQuantity(totalQuantity);
    setTotalIgst(totalIgst);
    setTotalCgst(totalCgst);
    setTotalSgst(totalSgst);
    setLoading(false);
  };

  useEffect(() => {
    fetchVoucherDetails();
  }, [invoiceId]);

  return (
    <div className={classes.root} id="gstVoucher">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div ref={pdfRef}>
            <section>
              <div
                style={{
                  paddingLeft: "8px",
                  marginTop: "-18px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                <aside>
                  {currentLogo}
                  <tr>
                    <td style={{ fontSize: "18px", color: "#f46d25" }}>
                      GSTIN
                    </td>
                    <td
                      style={{
                        fontSize: "18px",
                        color: "#f46d25",
                        padding: "0px 10px",
                      }}
                    >
                      :
                    </td>
                    <td style={{ fontSize: "18px" }}>
                      {companyGstDetails.gstNo}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: "18px", color: "#f46d25" }}>PAN</td>
                    <td
                      style={{
                        fontSize: "18px",
                        color: "#f46d25",
                        padding: "0px 10px",
                      }}
                    >
                      :
                    </td>
                    <td style={{ fontSize: "18px" }}>
                      {companyGstDetails.panNo}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: "18px", color: "#f46d25" }}>
                      State
                    </td>
                    <td
                      style={{
                        fontSize: "18px",
                        color: "#f46d25",
                        padding: "0px 10px",
                      }}
                    >
                      :
                    </td>
                    <td style={{ fontSize: "18px" }}>
                      {companyGstDetails.state}
                    </td>
                  </tr>
                </aside>
                <aside>
                  <tr style={{ height: "23px" }}></tr>
                  <tr style={{ fontWeight: "bold" }}>
                    <td style={{ fontSize: "18px", color: "#f46d25" }}>
                      Total Amount
                    </td>
                    <td
                      style={{
                        fontSize: "18px",
                        color: "#f46d25",
                        padding: "0px 10px",
                      }}
                    >
                      :
                    </td>
                    <td style={{ fontSize: "18px", color: "#f46d25" }}>
                      Rs.{" "}
                      {voucherDetails &&
                        formatter.format(voucherDetails?.totalAmountWithGst)}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ fontSize: "18px" }}>Invoice ID</td>
                    <td
                      style={{
                        fontSize: "18px",

                        padding: "0px 10px",
                      }}
                    >
                      :
                    </td>
                    <td style={{ fontSize: "18px" }}>{invoiceId}</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: "18px" }}>Billing Date</td>
                    <td
                      style={{
                        fontSize: "18px",

                        padding: "0px 10px",
                      }}
                    >
                      :
                    </td>
                    <td style={{ fontSize: "18px" }}>
                      {voucherDetails &&
                        format(
                          new Date(voucherDetails?.gstBilledBy?.billingDate),
                          "d MMMM, yyyy"
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: "18px" }}>Due Date</td>
                    <td
                      style={{
                        fontSize: "18px",
                        padding: "0px 10px",
                      }}
                    >
                      :
                    </td>
                    <td style={{ fontSize: "18px" }}>
                      {voucherDetails &&
                        format(
                          new Date(voucherDetails.gstBilledBy.dueDate),
                          "d MMMM, yyyy"
                        )}
                    </td>
                  </tr>
                </aside>
              </div>
            </section>
            <section
              style={{
                marginLeft: "8px",
                border: "2px solid #D5D6D7",
                borderRadius: "8px",
                padding: "10px 30px",
                background: "#F8F8F8",
                margin: "30px 0px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid container item xs={4} direction="column">
                <p style={{ fontWeight: "bold", color: "#f46d25" }}>Customer</p>
                <div>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Name</td>
                    <td style={{ padding: "0px 10px" }}>:</td>
                    <td>
                      {voucherDetails && voucherDetails.gstBilledTo.clientName}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Number</td>
                    <td style={{ padding: "0px 10px" }}>:</td>
                    <td>
                      {voucherDetails &&
                        voucherDetails.gstBilledTo.clientNumber}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>GST No.</td>
                    <td style={{ padding: "0px 10px" }}>:</td>
                    <td>
                      {voucherDetails && voucherDetails.gstBilledTo.gstNo}
                    </td>
                  </tr>
                </div>
              </Grid>
              <Grid
                container
                item
                xs={4}
                direction="column"
                alignItems="baseline"
              >
                <p
                  style={{
                    fontWeight: "bold",
                    color: "#f46d25",
                    textAlign: "center",
                  }}
                >
                  Billing Address
                </p>
                <p style={{ fontSize: "14px" }}>
                  {voucherDetails && voucherDetails.gstBilledTo.billingAddress}
                </p>
                <p style={{ fontSize: "14px" }}>
                  {voucherDetails && voucherDetails.gstBilledTo.clientState}
                </p>
              </Grid>
              <Grid container item xs={4} direction="column">
                <p style={{ fontWeight: "bold", color: "#f46d25" }}>
                  Shipping Address
                </p>
                <p style={{ fontSize: "14px" }}>
                  {voucherDetails && voucherDetails.gstBilledBy.shippingAddress}
                </p>
                <p style={{ fontSize: "14px" }}>
                  {voucherDetails && voucherDetails.gstBilledBy.state}
                </p>
              </Grid>
            </section>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow style={{ background: "#f46d25" }}>
                    {tableHeader.map((header) => (
                      <TableCell style={{ color: "white" }}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {voucherDetails &&
                    voucherDetails.gstBillingInformation.map((bill) => (
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          {bill.bookingItem}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {bill.code}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {" "}
                          {bill.rate}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {bill.quantity}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {parseFloat(bill.rate) * parseFloat(bill.quantity)}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {voucherDetails.gstBilledBy.state.toLowerCase() !==
                          voucherDetails.gstBilledTo.clientState.toLowerCase()
                            ? bill.igst
                            : "-"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {voucherDetails.gstBilledBy.state.toLowerCase() ===
                          voucherDetails.gstBilledTo.clientState.toLowerCase()
                            ? bill.cgst
                            : "-"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {voucherDetails.gstBilledBy.state.toLowerCase() ===
                          voucherDetails.gstBilledTo.clientState.toLowerCase()
                            ? bill.sgst
                            : "-"}
                        </TableCell>

                        <TableCell className={classes.tableCell}>
                          {bill.amountIncGst}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter
                  style={{
                    border: "1px solid #D5D6D7",
                    borderRadius: "8px",
                  }}
                >
                  <TableCell />
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  >
                    ₹ {voucherDetails && formatter.format(totalRate)}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  >
                    {voucherDetails && totalQuantity}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  >
                    ₹{" "}
                    {voucherDetails &&
                      formatter.format(voucherDetails.totalTaxableAmount)}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  >
                    ₹ {voucherDetails && totalIgst}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  >
                    ₹ {voucherDetails && totalCgst}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  >
                    ₹ {voucherDetails && totalSgst}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#000000",
                      fontSize: "16px",
                    }}
                  >
                    ₹{" "}
                    {voucherDetails &&
                      formatter.format(voucherDetails.totalAmountWithGst)}
                  </TableCell>
                </TableFooter>
              </Table>
            </TableContainer>

            <section>
              <p
                style={{
                  fontWeight: "bold",
                  color: "#f46d25",
                  marginLeft: "1.5em",
                }}
              >
                Notes/Remarks
              </p>
              <Box
                style={{
                  borderRadius: "5px",
                  border: "1px solid #D5D6D7",
                  background: "#F8F8F8",
                  padding: "20px",
                }}
              >
                {voucherDetails && voucherDetails.addNotes}
              </Box>
            </section>
          </div>

          {/* <Button style={twnButtonStyles.orangeBtn} onClick={downloadPdf}>
            Download Pdf
          </Button> */}
        </>
      )}
    </div>
  );
};

export default GstInvoiceVoucher;
