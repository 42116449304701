import { Grid, InputAdornment, Stack, TextField } from "@mui/material";
import { format, parse } from "date-fns";
import Select from "react-select";
// import { DualTextField } from "../CreatePackage/AgentCreatePackage"

// export const paymentModeOptions = [
//   { value: "cash", label: "Cash" },
//   { value: "upi", label: "UPI" },
//   { value: "BankTransfer", label: "Bank Transfer" },
//   { value: "Instamojo", label: "Instamojo" },
//   { value: "creditnote", label: "Credit Note" },
// ];

export default function TransactionSummary(props) {
  const {
    transferPaymentDetails,
    setTransferPaymentDetails,
    formData,
    tabValue,
    paymentModeOptions,
  } = props;
  return (
    <>
      <Stack
        spacing={2}
        style={{
          backgroundColor: "#eee",
          width: "auto",
          border: "1px solid #eee",
          padding: "0.5em 1em",
        }}
      >
        <span style={{ color: "#f46d25", width: "100%", fontWeight: "bold" }}>
          Transaction Summary
        </span>
      </Stack>
      <Stack spacing={2} style={{ width: "auto", border: "1px solid #eee" }}>
        <Grid container spacing={2} style={{ padding: "0.5em" }}>
          <Grid item xs={4}>
            <DualTextField
              label="Amount Paid *"
              name="paidAmount"
              value={transferPaymentDetails.paidAmount}
              changeFn={setTransferPaymentDetails}
            />
          </Grid>
          <Grid item xs={4}>
            <DualTextField
              label="Reference Number"
              name="paymentRefNo"
              value={transferPaymentDetails.paymentRefNo}
              changeFn={setTransferPaymentDetails}
              inputValue={false}
            />
          </Grid>
          <Grid item xs={4}>
            <DualTextField
              label="Payment Mode"
              name="paymentMode"
              value=""
              changeFn={(option) =>
                setTransferPaymentDetails({
                  target: { name: "paymentMode", value: option.label },
                })
              }
              options={paymentModeOptions}
            />
          </Grid>
        </Grid>
        {/*Transaction Table  */}
        {formData.transferPaymentDetails.length > 0 && (
          <>
            <Grid container spacing={0} style={{ paddingLeft: "1em" }}>
              <Grid item xs={3}>
                <span
                  style={{
                    color: "#f46d25",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Date
                </span>
              </Grid>
              <Grid item xs={3}>
                <span
                  style={{
                    color: "#f46d25",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Amount Paid
                </span>
              </Grid>
              <Grid item xs={3}>
                <span
                  style={{
                    color: "#f46d25",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Payment Mode
                </span>
              </Grid>
              <Grid item xs={3}>
                <span
                  style={{
                    color: "#f46d25",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Reference Number
                </span>
              </Grid>
              {formData.transferPaymentDetails.map((each, index) => {
                return (
                  <>
                    <Grid
                      item
                      xs={3}
                      style={{
                        color: "#111",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {each.createdAt
                        ? format(
                            parse(
                              each.createdAt,
                              "yyyy-MM-dd HH:mm:ss",
                              new Date()
                            ),
                            "dd MMM yy, hh:mm a"
                          )
                        : null}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        color: "#111",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {each.paidAmount}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        color: "#111",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {each.paymentMode}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        color: "#111",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      {each.paymentRefNo}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </>
        )}
        <br />
      </Stack>
    </>
  );
}
export function DualTextField(props) {
  return (
    <Grid container>
      <Grid item xs={6}>
        <DualTextFieldLabel label={props.label} fullWidth={props.fullWidth} />
      </Grid>
      <Grid item xs={6}>
        {props.options ? (
          <DualTextFieldSelect
            options={props.options}
            changeFn={props.changeFn}
          ></DualTextFieldSelect>
        ) : (
          <DualTextFieldValue
            name={props.name}
            value={props.value}
            changeFn={props.changeFn}
            disabled={props.disabled}
            inputValue={props.inputValue}
          />
        )}
      </Grid>
    </Grid>
  );
}

DualTextField.defaultProps = {
  options: null,
  fullWidth: true,
  disabled: false,
  inputValue: true,
};

function DualTextFieldLabel(props) {
  const { tabValue } = props;
  return (
    <TextField
      variant="outlined"
      size="small"
      fullWidth={props.fullWidth}
      style={{
        color: "#138128",
        borderColor: "#deffde",
      }}
      InputLabelProps={{
        style: {
          color: "#f46d25",
          marginTop: "-7px",
          fontSize: "15px",
          marginLeft: "0",
        },
      }}
      InputProps={{ sx: { height: "40px" } }}
      label={props.label}
      disabled
      // sx={{ '& .MuiInputBase-input': { height: 0 } }}
    />
  );
}

function DualTextFieldValue(props) {
  const InputProps = {
    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
    sx: { height: "40px" },
  };
  return (
    <TextField
      name={props.name}
      data-testid={props.name}
      variant="outlined"
      fullWidth
      disabled={props.disabled}
      style={{
        backgroundColor: props.name === "paymentRefNo" ? "#FFFFFF" : "#ffe2d7",
        marginLeft: "-20px",
      }}
      InputProps={props.inputValue ? InputProps : { sx: { height: "40px" } }}
      // InputProps={{
      //     startAdornment: (
      //         <InputAdornment position="start">
      //             ₹
      //         </InputAdornment>
      //     ),
      // }}
      size="small"
      value={props.value}
      onChange={props.changeFn}
      autoComplete="off"
      // sx={{ '& .MuiInputBase-input': { height: 0 } }}
    />
  );
}

function DualTextFieldSelect(props) {
  return (
    <div
      style={{
        borderColor: "#f46d25",
        width: "100%",
        marginLeft: "-10px",
        height: "100%",
      }}
    >
      <Select
        placeholder="Select"
        options={props.options}
        onChange={props.changeFn}
        styles={{
          control: (base, state) => ({
            ...base,
            height: "40px",
            border: "1px solid #f46D25",
          }),
        }}
      />
    </div>
  );
}
