import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  ThemeProvider,
  createTheme,
  makeStyles,
  styled,
} from "@material-ui/core"
import SearchIcon from "@material-ui/icons/SearchOutlined"
import DownloadIcon from "@material-ui/icons/GetApp"
import VisibilityIcon from "@material-ui/icons/Visibility"
import EditIcon from "@material-ui/icons/Create"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import Loader from "../../components/Loader/Loader"
import { useEffect, useRef, useState } from "react"
import { createPDF } from "./ViewTransferMobileUI"

const useStyles = makeStyles({
  root: {
    padding: "23% 3%",
    paddingBottom: "20%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  createButton: {
    background: "#343A40",
    fontSize: "18px",
    height: "50%",
  },
  searchField: {
    width: "100%",
  },
  select: {
    "&:before": {
      border: "none",
    },
    "&:after": {
      border: "none",
    },
  },
})

const customTheme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        border: "1px solid #343A40",
      },
    },
    MuiButton: {
      root: {
        background: "#343A40 !important",
        color: "white",
        fontSize: "12px",
        "& $hover": {
          background: "#343A40",
        },
      },
    },
  },
})

const TransferListMobile = ({ data, textToSearch, setTextToSearch }) => {
  const classes = useStyles()
  const history = useHistory()

  const totalDataLength = data.length
  const dataPerPage = 6
  let noOfPages = Math.ceil(totalDataLength / dataPerPage)
  console.log("totalDataLength", totalDataLength, noOfPages)
  const [currentPageNo, setCurrentPageNo] = useState(1)
  let tempDataIndex = currentPageNo * dataPerPage //variable for min and max data range for page
  let lastDataIndex = Math.min(tempDataIndex, totalDataLength)
  let firstDataIndex = 0
  console.log("first and last indices of data:", firstDataIndex, lastDataIndex)

  const changePageNo = (value) => {
    let tempPageNo = currentPageNo + value //value can be 1 or -1 depending upon arrow clicked
    if (tempPageNo >= 1 && tempPageNo <= noOfPages) {
      setCurrentPageNo(tempPageNo)
    }
  }

  const loaderRef = useRef(null)
  const [showButton, setShowButton] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement
    const scrollPosition = window.pageYOffset

    // if (windowHeight + scrollPosition >= documentHeight) {
    //   setPage((prevPage) => prevPage + 1);
    // }

    console.log(
      "scroll height",
      Math.ceil(window.innerHeight + document.documentElement.scrollTop)
    )

    console.log("offsetHeight", document.documentElement.offsetHeight)

    scrollPosition > 300 ? setShowButton(true) : setShowButton(false)

    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) >=
      document.documentElement.offsetHeight
    ) {
      setLoading(true)

      setTimeout(() => {
        setLoading(false)
        changePageNo(1)
      }, 2000)
      console.log("ok")
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [firstDataIndex, lastDataIndex, currentPageNo, handleScroll])

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span
          style={{ fontSize: "20px", fontWeight: "bold", color: "#343A40" }}
        >
          {" "}
          Transfer{" "}
        </span>
        <ThemeProvider theme={customTheme}>
          <Button onClick={() => history.push(`/konnect/transferForm`)}>
            Create Transfer
          </Button>
        </ThemeProvider>
      </div>
      <Grid item style={{ width: "100%" }}>
        <ThemeProvider theme={customTheme}>
          <TextField
            type="search"
            style={{ width: "100%", marginTop: "5%", paddingBottom: "5%" }}
            placeholder="Search Booking Id"
            value={textToSearch}
            onChange={(e) => {
              setTextToSearch(e.target.value)
            }}
            InputProps={{
              style: { height: "40px" },
              startAdornment: (
                <InputAdornment
                  style={{
                    padding: "0",
                    margin: "0",
                    color: "#f46d25",
                    marginLeft: "-2%",
                  }}
                >
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </ThemeProvider>
      </Grid>
      {data.slice(firstDataIndex, lastDataIndex).map((detail) => (
        <div style={{ paddingBottom: "5%" }}>
          <div
            style={{
              display: "flex",
              background: "#f46d25",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "10px 10px 0px 0px",
              height: "35px",
              padding: "1%",
            }}
          >
            <h4 style={{ color: "white" }}>{detail.transferId}</h4>
            <div
              style={{
                width: "30%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                style={{
                  color: "#343A40",
                  border: "50%",
                  background: "white",
                  height: "20px",
                  width: "20px",
                }}
                onClick={async () => createPDF(detail.transferId)}
              >
                <DownloadIcon style={{ height: "18px" }} />
              </IconButton>

              <IconButton
                style={{
                  color: "#343A40",
                  border: "50%",
                  background: "white",
                  height: "20px",
                  width: "20px",
                }}
                onClick={() =>
                  history.push(`/konnect/viewTransfer/${detail.transferId}`)
                }
              >
                <VisibilityIcon style={{ height: "18px" }} />
              </IconButton>

              <IconButton
                style={{
                  color: "#343A40",
                  border: "50%",
                  background: "white",
                  height: "20px",
                  width: "20px",
                }}
                onClick={() =>
                  history.push(`/konnect/transferForm/${detail.transferId}`)
                }
              >
                <EditIcon style={{ height: "18px" }} />
              </IconButton>
            </div>
          </div>
          <Paper
            style={{
              display: "flex",
              borderRadius: "0px 0px 10px 10px",
              padding: "1%",
            }}
          >
            <Grid container>
              <Grid container xs={6}>
                <Grid item style={{ fontSize: "12px", width: "100%" }}>
                  <span style={{ fontWeight: "bold" }}>Name :</span>{" "}
                  {detail.clientName}
                </Grid>
                <Grid item style={{ fontSize: "12px", width: "100%" }}>
                  <span style={{ fontWeight: "bold" }}>Agent :</span>{" "}
                  {detail.createdBy}
                </Grid>
                <Grid item style={{ fontSize: "12px", width: "100%" }}>
                  <span style={{ fontWeight: "bold" }}>No. :</span>{" "}
                  {detail.clientPhoneNumber}
                </Grid>
              </Grid>
              <Grid container xs={6}>
                <Grid item style={{ fontSize: "12px", width: "100%" }}>
                  <span style={{ fontWeight: "bold" }}>Transfer :</span>{" "}
                  {detail.type.split(",").join(" | ")}
                </Grid>
                <Grid item style={{ fontSize: "12px", width: "100%" }}>
                  <span style={{ fontWeight: "bold" }}>Total Amount :</span> Rs.
                  {detail.totalAmount}
                </Grid>
                <Grid item style={{ fontSize: "12px", width: "100%" }}>
                  <span style={{ fontWeight: "bold" }}>Commission :</span> Rs.
                  {detail.commission}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </div>
      ))}
      <div ref={loaderRef} />
      {loading && lastDataIndex < data.length && (
        <div
          style={{
            top: 0,
            width: "100%",
            height: "5%",
            padding: "0",
            margin: "0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      )}
    </div>
  )
}

export default TransferListMobile
