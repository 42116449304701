import styles from "./KeyPointCard.module.css"

const KeyPointCard = ({ header, content, imagePath }) => {
  return (
    <div className={styles.keyPoint}>
      {/* <div
        className={styles.imgContainer}
        style={{ background: `url(${imagePath})`, objectFit: "cover" }}
      ></div> */}
      <img src={imagePath} className={styles.imgContainer} />
      <div className={styles.textContainer}>
        <header className={styles.header}>{header}</header>
        <div className={styles.content}>{content}</div>
      </div>
    </div>
  )
}

export default KeyPointCard
