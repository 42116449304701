import { Breadcrumbs, Typography, makeStyles } from "@material-ui/core"
import usePathProvider from "../../hooks/usePathProvider.hook"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import _, { entries } from "lodash"
import { useContext, useEffect, useRef, useState } from "react"
import { SideNavbarContext } from "../../context/sideNavBarContext"

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    background: "linear-gradient(rgba(255, 243, 0, 1),rgba(255, 0, 0, 1))",
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
    "@media(max-width:768px)": {
      fontSize: "1.2rem",
    },
  },

  seperator: {
    "& .MuiBreadcrumbs-separator": {
      background: "linear-gradient(rgba(255, 243, 0, 1),rgba(255, 0, 0, 1))",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    padding: "1rem 2rem",
    fontSize: "1.5rem",
    "@media(max-width:768px)": {
      padding: "0.5rem 1rem",
      fontSize: "1.2rem",
    },
  },
  typography: {
    fontSize: "1.5rem",
    color: "hsla(0, 0%, 100%, 0.72)",
    "@media(max-width:768px)": {
      fontSize: "1.2rem",
    },
  },
}))

const alteredLabel = (label) => {
  let newLabel = ""
  switch (label) {
    case "placesToStay":
      newLabel = "Places to Visit"
      break
    case "tourPackages":
      newLabel = "Tour Packages"
      break
    case "bestTimeToVisit":
      newLabel = "Best time to visit"
      break
    case "thingsToDo":
      newLabel = "Things To Do"
      break
    case "howToReach":
      newLabel = "How to reach"
      break
    case "keyFacts":
      newLabel = "Key facts"
      break
    case "shoppingAndEntertainment":
      newLabel = "Shopping and Entertainment"
      break
    case "thingsToCarry":
      newLabel = "Things to carry"
      break
    case "festivalsAndEvents":
      newLabel = "Festival and events"
      break
    default:
      newLabel = label
  }

  return newLabel
}

const BredCrumbs = ({ pathname }) => {
  const styles = useStyles()
  const [paths, setPaths] = useState([])
  const crumbsRef = useRef(null)

  const { setDestinationTitle } = useContext(SideNavbarContext)

  useEffect(() => {
    const pathArr = pathname?.split("/")
    const filteredPath = pathArr?.filter((p) => !_.isEmpty(p))
    setPaths(filteredPath)
  }, [pathname])

  useEffect(() => {
    const breadcrumbs = crumbsRef.current

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setDestinationTitle("Destinations")
      } else {
        setDestinationTitle(pathname.split("/")[1])
      }
    })

    observer.observe(breadcrumbs)

    return () => {
      observer.disconnect()
      setDestinationTitle("Destinations")
    }
  }, [])

  return (
    <Breadcrumbs
      separator={"›"}
      className={styles.seperator}
      innerRef={crumbsRef}
    >
      <Link className={styles.breadcrumbs} to="/">
        Home
      </Link>
      {paths?.map((p, i) => {
        const last = i == paths.length - 1
        const to = `/${paths.slice(0, i + 1).join("/")}`

        return last ? (
          <Typography style={{}} key={to} className={styles.typography}>
            {console.log(p)}
            {_.capitalize(alteredLabel(p))}
          </Typography>
        ) : (
          <Link to={to} key={`${to}-${i}`} className={styles.breadcrumbs}>
            {_.capitalize(p.split("/")[0])}
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}

export default BredCrumbs
