import { useContext, useLayoutEffect } from "react"
import AsideDestinationNavbar from "../../../../components/AsideDestinationNavbar/AsideDestinationNavbar.component"
import BiggerGroupBannerPost from "../../../../components/BiggerGroupBanner/BiggerGroupBanner.component"
import ContactInfo from "../../../../components/ContactInfo/ContactInfo.component"
import ImageAndContentCard from "../../../../components/ImageAndContentCard/ImageAndContentCard.component"
import QueryForm from "../../../../components/QueryForm/QueryForm.component"
import globalStyles from "../../../../globalStyles.module.css"
import DestinationSubLayout from "../../../../layout/DestinationSubLayout/DestinationSub.layout"
import styles from "./DestinationFestivalsAndEvents.module.css"
import parser from "html-react-parser"
import { DestinationLayoutContext } from "../../../../layout/DestinationLayout/Destination.layout"

const festivalsAndEvents = [
  {
    src: "",
    title: "Nanda Devi Mela In Uttarakhand",
    content:
      "The Nanda Devi Mela is a colourful fair organised in the month of September in Almora for 5 to 7 days during the festival of Nanda Ashtami. The fair is held in the temple of Nanda Devi. Processions can also be seen in many places in Uttarakhand like Almora, Nainital, Nauti, Dandidhara, Munsyari, Ranikhet, Bhowali, and Kichha One of the most popular festivals in Uttarakhand, along with the local songs and folk dances, a huge market is organized which displays local handmade products. ",
  },
  {
    src: "",
    title: "Kumbh Mela",
    content:
      "One of the largest and most popular events of Uttarakhand, the Kumbh Mela is a three-month-long festival. This event takes place once every 4 years rotating between Ujjain, Haridwar, Allahabad and Nashik. Thousands of devotees visit the holy river Ganga to take a dip in the river to wash away their sins during this period.",
  },
  {
    src: "",
    title: "Ganga Dussehra ",
    content:
      "A unique Uttarakhand festival that celebrates the advent of Ganga from heaven, this festival is celebrated on the tenth day of the bright half of the moon in the Hindu month of Jyaistha, which is around May. A ten-day festival, it is accompanied by devotees taking holy dips in the river Ganga as well as a lot of rituals celebrating the river.",
  },
  {
    src: "",
    title: "International yoga festival",
    content:
      "One of the most exciting events happening in Uttarakhand, the international yoga festival is held every year in March since 1999. This festival is attended by thousands of Yogis from across the world who come together to celebrate the spirit of yoga.",
  },
  {
    src: "",
    title: "Kanwar Yatra",
    content:
      "When you’re looking for upcoming events in Uttarakhand, make sure you check out the Kanwar Yatra. The yatra takes place during the religious Hindu month of Shravan, which comes around July. Thousands of devotees of Lord Shiva from across India travel to Uttarakhand to the banks of river Ganga to collect Ganga water (Gangajal) and take it home to pour on the Shiv Lingas in their towns and villages. The Kanwariyas as they are called head to cities such as Haridwar and Gangotri.  ",
  },
  {
    src: "",
    title: "Magh Mela Uttarkashi",
    content:
      "A religious fair is held in the month of January where palanquins carry a Hindu God deity to Vishwanath Temple in Uttarkashi along with colourful processions.",
  },
  {
    src: "",
    title: "Holi",
    content:
      "The most vibrant festival out of the event calendar of Uttarakhand, this festival of colours is widely celebrated across Uttarakhand. Celebrated with great pomp and show by people of all ages and ethnicities, this is a must-attend festival if you’re planning a trip to Uttarakhand in March.<br/>In addition to these festivals and events in Uttarakhand, there are numerous locally held upcoming events in Uttarakhand such as the Uttarakhand Literary Festival, and the Uttarakhand Film Festival, which you must not miss during your trip.",
  },
  { src: "", title: "", content: "" },
]

const DestinationFestivalsAndEvents = () => {
  const { setBanner } = useContext(DestinationLayoutContext)

  useLayoutEffect(() => {
    setBanner("festivalsAndEvents")
  }, [])

  return (
    <DestinationSubLayout>
      <main className={globalStyles.main}>
        <section className={globalStyles.topMostSection}>
          <header>
            Festivals And Events In <span>Uttarakhand</span>
          </header>
          <div className={globalStyles.content}>
            With its rich heritage and culture, Uttarakhand is the perfect
            destination to celebrate festivals of colour and spirit. If you’re
            planning to visit this state, here’s a <b>list of festivals</b> in
            Uttarakhand that must be on your radar:
          </div>
        </section>
        <section className={styles.contentList}>
          {festivalsAndEvents.map((f, idx) => (
            <ImageAndContentCard key={idx}>
              <ImageAndContentCard.Title>{f.title}</ImageAndContentCard.Title>
              <ImageAndContentCard.Content>
                {parser(f.content)}
              </ImageAndContentCard.Content>
            </ImageAndContentCard>
          ))}
        </section>
        <BiggerGroupBannerPost />
      </main>
    </DestinationSubLayout>
  )
}

export default DestinationFestivalsAndEvents
