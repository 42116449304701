import React from "react";
import { Box, Stack, TextField, Grid } from "@mui/material";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Select, { components } from "react-select";

const CabTrip = [
  { label: "Pickup", value: "Pickup" },
  { label: "Drop", value: "Drop" },
  { label: "Round", value: "Round" },
];
const CabType = [
  { label: "Private", value: "Private" },
  { label: "SIC", value: "SIC" },
];
const CabVehicle = [
  { label: "Hatchback", value: "Hatchback" },
  { label: "SUV", value: "SUV" },
  { label: "Sedan", value: "Sedan" },
  { label: "12 Seater", value: "12 Seater" },
  { label: "24 Seater", value: "24 Seater" },
  { label: "48 Seater", value: "48 Seater" },
];

const getTransferVehicleOptions = (transferType) => {
  if (transferType === "Private") {
    return [
      { label: "Sedan", value: "Sedan" },
      { label: "Hatchback", value: "Hatchback" },
      { label: "SUV", value: "SUV" },
    ];
  } else if (transferType === "SIC") {
    return [
      { label: "12 Seater", value: "12 Seater" },
      { label: "24 Seater", value: "24 Seater" },
      { label: "48 Seater", value: "48 Seater" },
    ];
  } else {
    return [];
  }
};

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

export default function RoadForm({
  cabInput,
  setCabInput,
  busInput,
  setBusInput,
  cabInputError,
  setCabInputError,
  busInputError,
  setBusInputError,
  classes,
}) {
  const validateField = (name, value) => {
    var error = "";

    switch (name) {
      case "cabFrom":
      case "busFrom":
      case "cabTo":
      case "busTo":
        error = value.length < 3 ? "Required" : "";
        break;
      case "cabAdults":
      case "busAdults":
        error = /^\+?\d+$/.test(value) ? "" : "Enter a valid positive no.";
        break;
      case "cabAmount":
      case "busAmount":
        error = /^\+?\d+$/.test(value) ? "" : "Enter valid amount";
        break;
    }
    return error;
  };

  const handleRemoveClickCab = (index) => {
    const list = [...cabInput];
    list.splice(index, 1);
    setCabInput(list);

    const listError = [...cabInputError];
    listError.splice(index, 1);
    setCabInputError(listError);
  };
  const handleAddClickCab = () => {
    setCabInput([
      ...cabInput,
      {
        id: "",
        cabFrom: "",
        cabTo: "",
        cabTrip: "",
        cabType: "",
        cabVehicle: "",
        cabAdults: "",
        cabChild: "",
        cabAmount: "",
        cabCommission: "",
      },
    ]);

    setCabInputError([
      ...cabInputError,
      {
        id: "",
        cabFrom: "",
        cabTo: "",
        cabTrip: "",
        cabType: "",
        cabVehicle: "",
        cabAdults: "",
        cabChild: "",
        cabAmount: "",
        cabCommission: "",
      },
    ]);
  };
  const handleCabChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...cabInput];
    list[index][name] = value;
    setCabInput(list);

    const listError = [...cabInputError];
    const error = validateField(name, value.trim());
    listError[index][name] = error;
    setCabInputError(listError);
  };
  const handleCabSelectChange = (name, value, index) => {
    const list = [...cabInput];
    list[index][name] = value;
    setCabInput(list);
  };

  const handleRemoveClickBus = (index) => {
    const list = [...busInput];
    list.splice(index, 1);
    setBusInput(list);
  };
  const handleAddClickBus = () => {
    setBusInput([
      ...busInput,
      {
        id: "",
        busName: "",
        busFrom: "",
        busTo: "",
        busSeatNo: "",
        busAdults: "",
        busChild: "",
        busAmount: "",
        busCommission: "",
      },
    ]);

    setBusInputError([
      ...busInputError,
      {
        id: "",
        busName: "",
        busFrom: "",
        busTo: "",
        busSeatNo: "",
        busAdults: "",
        busChild: "",
        busAmount: "",
        busCommission: "",
      },
    ]);
  };
  const handleBusChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...busInput];
    list[index][name] = value;
    setBusInput(list);

    const listError = [...busInputError];
    const error = validateField(name, value.trim());
    listError[index][name] = error;
    setBusInputError(listError);
  };

  return (
    <div>
      <Stack
        style={{ padding: window.innerWidth <= 768 ? "0" : "0.8em 0.5em" }}
      >
        {cabInput.map((x, i) =>
          window.innerWidth <= 768 ? (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {i == 0 && (
                  <p
                    style={{
                      fontSize: "18px",
                      margin: "0",
                      color: "#f46d25",
                      fontWeight: "500",
                    }}
                  >
                    Cab Details
                  </p>
                )}
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  {i + 1 + "."}
                </Grid>
                <Grid item xs={6} textAlign="end">
                  {cabInput.length !== 1 && (
                    <DeleteIcon
                      onClick={() => handleRemoveClickCab(i)}
                      className={classes.plus}
                    />
                  )}
                  {cabInput.length - 1 === i && (
                    <AddCircleOutlineIcon
                      onClick={handleAddClickCab}
                      size="small"
                      className={classes.plus}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <CustomTextInlineField
                  name="cabFrom"
                  label="From*"
                  value={x.cabFrom}
                  onChange={(e) => {
                    handleCabChange(e, i);
                  }}
                  error={cabInputError[i]["cabFrom"]}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextInlineField
                  name="cabTo"
                  label="To*"
                  value={x.cabTo}
                  onChange={(e) => {
                    handleCabChange(e, i);
                  }}
                  error={cabInputError[i]["cabTo"]}
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  name="cabTrip"
                  placeholder="Pickup/Drop/Round"
                  value={CabTrip.filter((i) => i.value === x.cabTrip)}
                  options={CabTrip}
                  onChange={(e) => handleCabSelectChange("cabTrip", e.value, i)}
                  components={{
                    ValueContainer: CustomValueContainer,
                  }}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    control: (base, state) => ({
                      ...base,
                      "&:hover": { borderColor: "#f46d25" },
                      borderColor: "#f46d25",
                      boxShadow: "none",
                      width: "100%",
                    }),
                    placeholder: (provided, state) => ({
                      ...provided,
                      position: "absolute",
                      top:
                        state.hasValue || state.selectProps.inputValue
                          ? -4
                          : "50%",
                      background: "#fff",
                      padding: "0px 5px",
                      transition: "top 0.1s, font-size 0.1s",
                      fontSize: "14px",
                    }),
                    container: (provided) => ({
                      ...provided,
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      overflow: "visible",
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  name="cabType"
                  placeholder="Private/SIC"
                  value={CabType.filter((i) => i.value === x.cabType)}
                  options={CabType}
                  onChange={(e) => handleCabSelectChange("cabType", e.value, i)}
                  components={{
                    ValueContainer: CustomValueContainer,
                  }}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    control: (base, state) => ({
                      ...base,
                      "&:hover": { borderColor: "#f46d25" },
                      borderColor: "#f46d25",
                      boxShadow: "none",
                    }),
                    placeholder: (provided, state) => ({
                      ...provided,
                      position: "absolute",
                      top:
                        state.hasValue || state.selectProps.inputValue
                          ? -4
                          : "50%",
                      background: "#fff",
                      padding: "0px 5px",
                      transition: "top 0.1s, font-size 0.1s",
                      fontSize: "14px",
                    }),
                    container: (provided) => ({
                      ...provided,
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      overflow: "visible",
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  name="cabVehicle"
                  placeholder="Select Vehicle"
                  value={CabVehicle.filter((i) => i.value === x.cabVehicle)}
                  options={getTransferVehicleOptions(x.cabType)}
                  onChange={(e) =>
                    handleCabSelectChange("cabVehicle", e.value, i)
                  }
                  components={{
                    ValueContainer: CustomValueContainer,
                  }}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    control: (base, state) => ({
                      ...base,
                      "&:hover": { borderColor: "#f46d25" },
                      borderColor: "#f46d25",
                      boxShadow: "none",
                    }),
                    placeholder: (provided, state) => ({
                      ...provided,
                      position: "absolute",
                      top:
                        state.hasValue || state.selectProps.inputValue
                          ? -4
                          : "50%",
                      background: "#fff",
                      padding: "0px 5px",
                      transition: "top 0.1s, font-size 0.1s",
                      fontSize: "14px",
                    }),
                    container: (provided) => ({
                      ...provided,
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      overflow: "visible",
                    }),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextInlineField
                  name="cabAdults"
                  label="Adults*"
                  value={x.cabAdults}
                  onChange={(e) => {
                    handleCabChange(e, i);
                  }}
                  error={cabInputError[i]["cabAdults"]}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextInlineField
                  name="cabChild"
                  label="Children"
                  value={x.cabChild}
                  onChange={(e) => {
                    handleCabChange(e, i);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextInlineField
                  name="cabAmount"
                  label="Amount*"
                  style={{ backgroundColor: "#fff" }}
                  value={x.cabAmount}
                  onChange={(e) => {
                    handleCabChange(e, i);
                  }}
                  error={cabInputError[i]["cabAmount"]}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomTextInlineField
                  name="cabCommission"
                  fullWidth
                  label="Commission*"
                  error={cabInputError[i]["cabCommission"]}
                  // style={{ borderColor: "#deffde" }}
                  value={x.cabCommission != "" ? x.cabCommission : " "}
                  defaultValue=" "
                  variant="outlined"
                  size="small"
                  onChange={(e) => {
                    handleCabChange(e, i);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      padding: "9px 14px",
                      backgroundColor: "#F7F7F7",
                    },
                    "& .MuiInputBase-input": {
                      height: 1,
                      backgroundColor: "#F7F7F7",
                    },
                    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                      border: "1.8px solid #f46d25",
                    },
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container columnSpacing={1.5} rowSpacing={1} key={i}>
                <Grid item sm={6}>
                  {i == 0 && (
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "0",
                        color: "#f46d25",
                        fontWeight: "500",
                      }}
                    >
                      Cab Details
                    </p>
                  )}
                </Grid>
                <Grid item sm={6} style={{ textAlign: "end" }}>
                  {cabInput.length !== 1 && (
                    <DeleteIcon
                      onClick={() => handleRemoveClickCab(i)}
                      className={classes.plus}
                    />
                  )}
                  {cabInput.length - 1 === i && (
                    <AddCircleOutlineIcon
                      onClick={handleAddClickCab}
                      size="small"
                      className={classes.plus}
                    />
                  )}
                </Grid>
                <div
                  style={{
                    width: "2%",
                    textAlign: "center",
                    marginTop: "1em",
                    fontSize: "14px",
                  }}
                >
                  {i + 1 + "."}
                </div>
                <div style={{ width: "98%", marginTop: "1em" }}>
                  <Stack direction="row" width="100%" spacing={2}>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="cabFrom"
                        label="From*"
                        value={x.cabFrom}
                        onChange={(e) => {
                          handleCabChange(e, i);
                        }}
                        error={cabInputError[i]["cabFrom"]}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="cabTo"
                        label="To*"
                        value={x.cabTo}
                        onChange={(e) => {
                          handleCabChange(e, i);
                        }}
                        error={cabInputError[i]["cabTo"]}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <Select
                        name="cabTrip"
                        placeholder="Pickup/Drop/Round"
                        value={CabTrip.filter((i) => i.value === x.cabTrip)}
                        options={CabTrip}
                        onChange={(e) =>
                          handleCabSelectChange("cabTrip", e.value, i)
                        }
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        styles={{
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          control: (base, state) => ({
                            ...base,
                            "&:hover": { borderColor: "#f46d25" },
                            borderColor: "#f46d25",
                            boxShadow: "none",
                            width: "100%",
                          }),
                          placeholder: (provided, state) => ({
                            ...provided,
                            position: "absolute",
                            top:
                              state.hasValue || state.selectProps.inputValue
                                ? -4
                                : "50%",
                            background: "#fff",
                            padding: "0px 5px",
                            transition: "top 0.1s, font-size 0.1s",
                            fontSize: "14px",
                          }),
                          container: (provided) => ({
                            ...provided,
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            overflow: "visible",
                          }),
                        }}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <Select
                        name="cabType"
                        placeholder="Private/SIC"
                        value={CabType.filter((i) => i.value === x.cabType)}
                        options={CabType}
                        onChange={(e) =>
                          handleCabSelectChange("cabType", e.value, i)
                        }
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        styles={{
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          control: (base, state) => ({
                            ...base,
                            "&:hover": { borderColor: "#f46d25" },
                            borderColor: "#f46d25",
                            boxShadow: "none",
                          }),
                          placeholder: (provided, state) => ({
                            ...provided,
                            position: "absolute",
                            top:
                              state.hasValue || state.selectProps.inputValue
                                ? -4
                                : "50%",
                            background: "#fff",
                            padding: "0px 5px",
                            transition: "top 0.1s, font-size 0.1s",
                            fontSize: "14px",
                          }),
                          container: (provided) => ({
                            ...provided,
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            overflow: "visible",
                          }),
                        }}
                      />
                    </div>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    width="100%"
                    marginTop="10px"
                  >
                    <div style={{ width: "100%" }}>
                      <Select
                        name="cabVehicle"
                        placeholder="Select Vehicle"
                        value={CabVehicle.filter(
                          (i) => i.value === x.cabVehicle
                        )}
                        options={getTransferVehicleOptions(x.cabType)}
                        onChange={(e) =>
                          handleCabSelectChange("cabVehicle", e.value, i)
                        }
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        styles={{
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          control: (base, state) => ({
                            ...base,
                            "&:hover": { borderColor: "#f46d25" },
                            borderColor: "#f46d25",
                            boxShadow: "none",
                          }),
                          placeholder: (provided, state) => ({
                            ...provided,
                            position: "absolute",
                            top:
                              state.hasValue || state.selectProps.inputValue
                                ? -4
                                : "50%",
                            background: "#fff",
                            padding: "0px 5px",
                            transition: "top 0.1s, font-size 0.1s",
                            fontSize: "14px",
                          }),
                          container: (provided) => ({
                            ...provided,
                          }),
                          valueContainer: (provided) => ({
                            ...provided,
                            overflow: "visible",
                          }),
                        }}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="cabAdults"
                        label="Adults*"
                        value={x.cabAdults}
                        onChange={(e) => {
                          handleCabChange(e, i);
                        }}
                        error={cabInputError[i]["cabAdults"]}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="cabChild"
                        label="Children"
                        value={x.cabChild}
                        onChange={(e) => {
                          handleCabChange(e, i);
                        }}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="cabAmount"
                        label="Amount*"
                        style={{ backgroundColor: "#fff" }}
                        value={x.cabAmount}
                        onChange={(e) => {
                          handleCabChange(e, i);
                        }}
                        error={cabInputError[i]["cabAmount"]}
                      />
                    </div>
                  </Stack>
                  {/* 
                                MuiOutlinedInput-root : {
                                    border: 1px solid red
                                }
                                 */}
                  <Stack direction="row" spacing={2} marginTop="10px">
                    <div style={{ width: "100%" }}>
                      {/* <CustomTextInlineField name="cabCommission" label='Commission' style={{ backgroundColor: '#fff' }} value={x.cabCommission} onChange={(e) => { handleCabChange(e, i) }} width="100%" /> */}
                      <CustomTextInlineField
                        name="cabCommission"
                        fullWidth
                        label="Commission*"
                        error={cabInputError[i]["cabCommission"]}
                        // style={{ borderColor: "#deffde" }}
                        value={x.cabCommission != "" ? x.cabCommission : " "}
                        defaultValue=" "
                        variant="outlined"
                        size="small"
                        onChange={(e) => {
                          handleCabChange(e, i);
                        }}
                        sx={{
                          "& .MuiOutlinedInput-input": {
                            padding: "9px 14px",
                            backgroundColor: "#F7F7F7",
                          },
                          "& .MuiInputBase-input": {
                            height: 1,
                            backgroundColor: "#F7F7F7",
                          },
                          "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                            border: "1.8px solid #f46d25",
                          },
                        }}
                      />
                    </div>
                    <div style={{ width: "100%" }}></div>
                    <div style={{ width: "100%" }}></div>
                    <div style={{ width: "100%" }}></div>
                  </Stack>
                </div>
              </Grid>
            </>
          )
        )}
      </Stack>
      <Stack
        style={{ padding: window.innerWidth <= 768 ? "0" : "0.8em 0.5em" }}
      >
        {busInput.map((x, i) => (
          <>
            {window.innerWidth <= 768 ? (
              <Grid container spacing={1} style={{ paddingTop: "30px" }}>
                <Grid item xs={12}>
                  {i == 0 && (
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "0",
                        color: "#f46d25",
                        fontWeight: "500",
                      }}
                    >
                      Bus Details
                    </p>
                  )}
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    {i + 1 + "."}
                  </Grid>
                  <Grid item xs={6} textAlign="end">
                    {busInput.length !== 1 && (
                      <DeleteIcon
                        onClick={() => handleRemoveClickBus(i)}
                        className={classes.plus}
                      />
                    )}
                    {busInput.length - 1 === i && (
                      <AddCircleOutlineIcon
                        onClick={handleAddClickBus}
                        size="small"
                        className={classes.plus}
                      />
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <CustomTextInlineField
                    name="busName"
                    label="Bus Name"
                    value={x.busName}
                    onChange={(e) => {
                      handleBusChange(e, i);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextInlineField
                    name="busFrom"
                    label="From"
                    required
                    value={x.busFrom}
                    onChange={(e) => {
                      handleBusChange(e, i);
                    }}
                    error={busInputError[i]["busFrom"]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextInlineField
                    name="busTo"
                    label="To"
                    required
                    value={x.busTo}
                    onChange={(e) => {
                      handleBusChange(e, i);
                    }}
                    error={busInputError[i]["busTo"]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextInlineField
                    name="busSeatNo"
                    label="Seat No"
                    value={x.busSeatNo}
                    onChange={(e) => {
                      handleBusChange(e, i);
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <CustomTextInlineField
                    name="busAdults"
                    label="Adult"
                    required
                    value={x.busAdults}
                    onChange={(e) => {
                      handleBusChange(e, i);
                    }}
                    error={busInputError[i]["busAdults"]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextInlineField
                    name="busChild"
                    label="Children"
                    value={x.busChild}
                    onChange={(e) => {
                      handleBusChange(e, i);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CustomTextInlineField
                    name="busAmount"
                    label="Amount"
                    required
                    style={{ backgroundColor: "#fff" }}
                    value={x.busAmount}
                    onChange={(e) => {
                      handleBusChange(e, i);
                    }}
                    error={busInputError[i]["busAmount"]}
                  />
                </Grid>
                <Grid item xs={6}>
                  {/* <CustomTextInlineField name="busCommission" label='Commission' style={{ backgroundColor: '#fff' }} value={x.busCommission} onChange={(e) => { handleBusChange(e, i) }} /> */}
                  <CustomTextInlineField
                    name="busCommission"
                    fullWidth
                    label="Commission"
                    required
                    error={busInputError[i]["busCommission"]}
                    style={{
                      border: "1px solid #deffde !important",
                      color: "#deffde",
                    }}
                    value={x.busCommission != "" ? x.busCommission : " "}
                    defaultValue=" "
                    variant="outlined"
                    size="small"
                    onChange={(e) => {
                      handleBusChange(e, i);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-input": { padding: "9px 14px" },
                      "& .MuiInputBase-input": { height: 1 },
                      "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                        border: "1.8px solid #f46d25",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container columnSpacing={1.5} rowSpacing={1} key={i}>
                <Grid item sm={6}>
                  {i == 0 && (
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "0",
                        color: "#f46d25",
                        fontWeight: "500",
                      }}
                    >
                      Bus Details
                    </p>
                  )}
                </Grid>
                <Grid item sm={6} style={{ textAlign: "end" }}>
                  {busInput.length !== 1 && (
                    <DeleteIcon
                      onClick={() => handleRemoveClickBus(i)}
                      className={classes.plus}
                    />
                  )}
                  {busInput.length - 1 === i && (
                    <AddCircleOutlineIcon
                      onClick={handleAddClickBus}
                      size="small"
                      className={classes.plus}
                    />
                  )}
                </Grid>
                <div
                  style={{
                    width: "2%",
                    textAlign: "center",
                    marginTop: "1em",
                    fontSize: "14px",
                  }}
                >
                  {i + 1 + "."}
                </div>
                <div style={{ width: "98%", marginTop: "1em" }}>
                  <Stack direction="row" width="100%" spacing={2}>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="busName"
                        label="Bus Name"
                        value={x.busName}
                        onChange={(e) => {
                          handleBusChange(e, i);
                        }}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="busFrom"
                        label="From"
                        required
                        value={x.busFrom}
                        onChange={(e) => {
                          handleBusChange(e, i);
                        }}
                        error={busInputError[i]["busFrom"]}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="busTo"
                        label="To"
                        required
                        value={x.busTo}
                        onChange={(e) => {
                          handleBusChange(e, i);
                        }}
                        error={busInputError[i]["busTo"]}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="busSeatNo"
                        label="Seat No"
                        value={x.busSeatNo}
                        onChange={(e) => {
                          handleBusChange(e, i);
                        }}
                      />
                    </div>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    width="100%"
                    marginTop="10px"
                  >
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="busAdults"
                        label="Adult"
                        required
                        value={x.busAdults}
                        onChange={(e) => {
                          handleBusChange(e, i);
                        }}
                        error={busInputError[i]["busAdults"]}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="busChild"
                        label="Children"
                        value={x.busChild}
                        onChange={(e) => {
                          handleBusChange(e, i);
                        }}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="busAmount"
                        label="Amount"
                        required
                        style={{ backgroundColor: "#fff" }}
                        value={x.busAmount}
                        onChange={(e) => {
                          handleBusChange(e, i);
                        }}
                        error={busInputError[i]["busAmount"]}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      {/* <CustomTextInlineField name="busCommission" label='Commission' style={{ backgroundColor: '#fff' }} value={x.busCommission} onChange={(e) => { handleBusChange(e, i) }} /> */}
                      <CustomTextInlineField
                        name="busCommission"
                        fullWidth
                        label="Commission"
                        required
                        error={busInputError[i]["busCommission"]}
                        style={{
                          border: "1px solid #deffde !important",
                          color: "#deffde",
                        }}
                        value={x.busCommission != "" ? x.busCommission : " "}
                        defaultValue=" "
                        variant="outlined"
                        size="small"
                        onChange={(e) => {
                          handleBusChange(e, i);
                        }}
                        sx={{
                          "& .MuiOutlinedInput-input": { padding: "9px 14px" },
                          "& .MuiInputBase-input": { height: 1 },
                          "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
                            border: "1.8px solid #f46d25",
                          },
                        }}
                      />
                    </div>
                  </Stack>
                </div>
              </Grid>
            )}
          </>
        ))}
      </Stack>
    </div>
  );
}

function CustomTextInlineField(props) {
  return (
    <Stack>
      <TextField
        name={props.name}
        fullWidth
        label={props.label}
        value={props.value != "" ? props.value : " "}
        defaultValue=" "
        variant="outlined"
        required={props.required}
        size="small"
        style={{
          backgroundColor: "#fff",
          color: "#f46d25",
          borderColor: "#f46d25",
        }}
        onChange={props.onChange}
        sx={{
          "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
            borderColor: "#f46d25",
          },
        }}
      />
      {props.error.length > 0 && (
        <span style={{ color: "#ff0000" }}>{props.error}</span>
      )}
    </Stack>
  );
}

CustomTextInlineField.defaultProps = {
  error: "",
};
