import { createContext, useState } from "react"

export const CaptchContext = createContext({
  userInput: "",
  setUserInput: () => null,
  captchaText: "",
  setCaptchaText: () => null,
  valid: false,
  setValid: () => null,
})

export const CaptchaProvider = ({ children }) => {
  const [captchaText, setCaptchaText] = useState("")
  const [userInput, setUserInput] = useState("")
  const [valid, setValid] = useState(false)

  return (
    <CaptchContext.Provider
      value={{
        userInput,
        setUserInput,
        captchaText,
        setCaptchaText,
        valid,
        setValid,
      }}
    >
      {children}
    </CaptchContext.Provider>
  )
}
