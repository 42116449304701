import { Grid, IconButton, Paper } from "@material-ui/core"
import DownloadIcon from "@material-ui/icons/GetApp"
import VisibilityIcon from "@material-ui/icons/Visibility"
import EditIcon from "@material-ui/icons/Create"
import { format, parseISO } from "date-fns"
import { useHistory } from "react-router-dom"

const InvoiceCard = ({ record }) => {
  const date = parseISO(record.createdAt)
  const formattedDate = format(date, "d MMM,yyyy")
  const history = useHistory()

  return (
    <div style={{ marginBottom: "5%" }}>
      <div
        style={{
          display: "flex",
          background: "#f46d25",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "10px 10px 0px 0px",
          height: "35px",
          padding: "3px",
        }}
      >
        <h4 style={{ color: "white" }}>{record.invoiceNo}</h4>
        <div
          style={{
            width: "30%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            style={{
              color: "#343A40",
              border: "50%",
              background: "white",
              height: "20px",
              width: "20px",
            }}
          >
            <DownloadIcon style={{ height: "18px" }} />
          </IconButton>
          <IconButton
            style={{
              color: "#343A40",
              border: "50%",
              background: "white",
              height: "20px",
              width: "20px",
            }}
            onClick={() =>
              history.push(`/konnect/viewGstInvoice/${record.invoiceNo}`)
            }
          >
            <VisibilityIcon style={{ height: "18px" }} />
          </IconButton>
          <IconButton
            style={{
              color: "#343A40",
              border: "50%",
              background: "white",
              height: "20px",
              width: "20px",
            }}
            onClick={() =>
              history.push(`/konnect/generateInvoice/${record.invoiceNo}`)
            }
          >
            <EditIcon style={{ height: "18px" }} />
          </IconButton>
        </div>
      </div>
      <Paper
        style={{
          display: "flex",
          borderRadius: "0px 0px 10px 10px",
          padding: "1%",
        }}
      >
        <Grid container xs={6}>
          <Grid item style={{ fontSize: "12px", width: "100%" }}>
            <span style={{ fontWeight: "bold" }}>Client :</span>{" "}
            {record.clientName}
          </Grid>
          <Grid item style={{ fontSize: "12px", width: "100%" }}>
            <span style={{ fontWeight: "bold" }}>Hotel :</span>{" "}
            {record.hotelName}
          </Grid>
          <Grid item style={{ fontSize: "12px", width: "100%" }}>
            <span style={{ fontWeight: "bold" }}>Created Date :</span>{" "}
            {formattedDate}
          </Grid>
        </Grid>
        <Grid container xs={6}>
          <Grid item style={{ fontSize: "12px", width: "100%" }}>
            <span style={{ fontWeight: "bold" }}>Taxable Amount :</span> Rs.
            {record.totalTaxableAmount}
          </Grid>
          <Grid item style={{ fontSize: "12px", width: "100%" }}>
            <span style={{ fontWeight: "bold" }}>Total Tax :</span> Rs.
            {record.totalTax}
          </Grid>
          <Grid item style={{ fontSize: "12px", width: "100%" }}>
            <span style={{ fontWeight: "bold" }}>Amount Inc. Tax :</span> Rs.
            {record.totalAmountWithGst}
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default InvoiceCard
