import { useEffect, useState } from "react"
import API from "../../Service/Api"
import { sortByItem } from "../../Service/CommonFunctionFile"

const useLeadAssignForList = (unique_id) => {
  const [leadAssignList, setLeadAssignList] = useState([])

  const fetchList = async () => {
    try {
      const response = await API.get(`leadassignfor/${unique_id}`)
      if (response.status == 200) {
        const sortedData = response?.data?.sort(sortByItem("username"))
        console.log(response.data)
        setLeadAssignList(sortedData)
        return
      }
      return Promise.reject(response)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchList()
  }, [])

  return leadAssignList
}

export default useLeadAssignForList
