import {
  Button,
  InputAdornment,
  TextField,
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/SearchOutlined";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../components/Loader/Loader";
import InvoiceCard from "./InvoiceCardComponent";

const customTheme = createTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        border: "2px solid #343A40",
        height: "40px",
      },
    },
    MuiButton: {
      root: {
        background: "#343A40 !important",
        color: "white",
        fontSize: "12px",
      },
      "& $hover": {
        background: "#343A40 !important",
      },
    },
  },
  props: {
    MuiOutlinedInput: {
      InputProps: {
        style: { height: "30px" },
      },
    },
  },
});

const useStyles = makeStyles({
  root: {
    margin: "15% 3%",
    paddingBottom: "20%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  createButton: {
    background: "#343A40",
    fontSize: "18px",
    height: "50%",
  },
  searchField: {
    width: "100%",
  },
});

const InvoiceListMobileUI = ({ data, textToSearch, setTextToSearch }) => {
  const classes = useStyles();

  const totalDataLength = data.length;
  const dataPerPage = 5;
  let noOfPages = Math.ceil(totalDataLength / dataPerPage);
  console.log("totalDataLength", totalDataLength, noOfPages);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  let tempDataIndex = currentPageNo * dataPerPage; //variable for min and max data range for page
  let lastDataIndex = Math.min(tempDataIndex, totalDataLength);
  let firstDataIndex = 0;
  console.log("first and last indices of data:", firstDataIndex, lastDataIndex);

  const changePageNo = (value) => {
    let tempPageNo = currentPageNo + value; //value can be 1 or -1 depending upon arrow clicked
    if (tempPageNo >= 1 && tempPageNo <= noOfPages) {
      setCurrentPageNo(tempPageNo);
    }
  };

  const loaderRef = useRef(null);
  const [showButton, setShowButton] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    const scrollPosition = window.pageYOffset;

    // if (windowHeight + scrollPosition >= documentHeight) {
    //   setPage((prevPage) => prevPage + 1);
    // }

    console.log(
      "scroll height",
      Math.ceil(window.innerHeight + document.documentElement.scrollTop)
    );

    console.log("offsetHeight", document.documentElement.offsetHeight);

    scrollPosition > 300 ? setShowButton(true) : setShowButton(false);

    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) >=
      document.documentElement.offsetHeight
    ) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        changePageNo(1);
      }, 2000);
      console.log("ok");
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [firstDataIndex, lastDataIndex, currentPageNo, handleScroll]);

  const history = useHistory();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h3 style={{ fontSize: "20px" }}>Invoice</h3>
        <ThemeProvider theme={customTheme}>
          <Button onClick={() => history.replace("/generateInvoice")}>
            Create Invoice
          </Button>
        </ThemeProvider>
      </div>
      <ThemeProvider theme={customTheme}>
        <TextField
          className={classes.searchField}
          type="search"
          variant="outlined"
          value={textToSearch}
          style={{ marginBottom: "10%" }}
          placeholder="Search by Name/Hotel"
          onChange={(e) => setTextToSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment
                style={{
                  padding: "0",
                  margin: "0",
                  color: "#f46d25",
                  marginLeft: "-2%",
                }}
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </ThemeProvider>
      {data &&
        data
          .slice(firstDataIndex, lastDataIndex)
          .map((record) => <InvoiceCard record={record} />)}
      <div ref={loaderRef} />
      {loading && lastDataIndex < data.length && (
        <div
          style={{
            top: 0,
            width: "100%",
            height: "5%",
            padding: "0",
            margin: "0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      )}
    </div>
  );
};

export default InvoiceListMobileUI;
