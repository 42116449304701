import React, { useEffect, useState } from "react"
import DesktopHome from "./DesktopHome"
import MobileHome from "./MobileHome"
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min"

export function Homepage() {
  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => window.removeEventListener("resize", handleWindowSizeChange)
  }, [width])

  const clearBrowserStorage = () => {
    localStorage.clear()
    sessionStorage.clear()
  }

  useEffect(() => {
    window.addEventListener("load", clearBrowserStorage)
    return () => window.removeEventListener("load", clearBrowserStorage)
  }, [])

  return (
    <>
      {width <= 768 ? (
        <MobileHome width={width} />
      ) : (
        <DesktopHome width={width} />
      )}
    </>
  )
}
