// import { Grid, InputAdornment, Stack, Table, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Dialog, Slide, Stack, Table, TableBody, TableRow, TableHead, Tooltip, IconButton, Alert, Typography, TextField, Grid, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { twnButtonStyles } from '../../utils/townoStyle';
// import { DualTextField } from "../CreatePackage/AgentCreatePackage";

const PackageOrQuotation = (isPackage) => { return isPackage ? "Package" : "Quotation" }

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#f46d25',
        color: "#fff",
        fontSize: 14,
        // fontWeight: 'bold'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        backgroundColor: '#fff',
        boxShadow: 'none',
        borderRadius: '5px',
        fontWeight: 'bold'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    border: "1px solid #eee",
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="top" ref={ref} {...props} />;
});


export default function TransferRefund(props) {
    const { openRefund, closeFn, handleRefund, totalGrossAmount, totalAmountPaid, comission, amountRefunded, setAmountRefunded, amountRetained, setAmountRetained, revisedCommission, setRevisedCommission } = props
    const [amtRefundedError, setAmountRefundedError] = useState("");
    const [revisedCommissionError, setRevisedCommissionError] = useState("");

    let { pkgOrQtn } = useParams();
    let isPackage = pkgOrQtn === "package" ? true : false;

    const onChangeAmtRefunded = (event) => {
        if ((event.target.value / 1) > totalAmountPaid) {
            setAmountRefundedError("Can't be more that Total Paid Amount");
        } else { setAmountRefundedError("") }

        setAmountRefunded(event.target.value);
        setAmountRetained(totalAmountPaid - (event.target.value / 1));
    }

    const onChangeRevComm = (event) => {
        if ((event.target.value / 1) > amountRetained) {
            setRevisedCommissionError("Can't be more that Amount Retained");
        } else { setRevisedCommissionError("") }

        setRevisedCommission(event.target.value);
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            sx={{ maxHeight: '75vh', marginTop: "8%" }}
            open={openRefund}
            onClose={closeFn}
            TransitionComponent={Transition}
        >
            <div>
                <Typography variant="subtitle2" component="h6" style={{ ...twnButtonStyles.xlFonts, padding: "10px 0px 0px 10px" }}>Cancel {PackageOrQuotation(isPackage)}</Typography>
                <br />
                <Table size="small" style={{ "width": "94%", marginLeft: "2.5%" }}>
                    <TableHead style={{ height: '25px', backgroundColor: '#F46D25', color: '#fff', fonstSize: '12px' }}>
                        <TableRow>
                            <StyledTableCell style={{ borderTopLeftRadius: '5px' }}>Total Gross Amt.</StyledTableCell>
                            <StyledTableCell>Total Paid Amt.</StyledTableCell>
                            <StyledTableCell style={{ borderTopRightRadius: '5px' }}>Comission</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">{totalGrossAmount}</StyledTableCell>
                            <StyledTableCell component="th" scope="row">{totalAmountPaid}</StyledTableCell>
                            <StyledTableCell component="th" scope="row">{comission}</StyledTableCell>
                        </StyledTableRow>
                    </TableBody>

                </Table>

                <Stack style={{ padding: "15px 0px 10px 15px" }} spacing={2} sx={{ '& .MuiInputBase-input': { height: 1 } }}>
                    <DualTextField label="Amount Refunded *" name={"amountRefunded"} value={amountRefunded} changeFn={onChangeAmtRefunded} />
                    {amtRefundedError && <Typography variant="subtitle2" style={{ margin: "0 3%", color: "#ff0000", textAlign: 'right' }}>{amtRefundedError}</Typography>}
                    <DualTextField label="Amount Retained *" name={"amountRetained"} value={amountRetained} changeFn={() => { }} disabled={true} />
                    {/* <DualTextField label="Revised Commission *" name={"revisedCommission"} value={revisedCommission} changeFn={(event) => { setRevisedCommission(event.target.value) }} /> */}
                    <DualTextField label="Revised Commission *" name={"revisedCommission"} value={revisedCommission} changeFn={onChangeRevComm} />
                    {revisedCommissionError && <Typography variant="subtitle2" style={{ margin: "0 3%", color: "#ff0000", textAlign: 'right' }}>{revisedCommissionError}</Typography>}
                </Stack>
            </div>
            <div style={{ backgroundColor: '#fff' }}>
                <Stack direction='row' spacing={2} justifyContent='center' style={{ margin: '1%' }}>
                    {(amountRefunded > 0 && amountRetained > 0 && revisedCommission > 0) ?
                        <button style={twnButtonStyles.orangeBtn} onClick={handleRefund}>Refund</button>
                        :
                        <button style={twnButtonStyles.disabledBtn}>Refund</button>
                    }
                    <button style={twnButtonStyles.blackBtn} autoFocus color="inherit" onClick={closeFn}>
                        Back
                    </button>
                </Stack>
            </div>
            <br />
        </Dialog>
    )
} export function DualTextField(props) {
    return (
        <Grid container>
            <Grid item xs={6}>
                <DualTextFieldLabel label={props.label} fullWidth={props.fullWidth} />
            </Grid>
            <Grid item xs={6}>
                <DualTextFieldValue name={props.name} value={props.value} changeFn={props.changeFn} disabled={props.disabled} />
            </Grid>
        </Grid>
    )
}

DualTextField.defaultProps = { options: null, fullWidth: true, disabled: false }

function DualTextFieldLabel(props) {
    return (
        <TextField
            variant="outlined"
            size="small"
            fullWidth={props.fullWidth}
            style={{
                color: "#138128",
                borderColor: "#deffde",
            }}
            InputLabelProps={{
                style: {
                    color: "#f46d25",
                    marginTop: "-6px",
                    fontSize: "15px"
                },
            }}
            label={props.label}
            disabled
            sx={{ '& .MuiInputBase-input': { height: 0, padding: "7%" }, }}
        />
    )
}

function DualTextFieldValue(props) {
    return (
        <TextField
            name={props.name}
            data-testid={props.name}
            variant="outlined"
            fullWidth
            disabled={props.disabled}
            style={{
                backgroundColor: '#ffe2d7',
                marginLeft: "-20px",
            }}
            // InputProps={props.InputProps}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        ₹
                    </InputAdornment>
                ),
            }}
            size="small"
            value={props.value}
            onChange={props.changeFn}
            autoComplete="off"
            sx={{ '& .MuiInputBase-input': { height: 0, padding: "7.5%" } }}
        />
    )
}
