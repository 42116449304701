import React, { useEffect, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material"
import headerImage from "../../assets/Towno-IC-2.png"
import exclusiveMembers from "../../assets/exclusive_members.png"
import birthdayBenefits from "../../assets/birthday_benefits.png"
import logo from "../../assets/Towno_W&O 1.svg"
import styles from "./LandingPage.module.scss"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import NewContactForm from "../../Components/ContactForm/NewContactForm"
import MobileBenifitsImg from "../../assets/InnercircleMobile.png"

const LandingPage = () => {
  const [expandedAccordion, setExpandedAccordion] = useState("panel1")

  const accordionData = [
    {
      id: "panel1",
      title: "What is the Towno Inner Circle Program?",
      content:
        "The Towno Inner Circle Program is an exclusive membership program to our valued customers by offering premium and exclusive discounts, birthday/anniversary surprises, and more.",
    },
    {
      id: "panel2",
      title: "What are the benefits of joining the Towno Inner Circle?",
      content:
        "Exclusive member-only discounts, delightful surprises on your birthday/anniversary, exclusive discounts on hotel stays and Towno packages, additional coupons for friends and family, upgradation on stays and packages, and membership card.",
    },
    {
      id: "panel3",
      title: "How can I join the Towno Inner Circle?",
      content:
        "You can join the Towno Inner Circle by visiting our website or contact our exclusive support team to become a member and start enjoying the exclusive benefits.",
    },
    {
      id: "panel4",
      title:
        "Can I share the Towno Inner Circle benefits with friends and family?",
      content:
        "Absolutely! As an Inner Circle member, you can share the perks with friends and family by providing them with additional coupons without any additional joining fees.",
    },
    {
      id: "panel5",
      title:
        "How do I receive my unique membership number and card after joining the Towno Inner Circle?",
      content:
        "Upon joining, you will receive your unique membership number and card through the email address provided during the sign-up process. A physical card will be sent to the address provided.",
    },
    {
      id: "panel6",
      title: "Is there a refund policy for the Towno Inner Circle Program?",
      content:
        "No, the Towno Inner Circle Program is a complimentary membership with no associated fees. As such, there is no payment involved, and therefore, no refund policy applies.",
    },
    {
      id: "panel7",
      title:
        "Can I upgrade my Inner Circle membership to a higher tier for additional benefits?",
      content:
        "Currently, the Towno Inner Circle offers a unified membership with a standard set of benefits. While there are no higher tiers, all members enjoy the same exclusive perks, ensuring fairness and equality within the program. Rahul sir wants Gold, Silver and Bronze kinda tiers. We can include this later.",
    },
    {
      id: "panel8",
      title:
        "Is the Inner Circle membership limited to a certain duration, or is it a lifetime membership?",
      content:
        "The Towno Inner Circle membership is a lifetime membership. The discount coupons are valid for a year.",
    },
    {
      id: "panel9",
      title: "Can I transfer my Inner Circle membership to someone else?",
      content:
        "No, the Towno Inner Circle membership is non-transferable. It is linked to the individual who initially signs up, ensuring that the exclusive benefits are personalized for each member's experience.",
    },
  ]

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null)
  }

  return (
    <div className={styles.innerCirclelandingPage}>
      {/* --------------------------------Header Section--------------------- */}

      <div className={styles.headerSectionMainBox}>
        <div className={styles.leftBlur}></div>
        <div className={styles.headerSection}>
          <div className={styles.leftSide}>
            <img
              src={headerImage}
              className={styles.headerlogo}
              alt="headerimg"
            />
          </div>
          <div className={styles.rightSide}>
            <img src={logo} alt="logo" />
            <p className={styles.headingName}>Inner Circle</p>
            <div className={styles.contentBox}>
              <p>
                We believe in expressing our gratitude to our valued customers,
                and what better way to do that than through our exclusive Towno
                Inner Circle Program
              </p>
              <div className={styles.box1}>
                <div>
                  <h3>We value the trust you place in us</h3>
                  <p>
                    The Towno Inner Circle Program is our way of giving back and
                    ensuring that every interaction with us is not just a
                    transaction but a experience
                  </p>
                </div>
                <div className={styles.verticalLine}></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------------Exciting Rewards----------------------------------------- */}

      <div className={styles.excitingRewardsmainBox}>
        <div className={styles.rightBlur}></div>
        <div className={styles.excitingRewards}>
          <div className={styles.leftline}></div>
          <div className={styles.headingName}>
            <h3>Your gateway to a world of exciting rewards</h3>
          </div>
          <div className={styles.rightline}></div>
        </div>

        <div className={styles.excitingRewardsContent}>
          <div className={styles.leftSideContent}>
            <div className={styles.mainBox}>
              <div className={styles.box1}>
                <img src={exclusiveMembers} alt="exclusivemembers" />
              </div>
              <div className={styles.box2}>
                <h3>Exclusive Member-Only Discounts</h3>
                <p>
                  As a Inner Circle member, you can access special discounts and
                  promotions reserved just for you. Enjoy exclusive pricing and
                  unbeatable deals on your favorite products.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.rightSideContent}>
            <div className={styles.mainBox}>
              <div className={styles.box1}>
                <img src={birthdayBenefits} alt="birthdayBenefits" />
              </div>
              <div className={styles.box2}>
                <h3>Birthday/Anniversary Surprises</h3>
                <p>
                  We love celebrating your special day with you ! Expect a
                  delightful surprise on your birthday as our token of
                  appreciation for being a part of the Towno family.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ----------------------------------------InnerCircle Benefits--------------------------------- */}
      <div className={styles.joinRewardsAndBenefitsbox}>
        <div className={styles.joinBenefits}>
          <div className={styles.leftline}></div>
          <div className={styles.headingName}>
            <h3>Join Our Towno Inner Circle & let the rewards begin!</h3>
          </div>
          <div className={styles.rightline}></div>
        </div>
        <div className={styles.benefitsContentBox}>
          <div className={styles.benefitsContainer}>
            <div>
              <h3>Inner Circle</h3>
              <p>Benefits</p>
            </div>
          </div>

          <div className={styles.mobileBenifitsContainer}>
            <img src={MobileBenifitsImg} alt="MobilebenifitImg" />
          </div>

          <div className={styles.listItems}>
            <div className={styles.verticalLine}></div>
            <ul>
              <li>
                <span>No Joining Fees.</span>
              </li>
              <li>
                <span>
                  Exclusive Discounts on Hotel Stays and Towno Exclusive
                  Package.
                </span>
              </li>
              <li>
                <span>
                  Additional Coupons for Friends and Family with zero joining
                  fee.
                </span>
              </li>
              <li>
                <span>Upgradation on Stays and Packages.</span>
              </li>
              <li>
                <span>Unique Membership Number and Card.</span>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.rewardsleftBlur}></div>
      </div>

      {/* ---------------------------------Contact Box---------------------------------- */}

      <div className={styles.contactMainBox}>
        <div className={styles.box1}>
          <NewContactForm />
        </div>
      </div>

      {/* -----------------------------------Frequently asked------------------------------- */}

      <div className={styles.frequentlyAskedmainBox}>
        <div className={styles.contentMainBox}>
          <div className={styles.headingSection}>
            <h3>Frequently Asked Questions</h3>
            <p>
              These questions might not be on everyone's FAQ list, but we've got
              your back in case you were curious.
            </p>
          </div>

          <div className={styles.accordionMainBox}>
            {accordionData.map((accordion, index) => (
              <Accordion
                key={accordion.id}
                className={styles.accordion}
                expanded={expandedAccordion === accordion.id}
                onChange={handleChange(accordion.id)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}
                  aria-controls={`${accordion.id}-content`}
                  className={
                    expandedAccordion === accordion.id
                      ? styles.accordionSummaryExpanded
                      : styles.accordionSummary
                  }
                >
                  <h4 className={styles.accordionTitle}>
                    <span style={{ marginRight: "10px" }}>{index + 1} -</span>
                    {accordion.title}
                  </h4>
                </AccordionSummary>
                <AccordionDetails className={styles.accordionDetails}>
                  <Typography className={styles.accordionContent}>
                    {accordion.content}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
        <div className={styles.frequentlyCenterBlur}></div>
      </div>
    </div>
  )
}

export default LandingPage
