import { useContext } from "react"
import styles from "./ItemDirectory.module.css"
import { ContactModalContext } from "../../context/contactModal.context"
const ItemDirectory = ({ primaryHeader, secondaryHeader, data, Component }) => {
  const { toggleModal } = useContext(ContactModalContext)
  return (
    <section className={styles.section}>
      <header className={styles.header}>
        <div>
          {primaryHeader}{" "}
          <span className={styles.secondaryHeader}>{secondaryHeader}</span>
        </div>
        <div className={styles.link} onClick={toggleModal}>
          View All
        </div>
      </header>
      <main className={styles.main}>
        {data?.map((data, i) => (
          <Component {...data} key={`${data.location}-${i}`} />
        ))}
      </main>
    </section>
  )
}

export default ItemDirectory
