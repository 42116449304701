import parser from "html-react-parser"
import { Fragment, useContext, useLayoutEffect } from "react"
import BiggerGroupBannerPost from "../../../../components/BiggerGroupBanner/BiggerGroupBanner.component"
import InlineImageAndContentCard from "../../../../components/InlineImageAndContentCard/InlineImageAndContent.component"
import globalStyles from "../../../../globalStyles.module.css"
import { DestinationLayoutContext } from "../../../../layout/DestinationLayout/Destination.layout"
import styles from "./DestinationCuisine.module.css"

import BhangImg from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Cusine/Bhang ki chutney.jpg"
import MandwaImg from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Cusine/Cusine.jpg"
import DubukImg from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Cusine/Dubuk and Chainsoo.jpg"
import JhangoreImg from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Cusine/Jhangore Ki Kheer.jpg"
import SisunaakImg from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Cusine/Sisunaak Sag.jpg"
import DestinationSubLayout from "../../../../layout/DestinationSubLayout/DestinationSub.layout"

const cuisine = [
  {
    src: MandwaImg,
    title: "Mandwa Ki Roti",
    content: `A healthy, wholesome delicacy that is made from Ragi flour as well as vegetables, Mandwa ki roti is a mouth-watering accompaniment served with curry dishes and chutney.  This is a Uttarakhand vegetarian dish that is a staple of Uttarakhand and one of the must-try dishes while you are there.`,
  },
  {
    src: DubukImg,
    title: "Dubuk and Chainsoo",
    content: `Dubuk is a Kumaoni preparation made from local pulses. A tasty black bean dal, this is a staple food from the Garhwal region and one of the traditional foods of Uttarakhand. A high protein dish prepared from black gram dal, Chainsoo is a famous Garhwali dish and one of the most popular Uttarakhand vegetarian dishes, prepared in iron pots and cooked over fire slowly which gives it an extremely earthy taste.`,
  },
  {
    src: SisunaakImg,
    title: "Sisunaak Sag",
    content: `This local delicacy and one of the best Uttarakhand dishes is made from a plant that is locally known as Bichhoo Ghas, a green leafy vegetable that is extremely nutritious and has its unique flavour.`,
  },
  {
    src: JhangoreImg,
    title: "Jhangore Ki Kheer",
    content: `A sweet dish prepared from a millet called Jhangore and mixed with dry fruits, this traditional Uttarakhand dish is a local delicacy and extremely popular among tourists and locals.`,
  },
  {
    src: BhangImg,
    title: "Bhang ki chutney",
    content: `A lip-smacking tangy preparation of Bhang or Hemp seeds, this chutney is the highlight of local Uttarakhand food. It is extremely aromatic and enhances the taste of every other accompanying element on the plate. `,
  },
]

const DestinationCuisine = () => {
  const { setBanner } = useContext(DestinationLayoutContext)

  useLayoutEffect(() => {
    setBanner("cuisine")
  }, [])

  return (
    <DestinationSubLayout>
      <main className={globalStyles.main}>
        <article className={globalStyles.topMostSection}>
          <header>
            <span>The Best Cusine Of Uttarakhand</span>
          </header>
          <div className={globalStyles.content}>
            Uttarakhand is a culinary heaven for all those who love food and
            flavours. The cuisine of Uttarakhand is simple, flavoursome and
            delicious. Different regions of Uttarakhand namely, Kumaon and
            Garhwal have their distinct features, cultures as well as imprints
            in food and flavours. One commonality they both have is that the
            best Uttarakhand food is cooked slowly over fire and has an
            abundance of fresh vegetables and lentils along with local spices.
            Uttarakhand dishes are extremely simplistic and high on nutrition,
            containing local pulses like Gehet and Bhatt and cereals such as
            Mandua, The food of Uttarakhand also uses Ghee liberally, possibly
            to combat the extreme winters.
            <p>
              Some of the best food in Uttarakhand is vegetarian, made from
              seasonal fruits and vegetables, and cooked slowly to perfection.
              Some of Uttarakhand’s famous foods that you must try while you are
              in the state are:
            </p>
          </div>
        </article>
        <div className={styles.cuisineList}>
          {cuisine.map((_, i) => (
            <Fragment key={i}>
              <InlineImageAndContentCard>
                <InlineImageAndContentCard.Image src={_.src} size="medium" />
                <InlineImageAndContentCard.Content title={_.title}>
                  {parser(_.content)}
                </InlineImageAndContentCard.Content>
              </InlineImageAndContentCard>
              <hr className={styles.hr} />
            </Fragment>
          ))}
          <div
            style={{ color: "rgba(181, 181, 181, 1)", lineHeight: "1.6rem" }}
          >
            <p>
              In addition to the local cuisine of Uttarakhand, every city and
              town is popular for its own set of good restaurants in Uttarakhand
              that provide both local as well as international cuisine. Street
              food such as Momos, Maggi, and Bun Tikki as well as an assortment
              of Chats are popular across the state and can be found in most
              Uttarakhand restaurants.
            </p>
            Multi-cuisine restaurants in Uttarakhand can be found in all the
            major tourist destinations from the Mall Road in Mussoorie and
            Nainital to Rajpur Road in Dehradun. Some of the best food places in
            Uttarakhand are often local and smaller food establishments which
            use fresh produce and local flavours. We urge you to always try to
            go for local food which is often served in the best restaurants in
            Uttarakhand and avoid visiting popular fast food chains. Not only
            would you be treated to fresh and authentic food, you would be
            helping to develop the local economy as well.
          </div>
        </div>
        <BiggerGroupBannerPost />
      </main>
    </DestinationSubLayout>
  )
}

export default DestinationCuisine
