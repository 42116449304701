import { createContext, useState } from "react"

export const SideNavbarContext = createContext({
  openSideNavbar: false,
  destinationTitle: "",
  toggleNavBar: () => null,
  setOpenSideNavbar: () => null,
  setDestinationTitle: () => null,
})

export const SideNavbarContextProvider = ({ children }) => {
  const [openSideNavbar, setOpenSideNavbar] = useState(false)
  const [destinationTitle, setDestinationTitle] = useState("Destinations")
  const toggleNavBar = () => {
    setOpenSideNavbar((o) => !o)
  }

  return (
    <SideNavbarContext.Provider
      value={{
        openSideNavbar,
        toggleNavBar,
        setOpenSideNavbar,
        destinationTitle,
        setDestinationTitle,
      }}
    >
      {children}
    </SideNavbarContext.Provider>
  )
}
