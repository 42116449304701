import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  eachDayOfInterval,
  endOfMonth,
  format,
  startOfMonth,
  subMonths,
} from "date-fns";
import { formatter } from "../../utils/formatNumber";
import { Grid, Stack } from "@mui/material";
import MultiLineChart from "../Dashboard/Chart";
import Api from "../../Service/Api";
import MultiLineAgentChart from "../Dashboard/AgentChart";
import MultiLineChartHotelLogin from "./multiLineChart";
import UpcomingPayment from "../Dashboard/UpcomingPayment";
import UpcomingCheckin from "../Dashboard/UpcomingCheckin";
import { twnButtonStyles } from "../../utils/townoStyle";

const useStyles = makeStyles(() => ({
  root: { padding: " 5% 1.5% 5% 1.5%", margin: "0", fontSize: "1rem" },
  sectionHeader: {
    background: "#f46d25",
    color: "white",
    fontWeight: "bold",
    padding: "10px",
    borderRadius: "5px 5px 0px 0px",
    marginTop: "30px",
  },
  formControl: {
    minWidth: "60px",
    height: "40px",
    background: "white",
    borderRadius: "5px",
  },
  tabs: {
    "& button": {
      maxHeight: "10px",
    },
  },
  tabsRoot: {
    minHeight: "40px",
    height: "40px",
    padding: "0 !important",
  },
  tabRoot: {
    minHeight: "40px",
    height: "40px",
    padding: "0 !important",
    width: "fit-content",
  },
  buttonRoot: {
    "& .MuiButton-label": {
      color: "#f46d25",
    },
  },
}));

const currentDate = new Date();

{
  /**current month dates array - starts */
}
const startOfMonthDate = startOfMonth(currentDate);
const endOfMonthDate = endOfMonth(currentDate);
const allDatesOfCurrentMonth = eachDayOfInterval({
  start: startOfMonthDate,
  end: endOfMonthDate,
});

const formattedFullMonthDates = allDatesOfCurrentMonth.map((currDate) => ({
  x: format(currDate, "yyyy-MM-dd"),
  y: "0",
}));

const townoFormattedFullMonthDates = allDatesOfCurrentMonth.map((currDate) => ({
  x: format(currDate, "yyyy-MM-dd"),
  y: "0",
}));

// console.log("Date-fns dates", formattedFullMonthDates);
{
  /**current month dates array - ends */
}

/**previous month dates array - starts */
const previousMonth = subMonths(currentDate, 1);
const firstDayOfPreviousMonth = startOfMonth(previousMonth);
const lastDayOfPreviousMonth = endOfMonth(previousMonth);
const allDatesOfPreviousMonth = eachDayOfInterval({
  start: firstDayOfPreviousMonth,
  end: lastDayOfPreviousMonth,
});

const formattedPreviousMonthDatesHotel = allDatesOfPreviousMonth.map(
  (currDate) => ({
    x: format(currDate, "yyyy-MM-dd"),
    y: "0",
  })
);

const formattedPreviousMonthDatesTowno = allDatesOfPreviousMonth.map(
  (currDate) => ({
    x: format(currDate, "yyyy-MM-dd"),
    y: "0",
  })
);
/**previous month dates array - ends*/

//factory function to return dates array

const HotelDashboard = () => {
  const classes = useStyles();

  const [hotelRevenueData, setHotelRevenueData] = useState([]);
  const [hotelRoomNightsData, setHotelRoomNightsData] = useState(() => []);
  const [revenueDataTimeline, setRevenueDataTimeLine] = useState(
    () => "present"
  );
  const [townoRevenueData, setTownoRevenueData] = useState([]);
  const [townoRoomNightsData, setTownoRoomNightsData] = useState(() => []);
  const [roomNightsDataTimeline, setRoomNightsDataTimeLine] = useState(
    () => "present"
  );

  let unique_id = localStorage.getItem("unique_id");
  const [loading, setLoading] = useState(() => false);

  const fetchRevenueData = async () => {
    setLoading(true);

    const hotelResponse = await Api.get(
      `hotelBookingRevenue/${unique_id}/${revenueDataTimeline}`
    );

    const townoResponse = await Api.get(
      `hotelBookingRevenueFromTowno/${unique_id}/${revenueDataTimeline}`
    );

    console.log("hotel response", hotelResponse);
    console.log("towno response", townoResponse);

    const hotelRevenue = hotelResponse.data.map((hotel) => ({
      x: hotel.date,
      y: hotel.totalrevenue,
    }));
    const townoRevenue = townoResponse.data.map((hotel) => ({
      x: hotel.date,
      y: hotel.totalrevenue,
    }));
    const hotelRevenueMap = new Map(
      hotelRevenue.map((item) => [item.x, item.y])
    );

    const hotelRevenueForEntireMonth = (
      revenueDataTimeline === "present"
        ? formattedFullMonthDates
        : formattedPreviousMonthDatesHotel
    ).map(({ x, y }) => ({
      x: format(new Date(x), "d"),
      y: hotelRevenueMap.has(x) ? hotelRevenueMap.get(x) : y,
    }));
    console.log("hotelRevenueForEntireMonth", hotelRevenueForEntireMonth);

    const townoRevenueMap = new Map(
      townoRevenue.map((item) => [item.x, item.y])
    );

    const townoRevenueForEntireMonth = (
      revenueDataTimeline === "present"
        ? townoFormattedFullMonthDates
        : formattedPreviousMonthDatesTowno
    ).map(({ x, y }) => ({
      x: format(new Date(x), "d"),
      y: townoRevenueMap.has(x) ? townoRevenueMap.get(x) : y,
    }));
    console.log("townoRevenueForEntireMonth", townoRevenueForEntireMonth);

    console.log(hotelResponse.data);
    setHotelRevenueData(hotelRevenueForEntireMonth);
    setTownoRevenueData(townoRevenueForEntireMonth);
    setLoading(false);
  };

  const fetchRoomNightsData = async () => {
    setLoading(true);
    const hotelResponse = await Api.get(
      `hotelBookingRevenue/${unique_id}/${roomNightsDataTimeline}`
    );

    const townoResponse = await Api.get(
      `hotelBookingRevenueFromTowno/${unique_id}/${roomNightsDataTimeline}`
    );

    const hotelRoomNightsData = hotelResponse.data.map((hotel) => ({
      x: hotel.date,
      y: hotel.noOfRoomNights,
    }));

    const townoRoomNightsData = townoResponse.data.map((hotel) => ({
      x: hotel.date,
      y: hotel.noOfRoomNights,
    }));

    const hotelRoomNightsMap = new Map(
      hotelRoomNightsData.map((item) => [item.x, item.y])
    );

    const hotelRoomNightsForEntireMonth = (
      roomNightsDataTimeline === "present"
        ? formattedFullMonthDates
        : formattedPreviousMonthDatesHotel
    ).map(({ x, y }) => ({
      x: format(new Date(x), "d"),
      y: hotelRoomNightsMap.has(x) ? hotelRoomNightsMap.get(x) : y,
    }));

    const townoRoomNightsMap = new Map(
      townoRoomNightsData.map((item) => [item.x, item.y])
    );

    const townoRoomNightsForEntireMonth = (
      roomNightsDataTimeline === "present"
        ? townoFormattedFullMonthDates
        : formattedPreviousMonthDatesTowno
    ).map(({ x, y }) => ({
      x: format(new Date(x), "d"),
      y: townoRoomNightsMap.has(x) ? townoRoomNightsMap.get(x) : y,
    }));

    console.log(townoResponse.data);
    setHotelRoomNightsData(hotelRoomNightsForEntireMonth);
    setTownoRoomNightsData(townoRoomNightsForEntireMonth);

    setLoading(false);
  };
  useEffect(() => {
    fetchRevenueData();
  }, [unique_id, revenueDataTimeline]);

  useEffect(() => {
    fetchRoomNightsData();
  }, [unique_id, roomNightsDataTimeline]);

  return (
    <div className={classes.root}>
      <>
        <Typography className={classes.sectionHeader}>Total Revenue</Typography>
        <Paper style={{ padding: "10px" }}>
          <Grid container>
            <Grid item xs={12} paddingLeft="78%">
              <Stack
                direction="row"
                justifyContent="right"
                alignContent="right"
                style={{ backgroundColor: "#fff", padding: "1em 1em 0 0" }}
              >
                <Paper
                  square
                  style={{
                    width: "fit-content",
                    borderRadius: "5px",
                    height: "40px",
                    padding: "0px",
                  }}
                >
                  <ButtonGroup
                    style={{
                      border: "1px solid #f46d25",
                      borderRadius: "5px",
                      height: "100%",
                      padding: "0px",
                    }}
                  >
                    <Button
                      style={{
                        background:
                          revenueDataTimeline === "previous" && "#f46d25",
                        fontSize: "10px",
                        padding: "5px",
                      }}
                      onClick={() => setRevenueDataTimeLine("previous")}
                    >
                      <span
                        style={{
                          color:
                            revenueDataTimeline !== "previous" && "#f46d25",
                        }}
                      >
                        Previous Month
                      </span>
                    </Button>
                    <Button
                      style={{
                        background:
                          revenueDataTimeline === "present" && "#f46d25",
                        color:
                          revenueDataTimeline === "present"
                            ? "white"
                            : "#f46d25 !important",
                        fontSize: "10px",
                        padding: "5px",
                      }}
                      onClick={() => setRevenueDataTimeLine("present")}
                    >
                      <span
                        style={{
                          color: revenueDataTimeline !== "present" && "#f46d25",
                        }}
                      >
                        Present Month
                      </span>
                    </Button>
                  </ButtonGroup>
                </Paper>
              </Stack>
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
              <MultiLineChartHotelLogin
                hotelResponseData={hotelRevenueData}
                townoResponseData={townoRevenueData}
              />
            </Grid>
          </Grid>
        </Paper>
      </>
      <>
        <Typography className={classes.sectionHeader}>Room Nights</Typography>
        <Paper style={{ padding: "10px" }}>
          <Grid container>
            <Grid item xs={12} paddingLeft="78%">
              <Stack
                direction="row"
                justifyContent="right"
                alignContent="right"
                style={{ backgroundColor: "#fff", padding: "1em 1em 0 0" }}
              >
                <Paper
                  square
                  style={{
                    width: "fit-content",
                    borderRadius: "5px",
                    height: "40px",
                    padding: "0px",
                  }}
                >
                  <ButtonGroup
                    style={{
                      border: "1px solid #f46d25",
                      borderRadius: "5px",
                      height: "100%",
                      padding: "0px",
                    }}
                  >
                    <Button
                      style={{
                        background:
                          roomNightsDataTimeline === "previous" && "#f46d25",
                        fontSize: "10px",
                        padding: "5px",
                      }}
                      onClick={() => setRoomNightsDataTimeLine("previous")}
                    >
                      <span
                        style={{
                          color:
                            roomNightsDataTimeline !== "previous" && "#f46d25",
                        }}
                      >
                        Previous Month
                      </span>
                    </Button>
                    <Button
                      style={{
                        background:
                          roomNightsDataTimeline === "present" && "#f46d25",
                        color:
                          roomNightsDataTimeline === "present"
                            ? "white"
                            : "#000000",
                        fontSize: "10px",
                        padding: "5px",
                      }}
                      onClick={() => setRoomNightsDataTimeLine("present")}
                    >
                      <span
                        style={{
                          color:
                            roomNightsDataTimeline !== "present" && "#f46d25",
                        }}
                      >
                        Present Month
                      </span>
                    </Button>
                  </ButtonGroup>
                </Paper>
              </Stack>
            </Grid>
            <Grid item xs={12} textAlign={"center"}>
              <MultiLineChartHotelLogin
                hotelResponseData={hotelRoomNightsData}
                townoResponseData={townoRoomNightsData}
                type="nights"
              />
            </Grid>
          </Grid>
        </Paper>
      </>
      <div style={{ marginTop: "20px" }}>
        <UpcomingPayment />
      </div>
      <div style={{ marginTop: "20px" }}>
        <UpcomingCheckin />
      </div>
    </div>
  );
};

export default HotelDashboard;
