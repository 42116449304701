import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Checkbox, FormControlLabel, Grid } from "@mui/material"
import { Paper, Button } from "@material-ui/core"
import Select from "react-select"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import Api from "../../Service/Api"
import {
  format,
  addDays,
  addMonths,
  addWeeks,
  subYears,
  subMonths,
  subWeeks,
} from "date-fns"
import moment from "moment"
import _ from "lodash"

import AgentWiseSalesReport from "./AgentWiseSalesReport"
import TotalClients from "./TotalClients"
import TotalHotels from "./TotalHotels"
import FinanceReport from "./FinanceReport"
import LeadReport from "./LeadReport"
import AgentFinaceReport from "./AgentFinanceReport"

import AcUnitIcon from "@material-ui/icons/AcUnit"
import BrightnessHighIcon from "@material-ui/icons/BrightnessHigh"
import WhatshotTwoToneIcon from "@material-ui/icons/WhatshotTwoTone"
import ThumbDownIcon from "@material-ui/icons/ThumbDown"
import ThumbUpIcon from "@material-ui/icons/ThumbUp"
import FiberNewIcon from "@material-ui/icons/FiberNew"
import EventNoteIcon from "@material-ui/icons/EventNote"
import PackageReport from "./PackageReport"
import TransferReport from "./TransferReport"
import AgentWiseHotelReport from "./AgentWiseHotelReport"
import AgentClientReport from "./AgentClientReport"
import TotalAgentHotels from "./TotalAgentHotels"
import HotelProductivity from "./HotelProductivityReport"
import AgentRevenueReports from "./AgentRevenueReports"
import StaffRevenueCompareReport from "./StaffRevenueCompareReport"
import useLeadAssignForList from "../../hooks/lead/useLeadAssignForList"

const useStyles = makeStyles((theme) => ({
  root: {
    // paddingTop: "100px",
    // margin: "0px 10px",
    padding: "5.9% 1% 1% 1%",
  },
  paper: {
    padding: "20px",
    background: "#F4F4F4",
  },
  icon: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    position: "absolute",
    right: "15px",
    top: "5px",
    color: "#f46d25",
  },
  topBorder: {
    position: "absolute",
    top: "-9px",
    zIndex: "10",
    left: "12px",
    color: "#fff",
    background: "#F46D25",
    borderRadius: "4px",
    padding: "1px 4px",
    fontSize: "12px",
  },
}))

const filterOptions = [
  { label: "All", value: "", icon: " " },
  {
    label: "New",
    value: "new",
    icon: (
      <FiberNewIcon
        style={{ marginLeft: "10px", color: "#fc7506", fontSize: "26px" }}
      />
    ),
  },
  {
    label: "Followup",
    value: "followup",
    icon: (
      <EventNoteIcon
        style={{ marginLeft: "10px", color: "#0000a5", fontSize: "26px" }}
      />
    ),
  },
  {
    label: "Lost",
    value: "lost",
    icon: (
      <ThumbDownIcon
        style={{ marginLeft: "10px", color: "#abaaaa", fontSize: "26px" }}
      />
    ),
  },
  {
    label: "Closed",
    value: "closed",
    icon: (
      <ThumbUpIcon
        style={{ marginLeft: "10px", color: "#1eaf1e", fontSize: "26px" }}
      />
    ),
  },
  {
    label: "Warm",
    value: "followupwarm",
    icon: (
      <BrightnessHighIcon
        style={{ marginLeft: "10px", color: "#febc12", fontSize: "26px" }}
      />
    ),
  },
  {
    label: "Hot",
    value: "followuphot",
    icon: (
      <WhatshotTwoToneIcon
        style={{ marginLeft: "10px", color: "#e71e24", fontSize: "26px" }}
      />
    ),
  },
  {
    label: "Cold",
    value: "followupcold",
    icon: (
      <AcUnitIcon
        style={{ marginLeft: "10px", color: "#8aceee", fontSize: "26px" }}
      />
    ),
  },
]

const revenueDuration = [
  { label: "Day", value: "day" },
  // { label: "Week", value: "week" },
  { label: "CustomDateRange", value: "customDateRange" },
  { label: "Month", value: "month" },
  { label: "Quarter", value: "quarter" },
  { label: "Year", value: "year" },
]

const ReportByOptions = [
  { label: "Booking Date", value: "bookingDate" },
  { label: "CheckIn Date", value: "checkInDate" },
]

export default function Report() {
  const classes = useStyles()
  let hasAdmin = localStorage.getItem("role")
  let checkAgent = localStorage.getItem("agent")
  const uniqueid = localStorage.getItem("unique_id")
  const leadAssignedForList = useLeadAssignForList(uniqueid)
  const [reportType, setReportType] = useState({
    value: "financeReport",
    label: "Finance Report",
  })

  const [agentRevenueDuartion, setAgentRevenueDuartion] = useState(
    revenueDuration[0].value
  )

  const [hotelList, setHotelList] = useState([])
  const [agentList, setAgentList] = useState([])
  const [errorFrom, setError] = useState({})
  const [checkin, setCheckin] = useState(null)
  const [checkout, setCheckout] = useState(null)
  const [bookingDateFrom, setBookingDateFrom] = useState(null)
  const [bookingDateTo, setBookingDateTo] = useState(null)
  const [hotelName, setHotelName] = useState({
    value: reportType.value === "hotelProductivityReport" ? "" : "all",
    label: reportType.value === "hotelProductivityReport" ? "" : "All",
  })
  const [agent, setAgent] = useState({
    value: "all",
    label: "All",
  })
  const [reportData, setReportData] = useState([])
  const [leadscoringFilter, setLeadscoringFilter] = useState({
    value: "",
    label: "All",
  })
  const [duration, setDuration] = useState({
    label: "Booking Date",
    value: "bookingDate",
  })

  const [leadHotel, setLeadHotel] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    Api.get(`gethoteloflead/${uniqueid}`).then((res) => {
      // console.log("leadReport Hotels:", res.data, leadHotel);
      setLeadHotel(res.data)
    })
  }, [])

  const RestData = () => {
    setReportData([])
    setAgent({
      value: "all",
      label: "All",
    })
    setHotelName({
      value: "all",
      label: "All",
    })
    setLeadscoringFilter({
      value: "",
      label: "All",
    })
    setBookingDateFrom(null)
    setBookingDateTo(null)
    setCheckout(null)
    setCheckin(null)
    setError({})
    setReportType({
      value: "financeReport",
      label: "Finance Report",
    })
    setAgentRevenueDuartion(revenueDuration[0].value)
    setAgentRevenueList([])
    setVarianceIsChecked(false)
    setReportBy("")
    setDayOrQuarter(new Date())
  }
  useEffect(() => {
    if (reportType.value === "totalHotels") {
      HotelReport()
    }
    hotelFetch()
    AgentFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType])

  const hotelFetch = () => {
    //let url = "propertybasicpropertydetailsall";
    let url = `propertydisplayname/${uniqueid}`
    if (reportType.value === "leadReport") {
      if (hasAdmin === "Agent Admin") {
        url = `leadpropertydisplayname/${uniqueid}`
      } else {
        url = `propertydisplayname/${uniqueid}`
      }
    } else if (reportType.value === "packageReport") {
      url = `agentpackagepropertyname/${uniqueid}`
    }

    if (hasAdmin === "Admin") {
      url = `propertydisplaynameall/${uniqueid}`
    }

    if (hasAdmin === "Agent Admin" || checkAgent === "Agent") {
      if (reportType.value === "leadReport") {
        url = `leadpropertydisplayname/${uniqueid}`
      } else if (reportType.value === "packageReport") {
        url = `agentpackagepropertyname/${uniqueid}`
      } else {
        url = `agentbookingproperty/${uniqueid}`
      }
    }

    Api.get(url).then((res) => {
      let result = res.data
      // console.log("leadReport response data: ", result);
      let sorted = _.orderBy(result, ["displayName"], ["asec"])
      const Merged =
        reportType.value !== "hotelProductivityReport"
          ? [
              ...[
                {
                  displayName: "All",
                  propertyId: "all",
                },
              ],
              ...sorted,
            ]
          : [...sorted]

      // console.log("hotellist:", Merged);
      setHotelList(Merged)
    })
  }

  const AgentFetch = () => {
    const url =
      reportType.value === "agentwiseRevenueReports"
        ? "agentlistforsearch" //staffDisplayNameAll earlier it is this
        : "leadassignfor"

    Api.get(`${url}/${uniqueid}`).then((res) => {
      // console.log("url response: ", res);
      let sorted = _.orderBy(res.data, [], ["asec"])

      const forAgentRevenue = sorted.map((agent) => ({
        label: agent,
        value: agent,
      }))

      const forOthers = sorted.map((agent) => ({
        label: agent.username,
        value: agent.username,
      }))

      let mapped_data =
        reportType.value === "agentwiseRevenueReports"
          ? forAgentRevenue
          : forOthers
      let Merged =
        reportType.value !== "agentwiseRevenueReports"
          ? [
              {
                label: "All",
                value: "All",
              },
              ...mapped_data,
            ]
          : [
              {
                label: "All",
                value: "All",
              },
              ...mapped_data,
            ]
      setAgentList(Merged)
    })
  }

  /**Hotel Productivity Starts */
  const HotelProductivityReport = (data) => {
    // console.log("hotel");
    if (hasAdmin === "Admin") {
      // let url = "hotelProductivityReport"
      // Api.post(url, data).then((res) => {
      //   // console.log("hotel productivity report", res.data);
      //   setReportData(res.data)
      // })

      const url1 = "hotelProductivityReportindividual"
      const url2 = "hotelProductivityReporttotal"

      Api.post(url1, data).then((res) => {
        setReportData((c) => ({ ...c, list: res.data }))
        Api.post(url2, data).then((res) => {
          setReportData((c) => ({ ...c, total: res.data }))
        })
      })
    }
  }
  // console.log(hasAdmin);

  /**Hotel Productivity Ends */

  /**Agent Wise Revenue Reports starts*/
  const [durationType, setDurationType] = useState("")

  const [revenueAgentList, setAgentRevenueList] = useState([])
  const [errorSelectAgentRevenue, setErrorSelectAgentRevenue] = useState("")
  const [varianceisChecked, setVarianceIsChecked] = useState(false)
  const [reportBy, setReportBy] = useState("")

  const validateAgents = (selectedAgents) => {
    if (!selectedAgents || selectedAgents.length === 0) {
      setErrorSelectAgentRevenue("Select atleast one option.")
      return false
    } else if (selectedAgents.length > 2) {
      setErrorSelectAgentRevenue("You can only select max of 2 agents")
      return false
    }
    setError("")
    return true
  }

  const agentWiseRevenueReports = async () => {
    // console.log({ agent, agentRevenueDuartion })

    // console.log({ revenueAgentList });

    const baseParams = {
      uniqueId: uniqueid,
      agent: revenueAgentList?.map((e) => e.value),
      reportBy: reportBy,
      // agent1: revenueAgentList?.[0]?.value,
      // agent2: revenueAgentList?.[1]?.value,
      // dateType: agentRevenueDuartion,
      dateSelection: agentRevenueDuartion,
      variance: varianceisChecked,
    }

    const dayWise = {
      date: format(dayOrQuarter, "yyyy-MM-dd"),
      date1:
        varianceisChecked && agentRevenueDuartion == "day"
          ? format(varianceDayDate, "yyyy-MM-dd")
          : "",
    }

    const customDateRangeWise = {
      dateFrom: format(dateFrom, "yyyy-MM-dd"),
      dateTo: format(dateTo, "yyyy-MM-dd"),
      dateFrom1:
        varianceisChecked && agentRevenueDuartion == "customDateRange"
          ? format(dateFrom1, "yyyy-MM-dd")
          : "",
      dateTo1:
        varianceisChecked && agentRevenueDuartion == "customDateRange"
          ? format(dateTo1, "yyyy-MM-dd")
          : "",
    }

    const weekWise = {
      week1: format(weekOne, "yyyy-MM-dd"),
      week2: format(weekTwo, "yyyy-MM-dd"),
    }

    const monthWise = {
      month: format(monthOne, "MM-yyyy"),
      month1:
        varianceisChecked && agentRevenueDuartion == "month"
          ? format(monthTwo, "MM-yyyy")
          : "",
    }
    const quarterly = {
      // year1: format(dayOrQuarter, "yyyy"),
      year: format(dayOrQuarter, "yyyy"),
      year1:
        varianceisChecked && agentRevenueDuartion == "quarter"
          ? format(varianceDayDate, "yyyy")
          : "",
    }

    const yearWise = {
      // year1: format(yearOne, "yyyy"),
      // year2: format(yearTwo, "yyyy"),
      year: format(yearOne, "yyyy"),
      year1:
        varianceisChecked && agentRevenueDuartion == "year"
          ? format(yearTwo, "yyyy")
          : "",
    }

    const additionalParams = {
      day: dayWise,
      customDateRange: customDateRangeWise,
      week: weekWise,
      month: monthWise,
      quarter: quarterly,
      year: yearWise,
    }[agentRevenueDuartion]

    const postParams = { ...baseParams, ...additionalParams }

    try {
      const res = await Api.post(`staffWiseRevenueReport`, postParams)

      if (res.status == 200) {
        setReportData(res.data)
        setDurationType(agentRevenueDuartion)
        return
      }
      return Promise.reject(res)
    } catch (error) {
      console.log(error)
    }
  }

  const StaffWiseRevenueComparisionReport = async () => {
    // console.log({ agent, agentRevenueDuartion })

    // console.log({ revenueAgentList });

    const baseParams = {
      uniqueId: uniqueid,
      agent: revenueAgentList?.map((e) => e.value),
      reportBy: reportBy,
      // agent1: revenueAgentList?.[0]?.value,
      // agent2: revenueAgentList?.[1]?.value,
      // dateType: agentRevenueDuartion,
      dateSelection: agentRevenueDuartion,
      variance: varianceisChecked,
    }

    const dayWise = {
      date: format(dayOrQuarter, "yyyy-MM-dd"),
      date1:
        varianceisChecked && agentRevenueDuartion == "day"
          ? format(varianceDayDate, "yyyy-MM-dd")
          : "",
    }

    const customDateRangeWise = {
      dateFrom: format(dateFrom, "yyyy-MM-dd"),
      dateTo: format(dateTo, "yyyy-MM-dd"),
      dateFrom1:
        varianceisChecked && agentRevenueDuartion == "customDateRange"
          ? format(dateFrom1, "yyyy-MM-dd")
          : "",
      dateTo1:
        varianceisChecked && agentRevenueDuartion == "customDateRange"
          ? format(dateTo1, "yyyy-MM-dd")
          : "",
    }

    const weekWise = {
      week1: format(weekOne, "yyyy-MM-dd"),
      week2: format(weekTwo, "yyyy-MM-dd"),
    }

    const monthWise = {
      month: format(monthOne, "MM-yyyy"),
      month1:
        varianceisChecked && agentRevenueDuartion == "month"
          ? format(monthTwo, "MM-yyyy")
          : "",
    }
    const quarterly = {
      // year1: format(dayOrQuarter, "yyyy"),
      year: format(dayOrQuarter, "yyyy"),
      year1:
        varianceisChecked && agentRevenueDuartion == "quarter"
          ? format(varianceDayDate, "yyyy")
          : "",
    }

    const yearWise = {
      // year1: format(yearOne, "yyyy"),
      // year2: format(yearTwo, "yyyy"),
      year: format(yearOne, "yyyy"),
      year1:
        varianceisChecked && agentRevenueDuartion == "year"
          ? format(yearTwo, "yyyy")
          : "",
    }

    const additionalParams = {
      day: dayWise,
      customDateRange: customDateRangeWise,
      week: weekWise,
      month: monthWise,
      quarter: quarterly,
      year: yearWise,
    }[agentRevenueDuartion]

    const postParams = { ...baseParams, ...additionalParams }

    try {
      const res = await Api.post(
        `staffWiseRevenueComparisionReport`,
        postParams
      )

      if (res.status == 200) {
        setReportData(res.data)
        setDurationType(agentRevenueDuartion)
        return
      }
      return Promise.reject(res)
    } catch (error) {
      console.log(error)
    }
  }

  const [dayOrQuarter, setDayOrQuarter] = useState(new Date())
  const [varianceDayDate, setVarianceDayDate] = useState(null)
  const [monthOne, setMonthOne] = useState(subMonths(new Date(), 1))
  const [dateFrom, setDateFrom] = useState(new Date())
  const [dateFrom1, setDateFrom1] = useState(null)
  const [dateTo, setDateTo] = useState(new Date())
  const [dateTo1, setDateto1] = useState(null)
  const [monthTwo, setMonthTwo] = useState(new Date())
  const [weekOne, setWeekOne] = useState(subWeeks(new Date(), 1))
  const [weekTwo, setWeekTwo] = useState(new Date())
  const [yearOne, setYearOne] = useState(subYears(new Date(), 1))
  const [yearTwo, setYearTwo] = useState(new Date())

  const day = {
    label: "Select Day",
    // views: ["day"],
    format: "d/M/yyyy",
  }

  const customDateRange = {
    label1: "Date From",
    label2: "Date To",
    format: "d/M/yyyy",
  }

  const quarter = {
    label: "Select Year",
    views: ["year"],
    format: "yyyy",
  }

  const month = {
    label1: "Month 1",
    label2: "Month 2",
    views: ["month", "year"],
    format: "MM/yyyy",
  }

  const week = {
    label1: "Week 1",
    label2: "Week 2",
    format: "dd/MM/yyyy",
    valueOne: weekOne,
    valueTwo: weekTwo,
    // views: ["day", "month", "year"],
    onChangeOne: (date) => setWeekOne(date),
    onChangeTwo: (date) => setWeekTwo(date),
  }

  const year = {
    label1: "Year 1",
    label2: "Year 2",
    format: "yyyy",
    views: ["year"],
    valueOne: yearOne,
    valueTwo: yearTwo,
    onChangeOne: (date) => setYearOne(date),
    onChangeTwo: (date) => setYearTwo(date),
  }

  const datePickerOptions = {
    day,
    customDateRange,
    quarter,
    month,
    year,
    week,
  }[agentRevenueDuartion]

  /**Agent Wise Revenue Reports ends*/

  const FinanceReportData = (Data) => {
    let url = "FinanceReport"
    if (hasAdmin === "Agent Admin") {
      url = "financeBookingReport"
    }
    Api.post(url, Data).then((res) => {
      // console.log("finance response data", res.data);
      setReportData(res.data)
    })
  }

  const AgentReport = (Data) => {
    let url = "AgentWiseSalesReport"
    if (hasAdmin === "Agent Admin") {
      url = "AgentWiseHotelBookingReport"
    }
    Api.post(url, Data).then((res) => {
      setReportData(res.data)
    })
  }

  const HotelReport = () => {
    let url = "HotelReport"
    if (hasAdmin === "Agent Admin") {
      url = `QuickPropertyReport/${uniqueid}`
    }
    Api.get(url).then((res) => {
      setReportData(res.data)
    })
  }

  const ClientReport = (Data) => {
    let url = "ClientReport"
    if (hasAdmin === "Agent Admin") {
      url = "TravelAgentClientReport"
    }
    Api.post(url, Data).then((res) => {
      setReportData(res.data)
    })
  }

  const LeadReports = (Data) => {
    Api.post(`reportboardlead/${uniqueid}`, Data).then((res) => {
      // console.log("Reports|LeadReports|", res.data);
      setReportData(res.data)
    })
  }

  const PackageReports = (Data) => {
    Api.post("PackageReport", Data).then((res) => {
      // console.log("Reports|PackageReports|", res.data);
      setReportData(res.data)
    })
  }

  const TransferReports = (Data) => {
    Api.post("TransferReport", Data).then((res) => {
      // console.log("Reports|TransferReports|", res.data);
      setReportData(res.data)
    })
  }

  const checkDateErrors = (e, triggerDate) => {
    const errors = {}
    if (triggerDate === "bookingDateFrom") {
      if (_.isDate(bookingDateTo)) {
        const MyNext = addDays(e, 90)
        if (moment(MyNext).isSameOrAfter(bookingDateTo) == false) {
          errors.bookingDateFrom =
            reportType.value === "leadReport"
              ? "Select 90 days Only Before the Date of CreatedDateTo"
              : "Select 90 days Only Before the Date of BookingDateTo"
        }
        if (moment(e).isBefore(bookingDateTo) == false) {
          errors.bookingDateFrom =
            reportType.value === "leadReport"
              ? "Select Less than Created Date To"
              : "Select Less than Booking Date To"
        }
      }
    } else if (triggerDate === "bookingDateTo") {
      if (_.isDate(bookingDateFrom)) {
        const MyNext = addDays(bookingDateFrom, 90)
        if (moment(MyNext).isSameOrAfter(e) == false) {
          errors.bookingDateTo =
            reportType.value === "leadReport"
              ? "Select 90 days Only From the Date of CreatedDateFrom"
              : "Select 90 days Only From the Date of BookingDateFrom"
        }
        if (moment(bookingDateFrom).isBefore(e) == false) {
          errors.bookingDateTo =
            reportType.value === "leadReport"
              ? "Select Greater than Created Date From"
              : "Select Greater than Booking Date From"
        }
      }
    }

    setError(errors)
  }

  const Submit = (e) => {
    e.preventDefault()
    // console.log(e);
    const errors = {}

    if (bookingDateFrom !== null) {
      if (bookingDateTo == null) {
        errors.bookingDateTo =
          reportType.value === "leadReport"
            ? "CreatedDateTo Required"
            : "BookingDateTo Required"
      }
    }
    if (bookingDateTo !== null) {
      if (bookingDateFrom == null) {
        errors.bookingDateFrom =
          reportType.value === "leadReport"
            ? "CreatedDateFrom Required"
            : "BookingDateFrom Required"
      }
    }
    if (!checkout && !checkin) {
      if (!bookingDateFrom && !bookingDateTo) {
        errors.bookingDateTo =
          reportType.value === "leadReport"
            ? "CreatedDateTo Required"
            : "BookingDateTo Required"
        errors.bookingDateFrom =
          reportType.value === "leadReport"
            ? "CreatedDateFrom Required"
            : "BookingDateFrom Required"
      }
    }

    if (_.isDate(checkin) && _.isDate(checkout)) {
      const MyNext = addDays(checkin, 90)
      if (moment(MyNext).isSameOrAfter(checkout) == false) {
        errors.checkoutValid = "Select 90 days Only From the Date of Checkin"
      }
    }
    if (_.isDate(bookingDateFrom) && _.isDate(bookingDateTo)) {
      const MyNext = addDays(bookingDateFrom, 90)
      if (moment(MyNext).isSameOrAfter(bookingDateTo) == false) {
        errors.bookingDateTo =
          reportType.value === "leadReport"
            ? "Select 90 days Only From the Date of CreatedDateFrom"
            : "Select 90 days Only From the Date of BookingDateFrom"
      }
    }

    if (_.isDate(bookingDateFrom)) {
      if (bookingDateTo == null) {
        errors.bookingDateTo =
          reportType.value === "leadReport"
            ? "CreatedDateTo Required"
            : "BookingDateTo Required"
      }
    }
    if (_.isDate(bookingDateTo)) {
      if (bookingDateFrom == null) {
        errors.bookingDateFrom =
          reportType.value === "leadReport"
            ? "CreatedDateFrom Required"
            : "BookingDateFrom Required"
      }
    }

    if (_.isDate(checkin) && _.isDate(checkout)) {
      if (moment(checkin).isBefore(checkout) == false) {
        errors.checkoutValid = "Select Greater than Checkin"
      }
    }

    if (_.isDate(bookingDateFrom) && _.isDate(bookingDateTo)) {
      if (moment(bookingDateFrom).isBefore(bookingDateTo) == false) {
        errors.bookingDateTo =
          reportType.value === "leadReport"
            ? "Select Greater than Created Date From"
            : "Select Greater than Booking Date From"
      }
    }

    if (
      hotelName.value == "" ||
      (null && reportType.value === "hotelProductivityReport")
    ) {
      errors.hotelName = "Please Select Any Hotel"
    }

    setError(errors)
    let sendCheckIn = null
    let sendCheckOut = null
    if (reportType.value === "packageReport") {
      sendCheckIn = checkin !== null ? format(checkin, "dd-MM-yyyy") : ""
      sendCheckOut = checkout !== null ? format(checkout, "dd-MM-yyyy") : ""
    } else {
      sendCheckIn = checkin !== null ? format(checkin, "yyyy-MM-dd") : ""
      sendCheckOut = checkout !== null ? format(checkout, "yyyy-MM-dd") : ""
    }

    if (
      Object.keys(errors).length === 0 ||
      (reportType.value === "hotelProductivityReport" && !errors.hotelName)
    ) {
      const data = {
        checkin: sendCheckIn,
        checkout: sendCheckOut,
        from:
          bookingDateFrom !== null ? format(bookingDateFrom, "yyyy-MM-dd") : "",
        to: bookingDateTo !== null ? format(bookingDateTo, "yyyy-MM-dd") : "",
        hotel: hotelName.value !== "all" ? hotelName.value : "",
        agent: agent.value !== "all" ? agent.value : "",
        leadscoring: leadscoringFilter.value,
        uniqueid: uniqueid,
      }
      // console.log(data);

      const hotelProductivityReportData = {
        uniqueid: uniqueid,
        hotel: hotelName.value !== "all" ? hotelName.value : "",
        duration: duration.value,
        from:
          bookingDateFrom !== null ? format(bookingDateFrom, "yyyy-MM-dd") : "",
        to: bookingDateTo !== null ? format(bookingDateTo, "yyyy-MM-dd") : "",
      }

      setLoading(true)
      // console.log(data);
      // console.log(hotelProductivityReportData);
      if (reportType.value === "financeReport") {
        FinanceReportData(data)
        // console.log("finanaaaance");
      }
      if (reportType.value === "leadReport") {
        LeadReports(data)
      }
      if (reportType.value === "agentWiseSalesReport") {
        AgentReport(data)
      }
      if (reportType.value === "totalClient") {
        ClientReport(data)
      }
      if (reportType.value === "packageReport") {
        PackageReports(data)
      }
      if (reportType.value === "transferReport") {
        TransferReports(data)
      }
      if (
        reportType.value === "hotelProductivityReport" &&
        hasAdmin === "Admin"
      ) {
        HotelProductivityReport(hotelProductivityReportData)
        // console.log(hotelProductivityReportData);
      }

      if (_.isEqual(reportType.value, "staffWiseLeadReport")) {
        console.log("Hello")
      }
    }

    if (reportType.value === "agentwiseRevenueReports") {
      agentWiseRevenueReports()
      // if (validateAgents(revenueAgentList)) {
      //   agentWiseRevenueReports();
      // }
    }
    if (reportType.value === "staffWiseRevenueComparisionReport") {
      StaffWiseRevenueComparisionReport()
    }
    setLoading(false)
  }

  const Re = (reportType) => {
    console.log("calling after report change value")
    setReportType(reportType)
    setReportData(reportType.value === "hotelProductivityReport" ? {} : [])
    setAgent({
      value: "all",
      label: "All",
    })
    setHotelName({
      value: reportType.value === "hotelProductivityReport" ? "" : "all",
      label: reportType.value === "hotelProductivityReport" ? "" : "All",
    })
    setLeadscoringFilter({
      value: "",
      label: "All",
    })
    setDuration({
      label:
        reportType.value === "hotelProductivityReport"
          ? "Booking Date"
          : "Today",
      value:
        reportType.value === "hotelProductivityReport"
          ? "bookingDate"
          : "Today",
    })
    setBookingDateFrom(null)
    setBookingDateTo(null)
    setCheckout(null)
    setCheckin(null)
    setError({})
    setAgentRevenueList([])
    setDayOrQuarter(new Date())
    setVarianceDayDate(null)
    setDateFrom(new Date())
    setDateFrom1(null)
    setDateTo(new Date())
    setDateto1(null)
  }

  const durationOptions = [
    { label: "Booking Date", value: "bookingDate" },
    { label: "Check-in", value: "checkInDate" },
  ]

  const hotelProductivityReportAdditionalParams = {
    [duration.label]: `${format(
      new Date(bookingDateFrom),
      "dd-MM-yyyy"
    )} to ${format(new Date(bookingDateTo), "dd-MM-yyyy")}`,
    ["Hotel Name"]: hotelName.label,
    Name: "Hotel Productivity Summary",
    queryBy: duration.label,
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Paper elevation={3} className={classes.paper}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={2}>
                {/* Reports Type Select Box  */}
                <Grid item lg={3}>
                  <div style={{ position: "relative" }}>
                    <span className={classes.topBorder}>Reports Type</span>

                    <Select
                      options={[
                        { value: "financeReport", label: "Finance Report" },
                        {
                          value: "agentWiseSalesReport",
                          label: "Agent Wise Sales Report",
                        },
                        hasAdmin !== "Hotel Admin" && {
                          value: "totalHotels",
                          label: "Total Hotels",
                        },
                        { value: "totalClient", label: "Total Client" },
                        // { value: "refundReport", label: "Refund Report" },
                        { value: "leadReport", label: "Lead Report" },
                        { value: "packageReport", label: "Package Report" },
                        { value: "transferReport", label: "Transfer Report" },
                        hasAdmin === "Admin" && {
                          value: "hotelProductivityReport",
                          label: "Hotel Productivity Report",
                        },
                        hasAdmin === "Admin" && {
                          value: "agentwiseRevenueReports",
                          label: "Staff Performance Reports",
                        },
                        hasAdmin === "Admin" && {
                          value: "staffWiseRevenueComparisionReport",
                          label: "Staff Revenue Comparision Reports",
                        },
                        hasAdmin === "Admin" && {
                          label: "Lead Report [Staff-wise]",
                          value: "staffWiseLeadReport",
                        },
                      ]}
                      placeholder=""
                      value={reportType}
                      onChange={Re}
                      styles={{
                        container: (provided) => ({
                          ...provided,
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 9,
                        }),
                        control: (base, state) => ({
                          ...base,
                          "&:hover": { borderColor: "#f46d25" },
                          borderColor: "#f46d25",
                          boxShadow: "none",
                        }),
                      }}
                    />
                  </div>
                </Grid>
                {/* Agent Select Box  */}
                {reportType.value === "financeReport" ||
                reportType.value === "agentWiseSalesReport" ||
                reportType.value === "leadReport" ||
                reportType.value === "packageReport" ||
                reportType.value === "transferReport" ? (
                  <Grid item lg={3}>
                    <div style={{ position: "relative" }}>
                      <span className={classes.topBorder}>
                        {hasAdmin === "Agent Admin" ? "Staff" : "Staff"}
                      </span>

                      <Select
                        options={agentList}
                        placeholder=""
                        value={agent}
                        onChange={(agent) => {
                          setAgent(agent)
                        }}
                        styles={{
                          container: (provided) => ({
                            ...provided,
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9,
                          }),
                          control: (base, state) => ({
                            ...base,
                            "&:hover": { borderColor: "#f46d25" },
                            borderColor: "#f46d25",
                            boxShadow: "none",
                          }),
                        }}
                      />
                    </div>
                  </Grid>
                ) : null}
                {reportType.value === "agentwiseRevenueReports" ||
                reportType.value === "staffWiseRevenueComparisionReport" ? (
                  <Grid item lg={3}>
                    <div style={{ position: "relative" }}>
                      <span className={classes.topBorder}>Report By</span>
                      <Select
                        options={ReportByOptions}
                        placeholder=""
                        value={ReportByOptions.find(
                          (r) => r.value === reportBy
                        )}
                        defaultValue={ReportByOptions[0]}
                        onChange={(report) => {
                          // console.log("reportBy", report.value);
                          setReportBy(report.value)
                          // setErrorSelectAgentRevenue("")
                        }}
                        styles={{
                          color: "black",
                          container: (provided) => ({
                            ...provided,
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9,
                          }),
                          control: (base, state) => ({
                            ...base,
                            "&:hover": { borderColor: "#f46d25" },
                            borderColor: "#f46d25",
                            boxShadow: "none",
                          }),
                        }}
                      />
                      {/* <div style={{ color: "red" }}>
                        {errorSelectAgentRevenue ? errorSelectAgentRevenue : ""}
                      </div> */}
                    </div>
                  </Grid>
                ) : null}
                {reportType.value === "agentwiseRevenueReports" ||
                reportType.value === "staffWiseRevenueComparisionReport" ? (
                  <Grid item lg={3}>
                    <div style={{ position: "relative" }}>
                      <span className={classes.topBorder}>
                        {hasAdmin === "Agent Admin" ? "Staff" : "Staff"}
                      </span>

                      <Select
                        options={agentList}
                        placeholder=""
                        value={revenueAgentList}
                        onChange={(agent) => {
                          // console.log("agentList", agentList);
                          console.log({ agent })
                          setAgentRevenueList(agent)
                          // setErrorSelectAgentRevenue("");
                        }}
                        styles={{
                          container: (provided) => ({
                            ...provided,
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9,
                          }),
                          control: (base, state) => ({
                            ...base,
                            "&:hover": { borderColor: "#f46d25" },
                            borderColor: "#f46d25",
                            boxShadow: "none",
                          }),
                        }}
                        isMulti
                      />
                      {/* <div style={{ color: "red" }}>
                        {errorSelectAgentRevenue ? errorSelectAgentRevenue : ""}
                      </div> */}
                    </div>
                  </Grid>
                ) : null}
                {reportType.value === "agentwiseRevenueReports" ||
                reportType.value === "staffWiseRevenueComparisionReport" ? (
                  <>
                    <Grid item lg={2}>
                      <div style={{ position: "relative" }}>
                        <span className={classes.topBorder}>Duration</span>
                        <Select
                          options={revenueDuration}
                          placeholder="Duration"
                          value={revenueDuration.find(
                            (r) => r.value === agentRevenueDuartion
                          )}
                          onChange={(option) => {
                            // console.log(option.value)
                            setAgentRevenueDuartion(option.value)
                            setDayOrQuarter(new Date())
                            setVarianceDayDate(null)
                            setDateFrom(new Date())
                            setDateFrom1(null)
                            setDateTo(new Date())
                            setDateto1(null)
                          }}
                          styles={{
                            container: (provided) => ({
                              ...provided,
                            }),
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9,
                            }),
                            control: (base, state) => ({
                              ...base,
                              "&:hover": { borderColor: "#f46d25" },
                              borderColor: "#f46d25",
                              boxShadow: "none",
                            }),
                          }}
                        />
                      </div>
                    </Grid>
                  </>
                ) : null}
                {reportType.value === "agentwiseRevenueReports" ||
                reportType.value === "staffWiseRevenueComparisionReport" ? (
                  <>
                    <Grid item lg={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={varianceisChecked}
                            onChange={(e) =>
                              setVarianceIsChecked(e.target.checked)
                            }
                          />
                        }
                        label="Variance"
                      />
                    </Grid>
                  </>
                ) : null}
                {(reportType.value === "agentwiseRevenueReports" ||
                  reportType.value === "staffWiseRevenueComparisionReport") &&
                (agentRevenueDuartion == "day" ||
                  agentRevenueDuartion == "quarter") ? (
                  <Grid item lg={3}>
                    <DatePicker
                      label={datePickerOptions.label}
                      InputLabelProps={{ shrink: true }}
                      inputVariant="outlined"
                      size="small"
                      name="dayOrQuarter"
                      fullWidth
                      views={
                        datePickerOptions.views && [...datePickerOptions.views]
                      }
                      value={dayOrQuarter}
                      onChange={(date) => setDayOrQuarter(date)}
                      // animateYearScrolling
                      format={datePickerOptions.format}
                      variant="inline"
                      autoOk="true"
                      disableFuture
                    />
                  </Grid>
                ) : null}
                {(reportType.value === "agentwiseRevenueReports" ||
                  reportType.value === "staffWiseRevenueComparisionReport") &&
                varianceisChecked &&
                (agentRevenueDuartion === "day" ||
                  agentRevenueDuartion == "quarter") ? (
                  <Grid item lg={3}>
                    <DatePicker
                      label={datePickerOptions.label}
                      InputLabelProps={{ shrink: true }}
                      inputVariant="outlined"
                      size="small"
                      name="varianceDayDate"
                      fullWidth
                      views={
                        datePickerOptions.views && [...datePickerOptions.views]
                      }
                      value={varianceDayDate}
                      onChange={(date) => setVarianceDayDate(date)}
                      // animateYearScrolling
                      format={datePickerOptions.format}
                      variant="inline"
                      autoOk="true"
                      disableFuture
                    />
                  </Grid>
                ) : null}
                {(reportType.value === "agentwiseRevenueReports" ||
                  reportType.value === "staffWiseRevenueComparisionReport") &&
                agentRevenueDuartion == "customDateRange" ? (
                  <>
                    <Grid item lg={3}>
                      <DatePicker
                        label={datePickerOptions.label1}
                        InputLabelProps={{ shrink: true }}
                        inputVariant="outlined"
                        size="small"
                        name="dateFrom"
                        fullWidth
                        views={
                          datePickerOptions.views && [
                            ...datePickerOptions.views,
                          ]
                        }
                        value={dateFrom}
                        onChange={(date) => setDateFrom(date)}
                        // animateYearScrolling
                        format={datePickerOptions.format}
                        variant="inline"
                        autoOk="true"
                        disableFuture
                      />
                    </Grid>

                    <Grid item lg={3}>
                      <DatePicker
                        label={datePickerOptions.label2}
                        InputLabelProps={{ shrink: true }}
                        inputVariant="outlined"
                        size="small"
                        name="dateTo"
                        fullWidth
                        views={
                          datePickerOptions.views && [
                            ...datePickerOptions.views,
                          ]
                        }
                        value={dateTo}
                        onChange={(date) => setDateTo(date)}
                        // animateYearScrolling
                        format={datePickerOptions.format}
                        variant="inline"
                        autoOk="true"
                        disableFuture
                      />
                    </Grid>
                  </>
                ) : null}
                {(reportType.value === "agentwiseRevenueReports" ||
                  reportType.value === "staffWiseRevenueComparisionReport") &&
                varianceisChecked &&
                agentRevenueDuartion == "customDateRange" ? (
                  <>
                    <Grid item lg={3}>
                      <DatePicker
                        label={datePickerOptions.label1}
                        InputLabelProps={{ shrink: true }}
                        inputVariant="outlined"
                        size="small"
                        name="dateFrom1"
                        fullWidth
                        views={
                          datePickerOptions.views && [
                            ...datePickerOptions.views,
                          ]
                        }
                        value={dateFrom1}
                        onChange={(date) => setDateFrom1(date)}
                        // animateYearScrolling
                        format={datePickerOptions.format}
                        variant="inline"
                        autoOk="true"
                        disableFuture
                      />
                    </Grid>

                    <Grid item lg={3}>
                      <DatePicker
                        label={datePickerOptions.label2}
                        InputLabelProps={{ shrink: true }}
                        inputVariant="outlined"
                        size="small"
                        name="dateTo1"
                        fullWidth
                        views={
                          datePickerOptions.views && [
                            ...datePickerOptions.views,
                          ]
                        }
                        value={dateTo1}
                        onChange={(date) => setDateto1(date)}
                        // animateYearScrolling
                        format={datePickerOptions.format}
                        variant="inline"
                        autoOk="true"
                        disableFuture
                      />
                    </Grid>
                  </>
                ) : null}
                {agentRevenueDuartion == "month" ? (
                  <>
                    <Grid item lg={3}>
                      <DatePicker
                        label={datePickerOptions.label1}
                        InputLabelProps={{ shrink: true }}
                        inputVariant="outlined"
                        size="small"
                        name="monthOne"
                        fullWidth
                        views={
                          datePickerOptions.views && [
                            ...datePickerOptions.views,
                          ]
                        }
                        value={monthOne}
                        onChange={(date) => setMonthOne(date)}
                        animateYearScrolling
                        format={datePickerOptions.format}
                        variant="inline"
                        autoOk="true"
                      />
                    </Grid>

                    {varianceisChecked && (
                      <Grid item lg={3}>
                        <DatePicker
                          label={datePickerOptions.label2}
                          InputLabelProps={{ shrink: true }}
                          inputVariant="outlined"
                          size="small"
                          name="monthTwo"
                          fullWidth
                          views={
                            datePickerOptions.views && [
                              ...datePickerOptions.views,
                            ]
                          }
                          value={monthTwo}
                          onChange={(date) => setMonthTwo(date)}
                          animateYearScrolling
                          format={datePickerOptions.format}
                          variant="inline"
                          autoOk="true"
                        />
                      </Grid>
                    )}
                  </>
                ) : null}
                {(reportType.value === "agentwiseRevenueReports" ||
                  reportType.value === "staffWiseRevenueComparisionReport") &&
                (agentRevenueDuartion == "year" ||
                  agentRevenueDuartion == "week") ? (
                  <>
                    <Grid item lg={3}>
                      <DatePicker
                        label={datePickerOptions.label1}
                        InputLabelProps={{ shrink: true }}
                        inputVariant="outlined"
                        size="small"
                        fullWidth
                        views={
                          datePickerOptions.views && [
                            ...datePickerOptions.views,
                          ]
                        }
                        value={datePickerOptions.valueOne}
                        onChange={datePickerOptions.onChangeOne}
                        // animateYearScrolling
                        format={datePickerOptions.format}
                        variant="inline"
                        autoOk="true"
                        openTo={
                          agentRevenueDuartion === "year"
                            ? agentRevenueDuartion
                            : "date"
                        }
                        disableFuture
                      />
                    </Grid>

                    {varianceisChecked && (
                      <Grid item lg={3}>
                        <DatePicker
                          label={datePickerOptions.label2}
                          InputLabelProps={{ shrink: true }}
                          inputVariant="outlined"
                          size="small"
                          fullWidth
                          views={
                            datePickerOptions.views && [
                              ...datePickerOptions.views,
                            ]
                          }
                          value={datePickerOptions.valueTwo}
                          onChange={datePickerOptions.onChangeTwo}
                          // animateYearScrolling
                          format={datePickerOptions.format}
                          variant="inline"
                          autoOk="true"
                          openTo={
                            agentRevenueDuartion === "year"
                              ? agentRevenueDuartion
                              : "date"
                          }
                          disableFuture
                        />
                      </Grid>
                    )}
                  </>
                ) : null}
                {/* Hotels Select Box   */}
                {(reportType.value === "financeReport" ||
                  reportType.value === "agentWiseSalesReport" ||
                  reportType.value === "leadReport" ||
                  reportType.value === "packageReport" ||
                  reportType.value === "hotelProductivityReport") &&
                hasAdmin !== "Hotel Admin" ? (
                  <Grid item md={3}>
                    <div style={{ position: "relative" }}>
                      <span className={classes.topBorder}>Hotels</span>
                      <Select
                        placeholder=""
                        options={hotelList.map((hotel) => ({
                          label: hotel.displayName,
                          value: hotel.propertyId,
                        }))}
                        value={hotelName}
                        onChange={(hotelName) => setHotelName(hotelName)}
                        styles={{
                          container: (provided) => ({
                            ...provided,
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9,
                          }),
                          control: (base, state) => ({
                            ...base,
                            "&:hover": { borderColor: "#f46d25" },
                            borderColor: "#f46d25",
                            boxShadow: "none",
                          }),
                        }}
                      />
                    </div>
                    {reportType.value === "hotelProductivityReport" && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "13px",
                          fontStyle: "italic",
                        }}
                      >
                        {errorFrom.hotelName}
                      </span>
                    )}
                  </Grid>
                ) : null}
                {/*Duration for Hotel Productivity Report */}
                {reportType.value === "hotelProductivityReport" &&
                  hasAdmin === "Admin" && (
                    <Grid item md={3}>
                      <div style={{ position: "relative" }}>
                        <span className={classes.topBorder}>Duration</span>
                        <Select
                          placeholder=""
                          options={durationOptions.map((option) => ({
                            label: option.label,
                            value: option.value,
                          }))}
                          value={duration}
                          defaultValue={duration}
                          onChange={(duration) => {
                            setDuration(duration)
                            console.log("duration", duration)
                          }}
                          styles={{
                            container: (provided) => ({
                              ...provided,
                            }),
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9,
                            }),
                            control: (base, state) => ({
                              ...base,
                              "&:hover": { borderColor: "#f46d25" },
                              borderColor: "#f46d25",
                              boxShadow: "none",
                            }),
                          }}
                        />
                      </div>
                    </Grid>
                  )}
                {/* Lead Scoring Filter By Select Box*/}
                {reportType.value === "leadReport" ? (
                  <Grid item md={3}>
                    <div style={{ position: "relative" }}>
                      <span className={classes.topBorder}>Filter By</span>
                      <Select
                        placeholder=""
                        options={filterOptions.map((each) => ({
                          label: each.label,
                          value: each.value,
                          icon: each.icon,
                        }))}
                        value={leadscoringFilter}
                        onChange={(leadscoringFilter) =>
                          setLeadscoringFilter(leadscoringFilter)
                        }
                        styles={{
                          container: (provided) => ({
                            ...provided,
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9,
                          }),
                          control: (base, state) => ({
                            ...base,
                            "&:hover": { borderColor: "#f46d25" },
                            borderColor: "#f46d25",
                            boxShadow: "none",
                          }),
                        }}
                      />
                    </div>
                  </Grid>
                ) : null}
                {/* Check In Select Box  */}
                {reportType.value === "financeReport" ||
                reportType.value === "agentWiseSalesReport" ||
                reportType.value === "packageReport" ? (
                  <Grid item md={3}>
                    <div style={{ position: "relative" }}>
                      <DatePicker
                        label="Check-In"
                        inputVariant="outlined"
                        fullWidth
                        size="small"
                        animateYearScrolling
                        format="dd/MM/yyyy"
                        variant="inline"
                        autoOk="true"
                        value={checkin}
                        onChange={(e) => setCheckin(e)}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: "#fff",
                            background: "#F46D25",
                            borderRadius: "4px",
                            padding: "2px 4px",
                          },
                        }}
                      />
                      <CalendarTodayIcon className={classes.icon} />
                    </div>
                  </Grid>
                ) : null}
                {/* Check Out Select Box  */}
                {reportType.value === "financeReport" ||
                reportType.value === "agentWiseSalesReport" ||
                reportType.value === "packageReport" ? (
                  <Grid item md={3}>
                    <div style={{ position: "relative" }}>
                      <DatePicker
                        label="Check-Out"
                        inputVariant="outlined"
                        size="small"
                        fullWidth
                        format="dd/MM/yyyy"
                        animateYearScrolling
                        variant="inline"
                        autoOk="true"
                        value={checkout}
                        onChange={(e) => setCheckout(e)}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: "#fff",
                            background: "#F46D25",
                            borderRadius: "4px",
                            padding: "2px 4px",
                          },
                        }}
                      />
                      <CalendarTodayIcon className={classes.icon} />
                    </div>
                    <span style={{ color: "red" }}>
                      {errorFrom.checkoutValid}
                    </span>
                  </Grid>
                ) : null}
                {/* Date From Select Box  */}
                {reportType.value === "financeReport" ||
                reportType.value === "agentWiseSalesReport" ||
                reportType.value === "totalClient" ||
                reportType.value === "leadReport" ||
                reportType.value === "packageReport" ||
                reportType.value === "transferReport" ||
                reportType.value === "staffWiseLeadReport" ||
                reportType.value === "hotelProductivityReport" ? (
                  <Grid item md={3}>
                    <div style={{ position: "relative" }}>
                      <DatePicker
                        label={
                          reportType.value === "leadReport"
                            ? "Created Date From"
                            : reportType.value === "hotelProductivityReport"
                            ? duration.value === "bookingDate"
                              ? "Booking Date From"
                              : "Check-In Date From"
                            : "Booking Date From"
                        }
                        inputVariant="outlined"
                        fullWidth
                        size="small"
                        animateYearScrolling
                        format="dd/MM/yyyy"
                        variant="inline"
                        autoOk="true"
                        value={bookingDateFrom}
                        onChange={(e) => {
                          setBookingDateFrom(e)
                          checkDateErrors(e, "bookingDateFrom")
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: "#fff",
                            background: "#F46D25",
                            borderRadius: "4px",
                            padding: "2px 4px",
                          },
                        }}
                      />
                      <CalendarTodayIcon className={classes.icon} />
                    </div>
                    {reportType.value != "hotelProductivityReport" && (
                      <span style={{ color: "red" }}>
                        {errorFrom.bookingDateFrom}
                      </span>
                    )}
                  </Grid>
                ) : null}
                {/* Date To Select Box  */}
                {reportType.value === "financeReport" ||
                reportType.value === "agentWiseSalesReport" ||
                reportType.value === "totalClient" ||
                reportType.value === "leadReport" ||
                reportType.value === "packageReport" ||
                reportType.value === "transferReport" ||
                reportType.value === "hotelProductivityReport" ? (
                  <Grid item md={3}>
                    <div style={{ position: "relative" }}>
                      <DatePicker
                        label={
                          reportType.value === "leadReport"
                            ? "Created Date To"
                            : reportType.value === "hotelProductivityReport"
                            ? duration.value === "bookingDate"
                              ? "Booking Date To"
                              : "Check-In Date To"
                            : "Booking Date To"
                        }
                        inputVariant="outlined"
                        size="small"
                        fullWidth
                        format="dd/MM/yyyy"
                        animateYearScrolling
                        variant="inline"
                        autoOk="true"
                        value={bookingDateTo}
                        onChange={(e) => {
                          setBookingDateTo(e)
                          checkDateErrors(e, "bookingDateTo")
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: "#fff",
                            background: "#F46D25",
                            borderRadius: "4px",
                            padding: "2px 4px",
                          },
                        }}
                      />
                      <CalendarTodayIcon className={classes.icon} />
                    </div>
                    {reportType.value != "hotelProductivityReport" && (
                      <span style={{ color: "red" }}>
                        {errorFrom.bookingDateTo}
                      </span>
                    )}
                  </Grid>
                ) : null}

                {/* Submit and Reset Buttons  */}
                {reportType.value === "totalHotels" ? null : (
                  <Grid item lg={12} style={{ textAlign: "center" }}>
                    <Button onClick={Submit} color="primary">
                      Submit
                    </Button>
                    <Button
                      onClick={RestData}
                      color="secondary"
                      style={{ marginLeft: "10px" }}
                    >
                      Reset
                    </Button>
                  </Grid>
                )}
              </Grid>
            </MuiPickersUtilsProvider>
          </Paper>
        </Grid>
        <Grid item lg={12}>
          {reportType.value === "financeReport" ? (
            hasAdmin === "Agent Admin" ? (
              <AgentFinaceReport reportData={reportData} />
            ) : (
              <FinanceReport reportData={reportData} />
            )
          ) : null}

          {reportType.value === "agentWiseSalesReport" ? (
            hasAdmin === "Agent Admin" ? (
              <AgentWiseHotelReport reportData={reportData} />
            ) : (
              <AgentWiseSalesReport reportData={reportData} />
            )
          ) : null}
          {reportType.value === "totalHotels" ? (
            hasAdmin === "Agent Admin" ? (
              <TotalAgentHotels reportData={reportData} />
            ) : (
              <TotalHotels reportData={reportData} />
            )
          ) : null}
          {reportType.value === "totalClient" ? (
            hasAdmin === "Agent Admin" ? (
              <AgentClientReport reportData={reportData} />
            ) : (
              <TotalClients reportData={reportData} />
            )
          ) : null}
          {reportType.value === "leadReport" ? (
            <LeadReport reportData={reportData} />
          ) : null}
          {reportType.value === "packageReport" ? (
            <PackageReport reportData={reportData} />
          ) : null}
          {reportType.value === "transferReport" ? (
            <TransferReport reportData={reportData} />
          ) : null}
          {reportType.value === "hotelProductivityReport" &&
            hasAdmin === "Admin" && (
              <HotelProductivity
                reportData={reportData}
                loading={loading}
                additionalParams={hotelProductivityReportAdditionalParams}
              />
            )}

          {reportType.value === "agentwiseRevenueReports" && (
            <AgentRevenueReports
              reportData={reportData}
              agentRevenueDuartion={durationType}
            />
          )}
          {reportType.value === "staffWiseRevenueComparisionReport" && (
            <StaffRevenueCompareReport
              reportData={reportData}
              agentRevenueDuartion={durationType}
              varianceisChecked={varianceisChecked}
            />
          )}
        </Grid>
      </Grid>
    </div>
  )
}
