/* eslint-disable eqeqeq */
import { filter } from "lodash"
import { useState, useEffect } from "react"
// material
import Pagination from "@material-ui/lab/Pagination"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  IconButton,
  Tooltip,
  Grid,
  TextField,
  Switch,
} from "@material-ui/core"
import {
  TableListHead,
  TableToolbarAdmin,
  SearchNotFound,
} from "../../../components/tabel"
import XLSX from "xlsx"
import "./property.scss"
// components
import jsPDF from "jspdf"
import "jspdf-autotable"
import { useDispatch, useSelector } from "react-redux"
import EditIcon from "@material-ui/icons/Edit"
import { useHistory } from "react-router-dom"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { propertyListInitial } from "../../../redux/actions/propertyAction"
import CloseIcon from "@material-ui/icons/Close"
import VisibilityIcon from "@material-ui/icons/Visibility"
import Rating from "./../../OrderBooking/Rating"
import { twnButtonStyles } from "../../../utils/townoStyle"
import Api from "../../../Service/Api"
import { AddAPhotoOutlined, EditAttributes } from "@material-ui/icons"
import { useRef } from "react"
import Loader from "../../../components/Loader/Loader"
import { ReactComponent as RoomRentIcon } from "../../../assets/Icons/roomRent.svg"
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min"

const TABLE_HEAD = [
  { id: "propertyName", label: "Name", alignRight: false },
  { id: "city", label: "Location", alignRight: false },
  { id: "starRating", label: "Star Rating", alignRight: false },
  { id: "noofRooms", label: "No of Rooms", alignRight: false },
  { id: "swimingpool", label: "Swimming Pool", alignRight: false },
  { id: "jacuzzi", label: "Jacuzzi", alignRight: false },
  { id: "petFriendly", label: "Pet Friendly", alignRight: false },
  { id: "", label: "Action", align: "right" },
]

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  if (query) {
    return filter(
      array,
      (_property) =>
        _property.displayName.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _property.starRating.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _property.city.toLowerCase().indexOf(query.toLowerCase()) !== -1
    )
  }
  return stabilizedThis.map((el) => el[0])
}

export default function PropertyList() {
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("createdAt")
  const [filterName, setFilterName] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const dispatch = useDispatch()
  const history = useHistory()
  var uniqueid = localStorage.getItem("unique_id")
  var role = localStorage.getItem("role")
  const propertyLists = useSelector((state) => state.propertyList.propertyLists)
  const [pageNumber, setPageNumber] = useState(0)
  const DataPerPage = 10
  const { url } = useRouteMatch()
  console.log({ url })

  const pagesVisited = pageNumber * DataPerPage

  const pageCount = Math.ceil(propertyLists.length / DataPerPage)

  const changePage = ({ selected }) => {
    setPageNumber(selected)
  }

  const [width, setWidth] = useState(window.innerWidth)

  const [updateList, setUpdateList] = useState(false)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  useEffect(() => {
    dispatch(propertyListInitial())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateList])

  const downloadExcel = () => {
    const newData = propertyLists.map((row) => {
      delete row.id
      delete row.uniqueId
      delete row.createdBy
      delete row.createdAt
      delete row.updatedBy
      delete row.updatedAt
      delete row.status
      return row
    })
    const workSheet = XLSX.utils.json_to_sheet(newData)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, "Property List")
    // const buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
    XLSX.writeFile(workBook, "PropertyList.xlsx")
  }

  const DownloadPdf = () => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF()
    doc.text("Property", 20, 10)
    doc.autoTable({
      body: propertyLists,
      columns: [
        { header: "Name", dataKey: "propertyName" },
        { header: "Location", dataKey: "city" },
        { header: "Star Rating", dataKey: "starRating" },
        { header: "noofRooms", dataKey: "noofRooms" },
      ],
    })
    doc.save("Property List.pdf")
  }

  const handleOpen = (propertyList) => {
    history.push("/addproperty")
    var propertyId = propertyList.propertyId.replace(/['"']+/g, "")
    console.log(propertyId)
    sessionStorage.setItem("propertyId", propertyId)
  }

  const handleCreate = () => {
    history.push("/addproperty")
    sessionStorage.removeItem("propertyId")
  }
  const handleView = (data) => {
    history.push(`/konnect/hotelView/${data.propertyId}`)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - propertyLists.length) : 0

  const filteredProperty = applySortFilter(
    propertyLists,
    getComparator(order, orderBy),
    filterName
  )

  const handleSwitch = async (propertyId) => {
    await Api.get(`activateOrDeactivateProperty/${propertyId}`)
    setUpdateList(!updateList)
  }

  const isNotFound = filteredProperty.length === 0

  console.log(propertyLists.length)

  const totalDataLength = filteredProperty.length
  const dataPerPage = 7
  let noOfPages = Math.ceil(totalDataLength / dataPerPage)
  console.log("totalDataLength", totalDataLength, noOfPages)
  const [currentPageNo, setCurrentPageNo] = useState(1)
  let tempDataIndex = currentPageNo * dataPerPage //variable for min and max data range for page
  let lastDataIndex = Math.min(tempDataIndex, totalDataLength)
  let firstDataIndex = 0
  console.log("first and last indices of data:", firstDataIndex, lastDataIndex)

  const changePageNo = (value) => {
    let tempPageNo = currentPageNo + value //value can be 1 or -1 depending upon arrow clicked
    if (tempPageNo >= 1 && tempPageNo <= noOfPages) {
      setCurrentPageNo(tempPageNo)
    }
  }

  const loaderRef = useRef(null)
  const [showButton, setShowButton] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement
    const scrollPosition = window.pageYOffset

    // if (windowHeight + scrollPosition >= documentHeight) {
    //   setPage((prevPage) => prevPage + 1);
    // }

    console.log(
      "scroll height",
      Math.ceil(window.innerHeight + document.documentElement.scrollTop)
    )

    console.log("offsetHeight", document.documentElement.offsetHeight)

    scrollPosition > 300 ? setShowButton(true) : setShowButton(false)

    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) >=
      document.documentElement.offsetHeight
    ) {
      setLoading(true)

      setTimeout(() => {
        setLoading(false)
        changePageNo(1)
      }, 2000)
      console.log("ok")
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [firstDataIndex, lastDataIndex, currentPageNo, handleScroll])

  return (
    <div style={twnButtonStyles.allPagesWithoutPadding}>
      {width <= 768 ? (
        <div style={{ padding: "25% 10px 20% 10px" }}>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                name="filterName"
                value={filterName}
                variant="outlined"
                onChange={handleFilterByName}
                label="Search Your PropertyName"
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
          {showButton && (
            <IconButton
              style={{
                position: "fixed",
                top: "87%",
                left: "80%",
                rotate: "-90deg",
                backgroundColor: "black",
                color: "#f46d25",
                height: "30px",
                width: "30px",
                zIndex: 1000,
              }}
              onClick={scrollToTop}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          )}
          {filteredProperty.slice(firstDataIndex, lastDataIndex).map((row) => {
            const {
              id,
              displayName,
              city,
              starRating,
              noofRooms,
              swimingpool,
              jacuzzi,
              petFriendly,
            } = row
            return (
              <div key={id} className="propertycard">
                <div className="title">
                  <h2>{displayName}</h2>
                  <p>
                    <Rating rating={starRating} />
                  </p>
                </div>
                <div className="bookingContent">
                  <h2>Address : {city}</h2>
                  <div style={{ width: "50%", textAlign: "end" }}>
                    <button
                      style={{
                        height: "20px",
                        color: "#fff",
                        background: "#343A40",
                        border: "1px solid #343A40",
                        borderRadius: "3px",
                      }}
                      onClick={() => {
                        handleView(row)
                      }}
                    >
                      View
                    </button>
                  </div>
                </div>
                <div className="bookingContent">
                  <p>
                    <span className="circle"></span>No of Rooms : {noofRooms}
                  </p>
                  <p>
                    <span className="circle"></span>Swimming Pool :{" "}
                    {swimingpool ? "Yes" : "No"}
                  </p>
                </div>
                <div className="bookingContent">
                  <p>
                    <span className="circle"></span>Jacuzzi :{" "}
                    {jacuzzi ? "Yes" : "No"}
                  </p>
                  <p>
                    <span className="circle"></span>Pet Friendly :{" "}
                    {petFriendly ? "Yes" : "No"}
                  </p>
                </div>
              </div>
            )
          })}
          {/* <Grid item xs={12} container justifyContent="center">
            <Pagination
              count={pageCount}
              color="primary"
              onPageChange={changePage}
            />
          </Grid> */}
          <div ref={loaderRef} />
          {loading && (
            <div
              style={{
                top: 0,
                width: "100%",
                height: "5%",
                padding: "0",
                margin: "0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Loader />
            </div>
          )}
        </div>
      ) : (
        <div>
          <TableToolbarAdmin
            filterName={filterName}
            onFilterName={handleFilterByName}
            searchPlaceholderName={"Search Towno Properties"}
            tableName={"Property List"}
            open={handleCreate}
            pdfDownload={DownloadPdf}
            downloadExcel={downloadExcel}
          />
          <Card
            style={{
              boxShadow: "none",
              border: "1px solid #F4F4F4",
              padding: "0 1%",
            }}
          >
            <TableContainer sx={{ minWidth: 499 }}>
              <Table size="small">
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={propertyLists.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredProperty
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        displayName,
                        city,
                        starRating,
                        noofRooms,
                        swimingpool,
                        jacuzzi,
                        petFriendly,
                        uniqueId,
                        status,
                        propertyId,
                      } = row
                      return (
                        <TableRow hover key={id} tabIndex={-1}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ fontSize: "12px" }}
                          >
                            {displayName}
                          </TableCell>
                          <TableCell align="left" style={{ fontSize: "12px" }}>
                            {city}
                          </TableCell>
                          <TableCell align="left" style={{ fontSize: "12px" }}>
                            {starRating}
                          </TableCell>
                          <TableCell align="left" style={{ fontSize: "12px" }}>
                            {noofRooms}
                          </TableCell>
                          <TableCell align="left">
                            {swimingpool ? (
                              <CheckCircleIcon style={{ color: "green" }} />
                            ) : (
                              <CloseIcon
                                style={{
                                  color: "#201D1E",
                                  fontSize: "18px",
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {jacuzzi ? (
                              <CheckCircleIcon style={{ color: "green" }} />
                            ) : (
                              <CloseIcon
                                style={{
                                  color: "#201D1E",
                                  fontSize: "18px",
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {petFriendly ? (
                              <CheckCircleIcon style={{ color: "green" }} />
                            ) : (
                              <CloseIcon
                                style={{
                                  color: "#201D1E",
                                  fontSize: "18px",
                                }}
                              />
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {uniqueId == uniqueid &&
                            (role == "Super Admin" ||
                              role == "Admin" ||
                              role == "Data Manger" ||
                              role == "Sales") ? (
                              <Tooltip title="Edit">
                                <IconButton
                                  aria-label="edit"
                                  // onClick={() => {
                                  //   handleOpen(row);
                                  // }}
                                  onClick={() => {
                                    // sessionStorage.setItem(
                                    //   "propertyId",
                                    //   row.propertyId
                                    // );
                                    history.push(
                                      `/konnect/property/${row.propertyId}`
                                    )
                                  }}
                                  style={{
                                    padding: "0px",
                                    color: "#F46D25",
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            <Tooltip title="View">
                              <IconButton
                                aria-label="view"
                                style={{
                                  padding: "0px",
                                  marginLeft: "10px",
                                  color: "#F46D25",
                                }}
                                onClick={() => {
                                  handleView(row)
                                }}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>

                            {/* <Tooltip title="View">
                              <IconButton
                                aria-label="view"
                                style={{
                                  padding: "0px",
                                  marginLeft: "10px",
                                  color: "#F46D25",
                                }}
                                onClick={() => {
                                  sessionStorage.setItem(
                                    "propertyId",
                                    row.propertyId
                                  );
                                  history.push(`/property`);
                                }}
                              >
                                <AddAPhotoOutlined />
                              </IconButton>
                            </Tooltip> */}
                            {role === "Admin" && (
                              <Tooltip title="Edit Property Room Rent">
                                <IconButton
                                  aria-label="edit"
                                  onClick={() => {
                                    history.push(
                                      `/konnect/editPropertyRoomRent/${row.propertyId}`
                                    )
                                  }}
                                  style={{
                                    padding: "0",
                                    color: "#F46D25",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <RoomRentIcon
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}

                            {role === "Admin" && (
                              <Switch
                                color="primary"
                                inputProps={{ "aria-label": "primary" }}
                                checked={parseInt(status)}
                                size="small"
                                onChange={() => handleSwitch(propertyId)}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={9} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredProperty.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </div>
      )}
    </div>
  )
}
