import { Button, Grid, Paper, makeStyles } from "@material-ui/core";
import { parse, format, parseISO } from "date-fns";
import { enGB } from "date-fns/locale";
import Loader from "../../components/Loader/Loader";
import { useState } from "react";
import { useEffect } from "react";
import Api from "../../Service/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom";
import { twnButtonStyles } from "../../utils/townoStyle";
import { differenceInDays } from "date-fns";

const useStyles = makeStyles({
  root: {
    margin: "10% 3%",
    paddingBottom: "20%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  createButton: {
    background: "#343A40",
    fontSize: "18px",
    height: "50%",
  },
  searchField: {
    width: "100%",
  },
  select: {
    "&:before": {
      border: "none",
    },
    "&:after": {
      border: "none",
    },
  },
});

const formattedDate = (date) => {
  return parseISO(date);
};
const PackageOrQuotation = (isPackage) => {
  return isPackage ? "Package" : "Quotation";
};

const ViewPackageMobileUI = () => {
  var uniqueid = localStorage.getItem("unique_id");
  const [data, setData] = useState({});
  const [bookingDate, setBookingDate] = useState("");
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [paymentDetails, setPaymentDetails] = useState([]);
  let { pkgOrQtn, id } = useParams();
  let isPackage = pkgOrQtn === "package" ? true : false;
  const history = useHistory();

  useEffect(() => {
    Api.get(
      `/getpackageorquotationid/${uniqueid}/${PackageOrQuotation(
        isPackage
      )}/${id}`
    ).then((res) => {
      console.log("all data", res.data);
      setData(res.data);
      setBookingDate(format(formattedDate(res.data.createdAt), "d MMM, yyyy"));
      setCheckInDate(
        format(
          parse(res.data.checkIn, "dd-MM-yyyy", new Date()),
          "dd MMMM, yyyy",
          { locale: enGB }
        )
      );
      setCheckOutDate(
        format(
          parse(res.data.checkOut, "dd-MM-yyyy", new Date()),
          "dd MMMM, yyyy",
          { locale: enGB }
        )
      );
      setPaymentDetails([...paymentDetails, ...res.data.packagePaymentDetails]);
    });
  }, [id, pkgOrQtn, isPackage]);

  const classes = useStyles();

  return !Object.keys(data).length > 0 ? (
    <Loader />
  ) : (
    <div className={classes.root}>
      <Button
        onClick={() => history.goBack()}
        style={{ marginTop: "-5%", marginBottom: "3%" }}
      >
        Go Back
      </Button>
      <div
        style={{
          display: "flex",
          background: "#343A40",
          height: "fit-content",
          padding: "2%",
          borderRadius: "5px",
          marginBottom: "4%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ fontSize: "20px", color: "white", fontWeight: "bold" }}>
          {data.name}
        </div>
        <div
          style={{
            fontSize: "15px",
            background: "white",
            color: "#f46d25",
            borderRadius: "10px",
            padding: "1%",
            fontWeight: "bold",
          }}
        >
          {isPackage ? data.packageId : data.quotationId}
        </div>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <div
          style={{
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            background: "#f46d25",
            padding: "2%",
            borderRadius: "10px 10px 0px 0px",
          }}
        >
          Basic Details
        </div>
        <Paper style={{ padding: "2%", borderRadius: "0px 0px 10px 10px" }}>
          <Grid container>
            <Grid item xs={5}>
              Client Name
            </Grid>
            <Grid item xs={2} style={{ color: "#f46d25" }}>
              :
            </Grid>
            <Grid item xs={5}>
              {data.clientName}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              Destination
            </Grid>
            <Grid item xs={2} style={{ color: "#f46d25" }}>
              :
            </Grid>
            <Grid item xs={5}>
              {data.destination}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              Contact No.
            </Grid>
            <Grid item xs={2} style={{ color: "#f46d25" }}>
              :
            </Grid>
            <Grid item xs={5}>
              {data.clientMobileNo}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              Duration
            </Grid>
            <Grid item xs={2} style={{ color: "#f46d25" }}>
              :
            </Grid>
            <Grid item xs={5}>
              {differenceInDays(
                parse(data.checkOut, "dd-MM-yyyy", new Date()),
                parse(data.checkIn, "dd-MM-yyyy", new Date())
              )}{" "}
              days
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              Booking Date
            </Grid>
            <Grid item xs={2} style={{ color: "#f46d25" }}>
              :
            </Grid>
            <Grid item xs={5}>
              {bookingDate}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              Check-In Date
            </Grid>
            <Grid item xs={2} style={{ color: "#f46d25" }}>
              :
            </Grid>
            <Grid item xs={5}>
              {checkInDate}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              Check-Out Date
            </Grid>
            <Grid item xs={2} style={{ color: "#f46d25" }}>
              :
            </Grid>
            <Grid item xs={5}>
              {checkOutDate}
            </Grid>
          </Grid>
        </Paper>
      </div>
      <div div style={{ marginBottom: "10px" }}>
        <div
          style={{
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            background: "#f46d25",
            padding: "2%",
            borderRadius: "10px 10px 0px 0px",
          }}
        >
          Hotel Details
        </div>
        <Paper style={{ padding: "2%", borderRadius: "0px 0px 10px 10px" }}>
          {data &&
            data.hotelDetails.map((detail) => (
              <Grid container>
                <Grid container>
                  <Grid item xs={5}>
                    Hotel Name
                  </Grid>
                  <Grid item xs={2} style={{ color: "#f46d25" }}>
                    :
                  </Grid>
                  <Grid item xs={5}>
                    {detail.hotelName}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={5}>
                    Star rating
                  </Grid>
                  <Grid item xs={2} style={{ color: "#f46d25" }}>
                    :
                  </Grid>
                  <Grid item xs={5}>
                    {detail.hotelCategory.slice(0, 1)}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={5}>
                    Rooms
                  </Grid>
                  <Grid item xs={2} style={{ color: "#f46d25" }}>
                    :
                  </Grid>
                  <Grid item xs={5}>
                    {detail.noOfRooms}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={5}>
                    Nights
                  </Grid>
                  <Grid item xs={2} style={{ color: "#f46d25" }}>
                    :
                  </Grid>
                  <Grid item xs={5}>
                    {detail.noOfNights}
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Paper>
      </div>
      <div div style={{ marginBottom: "10px" }}>
        <div
          style={{
            color: "white",
            fontSize: "20px",
            fontWeight: "bold",
            background: "#f46d25",
            padding: "2%",
            borderRadius: "10px 10px 0px 0px",
          }}
        >
          Payment Details
        </div>
        <Paper style={{ padding: "2%", borderRadius: "0px 0px 10px 10px" }}>
          <Grid container>
            <Grid item xs={5}>
              Total Gross Amount
            </Grid>
            <Grid item xs={2} style={{ color: "#f46d25" }}>
              :
            </Grid>
            <Grid item xs={5}>
              {data && data.totalGrossAmount}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              Total Amount Paid
            </Grid>
            <Grid item xs={2} style={{ color: "#f46d25" }}>
              :
            </Grid>
            <Grid item xs={5}>
              {}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              Commission
            </Grid>
            <Grid item xs={2} style={{ color: "#f46d25" }}>
              :
            </Grid>
            <Grid item xs={5}>
              {data && data.revisedCommission}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              Payment Date
            </Grid>
            <Grid item xs={2} style={{ color: "#f46d25" }}>
              :
            </Grid>
            <Grid item xs={5}>
              {data && checkInDate}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              Payment Mode
            </Grid>
            <Grid item xs={2} style={{ color: "#f46d25" }}>
              :
            </Grid>
            <Grid item xs={5}>
              {}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5}>
              Reference
            </Grid>
            <Grid item xs={2} style={{ color: "#f46d25" }}>
              :
            </Grid>
            <Grid item xs={5}>
              {paymentDetails && paymentDetails.paymentRefNo}
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default ViewPackageMobileUI;
