import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Modal,
  makeStyles,
} from "@material-ui/core"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import { toast } from "react-toastify"
import API from "../../../Service/Api"
import {
  switchClonePropertyDialogOpen,
  switchCloneTrmAndCdnDialogOpen,
} from "../../../redux/actions/clonePropertyAmenities.action"
import { CloseOutlined } from "@material-ui/icons"

import Select from "react-select"

const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontSize: "17px",
    fontWeight: "bold",
    paddingBottom: "7px",
  },
  innerSectionStyles: {
    border: "1px solid #C4C4C4",
    padding: "10px",
    borderRadius: "5px",
  },
  outerSectionStyles: {
    marginTop: "19px",
  },
}))

const PropertyTermsConditionsAndPolicies = () => {
  const classes = useStyles()
  // const { termsAndConditions, cancellationPolicy, mealplanPolicy } =
  //   useSelector(({ hotelLogin }) => hotelLogin);
  const dispatch = useDispatch()
  const { propertyId } = useParams()
  const unique_id = localStorage.getItem("unique_id")
  const auth = localStorage.getItem("auth")

  const [fullResponseBody, setFullResponseBody] = useState(() => {})

  const [termsAndConditionsMessage, setTermsAndConditionsMessage] = useState(
    () => ""
  )
  const [cancellationPolicyMessage, setCancellationPolicyMessage] = useState(
    () => ""
  )
  const [mealplanPolicyMessage, setMealplanPolicyMessage] = useState(() => "")

  // console.log(termsAndConditions);
  // console.log(mealplanPolicy);
  // console.log(cancellationPolicy);

  // useEffect(() => {
  //   if (propertyId) {
  //     dispatch(hotelLoginTermsAndConditions(propertyId));
  //     dispatch(hotelLoginCancellationPolicy(propertyId));
  //     dispatch(hotelLoginMealplanPolicy(propertyId));
  //   }
  // }, [propertyId]);

  const [propertyDropDown, setPropertyDropDown] = useState([])
  const [propertyOption, setPropertyOption] = useState(null)
  const [cloning, setCloning] = useState(false)

  const { openT_and_C_Dialog } = useSelector(
    ({ clonePropertyAmenitiesReducer }) => clonePropertyAmenitiesReducer
  )

  const fetchPropertyDropdown = async () => {
    try {
      const { data } = await API.get(
        `propertydisplaynameall/${localStorage.getItem("unique_id")}`
      )
      const options = data?.map((option) => ({
        label: option?.displayName,
        value: option?.propertyId,
      }))
      console.log(options)
      setPropertyDropDown(options)
    } catch (error) {
      toast.error("unable to fetch the property list")
    }
  }

  useEffect(() => {
    fetchPropertyDropdown()
  }, [])

  const fetchTermsConditionsAndMealplan = async () => {
    setLoading(true)
    try {
      const res = await API.get(`condtionAndPolicyBulkFetch/${propertyId}`)
      console.log(res)

      setFullResponseBody(res.data)

      setTermsAndConditionsMessage(
        _.isNull(res.data.termsAndCondition.message)
          ? ""
          : res.data.termsAndCondition.message
      )
      setCancellationPolicyMessage(
        _.isNull(res.data.cancelationPolicy.message)
          ? ""
          : res.data.cancelationPolicy.message
      )
      setMealplanPolicyMessage(
        _.isNull(res.data.mealPlanTermsAndConditions.message)
          ? ""
          : res.data.mealPlanTermsAndConditions.message
      )
    } catch (error) {
      toast.error("Please fill the fields")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (propertyId) {
      fetchTermsConditionsAndMealplan()
    }
  }, [propertyId])

  const handleCkeditorTermsAndConditions = (event, editor) => {
    const data = editor.getData()
    setTermsAndConditionsMessage(data)
  }

  const handleCkeditorCancellationPolicy = (event, editor) => {
    const data = editor.getData()
    setCancellationPolicyMessage(data)
  }

  const handleCkeditorMealplanPolicy = (event, editor) => {
    const data = editor.getData()
    setMealplanPolicyMessage(data)
  }

  const [loading, setLoading] = useState(() => false)

  const cloneTermsConditionsAndMealplanPolicy = async () => {
    try {
      const { data } = await API.get(
        `condtionAndPolicyBulkFetch/${propertyOption.value}`
      )
      console.log(data)
      return data
    } catch (error) {
      toast.error("Failed to fetch the data")
    }
  }

  const cloneData = async () => {
    setLoading(true)
    try {
      const cloneData = await cloneTermsConditionsAndMealplanPolicy()

      const {
        cancelationPolicy,
        termsAndCondition,
        mealPlanTermsAndConditions,
      } = cloneData

      console.log({
        cancelationPolicy,
        termsAndCondition,
        mealPlanTermsAndConditions,
      })
      const postData = {
        termsAndCondition: {
          uniqueId: unique_id,
          propertyId: propertyId,
          message: _.isNull(termsAndCondition.message)
            ? ""
            : termsAndCondition.message,
        },
        cancelationPolicy: {
          uniqueId: unique_id,
          propertyId: propertyId,
          message: _.isNull(cancelationPolicy.message)
            ? ""
            : cancelationPolicy.message,
        },
        mealPlanTermsAndConditions: {
          uniqueId: unique_id,
          propertyId: propertyId,
          message: _.isNull(mealPlanTermsAndConditions.message)
            ? ""
            : mealPlanTermsAndConditions.message,
        },
      }
      console.log(postData)
      const response = await API.post(`condtionAndPolicyBulkCreate`, postData)

      if (response.status == 200) {
        toast.success("Created")
        fetchTermsConditionsAndMealplan()
      } else {
        toast.error(response.statusText)
      }

      window.open(
        `/konnect/property/${propertyId}/propertyTermsAndConditions`,
        "_self"
      )
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  // useEffect(() => {
  //   if (!_.isNull(propertyOption)) {
  //     cloneTermsConditionsAndMealplanPolicy();
  //   }
  // }, [propertyOption]);

  const createTermsConditionsAndMealplanPolicy = async () => {
    setLoading(true)
    try {
      const postData = {
        termsAndCondition: {
          uniqueId: unique_id,
          propertyId: propertyId,
          message: termsAndConditionsMessage,
        },
        cancelationPolicy: {
          uniqueId: unique_id,
          propertyId: propertyId,
          message: cancellationPolicyMessage,
        },
        mealPlanTermsAndConditions: {
          uniqueId: unique_id,
          propertyId: propertyId,
          message: mealplanPolicyMessage,
        },
      }
      console.log(postData)
      const response = await API.post(`condtionAndPolicyBulkCreate`, postData)
      if (response.status == 200) {
        toast.success("Created")
      } else {
        toast.error(response.statusText)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const updateTermsConditionsAndMealplanPolicy = async () => {
    setLoading(true)
    try {
      const postData = {
        cancelationPolicy: {
          id: fullResponseBody?.cancelationPolicy?.id,
          message: cancellationPolicyMessage,
          updatedBy: fullResponseBody?.cancelationPolicy?.updatedBy ?? auth,
          uniqueId: unique_id,
          createdBy: fullResponseBody?.cancelationPolicy?.createdBy,
          propertyId: propertyId,
          status: "1",
        },
        termsAndCondition: {
          id: fullResponseBody?.termsAndCondition?.id,
          message: termsAndConditionsMessage,
          updatedBy: auth,
          uniqueId: unique_id,
          createdBy: fullResponseBody?.termsAndCondition?.createdBy,
          propertyId: propertyId,
          status: "1",
        },
        mealPlanTermsAndConditions: {
          id: fullResponseBody?.mealPlanTermsAndConditions?.id,
          message: mealplanPolicyMessage,
          updatedBy: auth,
          uniqueId: unique_id,
          createdBy: fullResponseBody?.mealPlanTermsAndConditions?.createdBy,
          propertyId: propertyId,
          status: "1",
        },
      }
      console.log({ postData })
      const response = await API.put("condtionAndPolicyBulkUpdate", postData)
      if (response.status == 200) {
        toast.success("Update SuccessFull")
        fetchTermsConditionsAndMealplan()
      } else {
        toast.error(response.statusText)
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      // const {
      //   termsAndCondition,
      //   cancelationPolicy,
      //   mealPlanTermsAndConditions,
      // } = fullResponseBody && fullResponseBody;
      if (
        fullResponseBody?.termsAndCondition?.id ||
        fullResponseBody?.cancelationPolicy?.id ||
        fullResponseBody?.mealPlanTermsAndConditions?.id
      ) {
        updateTermsConditionsAndMealplanPolicy()
      } else {
        createTermsConditionsAndMealplanPolicy()
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <section className={classes.outerSectionStyles}>
          <header className={classes.sectionHeader}>Terms & Conditions</header>
          <section className={classes.innerSectionStyles}>
            <Grid container>
              <Grid item xs={12}>
                <CKEditor
                  editor={ClassicEditor}
                  type="inline"
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "blockQuote",
                      "link",
                      "numberedList",
                      "bulletedList",
                      "mediaEmbed",
                      "|",
                      "undo",
                      "redo",
                    ],
                  }}
                  name="termsAndConditions"
                  onChange={handleCkeditorTermsAndConditions}
                  value={termsAndConditionsMessage}
                  content={termsAndConditionsMessage}
                  data={termsAndConditionsMessage}
                  required
                />
              </Grid>
            </Grid>
          </section>
        </section>
        <section className={classes.outerSectionStyles}>
          <header className={classes.sectionHeader}>Cancellation Policy</header>
          <section className={classes.innerSectionStyles}>
            <Grid container>
              <Grid item xs={12}>
                <CKEditor
                  editor={ClassicEditor}
                  type="inline"
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "blockQuote",
                      "link",
                      "numberedList",
                      "bulletedList",
                      "mediaEmbed",
                      "|",
                      "undo",
                      "redo",
                    ],
                  }}
                  name="cancellationPolicy"
                  onChange={handleCkeditorCancellationPolicy}
                  value={cancellationPolicyMessage}
                  content={cancellationPolicyMessage}
                  data={cancellationPolicyMessage}
                  required
                />
              </Grid>
            </Grid>
          </section>
        </section>
        <section className={classes.outerSectionStyles}>
          <header className={classes.sectionHeader}>Meal Plan Policy</header>
          <section className={classes.innerSectionStyles}>
            <Grid container>
              <Grid item xs={12}>
                <CKEditor
                  editor={ClassicEditor}
                  type="inline"
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "blockQuote",
                      "link",
                      "numberedList",
                      "bulletedList",
                      "mediaEmbed",
                      "|",
                      "undo",
                      "redo",
                    ],
                  }}
                  name="mealPlanPolicy"
                  onChange={handleCkeditorMealplanPolicy}
                  value={mealplanPolicyMessage}
                  content={mealplanPolicyMessage}
                  data={mealplanPolicyMessage}
                  required
                />
              </Grid>
            </Grid>
          </section>
        </section>
        <center>
          <Button style={{ height: "30px", margin: "10px 0px" }} type="submit">
            Save
          </Button>
        </center>
      </form>
      <Modal
        open={openT_and_C_Dialog}
        onClose={() => dispatch(switchCloneTrmAndCdnDialogOpen())}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card style={{ width: "400px", height: "auto" }}>
          <CardHeader
            title={
              <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                Select property to clone .
              </div>
            }
            action={
              <IconButton
                title="close dialog"
                size="small"
                style={{ border: "1px solid #f46d25" }}
                onClick={() => dispatch(switchCloneTrmAndCdnDialogOpen())}
              >
                <CloseOutlined
                  color="primary"
                  style={{ height: "15px", width: "15px" }}
                />
              </IconButton>
            }
            subheader={<small>**clones T&C of selected property</small>}
          />
          <CardContent>
            <Select
              placeholder="Select Any Property"
              value={propertyOption}
              options={propertyDropDown}
              onChange={(option) => setPropertyOption(option)}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  width: "350px",
                }),
                container: (provided, state) => ({
                  ...provided,
                  position: "absolute",
                }),
              }}
            />
            <center style={{ position: "relative", marginTop: "40px" }}>
              <Button
                size="small"
                style={{ marginTop: "20px", height: "30px" }}
                disabled={
                  propertyOption == "null" ||
                  propertyOption == undefined ||
                  loading
                }
                onClick={cloneData}
              >
                Clone T&C
              </Button>
            </center>
          </CardContent>
        </Card>
      </Modal>
    </>
  )
}

export default PropertyTermsConditionsAndPolicies
