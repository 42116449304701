import {
  Card,
  Dialog,
  Grid,
  Slide,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import React, { useReducer, useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { twnButtonStyles } from "../../utils/townoStyle"
import Api from "../../Service/Api"
import PreviewOnboard from "./PreviewOnboard"
import Select from "react-select"

const onBoardReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value,
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

export default function CreateUpdateOnboard() {
  const { id } = useParams()
  const [createdBy, setCreatedBy] = useState("")
  const [travelAgencyName, setTravelAgencyName] = useState("")
  const [city, setCity] = useState("")
  const [agentRefferedBy, setAgentRefferedBy] = useState("")
  const [venueDetails, setVenueDetails] = useState("")
  const [type, setType] = useState("")
  const [name, setName] = useState("")
  const [designation, setDesignation] = useState("")
  const [mobile1, setMobile1] = useState("")
  const [mobile2, setMobile2] = useState("")
  const [phone, setPhone] = useState("")
  const [mail, setMail] = useState("")
  const [mail1, setMail1] = useState("")
  const [mail2, setMail2] = useState("")
  const [mail3, setMail3] = useState("")
  const [websiteAddress, setWebsiteAddress] = useState("")
  const [promotingDestination, setPromotingDestination] = useState("")
  const [areaOfOperation, setAreaOfOperation] = useState("")
  const [onboardData, setOnboardData] = useState({
    id: "",
    createdBy: "",
    travelAgencyName: "",
    city: "",
    agentRefferedBy: "",
    venueDetails: "",
    type: "",
    name: "",
    designation: "",
    mobile1: "",
    mobile2: "",
    phone: "",
    mail: "",
    mail1: "",
    mail2: "",
    mail3: "",
    websiteAddress: "",
    promotingDestination: "",
    areaOfOperation: "",
  })

  const [openPreview, setOpenPreview] = useState(false)

  const onSubmitFun = () => {
    let postData = { ...onboardData }
    Api.post("travelagentonboarding", postData).then((res) => {
      console.log(res)
      window.open("/konnect/agencyOnboarding", "_self")
    })
  }

  function handleSelectChange(name, value) {
    let postData = onboardData
    postData[name] = value
    setOnboardData(postData)
  }

  const typeOptions = [
    { value: "Hotel", label: "Hotel" },
    { value: "Agent B2B Login Supplier", label: "Agent B2B Login Supplier" },
    { value: "Travel Agent", label: "Travel Agent" },
    {
      value: "DMC / Aggregation International",
      label: "DMC / Aggregation International",
    },
    {
      value: "DMC / Aggregat or Domestic",
      label: "DMC / Aggregat or Domestic",
    },
    {
      value: "Travel Agent Owning Hotels and Promoting Destinations",
      label: "Travel Agent Owning Hotels and Promoting Destinations",
    },
  ]

  const AgentRefferedByOptions = [
    { value: "RB", label: "RB" },
    { value: "Deepa", label: "Deepa" },
    { value: "Neha", label: "Neha" },
    { value: "Prasoon", label: "Prasoon" },
    { value: "Devashish", label: "Devashish" },
    { value: "Archana", label: "Archana" },
    { value: "Deepak", label: "Deepak" },
    { value: "Shailesh", label: "Shailesh" },
    { value: "Raj", label: "Raj" },
  ]

  const VenueDetailsOptions = [
    { value: "OTM", label: "OTM" },
    { value: "SATTE", label: "SATTE" },
    { value: "TTF Bangalore", label: "TTF Bangalore" },
    { value: "TTF Chennai", label: "TTF Chennai" },
    { value: "GTF", label: "GTF" },
  ]

  const DesignationOptions = [
    { value: "Owner", label: "Owner" },
    { value: "Sales Staff", label: "Sales Staff" },
    { value: "Director", label: "Director" },
    { value: "HOD", label: "HOD" },
    { value: "General Manager", label: "General Manager" },
    { value: "Travel Agent Executive", label: "Travel Agent Executive" },
    { value: "Manager", label: "Manager" },
  ]

  const selectStyle = {
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    control: (base, state) => ({
      ...base,
      "&:hover": { borderColor: "#f46d25" },
      boxShadow: "none",
      width: "100%",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: "absolute",
      top: state.hasValue || state.selectProps.inputValue ? -4 : "50%",
      background: "#fff",
      padding: "0px 5px",
      transition: "top 0.1s, font-size 0.1s",
      fontSize: "14px",
    }),
    container: (provided) => ({
      ...provided,
    }),
    valueContainer: (provided) => ({
      ...provided,
      overflow: "visible",
    }),
  }
  useEffect(() => {
    if (id) {
      Api.get(`travelagentonboardingdetail/${id}`).then((res) => {
        let postData = onboardData
        Object.entries(res.data).forEach(([key, value]) => {
          postData[key] = value
        })
        setOnboardData(postData)
        setTravelAgencyName(res.data.travelAgencyName)
        setCity(res.data.city)
        setAgentRefferedBy(res.data.agentRefferedBy)
        setVenueDetails(res.data.venueDetails)
        setType(res.data.type)
        setName(res.data.name)
        setDesignation(res.data.designation)
        setMobile1(res.data.mobile1)
        setMobile2(res.data.mobile2)
        setPhone(res.data.phone)
        setMail(res.data.mail)
        setMail1(res.data.mail1)
        setMail2(res.data.mail2)
        setMail3(res.data.mail3)
        setWebsiteAddress(res.data.websiteAddress)
        setPromotingDestination(res.data.promotingDestination)
        setAreaOfOperation(res.data.areaOfOperation)
      })
    }
  }, [])

  return (
    <div style={{ ...twnButtonStyles.allPages, paddingTop: "5%" }}>
      <Typography component="h4" style={twnButtonStyles.xlFonts}>
        {id ? "Update" : "Create"} Onboard
      </Typography>
      <br />
      <Card>
        <div
          style={{
            backgroundColor: "#f46d25",
            color: "#fff",
            padding: "0.5em 1em",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Basic Details
        </div>
        <Grid container spacing={3} style={{ padding: "2% 2% 0.5% 2%" }}>
          <Grid item xs={4}>
            <CustomTextInlineField
              name="travelAgencyName"
              fullWidth
              label="Company / Hotel Name"
              value={travelAgencyName}
              onChange={(e) => {
                setTravelAgencyName(e.target.value)
                handleSelectChange("travelAgencyName", e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextInlineField
              name="city"
              label="City"
              fullWidth
              value={city}
              onChange={(e) => {
                setCity(e.target.value)
                handleSelectChange("city", e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              name="agentRefferedBy"
              label="Agent Reffered By"
              placeholder="Agent Reffered By"
              value={AgentRefferedByOptions.filter(
                (i) => i.value === agentRefferedBy
              )}
              defaultValue={agentRefferedBy}
              options={AgentRefferedByOptions}
              onChange={(e) => {
                setAgentRefferedBy(e.value)
                handleSelectChange("agentRefferedBy", e.value)
              }}
              styles={selectStyle}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ padding: "1% 2%" }}>
          <Grid item xs={4}>
            <Select
              name="venueDetails"
              label="Venue Details"
              placeholder="Venue Details"
              value={VenueDetailsOptions.filter(
                (i) => i.value === venueDetails
              )}
              defaultValue={venueDetails}
              options={VenueDetailsOptions}
              onChange={(e) => {
                setVenueDetails(e.value)
                handleSelectChange("venueDetails", e.value)
              }}
              styles={selectStyle}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextInlineField
              name="areaOfOperation"
              label="Area of Operation"
              fullWidth
              value={areaOfOperation}
              onChange={(e) => {
                setAreaOfOperation(e.target.value)
                handleSelectChange("areaOfOperation", e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              name="type"
              label="Type"
              placeholder="Type"
              value={typeOptions.filter((i) => i.value === type)}
              defaultValue={type}
              options={typeOptions}
              onChange={(e) => {
                setType(e.value)
                handleSelectChange("type", e.value)
              }}
              styles={selectStyle}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ padding: "1% 2% 2% 2%" }}>
          <Grid item xs={4}>
            <CustomTextInlineField
              name="name"
              label="Name"
              fullWidth
              value={name}
              onChange={(e) => {
                setName(e.target.value)
                handleSelectChange("name", e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              name="designation"
              label="Designation"
              placeholder="Designation"
              value={DesignationOptions.filter((i) => i.value === designation)}
              defaultValue={designation}
              options={DesignationOptions}
              onChange={(e) => {
                setDesignation(e.value)
                handleSelectChange("designation", e.value)
              }}
              styles={selectStyle}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextInlineField
              name="mobile1"
              label="Mobile 1"
              fullWidth
              value={mobile1}
              onChange={(e) => {
                setMobile1(e.target.value)
                handleSelectChange("mobile1", e.target.value)
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ padding: "1% 2% 2% 2%" }}>
          <Grid item xs={4}>
            <CustomTextInlineField
              name="mobile2"
              label="Mobile 2"
              fullWidth
              value={mobile2}
              onChange={(e) => {
                setMobile2(e.target.value)
                handleSelectChange("mobile2", e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextInlineField
              name="phone"
              fullWidth
              label="Landline (if any)"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value)
                handleSelectChange("phone", e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextInlineField
              name="mail"
              label="Email"
              fullWidth
              value={mail}
              onChange={(e) => {
                setMail(e.target.value)
                handleSelectChange("mail", e.target.value)
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ padding: "1% 2% 2% 2%" }}>
          <Grid item xs={4}>
            <CustomTextInlineField
              name="mail1"
              label="Mail 1"
              fullWidth
              value={mail1}
              onChange={(e) => {
                setMail1(e.target.value)
                handleSelectChange("mail1", e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextInlineField
              name="mail2"
              fullWidth
              label="Mail 2"
              value={mail2}
              onChange={(e) => {
                setMail2(e.target.value)
                handleSelectChange("mail2", e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextInlineField
              name="mail3"
              label="Mail 3"
              fullWidth
              value={mail3}
              onChange={(e) => {
                setMail3(e.target.value)
                handleSelectChange("mail3", e.target.value)
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ padding: "1% 2% 2% 2%" }}>
          <Grid item xs={4}>
            <CustomTextInlineField
              name="websiteAddress"
              fullWidth
              label="Website Address"
              value={websiteAddress}
              onChange={(e) => {
                setWebsiteAddress(e.target.value)
                handleSelectChange("websiteAddress", e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomTextInlineField
              name="promotingDestination"
              label="Promoting Destination"
              fullWidth
              value={promotingDestination}
              onChange={(e) => {
                setPromotingDestination(e.target.value)
                handleSelectChange("promotingDestination", e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Card>
      <br />
      <Stack direction="row" spacing={2} justifyContent="center">
        <button
          style={twnButtonStyles.orangeBtn}
          onClick={() => {
            setOpenPreview(true)
          }}
        >
          Preview
        </button>
        <Link
          to={{ pathname: "/konnect/agencyOnboarding" }}
          style={twnButtonStyles.linkBlackBtn}
        >
          Cancel
        </Link>
      </Stack>

      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        sx={{ maxHeight: "100vh" }}
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        TransitionComponent={Transition}
      >
        <div>
          <PreviewOnboard onboardData={onboardData} />
        </div>
        <div style={{ backgroundColor: "#eee" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            style={{ margin: "1%" }}
          >
            <button style={twnButtonStyles.orangeBtn} onClick={onSubmitFun}>
              Submit
            </button>
            <button
              style={twnButtonStyles.blackBtn}
              autoFocus
              color="inherit"
              onClick={() => setOpenPreview(false)}
            >
              Edit
            </button>
          </Stack>
        </div>
      </Dialog>
    </div>
  )
}
function CustomTextInlineField(props) {
  return (
    <TextField
      name={props.name}
      fullWidth={props.fullWidth}
      label={props.label}
      value={props.value}
      variant="outlined"
      size="small"
      onChange={props.onChange}
      sx={{
        "& .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root": {
          color: "#f46d25",
        },
      }}
    />
  )
}
