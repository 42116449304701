import styles from "./DestinationKeyFacts.module.css"
import globalStyles from "../../../../globalStyles.module.css"
import { useContext, useLayoutEffect } from "react"
import { DestinationLayoutContext } from "../../../../layout/DestinationLayout/Destination.layout"
import QueryForm from "../../../../components/QueryForm/QueryForm.component"
import ContactInfo from "../../../../components/ContactInfo/ContactInfo.component"
import AsideDestinationNavbar from "../../../../components/AsideDestinationNavbar/AsideDestinationNavbar.component"
import ImageAndContentCard from "../../../../components/ImageAndContentCard/ImageAndContentCard.component"
import parser from "html-react-parser"
import BiggerGroupBannerPost from "../../../../components/BiggerGroupBanner/BiggerGroupBanner.component"

import Image1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Monsoon in Uttarakhand.jpg"
import Image2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Shopping on Mall Road Mussoorie1.jpg"
import Image3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Rishikesh.jpg"
import DestinationSubLayout from "../../../../layout/DestinationSubLayout/DestinationSub.layout"

const keyImages = [Image1, Image2, Image3]

const keyFacts = [
  {
    title: "Area",
    content: `A key fact about Uttarakhand is that it is spread over a sprawling area of 53,483 km2. Another unique fact about Uttarakhand is that about 86% of the state is covered with mountains and approximately 65% of it is covered by forest. The northern part of the state is distinguished with Himalayan peaks and prominent glaciers.`,
  },
  {
    title: "Capital City",
    content: `The capital of Uttarakhand is Dehradun, which is located in the Garhwal region, and is located about 236 km from Delhi.`,
  },
  {
    title: "Main Cities",
    content: `Other than Dehradun, the capital of Uttarakhand, few key Uttarakhand facts and figures regarding to the major cities are that there are 13 administrative districts in Uttarakhand which are then further grouped into two divisions, Kumaon and Garhwal for governance. The key cities of Uttarakhand are Badrinath, Kedarnath and Haridwar, all three cities that are popular pilgrimages site amongst Hindus. The other significant cities are Nainital, Haridwar, Roorkee, Haldwani, Rudrapur, Kashipur, Rishikesh and Almora.
`,
  },
  {
    title: "World Heritage Sites",
    content: `Nanda Devi and Valley of Flowers National Park located in the western Himalayas has been recognized by UNESCO as a world heritage site. It has unmatched natural beauty and has both historical as well as geographical significance.`,
  },
  {
    title: " Population",
    content: `Another Uttarakhand quick fact is that it has a population of 10,086,292, which makes it the 20th most populous state in India`,
  },
  { title: "Density", content: `189 people per km2` },
  {
    title: "Religion",
    content: `Majority of Uttarakhand's residents are Hindus. Other dominant religions are Muslims, Sikhs, Christians, Buddhists, and Jains.`,
  },
  {
    title: " Weather",
    content: `An important fact about Uttarakhand is that its weather varies across the year. During April to June, in the summer season, temperature ranges from 25°C and 35°C in Uttarakhand. During monsoon the state receives heavy rainfall, often causing landslides and floods. During the winter season from October to March, the state looks spectacular, especially when snows. The temperature ranges between 0°C and 15°C and even below zero in the north.`,
  },
  {
    title: " When To Visit",
    content: `The best time to visit the hill stations is the summer. Winter is also an excellent time to get closer to snow-capped mountains and get a taste of winter sports.`,
  },
  {
    title: "Currency",
    content: `Indian rupees (₹)`,
  },
  {
    title: "Languages",
    content: `The official language is Hindi, while Garhwali, Kumaoni and Jaunsari are other regional dialects. A hidden fact about Uttarakhand is that it is the only state in India which has Sanskrit as its second official language after Hindi.`,
  },
  {
    title: "Emergency Contact Numbers",
    content: `<ul>
 <li>Police: 100</li>
<li>Ambulance And Medical: 102</li>
<li>Women Helpline: 1090</li>
<li>Disaster/Medical Helpline: 108</li>
<li>District Emergency Operation Centre: 1077</li>
<li>Disaster (Earthquake/Flood/Cyclone): 022-22027990</li>
        </ul>`,
  },
  {
    title: " Fun Facts About Destination",
    content: `One of the Uttarakhand interesting facts is that its State flower is the Brahma Kamal or white lotus which is according to legend held by Lord Brahma. Another fun fact about Uttarakhand is that the Beatles spent close to three months in Rishikesh in 1968 and the Beatles Ashram still stands as memory to their visit.`,
  },
]

const DestinationKeyFacts = () => {
  const { setBanner } = useContext(DestinationLayoutContext)

  useLayoutEffect(() => {
    setBanner("keyFacts")
  }, [])

  return (
    <DestinationSubLayout>
      <main className={globalStyles.main}>
        <article className={globalStyles.topMostSection}>
          <header>
            Key <span>Facts</span>
          </header>
          <div className={globalStyles.content}>
            Did you know that Uttarakhand which was previously known as
            Uttaranchal came into existence only in the year 2000? There are
            many unique things about Uttarakhand that make it the perfect
            tourist destination. Even though it is widely known as Land of the
            Gods, there an interesting fact about Uttarakhand is that its hiking
            trails, wildlife sanctuaries and lush green meadows are equally
            attractive tourist destinations.
            <p>
              Here are a few amazing facts about Uttarakhand you should
              definitely know if you’re planning a trip to Uttarakhand:
            </p>
          </div>
          <div className={styles.imageList}>
            {keyImages.map((_, i) => (
              <div className={styles.imageCard} key={i + 1}>
                <img src={_} />
              </div>
            ))}
          </div>
        </article>
        <article className={styles.keyFactsList}>
          {keyFacts.map((fact, idx) => (
            <ImageAndContentCard key={fact.title}>
              <ImageAndContentCard.Title>
                {idx + 1}. {fact.title}
              </ImageAndContentCard.Title>
              <ImageAndContentCard.Content>
                {parser(fact.content)}
              </ImageAndContentCard.Content>
            </ImageAndContentCard>
          ))}
        </article>
        <BiggerGroupBannerPost />
      </main>
    </DestinationSubLayout>
  )
}

export default DestinationKeyFacts
