import React, { useState } from "react";
import {
  TextField,
  Grid,
  Typography,
  TextareaAutosize,
  Stack,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Select, { components } from "react-select";
import DateFnsUtils from "@date-io/date-fns";

const FlightTripOption = [
  { label: "Oneway", value: "Oneway" },
  { label: "Round", value: "Round" },
];

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const selectStyle = {
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  control: (base, state) => ({
    ...base,
    "&:hover": { borderColor: "#f46d25" },
    borderColor: "#f46d25",
    boxShadow: "none",
    width: "100%",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: "absolute",
    top: state.hasValue || state.selectProps.inputValue ? -4 : "50%",
    background: "#fff",
    padding: "0px 5px",
    transition: "top 0.1s, font-size 0.1s",
    fontSize: "14px",
  }),
  container: (provided) => ({
    ...provided,
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: "visible",
  }),
};
export default function AgentFlightForm({
  flightInput,
  setFlightInput,
  flightInputError,
  setFlightInputError,
  classes,
}) {
  const validateField = (name, value) => {
    var error = "";

    switch (name) {
      case "flightFrom":
        error = value.length < 3 ? "Required" : "";
        break;
      case "flightTo":
        error = value.length < 3 ? "Required" : "";
        break;
      case "flightAdults":
        error = /^\+?\d+$/.test(value) ? "" : "Enter a valid positive no.";
        break;
      case "flightAmount":
        error = /^\+?\d+$/.test(value) ? "" : "Enter valid amount";
        break;
    }
    return error;
  };
  const handleRemoveClickFlight = (index) => {
    const list = [...flightInput];
    list.splice(index, 1);
    setFlightInput(list);

    const listError = [...flightInputError];
    listError.splice(index, 1);
    setFlightInputError(listError);
  };
  const handleAddClickFlight = () => {
    setFlightInput([
      ...flightInput,
      {
        id: "",
        flightTrip: "",
        flightName: "",
        flightFrom: "",
        flightTo: "",
        flightDepartDate: "",
        flightReturnDate: "",
        flightPnr: "",
        flightAdults: " ",
        flightChild: " ",
        flightInclusion: " ",
        flightAmount: "",
        flightComission: "",
      },
    ]);

    setFlightInputError([
      ...flightInputError,
      {
        id: "",
        flightTrip: "",
        flightName: "",
        flightFrom: "",
        flightTo: "",
        flightDepartDate: "",
        flightReturnDate: "",
        flightPnr: "",
        flightAdults: " ",
        flightChild: " ",
        flightInclusion: " ",
        flightAmount: "",
        flightComission: "",
      },
    ]);
  };
  const handleFlightChange = (e, index) => {
    const { name, value } = e.target;
    console.log("handleFlightChange", name, " ", value);
    const list = [...flightInput];
    list[index][name] = value;
    setFlightInput(list);

    const listError = [...flightInputError];
    const error = validateField(name, value.trim());
    listError[index][name] = error;
    setFlightInputError(listError);
  };

  const handleFlightSelectChange = (name, value, index) => {
    const list = [...flightInput];
    list[index][name] = value;
    setFlightInput(list);
  };

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Stack style={{ padding: "0.8em 0.5em" }}>
          {flightInput.map((x, i) => (
            <>
              <Grid container columnSpacing={1.5} rowSpacing={1} key={i}>
                <Grid item sm={6}>
                  {i == 0 && (
                    <p
                      style={{
                        fontSize: "18px",
                        margin: "0",
                        color: "#f46d25",
                        fontWeight: "500",
                      }}
                    >
                      Flight Details
                    </p>
                  )}
                </Grid>
                <Grid item sm={6} style={{ textAlign: "end" }}>
                  {flightInput.length !== 1 && (
                    <DeleteIcon
                      onClick={() => handleRemoveClickFlight(i)}
                      className={classes.plus}
                    />
                  )}
                  {flightInput.length - 1 === i && (
                    <AddCircleOutlineIcon
                      onClick={handleAddClickFlight}
                      size="small"
                      className={classes.plus}
                    />
                  )}
                </Grid>
                <div
                  style={{
                    width: "2%",
                    textAlign: "center",
                    marginTop: "1em",
                    fontSize: "14px",
                  }}
                >
                  {i + 1 + "."}
                </div>
                <div style={{ width: "98%", marginTop: "1em" }}>
                  <Stack direction="row" width="100%" spacing={2}>
                    <div style={{ width: "100%", borderColor: "#f46d25" }}>
                      <Select
                        name="flightTrip"
                        placeholder="Trip"
                        value={FlightTripOption.filter(
                          (i) => i.value === x.flightTrip
                        )}
                        options={FlightTripOption}
                        onChange={(e) =>
                          handleFlightSelectChange("flightTrip", e.value, i)
                        }
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        styles={selectStyle}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="flightName"
                        label="Flight Name"
                        value={x.flightName}
                        onChange={(e) => {
                          handleFlightChange(e, i);
                        }}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="flightFrom"
                        label="From"
                        required
                        value={x.flightFrom}
                        onChange={(e) => {
                          handleFlightChange(e, i);
                        }}
                        error={flightInputError[i]["flightFrom"]}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="flightTo"
                        label="To"
                        required
                        value={x.flightTo}
                        onChange={(e) => {
                          handleFlightChange(e, i);
                        }}
                        error={flightInputError[i]["flightTo"]}
                      />
                    </div>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    width="100%"
                    marginTop="10px"
                  >
                    <div style={{ width: "100%" }}>
                      <DatePicker
                        label="Departure Date"
                        size="small"
                        autoOk
                        required
                        format="dd-MM-yyyy"
                        inputVariant="outlined"
                        fullWidth
                        // disablePast={true}
                        disablePast={x.flightDepartDate ? false : true}
                        variant="inline"
                        value={x.flightDepartDate ? x.flightDepartDate : null}
                        onChange={(e) =>
                          handleFlightSelectChange("flightDepartDate", e, i)
                        }
                        minDate={
                          x.flightDepartDate ? x.flightDepartDate : new Date()
                        }
                      />
                    </div>

                    {x.flightTrip === "Round" ? (
                      <div style={{ width: "100%" }}>
                        <DatePicker
                          label="Return Date"
                          size="small"
                          autoOk
                          required
                          format="dd-MM-yyyy"
                          inputVariant="outlined"
                          fullWidth
                          // disablePast={true}
                          variant="inline"
                          value={x.flightReturnDate ? x.flightReturnDate : null}
                          onChange={(e) =>
                            handleFlightSelectChange("flightReturnDate", e, i)
                          }
                          minDate={
                            x.flightDepartDate ? x.flightDepartDate : new Date()
                          }
                        />
                      </div>
                    ) : null}
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="flightPnr"
                        label="PNR No"
                        value={x.flightPnr}
                        onChange={(e) => {
                          handleFlightChange(e, i);
                        }}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="flightAdults"
                        label="Adults"
                        required
                        value={x.flightAdults}
                        onChange={(e) => {
                          handleFlightChange(e, i);
                        }}
                        error={flightInputError[i]["flightAdults"]}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="flightChild"
                        label="Children"
                        value={x.flightChild}
                        onChange={(e) => {
                          handleFlightChange(e, i);
                        }}
                      />
                    </div>
                  </Stack>
                  <Stack direction="row" spacing={2} marginTop="10px">
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="flightInclusion"
                        label="Inclusion"
                        value={x.flightInclusion ? x.flightInclusion : " "}
                        onChange={(e) => {
                          handleFlightChange(e, i);
                        }}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CustomTextInlineField
                        name="flightAmount"
                        label="Amount"
                        required
                        value={x.flightAmount}
                        onChange={(e) => {
                          handleFlightChange(e, i);
                        }}
                        error={flightInputError[i]["flightAmount"]}
                      />
                    </div>
                    {/* <CustomTextInlineField name="flightComission" label='Commisssion' value={x.flightComission} onChange={(e) => { handleFlightChange(e, i) }}/> */}
                    <div style={{ width: "100%" }}>
                      <TextField
                        name="flightComission"
                        fullWidth
                        label="Commission"
                        style={{ color: "#deffde", borderColor: "#deffde" }}
                        value={
                          x.flightComission != "" ? x.flightComission : " "
                        }
                        required
                        defaultValue=" "
                        variant="outlined"
                        size="small"
                        onChange={(e) => {
                          handleFlightChange(e, i);
                        }}
                        sx={{
                          "& .MuiOutlinedInput-input": { padding: "9px 14px" },
                          "& .MuiInputBase-input": { height: 1 },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            { border: "1.5px solid rgb(30, 175, 30)" },
                        }}
                        inputMode="numeric"
                      />
                    </div>
                    <div style={{ width: "100%" }}></div>
                  </Stack>
                </div>
                <br />
              </Grid>
            </>
          ))}
        </Stack>
        <br />
      </MuiPickersUtilsProvider>
    </div>
  );
}

function CustomTextInlineField(props) {
  return (
    <>
      <TextField
        name={props.name}
        fullWidth
        label={props.label}
        value={props.value != "" ? props.value : " "}
        defaultValue=" "
        required={props.required}
        variant="outlined"
        size="small"
        style={{ backgroundColor: "#" }}
        onChange={props.onChange}
        sx={{
          "& .MuiOutlinedInput-input": { padding: "9px 14px" },
          "& .MuiInputBase-input": { height: 1 },
        }}
      />
      {props.error.length > 0 && (
        <span style={{ color: "#ff0000" }}>{props.error}</span>
      )}
    </>
  );
}

CustomTextInlineField.defaultProps = {
  error: "",
};
