import { ActionTypes } from "../actions/actionTypes";

export const paymentModeOptionsReducer = (
  state = { paymentModeOptions: [] },
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.FETCH_PAYMENT_MODE_OPTIONS: {
      return { ...state, paymentModeOptions: payload };
    }
    default:
      return state;
  }
};

export const bookingSourceOptionsReducer = (
  state = { bookingSourceOptions: [] },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.FETCH_BOOKING_SOURCE_OPTIONS: {
      return { ...state, bookingSourceOptions: payload };
    }
    default:
      return state;
  }
};
