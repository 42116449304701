/* eslint-disable eqeqeq */
import { filter } from "lodash"
import { useEffect, useState } from "react"
// material
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@material-ui/core"
import "jspdf-autotable"
import {
  SearchNotFound,
  TableListHead,
  TableToolbar,
} from "../../components/tabel"

import { differenceInDays, format } from "date-fns"
import { toast } from "react-toastify"
import Api from "../../Service/Api"

import AcUnitIcon from "@material-ui/icons/AcUnit"
import BrightnessHighIcon from "@material-ui/icons/BrightnessHigh"
import WhatshotTwoToneIcon from "@material-ui/icons/WhatshotTwoTone"

const TABLE_HEAD = [
  { id: "hotelName", label: "Hotel Name" },
  { id: "rooms", label: "Rooms" },
  { id: "nights", label: "Nights" },
  { id: "adult", label: "Adult" },
  { id: "children", label: "Children" },
  { id: "mealPlan", label: "Meal Plan" },
  { id: "checkInDate", label: "Check In Date" },
  { id: "status", label: "Status" },
]

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  if (query) {
    return filter(
      array,
      (_query) =>
        _query?.propertyName?.toLowerCase().indexOf(query.toLowerCase()) !== -1
    )
  }
  return stabilizedThis.map((el) => el[0])
}

export default function AgentEnquiries() {
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("id")
  const [filterName, setFilterName] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [myEnquiries, setMyEnquiries] = useState([])
  const auth = localStorage.getItem("auth")

  const statusProvider = (leadscoring, leadscoringvalue) => {
    if (leadscoring === "new") {
      return "Lead Assigned".toLocaleUpperCase()
    }
    if (leadscoring === "closed") {
      return "Closed".toLocaleUpperCase()
    }
    if (leadscoring === "lost") {
      return "Lead Lost".toLocaleUpperCase()
    }
    if (leadscoring === "followup") {
      switch (leadscoringvalue) {
        case "followuphot":
          return (
            <span style={{ display: "flex", alignItems: "center" }}>
              FOLLOW UP :{" "}
              <WhatshotTwoToneIcon
                style={{
                  marginLeft: "10px",
                  color: "#e71e25",
                }}
              />
            </span>
          )
        case "followupwarm":
          return (
            <span style={{ display: "flex", alignItems: "center" }}>
              FOLLOW UP :{" "}
              <BrightnessHighIcon
                style={{
                  marginLeft: "10px",
                  color: "#febc12",
                }}
              />
            </span>
          )
        case "followupcold":
          return (
            <span style={{ display: "flex", alignItems: "center" }}>
              FOLLOW UP :{" "}
              <AcUnitIcon
                style={{
                  marginLeft: "10px",
                  color: "#8aceee",
                }}
              />
            </span>
          )
        default:
          return "----"
      }
    }
    if (leadscoring === "" || leadscoring === null) {
      return "----"
    }
  }

  const fetchQueryData = async () => {
    try {
      const { data } = await Api.get(`getAgentEnquiries/${auth}`)
      console.log(data)
      setMyEnquiries(data)
    } catch (error) {
      toast.error("Failed to Fetch Data")
    }
  }

  useEffect(() => {
    fetchQueryData()
  }, [auth])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - myEnquiries.length) : 0

  const filteredMyEnquires = applySortFilter(
    myEnquiries,
    getComparator(order, orderBy),
    filterName
  )

  const isNotFound = filteredMyEnquires.length === 0

  return (
    <div style={{ padding: "4% 1% 1% 1%" }}>
      <div>
        <TableToolbar
          filterName={filterName}
          onFilterName={handleFilterByName}
          searchPlaceholderName={"Search By Hotel"}
          tableName={"My Enquiries"}
        />
        <Card
          style={{
            marginTop: "15px",
            boxShadow: "none",
            border: "1px solid #F4F4F4",
            fontSize: "14px",
          }}
        >
          <TableContainer sx={{ minWidth: 800 }}>
            <Table size="small">
              <TableListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={myEnquiries.length}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {filteredMyEnquires
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const {
                      id,
                      propertyName,
                      noofrooms,
                      noofadults,
                      noofchild,
                      checkout,
                      checkin,
                      mealplan,
                      leadscoring,
                      leadscoringvalue,
                    } = row
                    return (
                      <TableRow hover key={id} tabIndex={-1}>
                        <TableCell component="th" scope="row">
                          {propertyName}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {noofrooms}
                        </TableCell>
                        <TableCell align="left">
                          {noofrooms *
                            differenceInDays(
                              new Date(checkout),
                              new Date(checkin)
                            )}
                        </TableCell>
                        <TableCell align="left">{noofadults}</TableCell>
                        <TableCell align="left">{noofchild}</TableCell>

                        <TableCell align="left">
                          {mealplan?.toUpperCase()}
                        </TableCell>
                        <TableCell align="left">
                          {format(new Date(checkin), "dd MMMM, yyyy")}
                        </TableCell>

                        <TableCell align="left">
                          {statusProvider(leadscoring, leadscoringvalue)}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={9} />
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredMyEnquires.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>
    </div>
  )
}
