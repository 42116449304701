import {
  makeStyles,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Tooltip,
} from "@material-ui/core"
import { Grid } from "@mui/material"
import { Field } from "formik"
import HelpIcon from "@material-ui/icons/Help"
const mealPlanOptions = [
  { value: "eprate", label: "EP Rate" },
  { value: "cprate", label: "CP Rate" },
  { value: "maprate", label: "MAP Rate" },
  { value: "aprate", label: "AP Rate" },
]

const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontSize: "17px",
    fontWeight: "bold",
    paddingBottom: "7px",
  },
  innerSectionStyles: {
    border: "1px solid #C4C4C4",
    padding: "10px",
    borderRadius: "5px",
  },
  outerSectionStyles: {
    marginTop: "19px",
  },
  labelProps: {
    color: "#000000",
  },
  inputProps: {
    height: "40px",
  },
  fieldStyle: {
    width: "100%",
  },
  menuItemStyles: {
    height: "300px",
    fontSize: "14px",
  },
  selectStyle: {
    height: "40px",
    width: "100%",
    "& .MuiSelect-icon": {
      color: "#f46d25",
    },
  },
  selectLabelProps: {
    backgroundColor: "white",
    color: "#000000",
    padding: "0px 5px 5px 5px",
    borderRadius: "5px",
  },
  checkboxStyling: {
    "& .MuiSvgIcon-root": {
      color: "#f46d25",
    },
  },
  menuStyling: {
    fontSize: "14px",
  },
}))

const PropertyRoomRentForm = ({ values }) => {
  const classes = useStyles()

  return (
    <section className={classes.outerSectionStyles}>
      <header className={classes.sectionHeader}>Room Rent</header>
      <section className={classes.innerSectionStyles}>
        <Grid container spacing={2}>
          <Grid container item spacing={2}>
            <Grid item xs={3}>
              <Field
                name="roomRent"
                as={TextField}
                type="number"
                value={values.roomRent}
                variant="outlined"
                label="Room Rent"
                className={classes.fieldStyle}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labelProps,
                }}
                InputProps={{
                  className: classes.inputProps,
                }}
                required
                onWheel={(e) => e.target.blur()}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="guestRent"
                as={TextField}
                type="number"
                value={values.guestRent}
                variant="outlined"
                label="Extra Guest Rent"
                className={classes.fieldStyle}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labelProps,
                }}
                InputProps={{
                  className: classes.inputProps,
                }}
                required
                onWheel={(e) => e.target.blur()}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="guestChildRent"
                as={TextField}
                type="number"
                value={values.guestChildRent}
                variant="outlined"
                label="Extra Child Rent"
                className={classes.fieldStyle}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labelProps,
                }}
                InputProps={{
                  className: classes.inputProps,
                }}
                required
                onWheel={(e) => e.target.blur()}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                required
              >
                <InputLabel className={classes.selectLabelProps} shrink>
                  Meal Plan
                </InputLabel>
                <Field
                  as={Select}
                  name="mealplan"
                  type="text"
                  className={classes.selectStyle}
                  MenuProps={{
                    className: classes.menuItemStyles,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  value={values.mealplan}
                >
                  {mealPlanOptions.map((meal) => (
                    <MenuItem value={meal.value}>{meal.label}</MenuItem>
                  ))}
                </Field>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item xs={9} spacing={2}>
            <Grid item xs={4}>
              <Field
                name="roomRentBronze"
                as={TextField}
                type="number"
                value={values.roomRentBronze}
                variant="outlined"
                label="Room Rent Bronze (%)"
                className={classes.fieldStyle}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labelProps,
                }}
                InputProps={{
                  className: classes.inputProps,
                }}
                required
                onWheel={(e) => e.target.blur()}
                maxLength={3}
              />
            </Grid>

            <Grid item xs={4}>
              <Field
                name="guestRentBronze"
                as={TextField}
                type="number"
                value={values.guestRentBronze}
                variant="outlined"
                label="Guest Rent Bronze (%)"
                className={classes.fieldStyle}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labelProps,
                }}
                InputProps={{
                  className: classes.inputProps,
                }}
                required
                onWheel={(e) => e.target.blur()}
                maxLength={3}
              />
            </Grid>

            <Grid item xs={4}>
              <Field
                name="guestChildRentBronze"
                as={TextField}
                type="number"
                value={values.guestChildRentBronze}
                variant="outlined"
                label="Guest Child Rent Bronze (%)"
                className={classes.fieldStyle}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labelProps,
                }}
                InputProps={{
                  className: classes.inputProps,
                }}
                required
                onWheel={(e) => e.target.blur()}
                maxLength={3}
              />
            </Grid>

            <Grid item xs={4}>
              <Field
                name="cprate"
                as={TextField}
                type="number"
                value={values.cprate}
                variant="outlined"
                label="CP Supplement Rate Per Adult"
                className={classes.fieldStyle}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labelProps,
                }}
                InputProps={{
                  className: classes.inputProps,
                }}
                required
                onWheel={(e) => e.target.blur()}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="maprate"
                as={TextField}
                type="number"
                value={values.maprate}
                variant="outlined"
                label="MAP Supplement Rate Per Adult"
                className={classes.fieldStyle}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labelProps,
                }}
                InputProps={{
                  className: classes.inputProps,
                }}
                required
                onWheel={(e) => e.target.blur()}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="aprate"
                as={TextField}
                type="number"
                value={values.aprate}
                variant="outlined"
                label="AP Supplement Rate Per Adult"
                className={classes.fieldStyle}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labelProps,
                }}
                InputProps={{
                  className: classes.inputProps,
                }}
                required
                onWheel={(e) => e.target.blur()}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                as={TextField}
                type="number"
                variant="outlined"
                label={
                  <span style={{ display: "flex", alignItems: "center" }}>
                    CP Rate
                    <HelpIcon color="primary" />
                  </span>
                }
                className={classes.fieldStyle}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labelProps,
                }}
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
                value={values.cprate * values.adultsBase + values.roomRent}
                title="CP_Rate = Room Rent + (Adults Base * CP Supplement Rate Per Adult)"
                size="small"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                as={TextField}
                type="number"
                variant="outlined"
                label={
                  <span style={{ display: "flex", alignItems: "center" }}>
                    MAP Rate
                    <HelpIcon color="primary" />
                  </span>
                }
                className={classes.fieldStyle}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labelProps,
                }}
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
                value={
                  parseFloat(values.maprate) * parseFloat(values.adultsBase) +
                  parseFloat(values.roomRent)
                }
                title="MAP_Rate = Room Rent + (Adults Base * MAP Supplement Rate Per Adult)"
                size="small"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                as={TextField}
                type="number"
                variant="outlined"
                label={
                  <span style={{ display: "flex", alignItems: "center" }}>
                    AP Rate
                    <HelpIcon color="primary" />
                  </span>
                }
                className={classes.fieldStyle}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labelProps,
                }}
                InputProps={{
                  className: classes.inputProps,
                  readOnly: true,
                }}
                value={values.aprate * values.adultsBase + values.roomRent}
                title="AP_Rate = Room Rent + (Adults Base * AP Supplement Rate Per Adult)"
                size="small"
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="chcprate"
                as={TextField}
                type="number"
                variant="outlined"
                value={values.chcprate}
                label="CP Supplement Rate Per Child"
                className={classes.fieldStyle}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labelProps,
                }}
                InputProps={{
                  className: classes.inputProps,
                }}
                required
                onWheel={(e) => e.target.blur()}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="chmaprate"
                as={TextField}
                type="number"
                value={values.chmaprate}
                variant="outlined"
                label="MAP Supplement Rate Per Child"
                className={classes.fieldStyle}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labelProps,
                }}
                InputProps={{
                  className: classes.inputProps,
                }}
                required
                onWheel={(e) => e.target.blur()}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                name="chaprate"
                as={TextField}
                type="number"
                value={values.chaprate}
                variant="outlined"
                label="AP Supplement Rate Per Child"
                className={classes.fieldStyle}
                InputLabelProps={{
                  shrink: true,
                  className: classes.labelProps,
                }}
                InputProps={{
                  className: classes.inputProps,
                }}
                required
                onWheel={(e) => e.target.blur()}
              />
            </Grid>
          </Grid>
        </Grid>
      </section>
    </section>
  )
}

export default PropertyRoomRentForm
