import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import { Grid } from "@mui/material"
import { Form, Formik } from "formik"

import _ from "lodash"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import Api from "../../../Service/Api"
import FormikInlineCheckboxAndSwitchOnly from "../../../components/amenityComponents/inlineCheckBoxAndSwitchOnly.component"
import ScreenLoader from "../../../components/loadingScreen/screenLoader.component"

const initialValues = {
  forex: false,
  forexStarred: false,
  cash: false,
  cashStarred: false,
  upi: false,
  upiStarred: false,
  pos: false,
  posStarred: false,
}

const paymentServicesWithSwitchOnly = [
  {
    name: "forex",
    label: "Foreign Exchange",
    switchName: "forexStarred",
  },
  {
    name: "cash",
    label: "Cash",
    switchName: "cashStarred",
  },
  {
    name: "upi",
    label: "UPI",
    switchName: "upiStarred",
  },
  {
    name: "pos",
    label: "POS",
    switchName: "posStarred",
  },
]

const PaymentServices = ({
  classes,
  handleChangePanel,
  expanded,
  setExpanded,
}) => {
  const { propertyId } = useParams()
  const [paymentServiceData, setPaymentServiceData] = useState({})
  const [loading, setLoading] = useState(() => false)
  const data = { propertyId: propertyId }
  const uniqueid = localStorage.getItem("unique_id")
  const createdBy = localStorage.getItem("auth")
  const create = { uniqueId: uniqueid, createdBy: createdBy }

  const handleChangeAccordion = () => {
    setExpanded("panel7")
  }

  const fetchPayementService = async () => {
    setLoading(true)
    try {
      const normalData = await Api.post(`AmenitiesPaymentServicevalue`, {
        propertyId,
      })

      const switchData = await Api.get(
        `AmenitiesPaymentServiceStarredvalue/${propertyId}`
      )

      console.log(normalData.data)
      console.log(switchData.data)

      setPaymentServiceData({ ...normalData.data, ...switchData.data })
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (propertyId && expanded == "panel6") {
      fetchPayementService()
    }
  }, [expanded])

  function onSubmit(fields, { setStatus }) {
    console.log(paymentServiceData)
    const id = paymentServiceData.id

    if (fields.forex == false) {
      fields.forexStarred = false
    }
    if (fields.cash == false) {
      fields.cashStarred = false
    }
    if (fields.upi == false) {
      fields.upiStarred = false
    }
    if (fields.pos == false) {
      fields.posStarred = false
    }
    setStatus()
    if (id) {
      updatePayment(fields)
    } else {
      createPayment(fields)
    }
  }

  async function createStarred(fields) {
    fields.propertyId = propertyId
    const newData = { ...data, ...fields, ...create }
    const res = await Api.post("AmenitiesPaymentServiceStarred", newData)
    if (res.status >= 400) {
      toast.error(`${res.status} - ${res.statusText}`)
    }
  }
  function updateStarred(fields) {
    fields.updateBy = createdBy
    Api.put("AmenitiesPaymentServiceStarredupdate", fields)
  }

  function createPayment(fields) {
    setLoading(true)
    fields.propertyId = propertyId
    console.log(fields)
    const newData = { ...data, ...fields, ...create }
    Api.post("AmenitiesPaymentService", newData).then((res) => {
      if (res.status === 200) {
        createStarred(fields)
        toast.success("Successfully Created")
        handleChangeAccordion()
        setLoading(false)
      } else {
        toast.error(`${res.status} - ${res.statusText} `)
        setLoading(false)
      }
    })
  }

  function updatePayment(fields) {
    setLoading(true)
    fields.updateBy = createdBy
    console.log({ fields })
    // Api.put("AmenitiesPaymentServiceupdate", fields)
    //   .then((res) => {
    //     if (res.status == 200) {
    //       updateStarred(fields);
    //       toast.success("Successfully Updated");
    //       handleChangeAccordion();
    //     } else {
    //       toast.error(`${res.status} - ${res.statusText} `);
    //     }
    //   })
    //   .catch((error) => toast.error("Error Occurred"))
    //   .finally(() => setLoading(false));
  }

  return (
    <Formik
      initialValues={
        !_.isEmpty(paymentServiceData) ? paymentServiceData : initialValues
      }
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue }) =>
        loading ? (
          <ScreenLoader open={loading} />
        ) : (
          <Form autoComplete="off">
            <Accordion
              square
              expanded={expanded === "panel6"}
              onChange={handleChangePanel("panel6")}
              className={classes.accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#f46d25",
                      background: "white",
                      borderRadius: "5px",
                    }}
                  />
                }
                className={classes.accordionSummaryStyles}
              >
                Payment Services
              </AccordionSummary>
              <AccordionDetails className={classes.outerSectionStyles}>
                <Grid container spacing={1}>
                  {/**Property Services switch only */}
                  {paymentServicesWithSwitchOnly.map((amenity) => (
                    <FormikInlineCheckboxAndSwitchOnly
                      amenity={amenity}
                      values={values}
                    />
                  ))}
                </Grid>
              </AccordionDetails>
              <center>
                <Button
                  style={{ height: "30px", margin: "10px 0px" }}
                  type="submit"
                >
                  Save and Next
                </Button>
              </center>
            </Accordion>
          </Form>
        )
      }
    </Formik>
  )
}

export default PaymentServices
