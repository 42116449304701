import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Api from "../../Service/Api";
import { Button, Dialog, Grid, Stack, Typography } from "@mui/material";
import { baseurl } from "../../Service/httpCommon";
import { twnButtonStyles } from "../../utils/townoStyle";
import Profile from "../../assets/pictures/profile.jpg";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh",
    minWidth: "55%",
    padding: "20px 20px",
    position: "absolute !important",
    backgroundColor: "#e3e3e3",
    margin: "0 !important",
    right: "0px",
    zIndex: "1000",
  },
  heading: {
    marginLeft: "30px",
    fontWeight: "bold",
    color: "#ffffff",
  },

  closeIcon: {
    fontSize: "30px",
    textAlign: "right",
    color: "#ffffff",
    cursor: "pointer",
    backgroundColor: "black",
  },
}));

export default function TravelAgentView(props) {
  const classes = useStyles();

  const { onClose, selectedIdView, open } = props;
  const [agentDetail, setAgentDetail] = useState("");

  useEffect(() => {
    if (selectedIdView) {
      Api.get(`companyregisteration/${selectedIdView}`).then((res) => {
        console.log("companyregisteration|res.data", res.data);
        setAgentDetail(res.data);
      });
    }
  }, [selectedIdView]);

  const handleClose = () => {
    onClose(true);
  };

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        open={open}
        onClose={handleClose}
        fullWidth
      >
        <Stack spacing={5}>
          <Stack
            direction="row"
            justifyContent="space-between"
            backgroundColor="#111"
            padding="5px 10px"
            borderRadius="5px"
          >
            <Typography
              variant="h5"
              style={{ color: "#fff", fontWeight: "bold" }}
            >
              Agent Details
            </Typography>
            {/* <CloseIcon style={{ backgroundColor: "#f46d25", color: "#fff", borderRadius: "15px", cursor: "pointer" }} /> */}
          </Stack>
          <Stack direction="row" spacing={2}>
            <Stack spacing={2} justifyContent="center" alignItems="center">
              <img
                src={
                  agentDetail.logoPath
                    ? `${baseurl}getimage/${agentDetail.logoPath}`
                    : Profile
                }
                style={{
                  boxShadow: "1px 1px 7px rgb(1 1 1 /0.3)",
                  height: "225px",
                  width: "175px",
                }}
              />
              <div style={{ ...twnButtonStyles.xlFonts, textAlign: "center" }}>
                {agentDetail.name}
              </div>
            </Stack>
            <Stack
              style={{
                backgroundColor: "#eee",
                borderRadius: "5px",
                padding: "20px",
                width: "30em",
                fontSize: "15px",
                fontWeight: "450",
              }}
              spacing={1}
            >
              <Grid container>
                <Grid item xs={7}>
                  User Name
                </Grid>
                <Grid item xs={5}>
                  : {agentDetail.username}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7}>
                  Unique ID
                </Grid>
                <Grid item xs={5}>
                  : {agentDetail.uniqueId}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7}>
                  Website
                </Grid>
                <Grid item xs={5}>
                  : {agentDetail.website}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7}>
                  Email
                </Grid>
                <Grid item xs={5}>
                  : {agentDetail.mail}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7}>
                  Mobile
                </Grid>
                <Grid item xs={5}>
                  : {agentDetail.mobile}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7}>
                  Alt.Mobile
                </Grid>
                <Grid item xs={5}>
                  : {agentDetail.altmobile}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7}>
                  City
                </Grid>
                <Grid item xs={5}>
                  : {agentDetail.city}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7}>
                  PanCard
                </Grid>
                <Grid item xs={5}>
                  : {agentDetail.panCard}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7}>
                  Onboarded By
                </Grid>
                <Grid item xs={5}>
                  : {agentDetail.onboardedBy}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7}>
                  Address
                </Grid>
                <Grid item xs={5}>
                  : {agentDetail.address}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7}>
                  Remark
                </Grid>
                <Grid item xs={5}>
                  : {agentDetail.remark}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={7}>
                  Travel Agent Category
                </Grid>
                <Grid item xs={5}>
                  : {agentDetail.travelAgentCategory}
                </Grid>
              </Grid>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <button onClick={handleClose} style={twnButtonStyles.orangeBtn}>
              Close
            </button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
}
