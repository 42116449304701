import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from "@material-ui/core"
import { Grid } from "@mui/material"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { useEffect, useState } from "react"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import * as Yup from "yup"
import Api from "../../../Service/Api"
import ScreenLoader from "../../../components/loadingScreen/screenLoader.component"
import PropertyRoomRentForm from "./propertyRoomRentForm.component"

const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontSize: "17px",
    fontWeight: "bold",
    paddingBottom: "7px",
  },
  innerSectionStyles: {
    border: "1px solid #C4C4C4",
    padding: "10px",
    borderRadius: "5px",
  },
  outerSectionStyles: {
    marginTop: "19px",
  },
  labelProps: {
    color: "#000000",
  },
  inputProps: {
    height: "40px",
  },
  fieldStyle: {
    width: "100%",
  },
  menuItemStyles: {
    height: "300px",
  },
  selectStyle: {
    height: "40px",
    width: "100%",
    "& .MuiSelect-icon": {
      color: "#f46d25",
    },
  },
  selectLabelProps: {
    backgroundColor: "white",
    color: "#000000",
    padding: "0px 5px 5px 5px",
    borderRadius: "5px",
  },
  checkboxStyling: {
    "& .MuiSvgIcon-root": {
      color: "#f46d25",
    },
  },
  error: {
    color: "red",
    cursor: "pointer",
  },
}))

const AddNewRoomForm = () => {
  const classes = useStyles()
  const initialValues = {
    displayName: "",
    roomType: "",
    longDescription: "",
    totalRooms: 0,
    roomArea: 0,
    roomAreaType: "",
    bedType: "",
    extraBedType: "",
    roomView: "",
    balacony: false,
    bathTub: false,
    adultsBase: 0,
    adultsMax: 0,
    childBase: 0,
    childMax: 0,
    roomRent: 0,
    guestRent: 0,
    mealplan: "",
    cprate: 0,
    maprate: 0,
    aprate: 0,
    chcprate: 0,
    chmaprate: 0,
    chaprate: 0,
    guestChildRent: 0,
  }

  // const { propertyId, displayName } = useParams();

  const { propertyId, displayName } = useParams()

  const [roomData, setRoomData] = useState(() => {})
  const data = { propertyId: propertyId, displayName: displayName }

  const [roomTypes, setRoomTypes] = useState([])
  const [roomViews, setRoomViews] = useState([])
  const [bedTypes, setBedTypes] = useState([])
  const [extraBedTypes, setExtraBedTypes] = useState([])
  const history = useHistory()
  const [loading, setLoading] = useState(() => false)

  const getRoomData = () => {
    setLoading(true)
    Api.post("propertyamenitiesroomvalue", data)
      .then((res) => {
        setRoomData(res.data)
      })
      .catch((error) => {
        toast.error(`${error.code}- An Error Occured`)
      })
      .finally(() => setLoading(false))
  }

  const RoomTypes = async () => {
    const RoomTypesData = {
      type: "Room Type",
    }
    await Api.post("productdropdownonly", RoomTypesData).then((res) => {
      setRoomTypes(res.data)
    })
  }
  const RoomViews = async () => {
    const RoomViewsData = {
      type: "Room View",
    }
    await Api.post("productdropdownonly", RoomViewsData).then((response) => {
      setRoomViews(response.data)
    })
  }
  const BedTypes = async () => {
    const BedTypesData = {
      type: "Bed Type",
    }
    await Api.post("productdropdownonly", BedTypesData).then((response) => {
      setBedTypes(response.data)
    })
  }
  const ExtraBedTypes = async () => {
    const ExtraBedTypesData = {
      type: "Extra Bed Type",
    }
    await Api.post("productdropdownonly", ExtraBedTypesData).then(
      (response) => {
        setExtraBedTypes(response.data)
      }
    )
  }

  useEffect(() => {
    RoomTypes()
    RoomViews()
    BedTypes()
    ExtraBedTypes()
  }, [])

  useEffect(() => {
    if (displayName && propertyId) {
      getRoomData()
    }
    console.log(displayName)
  }, [displayName, propertyId])

  const returnState = () => {
    if (sessionStorage.getItem("displayName") != "" && propertyId) {
      return roomData
    }

    return initialValues
  }

  const validationSchema = Yup.object({
    roomType: Yup.string().required("RoomType Required"),
    roomArea: Yup.number().required("Required"),
    roomAreaType: Yup.string().required("Required"),
    totalRooms: Yup.number().required("TotalRooms Required"),
    roomView: Yup.string().required("RoomView Required"),
    bedType: Yup.string().required("BedType Required"),
    extraBedType: Yup.string().required("ExtraBedType Required"),
    adultsBase: Yup.number().required("AdultsBase Required"),
    adultsMax: Yup.number().required("AdultsMax Required"),
    childBase: Yup.number().required("ChildBase Required"),
    childMax: Yup.number().required("ChildMax Required"),
    // roomRent: Yup.number().required("RoomRent Required"),
    // guestRent: Yup.number().required("GuestRent Required"),
    // mealplan: Yup.string().required("Mealplan Required"),
    // cprate: Yup.number().required("Cprate Required"),
    // maprate: Yup.number().required("Maprate Required"),
    // aprate: Yup.number().required("Aprate Required"),
    // guestChildRent: Yup.number().required("Child Rent Required"),
    // chcprate: Yup.number().required("Child Cprate Required"),
    // chmaprate: Yup.number().required("Child Maprate Required"),
    // chaprate: Yup.number().required("Child Aprate Required"),
  })

  const uniqueid = localStorage.getItem("unique_id")
  const createdBy = localStorage.getItem("auth")

  const create = { uniqueId: uniqueid, createdBy: createdBy }

  const onSubmit = (fields) => {
    console.log(fields)
    setLoading(true)

    if (propertyId && displayName) {
      fields.updateBy = localStorage.getItem("auth")
      Api.put("propertyamenitiesroomupdate", fields)
        .then((res) => {
          console.log(res)

          history.push(
            `/konnect/property/${res.data.propertyId}/roomDetails/${res.data.displayName}/roomAmenities`
          )
          toast.success("Successfully Updated")
        })
        .catch((error) => {
          toast.error(error.message)
        })
        .finally(() => setLoading(false))
    } else {
      const createRoomData = { ...data, ...fields, ...create }
      Api.post("propertyamenitiesroom", createRoomData)
        .then((res) => {
          toast.success("Successfully Created")

          console.log(res.data)

          // if (res.data.displayName) {
          // setNewPage(true);

          history.push(
            `/konnect/property/${res.data.propertyId}/roomDetails/${res.data.displayName}/roomAmenities`
          )
          // ListProperty();
          // }
        })
        .catch((error) => {
          toast.error(error.message)
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <Formik
      initialValues={roomData || initialValues}
      onSubmit={onSubmit}
      enableReinitialize
      validateOnMount
      validationSchema={validationSchema}
    >
      {({ isSubmitting, values, setFieldValue, isValid }) =>
        loading ? (
          <ScreenLoader open={loading} />
        ) : (
          <Form autoComplete="off">
            <section className={classes.outerSectionStyles}>
              <header className={classes.sectionHeader}>Basic Details</header>
              <section className={classes.innerSectionStyles}>
                <Grid container spacing={2}>
                  {!displayName && (
                    <Grid item xs={3}>
                      <Field
                        name="displayName"
                        as={TextField}
                        value={values.displayName}
                        variant="outlined"
                        label="Display Name"
                        className={classes.fieldStyle}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labelProps,
                        }}
                        InputProps={{
                          className: classes.inputProps,
                        }}
                        required
                      />
                    </Grid>
                  )}
                  {displayName && (
                    <Grid item xs={3}>
                      <Field
                        name="visibleName"
                        as={TextField}
                        value={values.visibleName}
                        variant="outlined"
                        label="Display Name"
                        className={classes.fieldStyle}
                        InputLabelProps={{
                          shrink: true,
                          className: classes.labelProps,
                        }}
                        InputProps={{
                          className: classes.inputProps,
                        }}
                        required
                      />
                    </Grid>
                  )}
                  <Grid item xs={3}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                    >
                      <InputLabel className={classes.selectLabelProps} shrink>
                        Room Type
                      </InputLabel>
                      <Field
                        as={Select}
                        name="roomType"
                        className={classes.selectStyle}
                        value={values.roomType}
                        MenuProps={{
                          className: classes.menuItemStyles,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {roomTypes.map((room) => (
                          <MenuItem value={room}>{room}</MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                    <ErrorMessage name="roomType">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="longDescription"
                      as={TextField}
                      value={values.longDescription}
                      variant="outlined"
                      label="Description"
                      className={classes.fieldStyle}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      InputProps={{
                        className: classes.inputProps,
                      }}
                      multiLine
                      minRows={1}
                      maxRows={1}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <Field
                      name="totalRooms"
                      as={TextField}
                      value={values.totalRooms}
                      variant="outlined"
                      label="Total Rooms"
                      className={classes.fieldStyle}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      InputProps={{
                        className: classes.inputProps,
                      }}
                      required
                      type="number"
                      inputMode="numeric"
                    />
                    <ErrorMessage name="totalRooms">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Field
                      name="roomArea"
                      as={TextField}
                      value={values.roomArea}
                      type="number"
                      variant="outlined"
                      label="Room Area"
                      className={classes.fieldStyle}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      InputProps={{
                        className: classes.inputProps,
                      }}
                      required
                    />
                    <ErrorMessage name="roomArea">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={1.5}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                    >
                      <InputLabel className={classes.selectLabelProps} shrink>
                        Area In
                      </InputLabel>
                      <Field
                        as={Select}
                        name="roomAreaType"
                        className={classes.selectStyle}
                        value={values.roomAreaType}
                        MenuProps={{
                          className: classes.menuItemStyles,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        <MenuItem value="sqft">Sq.ft</MenuItem>
                        <MenuItem value="sqmtr">Sq.mtr</MenuItem>
                      </Field>
                    </FormControl>
                    <ErrorMessage name="roomAreaType">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                    >
                      <InputLabel className={classes.selectLabelProps} shrink>
                        Bed Type
                      </InputLabel>
                      <Field
                        as={Select}
                        name="bedType"
                        value={values.bedType}
                        className={classes.selectStyle}
                        MenuProps={{
                          className: classes.menuItemStyles,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {bedTypes.map((bed) => (
                          <MenuItem value={bed}>{bed}</MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                    <ErrorMessage name="bedType">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                    >
                      <InputLabel className={classes.selectLabelProps} shrink>
                        Extra Bed Type
                      </InputLabel>
                      <Field
                        as={Select}
                        name="extraBedType"
                        value={values.extraBedType}
                        className={classes.selectStyle}
                        MenuProps={{
                          className: classes.menuItemStyles,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {extraBedTypes.map((extraBed) => (
                          <MenuItem value={extraBed}>{extraBed}</MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                    <ErrorMessage name="extraBedType">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%" }}
                      required
                    >
                      <InputLabel className={classes.selectLabelProps} shrink>
                        Room View
                      </InputLabel>
                      <Field
                        as={Select}
                        name="roomView"
                        value={values.roomView}
                        className={classes.selectStyle}
                        MenuProps={{
                          className: classes.menuItemStyles,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {roomViews.map((room) => (
                          <MenuItem value={room}>{room}</MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                    <ErrorMessage name="roomView">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          checked={values.balacony}
                          name="balacony"
                          className={classes.checkboxStyling}
                          type="checkbox"
                        />
                      }
                      label="Balcony"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Field
                          as={Checkbox}
                          checked={values.bathTub}
                          name="bathTub"
                          type="checkbox"
                          className={classes.checkboxStyling}
                        />
                      }
                      label="Bathtub"
                    />
                  </Grid>
                </Grid>
              </section>
            </section>

            {/**Room Occupancy Details */}

            <section className={classes.outerSectionStyles}>
              <header className={classes.sectionHeader}>Room Occupancy</header>
              <section className={classes.innerSectionStyles}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Field
                      name="adultsBase"
                      as={TextField}
                      type="number"
                      value={values.adultsBase}
                      variant="outlined"
                      label="Adults Base"
                      className={classes.fieldStyle}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      InputProps={{
                        className: classes.inputProps,
                      }}
                      onWheel={(e) => e.target.blur()}
                      required
                    />
                    <ErrorMessage name="adultsBase">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="adultsMax"
                      as={TextField}
                      type="number"
                      value={values.adultsMax}
                      variant="outlined"
                      label="Adults Max"
                      className={classes.fieldStyle}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      InputProps={{
                        className: classes.inputProps,
                      }}
                      onWheel={(e) => e.target.blur()}
                      required
                    />
                    <ErrorMessage name="adultsMax">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="childBase"
                      as={TextField}
                      type="number"
                      value={values.childBase}
                      variant="outlined"
                      label="Child Base"
                      className={classes.fieldStyle}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      InputProps={{
                        className: classes.inputProps,
                      }}
                      onWheel={(e) => e.target.blur()}
                      required
                    />
                    <ErrorMessage name="childBase">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      name="childMax"
                      as={TextField}
                      type="number"
                      value={values.childMax}
                      variant="outlined"
                      label="Child Max"
                      className={classes.fieldStyle}
                      InputLabelProps={{
                        shrink: true,
                        className: classes.labelProps,
                      }}
                      InputProps={{
                        className: classes.inputProps,
                      }}
                      onWheel={(e) => e.target.blur()}
                      required
                    />
                    <ErrorMessage name="childMax">
                      {(error) => <div className={classes.error}>{error}</div>}
                    </ErrorMessage>
                  </Grid>
                </Grid>
              </section>
            </section>
            <PropertyRoomRentForm values={values} classes={classes} />
            <footer
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                style={{ height: "30px", margin: "0px 10px" }}
                type="submit"
                disabled={!isValid}
              >
                Save And Next
              </Button>
              <Button
                onClick={() => {
                  sessionStorage.setItem("displayName", null)
                  history.goBack()
                }}
                style={{ background: "#000000", height: "30px" }}
              >
                Cancel
              </Button>
            </footer>
          </Form>
        )
      }
    </Formik>
  )
}

export default AddNewRoomForm
