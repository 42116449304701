import React, { useState } from "react"
import { Stack, Typography, Grid, Card, Button } from "@mui/material"
import { Link } from "react-router-dom"
import { format } from "date-fns"
import { twnButtonStyles } from "../../utils/townoStyle"
import { formatCurrency, formatter } from "../../utils/formatNumber"
import parse from "html-react-parser"

const cardLabel = { padding: "0.5em 1em", color: "#111", fontWeight: "500" }
const cardValue = { padding: "0.5em 1em", color: "#111" }
const hrStyle = { backgroundColor: "#111", margin: "1em 0", height: "0.1em" }
export default function PreviewPage({
  basicInput,
  flightInput,
  trainInput,
  cabInput,
  busInput,
  totalAmount,
  commission,
  packageTeamCondition,
  transferPaymentDetails,
}) {
  var flightTempAmountArray = flightInput.map((x, i) => {
    return x.flightAmount
  })
  var flightTempTotalAmount = flightTempAmountArray.reduce((total, num) => {
    return total + num / 1
  }, 0)
  // console.log(
  //   "flightTempTotalAmount",
  //   flightTempTotalAmount,
  //   flightTempAmountArray,
  //   flightInput
  // )
  var trainTempAmountArray = trainInput.map((x, i) => {
    return x.trainAmount
  })
  var trainTempTotalAmount = trainTempAmountArray.reduce((total, num) => {
    return total + num / 1
  }, 0)
  var cabTempAmountArray = cabInput.map((x, i) => {
    return x.cabAmount
  })
  var cabTempTotalAmount = cabTempAmountArray.reduce((total, num) => {
    return total + num / 1
  }, 0)
  var busTempAmountArray = busInput.map((x, i) => {
    return x.busAmount
  })
  var busTempTotalAmount = busTempAmountArray.reduce((total, num) => {
    return total + num / 1
  }, 0)
  console.log("transferPaymentDetail: ", transferPaymentDetails)
  return (
    <div
      style={{ padding: "2% 1%", fontSize: "14px", backgroundColor: "#F7F7F7" }}
    >
      {/* <div style={{ backgroundColor: "#eee", padding: "2%", height: "100%" }}> */}
      <Typography style={{ ...twnButtonStyles.xlFonts, color: "#F46D25" }}>
        Transfer Details
      </Typography>

      <>
        <Card style={{ marginTop: "9px" }}>
          {" "}
          <div
            style={{
              backgroundColor: "#f46d25",
              color: "#fff",
              padding: "0.5em 1em",
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            Client Details
          </div>
          {/* <Card style={{ borderRadius: '0' }}> */}
          {basicInput.map((each, index) => {
            return (
              <>
                <Stack key={index}>
                  <Stack
                    direction="row"
                    spacing={2}
                    style={{ lineHeight: "0.3", padding: "0.5em " }}
                  >
                    <Grid container>
                      <Grid item xs={4} style={cardLabel}>
                        Name
                      </Grid>
                      <Grid item xs={8} style={cardValue}>
                        : {each.clientName}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={4} style={cardLabel}>
                        Mobile No
                      </Grid>
                      <Grid item xs={8} style={cardValue}>
                        : {each.clientMobileNo}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={4} style={cardLabel}>
                        Alt Mobile no
                      </Grid>
                      <Grid item xs={8} style={cardValue}>
                        : {each.clientAltNo}
                      </Grid>
                    </Grid>
                  </Stack>
                  <Grid
                    container
                    item
                    xs={8}
                    style={{ lineHeight: "0", padding: "0 0.5em 0.5em 0.5em" }}
                  >
                    <Grid item xs={2} style={cardLabel}>
                      Email{" "}
                    </Grid>
                    <Grid item xs={10} style={cardValue}>
                      : {each.clientEmail}
                    </Grid>
                  </Grid>
                </Stack>

                {index + 1 !== basicInput.length ? (
                  <hr
                    style={{
                      backgroundColor: "#eee",
                      margin: "0.5em 1em",
                      height: "0.025em",
                    }}
                  />
                ) : null}
              </>
            )
          })}
        </Card>
      </>

      {/* flight  */}
      {flightTempTotalAmount > 0 && (
        <>
          <Card style={{ marginTop: "9px" }}>
            {" "}
            <div
              style={{
                backgroundColor: "#f46d25",
                color: "#fff",
                padding: "0.5em 1em",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Flight Details
            </div>
            {flightInput.map((each, index) => {
              return (
                <>
                  <Stack key={index}>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{
                        lineHeight: "0.3",
                        padding: "0.5em 0.5em 0 0.5em",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Name
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.flightName}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          From
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.flightFrom}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          To
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.flightTo}
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ lineHeight: "0.3", padding: "0 0.5em " }}
                    >
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          PNR
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.flightPnr}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Inclusion
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {formatCurrency(each.flightInclusion)}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Departure Date
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          :{" "}
                          {each.flightDepartDate
                            ? format(
                                new Date(each.flightDepartDate),
                                "dd-MM-yyyy"
                              )
                            : null}
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ lineHeight: "0.3", padding: "0 0.5em " }}
                    >
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Adults
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.flightAdults}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Children
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.flightChild}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Return Date
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          :{" "}
                          {each.flightReturnDate
                            ? format(
                                new Date(each.flightReturnDate),
                                "dd-MM-yyyy"
                              )
                            : null}
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{
                        lineHeight: "0.3",
                        padding: "0 0.5em 0.5em 0.5em",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Amount
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {formatCurrency(each.flightAmount)}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Commission
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {formatCurrency(each.flightComission)}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Trip
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.flightTrip ?? null}
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                  {index + 1 !== flightInput.length ? (
                    <hr
                      style={{
                        backgroundColor: "#eee",
                        margin: "0.5em 1em",
                        height: "0.025em",
                      }}
                    />
                  ) : null}
                </>
              )
            })}
            {/* </Card> */}
          </Card>
        </>
      )}

      {/* train  */}
      {trainTempTotalAmount > 0 && (
        <>
          <Card style={{ marginTop: "9px" }}>
            {" "}
            <div
              style={{
                backgroundColor: "#f46d25",
                color: "#fff",
                padding: "0.5em 1em",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Train Details
            </div>
            {trainInput.map((each, index) => {
              return (
                <>
                  <Stack key={index}>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{
                        lineHeight: "0.3",
                        padding: "0.5em 0.5em 0 0.5em",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Name
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.trainName}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          From
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.trainFrom}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          To
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.trainTo}
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ lineHeight: "0.3", padding: "0 0.5em " }}
                    >
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          PNR
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.trainPnr}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Departure Date
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          :{" "}
                          {each.trainDepartDate
                            ? format(
                                new Date(each.trainDepartDate),
                                "dd-MM-yyyy"
                              )
                            : null}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Travel Class
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.traintravelClass}
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ lineHeight: "0.3", padding: "0 0.5em " }}
                    >
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Adults
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.trainAdults}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Children
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.trainChild}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Inclusion
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {formatCurrency(each.trainInclusion)}
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{
                        lineHeight: "0.3",
                        padding: "0 0.5em 0.5em 0.5em",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Amount
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {formatCurrency(each.trainAmount)}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Commission
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {formatCurrency(each.trainComission)}
                        </Grid>
                      </Grid>
                      <Grid container></Grid>
                    </Stack>
                  </Stack>
                  {index + 1 !== trainInput.length ? (
                    <hr
                      style={{
                        backgroundColor: "#eee",
                        margin: "0.5em 1em",
                        height: "0.025em",
                      }}
                    />
                  ) : null}
                </>
              )
            })}
          </Card>
        </>
      )}
      {/* cab  */}
      {cabTempTotalAmount > 0 && (
        <>
          <Card style={{ marginTop: "9px" }}>
            {" "}
            <div
              style={{
                backgroundColor: "#f46d25",
                color: "#fff",
                padding: "0.5em 1em",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Cab Details
            </div>
            {cabInput.map((each, index) => {
              console.log("cab|each: ", each, cabInput)
              return (
                <>
                  <Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ lineHeight: "0.5" }}
                    >
                      <Grid container>
                        <Grid item xs={5} style={cardLabel}>
                          From
                        </Grid>
                        <Grid item xs={1} style={cardLabel}>
                          :
                        </Grid>
                        <Grid item xs={6} style={cardValue}>
                          {each.cabFrom}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={5} style={cardLabel}>
                          To
                        </Grid>
                        <Grid item xs={1} style={cardLabel}>
                          :
                        </Grid>
                        <Grid item xs={6} style={cardValue}>
                          {each.cabTo}
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ lineHeight: "0.5" }}
                    >
                      <Grid container>
                        <Grid item xs={5} style={cardLabel}>
                          Trip
                        </Grid>
                        <Grid item xs={1} style={cardLabel}>
                          :
                        </Grid>
                        <Grid item xs={6} style={cardValue}>
                          {each.cabTrip}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={5} style={cardLabel}>
                          Type
                        </Grid>
                        <Grid item xs={1} style={cardLabel}>
                          :
                        </Grid>
                        <Grid item xs={6} style={cardValue}>
                          {each.cabType}
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ lineHeight: "0.5" }}
                    >
                      <Grid container>
                        <Grid item xs={5} style={cardLabel}>
                          Vehicle
                        </Grid>
                        <Grid item xs={1} style={cardLabel}>
                          :
                        </Grid>
                        <Grid item xs={6} style={cardValue}>
                          {each.cabVehicle}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={5} style={cardLabel}>
                          Adults
                        </Grid>
                        <Grid item xs={1} style={cardLabel}>
                          :
                        </Grid>
                        <Grid item xs={6} style={cardValue}>
                          {each.cabAdults}
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ lineHeight: "0.5" }}
                    >
                      <Grid container>
                        <Grid item xs={5} style={cardLabel}>
                          Children
                        </Grid>
                        <Grid item xs={1} style={cardLabel}>
                          :
                        </Grid>
                        <Grid item xs={6} style={cardValue}>
                          {each.cabChild}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={5} style={cardLabel}>
                          Amount
                        </Grid>
                        <Grid item xs={1} style={cardLabel}>
                          :
                        </Grid>
                        <Grid item xs={6} style={cardValue}>
                          {formatCurrency(each.cabAmount)}
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ lineHeight: "0.5", paddingBottom: "0.5em" }}
                    >
                      <Grid container>
                        <Grid item xs={5} style={cardLabel}>
                          Commission
                        </Grid>
                        <Grid item xs={1} style={cardLabel}>
                          :
                        </Grid>
                        <Grid item xs={6} style={cardValue}>
                          {formatCurrency(each.cabCommission)}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}></Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </>
              )
            })}
          </Card>
          <br />
        </>
      )}

      {/* bus  */}
      {busTempTotalAmount > 0 && (
        <>
          <Card
            style={{
              backgroundColor: "#F46D25",
              color: "#fff",
              fontWeight: "bold",
              padding: "0.5em 1em",
            }}
          >
            Bus Details
          </Card>
          <Card style={{ borderRadius: "0" }}>
            {busInput.map((each, index) => {
              return (
                <>
                  <Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ lineHeight: "0.5" }}
                    >
                      <Grid container>
                        <Grid item xs={5} style={cardLabel}>
                          Name
                        </Grid>
                        <Grid item xs={1} style={cardLabel}>
                          :
                        </Grid>
                        <Grid item xs={6} style={cardValue}>
                          {each.busName}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={5} style={cardLabel}>
                          From
                        </Grid>
                        <Grid item xs={1} style={cardLabel}>
                          :
                        </Grid>
                        <Grid item xs={6} style={cardValue}>
                          {each.busFrom}
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ lineHeight: "0.5" }}
                    >
                      <Grid container>
                        <Grid item xs={5} style={cardLabel}>
                          To
                        </Grid>
                        <Grid item xs={1} style={cardLabel}>
                          :
                        </Grid>
                        <Grid item xs={6} style={cardValue}>
                          {each.busTo}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={5} style={cardLabel}>
                          Seat No
                        </Grid>
                        <Grid item xs={1} style={cardLabel}>
                          :
                        </Grid>
                        <Grid item xs={6} style={cardValue}>
                          {each.busSeatNo}
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ lineHeight: "0.5" }}
                    >
                      <Grid container>
                        <Grid item xs={5} style={cardLabel}>
                          Adults
                        </Grid>
                        <Grid item xs={1} style={cardLabel}>
                          :
                        </Grid>
                        <Grid item xs={6} style={cardValue}>
                          {each.busAdults}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={5} style={cardLabel}>
                          Children
                        </Grid>
                        <Grid item xs={1} style={cardLabel}>
                          :
                        </Grid>
                        <Grid item xs={6} style={cardValue}>
                          {each.busChild}
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ lineHeight: "0.5", paddingBottom: "0.5em" }}
                    >
                      <Grid container>
                        <Grid item xs={5} style={cardLabel}>
                          Amount
                        </Grid>
                        <Grid item xs={1} style={cardLabel}>
                          :
                        </Grid>
                        <Grid item xs={6} style={cardValue}>
                          {formatCurrency(each.busAmount)}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={5} style={cardLabel}>
                          Commission
                        </Grid>
                        <Grid item xs={1} style={cardLabel}>
                          :
                        </Grid>
                        <Grid item xs={6} style={cardValue}>
                          {formatCurrency(each.busCommission)}
                        </Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </>
              )
            })}
          </Card>
          <br />
        </>
      )}
      <Card style={{ marginTop: "9px" }}>
        {" "}
        <div
          style={{
            backgroundColor: "#f46d25",
            color: "#fff",
            padding: "0.5em 1em",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Payment Details
        </div>
        <Stack
          direction="row"
          spacing={2}
          style={{
            backgroundColor: "#eee",
            width: "auto",
            fontWeight: "bold",
            border: "1px solid #eee",
          }}
        >
          <span
            style={{ color: "#f46d25", padding: "0.5em 1em", width: "50%" }}
          >
            Total Transfer Booking Amount
          </span>
          <span
            style={{ color: "#f46d25", padding: "0.5em 1em", width: "50%" }}
          >
            Amount Paid
          </span>
          <span
            style={{ color: "#f46d25", padding: "0.5em 1em", width: "50%" }}
          >
            Reference No
          </span>
          <span
            style={{ color: "#f46d25", padding: "0.5em 1em", width: "50%" }}
          >
            Payment Mode
          </span>
          <span
            style={{ color: "#1eaf1e", padding: "0.5em 1em", width: "50%" }}
          >
            Commission
          </span>
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          style={{ width: "auto", fontWeight: "500", border: "1px solid #eee" }}
        >
          <span style={{ padding: "0.5em 1.2em", width: "50%" }}>
            {formatCurrency(totalAmount)}
          </span>
          <span style={{ padding: "0.5em 1.2em", width: "50%" }}>
            {formatCurrency(transferPaymentDetails?.paidAmount)}
          </span>
          <span style={{ padding: "0.5em 1.2em", width: "50%" }}>
            {transferPaymentDetails.paymentRefNo}
          </span>
          <span style={{ padding: "0.5em 1.2em", width: "50%" }}>
            {transferPaymentDetails.paymentMode}
          </span>
          <span style={{ padding: "0.5em 1.2em", width: "50%" }}>
            {formatCurrency(commission)}
          </span>
        </Stack>
      </Card>

      <Card style={{ marginTop: "9px" }}>
        {" "}
        <div
          style={{
            backgroundColor: "#f46d25",
            color: "#fff",
            padding: "0.5em 1em",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Terms And Conditions
        </div>
        <Stack
          direction="row"
          spacing={1}
          style={{ padding: "0.5em 1em", fontWeight: "500" }}
        >
          {parse(packageTeamCondition.teamCondition)}
        </Stack>
      </Card>

      <Card style={{ marginTop: "9px" }}>
        {" "}
        <div
          style={{
            backgroundColor: "#f46d25",
            color: "#fff",
            padding: "0.5em 1em",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Cancellation Policy
        </div>
        <Stack
          direction="row"
          spacing={1}
          style={{ padding: "0.5em 1em", fontWeight: "500" }}
        >
          {parse(packageTeamCondition.cancellationPolicy)}
        </Stack>
      </Card>
    </div>
  )
}
