import styles from "./DestinationShoppingAndEntertainmernt.module.css"
import globalStyles from "../../../../globalStyles.module.css"
import { useContext, useLayoutEffect } from "react"
import { DestinationLayoutContext } from "../../../../layout/DestinationLayout/Destination.layout"
import QueryForm from "../../../../components/QueryForm/QueryForm.component"
import ContactInfo from "../../../../components/ContactInfo/ContactInfo.component"
import AsideDestinationNavbar from "../../../../components/AsideDestinationNavbar/AsideDestinationNavbar.component"
import ImageAndContentCard from "../../../../components/ImageAndContentCard/ImageAndContentCard.component"
import parser from "html-react-parser"
import BiggerGroupBannerPost from "../../../../components/BiggerGroupBanner/BiggerGroupBanner.component"
import MockImage from "../../../../assets/illustrations/mockImages/Dubai.jpg"
import DestinationSubLayout from "../../../../layout/DestinationSubLayout/DestinationSub.layout"

const options = [
  {
    title: "Paltan Bazar in Dehradun",
    content: `Paltan Bazar in the capital city of Dehradun is one of the cheap shopping places in Uttarakhand and a wonderful place to buy hand-woven and handmade products, spices and artefacts. It is also the ideal place where women would also like to buy bangles as well as colourful jewellery. If you are shopping in Uttarakhand for clothes, the Tibetan market close by is also another shopping arena where you can buy cheap but good quality garments and woollens.`,
  },
  {
    title: "Mall Road in Mussoorie",
    content: `The Mall Road in Mussoorie is one of the best shopping places in Uttarakhand. It is the main shopping area where vendors decorate both sides of the road. As you gorge on street food, you can purchase local colourful fabrics, woollens as well as cheap electronic goods. Intricately covered wooden statues and boxes align the roads and beckon to you as you walk down the road From a Himachali topi to a jamawar shawl, this is definitely the place to take home souvenirs for your friends and family `,
  },

  {
    title: "Bara Bazar Nainital",
    content:
      "One of the oldest marketplaces and best shopping markets in Uttarakhand is located in the Mallital region at Bara Bazaar. You can find woollen clothes such as shawls, caps and sweaters. In addition, you can also find locally made jams and squashes. The speciality of this market however is its candles, exclusively made and available only in Nainital.",
  },
  {
    title: "Chowk Bazar Almora",
    content:
      "Along with angora wool and wooden items, this Uttarakhand market for shopping is uniquely famous for its copperware. From copper pots to drinking glasses, musical instruments, water bottles and other utensils, you can buy it all in Almora.",
  },
  {
    title: "Nightlife",
    content:
      "The larger cities like Dehradun, Nainital and Mussoorie have a buzzing nightlife with parties happening in Uttarakhand. You can go and enjoy a quiet nightcap with your loved ones, have coffee or wine in a cafe with a view, relax with music in the background or attend some of the best parties in Uttarakhand.",
  },
]

const DestinationShoppingAndEntertainment = () => {
  const { setBanner } = useContext(DestinationLayoutContext)

  useLayoutEffect(() => {
    setBanner("shopping")
  }, [])

  return (
    <DestinationSubLayout>
      <main className={globalStyles.main}>
        <article className={globalStyles.topMostSection}>
          <header>
            Shopping & <span>Entertainments</span>
          </header>
          <div className={globalStyles.content}>
            The best part about travel is the shopping that accompanies the
            trip. When you buy souvenirs, you take back home a bit of your trip
            that serves as both memories and inspiration. The culturally rich
            state of Uttarakhand provides a unique opportunity to take home a
            piece of the state in the form of local handicrafts, wooden work,
            carpets, jewellery, gems, jams and woollen garments. If you’re
            looking for what is famous in Uttarakhand for shopping, look no
            further. While hill stations such as Nainital are famous for their
            wax candles, Almora is famous for its Angora clothes and woodcrafts
            can be bought in plenty in Munsiyari, Mussoorie and Nainital.
            <p>
              Some of the famous shopping places in Uttarakhand can be found on
              the streets of the various towns and cities of Uttarakhand, where
              you can shop till you drop, engage in colourful conversations with
              the local vendors and give a boost to the local economy as well.
            </p>
            Some of the famous Uttarakhand shopping markets are:
          </div>
        </article>
        <article className={styles.shoppingList}>
          {options.map((option, idx) => (
            <ImageAndContentCard key={option.title}>
              <ImageAndContentCard.Title>
                {option.title}
              </ImageAndContentCard.Title>
              <ImageAndContentCard.Content>
                {parser(option.content)}
              </ImageAndContentCard.Content>
              {/* <div className={styles.placeHolderImageContainer}>
                {Array.from({ length: 8 }, (_, i) => (
                  <div className={styles.cardContainer}>
                    <img
                      className={styles.placeHolderImage}
                      src={MockImage}
                      key={i}
                    />
                    <p>Destination</p>
                  </div>
                ))}
              </div> */}
            </ImageAndContentCard>
          ))}
        </article>
        <BiggerGroupBannerPost />
      </main>
    </DestinationSubLayout>
  )
}

export default DestinationShoppingAndEntertainment
