import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core"
import SearchIcon from "@material-ui/icons/SearchOutlined"
import { useEffect, useRef, useState } from "react"
import PackageCard from "./PackageCard"
import QuotationCard from "./QuotationCard"
import {
  Link,
  NavLink,
  useHistory,
} from "react-router-dom/cjs/react-router-dom"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Loader from "../../components/Loader/Loader"

const useStyles = makeStyles({
  root: {
    margin: "20% 3%",
    paddingBottom: "20%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  createButton: {
    background: "#343A40",
    fontSize: "18px",
    height: "50%",
  },
  searchField: {
    width: "100%",
  },
  select: {
    "&:before": {
      border: "none",
    },
    "&:after": {
      border: "none",
    },
  },
})

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#343A40",
    },
    secondary: {
      main: "#343A40",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        background: "#343A40 !important",
        color: "white",
        fontSize: "12px",
        "& $hover": {
          background: "#343A40",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        border: "1px solid #343A40",
      },
    },
    MuiFormControl: {
      root: {
        border: "1px solid white",
      },
    },

    MuiSelect: {
      root: {
        border: "none !important",
        height: "40px",
        borderBottom: "none !important",
      },
      iconFilled: {
        color: "white",
        background: "#f46d25",
        borderRadius: "5px",
        width: "20%",
        height: "27px",
      },
      iconOpen: {
        transform: "rotate(180deg)",
      },
    },
  },
})

const options = [
  { label: "Package", value: "package" },
  { label: "Quotation", value: "quotation" },
]

const PackageMobileUI = ({
  isPackage,
  data,
  pkgOrQtn,
  textToSearch,
  setTextToSearch,
}) => {
  const classes = useStyles()
  console.log(isPackage)
  console.log(data)
  const [optionValues, setOptionValues] = useState(pkgOrQtn)
  let hasAdmin = localStorage.getItem("role")
  let checkAgent = localStorage.getItem("agent")
  const history = useHistory()

  const handleOptionChange = (e) => {
    console.log(e.target.value)
    setOptionValues(e.target.value)
  }

  const totalDataLength = data.length
  const dataPerPage = 5
  let noOfPages = Math.ceil(totalDataLength / dataPerPage)
  console.log("totalDataLength", totalDataLength, noOfPages)
  const [currentPageNo, setCurrentPageNo] = useState(1)
  let tempDataIndex = currentPageNo * dataPerPage //variable for min and max data range for page
  let lastDataIndex = Math.min(tempDataIndex, totalDataLength)
  let firstDataIndex = 0
  console.log("first and last indices of data:", firstDataIndex, lastDataIndex)

  const changePageNo = (value) => {
    let tempPageNo = currentPageNo + value //value can be 1 or -1 depending upon arrow clicked
    if (tempPageNo >= 1 && tempPageNo <= noOfPages) {
      setCurrentPageNo(tempPageNo)
    }
  }

  const loaderRef = useRef(null)
  const [showButton, setShowButton] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement
    const scrollPosition = window.pageYOffset

    // if (windowHeight + scrollPosition >= documentHeight) {
    //   setPage((prevPage) => prevPage + 1);
    // }

    console.log(
      "scroll height",
      Math.ceil(window.innerHeight + document.documentElement.scrollTop)
    )

    console.log("offsetHeight", document.documentElement.offsetHeight)

    scrollPosition > 300 ? setShowButton(true) : setShowButton(false)

    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) >=
      document.documentElement.offsetHeight
    ) {
      setLoading(true)

      setTimeout(() => {
        setLoading(false)
        changePageNo(1)
      }, 2000)
      console.log("ok")
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [firstDataIndex, lastDataIndex, currentPageNo, handleScroll])

  return (
    <div className={classes.root}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ThemeProvider theme={customTheme}>
          <Select
            className={classes.select}
            style={{
              height: "40px",
              width: "50%",
              fontSize: "23px",
              fontWeight: "Bold",
              border: "none",
            }}
            onChange={handleOptionChange}
            defaultValue={"package"}
            value={optionValues}
            IconComponent={() => (
              <ExpandMoreIcon
                style={{
                  background: "#f46d25",
                  color: "white",
                  height: "25px",
                  fontSize: "30px",
                  borderRadius: "5px",
                }}
              />
            )}
          >
            {options.map((option) => (
              <MenuItem
                value={option.value}
                style={{ fontSize: "20px" }}
                onClick={() =>
                  history.push(`/konnect/packageOrQuotation/${option.value}`)
                }
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </ThemeProvider>

        <ThemeProvider theme={customTheme}>
          {hasAdmin === "Agent Admin" || checkAgent === "Agent" ? (
            <Button
              onClick={() => {
                history.push(
                  `/konnect/agentCreatePackageOrQuotation/${pkgOrQtn}/${isPackage}`,
                  { displayConvert: false }
                )
              }}
            >
              {isPackage ? "Create Package" : "Create Quotation"}
            </Button>
          ) : (
            <Button
              onClick={() => {
                history.push(
                  `/konnect/createPackageOrQuotation/${pkgOrQtn}/${isPackage}`,
                  { displayConvert: false }
                )
              }}
            >
              {isPackage ? "Create Package" : "Create Quotation"}
            </Button>
          )}
          {/* <Button>
            {isPackage === true ? "Create Package" : "Create Quotation"}
          </Button> */}
        </ThemeProvider>
      </div>
      <ThemeProvider theme={customTheme}>
        <TextField
          value={textToSearch}
          onChange={(e) => {
            setTextToSearch(e.target.value)
          }}
          type="search"
          style={{ width: "100%", marginTop: "5%", paddingBottom: "5%" }}
          placeholder="Search Booking Id"
          InputProps={{
            style: { height: "40px" },
            startAdornment: (
              <InputAdornment
                style={{
                  padding: "0",
                  margin: "0",
                  color: "#f46d25",
                  marginLeft: "-2%",
                }}
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </ThemeProvider>
      <div>
        {data &&
          data
            .slice(firstDataIndex, lastDataIndex)
            .filter(
              (info) =>
                info.name ||
                info.packageId ||
                info.quotationId ||
                info.clientName
                  .toLowerCase()
                  .includes(textToSearch.toLowerCase())
            )
            .map((pack) =>
              isPackage === true ? (
                <PackageCard pack={pack} />
              ) : (
                <QuotationCard quotation={pack} />
              )
            )}
        <div ref={loaderRef} />
        {loading && lastDataIndex < data.length && (
          <div
            style={{
              top: 0,
              width: "100%",
              height: "5%",
              padding: "0",
              margin: "0",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Loader />
          </div>
        )}
      </div>
    </div>
  )
}

export default PackageMobileUI
