import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Grid,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
} from "@mui/material";

import Switch from '@material-ui/core/Switch';
import { styled } from "@mui/material/styles";
import { Button } from "@material-ui/core";
import XLSX from "xlsx";
import Select from "react-select";
import _ from "lodash";
import { format } from "date-fns";
        
       
const FinanceOpition = [
    { value: "transferId", label: "Transfer Id" },
    {value: "type",label: "Transfer Type"},
    {value: "transferStatus",label: "Transfer Status"},
    {value: "clientName",label: "Client Name"},
    {value: "clientMobileNo",label: "Client Mobile No"},
    {value: "clientEmail",label: "Client Email"},    
    {value: "agentName",label: "Agent Name"},
    {value: "totalAmount",label: "Total Amount"},
    {value: "totalComission",label: "Total Commission"},   
    {value: "amountRefunded",label: "Amount Refunded"},
    {value: "amountRetained",label: "Amount Retained"},    
    {value: "revisedCommission",label: "Revised Commission"},
    {value: "flightName",label: "Flight Name"},    
    {value: "flightAmount",label: "Flight Amount"},
    {value: "flightComission",label: "Flight Commission"},
    {value: "trainName",label: "Train Name"},
    {value: "trainAmount",label: "Train Amount"},
    {value: "trainComission",label: "Train Commission"},
    {value: "cabAmount",label: "Cab Amount"},    
    {value: "cabCommission",label: "Cab Commission"},  
    {value: "cabVehicle",label: "Cab Vehicle"}, 
    {value: "busName",label: "Bus Name"}, 
    {value: "busAmount",label: "Bus Amount"},  
    {value: "busCommission",label: "Bus Commission"}, 
    {value: "createdDate",label: "Created Date"},
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  background: "#F46D25",
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: "#F46D25",
  color: "#fff",
  fontSize: "12px",
  padding: "5px",
  lineHeight: "1rem",
  minWidth: "100px",
}));

const StyledTableCellInside = styled(TableCell)(({ theme }) => ({
  padding: "5px",
  fontSize: "12px",
  lineHeight: "15px",
}));

const TABLE_HEAD_CONST = [
  { value: "transferId", label: "Transfer Id" },
  {value: "clientName",label: "Client Name"},
  {value: "agentName",label: "Agent Name"},
  {value: "clientMobileNo",label: "Client Mobile No"},
  {value: "totalAmount",label: "Total Amount"},
  {value: "totalComission",label: "Total Commission"},   
  ];

  const filter=[
    {id: "transferStatus",label: "Transfer Status", type:"normal", selected:false},
  {id: "clientEmail",label: "Client Email", type:"normal", selected:false},    
  {id: "type",label: "Transfer Type", type:"normal", selected:false},
  {id: "amountRefunded",label: "Amount Refunded", type:"normal", selected:false},
  {id: "amountRetained",label: "Amount Retained", type:"normal", selected:false},    
  {id: "revisedCommission",label: "Revised Commission", type:"normal", selected:false},
  {id: "flightName",label: "Flight Name", type:"normal", selected:false},    
  {id: "flightAmount",label: "Flight Amount", type:"normal", selected:false},
  {id: "flightComission",label: "Flight Commission", type:"normal", selected:false},
  {id: "trainName",label: "Train Name", type:"normal", selected:false},
  {id: "trainAmount",label: "Train Amount", type:"normal", selected:false},
  {id: "trainComission",label: "Train Commission", type:"normal", selected:false},
  {id: "cabAmount",label: "Cab Amount", type:"normal", selected:false},    
  {id: "cabCommission",label: "Cab Commission", type:"normal", selected:false},  
  {id: "cabVehicle",label: "Cab Vehicle", type:"normal", selected:false}, 
  {id: "busName",label: "Bus Name", type:"normal", selected:false}, 
  {id: "busAmount",label: "Bus Amount", type:"normal", selected:false},  
  {id: "busCommission",label: "Bus Commission", type:"normal", selected:false}, 
  {id: "createdDate",label: "Created Date", type:"date", selected:false},

  ]

export default function TransferReport(props) {
  const [transferReport, setTransferReport] = useState(props.reportData);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(null);

  useEffect(() => {
    setTransferReport(props.reportData);
  }, [props]);

  const HandleSort = (sort) => {
    setSort(sort);
    let sorted = _.orderBy(transferReport, [sort.value], ["asec"]);
    setTransferReport(sorted);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const downloadExcel = () => {
    const newData = transferReport.map((row) => {
      delete row.id;
      return row;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Transfer");
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "Transfer.xlsx");
  };

  //!
  const [open, setOpen] = useState(false);
  
  const [tableHead,setTableHead]=useState(TABLE_HEAD_CONST)

  const[newTable,setNewTable]=useState([
    {id: "transferStatus",label: "Transfer Status", type:"normal", selected:false},
  {id: "clientEmail",label: "Client Email", type:"normal", selected:false},    
  {id: "type",label: "Transfer Type", type:"normal", selected:false},
  {id: "amountRefunded",label: "Amount Refunded", type:"normal", selected:false},
  {id: "amountRetained",label: "Amount Retained", type:"normal", selected:false},    
  {id: "revisedCommission",label: "Revised Commission", type:"normal", selected:false},
  {id: "flightName",label: "Flight Name", type:"normal", selected:false},    
  {id: "flightAmount",label: "Flight Amount", type:"normal", selected:false},
  {id: "flightComission",label: "Flight Commission", type:"normal", selected:false},
  {id: "trainName",label: "Train Name", type:"normal", selected:false},
  {id: "trainAmount",label: "Train Amount", type:"normal", selected:false},
  {id: "trainComission",label: "Train Commission", type:"normal", selected:false},
  {id: "cabAmount",label: "Cab Amount", type:"normal", selected:false},    
  {id: "cabCommission",label: "Cab Commission", type:"normal", selected:false},  
  {id: "cabVehicle",label: "Cab Vehicle", type:"normal", selected:false}, 
  {id: "busName",label: "Bus Name", type:"normal", selected:false}, 
  {id: "busAmount",label: "Bus Amount", type:"normal", selected:false},  
  {id: "busCommission",label: "Bus Commission", type:"normal", selected:false}, 
  {id: "createdDate",label: "Created Date", type:"date", selected:false},

  ])
  const [headValues,setHeadValues]=useState([
    {id: "transferStatus",label: "Transfer Status", type:"normal", selected:false},
  {id: "clientEmail",label: "Client Email", type:"normal", selected:false},    
  {id: "type",label: "Transfer Type", type:"normal", selected:false},
  {id: "amountRefunded",label: "Amount Refunded", type:"normal", selected:false},
  {id: "amountRetained",label: "Amount Retained", type:"normal", selected:false},    
  {id: "revisedCommission",label: "Revised Commission", type:"normal", selected:false},
  {id: "flightName",label: "Flight Name", type:"normal", selected:false},    
  {id: "flightAmount",label: "Flight Amount", type:"normal", selected:false},
  {id: "flightComission",label: "Flight Commission", type:"normal", selected:false},
  {id: "trainName",label: "Train Name", type:"normal", selected:false},
  {id: "trainAmount",label: "Train Amount", type:"normal", selected:false},
  {id: "trainComission",label: "Train Commission", type:"normal", selected:false},
  {id: "cabAmount",label: "Cab Amount", type:"normal", selected:false},    
  {id: "cabCommission",label: "Cab Commission", type:"normal", selected:false},  
  {id: "cabVehicle",label: "Cab Vehicle", type:"normal", selected:false}, 
  {id: "busName",label: "Bus Name", type:"normal", selected:false}, 
  {id: "busAmount",label: "Bus Amount", type:"normal", selected:false},  
  {id: "busCommission",label: "Bus Commission", type:"normal", selected:false}, 
  {id: "createdDate",label: "Created Date", type:"date", selected:false},

  ])
    const handleChange=(i)=>{
    console.log(":Index:",i);
    const values=[...headValues];
    values[i].selected=!values[i].selected;
   
    setHeadValues(values)

  }
   const handleClose = () => {
    setOpen(false);
  };


  //!

  return (
    <div>
       <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{backgroundColor:"#000", color:"#fff", textAlign:"center", padding:4}} >
          <Typography variant="h6">

           Column Settings
          </Typography>
          </div>
        <DialogContent>
          
            <Grid container spacing={2}>
             {headValues.map((item,i)=>
             <React.Fragment key={i}>
            
             <Grid item xs={3}>
              <Typography variant="body1" sx={{fontWeight:800}}>{item.label}</Typography></Grid>
              <Grid item xs={3}>

          <Switch
            checked={item.selected}
            onChange={()=>handleChange(i)}
            // name="checkedB"
            color="primary"
          />
       
      
              
              </Grid> </React.Fragment>) }
            </Grid>
    
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{
            const values=headValues.filter(item=>item.selected===true);
            // const columns=JSON.parse(JSON.stringify(headValues))
            const columns=_.cloneDeep(headValues) 
            setTableHead([...TABLE_HEAD_CONST,...values])
            setNewTable(columns);
            handleClose()
          }} color="primary">
            Submit
          </Button>
          <Button onClick={()=>{
            setHeadValues(_.cloneDeep(newTable))
            handleClose()
            }}  color="secondary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item md={7}>
          <b>Transfer Report</b>
        </Grid>
        <Grid item md={2}>
          <div style={{ position: "relative" }}>
            <span
              style={{
                position: "absolute",
                top: "-9px",
                zIndex: "1",
                left: "12px",
                color: "#fff",
                background: "#F46D25",
                borderRadius: "4px",
                padding: "1px 4px",
                fontSize: "12px",
              }}
            >
              Sort By
            </span>
            <Select
              options={FinanceOpition}
              placeholder=""
              value={sort}
              onChange={HandleSort}
              styles={{
                container: (provided) => ({
                  ...provided,
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9,
                }),
                control: (base, state) => ({
                  ...base,
                  "&:hover": { borderColor: "#f46d25" },
                  borderColor: "#f46d25",
                  boxShadow: "none",
                }),
              }}
            />
          </div>
        </Grid>
        <Grid item md={2} style={{ textAlign: "right" }}>
          <Button size="small" onClick={downloadExcel}>
            Download As Excel
          </Button>
        </Grid>
        
        <Grid item md={1} style={{ textAlign: "right" }}>
          <Button onClick={()=>setOpen(true)}>
           Filter
          </Button>
        </Grid>
      </Grid>

      {/* <Paper sx={{ width: "100%", overflow: "hidden" }}> */}
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <StyledTableRow>
                {tableHead.map((column) => (
                  <StyledTableCell key={column.id} align={column.align}>
                    {column.label}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {transferReport
                .slice(
                    transferReport * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                .map((row, index) => {
                  return (
                    <TableRow key={row.id}>
                      <StyledTableCellInside
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.transferId}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.clientName}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.agentName}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.clientMobileNo}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.totalAmount}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.totalComission}
                      </StyledTableCellInside>
                      {newTable.filter?.(item=> item.selected===true)?.map((val,i)=>{
                          
                          
                          return <StyledTableCellInside key={i}>{row?.[val.id]}</StyledTableCellInside>
                          }
                          )}
                          
                    </TableRow>
                    
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          count={transferReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      {/* </Paper> */}
    </div>
  );
}
