import { Paper } from "@material-ui/core";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import parse from "html-react-parser";
import { baseurl } from "../../Service/httpCommon";

const ItineraryDayPlanner = ({
  itineraryData,
  classes,
  length,
  recordsPerPage,
}) => {
  console.log(itineraryData);
  return (
    <Paper variant="outlined" className={classes.paperStyle}>
      <section
        style={{
          border: "1px solid #f46d25",
          padding: "5px 0px 0px 5px",
          width: "100%",
          height: "100%",
        }}
      >
        <h2 style={{ marginLeft: "40px" }}>Daywise Itinerary</h2>
        <Timeline align="left">
          {itineraryData?.itineraryDataRequest?.map((itinerary, idx) => (
            <TimelineItem
              style={{
                marginBottom: "40px",
                display: "flex",
                alignItems: "center",
              }}
              key={itinerary.id}
            >
              <TimelineOppositeContent
                style={{
                  maxWidth: "1px",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                }}
              />

              <TimelineSeparator>
                <TimelineDot color="primary" />
                {Number(itinerary?.type) < length && idx !== 3 && (
                  <TimelineConnector
                    style={{
                      height: "180px",
                      position: "absolute",
                      marginTop: "40px",
                    }}
                  />
                )}
              </TimelineSeparator>

              <TimelineContent>
                <figure
                  style={{
                    display: "flex",
                    width: "100%",
                    marginLeft: "0px",
                    alignItems: "first-baseline",
                  }}
                >
                  <img
                    src={`${baseurl}getimage/${itinerary?.imagePath}`}
                    width="275px"
                    height="150px"
                  />

                  <figcaption
                    style={{ paddingLeft: "50px", marginTop: "-24px" }}
                  >
                    <h4>Day {itinerary?.type}</h4>
                    <div
                      style={{
                        fontSize: "13px",
                        wordWrap: "break-word",
                        width: "350px",
                        marginTop: "-10px",
                      }}
                    >
                      {parse(itinerary.data)}
                    </div>
                  </figcaption>
                </figure>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </section>
    </Paper>
  );
};

export default ItineraryDayPlanner;
