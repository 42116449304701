import React from "react"
import EditIcon from "@material-ui/icons/Edit"
import VisibilityIcon from "@material-ui/icons/Visibility"
import { styled } from "@mui/material/styles"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import {
  Dialog,
  Slide,
  Stack,
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material"
import { twnButtonStyles } from "../../utils/townoStyle"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f46d25",
    color: "#fff",
    fontSize: 14,
    // fontWeight: 'bold'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    backgroundColor: "#fff",
    boxShadow: "none",
    borderRadius: "5px",
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  border: "1px solid #eee",
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

export default function OnboardTable({ onboardTableData }) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [openPreview, setOpenPreview] = React.useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  return (
    <>
      <div>
        <Table size="small">
          <TableHead style={twnButtonStyles.headerStyle}>
            <TableRow>
              <StyledTableCell style={{ borderTopLeftRadius: "5px" }}>
                Agency Name
              </StyledTableCell>
              <StyledTableCell align="left">Type</StyledTableCell>
              <StyledTableCell align="left">Mobile</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">Name</StyledTableCell>
              <StyledTableCell align="left">Phone</StyledTableCell>
              <StyledTableCell align="left">Designation</StyledTableCell>
              <StyledTableCell align="left">City</StyledTableCell>
              <StyledTableCell align="left">Area of Operation</StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ borderTopRightRadius: "5px" }}
              >
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {onboardTableData.length > 0 ? (
            <TableBody>
              {onboardTableData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {row.travelAgencyName}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.type}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.mobile1}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.mail}</StyledTableCell>
                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                    <StyledTableCell align="left">{row.phone}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.designation}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.city}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.areaOfOperation}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="left"
                        style={{ color: "#f46d25" }}
                      >
                        <div
                          onClick={() => {
                            window.open(
                              `/konnect/creatUpdateOnboard/${row.id}`,
                              "_self"
                            )
                          }}
                        >
                          <Tooltip title="Edit" arrow>
                            <EditIcon
                              style={{ color: "#f46d25", cursor: "pointer" }}
                            />
                          </Tooltip>
                        </div>
                        <div
                          onClick={() => {
                            window.open(
                              `/konnect/viewOnboard/${row.id}`,
                              "_self"
                            )
                          }}
                        >
                          <Tooltip title="View" arrow>
                            <VisibilityIcon style={{ color: "#f46d25" }} />
                          </Tooltip>
                        </div>
                      </Stack>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          ) : (
            "Search Not Found"
          )}
        </Table>
      </div>

      <TablePagination
        component="div"
        count={onboardTableData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* <Dialog
                fullWidth={true}
                maxWidth={'xl'}
                sx={{ maxHeight: '100vh' }}
                open={openPreview}
                onClose={() => setOpenPreview(false)}
                TransitionComponent={Transition}
            >
                <div>
                    <ViewOnboard onboardTableData={onboardTableData} />
                </div>
                <div style={{ backgroundColor: '#eee' }}>
                    <Stack direction='row' spacing={2} justifyContent='center' style={{ margin: '1%' }}>
                        <button style={twnButtonStyles.blackBtn} autoFocus color="inherit" onClick={() => setOpenPreview(false)}>
                            Close
                        </button>
                    </Stack>
                </div>
            </Dialog> */}
    </>
  )
}
