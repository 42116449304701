import React, { useState } from "react";
import Slider from "react-slick";
import Dubai from "../../assets/AgentHomeBannerImages/Dubai.jpg";
import LonavalaImg from "../../assets/AgentHomeBannerImages/Lonavala.jpg";
import MahabaleahwarImg from "../../assets/AgentHomeBannerImages/Mahabaleshwar.jpg";
import ThailandImg from "../../assets/AgentHomeBannerImages/Thailand.jpg";
import GoaImage from "../../assets/AgentHomeBannerImages/goa.jpg";
import ManaliImg from "../../assets/AgentHomeBannerImages/manali.jpg";
import MussoorieImg from "../../assets/AgentHomeBannerImages/mussoorie.jpg";
import "./ImageSlider.css";

const bannerInfo = [
  { location: "goa", imagePath: GoaImage },
  {
    location: "lonavala",
    imagePath: LonavalaImg,
  },
  {
    location: "mahabaleshwar",
    imagePath: MahabaleahwarImg,
  },
  {
    location: "manali",
    imagePath: ManaliImg,
  },
  {
    location: "mussoorie",
    imagePath: MussoorieImg,
  },
  {
    location: "thailand",
    imagePath: ThailandImg,
  },
  {
    location: "dubai",
    imagePath: Dubai,
  },
];
export default function BannerSlider() {
  const [popularDestinations, setPopularDestinations] = useState(bannerInfo);

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <Slider {...settings} pauseOnHover>
      {popularDestinations.map((destination, index) => (
        <div className={`${destination.location}`} style={{ width: "100%" }}>
          <img
            src={`${destination.imagePath}`}
            alt={`${destination.location}`}
            style={{ width: "100%", height: "100%" }}
            loading="lazy"
          />
        </div>
      ))}
    </Slider>
  );
}
