import React from "react";
import "./Navbar.css";
import { Link, NavLink } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import StoreIcon from "@material-ui/icons/Store";
import "./Footer.scss";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import { Booking, Property, Lead, AgentBookings, Agent } from "./MoreT";
import GroupIcon from "@material-ui/icons/Group";
import DateRangeIcon from "@material-ui/icons/DateRangeOutlined";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";

const Footer = () => {
  let hasAdmin = localStorage.getItem("role");
  let hasDesignation = localStorage.getItem("designation");
  let checkAgent = localStorage.getItem("agent");
  console.log(localStorage.getItem("agent"));
  console.log(localStorage.getItem("designation"));
  console.log(localStorage.getItem("role"));

  const homeLinkProvider = (role) => {
    if (role === "Admin" || role === "ViewOnly") return "/dashboard";
    if (role === "Agent Admin" || checkAgent === "common") return "/agent";
  };

  const contentProvider = (role) => {
    if (role === "Admin" || role === "ViewOnly") return <Booking />;
    if (role === "Agent Admin" || checkAgent === "bronze")
      return <AgentBookings />;
  };

  return (
    <div className="footer">
      <div className="subHeader">
        <div className="rightMenu">
          <Link to={homeLinkProvider(hasAdmin)} className="more">
            <div className="item">
              <HomeIcon />
              <span className="caption">Home</span>
            </div>
          </Link>
        </div>
        {checkAgent !== "Common" && (
          <div className="rightMenu">
            <Tippy
              className="admin"
              theme="light"
              content={contentProvider(hasAdmin, checkAgent)}
              interactive={true}
            >
              <Link to="#" className="more">
                <div className="item">
                  <LocalOfferIcon />
                  <span className="caption">Booking</span>
                </div>
              </Link>
            </Tippy>
          </div>
        )}

        {(hasAdmin === "Admin" || hasAdmin === "Super Admin") && (
          <div className="rightMenu">
            <Tippy
              className="admin"
              theme="light"
              content={<Agent />}
              interactive={true}
            >
              <Link to="#" className="more">
                <div className="item">
                  <LocalLibraryIcon />
                  <span className="caption">Agency</span>
                </div>
              </Link>
            </Tippy>
          </div>
        )}

        {hasAdmin === "Admin" ||
        hasAdmin === "Super Admin" ||
        hasAdmin === "Sales" ||
        hasDesignation === "Lead Manager" ? (
          <div className="rightMenu">
            <Link to="/lead" className="more">
              <div className="item">
                <GroupIcon />
                <span className="caption">Leads</span>
              </div>
            </Link>
          </div>
        ) : null}

        {hasAdmin === "Admin" || hasAdmin === "Super Admin" ? (
          <div className="rightMenu">
            <Tippy
              className="admin"
              theme="light"
              content={<Property />}
              interactive={true}
            >
              <Link to="#" className="more">
                <div className="item">
                  <StoreIcon />
                  <span className="caption">Property</span>
                </div>
              </Link>
            </Tippy>
          </div>
        ) : (
          checkAgent !== "Common" && (
            <div className="rightMenu">
              <Tippy
                className="admin"
                theme="light"
                content={<Property />}
                interactive={true}
              >
                <Link to="#" className="more">
                  <div className="item">
                    <StoreIcon />
                    <span className="caption">Property</span>
                  </div>
                </Link>
              </Tippy>
            </div>
          )
        )}

        {checkAgent === "Common" && (
          <div className="rightMenu">
            <Tippy
              className="admin"
              theme="light"
              content={<Property />}
              interactive={true}
            >
              <NavLink to="/roomListView" className="more">
                <div className="item">
                  <DateRangeIcon />
                  <span className="caption">Calender View</span>
                </div>
              </NavLink>
            </Tippy>
          </div>
        )}
      </div>
    </div>
  );
};

<NavLink to="/roomListView" activeClassName="active-link">
  Calender View
</NavLink>;

export default Footer;
