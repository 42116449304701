/* eslint-disable eqeqeq */
import DateFnsUtils from "@date-io/date-fns"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import AcUnitIcon from "@material-ui/icons/AcUnit"
import BrightnessHighIcon from "@material-ui/icons/BrightnessHigh"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import WhatshotTwoToneIcon from "@material-ui/icons/WhatshotTwoTone"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { ErrorMessage, Field, Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import * as Yup from "yup"
import Api from "../../Service/Api"
import MaterialSelect from "../../components/Select/MaterialSelect"
import { Numbers } from "./Data"
import _ from "lodash"

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "100%",
    minWidth: "75%",
    position: "absolute",
    margin: "0px",
    right: "0",
    zIndex: "1000",
    padding: "20px",
    "@media (max-width: 767px)": {
      position: "absolute",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      height: "100%",
      overflowY: "scroll",
      maxWidth: "100%",
      minHeight: "95%",
    },
  },
  error: {
    color: "red",
  },
  icon: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    position: "absolute",
    right: "15px",
    top: "5px",
    color: "#f46d25",
  },
  paper: {
    padding: "15px 20px",
    marginLeft: "3px",
    width: "95.79%",
    marginTop: "-1.6px",
    boxShadow: "0px 1px 3px 1px #343a40b8",
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
    background: "#F4F4F4",
    "@media (max-width: 767px)": {
      boxShadow: "none",
      padding: "10px",
    },
  },
  heading: {
    fontWeight: "bold",
    fontSize: "20px",
    color: "#fff",
    margin: "10px",
    "@media (max-width: 767px)": {
      display: "none",
    },
  },
  root: {
    display: "-webkit-box",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
    minHeight: "30px",
  },
  chip: {
    margin: theme.spacing(0.5),
    color: "#fff",
  },
}))

const initialValues = {
  propertyId: "",
  propertyName: "",
  displayName: "",
  noofrooms: "",
  noofadults: "",
  noofchild: "",
  checkin: null,
  checkout: null,
  mealplan: "",
  bookingStatus: "",
  assignTo: "",
  leadscoring: "new",
  leadscoringvalue: "",
  bookingId: "",
}
const BoardBasic = [
  { value: "ep", label: "EP" },
  { value: "cp", label: "CP" },
  { value: "map", label: "MAP" },
  { value: "ap", label: "AP" },
]
export default function LeadAgentForm(props) {
  const classes = useStyles()
  var uniqueid = localStorage.getItem("unique_id")
  var createdBy = localStorage.getItem("auth")
  const [assignTo, setLeadassignTo] = useState([])
  //const hotelDetails = useSelector((state) => state.hotelDetail.hotelDetails);
  const [adult, setAdult] = useState(0)
  const [propertyList, setPropertyList] = useState([])
  const [childern, setChildern] = useState(0)
  const [noofRooms, setNoofRooms] = useState(0)
  const [meal, setMeal] = useState("")
  const [room, setRoom] = useState("")
  const [propertyId, setPropertyId] = useState("")
  const [checkin, setCheckin] = useState(null)
  const [checkout, setCheckout] = useState(null)
  const create = { uniqueId: uniqueid, createdBy: createdBy }
  const { onClose, open, selectedId } = props
  const [agentLead, setAgentLead] = useState({})
  const [leadscorings, setLeadscoring] = useState(false)
  const [leadscoring, setLeadscorings] = useState(false)
  const [leadSource, setLeadSource] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)
  const [leadScoringType, setLeadScoringType] = useState("")

  useEffect(() => {
    LeadassignTo()
    LeadSource()
    if (selectedId) {
      agentLeadSingleData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId])
  const agentLeadSingleData = async () => {
    Api.get(`agenttryingbooking/${selectedId}`).then((res) => {
      console.log(res.data)
      setAgentLead({
        ...res.data,
        leadsource: "Travel Agent",
        leadscoring: _.isNull(res.data.leadscoring)
          ? "new"
          : res.data.leadscoring,
      })
      setLeadScoringType(
        _.isNull(res.data.leadscoring) ? "new" : res.data.leadscoring
      )
      setAdult(res.data.noofadults)
      setChildern(res.data.noofchild)
      setNoofRooms(res.data.noofrooms)
      setRoom(res.data.displayName)
      setMeal(res.data.mealplan)
      setCheckin(res.data.checkin)
      setCheckout(res.data.checkout)
      setPropertyId(res.data.propertyId)
      Api.get(`getdisplayname/${res.data.propertyId}`).then((res) => {
        console.log(res.data)
        setPropertyList(res.data)
      })
    })
  }
  const LeadassignTo = (event) => {
    Api.get(`leadassignfor/${uniqueid}`).then((res) => {
      setLeadassignTo(res.data)
    })
  }

  const Hoteloptions =
    propertyList &&
    propertyList.map((hotel) => {
      return { label: hotel.visibleName, value: hotel.displayName }
    })
  const handleAdultChange = (selectedOption) => {
    setAdult(selectedOption.value)
  }
  const handleChildernChange = (selectedOption) => {
    setChildern(selectedOption.value)
  }
  const handlenoofRoomsChange = (selectedOption) => {
    setNoofRooms(selectedOption.value)
  }
  const handlemealChange = (selectedOption) => {
    setMeal(selectedOption.value)
  }
  const handleroomChange = (selectedOption) => {
    setRoom(selectedOption.label)
  }
  const LeadSource = () => {
    Api.get(`commonfeatureonly/${uniqueid}/leadSource`).then((res) => {
      console.log(res.data)
      setLeadSource(res.data)
    })
  }
  const onSubmit = async (fields, { setStatus }) => {
    const id = selectedId
    setStatus()
    if (id) {
      updateagentLead(fields)
    } else {
      createagentLead(fields)
    }
  }

  async function createagentLead(fields) {
    if (fields.leadscoring !== "closed") {
      fields.bookingId = ""
    }
    const newdata = { ...fields, ...create }
    Api.post("agenttryingbooking", newdata).then((res) => {
      onClose(true)
      toast.success("Lead Created Successfull")
    })
  }
  async function updateagentLead(fields) {
    console.log({ fields })
    if (fields.leadscoring !== "closed") {
      fields.bookingId = ""
    }
    const newdata = { ...fields, ...create }
    newdata.propertyId = propertyId
    newdata.displayName = room
    newdata.noofrooms = noofRooms
    newdata.noofadults = adult
    newdata.noofchild = childern
    newdata.checkin = checkin
    newdata.checkout = checkout
    newdata.mealplan = meal
    console.log(newdata)
    const newsdata = { ...fields, ...create }
    console.log(newsdata)
    Api.put(`agenttryingbooking/${selectedId}`, newdata)

      .then((res) => {
        if (res.status == 200) {
          toast.success("Lead Update Successfull")
          return
        }

        throw new Error()
      })
      .catch((error) =>
        toast.error("Unexpected error occurred. Try after sometime.")
      )
      .finally(() => {
        onClose(true)
      })
  }

  const validationSchema = Yup.object({
    leadsource: Yup.string().required("Source is Required"),
    assignTo: Yup.string().required("Lead Assigned To is Required"),
    bookingId: Yup.string().when("leadscoring", {
      is: "closed",
      then: Yup.string().nullable().required("Booking ID is required"),
      otherwise: Yup.string().nullable(),
    }),
  })
  const handleClose = () => {
    onClose(true)
    setAgentLead(initialValues)
  }
  const leadassignTooptions =
    assignTo &&
    assignTo.map((lead) => {
      return { label: lead.name, value: lead.username }
    })
  const LeadSourceoptions =
    leadSource &&
    leadSource.map((lead) => {
      return { label: lead, value: lead }
    })

  const [width, setWidth] = useState(window.innerWidth)
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [width])

  const [expandedAccordion, setExpandedAccordion] = useState(0)

  const handleAccordionChange = (index) => {
    setExpandedAccordion(index)
  }

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {width <= 768 ? (
          <Modal
            open={open}
            onClose={handleClose}
            style={{ top: "63px", padding: "10px" }}
          >
            <Box style={{ background: "white" }}>
              <h3 style={{ color: "#F46D25", margin: "0px", padding: "10px" }}>
                {agentLead.id ? "Update Agent Lead" : "Add New Lead"}
              </h3>
              <Formik
                initialValues={agentLead || initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize
              >
                {({ values, isValid, setFieldValue, isSubmitting }) => {
                  return (
                    <Form autoComplete="off">
                      <Accordion
                        style={{
                          borderRadius: "6px",
                          marginBottom: "10px",
                          marginTop: "-15%",
                          boxShadow: "none",
                        }}
                        expanded={expandedAccordion === 0}
                        onChange={() => handleAccordionChange(0)}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon style={{ color: "#fff" }} />
                          }
                          style={{
                            background: "#343A40",
                            color: "#fff",
                            borderRadius: "6px",
                            marginTop: "20%",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "20px", fontWeight: "bold" }}
                          >
                            Travel Details
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Field
                                name="propertyName"
                                as={TextField}
                                label="Property Name"
                                value={
                                  values.propertyName || agentLead.propertyName
                                }
                                autoFocus
                                variant="outlined"
                                required
                                disabled
                                fullWidth
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <MaterialSelect
                                name="room"
                                placeholder="Select Room"
                                value={room || ""}
                                onChange={handleroomChange}
                                options={Hoteloptions}
                              />
                              <ErrorMessage name="room">
                                {(error) => (
                                  <div className={classes.error}>{error}</div>
                                )}
                              </ErrorMessage>
                            </Grid>
                            <Grid item xs={6}>
                              <DatePicker
                                label="Check-In"
                                inputVariant="outlined"
                                fullWidth
                                size="small"
                                animateYearScrolling
                                format="dd/MM/yyyy"
                                variant="inline"
                                autoOk="true"
                                value={checkin || agentLead.checkin}
                                onChange={(e) => setCheckin(e)}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <DatePicker
                                label="Check-Out"
                                inputVariant="outlined"
                                size="small"
                                fullWidth
                                format="dd/MM/yyyy"
                                animateYearScrolling
                                variant="inline"
                                autoOk="true"
                                value={checkout || agentLead.checkout}
                                onChange={(e) => setCheckout(e)}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <MaterialSelect
                                name="noofRooms"
                                placeholder="No. of Rooms"
                                value={noofRooms || agentLead.noofrooms}
                                onChange={handlenoofRoomsChange}
                                options={Numbers}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <MaterialSelect
                                name="adult"
                                placeholder="Adult"
                                value={adult || agentLead.noofadults}
                                onChange={handleAdultChange}
                                options={Numbers}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <MaterialSelect
                                name="childern"
                                placeholder="Childern"
                                value={childern || agentLead.noofchild}
                                onChange={handleChildernChange}
                                options={Numbers}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <MaterialSelect
                                name="mealPlan"
                                placeholder="Meal plan"
                                value={meal || agentLead.mealplan}
                                onChange={handlemealChange}
                                options={BoardBasic}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        style={{
                          borderRadius: "6px",
                          marginBottom: "10px",
                          marginTop: "-15%",
                          boxShadow: "none",
                        }}
                        expanded={expandedAccordion === 1}
                        onChange={() => handleAccordionChange(1)}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon style={{ color: "#fff" }} />
                          }
                          style={{
                            background: "#343A40",
                            color: "#fff",
                            borderRadius: "6px",
                            marginTop: "20%",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "20px", fontWeight: "bold" }}
                          >
                            Lead Status
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Field
                                as={MaterialSelect}
                                name="assignTo"
                                placeholder="Lead Assigned To *"
                                options={
                                  leadassignTooptions || agentLead.assignTo
                                }
                                value={values.assignTo || ""}
                                onChange={(Value) => {
                                  let lead = Value.value
                                  setFieldValue("assignTo", lead)
                                }}
                              />
                              <ErrorMessage name="assignTo">
                                {(error) => (
                                  <div className={classes.error}>{error}</div>
                                )}
                              </ErrorMessage>
                            </Grid>
                            <Grid item xs={12}>
                              <MaterialSelect
                                name="leadsource"
                                placeholder="Lead Source *"
                                options={LeadSourceoptions}
                                value={
                                  values.leadsource || agentLead.leadsource
                                }
                                onChange={(Value) => {
                                  let lead = Value.value
                                  setFieldValue("leadsource", lead)
                                }}
                              />
                              <ErrorMessage name="leadsource">
                                {(error) => (
                                  <div className={classes.error}>{error}</div>
                                )}
                              </ErrorMessage>
                            </Grid>
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                  marginTop: "5px",
                                  color: "#f46d25",
                                }}
                              >
                                Lead Scoring* :
                              </Grid>
                              <Grid item xs={3} style={{ textAlign: "center" }}>
                                <div style={{ marginLeft: "20px" }}>
                                  <FormControlLabel
                                    control={
                                      <Field
                                        as={Radio}
                                        type="radio"
                                        name="leadscoring"
                                        color="primary"
                                        onClick={() =>
                                          setLeadscoring(true) ||
                                          setLeadscorings(false)
                                        }
                                        value="new"
                                        style={{
                                          fontSize: "12px",
                                          color: "#f46d25",
                                        }}
                                      />
                                    }
                                    label={
                                      <span style={{ fontSize: "15px" }}>
                                        New
                                      </span>
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={3} style={{ textAlign: "center" }}>
                                <div style={{ marginLeft: "20px" }}>
                                  <FormControlLabel
                                    control={
                                      <Field
                                        as={Radio}
                                        type="radio"
                                        name="leadscoring"
                                        color="primary"
                                        value="followup"
                                        onClick={() =>
                                          setLeadscoring(true) ||
                                          setLeadscorings(false)
                                        }
                                        style={{
                                          fontSize: "12px",
                                          color: "#f46d25",
                                        }}
                                      />
                                    }
                                    label={
                                      <span style={{ fontSize: "15px" }}>
                                        Follow up
                                      </span>
                                    }
                                  />
                                </div>
                              </Grid>{" "}
                              <Grid item xs={3} style={{ textAlign: "center" }}>
                                <div style={{ marginLeft: "20px" }}>
                                  <FormControlLabel
                                    control={
                                      <Field
                                        as={Radio}
                                        type="radio"
                                        name="leadscoring"
                                        color="primary"
                                        onClick={() =>
                                          setLeadscoring(false) ||
                                          setLeadscorings(false)
                                        }
                                        value="closed"
                                        style={{
                                          fontSize: "12px",
                                          color: "#f46d25",
                                        }}
                                      />
                                    }
                                    label={
                                      <span style={{ fontSize: "15px" }}>
                                        Closed
                                      </span>
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={3} style={{ textAlign: "center" }}>
                                <div style={{ marginLeft: "20px" }}>
                                  <FormControlLabel
                                    control={
                                      <Field
                                        as={Radio}
                                        type="radio"
                                        name="leadscoring"
                                        onClick={() =>
                                          setLeadscoring(false) ||
                                          setLeadscorings(true)
                                        }
                                        color="primary"
                                        value="lost"
                                        style={{
                                          fontSize: "12px",
                                          color: "#f46d25",
                                        }}
                                      />
                                    }
                                    label={
                                      <span style={{ fontSize: "15px" }}>
                                        Lost
                                      </span>
                                    }
                                  />
                                </div>
                              </Grid>
                              {(leadScoringType === "followup" ||
                                leadscorings === "true") && (
                                <>
                                  <Grid
                                    item
                                    md={4}
                                    sm={12}
                                    xs={12}
                                    style={{ textAlign: "center" }}
                                  />
                                  <Grid
                                    item
                                    md={2}
                                    sm={12}
                                    xs={12}
                                    style={{ textAlign: "center" }}
                                  >
                                    <div style={{ marginLeft: "20px" }}>
                                      <FormControlLabel
                                        control={
                                          <Field
                                            as={Radio}
                                            type="radio"
                                            name="leadscoringvalue"
                                            color="primary"
                                            value="followuphot"
                                            style={{
                                              fontSize: "12px",
                                              color: "#f46d25",
                                            }}
                                            checkedIcon={<CheckBoxIcon />}
                                            icon={<CheckBoxOutlineBlankIcon />}
                                          />
                                        }
                                        label={
                                          <span style={{ fontSize: "15px" }}>
                                            <Grid continer spacing={2}>
                                              <Grid item lg={7}>
                                                <p
                                                  style={{
                                                    color: "#e93646",
                                                    fontWeight: "bold",
                                                    marginTop: "15px",
                                                    marginLeft: "1px",
                                                    marginBottom: "-8px",
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  Hot
                                                </p>
                                              </Grid>
                                              <Grid item lg={4}>
                                                <WhatshotTwoToneIcon
                                                  style={{
                                                    marginTop: "-60px",
                                                    marginLeft: "55px",
                                                    marginBottom: "6px",
                                                    color: "DF2038",
                                                  }}
                                                />
                                              </Grid>
                                            </Grid>
                                          </span>
                                        }
                                      />
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    sm={12}
                                    xs={12}
                                    style={{ textAlign: "center" }}
                                  >
                                    <div style={{ marginLeft: "20px" }}>
                                      <FormControlLabel
                                        control={
                                          <Field
                                            as={Radio}
                                            type="radio"
                                            name="leadscoringvalue"
                                            color="primary"
                                            value="followupcold"
                                            style={{
                                              fontSize: "12px",
                                              color: "#f46d25",
                                            }}
                                            checkedIcon={<CheckBoxIcon />}
                                            icon={<CheckBoxOutlineBlankIcon />}
                                          />
                                        }
                                        label={
                                          <span style={{ fontSize: "15px" }}>
                                            <Grid continer spacing={2}>
                                              <Grid item lg={7}>
                                                <h5
                                                  style={{
                                                    color: "#1f59a6",
                                                    fontWeight: "bold",
                                                    marginTop: "15px",
                                                    marginLeft: "1px",
                                                    marginBottom: "-8px",
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  Cold
                                                </h5>
                                              </Grid>
                                              <Grid item lg={4}>
                                                <AcUnitIcon
                                                  style={{
                                                    marginTop: "-60px",
                                                    marginLeft: "55px",
                                                    marginBottom: "6px",
                                                    color: "2356A5",
                                                  }}
                                                />
                                              </Grid>
                                            </Grid>
                                          </span>
                                        }
                                      />
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    md={2}
                                    sm={12}
                                    xs={12}
                                    style={{ textAlign: "center" }}
                                  >
                                    <div style={{ marginLeft: "20px" }}>
                                      <FormControlLabel
                                        control={
                                          <Field
                                            as={Radio}
                                            type="radio"
                                            name="leadscoringvalue"
                                            color="primary"
                                            value="followupwarm"
                                            style={{
                                              fontSize: "12px",
                                              color: "#f46d25",
                                            }}
                                            checkedIcon={<CheckBoxIcon />}
                                            icon={<CheckBoxOutlineBlankIcon />}
                                          />
                                        }
                                        label={
                                          <span style={{ fontSize: "15px" }}>
                                            <Grid continer spacing={2}>
                                              <Grid item lg={7}>
                                                <p
                                                  style={{
                                                    color: "#e48435",
                                                    fontWeight: "bold",
                                                    marginTop: "15px",
                                                    marginLeft: "1px",
                                                    marginBottom: "-8px",
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  Warm
                                                </p>
                                              </Grid>
                                              <Grid item lg={4}>
                                                <BrightnessHighIcon
                                                  style={{
                                                    marginLeft: "55px",
                                                    marginTop: "-60px",
                                                    marginBottom: "6px",
                                                    color: "EA8A23",
                                                  }}
                                                />
                                              </Grid>
                                            </Grid>
                                          </span>
                                        }
                                      />
                                    </div>
                                  </Grid>
                                </>
                              )}
                              {(leadScoringType === "lost" ||
                                leadscoring == true) && (
                                <>
                                  <Grid item md={12} sm={12} xs={12}>
                                    <Field
                                      as={TextField}
                                      name="reason"
                                      type="text"
                                      fullWidth
                                      label="Reason *"
                                      variant="outlined"
                                      size="small"
                                    />
                                    <ErrorMessage name="reason">
                                      {(error) => (
                                        <div className={classes.error}>
                                          {error}
                                        </div>
                                      )}
                                    </ErrorMessage>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>

                      <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          type="submit"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          Update
                        </Button>

                        <Button
                          color="secondary"
                          onClick={handleClose}
                          style={{
                            background: "#121212",
                            color: "#fff",
                            margin: "10px",
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Form>
                  )
                }}
              </Formik>
            </Box>
          </Modal>
        ) : (
          <Dialog
            classes={{ paper: classes.dialogPaper }}
            open={open}
            onClose={handleClose}
            fullWidth
          >
            <h2 style={{ color: "#F46D25", margin: "0px" }}>
              {agentLead.id ? "Update Agent Lead" : "Add New Lead"}
            </h2>
            <Formik
              initialValues={agentLead || initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              enableReinitialize
            >
              {({ values, isValid, setFieldValue, isSubmitting }) => {
                return (
                  <Form autoComplete="off">
                    <Grid
                      container
                      spacing={2}
                      item
                      lg={12}
                      style={{
                        backgroundColor: "black",
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                        boxShadow: "0px 1px 3px 1px #343a40b8",
                        marginLeft: "2px",
                        marginTop: "10px",
                        marginBottom: "2px",
                      }}
                    >
                      <h4 className={classes.heading}>Travel Details</h4>
                    </Grid>
                    <div className={classes.paper}>
                      <Grid container spacing={2}>
                        <Grid item lg={6}>
                          <Field
                            name="propertyName"
                            as={TextField}
                            label="Property Name"
                            value={
                              values.propertyName || agentLead.propertyName
                            }
                            autoFocus
                            variant="outlined"
                            required
                            disabled
                            fullWidth
                            size="small"
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <MaterialSelect
                            name="room"
                            placeholder="Select Room"
                            value={room || ""}
                            onChange={handleroomChange}
                            options={Hoteloptions}
                          />{" "}
                          <ErrorMessage name="room">
                            {(error) => (
                              <div className={classes.error}>{error}</div>
                            )}
                          </ErrorMessage>
                        </Grid>
                        <Grid item lg={6}>
                          <DatePicker
                            label="Check-In"
                            inputVariant="outlined"
                            fullWidth
                            size="small"
                            animateYearScrolling
                            format="dd/MM/yyyy"
                            variant="inline"
                            autoOk="true"
                            value={checkin || agentLead.checkin}
                            onChange={(e) => setCheckin(e)}
                          />
                        </Grid>
                        <Grid item lg={6}>
                          <DatePicker
                            label="Check-Out"
                            inputVariant="outlined"
                            size="small"
                            fullWidth
                            format="dd/MM/yyyy"
                            animateYearScrolling
                            variant="inline"
                            autoOk="true"
                            value={checkout || agentLead.checkout}
                            onChange={(e) => setCheckout(e)}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <MaterialSelect
                            name="noofRooms"
                            placeholder="No. of Rooms"
                            value={noofRooms || agentLead.noofrooms}
                            onChange={handlenoofRoomsChange}
                            options={Numbers}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <MaterialSelect
                            name="adult"
                            placeholder="Adult"
                            value={adult || agentLead.noofadults}
                            onChange={handleAdultChange}
                            options={Numbers}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <MaterialSelect
                            name="childern"
                            placeholder="Childern"
                            value={childern || agentLead.noofchild}
                            onChange={handleChildernChange}
                            options={Numbers}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <MaterialSelect
                            name="mealPlan"
                            placeholder="Meal plan"
                            value={meal || agentLead.mealplan}
                            onChange={handlemealChange}
                            options={BoardBasic}
                          />
                        </Grid>
                      </Grid>
                    </div>{" "}
                    <Grid
                      container
                      spacing={2}
                      item
                      lg={12}
                      style={{
                        backgroundColor: "black",
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                        boxShadow: "0px 1px 3px 1px #343a40b8",
                        marginLeft: "2px",
                        marginTop: "10px",
                        marginBottom: "2px",
                      }}
                    >
                      <h4 className={classes.heading}>Lead Status</h4>
                    </Grid>
                    <div className={classes.paper}>
                      <Grid container spacing={2}>
                        <Grid item md={6} sm={12} xs={12}>
                          <Field
                            as={MaterialSelect}
                            name="assignTo"
                            placeholder="Lead Assigned To *"
                            options={leadassignTooptions || agentLead.assignTo}
                            value={values.assignTo || ""}
                            onChange={(Value) => {
                              let lead = Value.value
                              setFieldValue("assignTo", lead)
                            }}
                          />
                          <ErrorMessage name="assignTo">
                            {(error) => (
                              <div className={classes.error}>{error}</div>
                            )}
                          </ErrorMessage>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <MaterialSelect
                            name="leadsource"
                            placeholder="Lead Source *"
                            options={LeadSourceoptions}
                            value={values.leadsource || agentLead.leadsource}
                            onChange={(Value) => {
                              let lead = Value.value
                              setFieldValue("leadsource", lead)
                            }}
                          />
                          <ErrorMessage name="leadsource">
                            {(error) => (
                              <div className={classes.error}>{error}</div>
                            )}
                          </ErrorMessage>
                        </Grid>
                      </Grid>
                      <Grid container xs={12} style={{ marginTop: "20px" }}>
                        <Grid
                          item
                          xs={3}
                          style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            marginTop: "5px",
                            color: "#f46d25",
                            textAlign: "center",
                          }}
                        >
                          Lead Scoring * :
                        </Grid>
                        <Grid item container xs={9}>
                          <Field
                            as={RadioGroup}
                            name="leadscoring"
                            value={values.leadscoring}
                            onChange={(e) => {
                              const { value } = e.target
                              setFieldValue("leadscoring", value)
                              console.log(value)
                              setFieldValue(
                                "leadscoringvalue",
                                value !== "followup" ? "" : "followuphot"
                              )
                            }}
                            style={{ width: "100%" }}
                          >
                            <Grid item container xs={12} spacing={1}>
                              <Grid item xs={3} style={{ textAlign: "left" }}>
                                <Field
                                  as={FormControlLabel}
                                  style={{ width: "100%" }}
                                  value="new"
                                  control={
                                    <Field
                                      as={Radio}
                                      color="primary"
                                      style={{
                                        fontSize: "12px",
                                        color: "#f46d25",
                                      }}
                                    />
                                  }
                                  label={
                                    <span style={{ fontSize: "15px" }}>
                                      New
                                    </span>
                                  }
                                />
                              </Grid>
                              <Grid item xs={3} style={{ textAlign: "left" }}>
                                <Field
                                  as={FormControlLabel}
                                  value="followup"
                                  style={{ width: "100%" }}
                                  control={
                                    <Field
                                      as={Radio}
                                      color="primary"
                                      style={{
                                        fontSize: "12px",
                                        color: "#f46d25",
                                      }}
                                    />
                                  }
                                  label={
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        width: "100%",
                                      }}
                                    >
                                      Follow up
                                    </span>
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                style={{ textAlign: "center", width: "100%" }}
                              >
                                <Field
                                  as={FormControlLabel}
                                  value="closed"
                                  control={
                                    <Field
                                      as={Radio}
                                      color="primary"
                                      style={{
                                        fontSize: "12px",
                                        color: "#f46d25",
                                      }}
                                    />
                                  }
                                  label={
                                    <span style={{ fontSize: "15px" }}>
                                      Closed
                                    </span>
                                  }
                                />
                                <Field
                                  as={TextField}
                                  name="bookingId"
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  placeholder="Booking Id"
                                  label="Booking ID"
                                  required={values.leadscoring == "closed"}
                                  // InputLabelProps={{ shrink: true }}
                                />
                                <ErrorMessage name="bookingId">
                                  {(error) => (
                                    <div className={classes.error}>{error}</div>
                                  )}
                                </ErrorMessage>
                              </Grid>
                              <Grid item xs={3} style={{ textAlign: "center" }}>
                                <Field
                                  as={FormControlLabel}
                                  value="lost"
                                  control={
                                    <Field
                                      as={Radio}
                                      color="primary"
                                      style={{
                                        fontSize: "12px",
                                        color: "#f46d25",
                                      }}
                                    />
                                  }
                                  label={
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        width: "100%",
                                      }}
                                    >
                                      Lost
                                    </span>
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Field>
                        </Grid>
                      </Grid>
                      <>
                        {values.leadscoring === "followup" && (
                          <Field
                            as={RadioGroup}
                            name="leadscoringvalue"
                            value={values.leadscoringvalue}
                            onChange={(e) => {
                              const { value } = e.target
                              setFieldValue("leadscoringvalue", value)
                              console.log(value)
                            }}
                          >
                            <Grid
                              item
                              container
                              xs={12}
                              spacing={1}
                              justifyContent="center"
                            >
                              <Grid item xs={2} style={{ textAlign: "center" }}>
                                <div style={{ marginLeft: "20px" }}>
                                  <FormControlLabel
                                    value="followuphot"
                                    control={
                                      <Field
                                        as={Radio}
                                        color="primary"
                                        style={{
                                          fontSize: "12px",
                                          color: "#f46d25",
                                        }}
                                        checkedIcon={<CheckBoxIcon />}
                                        icon={<CheckBoxOutlineBlankIcon />}
                                        size="small"
                                      />
                                    }
                                    label={
                                      <span style={{ fontSize: "15px" }}>
                                        <Grid continer spacing={2}>
                                          <Grid item lg={7}>
                                            <p
                                              style={{
                                                color: "#e93646",
                                                fontWeight: "bold",
                                                marginTop: "15px",
                                                marginLeft: "1px",
                                                marginBottom: "-8px",
                                                fontSize: "16px",
                                              }}
                                            >
                                              Hot
                                            </p>
                                          </Grid>
                                          <Grid item lg={4}>
                                            <WhatshotTwoToneIcon
                                              style={{
                                                marginTop: "-60px",
                                                marginLeft: "55px",
                                                marginBottom: "6px",
                                                color: "DF2038",
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </span>
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={2} style={{ textAlign: "center" }}>
                                <div style={{ marginLeft: "20px" }}>
                                  <FormControlLabel
                                    value="followupcold"
                                    control={
                                      <Field
                                        as={Radio}
                                        color="primary"
                                        style={{
                                          fontSize: "12px",
                                          color: "#f46d25",
                                        }}
                                        checkedIcon={<CheckBoxIcon />}
                                        icon={<CheckBoxOutlineBlankIcon />}
                                        size="small"
                                      />
                                    }
                                    label={
                                      <span style={{ fontSize: "15px" }}>
                                        <Grid continer spacing={2}>
                                          <Grid item lg={7}>
                                            <h5
                                              style={{
                                                color: "#1f59a6",
                                                fontWeight: "bold",
                                                marginTop: "15px",
                                                marginLeft: "1px",
                                                marginBottom: "-8px",
                                                fontSize: "16px",
                                              }}
                                            >
                                              Cold
                                            </h5>
                                          </Grid>
                                          <Grid item lg={4}>
                                            <AcUnitIcon
                                              style={{
                                                marginTop: "-60px",
                                                marginLeft: "55px",
                                                marginBottom: "6px",
                                                color: "2356A5",
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </span>
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={2} style={{ textAlign: "left" }}>
                                <div style={{ marginLeft: "20px" }}>
                                  <FormControlLabel
                                    value="followupwarm"
                                    control={
                                      <Field
                                        as={Radio}
                                        color="primary"
                                        style={{
                                          fontSize: "12px",
                                          color: "#f46d25",
                                        }}
                                        checkedIcon={<CheckBoxIcon />}
                                        icon={<CheckBoxOutlineBlankIcon />}
                                        size="small"
                                      />
                                    }
                                    label={
                                      <span style={{ fontSize: "15px" }}>
                                        <Grid continer spacing={2}>
                                          <Grid item lg={7}>
                                            <p
                                              style={{
                                                color: "#e48435",
                                                fontWeight: "bold",
                                                marginTop: "15px",
                                                marginLeft: "1px",
                                                marginBottom: "-8px",
                                                fontSize: "16px",
                                              }}
                                            >
                                              Warm
                                            </p>
                                          </Grid>
                                          <Grid item lg={4}>
                                            <BrightnessHighIcon
                                              style={{
                                                marginLeft: "55px",
                                                marginTop: "-60px",
                                                marginBottom: "6px",
                                                color: "EA8A23",
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </span>
                                    }
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Field>
                        )}
                        {values.leadscoring === "lost" && (
                          <>
                            <Grid item md={12} sm={12} xs={12}>
                              <Field
                                as={TextField}
                                name="reason"
                                type="text"
                                fullWidth
                                label="Reason *"
                                variant="outlined"
                                size="small"
                              />
                              <ErrorMessage name="reason">
                                {(error) => (
                                  <div className={classes.error}>{error}</div>
                                )}
                              </ErrorMessage>
                            </Grid>
                          </>
                        )}
                      </>
                    </div>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ textAlign: "center" }}
                    >
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <i className="fa fa-circle-o-notch fa-spin" />
                        )}
                        Update
                      </Button>

                      <Button
                        color="secondary"
                        onClick={handleClose}
                        style={{
                          background: "#121212",
                          color: "#fff",
                          margin: "10px",
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Form>
                )
              }}
            </Formik>
          </Dialog>
        )}
      </MuiPickersUtilsProvider>
    </>
  )
}
