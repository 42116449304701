import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  styled,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const StyledTableCellInside = styled(TableCell)(({ theme }) => ({
  padding: "5px",
  fontSize: "14px",
  lineHeight: "15px",
}));

const TownoBookingListTable = ({ row }) => {
  const history = useHistory();

  {
    /*So there are few changes in the variableName returned by the api for this particular table.
So, creating new component was necessary instead of writing much more messed up code*/
  }
  return (
    <TableRow key={row.id} style={{ height: "40px" }}>
      <StyledTableCellInside
        style={{
          textTransform: "capitalize",
          color: "#f46d25",
          fontWeight: "bold",
        }}
        align="center"
      >
        {row.bookingId}
      </StyledTableCellInside>
      <StyledTableCellInside align="center">
        {row.clientName}
      </StyledTableCellInside>
      <StyledTableCellInside align="center">
        {row?.checkin?.split("-").reverse().join("-")}
      </StyledTableCellInside>
      <StyledTableCellInside align="center">
        {row?.checkout?.split("-").reverse().join("-")}
      </StyledTableCellInside>
      <StyledTableCellInside align="center">
        {row.createdBy}
      </StyledTableCellInside>

      <StyledTableCellInside align="center">{row.night}</StyledTableCellInside>
      <StyledTableCellInside align="center" style={{ fontSize: "12px" }}>
        <Tooltip title="view">
          <IconButton
            aria-label="edit"
            style={{ padding: "0px", color: "#F46D25" }}
            onClick={() => history.push(`/bookingvoucher/${row.bookingId}`)}
          >
            <Visibility />
          </IconButton>
        </Tooltip>
      </StyledTableCellInside>
    </TableRow>
  );
};

export default TownoBookingListTable;
