/* eslint-disable eqeqeq */
import React from "react"
import { filter } from "lodash"
import { useState, useEffect } from "react"
// material
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import { Link } from "react-router-dom"
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Button,
  Paper,
  TextField,
  Grid,
  IconButton,
  Tooltip,
  Dialog,
} from "@material-ui/core"
import XLSX from "xlsx"
import { TableListHead } from "../../components/tabel"
import _ from "lodash"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import MaterialSelect from "../../components/Select/MaterialSelect"
import { useHistory } from "react-router-dom"
import Api from "../../Service/Api"
import { format, subDays } from "date-fns"
import { makeStyles } from "@material-ui/core/styles"
import Loader from "./../../components/Loader/Loader"
import "./AgentBookingList.scss"
import { formatter } from "../../utils/formatNumber"
import { Slide, Stack, Typography } from "@mui/material"
import { twnButtonStyles } from "../../utils/townoStyle"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned"
import moment from "moment/moment"
import VisibilityIcon from "@material-ui/icons/Visibility"
import AgentBookingView from "./AgentBookingView"
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft"
import ArrowRightIcon from "@material-ui/icons/ArrowRight"
import { ReactComponent as CustomerVoucherIcon } from "../../assets/logo/Customer Voucher.svg"
import { ReactComponent as HotelVoucherIcon } from "../../assets/logo/Hotel Voucher.svg"
import { GetApp } from "@material-ui/icons"
import ScreenLoader from "../../components/loadingScreen/screenLoader.component"
import ReactDOMServer from "react-dom/server"
import html2pdf from "html2pdf.js"
import AgentHotelVoucherPDF from "../../components/pdfVoucherComponents/AgentHotelVoucherPDF.component"
import { baseurl } from "../../Service/httpCommon"
import HotelVoucherPDFIcon from "../../assets/Icons/voucherIcons/Hotel.png"
import ClientVoucherPDFIcon from "../../assets/Icons/voucherIcons/Client.png"
import AgentClientVoucherPDF from "../../components/pdfVoucherComponents/AgentClientVoucherPDF.component"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

const TABLE_HEAD = [
  { id: "bookingId", label: "Booking ID", alignRight: false },
  { id: "clientName", label: "Client Name", alignRight: false },
  { id: "clientMobile", label: "Client Number", alignRight: false },
  { id: "hotelName", label: "Hotel Name", alignRight: false },
  { id: "checkIn", label: "CheckIn", alignRight: false },
  { id: "noOfNight", label: "Nights", alignRight: false },
  { id: "totalBookingAmount", label: "Total Amount", alignRight: false },
  { id: "createdBy", label: "Agent Name", alignRight: false },
  { label: "Action", alignRight: false },
]

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  if (query) {
    return filter(
      array,
      (_designation) =>
        _designation.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    )
  }
  return stabilizedThis.map((el) => el[0])
}

export default function AgentBookingList() {
  const classes = useStyles()
  const initalValue = {
    bookingId: "",
    clientName: "",
    email: "",
    contactno: "",
    bookingstatus: "",
    agentName: "",
  }
  const history = useHistory()
  const [expanded, setExpanded] = useState("panel")
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  const [bookingList, setBookingList] = useState([])
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("name")
  const [rowsPerPage, setRowsPerPage] = useState(10)
  var uniqueid = localStorage.getItem("unique_id")
  const [checkin, setCheckin] = useState(null)
  const [checkout, setCheckout] = useState(null)
  const [searchData, setSearchData] = useState(initalValue)
  const [bookingDateFrom, setBookingDateFrom] = useState(null)
  const [bookingDateTo, setBookingDateTo] = useState(null)
  const [errorFrom, setErrorFrom] = useState(false)
  const [errorTo, setErrorTo] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [bookingstatus, setBookingstatus] = useState(null)
  const [hotelName, setHotelName] = useState(null)
  const [hotelList, setHotelList] = useState([])
  const [loading, setLoading] = useState(false)
  const [pdfloading, setPdfloading] = useState(false)
  const { bookingId, clientName, email, contactno, agentName } = searchData
  const [width, setWidth] = useState(window.innerWidth)
  const [searchName, setSearchName] = useState("")
  const [searchList, setSearchList] = useState(null)
  const [pageNumber, setPageNumber] = useState(0)
  const [open, setOpen] = useState(false)
  const DataPerPage = 10
  var createdBy = localStorage.getItem("auth")
  let hasAdmin = localStorage.getItem("role")
  const [openPreview, setOpenPreview] = useState(false)
  const [currentId, setCurrentId] = useState(null)

  const generatePdf = (id) => {
    console.log("Hello", id)
    setCurrentId(id)

    setTimeout(() => {
      setCurrentId(null)
    }, 5000)
  }

  // const pagesVisited = pageNumber * DataPerPage;

  // const pageCount = Math.ceil(bookingList.length / DataPerPage);

  // const changePage = ({ selected }) => {
  //   setPageNumber(selected);
  // };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  const handleChangeSearchList = (selectedOption) => {
    setSearchList(selectedOption)
  }

  const handleChangePayment = (selectedOption) => {
    setPaymentStatus(selectedOption)
  }

  const handleChangeHotel = (option) => {
    setHotelName(option)
  }

  const handleChangeBookingstatus = (selectedOption) => {
    setBookingstatus(selectedOption)
  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target
    setSearchData({ ...searchData, [name]: value })
  }
  const Submit = (e) => {
    e.preventDefault()
    if (bookingDateFrom !== null) {
      if (bookingDateTo == null) {
        setErrorTo(true)
      }
    }
    if (bookingDateTo !== null) {
      if (bookingDateFrom == null) {
        setErrorFrom(true)
      }
    }
    const data = {
      checkIn: checkin !== null ? format(checkin, "yyyy-MM-dd") : "",
      // checkOut: checkout !== null ? format(checkout, "yyyy-MM-dd") : "",
      from:
        bookingDateFrom !== null ? format(bookingDateFrom, "yyyy-MM-dd") : "",
      to: bookingDateTo !== null ? format(bookingDateTo, "yyyy-MM-dd") : "",
      uniqueId: uniqueid,
      paymentstatus: paymentStatus !== null ? paymentStatus.value : "",
      bookingstatus: bookingstatus !== null ? bookingstatus.value : "",
      hotelname: hotelName !== null ? hotelName.value : "",
    }

    let url = `agentbookingsearch`
    const QueryValue = { ...searchData, ...data }
    Api.post(url, QueryValue).then((res) => {
      console.log("responseData", res.data, url)
      let sorted = _.orderBy(res.data, ["bookingId"], ["desc"])
      setBookingList(sorted)
    })
    setOpen(false)
  }

  const MobileSubmit = (e) => {
    e.preventDefault()

    let url = `bookingsearch`
    if (searchList.value == "bookingId") {
      url += "bookingId"
    }

    if (searchList.value == "clientName") {
      url += "clientName"
    }

    if (searchList.value == "email") {
      url += "clientEmail"
    }

    if (searchList.value == "contactno") {
      url += "clientMobile"
    }
    if (searchList.value == "agentName") {
      url += "createdBy"
    }

    const obj = { [searchList.value]: searchName }
    const unique = { uniqueId: uniqueid }
    const QueryValue = { ...obj, ...unique }
    Api.post(url, QueryValue).then((res) => {
      let sorted = _.orderBy(res.data, ["bookingId"], ["desc"])
      setBookingList(sorted)
    })
  }

  const resetData = (e) => {
    setSearchData(initalValue)
    setCheckin(null)
    setCheckout(null)
    setBookingDateFrom(null)
    setBookingDateTo(null)
    setPaymentStatus(null)
    setBookingstatus(null)
    BookingListData()
    setOpen(false)
  }

  useEffect(() => {
    BookingListData()
    hotelFetch()
  }, [])

  const BookingListData = () => {
    // console.log("bookinglistApi:", `agentBooking/${uniqueid}/${format(bookingDateFrom, "yyyy-MM-dd")}/${format(bookingDateTo, "yyyy-MM-dd")}`)
    setLoading(true)

    //for staffs
    let fromDate = bookingDateFrom ?? subDays(new Date(), 60)
    let toDate = bookingDateTo ?? new Date()
    let url = `/staffagentBooking/${uniqueid}/${format(
      fromDate,
      "yyyy-MM-dd"
    )}/${format(toDate, "yyyy-MM-dd")}/${createdBy}`
    if (
      hasAdmin === "Admin" ||
      hasAdmin === "Super Admin" ||
      hasAdmin === "Finance Team" ||
      hasAdmin === "Agent Admin"
    ) {
      url = `/agentBooking/${uniqueid}/${format(
        fromDate,
        "yyyy-MM-dd"
      )}/${format(toDate, "yyyy-MM-dd")}`
    }
    // Api.get(`agentBooking/${uniqueid}/${format(bookingDateFrom, "yyyy-MM-dd")}/${format(bookingDateTo, "yyyy-MM-dd")}`).then((res) => {
    Api.get(url).then((res) => {
      let sorted = _.orderBy(res.data, ["createdAt"], ["desc"])
      setBookingList(sorted)
      setLoading(false)
    })
  }
  const hotelFetch = () => {
    Api.get(`agentbookingproperty/${uniqueid}`).then((res) => {
      console.log("agentbookingproperty:", res)
      setHotelList(res.data)
    })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const downloadExcel = () => {
    const newData = bookingList.map((row) => {
      delete row.id
      delete row.uniqueId
      delete row.createdAt
      delete row.updatedBy
      delete row.updatedAt
      delete row.status
      return row
    })
    const workSheet = XLSX.utils.json_to_sheet(newData)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, "Branch List")
    // const buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
    XLSX.writeFile(workBook, "BookingList.xlsx")
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - bookingList.length) : 0

  const filteredBookingList = applySortFilter(
    bookingList,
    getComparator(order, orderBy)
  )

  //pagination
  const totalDataLength = filteredBookingList.length
  const dataPerPage = 5
  let noOfPages = Math.ceil(totalDataLength / dataPerPage)
  console.log("totalDataLength", totalDataLength, noOfPages)
  const [currentPageNo, setCurrentPageNo] = useState(1)
  let tempDataIndex = currentPageNo * dataPerPage //variable for min and max data range for page
  let lastDataIndex = Math.min(tempDataIndex, totalDataLength)
  let firstDataIndex = tempDataIndex - dataPerPage
  console.log("first and last indices of data:", firstDataIndex, lastDataIndex)

  const changePageNo = (value) => {
    let tempPageNo = currentPageNo + value //value can be 1 or -1 depending upon arrow clicked
    if (tempPageNo >= 1 && tempPageNo <= noOfPages) {
      setCurrentPageNo(tempPageNo)
    }
  }

  console.log(filteredBookingList)

  const [isDownloading, setIsDownloading] = useState(false)

  const DownloadClientVoucher = (data) => {
    setIsDownloading(true)
    console.log(data)
    let data1 = data.bookingId
    const link = document.createElement("a")
    link.target = "_blank"
    link.download = `${data1}.pdf`
    Api.get(`agentClientBookingpdf/${uniqueid}/${data.bookingId}`, {
      responseType: "blob",
    })
      .then((res) => {
        console.log(res)
        link.href = URL.createObjectURL(new Blob([res.data], { type: "pdf" }))
        link.click()
        setIsDownloading(false)
      })
      .catch((error) => {
        if (error) {
          setIsDownloading(false)
          alert(error)
        }
      })
  }

  const DownloadHotelVoucher = (data) => {
    setIsDownloading(true)
    console.log(data)
    let data1 = data.bookingId
    const link = document.createElement("a")
    link.target = "_blank"
    link.download = `${data1}.pdf`
    Api.get(`agentHotelBookingpdf/${uniqueid}/${data.bookingId}`, {
      responseType: "blob",
    }).then((res) => {
      console.log(res)
      link.href = URL.createObjectURL(new Blob([res.data], { type: "pdf" }))
      link.click()
      setIsDownloading(false)
    })
  }

  const createHotelCVoucherPDF = async (bookingId, fileName) => {
    setIsDownloading(true)
    try {
      const logoUrl = await Api.get(`getAgentLogo/${uniqueid}`)
      console.log(logoUrl)

      const bookingDetails = await Api.get(
        `agentBookingById/${uniqueid}/${bookingId}`
      )

      const termsandcondition = await Api.get(
        `agenthoteltermandcondition/${uniqueid}/hotelBookingTermCondition`
      )

      const cancelationpolicy = await Api.get(
        `agenthoteltermandcondition/${uniqueid}/hotelBookingCancellationPolicy`
      )

      const stayInclusion = await Api.get(
        `stayinclusion/${bookingDetails.data.propertyId}`
      )
      const property = await Api.post("propertybasicpropertydetailsvalue", {
        propertyId: bookingDetails.data.propertyId,
      })

      const passenger = await Api.get(`roomlead/${uniqueid}/${bookingId}`)

      const roomOccupied = await Api.get(
        `roomoccupied/${uniqueid}/${bookingId}`
      )

      const RoomData = await _.uniqBy(roomOccupied.data, "roomType")

      let roomInclusion

      await RoomData.map((item) =>
        Api.get(`stayroominclusion/${item.propertyId}/${item.roomType}`).then(
          (res) => {
            roomInclusion = [...res.data]
          }
        )
      )

      let alteredComponent = (
        <AgentHotelVoucherPDF
          bookingDetails={bookingDetails.data}
          roomCategories={bookingDetails.data.agentBookingRoomCategories}
          bookingInclusions={bookingDetails.data.agentBookingInclusions}
          termsandcondition={termsandcondition.data}
          cancelationpolicy={cancelationpolicy.data}
          stayInclusion={stayInclusion.data}
          property={property.data}
          passenger={passenger.data}
          roomOccupied={roomOccupied.data}
          RoomData={RoomData}
          filtered={RoomData}
          roomInclusion={roomInclusion}
          logoUrl={logoUrl.data}
        />
      )

      let jsxString = await ReactDOMServer.renderToStaticMarkup(
        alteredComponent
      )

      const pdfOptions = {
        // margin: 10,
        filename: fileName,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 3, useCORS: true },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      }

      const element = document.createElement("div")
      element.innerHTML = jsxString

      await html2pdf().from(element).set(pdfOptions).save()

      setIsDownloading(false)
    } catch (error) {
      console.error("Error creating PDF:", error)
      setIsDownloading(false)
      alert(error)
    }
  }

  const createClientVoucherPDF = async (bookingId, fileName) => {
    setIsDownloading(true)
    try {
      const logoUrl = await Api.get(`getAgentLogo/${uniqueid}`)

      const bookingDetailsResponse = await Api.get(
        `agentBookingById/${uniqueid}/${bookingId}`
      )

      const bookingDetails = (await bookingDetailsResponse).data
      const roomCategories = await bookingDetails.agentBookingRoomCategories
      const bookingInclusions = await bookingDetails.agentBookingInclusions
      const trmCdn = await bookingDetails.agentBookingTeamCondition

      const stayInclusion = await Api.get(
        `stayinclusion/${bookingDetails.propertyId}`
      )

      const property = await Api.post("propertybasicpropertydetailsvalue", {
        propertyId: bookingDetails.propertyId,
      })

      const passenger = await Api.get(`roomlead/${uniqueid}/${bookingId}`)

      const roomOccupied = await Api.get(
        `roomoccupied/${uniqueid}/${bookingId}`
      )

      const RoomData = await _.uniqBy(roomOccupied.data, "roomType")

      let roomInclusion

      await RoomData.map((item) =>
        Api.get(`stayroominclusion/${item.propertyId}/${item.roomType}`).then(
          (res) => {
            roomInclusion = [...res.data]
          }
        )
      )

      let pdfComponent = (
        <AgentClientVoucherPDF
          logoUrl={logoUrl.data}
          bookingDetails={bookingDetails}
          roomCategories={roomCategories}
          bookingInclusions={bookingInclusions}
          trmCdn={trmCdn}
          stayInclusion={stayInclusion.data}
          property={property.data}
          passenger={passenger.data}
          roomOccupied={roomOccupied.data}
          RoomData={RoomData}
          roomInclusion={roomInclusion}
          filtered={RoomData}
        />
      )
      let jsxString = await ReactDOMServer.renderToStaticMarkup(pdfComponent)

      const pdfOptions = {
        // margin: 10,
        filename: fileName,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 3, useCORS: true },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      }

      const element = document.createElement("div")
      element.innerHTML = jsxString

      await html2pdf().from(element).set(pdfOptions).save()

      setIsDownloading(false)
    } catch (error) {
      console.error("Error creating PDF:", error)
      setIsDownloading(false)
      alert(error)
    }
  }

  return (
    <div style={{ ...twnButtonStyles.allPages, paddingTop: "75px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={pdfloading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {loading ? (
        <Loader />
      ) : width <= 768 ? (
        <>
          <div style={{ padding: "8% 0px 15%", margin: "0px 20px " }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MaterialSelect
                  options={[
                    { value: "bookingId", label: "Booking Id" },
                    { value: "clientName", label: "Client Name" },
                    { value: "email", label: "Email" },
                    { value: "contactno", label: "Contact No" },
                    { value: "agentName", label: "Agent Name" },
                  ]}
                  placeholder="Search List"
                  value={searchList}
                  onChange={handleChangeSearchList}
                  isClearable={true}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="searchName"
                  value={searchName}
                  variant="outlined"
                  size="small"
                  label="search Name"
                  fullWidth
                  onChange={(e) => {
                    setSearchName(e.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <center>
                  <Button onClick={MobileSubmit}>Submit</Button>
                  <Button
                    type="reset"
                    onClick={resetData}
                    style={{ background: "#121212", marginLeft: "10px" }}
                  >
                    Reset
                  </Button>
                </center>
              </Grid>
            </Grid>

            {filteredBookingList &&
              filteredBookingList
                .slice(firstDataIndex, lastDataIndex)
                .map((row) => {
                  const {
                    id,
                    bookingId,
                    clientName,
                    hotelName,
                    checkOut,
                    checkIn,
                    noOfNight,
                    noofRooms,
                    totalBookingAmount,
                    totalNetPrice,
                    paidAmount,
                    townoPending,
                    hotelPending,
                    profit,
                  } = row
                  return (
                    <div key={id}>
                      <div className="bookingcard" variant="outlined">
                        <div className="title">
                          <h2
                            onClick={() =>
                              history.push(
                                `/konnect/newAgentBooking/${row.bookingId}`
                              )
                            }
                            className={classes.link}
                          >
                            {bookingId}
                          </h2>
                          <p>
                            {format(new Date(checkIn), "d MMM yy")} -{" "}
                            {format(new Date(checkOut), "d MMM yy")}
                          </p>
                        </div>
                        <div className="bookingContent">
                          <h2>{clientName}</h2>
                          <p>
                            <span className="circle"></span>Nights :{" "}
                            <b>{noOfNight}</b>
                          </p>
                        </div>
                        <div className="bookingContent">
                          <h2>{hotelName}</h2>
                          <p>
                            <span className="circle"></span>Rooms :{" "}
                            <b>{noofRooms}</b>
                          </p>
                        </div>
                        <div className="bookingContent1">
                          <p>Gross : {totalBookingAmount}</p>
                          <p>Net : {totalNetPrice}</p>
                          <p> Amount Paid : {paidAmount} </p>
                          <p> Payable to Towno: {townoPending}</p>
                          <p>Pay at Hotel: {hotelPending}</p>
                        </div>
                        <div className="bookingContent">
                          <h2>Profit : {profit}</h2>
                        </div>
                      </div>
                    </div>
                  )
                })}
            {/* <Pagination
                   count={pageCount}
                   color="primary"
                   onPageChange={changePage}
                 /> */}
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              style={{ margin: "1em" }}
            >
              <span
                onClick={() => {
                  changePageNo(-1)
                }}
              >
                <ArrowLeftIcon />
              </span>
              <span>
                {currentPageNo} of {noOfPages}
              </span>
              <span
                onClick={() => {
                  changePageNo(1)
                }}
              >
                <ArrowRightIcon />
              </span>
            </Stack>
          </div>
        </>
      ) : (
        <div>
          <Typography
            variant="h5"
            component="h5"
            style={twnButtonStyles.xlFonts}
          >
            Bookings
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            style={{ marginTop: "-24px" }}
          >
            <Button
              onClick={() => {
                setOpen(true)
              }}
              style={twnButtonStyles.orangeBtn}
            >
              Search
            </Button>

            <Button
              component={Link}
              to="/konnect/newAgentBooking"
              style={twnButtonStyles.orangeBtn}
            >
              New Booking
            </Button>
            {/* <Button onClick={downloadExcel} style={twnButtonStyles.blackBtn}>
                            Excel
                        </Button> */}
            <Tooltip
              title="Excel"
              style={{
                marginTop: "-10px",
                marginLeft: "-1px",
                fontSize: "28px",
              }}
            >
              <IconButton onClick={downloadExcel}>
                <i className="fas fa-file-csv" style={{ color: "#F46D25" }}></i>
              </IconButton>
            </Tooltip>
          </Stack>

          <Card
            style={{
              marginTop: "4px",
              boxShadow: "none",
              border: "1px solid #F4F4F4",
              fontSize: "14px",
            }}
          >
            <TableContainer sx={{ minWidth: 499 }}>
              <Table size="small">
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={bookingList.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {filteredBookingList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        id,
                        bookingId,
                        clientName,
                        clientMobile,
                        hotelName,
                        checkIn,
                        noOfNight,
                        // totalRoomRent,
                        totalBookingAmount,
                        // pendingAmount,
                        createdBy,
                      } = row
                      return (
                        <TableRow
                          key={id}
                          tabIndex={-1}
                          // className={bookingStatus == "Cancel" ? classes.tableRow : null}
                        >
                          {row.amountRefunded > 0 ? (
                            <TableCell
                              component="th"
                              scope="row"
                              // className={bookingStatus == "Cancel" ? classes.links : classes.link}
                              style={{ fontSize: "12px", color: "#111" }}
                            >
                              {bookingId}
                            </TableCell>
                          ) : (
                            <TableCell
                              component="th"
                              scope="row"
                              onClick={() =>
                                history.push(
                                  `/konnect/newAgentBooking/${row.bookingId}`
                                )
                              }
                              // className={bookingStatus == "Cancel" ? classes.links : classes.link}
                              style={{
                                fontSize: "12px",
                                cursor: "pointer",
                                color: "#f46d25",
                                textDecoration: "underline",
                                fontWeight: "bold",
                              }}
                            >
                              {bookingId}
                            </TableCell>
                          )}
                          <TableCell
                            align="left"
                            style={{ textTransform: "capitalize" }}
                          >
                            {clientName}
                          </TableCell>
                          <TableCell align="left">{clientMobile}</TableCell>
                          <TableCell align="left">{hotelName}</TableCell>
                          <TableCell align="left">
                            {checkIn
                              ? format(
                                  moment(checkIn, "YYYY-MM-DD")["_d"],
                                  "dd MMM yy"
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="left">{noOfNight}</TableCell>
                          {/* <TableCell align="left">{totalRoomRent}</TableCell> */}
                          <TableCell align="left">
                            {formatter.format(totalBookingAmount)}
                          </TableCell>
                          {/* <TableCell align="left"> */}
                          {/* {formatter.format(pendingAmount)}
                                                    </TableCell> */}
                          <TableCell align="left">{createdBy}</TableCell>
                          <TableCell align="left" style={{ fontSize: "12px" }}>
                            <Stack
                              direction="row"
                              spacing={2}
                              alignItems="center"
                            >
                              <Link
                                to={{
                                  pathname: `/konnect/viewAgentBooking/${row.bookingId}`,
                                }}
                              >
                                <Tooltip title="View" arrow>
                                  <VisibilityIcon
                                    style={{ color: "#f46d25" }}
                                  />
                                </Tooltip>
                              </Link>
                              <Tooltip title="Client Voucher">
                                <IconButton
                                  aria-label="edit"
                                  onClick={() => {
                                    window.open(
                                      `/konnect/agentClientVoucher/${row.bookingId}`
                                    )
                                  }}
                                  style={{ padding: "0px", color: "#F46D25" }}
                                >
                                  <AssignmentReturnedIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Hotel Voucher">
                                <IconButton
                                  aria-label="edit"
                                  onClick={() => {
                                    window.open(
                                      `/konnect/agentHotelVoucher/${row.bookingId}`
                                    )
                                  }}
                                  style={{ padding: "0px", color: "#F46D25" }}
                                >
                                  <FileCopyIcon />
                                </IconButton>
                              </Tooltip>

                              {/* <IconButton
                                style={{ color: "#f46d25", padding: "0" }}
                                onClick={() => DownloadClientVoucher(row)}
                                title="Download Client Voucher"
                              >
                                <CustomerVoucherIcon
                                  style={{
                                    height: "25px",
                                  }}
                                />
                              </IconButton> */}
                              <IconButton
                                style={{
                                  color: "#f46d25",
                                  padding: "0",
                                  cursor: "pointer",
                                }}
                                title="Download Client Voucher"
                                onClick={() =>
                                  createClientVoucherPDF(
                                    `${row.bookingId}`,
                                    `${row.bookingId}CV.pdf`
                                  )
                                }
                              >
                                <img
                                  src={ClientVoucherPDFIcon}
                                  style={{
                                    height: "20px",
                                    width: "auto",
                                    padding: "0",
                                  }}
                                />
                              </IconButton>
                              {/* <IconButton
                                style={{ color: "#f46d25", padding: "0" }}
                                onClick={() => DownloadHotelVoucher(row)}
                                title="Download Hotel Voucher"
                              >
                                <HotelVoucherIcon
                                  style={{
                                    height: "25px",
                                  }}
                                />
                              </IconButton> */}
                              <IconButton
                                onClick={() =>
                                  createHotelCVoucherPDF(
                                    `${row.bookingId}`,
                                    `${row.bookingId}HV.pdf`
                                  )
                                }
                                style={{ padding: "0" }}
                                title="Download Hotel Voucher"
                              >
                                <img
                                  src={HotelVoucherPDFIcon}
                                  style={{
                                    height: "20px",
                                    width: "auto",
                                    padding: "0",
                                  }}
                                />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={9} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={bookingList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>

          <Dialog
            open={open}
            onClose={() => {
              setOpen(false)
            }}
            fullWidth={true}
            maxWidth={"md"}
          >
            <Paper className={classes.paper}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <form onSubmit={Submit}>
                  <Grid container spacing={2}>
                    <Grid item md={3}>
                      <TextField
                        name="bookingId"
                        variant="outlined"
                        size="small"
                        label="Booking Id"
                        fullWidth
                        value={bookingId}
                        onChange={handleChangeInput}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        name="clientName"
                        value={clientName}
                        variant="outlined"
                        size="small"
                        label="Client Name"
                        fullWidth
                        onChange={handleChangeInput}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        name="email"
                        value={email}
                        variant="outlined"
                        size="small"
                        label="Email Id"
                        onChange={handleChangeInput}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        name="contactno"
                        placeholder="+91 127...."
                        value={contactno}
                        variant="outlined"
                        size="small"
                        label="Contact No"
                        fullWidth
                        onChange={handleChangeInput}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <MaterialSelect
                        placeholder="Hotels"
                        options={hotelList.map((hotel) => ({
                          label: hotel.displayName,
                          value: hotel.displayName,
                        }))}
                        value={hotelName}
                        onChange={handleChangeHotel}
                        isClearable={true}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <MaterialSelect
                        options={[
                          { value: "compeleted", label: "Compeleted" },
                          { value: "pending", label: "Pending" },
                          { value: "cancel", label: "Cancel" },
                        ]}
                        placeholder="Booking Status"
                        value={bookingstatus}
                        onChange={handleChangeBookingstatus}
                        isClearable={true}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <MaterialSelect
                        options={[
                          { value: "Full", label: "Paid" },
                          { value: "Partial", label: "Pending" },
                        ]}
                        placeholder="Payment Status"
                        value={paymentStatus}
                        onChange={handleChangePayment}
                        isClearable={true}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <TextField
                        name="agentName"
                        value={agentName}
                        variant="outlined"
                        size="small"
                        label="Agent Name"
                        fullWidth
                        onChange={handleChangeInput}
                      />
                    </Grid>
                    <Grid item md={3}>
                      <div style={{ position: "relative" }}>
                        <DatePicker
                          label="Check-In"
                          inputVariant="outlined"
                          fullWidth
                          size="small"
                          animateYearScrolling
                          format="dd/MM/yyyy"
                          variant="inline"
                          autoOk="true"
                          value={checkin}
                          onChange={(e) => setCheckin(e)}
                        />
                        <CalendarTodayIcon className={classes.icon} />
                      </div>
                    </Grid>
                    <Grid item md={3}>
                      <div style={{ position: "relative" }}>
                        <DatePicker
                          label="Booking Date From"
                          inputVariant="outlined"
                          fullWidth
                          size="small"
                          animateYearScrolling
                          format="dd/MM/yyyy"
                          variant="inline"
                          autoOk="true"
                          value={bookingDateFrom ?? null}
                          onChange={(e) => setBookingDateFrom(e)}
                        />
                        <CalendarTodayIcon className={classes.icon} />
                      </div>
                      {errorFrom ? (
                        <div style={{ color: "red" }}>
                          Booking Date From Required
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item md={3}>
                      <div style={{ position: "relative" }}>
                        <DatePicker
                          label="Booking Date To"
                          inputVariant="outlined"
                          size="small"
                          fullWidth
                          format="dd/MM/yyyy"
                          animateYearScrolling
                          variant="inline"
                          autoOk="true"
                          value={bookingDateTo ?? null}
                          onChange={(e) => setBookingDateTo(e)}
                        />
                        <CalendarTodayIcon className={classes.icon} />
                      </div>
                      {errorTo ? (
                        <div style={{ color: "red" }}>
                          Booking Date To Required
                        </div>
                      ) : null}
                    </Grid>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{ margin: "10px" }}
                    >
                      <Button
                        type="submit"
                        style={{
                          ...twnButtonStyles.orangeBtn,
                          padding: "0 26px",
                        }}
                      >
                        Search
                      </Button>
                      <Button
                        type="reset"
                        onClick={resetData}
                        style={{
                          ...twnButtonStyles.blackBtn,
                          padding: "0 26px",
                        }}
                      >
                        Reset
                      </Button>
                    </Stack>
                  </Grid>
                </form>
              </MuiPickersUtilsProvider>
            </Paper>
          </Dialog>
          <ScreenLoader open={isDownloading} />
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  button: {
    background: "#F46D25",
    color: "#fff",
    "&:hover": {
      background: "#F46D25",
    },
  },
  main: {
    padding: "100px 20px 0px 20px",
  },
  link: {
    textDecoration: "underline",
    color: "#F46D25",
    cursor: "pointer",
  },
  links: {
    textDecoration: "underline",
    color: "black",
    cursor: "pointer",
  },
  paper: {
    background: "#F4F4F4",
    // background : "rgba(244,0,0, 0.1)",
    padding: "25px",
    height: "30vh",
    overflow: "hidden",
  },
  icon: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    position: "absolute",
    right: "15px",
    top: "5px",
    color: "#f46d25",
  },
  tableRow: {
    backgroundColor: "#F4F4F4",
  },
}))
