import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Paper,
    Grid,
    DialogActions,
    Typography,
    DialogContent,
    Dialog,
} from "@mui/material";

import Switch from '@material-ui/core/Switch';
import { styled } from "@mui/material/styles";
import { Button } from "@material-ui/core";
import XLSX from "xlsx";
import Select from "react-select";
import _ from "lodash";
import { differenceInDays, format, parse } from "date-fns";
import moment from "moment";

const FinanceOpition = [
    { value: "packageId", label: "Package Id" },
    {value: "packageName",label: "Package Name"},
    {value: "clientName",label: "Client Name"},
    {value: "clientMobileNo",label: "Client Mobile No"},
    {value: "agentName",label: "Agent Name"},
    {value: "hotelName",label: "Hotel Name"},
    {value: "checkIn",label: "Check-In"},
    {value: "checkOut",label: "Check-Out"},
    {value: "duration",label: "Duration"},
    {value: "destination",label: "Destination"},
    {value: "noOfAdults",label: "Adults"},
    {value: "noOfChildren",label: "Children"},
    {value: "totalAmount",label: "Total Amount"},
    {value: "paid",label: "Amount Paid"},
    {value: "comission",label: "Commisiion"},
    {value: "transferFrom",label: "Transfer From"},
    {value: "transferTo",label: "Transfer To"},
    {value: "createdDate",label: "Created Date"},
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    background: "#F46D25",
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    background: "#F46D25",
    color: "#fff",
    fontSize: "12px",
    padding: "5px",
    lineHeight: "1rem",
    minWidth: "100px",
}));

const StyledTableCellInside = styled(TableCell)(({ theme }) => ({
    padding: "5px",
    fontSize: "12px",
    lineHeight: "15px",
}));

const TABLE_HEAD_CONST = [
    { value: "packageId", label: "Package Id" },
    {value: "packageName",label: "Package Name"},
    {value: "clientName",label: "Client Name"},
    {value: "agentName",label: "Agent Name"},
    {value: "duration",label: "Duration"},
    {value: "destination",label: "Destination"},
    {value: "totalAmount",label: "Total Amount"},
    {value: "paid",label: "Amount Paid"},
    {value: "comission",label: "Commisiion"},
    
];

const filter =[
    {value: "clientMobileNo",label: "Client Mobile No", selected:false, type:"normal"},
    {value: "hotelName",label: "Hotel Name", selected:false, type:"normal"},
    {value: "checkIn",label: "Check-In", selected:false, type:"date"},
    {value: "checkOut",label: "Check-Out", selected:false, type:"date"},
    {value: "noOfAdults",label: "Adults", selected:false, type:"normal"},
    {value: "noOfChildren",label: "Children", selected:false, type:"normal"},
    {value: "transferFrom",label: "Transfer From", selected:false, type:"normal"},
    {value: "transferTo",label: "Transfer To", selected:false, type:"normal"},
    {value: "createdDate",label: "Created Date", selected:false, type:"date"},
]

export default function PackageReport(props) {
    const [packageReport, setPackageReport] = useState(props.reportData);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState(null);


    useEffect(() => {
        setPackageReport(props.reportData);
    }, [props]);

    const HandleSort = (sort) => {
        setSort(sort);
        let sorted = _.orderBy(packageReport, [sort.value], ["asec"]);
        setPackageReport(sorted);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const downloadExcel = () => {
        const newData = packageReport.map((row) => {
            delete row.id;
            return row;
        });
        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Package");
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workBook, "Package.xlsx");
    };

    //!
    const [open, setOpen] = useState(false);
  
    const [tableHead,setTableHead]=useState(TABLE_HEAD_CONST)
  
    const[newTable,setNewTable]=useState([
        {id: "clientMobileNo",label: "Client Mobile No", selected:false, type:"normal"},
        {id: "hotelName",label: "Hotel Name", selected:false, type:"normal"},
        {id: "checkIn",label: "Check-In", selected:false, type:"normal"},
        {id: "checkOut",label: "Check-Out", selected:false, type:"normal"},
        {id: "noOfAdults",label: "Adults", selected:false, type:"normal"},
        {id: "noOfChildren",label: "Children", selected:false, type:"normal"},
        {id: "transferFrom",label: "Transfer From", selected:false, type:"normal"},
        {id: "transferTo",label: "Transfer To", selected:false, type:"normal"},
        {id: "createdDate",label: "Created Date", selected:false, type:"date"},
    ])
    const [headValues,setHeadValues]=useState([
        {id: "clientMobileNo",label: "Client Mobile No", selected:false, type:"normal"},
        {id: "hotelName",label: "Hotel Name", selected:false, type:"normal"},
        {id: "checkIn",label: "Check-In", selected:false, type:"normal"},
        {id: "checkOut",label: "Check-Out", selected:false, type:"normal"},
        {id: "noOfAdults",label: "Adults", selected:false, type:"normal"},
        {id: "noOfChildren",label: "Children", selected:false, type:"normal"},
        {id: "transferFrom",label: "Transfer From", selected:false, type:"normal"},
        {id: "transferTo",label: "Transfer To", selected:false, type:"normal"},
        {id: "createdDate",label: "Created Date", selected:false, type:"date"},
    ])
      const handleChange=(i)=>{
      console.log(":Index:",i);
      const values=[...headValues];
      values[i].selected=!values[i].selected;
     
      setHeadValues(values)
  
    }
     const handleClose = () => {
      setOpen(false);
    };
  

    //!

    return (
        <div>
            
        <Dialog 
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{backgroundColor:"#000", color:"#fff", textAlign:"center", padding:4}} >
          <Typography variant="h6">

           Column Settings
          </Typography>
          </div>
        <DialogContent>
          
            <Grid container spacing={2}>
             {headValues.map((item,i)=>
             <React.Fragment key={i}>
            
             <Grid item xs={3}>
              <Typography variant="body1" sx={{fontWeight:800}}>{item.label}</Typography></Grid>
              <Grid item xs={3}>

          <Switch
            checked={item.selected}
            onChange={()=>handleChange(i)}
            // name="checkedB"
            color="primary"
          />
       
      
              
              </Grid> </React.Fragment>) }
            </Grid>
    
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{
            const values=headValues.filter(item=>item.selected===true);
            // const columns=JSON.parse(JSON.stringify(headValues))
            const columns=_.cloneDeep(headValues) 
            setTableHead([...TABLE_HEAD_CONST,...values])
            setNewTable(columns);
            handleClose()
          }} color="primary">
            Submit
          </Button>
          <Button onClick={()=>{
            setHeadValues(_.cloneDeep(newTable))
            handleClose()
            }}  color="secondary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
            <Grid container spacing={2} style={{ marginBottom: "10px" }}>
                <Grid item md={7}>
                    <b>Package Report</b>
                </Grid>
                <Grid item md={2}>
                    <div style={{ position: "relative" }}>
                        <span
                            style={{
                                position: "absolute",
                                top: "-9px",
                                zIndex: "1",
                                left: "12px",
                                color: "#fff",
                                background: "#F46D25",
                                borderRadius: "4px",
                                padding: "1px 4px",
                                fontSize: "12px",
                            }}
                        >
                            Sort By
                        </span>
                        <Select
                            options={FinanceOpition}
                            placeholder=""
                            value={sort}
                            onChange={HandleSort}
                            styles={{
                                container: (provided) => ({
                                    ...provided,
                                }),
                                menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9,
                                }),
                                control: (base, state) => ({
                                    ...base,
                                    "&:hover": { borderColor: "#f46d25" },
                                    borderColor: "#f46d25",
                                    boxShadow: "none",
                                }),
                            }}
                        />
                    </div>
                </Grid>
                <Grid item md={2} style={{ textAlign: "right" }}>
                    <Button size="small" onClick={downloadExcel}>
                        Download As Excel
                    </Button>
                </Grid>
                    <Grid item md={1} style={{ textAlign: "right" }}>
          <Button onClick={()=>setOpen(true)}>
           Filter
          </Button>
        </Grid>
            </Grid>

            {/* <Paper sx={{ width: "100%", overflow: "hidden" }}> */}
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <StyledTableRow>
                            {tableHead.map((column) => (
                                <StyledTableCell key={column.id} align={column.align}>
                                    {column.label}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    </TableHead>
                    {packageReport.length>0?
                    <TableBody>
                        {packageReport
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => {
                                return (
                                    <TableRow key={row.id}>
                                        <StyledTableCellInside
                                            style={{ textTransform: "capitalize" }}
                                        >
                                            {row.packageId}
                                        </StyledTableCellInside>
                                        <StyledTableCellInside>
                                            {row.packageName}
                                        </StyledTableCellInside>
                                        <StyledTableCellInside>
                                            {row.clientName}
                                        </StyledTableCellInside>
                                        
                                        <StyledTableCellInside>
                                            {row.agentName}
                                        </StyledTableCellInside>
                                       
                                       
                                       
                                        <StyledTableCellInside>
                                            {row.checkOut?differenceInDays(parse(row.checkOut,"dd-MM-yyyy",new Date()),parse(row.checkIn,"dd-MM-yyyy",new Date())):null} days
                                        </StyledTableCellInside>
                                        <StyledTableCellInside>
                                            {row.destination}
                                        </StyledTableCellInside>
                                       
                                        <StyledTableCellInside>
                                            {row.totalAmount}
                                        </StyledTableCellInside>
                                        <StyledTableCellInside>
                                            {row.paid}
                                        </StyledTableCellInside>
                                        <StyledTableCellInside>
                                            {row.comission}
                                        </StyledTableCellInside>
                                        {newTable.filter?.(item=> item.selected===true)?.map((val,i)=>
                        
                        val.type==="date"?(<StyledTableCellInside key={i}>
                        {row?.[val.id]&&format(new Date(row?.[val.id]),"dd-MM-yyyy HH:mm:ss")}
                      </StyledTableCellInside>)
                        :<StyledTableCellInside key={i}>{row?.[val.id]}</StyledTableCellInside>
                          
                          )}

                                       
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                    :"Search Not Found"}
                </Table>
            </TableContainer>

            <TablePagination
                component="div"
                count={packageReport.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}                
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* </Paper> */}
        </div>
    );
}
