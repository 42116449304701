import styles from "./PlanningNextTrip.styles.module.css"
import NextTripBanner from "../../../assets/illustrations/destination/destination_intermediate_banner/background_next_trip.png"
import { useContext } from "react"
import { ContactModalContext } from "../../../context/contactModal.context"

const PlanningNextTripBanner = () => {
  const { toggleModal } = useContext(ContactModalContext)

  return (
    <section className={styles.nextTrip}>
      <div
        style={{ background: `url(${NextTripBanner})` }}
        className={styles.nextTripBanner}
      />
      <div className={styles.nextTripContent}>
        <header> Planning Your Next Trip For Uttarakhand</header>
        <p>
          Plan your tour to Uttarakhand with our handpicked travel itineraries
          and tour packages. Browse packages of your choice by destination or
          number of days you have for travel.
        </p>
        <button type="button" onClick={toggleModal}>
          Get A CallBack
        </button>
      </div>
    </section>
  )
}

export default PlanningNextTripBanner
