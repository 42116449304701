import styles from "./Destination.layout.module.css"
import BannerOne from "../../assets/illustrations/destination/banner_images/destination_bg_1.png"
import BannerTwo from "../../assets/illustrations/destination/banner_images/destination_bg2.png"
import BannerThree from "../../assets/illustrations/destination/banner_images/tourism.png"
import BannerFour from "../../assets/illustrations/destination/banner_images/thimgs_todo_banner.png"
import BannerFive from "../../assets/illustrations/destination/banner_images/key_facts.png"
import BannerSix from "../../assets/illustrations/destination/banner_images/shopping.png"
import BannerSeven from "../../assets/illustrations/destination/banner_images/cuisine.png"
import BannerEight from "../../assets/illustrations/destination/banner_images/things_to_carry.png"
import parser from "html-react-parser"
import BackgroundVideo from "../../assets/videos/Website Bg Video.mp4"

import {
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min"
import { createContext, useState } from "react"
import usePathProvider from "../../hooks/usePathProvider.hook"
import BredCrumbs from "../../components/Bredcrumbs/BredCrumbs.component"
import AsideDestinationNavbar from "../../components/AsideDestinationNavbar/AsideDestinationNavbar.component"
import { useDispatch, useSelector } from "react-redux"
import VideoPlayerModal from "../../components/VideoPlayerModal/VideoPlayerModal.component"
import { handleCloseVideoPlayerModal } from "../../../redux/actions/videoPlayerModal.action"

export const DestinationLayoutContext = createContext({
  banner: "",
  setBanner: () => null,
})

const DestinationLayout = ({ children }) => {
  const [banner, setBanner] = useState("home")
  const location = useLocation()
  const match = useRouteMatch()
  const dispatch = useDispatch()
  const { openModal, url } = useSelector(
    ({ videoPlayerModalReducer }) => videoPlayerModalReducer
  )
  console.log({ url })

  console.log(location)
  const path = usePathProvider(location.pathname)

  const bannerSrc = {
    home: {
      src: BannerOne,
      bannerTitle: `Headed to ${
        location.pathname.substring(1, 2).toUpperCase() +
        location.pathname.substring(2)
      }?`,
    },
    subCity: { src: BannerTwo, bannerTitle: "Uttarakhand Destination" },
    tourism: {
      src: BannerThree,
      bannerTitle: "Welcome to Uttarakhand",
      secondaryTitle: "Tourism",
    },
    places: {
      src: BannerThree,
      bannerTitle: "Welcome to Uttarakhand",
      secondaryTitle: "Places To Visit",
    },
    bestTime: {
      src: BannerThree,
      bannerTitle: "Welcome to Uttarakhand",
      secondaryTitle: "Best Time To Visit",
    },
    travelBy: {
      src: BannerThree,
      bannerTitle: "Welcome to Uttarakhand",
      secondaryTitle: "How To Reach?",
    },
    thingsTodo: {
      src: BannerFour,
      bannerTitle: "Welcome to Uttarakhand",
      secondaryTitle: "Things To Do",
    },
    keyFacts: {
      src: BannerFive,
      bannerTitle: "Welcome to Uttarakhand",
      secondaryTitle: "Key Facts",
    },
    shopping: {
      src: BannerSix,
      bannerTitle: "Welcome to Uttarakhand",
      secondaryTitle: "Shopping & Entertainment",
    },
    cuisine: {
      src: BannerSeven,
      bannerTitle: "Welcome to Uttarakhand",
      secondaryTitle: "Cuisine",
    },
    thingsToCarry: {
      src: BannerEight,
      bannerTitle: "Welcome to Uttarakhand",
      secondaryTitle: "Things To Carry",
    },
    festivalsAndEvents: {
      src: BannerOne,
      bannerTitle: "Welcome to Uttarakhand",
      secondaryTitle: "Festivals and Events",
    },
  }[banner]

  return (
    <DestinationLayoutContext.Provider value={{ setBanner }}>
      <section className={styles.destinationLayout}>
        <div className={styles.bannerContainer}>
          {/* <img src={bannerSrc.src} className={styles.banner} /> */}
          <video
            src={BackgroundVideo}
            autoStart
            autoPlay={true}
            controls={false}
            type="video/mp4"
            className={styles.banner}
            loop
            width="100%"
            height="100%"
          />
          <p className={styles.primaryTitle}>
            {parser(bannerSrc.bannerTitle)}
            <p className={styles.secondaryTitle}>{bannerSrc?.secondaryTitle}</p>
          </p>
        </div>
        <AsideDestinationNavbar />
        <BredCrumbs pathname={location?.pathname} />
        {children}
        <VideoPlayerModal
          open={openModal}
          url={url}
          handleClose={() => {
            dispatch(handleCloseVideoPlayerModal())
          }}
        />
      </section>
    </DestinationLayoutContext.Provider>
  )
}

export default DestinationLayout
