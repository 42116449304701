import "./Footer.styles.css"
import SalesNetworkBanner from "../../assets/illustrations/sales_network_banner.png"
import IphoneMockup from "../../assets/illustrations/iPhone_13_Mockup.png"
import { useContext, useState } from "react"
import TownoLogo from "../../assets/logo/towno.fontImage.svg"
import { NavLink } from "react-router-dom"
import { format } from "date-fns"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { ContactModalContext } from "../../context/contactModal.context"
import { ReactComponent as MailIcon } from "../../assets/icons/contact-icons/mail.svg"
import { ReactComponent as PhoneIcon } from "../../assets/icons/contact-icons/phone.svg"
import FooterLinks from "../FooterLinks/FooterLinks.component"
import MobileMockup from "../../assets/illustrations/Mobile-Mockup.png"

const footerLinks = [
  { name: "Home", path: "/" },
  { name: "Terms and Conditions", path: "/terms" },
  { name: "Privacy Policy", path: "/privacy" },
  { name: "Refund Policy", path: "/refund" },
]

const Footer = () => {
  const [messageLinkTo, setMessageLinkTo] = useState("mobile")
  const history = useHistory()
  const { toggleModal } = useContext(ContactModalContext)
  return (
    <footer
      className="sales-network-banner-container"
      id="banner-container-sales-network"
    >
      {/**hotel-sales-network banner starts*/}

      <img src={SalesNetworkBanner} className="sales-network-banner" />
      <div className="sales-network-info">
        <img className="phone-image-container" src={MobileMockup} />

        <div className="sales-network-primary-text">
          <div className="sales-section-title">
            Book an experiential holiday
          </div>
          <p>
            Choose from the shimmering sand beaches of Thailand to the melting
            pot of culture in Dubai, to the quaint towns of Uttarakhand and
            Himachal. Each of our customized holiday packages brings you a
            unique Towno experience with our Towno hotel and activity partners.
            ✨
          </p>

          <form
            className="search-form"
            onSubmit={(e) => {
              e.preventDefault()
              toggleModal()
            }}
          >
            <input
              className="user-input-destination"
              placeholder="Enter Your destination"
              type="text"
            />
            <input
              className="user-input-number"
              placeholder="Enter Your Number"
              type="text"
              inputMode="numeric"
            />
            <button type="submit" className="submit-btn">
              Customise My Holiday
            </button>
          </form>
        </div>
      </div>
      {/**hotel-sales-network banner ends*/}

      {/**footer links starts */}
      <FooterLinks />
      {/**footer links ends */}
    </footer>
  )
}
export default Footer
