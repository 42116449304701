import { Stack, Typography } from "@mui/material"
import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { twnButtonStyles } from "../../utils/townoStyle"
import OnboardTable from "./OnboardTable"
import Api from "../../Service/Api"

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export default function AgencyOnboarding() {
  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("createdAt")
  const [open, setOpen] = useState(false)
  const [onboardTableData, setOnboardTableData] = useState([])
  const [data, setData] = useState([])

  useEffect(() => {
    Api.get("travelagentonboardinglist").then((res) => {
      const sortedData = applySortFilter(
        res.data,
        getComparator(order, orderBy)
      )
      setOnboardTableData(sortedData)
      setData(sortedData)
    })
  })
  return (
    <div style={{ ...twnButtonStyles.allPages }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ paddingTop: "1.5%" }}
      >
        <Typography variant="h5" style={twnButtonStyles.xlFonts}>
          Agency Onboarding
        </Typography>
        <div
          onClick={() => {
            window.open(`/konnect/creatUpdateOnboard`, "_self")
          }}
          style={{ ...twnButtonStyles.linkOrangeBtn, paddingTop: "0.5%" }}
        >
          Create Onboard
        </div>
      </Stack>
      <div style={{ marginTop: "15px" }}>
        <OnboardTable onboardTableData={onboardTableData} />
      </div>
    </div>
  )
}
