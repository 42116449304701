import { Card, Grid, Stack, Typography } from "@mui/material"
import { useEffect } from "react"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { twnButtonStyles } from "../../utils/townoStyle"
import Api from "../../Service/Api"

const cardLabel = { padding: "0.5em 0.7em", color: "#111", fontWeight: "500" }
const cardValue = { padding: "0.5em 1em", color: "#111" }

export default function ViewOnboard(props) {
  const { id } = useParams()
  var [data, setData] = useState({})

  useEffect(() => {
    if (id) {
      Api.get(`travelagentonboardingdetail/${id}`).then((res) => {
        console.log(res)
        setData(res.data)
      })
    }
  }, [])

  return (
    <div
      style={{ padding: "2% 1%", fontSize: "14px", backgroundColor: "#F7F7F7" }}
    >
      <Typography variant="h5" component="h5" style={twnButtonStyles.xlFonts}>
        Onboard Details
      </Typography>
      <Card style={{ marginTop: "9px" }}>
        <div
          style={{
            backgroundColor: "#f46d25",
            color: "#fff",
            padding: "0.5em 1em",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Basic Details
        </div>
        <Stack style={{ padding: "0.5em" }}>
          <Stack
            direction="row"
            spacing={2}
            style={{ lineHeight: "0.3", padding: "0 0.5em" }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Agency Name
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.travelAgencyName}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Contact Person Name
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.agentRefferedBy}
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            style={{ lineHeight: "0.3", padding: "0 0.5em" }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Contact Person Phn.
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.mobile1}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Email
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.mail}
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            style={{ lineHeight: "0.3", padding: "0 0.5em" }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Owner Name
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.name}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Owner Phone
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.phone}
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            style={{ lineHeight: "0.3", padding: "0 0.5em" }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                City
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.city}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Designation
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.designation}
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            style={{ lineHeight: "0.3", padding: "0 0.5em" }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Area of Operation:
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.areaOfOperation}
              </Grid>
            </Grid>
            <Grid container></Grid>
          </Stack>
        </Stack>
      </Card>
      <Stack justifyContent="çenter">
        <button
          onClick={() => {
            window.open("/konnect/agencyOnboarding", "_self")
          }}
          style={twnButtonStyles.blackBtn}
        >
          Back
        </button>
      </Stack>
    </div>
  )
}
