import styles from "./ContactCard.module.css"

const ContactCard = () => {
  return (
    <div className={styles.contactCard}>
      <header className={styles.header}>WANT TO PARTNER WITH US?</header>
      <section className={styles.content}>
        <p>
          Are you a hotel, homestay or camp? We help you reach out to thousands
          of Towno explorers and help you build your online presence.
        </p>
        <p>
          Are you a corporate or a business? Reach out to use for our innovative
          experiential travel solutions.
        </p>
        <p>
          Are you a blogger or trave enthusiast? Do you want to get featured or
          recommend a unique travel experience? We help you spread the word.
        </p>
      </section>

      <section className={styles.addressContainer}>
        {/* <aside className={styles.address}>
          Address 1:
          <p>159 New, Old 159-83, Tilak Road, Dehradun, Uttarakhand - 248001</p>
        </aside> */}
        <aside className={styles.address}>
          Address :
          <p>
            <p style={{ fontWeight: "bold", padding: "0" }}>
              Intellego Tech Solutions Pvt Ltd
            </p>
            <a href="https://maps.app.goo.gl/2buVJMNxUhVCQmXH9" target="_blank">
              1, Nehru Enclave, GMS Road, Ballupur, Dehradun - 248001
            </a>
          </p>
        </aside>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3443.7736164437742!2d78.0119019!3d30.328956499999993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39092bc57fec9421%3A0xe0b3b81b5c15339b!2sTowno!5e0!3m2!1sen!2sin!4v1708405688547!5m2!1sen!2sin"
          // width="600"
          // height="450"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          className={styles.geoLocation}
        />
      </section>
    </div>
  )
}

export default ContactCard
