import Api from "../../Service/Api";
import { ActionTypes } from "./actionTypes";

export const getPaymentModeOptions = (uniqueid) => async (dispatch) => {
  const { data } = await Api.get(`commonfeaturemenu/${uniqueid}/paymentMode`);
  const paymenyModeOptions = data.map((option) => ({
    label: option.name,
    value: option.name,
  }));
  dispatch({
    type: ActionTypes.FETCH_PAYMENT_MODE_OPTIONS,
    payload: paymenyModeOptions,
  });
};

export const getBookingSourceOptions = (uniqueid) => async (dispatch) => {
  const { data } = await Api.get(`commonfeaturemenu/${uniqueid}/bookingSource`);
  const bookingSourceOptions = data.map((option) => ({
    label: option.name,
    value: option.name,
  }));
  dispatch({
    type: ActionTypes.FETCH_BOOKING_SOURCE_OPTIONS,
    payload: bookingSourceOptions,
  });
};
