export const itineraryData = [
  {
    bulletTitle: "Day 1",
    title: "Haridwar/Rishikesh to Govindghat via Joshimath",
    info: [
      { type: "Distance", content: "300 km" },
      { type: "Duration", content: "10 hours" },
      { type: "Elevation", content: "6,300ft./1,920m" },
    ],
    content: {
      body: "A representative will meet you and provide a brief summary of the entire excursion when you congregate at the prearranged location in Haridwar at approximately 7 am. From Haridwar, you will next start a lengthy, picturesque trip uphill to Govind Ghat.<p>Journey to Devprayag to see the sacred meeting point of the Bhagirathi and Alaknanda Rivers, which merge to form the Ganges River. Sometimes the only thing visible is the river gushing through the gorge situated far below where the roadways are carved out of the mountainside. Joshimath is also a place of religious pilgrimage that you will travel by on your way to other places. After sunset, make your overnight stop in Govindghat.</p> ",
      media: [
        require("../../../assets/illustrations/Trekking_assets/gettyimages-148409883-612x612.jpg"),
        require("../../../assets/illustrations/Trekking_assets/gettyimages-181606492-612x612.jpg"),
        require("../../../assets/illustrations/Trekking_assets/gettyimages-1212366889-612x612.jpg"),
      ],
    },
  },
  {
    bulletTitle: "Day 2",
    title:
      "Govindghat to Ghangaria | Witness Nature Slowly Unveiling its Secrets",
    info: [
      { type: "Distance", content: "13 km" },
      { type: "Duration", content: "5-6 hours" },
      { type: "Elevation", content: "9,600ft./2,926m" },
    ],
    content: {
      body: "Enjoy a substantial breakfast while taking in the breathtaking views of the beautiful, green mountains. After that, you'll be taken to Pulna, a nearby town, from where you'll begin your hike to Ghangaria. Stroll along the well-marked path that runs by the stunning Lakshman Ganga River and through a verdant forest. Please take a moment to express your gratitude to the volunteers that clean the path every day to keep it clear of debris and in top shape. Along the route, you'll come across a variety of dhabas where you can stop for refreshments and restock on water.<p>The short, steady climb of nine kilometers will get you to Ghangaria, a tiny community surrounded by mountains.After checking into your hotel, venture out to enjoy the lovely surroundings. Have a delicious dinner of local cuisines in Ghangharia and call to -a-day.</p> ",
      media: [
        require("../../../assets/illustrations/Trekking_assets/gettyimages-166043872-612x612.jpg"),
        require("../../../assets/illustrations/Trekking_assets/gettyimages-1645804483-612x612.jpg"),
        require("../../../assets/illustrations/Trekking_assets/Govind-Ghat-Joshimath-A-Doorway-to-Valley-of-Flowers-Hemkund-Sahib.jpg"),
      ],
    },
  },
  {
    bulletTitle: "Day 3",
    title: "Ghangaria to Valley of flowers | Back to Ghangaria",
    info: [
      { type: "Distance", content: "10 km" },
      { type: "Duration", content: "7 hrs drive approx" },
    ],
    content: {
      body: "A healthy breakfast is a great way to start the day. It is today that you will ascend 3,505 meters to reach the Valley of Flowers.<p>It only takes a quick look at the lush surroundings to transport you to a vibrantly colored paradise. Enjoy a moment of pure bliss as you gaze upon one of nature's most exquisite masterpieces, which is sure to soothe your soul. Ascend farther up the valley to see thousands of vivid, colorful flowers in full bloom. Although it will be difficult to leave such a beautiful view behind, you must start your trip back to Ghangaria in good time in order to get there before dusk.After a scrumptious dinner, end the evening.</p>",
      media: [
        require("../../../assets/illustrations/Trekking_assets/gettyimages-95691163-612x612.jpg"),
        require("../../../assets/illustrations/Trekking_assets/gettyimages-520895902-612x612.jpg"),
        require("../../../assets/illustrations/Trekking_assets/download.jpeg"),
      ],
    },
  },
  {
    bulletTitle: "Day 4",
    title: "Ghangaria to Hemkund sahib | Back to Ghangaria",
    info: [
      { type: "Distance", content: "14 km" },
      { type: "Duration", content: "9 hrs drive approx" },
    ],
    content: {
      body: "Enjoy a hot cup of chai and wake up early to the sounds of folks chanting bhajans in the chorus. Trekking sticks might be useful on the steep ascending path up to Hemkund Sahib, which makes the trek quite challenging. You will notice a decrease in oxygen levels in the air, so walk gently and stop often. Go to the holy place of Hemkund Sahib, where Guru Gobind Singh is reputed to have meditated during a past incarnation.<p>This location is undoubtedly peaceful and serene, with the pristine Hemkund Lake just behind it and seven snow-capped mountains around it. As the weather in this area can be erratic, with sudden cloud cover and rain, begin the steep descent back to Ghangaria in the late afternoon. After you get back, spend the night at the hotel and have dinner outside under the stars.</p>",
      media: [
        require("../../../assets/illustrations/Trekking_assets/download (1).jpeg"),
        require("../../../assets/illustrations/Trekking_assets/222377999Badrinath_Hemkund_Sahib_Main.jpg"),
        require("../../../assets/illustrations/Trekking_assets/gettyimages-535166864-612x612.jpg"),
      ],
    },
  },
  {
    bulletTitle: "Day 5",
    title: "Ghangaria to Pulna | Drive to Joshimath/Pipalkoti",
    info: [
      { type: "Distance", content: "09 km" },
      { type: "Duration", content: "5 hrs" },
    ],
    additionalInfo: [
      { type: "Distance", content: "22 km" },
      { type: "Duration", content: "1 hr drive approx" },
    ],
    content: {
      body: "You will be commencing the simple 9 km descent to Pulna on the same track that you climbed on earlier, after breakfast in the morning. You can take a taxi from Pulna to Govindghat, which is 4 miles away. You can spend the evening taking a tour of the stunning Auli if you'd like.<p>Return later in the evening to spend the night at the Govindghat hotel.</p>",
      media: [
        require("../../../assets/illustrations/Trekking_assets/Pulna-Village-nearby-Govindghat-Uttarakhand.jpg"),
        require("../../../assets/illustrations/Trekking_assets/1570868099_joshimath.jpg"),
        require("../../../assets/illustrations/Trekking_assets/Joshimath-is-a-popular-tourist-town-in-Uttarakhand-Photo-Credit-Vaibhav-via-Wikimedia-Commons.jpg"),
      ],
    },
  },
  {
    bulletTitle: "Back",
    title: "Govindghat to Rishikesh/Haridwar",
    info: [
      { type: "Distance", content: "253 km" },
      { type: "Duration", content: "8 hrs drive approx" },
    ],
    content: {
      body: "Enjoy a hot cup of chai and wake up early to the sounds of folks chanting bhajans in the chorus. Trekking sticks might be useful on the steep ascending path up to Hemkund Sahib, which makes the trek quite challenging. You will notice a decrease in oxygen levels in the air, so walk gently and stop often. Go to the holy place of Hemkund Sahib, where Guru Gobind Singh is reputed to have meditated during a past incarnation.<p>This location is undoubtedly peaceful and serene, with the pristine Hemkund Lake just behind it and seven snow-capped mountains around it. As the weather in this area can be erratic, with sudden cloud cover and rain, begin the steep descent back to Ghangaria in the late afternoon. After you get back, spend the night at the hotel and have dinner outside under the stars.</p>",
      media: [
        require("../../../assets/illustrations/Trekking_assets/Govind-Ghat-Joshimath-A-Doorway-to-Valley-of-Flowers-Hemkund-Sahib.jpg"),
        require("../../../assets/illustrations/Trekking_assets/gettyimages-1022364592-612x612.jpg"),
        require("../../../assets/illustrations/Trekking_assets/gettyimages-907914618-612x612.jpg"),
      ],
    },
  },
]
