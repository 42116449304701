import HotelCard from "../HotelCard/HotelCard.component"
import "./BestExperience.styles.css"

/**image imports */
import SantegoBeach from "../../assets/illustrations/hotels_mp/Santego Beach Resort.png"
import GiriVihar from "../../assets/illustrations/hotels_mp/Giri Vihar.png"
import GoldenTulip from "../../assets/illustrations/hotels_mp/Golden Tulip.png"
import MontegoBay from "../../assets/illustrations/hotels_mp/Montego Bay.png"
const locationImages = [
  {
    imageUrl: MontegoBay,
    location: "Goa",
    hotelName: "Montego Bay",
    rating: "4",
    reviews: "100",
    price: 7000,
  },
  {
    imageUrl: GoldenTulip,
    location: "Candolim",
    hotelName: "Hotel Golden Tulip",
    rating: "4.5",
    reviews: "98",
    price: 5500,
  },
  {
    imageUrl: GiriVihar,
    location: "Lonavala",
    hotelName: "Hotel Giri Vihar",
    rating: "3.5",
    reviews: "54",
    price: 4700,
  },
]

const BestExperiences = () => {
  return (
    <section className="best-experiences-section-home">
      <header className="section-header">
        Best <span>Experiences</span>
      </header>
      <div className="best-experiences-container">
        {locationImages.map((l, i) => (
          <HotelCard key={`${l.location}${i}`} {...l} />
        ))}
      </div>
    </section>
  )
}

export default BestExperiences
