import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import Api from "../../../Service/Api";
import FormikInlineCheckboxAndSwitchOnly from "../../../components/amenityComponents/inlineCheckBoxAndSwitchOnly.component";
import InlineFormikFieldWithCheckSelectAndSwitch from "../../../components/amenityComponents/inlineCheckBoxWithSelectAndSwitch.component";
import ScreenLoader from "../../../components/loadingScreen/screenLoader.component";
import {
  childrensplayareaInitial,
  conferenceareaInitial,
  fireplaceInitial,
  jacuzziInitial,
  loungesInitial,
  outdoorFurnitureInitial,
  shoppingInitial,
  templeInitial,
} from "../../../redux/actions/amenitiesDropDownAction";

const initialValues = {
  caretaker: false,
  caretakerStarred: false,
  lawn: false,
  lawnStarred: false,
  fireplace: false,
  fireplaceType: "",
  fireplaceStarred: false,
  lounge: false,
  loungeType: [],
  loungeStarred: false,
  jacuzzi: false,
  jacuzziType: "",
  jacuzziStarred: false,
  seatingArea: false,
  seatingAreaStarred: false,
  templeChapelPrayerRoom: false,
  templeChapelPrayerRoomType: [],
  templeChapelPrayerRoomStarred: false,
  verandah: false,
  verandahStarred: false,
  livingRoom: false,
  livingRoomStarred: false,
  outdoorFurniture: false,
  outdoorFurnitureType: [],
  outdoorFurnitureStarred: false,
  conferenceArea: false,
  conferenceAreaType: [],
  conferenceAreaStarred: false,
  petPlayArea: false,
  petPlayAreaStarred: false,
  atm: false,
  atmStarred: false,
  shopping: false,
  shoppingType: [],
  shoppingStarred: false,
  childrenPlayArea: false,
  childrenPlayAreaType: [],
  childrenPlayAreaStarred: false,
};

const commonAreaAmenitiesWthSwitch = [
  {
    name: "caretaker",
    label: "Caretaker",
    switchName: "caretakerStarred",
  },
  {
    name: "lawn",
    label: "Lawn",
    switchName: "lawnStarred",
  },

  {
    name: "seatingArea",
    label: "Seating Area",
    switchName: "seatingAreaStarred",
  },
  {
    name: "verandah",
    label: "Verandah",
    switchName: "verandahStarred",
  },

  {
    name: "livingRoom",
    label: "Living Room",
    switchName: "livingRoomStarred",
  },

  {
    name: "petPlayArea",
    label: "Pet Play Area",
    switchName: "petPlayAreaStarred",
  },
  {
    name: "atm",
    label: "ATM",
    switchName: "atmStarred",
  },
];

const CommonAreaAmenities = ({
  classes,
  handleChangePanel,
  expanded,
  setExpanded,
}) => {
  const [commonAreaAmenities, setCommonAreaAmenities] = useState({});
  const { propertyId } = useParams();
  const dispatch = useDispatch();
  const firePlaceLists = useSelector(
    (state) => state.firePlaceList.firePlaceLists
  );
  const loungesLists = useSelector((state) => state.loungesList.loungesLists);
  const jacuzziLists = useSelector((state) => state.jacuzziList.jacuzziLists);
  const conferenceareaLists = useSelector(
    (state) => state.conferenceareaList.conferenceareaLists
  );
  const shoppingLists = useSelector(
    (state) => state.shoppingList.shoppingLists
  );
  const childrenplayareaLists = useSelector(
    (state) => state.childrenplayareaList.childrenplayareaLists
  );
  const templeLists = useSelector((state) => state.templeList.templeLists);
  const outdoorFurnitureLists = useSelector(
    (state) => state.outdoorFurnitureList.outdoorFurnitureLists
  );

  const commonAreaAmenitiesWithSelectField = [
    {
      name: "fireplace",
      label: "Fire Place",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "fireplaceType",
      switchName: "fireplaceStarred",
      dropdDownOptions: firePlaceLists.map((option) => ({
        label: option,
        value: option,
      })),
    },

    {
      name: "lounge",
      label: "Lounge",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "loungeType",
      switchName: "loungeStarred",
      dropdDownOptions: loungesLists,
    },

    {
      name: "jacuzzi",
      label: "Jacuzzi",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "jacuzziType",
      switchName: "jacuzziStarred",
      dropdDownOptions: jacuzziLists.map((option) => ({
        label: option,
        value: option,
      })),
    },

    {
      name: "templeChapelPrayerRoom",
      label: "Prayer Room",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "templeChapelPrayerRoomType",
      switchName: "templeChapelPrayerRoomStarred",
      dropdDownOptions: templeLists,
    },

    {
      name: "outdoorFurniture",
      label: "Outdoor Furniture",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "outdoorFurnitureType",
      switchName: "outdoorFurnitureStarred",
      dropdDownOptions: outdoorFurnitureLists,
    },

    {
      name: "conferenceArea",
      label: "Banquet Hall",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "conferenceAreaType",
      switchName: "conferenceAreaStarred",
      dropdDownOptions: conferenceareaLists,
    },

    {
      name: "shopping",
      label: "Shopping",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "shoppingType",
      switchName: "shoppingStarred",
      dropdDownOptions: shoppingLists,
    },

    {
      name: "childrenPlayArea",
      label: "Children Play Area",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "childrenPlayAreaType",
      switchName: "childrenPlayAreaStarred",
      dropdDownOptions: childrenplayareaLists,
    },
  ];

  useEffect(() => {
    if (propertyId && expanded === "panel3") {
      dispatch(fireplaceInitial());
      dispatch(loungesInitial());
      dispatch(jacuzziInitial());
      dispatch(shoppingInitial());
      dispatch(childrensplayareaInitial());
      dispatch(conferenceareaInitial());
      dispatch(templeInitial());
      dispatch(outdoorFurnitureInitial());
    }
  }, [expanded]);

  const fetchCommonAreaAmenities = async () => {
    setLoading(true);
    try {
      const normalData = await Api.get(
        `amenitiescommonareavalue/${propertyId}`
      );
      const switchData = await Api.get(
        `AmenitiesCommonAreaStarredvalue/${propertyId}`
      );
      console.log(normalData.data);
      console.log(switchData.data);
      setCommonAreaAmenities({ ...normalData.data, ...switchData.data });
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (propertyId && expanded === "panel3") fetchCommonAreaAmenities();
  }, [expanded]);

  const [loading, setLoading] = useState(() => false);
  const data = { propertyId: propertyId };
  const uniqueid = localStorage.getItem("unique_id");
  const createdBy = localStorage.getItem("auth");
  const create = { uniqueId: uniqueid, createdBy: createdBy };

  const handlePanelChange = () => {
    setExpanded("panel4");
  };

  function onSubmit(fields, { setStatus }) {
    const id = commonAreaAmenities.id;
    setStatus();
    if (fields.fireplace == false) {
      fields.fireplaceType = "";
      fields.fireplaceStarred = false;
    }

    if (fields.lounge == false) {
      fields.loungeType = [];
      fields.loungeStarred = false;
    }

    if (fields.jacuzzi == false) {
      fields.jacuzziType = "";
      fields.jacuzziStarred = false;
    }

    if (fields.templeChapelPrayerRoom == false) {
      fields.templeChapelPrayerRoomType = [];
      fields.templeChapelPrayerRoomStarred = false;
    }
    if (fields.outdoorFurniture == false) {
      fields.outdoorFurnitureType = [];
      fields.outdoorFurnitureStarred = false;
    }
    if (fields.conferenceArea == false) {
      fields.conferenceAreaType = [];
      fields.conferenceAreaStarred = false;
    }
    if (fields.shopping == false) {
      fields.shoppingType = [];
      fields.shoppingStarred = false;
    }
    if (fields.childrenPlayArea == false) {
      fields.childrenPlayAreaType = [];
      fields.childrenPlayAreaStarred = false;
    }

    if (fields.caretaker == false) {
      fields.caretakerStarred = false;
    }
    if (fields.lawn == false) {
      fields.lawnStarred = false;
    }

    if (fields.seatingArea == false) {
      fields.seatingAreaStarred = false;
    }

    if (fields.verandah == false) {
      fields.verandahStarred = false;
    }
    if (fields.livingRoom == false) {
      fields.livingRoomStarred = false;
    }

    if (fields.petPlayArea == false) {
      fields.petPlayAreaStarred = false;
    }

    if (fields.atm == false) {
      fields.atmStarred = false;
    }

    if (id) {
      updateCommon(fields);
    } else {
      createCommon(fields);
    }
  }

  function createStarred(fields) {
    fields.propertyId = propertyId;
    const newData = { ...data, ...fields, ...create };
    Api.post("AmenitiesCommonAreaStarred", newData);
  }

  function updateStarred(fields) {
    fields.updateBy = createdBy;
    Api.put("AmenitiesCommonAreaStarredupdate", fields);
  }

  function createCommon(fields) {
    setLoading(true);
    fields.propertyId = propertyId;
    const newData = { ...data, ...fields, ...create };

    console.log(newData);
    Api.post("amenitiescommonarea", newData).then((res) => {
      if (res) {
        createStarred(fields);
        toast.success("Successfully Created");
        handlePanelChange();
        setLoading(false);
      }
    });
  }

  function updateCommon(fields) {
    setLoading(true);
    fields.updateBy = createdBy;
    Api.put("amenitiescommonareaupdate", fields).then((res) => {
      if (res) {
        updateStarred(fields);
        toast.success("Successfully Updated");
        handlePanelChange();
        setLoading(false);
      }
    });
  }

  return (
    <Formik
      initialValues={commonAreaAmenities || initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue }) =>
        loading ? (
          <ScreenLoader open={loading} />
        ) : (
          <Form autoComplete="off">
            <Accordion
              square
              expanded={expanded === "panel3"}
              onChange={handleChangePanel("panel3")}
              className={classes.accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#f46d25",
                      background: "white",
                      borderRadius: "5px",
                    }}
                  />
                }
                className={classes.accordionSummaryStyles}
              >
                Common Area
              </AccordionSummary>
              <AccordionDetails className={classes.outerSectionStyles}>
                <Grid container spacing={3}>
                  {commonAreaAmenitiesWithSelectField.map((amenity) => (
                    <InlineFormikFieldWithCheckSelectAndSwitch
                      amenity={amenity}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ))}

                  {/**Common amenities switch only */}
                  {commonAreaAmenitiesWthSwitch.map((amenity) => (
                    <FormikInlineCheckboxAndSwitchOnly
                      amenity={amenity}
                      values={values}
                    />
                  ))}
                </Grid>
              </AccordionDetails>
              <center>
                <Button
                  style={{ height: "30px", margin: "10px 0px" }}
                  type="submit"
                >
                  Save and Next
                </Button>
              </center>
            </Accordion>
          </Form>
        )
      }
    </Formik>
  );
};

export default CommonAreaAmenities;
