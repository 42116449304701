import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  makeStyles,
} from "@material-ui/core"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import { Grid } from "@mui/material"
import { Field, Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import Api from "../../../Service/Api"
import FormikInlineCheckboxAndSwitchOnly from "../../../components/amenityComponents/inlineCheckBoxAndSwitchOnly.component"
import InlineFormikFieldWithCheckSelectAndSwitch from "../../../components/amenityComponents/inlineCheckBoxWithSelectAndSwitch.component"
import ScreenLoader from "../../../components/loadingScreen/screenLoader.component"
import {
  ParkingInitial,
  airConditioningInitial,
  interComInitial,
  kitchenInitial,
  laundryInitial,
  roomServiceInitial,
  smokeDetectorInitial,
  swimmingPoolInitial,
} from "../../../redux/actions/amenitiesDropDownAction"
import _ from "lodash"

const useStyles = makeStyles(() => ({
  checkboxStyling: {
    "& .MuiSvgIcon-root": {
      color: "#f46d25",
      height: "18px",
      width: "18px",
    },
  },
  menuStyling: {
    fontSize: "14px",
  },
  selectLabelProps: {
    backgroundColor: "white",
    color: "#000000",
    padding: "0px 5px 5px 5px",
    borderRadius: "5px",
  },
  menuItemStyles: {
    height: "300px",
    fontSize: "14px",
  },
  selectStyle: {
    height: "35px",
    width: "100%",
    "& .MuiSelect-icon": {
      color: "#f46d25",
    },
  },
  checkBoxLabelStyles: {
    fontSize: "14px",
  },
}))

const basicAmenitiesInitalValues = {
  airconditioning: false,
  airconditioningType: "",
  airconditioningStarred: false,
  bathroom: false,
  bathroomType: "",
  bathroomStarred: false,
  laundry: false,
  laundryType: [],
  laundryStarred: false,
  housekeeping: false,
  housekeepingStarred: false,
  intercom: false,
  intercomType: [],
  intercomStarred: false,
  kitchen: false,
  kitchenType: [],
  kitchenStarred: false,
  parking: false,
  parkingType: "",
  parkingType1: "",
  parkingStarred: false,
  powerbackup: false,
  powerbackupStarred: false,
  refrigeratorminibar: false,
  refrigeratorminibarType: "",
  refrigeratorminibarStarred: false,
  roomservice: false,
  roomserviceType: "",
  roomserviceStarred: false,
  smokedetector: false,
  smokedetectorType: [],
  smokedetectorStarred: false,
  swimmingPool: false,
  swimmingPoolType: [],
  swimmingPoolStarred: false,
  smokingrooms: false,
  smokingRoomsStarred: false,
  publicrestrooms: false,
  publicrestroomsStarred: false,
  laundromat: false,
  laundromatStarred: false,
}

const PropertyBasicAmenities = ({
  classes,
  handleChangePanel,
  expanded,
  setExpanded,
}) => {
  const selectStyles = useStyles()
  const dispatch = useDispatch()
  const { propertyId } = useParams()
  const [basicAmenities, setBasicAmenities] = useState({})
  const kitchenLists = useSelector((state) => state.kitchenList.kitchenLists)
  const airConditionLists = useSelector(
    (state) => state.airConditionList.airConditionLists
  )

  const [initialValues, setInitialValues] = useState(basicAmenitiesInitalValues)

  const roomServiceLists = useSelector(
    (state) => state.roomServiceList.roomServiceLists
  )
  const smokeDetectorLists = useSelector(
    (state) => state.smokeDetectorList.smokeDetectorLists
  )
  const swimmingpoolLists = useSelector(
    (state) => state.swimmingpoolList.swimmingpoolLists
  )
  const interComLists = useSelector((state) => state.interComList.interComLists)
  const laundryLists = useSelector((state) => state.laundryList.laundryLists)
  const parkingLists = useSelector((state) => state.parkingList.parkingLists)

  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    if (propertyId && expanded == "panel1") {
      dispatch(ParkingInitial())
      dispatch(laundryInitial())
      dispatch(swimmingPoolInitial())
      dispatch(smokeDetectorInitial())
      dispatch(roomServiceInitial())
      dispatch(kitchenInitial())
      dispatch(airConditioningInitial())
      dispatch(interComInitial())
    }
  }, [expanded])

  const fetchBasicAmenities = async () => {
    setLoading(true)
    try {
      const res = await Api.get(`amenitiesbasicfacilitiesvalue/${propertyId}`)
      const { data } = await Api.get(
        `amenitiesbasicfacilitiesstarredvalue/${propertyId}`
      )
      console.log(data)
      console.log(res.data)
      setBasicAmenities({ ...res.data, ...data })
      setInitialValues({ ...res.data, ...data })
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (_.isEmpty(initialValues)) {
    }
  }, [])

  useEffect(() => {
    if (propertyId && expanded == "panel1") {
      fetchBasicAmenities()
    }
  }, [expanded])

  const basicAmenitiesWithMultipleSelectFields = [
    {
      name: "parking",
      label: "Parking",
      select: true,
      selectLabel1: "Type",
      selectLabel2: "Location",
      selectFieldName1: "parkingType",
      selectFieldName2: "parkingType1",
      switchName: "parkingStarred",
    },
  ]

  const basicAmenitiesSwitchOnly = [
    {
      name: "publicrestrooms",
      label: "Public Restroom",
      switchName: "publicrestroomsStarred",
    },
    {
      name: "laundromat",

      label: "Laundromat",
      switchName: "laundromatStarred",
    },
    {
      name: "housekeeping",
      label: "Housekeeping",
      switchName: "housekeepingStarred",
    },
    {
      name: "powerbackup",
      label: "Power Backup",
      switchName: "powerbackupStarred",
    },
    {
      name: "smokingrooms",
      label: "Smoking Rooms",
      switchName: "smokingroomsStarred",
    },
  ]

  const basicAmenitiesWithSelectComponent = [
    {
      name: "airconditioning",
      label: "Air Conditioning",
      select: true,
      selectType: "singleValue",
      selectLabel: "Air Conditioning Type",
      selectFieldName: "airconditioningType",
      switchName: "airconditioningStarred",
      dropdDownOptions: airConditionLists.map((condition) => ({
        value: condition,
        label: condition,
      })),
    },
    {
      name: "bathroom",
      label: "Bathroom",
      select: true,
      selectType: "singleValue",
      selectLabel: "Bathroom Type",
      selectFieldName: "bathroomType",
      switchName: "bathroomStarred",
      dropdDownOptions: [
        { value: "attached", label: "Attached" },
        { value: "common", label: "Common" },
      ],
    },
    {
      name: "laundry",
      label: "Laundry",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Laundry Type",
      selectFieldName: "laundryType",
      switchName: "laundryStarred",
      dropdDownOptions: laundryLists,
    },
    {
      name: "intercom",
      label: "Intercom",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Intercom Type",
      selectFieldName: "intercomType",
      switchName: "intercomStarred",
      dropdDownOptions: interComLists,
    },
    {
      name: "kitchen",
      label: "Kitchen / Kitchenette",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Kitchen Type",
      selectFieldName: "kitchenType",
      switchName: "kitchenStarred",
      dropdDownOptions: kitchenLists.map((kitchen) => ({
        value: kitchen,
        label: kitchen,
      })),
    },
    {
      name: "refrigeratorminibar",
      label: "Refrigerator / Minibar",
      select: true,
      selectType: "singleValue",
      selectLabel: "Refrigerator/Minibar Type",
      selectFieldName: "refrigeratorminibarType",
      switchName: "refrigeratorminibarStarred",
      dropdDownOptions: [
        { value: "free", label: "Free" },
        { value: "paid", label: "Paid" },
      ],
    },
    {
      name: "roomservice",
      label: "Room Service",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "roomserviceType",
      switchName: "roomserviceStarred",
      dropdDownOptions: roomServiceLists.map((service) => ({
        value: service,
        label: service,
      })),
    },
    {
      name: "smokedetector",
      label: "Smoke Detector",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "smokedetectorType",
      switchName: "smokedetectorStarred",
      dropdDownOptions: smokeDetectorLists,
    },
    {
      name: "swimmingPool",
      label: "Swimming Pool",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "swimmingPoolType",
      switchName: "swimmingPoolStarred",
      dropdDownOptions: swimmingpoolLists,
    },
  ]

  const uniqueid = localStorage.getItem("unique_id")
  const createdBy = localStorage.getItem("auth")
  const create = { uniqueId: uniqueid, createdBy: createdBy }

  const data = { propertyId: propertyId }

  function onSubmit(fields, { setStatus }) {
    console.log(fields)
    const id = basicAmenities.id
    setStatus()
    if (fields.airconditioning == false) {
      fields.airconditioningType = ""
      fields.airconditioningStarred = false
    }
    if (fields.bathroom == false) {
      fields.bathroomType = ""
      fields.bathroomStarred = false
    }
    if (fields.laundry == false) {
      fields.laundryType = []
      fields.laundryStarred = false
    }

    if (fields.housekeeping == false) {
      fields.housekeepingStarred = false
    }

    if (fields.intercom == false) {
      fields.intercomType = []
      fields.intercomStarred = false
    }

    if (fields.kitchen == false) {
      fields.kitchenType = []
      fields.kitchenStarred = false
    }

    if (fields.parking == false) {
      fields.parkingType = ""
      fields.parkingType1 = ""
      fields.parkingStarred = false
    }

    if (fields.powerbackup == false) {
      fields.powerbackupStarred = false
    }
    if (fields.refrigeratorminibar == false) {
      fields.refrigeratorminibar = ""
      fields.refrigeratorminibarType = ""
      fields.refrigeratorminibarStarred = false
    }

    if (fields.roomservice == false) {
      fields.roomserviceType = ""
      fields.roomserviceStarred = false
    }
    if (fields.smokedetector == false) {
      fields.smokedetectorType = []
      fields.smokedetectorStarred = false
    }
    if (fields.swimmingPool == false) {
      fields.swimmingPoolType = []
      fields.swimmingPoolStarred = false
    }
    if (fields.smokingrooms == false) {
      fields.smokingRoomsStarred = false
    }
    if (fields.publicrestrooms == false) {
      fields.publicrestroomsStarred = false
    }
    if (fields.laundromat == false) {
      fields.laundromatStarred = false
    }

    if (id) {
      updateBasicFacilite(fields)
    } else {
      createBasicFacilite(fields)
    }
  }

  function createStarred(fields) {
    console.log(propertyId)
    fields.propertyId = propertyId //this line is mandatory or else the property id will be set to null while creating new one.
    var newData = { ...data, ...fields, ...create }
    console.log(newData)
    Api.post("amenitiesbasicfacilitiesstarred", newData)
  }

  function updateStarred(fields) {
    console.log(fields)
    fields.updateBy = createdBy
    Api.put("amenitiesbasicfacilitiesstarredupdate", fields)
  }

  const handleChangeAccordion = () => {
    setExpanded("panel2")
  }
  function createBasicFacilite(fields) {
    setLoading(true)
    const newData = { ...fields, ...data, ...create }
    console.log(newData)
    Api.post("amenitiesbasicfacilities", newData).then((res) => {
      if (res) {
        createStarred(fields)
        toast.success("Successfully Created")
        handleChangeAccordion()
        fetchBasicAmenities()
        setLoading(false)
      }
    })
  }

  function updateBasicFacilite(fields) {
    fields.updateBy = createdBy
    setLoading(true)
    console.log(fields)
    Api.put("amenitiesbasicfacilitiesupdate", fields).then((res) => {
      if (res) {
        updateStarred(fields)
        toast.success("Successfully Updated")
        handleChangeAccordion()
        fetchBasicAmenities()
        setLoading(false)
      }
    })
  }

  return (
    <>
      {loading ? (
        <ScreenLoader open={loading} />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form autoComplete="off">
              <Accordion
                square
                expanded={expanded === "panel1"}
                onChange={handleChangePanel("panel1")}
                className={classes.accordionStyles}
              >
                <AccordionSummary
                  expandIcon={
                    <ArrowDropDownIcon
                      style={{
                        color: "#f46d25",
                        background: "white",
                        borderRadius: "5px",
                      }}
                    />
                  }
                  className={classes.accordionSummaryStyles}
                >
                  Basic Details
                </AccordionSummary>
                <AccordionDetails className={classes.outerSectionStyles}>
                  <Grid container spacing={3}>
                    {basicAmenitiesWithSelectComponent.map((amenity) => (
                      <InlineFormikFieldWithCheckSelectAndSwitch
                        amenity={amenity}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    ))}
                    {/**parking fields with multiple select fields */}
                    {basicAmenitiesWithMultipleSelectFields.map((amenity) => (
                      <Grid
                        item
                        container
                        xs={9}
                        alignItems="center"
                        spacing={3}
                      >
                        <Grid item xs={3}>
                          <Field
                            as={FormControlLabel}
                            control={
                              <Field
                                checked={values[amenity.name]}
                                as={Checkbox}
                                name={amenity.name}
                                className={selectStyles.checkboxStyling}
                              />
                            }
                            label={
                              <span
                                className={selectStyles.checkBoxLabelStyles}
                              >
                                {amenity.label}
                              </span>
                            }
                          />
                        </Grid>
                        {values[amenity.name] && (
                          <>
                            <Grid item xs={3}>
                              <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                              >
                                <InputLabel
                                  className={selectStyles.selectLabelProps}
                                  shrink
                                >
                                  {amenity.selectLabel1}
                                </InputLabel>
                                <Field
                                  as={Select}
                                  name={amenity.selectFieldName1}
                                  value={values[amenity.selectFieldName1]}
                                  className={selectStyles.selectStyle}
                                  MenuProps={{
                                    className: selectStyles.menuItemStyles,
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                  }}
                                >
                                  {new Array(
                                    { value: "free", label: "Free" },
                                    { value: "paid", label: "Paid" }
                                  ).map((option) => (
                                    <MenuItem
                                      className={selectStyles.menuStyling}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Field>
                              </FormControl>
                            </Grid>
                            {values[amenity.selectFieldName1] === "paid" && (
                              <Grid item xs={3}>
                                <FormControl
                                  variant="outlined"
                                  style={{ width: "100%" }}
                                >
                                  <InputLabel
                                    className={selectStyles.selectLabelProps}
                                    shrink
                                  >
                                    {amenity.selectLabel2}
                                  </InputLabel>
                                  <Field
                                    as={Select}
                                    name={amenity.selectFieldName2}
                                    value={values[amenity.selectFieldName2]}
                                    className={selectStyles.selectStyle}
                                    MenuProps={{
                                      className: selectStyles.menuItemStyles,
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                      },
                                      transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                      },
                                      getContentAnchorEl: null,
                                    }}
                                  >
                                    {parkingLists.map((option) => (
                                      <MenuItem
                                        className={selectStyles.menuStyling}
                                        value={option}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </Field>
                                </FormControl>
                              </Grid>
                            )}
                            <Grid item xs={3}>
                              <Field
                                name={amenity.switchName}
                                as={Switch}
                                color="primary"
                                checked={values[amenity.switchName]}
                                size="small"
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    ))}

                    {basicAmenitiesSwitchOnly.map((amenity) => (
                      <FormikInlineCheckboxAndSwitchOnly
                        amenity={amenity}
                        values={values}
                      />
                    ))}
                  </Grid>
                </AccordionDetails>
                <center>
                  <Button
                    style={{ height: "30px", margin: "10px 0px" }}
                    type="submit"
                  >
                    Save and Next
                  </Button>
                </center>
              </Accordion>
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

export default PropertyBasicAmenities
