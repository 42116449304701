import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core"
import RoomFeatures from "../../../Property/Rooms/Amenities/RoomFeatures"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import { useEffect } from "react"
import Api from "../../../../Service/Api"
import { useState } from "react"
import { Form, Formik } from "formik"
import { Grid } from "@mui/material"
import InlineFormikFieldWithCheckSelectAndSwitch from "../../../../components/amenityComponents/inlineCheckBoxWithSelectAndSwitch.component"
import FormikInlineCheckboxAndSwitchOnly from "../../../../components/amenityComponents/inlineCheckBoxAndSwitchOnly.component"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import ScreenLoader from "../../../../components/loadingScreen/screenLoader.component"

const initialValues = {
  dvdPlayer: false,
  dvdPlayerType: "",
  dvdPlayerStarred: false,
  inhousemovies: false,
  inhousemoviesStarred: false,
  kindle: false,
  kindleStarred: false,
  tv: false,
  tvType: [],
  tvStarred: false,
  iPodDockingStation: false,
  iPodDockingStationStarred: false,
  homeTheatre: false,
  homeTheatreStarred: false,
  smartControls: false,
  smartControlsStarred: false,
  soundSpeakers: false,
  soundSpeakersStarred: false,
  gameConsole: false,
  gameConsoleType: [],
  gameConsoleStarred: false,
}

const PropertyRoomMediaAndEnteratinment = ({
  classes,
  handleChangePanel,
  expanded,
  setExpanded,
}) => {
  const { propertyId, displayName } = useParams()
  const data = { propertyId: propertyId, displayName: displayName }
  var uniqueid = localStorage.getItem("unique_id")
  var createdBy = localStorage.getItem("auth")
  const create = { uniqueId: uniqueid, createdBy: createdBy }
  const [RoomMediaDVDPlayer, setRoomMediaDVDPlayer] = useState([])
  const [RoomMediaTV, setRoomMediaTV] = useState([])
  const [RoomMediaGameConsole, setRoomMediaGameConsole] = useState([])
  const [mediaAndEntertainment, setMediaAndEntertainment] = useState(() => {})

  const [loading, setLoading] = useState(false)

  const fetchRoomFeatures = async () => {
    setLoading(true)
    try {
      const normalData = await Api.get(
        `RAmenitiesEntertainmentvalue/${propertyId}/${displayName}`
      )
      const switchData = await Api.get(
        `RAmenitiesEntertainmentStarredvalue/${propertyId}/${displayName}`
      )

      console.log(normalData.data)
      console.log(switchData.data)

      setMediaAndEntertainment({ ...normalData.data, ...switchData.data })
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (propertyId && displayName && expanded === "panel4") {
      fetchRoomFeatures()
    }
  }, [expanded])

  const RoomMediaDVDPlayerGet = async () => {
    const dvdData = {
      type: "Room Media DVD Player",
    }
    await Api.post("productdropdownonly", dvdData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomMediaDVDPlayer(response.data)
    })
  }

  const RoomMediaTVGet = async () => {
    const tvData = {
      type: "Room Media TV",
    }
    await Api.post("productdropdownonly", tvData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomMediaTV(response.data)
    })
  }
  const RoomMediaGameConsoleGet = async () => {
    const gameData = {
      type: "Room Media Game Console",
    }
    await Api.post("productdropdownonly", gameData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomMediaGameConsole(response.data)
    })
  }

  useEffect(() => {
    if (propertyId && displayName && expanded === "panel4") {
      RoomMediaDVDPlayerGet()
      RoomMediaTVGet()
      RoomMediaGameConsoleGet()
    }
  }, [expanded])

  const handleAccordionChange = () => setExpanded("panel5")

  function onSubmit(fields, { setStatus }) {
    const id = mediaAndEntertainment.id

    if (fields.dvdPlayer == false) {
      fields.dvdPlayerType = ""
      fields.dvdPlayerStarred = false
    }
    if (fields.inhousemovies == false) {
      fields.inhousemoviesStarred = false
    }
    if (fields.kindle == false) {
      fields.kindleStarred = false
    }
    if (fields.tv == false) {
      fields.tvType = []
      fields.tvStarred = false
    }
    if (fields.iPodDockingStation == false) {
      fields.iPodDockingStationStarred = false
    }
    if (fields.homeTheatre == false) {
      fields.homeTheatreStarred = false
    }
    if (fields.smartControls == false) {
      fields.smartControlsStarred = false
    }
    if (fields.soundSpeakers == false) {
      fields.soundSpeakersStarred = false
    }
    if (fields.gameConsole == false) {
      fields.gameConsoleType = []
      fields.gameConsoleStarred = false
    }

    setStatus()
    if (id) {
      updateMedia(fields)
    } else {
      createMedia(fields)
    }
  }
  function createStarred(fields) {
    fields.propertyId = propertyId
    fields.displayName = displayName
    const newData = { ...fields, ...create }
    Api.post("RAmenitiesEntertainmentStarred", newData)
  }

  function updateStarred(fields) {
    fields.updateBy = createdBy
    Api.put("RAmenitiesEntertainmentStarredupdate", fields)
  }

  function createMedia(fields) {
    setLoading(true)
    fields.propertyId = propertyId
    fields.displayName = displayName
    const newData = { ...fields, ...create }
    Api.post("RAmenitiesEntertainment", newData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      createStarred(fields)
      toast.success("Successfully Created")
      handleAccordionChange()
      setLoading(false)
    })
  }
  function updateMedia(fields) {
    fields.updateBy = createdBy
    setLoading(true)
    Api.put("RAmenitiesEntertainmentupdate", fields, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      updateStarred(fields)
      toast.success("Successfully Updated")
      handleAccordionChange()
      setLoading(false)
    })
  }

  const mediaAndEntertainmentWithSelect = [
    {
      name: "dvdPlayer",
      label: "DVD Player",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "dvdPlayerType",
      switchName: "dvdPlayerStarred",
      dropdDownOptions: RoomMediaDVDPlayer.map((option) => ({
        value: option,
        label: option,
      })),
    },

    {
      name: "gameConsole",
      label: "Game Console",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "gameConsoleType",
      switchName: "gameConsoleStarred",
      dropdDownOptions: RoomMediaGameConsole,
    },

    {
      name: "tv",
      label: "TV",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "tvType",
      switchName: "tvStarred",
      dropdDownOptions: RoomMediaTV,
    },
  ]

  const mediaAndEntertainmentSwitchOnly = [
    {
      name: "inhousemovies",
      label: "in-House Movies",
      switchName: "inhousemoviesStarred",
    },
    {
      name: "kindle",
      label: "Kindle",
      switchName: "kindleStarred",
    },
    {
      name: "iPodDockingStation",
      label: "IPod Docking Station",
      switchName: "iPodDockingStationStarred",
    },

    {
      name: "homeTheatre",
      label: "Home Theatre",
      switchName: "homeTheatreStarred",
    },

    {
      name: "smartControls",
      label: "Smart Controls",
      switchName: "smartControlsStarred",
    },
    {
      name: "soundSpeakers",
      label: "Sound Speakers",
      switchName: "soundSpeakersStarred",
    },
  ]

  return (
    <Formik
      initialValues={mediaAndEntertainment || initialValues}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) =>
        loading ? (
          <ScreenLoader open={loading} />
        ) : (
          <Form autoComplete="off">
            <Accordion
              square
              expanded={expanded === "panel4"}
              onChange={handleChangePanel("panel4")}
              className={classes.accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#f46d25",
                      background: "white",
                      borderRadius: "5px",
                    }}
                  />
                }
                className={classes.accordionSummaryStyles}
              >
                Media And Entertainment
              </AccordionSummary>
              <AccordionDetails className={classes.outerSectionStyles}>
                <Grid container spacing={1}>
                  {mediaAndEntertainmentWithSelect.map((amenity) => (
                    <InlineFormikFieldWithCheckSelectAndSwitch
                      amenity={amenity}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ))}

                  {mediaAndEntertainmentSwitchOnly.map((amenity) => (
                    <FormikInlineCheckboxAndSwitchOnly
                      amenity={amenity}
                      values={values}
                    />
                  ))}
                </Grid>
              </AccordionDetails>
              <center>
                <Button type="submit" size="small">
                  Save and Next
                </Button>
              </center>
            </Accordion>
          </Form>
        )
      }
    </Formik>
  )
}

export default PropertyRoomMediaAndEnteratinment
