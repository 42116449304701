import { useContext, useEffect } from "react"
import styles from "./DestinationTourism.module.css"
import globalStyles from "../../../../globalStyles.module.css"
import { DestinationLayoutContext } from "../../../../layout/DestinationLayout/Destination.layout"
import MockImage from "../../../../assets/illustrations/mockImages/GOA.jpg"
import ContactInfo from "../../../../components/ContactInfo/ContactInfo.component"
import QueryForm from "../../../../components/QueryForm/QueryForm.component"
import BiggerGroupBanner from "../../../../assets/illustrations/destination/banner_images/bigger_grp_banner.png"
import BiggerGroupBannerPost from "../../../../components/BiggerGroupBanner/BiggerGroupBanner.component"
import AsideDestinationNavbar from "../../../../components/AsideDestinationNavbar/AsideDestinationNavbar.component"
import InlineImageAndContentCard from "../../../../components/InlineImageAndContentCard/InlineImageAndContent.component"

import CardImage from "../../../../assets/illustrations/destination/mock_card_images/inline_image_1.png"
import ImageAndContentCard from "../../../../components/ImageAndContentCard/ImageAndContentCard.component"

import NainitalImg from "../../../../assets/illustrations/destination/where-you-headed/nanital.jpg"
import WeatherImg from "../../../../assets/illustrations/destination/where-you-headed/uttarakhand_weather.jpg"

import FoodImg1 from "../../../../assets/illustrations/destination/food/food-uk.png"
import FoodImg2 from "../../../../assets/illustrations/destination/food/Eating Maggi on the way from Dehradun to Mussoorie1.jpg"
import FoodImg3 from "../../../../assets/illustrations/destination/food/Eating Maggi on the way from Dehradun to Mussoorie3.jpg"

import DehradunImg from "../../../../assets/illustrations/destination/where-you-headed/Dehradun.jpg"
import HaridwarImg from "../../../../assets/illustrations/destination/where-you-headed/Haridwar.jpg"
import MussoorieImg from "../../../../assets/illustrations/destination/where-you-headed/Mussoorie.jpg"
import RishikeshImg from "../../../../assets/illustrations/destination/where-you-headed/Rishikesh2.jpg"
import TungnathTempleImg from "../../../../assets/illustrations/destination/where-you-headed/Tungnath-Temple-by-Abhinav-Tunait-www.flickr.comphotos103602929@N08.jpg"
import UkhimathImg from "../../../../assets/illustrations/destination/where-you-headed/Ukhimath.jpg"
import AuliImg from "../../../../assets/illustrations/destination/where-you-headed/auli.jpg"
import DhanaultiImg from "../../../../assets/illustrations/destination/where-you-headed/Dhanaulti_2.jpg"
import ChamoliImg from "../../../../assets/illustrations/destination/where-you-headed/valley-of-flowers-5353172.jpg"
import MunsyariImg from "../../../../assets/illustrations/destination/where-you-headed/Chopta Chandrashila Trek3.JPG"
import BadrinathImg from "../../../../assets/illustrations/destination/where-you-headed/BadrinathTemple.jpg"

import Rafting from "../../../../assets/illustrations/destination/slider-images/rafting.jpg"
import BungeeJumping from "../../../../assets/illustrations/destination/things_todo/Bungee jumping in Rishikesh5.jpg"
import KrmptyFalls from "../../../../assets/illustrations/destination/things_todo/Visit To Kempty Falls in Mussoorie1.jpg"
import RajajiNationalPark from "../../../../assets/illustrations/destination/things_todo/Visit to Rajaji National Park2.jpg"
import ValleyOfFlowers from "../../../../assets/illustrations/destination/things_todo/Visit to the Valley of Flowers National Park3.jpg"

import EventImg1 from "../../../../assets/illustrations/destination/events_img/Evening Aarti in Haridwar and Rishikesh2.jpg"
import EventImg2 from "../../../../assets/illustrations/destination/events_img/Evening Aarti in Haridwar and Rishikesh5.jpeg"
import EventImg3 from "../../../../assets/illustrations/destination/events_img/Learn yoga in Rishikesh5.jpg"
import DestinationSubLayout from "../../../../layout/DestinationSubLayout/DestinationSub.layout"

const popularActvities = [
  {
    title: "Safari at Jim Corbett National Park",
    content: `One of the oldest wildlife sanctuaries in India, the Jim Corbett National Park is an absolute must-do activity in most Uttarakhand tourism packages. Don't miss a chance to spot the endangered Royal Bengal Tiger amongst other predators such as Leopards, Himalayan Black Bears and over 400 species of plants and 500 species of birds.`,
    src: RajajiNationalPark,
  },
  {
    title: "Rafting at Rishikesh",
    content: `One of the most sought-after things to do in Uttarakhand tourism packages is the thrilling adventure of river-rafting. Navigating the rapids of the river Ganga is a spine-tingling adventure that must be experienced once in your lifetime. Rafting can be enjoyed in Rishikesh during any season except the monsoon.`,
    src: Rafting,
  },
  {
    title: "Valley of Flowers Trek",
    content: `This rare natural garden that blooms only from July to September, which is also a UNESCO world heritage site. Surrounded by snow-clad mountains, the valley of flowers in one of the most picturesque treks in the world. Accompanied by a thrilling climb to the spiritual Hemkund Saheb this is the perfect trek for nature lovers.`,
    src: ValleyOfFlowers,
  },
]

const events = [EventImg1, EventImg2, EventImg3]

const popularPlaces = [
  {
    title: "Haridwar",
    content: `One of the most Holy sites for Hindus in India, Haridwar is a must-visit destination for those interested in history, mythology or culture. A must visit place as part of your Uttarakhand tourism package is Har ki Pauri on the banks of River Ganga, where it is claimed that the sacred river leaves the mountains and enters the plains. Every evening at sunset priests gather to perform Ganga Aarti, a mesmerizing ritual witnessed by thousands of believers.`,
    src: HaridwarImg,
  },
  {
    title: "Rishikesh",
    content: `Rishikesh is often called the adventure and yoga capital of India. Spend the day rafting in the river or stay overnight in one of the riverside camps if you're an adventure junkie. The city also boosts of a number of adventure activities such as bungee jumping, paragliding and flying fox. If eternal peace is what your mind craves, head to one of the ashrams for a complete rejuvenation as part of your tourism in Uttarakhand packages.`,
    src: RishikeshImg,
  },
  {
    title: "Mussoorie",
    content: `Located at the foothills of the Garwhal Himalayan range, Mussoorie is often aptly called The Queen of the Hills. A preferred honeymoon destination, the town of Mussoorie has been a leisure retreat since the Britishers established the first resort in 1825. A must visit destination for couples, Mussoorie is the crown of Uttarakhand tourism.`,
    src: MussoorieImg,
  },
]

const food = [FoodImg1, FoodImg2, FoodImg3]

const history = [
  { src: HaridwarImg, name: "Haridwar", link: "haridwar" },
  { src: DehradunImg, name: "Dehradun", link: "Dehradun" },
  { src: MussoorieImg, name: "Mussoorie", link: "haridwar" },
  { src: RishikeshImg, name: "Rishikesh", link: "haridwar" },
  { src: TungnathTempleImg, name: "Tungnath Temple", link: "haridwar" },
  { src: UkhimathImg, name: "Ukhimath", link: "haridwar" },
  { src: AuliImg, name: "Auli", link: "haridwar" },
  { src: NainitalImg, name: "Nainital", link: "haridwar" },
]

const DestinationTourism = () => {
  const { setBanner } = useContext(DestinationLayoutContext)

  useEffect(() => {
    setBanner("tourism")
  }, [])

  return (
    <DestinationSubLayout>
      <main className={globalStyles.main}>
        <section className={globalStyles.topMostSection}>
          <header>
            Uttarakhand <span>Tourism</span>
          </header>
          <div className={globalStyles.content}>
            From a flavour of culture to a dash of adventure,{" "}
            <b>Uttarakhand tourism</b> offers something for everyone. A truly
            diverse state in the north of India, whether you are looking to
            ignite your spiritual fire, meander in its lush green meadows or
            explore the indigenous wildlife, Uttarakhand is the perfect
            destination for you.
          </div>
        </section>
        <section className={styles.contentList}>
          <ImageAndContentCard>
            <ImageAndContentCard.Title>
              About Uttarakhand
            </ImageAndContentCard.Title>
            <div className={styles.textAndImageContainer}>
              <div className={styles.textContainer}>
                The State of Uttarakhand is often known as the Land of the Gods,
                or Devabhumi. However, the state has much to offer to nature
                lovers, adventure enthusiasts as well as leisure travellers.
                Divided into Garhwal in the west and Kumaon in east, the state
                exhibits diversity in its geography, heritage, traditions and
                culture.
              </div>
              <img src={NainitalImg} />
            </div>
          </ImageAndContentCard>

          <ImageAndContentCard>
            <ImageAndContentCard.Title>
              History Of Uttarakhand
            </ImageAndContentCard.Title>
            <ImageAndContentCard.Content>
              Legend has it that the famous sage Maharishi Vyasa composed the
              Mahabharata and the Puranas in this very state. Historically, the
              people of Uttarakhand have forged a close relationship with the
              divine as well as a close connection with nature. Apart from
              relics of Hinduism, there is also evidence of Shaivism and
              Buddhism being practiced in the 2nd Century. During the medieval
              period. the region grew distinctly into the Garhwal and Kumaon
              regions, creating their own flavours of culture, painting, cuisine
              and art. The state of Uttarakhand as we know it now was formed in
              2000 after it was segregated from Uttar Pradesh and is the 27th
              state of India. This history of the state has left a deep imprint
              on the culture of the state and has also woven itself in our
              Uttarakhand tourism packages.
            </ImageAndContentCard.Content>

            <div className={styles.historyImagesGrid}>
              {history
                .filter((_, idx) => idx < 5)
                .map((_, i) => (
                  <img src={_.src} key={i} />
                ))}
            </div>
          </ImageAndContentCard>

          <ImageAndContentCard>
            <ImageAndContentCard.Title>
              People & Culture in Destination
            </ImageAndContentCard.Title>
            <ImageAndContentCard.Content>
              As you traverse this state during a trip with Uttarakhand tourism,
              its sheer diversity and rich culture will amaze you. The people of
              State of Uttarakhand are often referred to as Paharis, or people
              from the Hills. But within these lies a diverse group from Rajput,
              Garhwali, Gujjar, Kumaoni communities and more. Apart from Hindi,
              you will spot the local dialects of Garhwali, Kumaoni and
              Jaunsari. If you look closely beyond the slate-roofed houses and
              the lush green terraced fields, you will observe a simple
              lifestyle, rich in humility and moral values. The simplistic
              nature and honest attitude of the people of Uttarakhand is one of
              the biggest reasons the state is fast becoming one of the biggest
              tourism destinations in India.
            </ImageAndContentCard.Content>
          </ImageAndContentCard>

          <ImageAndContentCard>
            <ImageAndContentCard.Title>
              Location & Climate
            </ImageAndContentCard.Title>

            <div className={styles.emptyDiv}>
              <img src={WeatherImg} />
            </div>
            <ImageAndContentCard.Content>
              Located on the southern slope of the Himalayas and the northern
              part of India, you will find that the terrain of Uttarakhand is as
              diverse as its culture. From snow and rock to alpine shrub and
              meadows, pine and deciduous forests and grassland covered
              lowlands, Uttarakhand is a nature lover's paradise. Flanked by
              Himachal Pradesh and Uttar Pradesh on one side in India and the
              countries of China and Nepal on the other, Uttarakhand is
              strategically located for domestic and international travellers.
              <p>
                The climate of Uttarakhand follows the terrain and depends on
                altitude and location and is a boon for Uttarakhand tourism. The
                hilly and mountainous regions often receive extreme snowfall in
                winters, whereas summers are pleasant and mild.  Summers are
                definitely not harsh as temperatures range between 25°C and 35°C
                and a visit to the hill stations of Uttarakhand provide the
                much-needed respite from heat to incoming tourists. During
                monsoons, the weather is cool but very humid. The hilly areas
                often receive heavy rainfalls and are prone to landslides and
                floods. Winters are chilly as temperatures range between 5°C to
                15°C. However, the temperature in winters can go below zero and
                it can get freezing cold as you move up the state. Due to this,
                few parts of the state also get inaccessible during extreme
                winters.
              </p>
            </ImageAndContentCard.Content>
          </ImageAndContentCard>

          <ImageAndContentCard>
            <ImageAndContentCard.Title>
              Food in Uttarakhand
            </ImageAndContentCard.Title>

            <ImageAndContentCard.Content>
              <div className={styles.emptyDivContainer}>
                {food.map((_, i) => (
                  <div className={styles.emptyDiv} key={i}>
                    <img src={_} />
                  </div>
                ))}
              </div>
              <br />
              Simple yet flavoursome, the cuisine of Uttarakhand reflects its
              culture. Balanced with fresh regional vegetables, local spices and
              lentils, the food in Uttarakhand is a delight to the palate. Food
              is often slowly cooked over fire to ensure it imbibes the spices
              and served with generous portions of ghee. Phanu, Kafuli,
              Thechwani, Chainsoo, Gahat ke Paranthe and Baadi rule the list of
              popular dishes from the Garhwal region. Ff you are in the Kumaon
              region make sure you sample the famous Aaloo Gutuk, Thathwani,
              Dubke, Chudkani, Jaula and Sisunaak Saag.
            </ImageAndContentCard.Content>
          </ImageAndContentCard>

          <ImageAndContentCard>
            <ImageAndContentCard.Title>
              Health and Safety in Uttarakhand
            </ImageAndContentCard.Title>

            <ImageAndContentCard.Content>
              The monsoon seasons often sees the state of Uttarakhand flooded
              and plagued by landslides. While areas close to Haridwar and
              Rishikesh in lower Himalayas are safe, regions near Gangotri and
              Badrinath are best approached with caution during the monsoon.
              While hiking through the dense forests, make sure you keep
              yourself covered and protected against mosquitoes and other insect
              bites.
            </ImageAndContentCard.Content>
          </ImageAndContentCard>

          <ImageAndContentCard>
            <ImageAndContentCard.Title>
              Popular Places in Uttarakhand
            </ImageAndContentCard.Title>

            <ImageAndContentCard.Content>
              Whether you're looking to get away from the city life for an
              adventurous weekend or a long leisure trip with the family, an
              Uttarakhand trip has something for everyone. While the state
              boosts of a number of hill stations, pilgrimage sites and leisure
              destinations, here are a few popular places to visit in
              Uttarakhand:
              <div className={styles.inlineImageAndText}>
                {popularPlaces.map((activity, idx) => (
                  <InlineImageAndContentCard key={idx}>
                    <InlineImageAndContentCard.Image
                      src={activity.src}
                      size="small"
                    />
                    <InlineImageAndContentCard.Content
                      title={`${idx + 1}. ${activity.title}`}
                    >
                      {activity.content}
                    </InlineImageAndContentCard.Content>
                  </InlineImageAndContentCard>
                ))}
              </div>
            </ImageAndContentCard.Content>
          </ImageAndContentCard>
 
          <ImageAndContentCard>
            <ImageAndContentCard.Title>
              Popular Activities & Things to Do in Uttarakhand
            </ImageAndContentCard.Title>

            <ImageAndContentCard.Content>
              Surrounded by magnificent views of Himalayas, Uttarakhand is a
              unique mix of nature and wildlife, spiritual mysticism and
              adventure. Each town, city and hill station has its unique
              offerings and there are plenty of things to do in Uttarakhand, a
              few being:
              <div className={styles.inlineImageAndText}>
                {popularActvities.map((activity, idx) => (
                  <InlineImageAndContentCard>
                    <InlineImageAndContentCard.Image
                      src={activity.src}
                      size="small"
                    />
                    <InlineImageAndContentCard.Content
                      title={`${idx + 1}. ${activity.title}`}
                    >
                      {activity.content}
                    </InlineImageAndContentCard.Content>
                  </InlineImageAndContentCard>
                ))}
              </div>
            </ImageAndContentCard.Content>
          </ImageAndContentCard>

          <ImageAndContentCard>
            <ImageAndContentCard.Title>
              Events & Festivals in Uttarakhand
            </ImageAndContentCard.Title>
            <ImageAndContentCard.Content>
              <div className={styles.emptyDivContainer}>
                {events.map((_, i) => (
                  <div className={styles.emptyDiv} key={i}>
                    <img src={_} />
                  </div>
                ))}
              </div>
              <br />
              Given its rich history heritage and culture, Uttarakhand is
              definitely a land of festivals that you must attend if you are
              planning an Uttarakhand tourism package.
              <p>
                {" "}
                The Nanda Devi Mela in Uttarakhand which is held in the month of
                September in Almora is a must visit, if you would like to see a
                glimpse of Uttarakhand’s folk dances, music as well as rituals.
                Festivals celebrated at the banks of river Ganga such as the
                Kumbh Mela which comes once in every 12 years to Haridwar, the
                Ganga Dussehra which comes around May, in the Hindu month of
                Jyaistha,  and the Kanwar yatra that is celebrated in the month
                Hindu month of Shravan around July, involve devotees taking holy
                dips in the river Ganga to wash away their sins.
              </p>{" "}
              If you are on a religious pilgrimage, then you must plan your trip
              during these times to absorb the mystic vibes of these festivals.
              The International Yoga Festival held yearly in Rishikesh in the
              month of March is a must attend for everybody who is spiritually
              inclined and inquisitive or passionate about yoga. This festival
              is one of the biggest highlights for tourism in Uttarakhand.
            </ImageAndContentCard.Content>
          </ImageAndContentCard>

          <ImageAndContentCard>
            <ImageAndContentCard.Title>
              Currency and Foreign Exchange
            </ImageAndContentCard.Title>

            <ImageAndContentCard.Content>
              The currency used in Uttarakhand is the Indian rupee.  Most hotels
              and establishments in Hill stations accept digital payments and
              you should not have a problem carrying credit or debit cards.
              However, if you would like to go street shopping or bargain with
              local vendors, then it is recommended to carry a certain amount of
              cash on you at all times. Further, ATMs may also be difficult to
              find in extreme interiors of the state and at high altitudes.
              Therefore, if you are visiting the interiors of the forests or
              trekking across hilly terrain, it would be pertinent to carry
              sufficient cash for your trip.
            </ImageAndContentCard.Content>
          </ImageAndContentCard>

          <ImageAndContentCard>
            <ImageAndContentCard.Title>
              Travelling With Family
            </ImageAndContentCard.Title>
            <ImageAndContentCard.Content>
              <div className={styles.emptyDiv}>
                <img src={BadrinathImg} />
              </div>
              <br />
              If you are traveling with family, then Uttarakhand is probably the
              perfect destination for you. Uttarakhand Tourism packages cater to
              the complete family needs. You have an assortment of religious
              pilgrimage, leisure destinations and adventure activities to
              choose from.  Elders in the family can really enjoy the Ganga
              Aarti at Rishikesh and Haridwar and hold deeply intellectual
              conversations with sages who have been meditating here since time
              immemorial.{" "}
              <p>
                The youngsters enjoy the bungee jumping and rafting activities
                that are abundantly available. Activities such as Jungle safaris
                and Camping are for the entire family and a great way to bond
                with each other and take a break from the mundane city life.
                Treks such as Char Dham yatra also are recommended for the
                entire family as the youngsters enjoy the challenge, the
                picturesque views and gain a new perspective to the Indian
                culture and history.
              </p>
            </ImageAndContentCard.Content>
          </ImageAndContentCard>

          <ImageAndContentCard>
            <ImageAndContentCard.Title>
              Travelling With Your Partner
            </ImageAndContentCard.Title>

            <ImageAndContentCard.Content>
              If you are travelling with your partner then Uttarakhand tourism
              provides you abundant opportunities with its romantic hill
              stations such as Mussoorie, Nainital, Ranikhet and so on.
              Mussoorie is one of the most popular honeymoon destinations in
              India. Boating in Nainital and watching the sunset points in
              different hill stations are memories that will stay with you
              forever. The Uttarakhand tourism packages also provide a lot of
              treks such as Nag Tibba Har ki Doon and of course the more
              difficult once such as the Valley of flowers treks which you can
              attempt with your loved one for the experience of a lifetime.
            </ImageAndContentCard.Content>
          </ImageAndContentCard>
        </section>

        <BiggerGroupBannerPost />
      </main>
    </DestinationSubLayout>
  )
}

export default DestinationTourism
