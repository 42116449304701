/**assests import */
import { format } from "date-fns"
import IslandIllustration from "../../assets/illustrations/island_illustration.png"

import "./Home.styles.css"
import { Link, NavLink } from "react-router-dom"
import { useContext, useEffect, useRef, useState } from "react"

import MostPopularHotels from "../../components/MostPopularHotels/MostPopularHotels.component"
import MostPopularPackages from "../../components/MostPopularPackages/MostPopularPackages.component"
import AboutUsIllustration from "../../assets/illustrations/about_us_illustration.png"
import HotelCard from "../../components/HotelCard/HotelCard.component"
import BestExperiences from "../../components/BestExperience/BestExperience.component"
import KeyPointCard from "../../components/KeyPointCard/KeyPointCard.component"
import KeyPoints from "../../components/KeyPoints/KeyPoints.component"

import Group from "../../assets/illustrations/ourBlog/Group.png"
import Image1 from "../../assets/illustrations/ourBlog/Rectangle 4389.png"
import Image2 from "../../assets/illustrations/ourBlog/Rectangle 4391.png"
import Image3 from "../../assets/illustrations/ourBlog/Rectangle 4392.png"
import Image4 from "../../assets/illustrations/ourBlog/Rectangle 4394.png"
import Image5 from "../../assets/illustrations/ourBlog/Rectangle 4395.png"

import OfferSectionImage from "../../assets/illustrations/offer_section_img.png"

import OfferBanner from "../../assets/illustrations/offer_banner_background.png"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { ContactModalContext } from "../../context/contactModal.context"

const illustrationCollection = [Group, Image1, Image2, Image3, Image4, Image5]

const blogData = illustrationCollection.map((v) => ({ imgPath: v }))

const Home = () => {
  const [searchOption, setSearchOption] = useState("Hotels")
  const [isIntersecting, setIsIntersecting] = useState(true)
  const history = useHistory()

  const { toggleModal } = useContext(ContactModalContext)

  // const offerTagRef = useRef(null)

  // useEffect(() => {
  //   function callBack(entries) {
  //     entries.forEach((entry) => {
  //       setIsIntersecting(entry.isIntersecting)
  //     })
  //   }
  //   const options = {
  //     threshold: 1,
  //   }
  //   const observer = new IntersectionObserver(callBack, options)

  //   const element = offerTagRef.current

  //   console.log(element)

  //   observer.observe(element)

  //   if (isIntersecting) {
  //     element.classList.add("show-offer")
  //     console.log("inside")
  //   }

  //   return () => {
  //     element.classList.remove("show-offer")
  //     observer.disconnect()
  //   }
  // }, [isIntersecting])
  return (
    <>
      <section className="welcome-text-island-illustrator-container">
        <img className="island-illustration" src={IslandIllustration} />
        <aside className="welcome-message-container">
          <div className="welcome-primary-text">
            <span className="where-text">
              Where You <span>Headed ?</span>
            </span>
          </div>
          <p>Holiday like a local with Towno</p>
          <div className="search-option">
            <button
              className={`hotel-btn ${
                searchOption === "Hotels" ? "active-btn" : ""
              }`}
              onClick={() => setSearchOption("Hotels")}
            >
              Hotels
            </button>
            <button
              className={`package-btn ${
                searchOption === "Packages" ? "active-btn" : ""
              }`}
              onClick={() => setSearchOption("Packages")}
            >
              Packages
            </button>
          </div>
          <div className="search-fields">
            <input placeholder="Where are you going?" />
            <input placeholder="When do you want to go?" />
            <input placeholder="How many people?" />
            <button onClick={toggleModal}>Search</button>
          </div>
        </aside>
      </section>

      {/** Most Popular Packages Home Page starts*/}
      <MostPopularPackages />
      {/** Most Popular Packages Home Page ends*/}

      {/**best experiences starts */}
      <BestExperiences />
      {/**best experience ends */}

      {/**Offer Banner Starts*/}
      <section className="offer-banner-container">
        <img className="offer-banner-background-img" src={OfferBanner} />
        <div className="offer-body-container">
          <>
            <div className="banner-primary-text">
              Bigger Group? Get Special
              <p>
                Offers upto <span className="home-valid-off">50% Off!</span>
              </p>
              <p className="banner-secondary-text">
                Towno holiday packages weave in a generous amount of authentic
                and offbeat travel experiences and stays in our Towno partner
                network.
              </p>
            </div>
            <button className="offer-btn" onClick={toggleModal}>
              Get A Callback
            </button>
          </>
        </div>
      </section>
      {/**Offer Banner ends*/}

      {/**About info starts*/}
      <section className="about-towno">
        <div className="about-text-container">
          <div className="about-header">About Us</div>
          <div className="about-us-primary-text">
            Book An <span className="boldtext">Experiential Holiday</span> With
            Us.
          </div>
          <div className="about-us-secondary-text">
            Towno holiday packages weave in a generous amount of authentic and
            offbeat travel experiences and stays in our Towno partner network.
          </div>
          <button className="contact-us" onClick={toggleModal}>
            Contact Us
          </button>
        </div>
        <img src={OfferSectionImage} className="explore-illustration" />
      </section>
      {/**About info ends*/}

      {/** Most Popular Hotels Home Page Starts*/}
      <MostPopularHotels />
      {/** Most Popular Hotels Home Page ends*/}

      {/**Keypoints section starts */}
      <KeyPoints />
      {/**KeyPoints section ends */}

      {/**Blog section starts */}
      {/* <section className="home-blog-section">
        <header className="blog-title">
          <div>
            These Are Our <span> Blogs</span>
          </div>
          <sub className="blog-sub-title">
            These Are The Best Destinations Offered These Are The Best
            Destinations
          </sub>
        </header>
        <div className="home-blog-container"></div>
      </section> */}
      {/**Blog section ends */}
    </>
  )
}

export default Home
