import ContactCard from "../../components/ContactCard/ContactCard.component"
import styles from "./Contact.module.css"

import QueryForm from "../../components/QueryForm/QueryForm.component"
import ContactInfo from "../../components/ContactInfo/ContactInfo.component"

const ContactPage = () => {
  return (
    <section className={styles.container}>
      <header className={styles.header}>Contact Us</header>
      <main className={styles.main}>
        <aside className={styles.contactContainer}>
          <ContactCard />
        </aside>
        <aside className={styles.aside}>
          <ContactInfo />
          <QueryForm />
        </aside>
      </main>
    </section>
  )
}

export default ContactPage
