import {
  makeStyles,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core"
import { Grid } from "@mui/material"
import { format } from "date-fns"

const useStyles = makeStyles((theme) => ({
  cellBase: {
    borderLeft: "1px solid #000",
    textAlign: "center",
  },
  label: {
    fontWeight: "bold",
    fontSize: "0.7rem",
    padding: theme.spacing(0.3),
  },
  value: {
    fontSize: "0.7rem",
    padding: theme.spacing(0.3),
  },
}))

const GenerateInvoiceTableComponent = (props) => {
  const {
    billingInputValues,
    totalTaxableAmount,
    totalTaxAmount,
    totalAmountIncGst,
    billedToDetails,
    billedByDetails,
    invoiceId,
    bookingID,
    trainDetails,
    flightDetails,
    busDetails,
    modeOfTransport,
    hotelName,
    packageName,
    leadTravellersName,
    activityDetails,
    dateOfIssue,
    destinationFrom,
    destinationTo,
  } = props
  const classes = useStyles()
  function travelModes() {
    const travelCategory = new Map()

    if (flightDetails[0]?.name) {
      travelCategory.set("Flight", "flight")
    }

    if (trainDetails[0]?.name) {
      travelCategory.set("Train", "train")
    }

    if (busDetails[0]?.name) {
      travelCategory.set("Bus", "bus")
    }

    return [...travelCategory.keys()].join(",")
  }

  return (
    <Grid container md={12} padding={1}>
      <Grid container item border={1} md={12}>
        <Grid item container md={12}>
          <Grid
            item
            md={2}
            borderRight={1}
            textAlign="center"
            fontSize="2em"
            fontWeight="bold"
            alignContent="center"
          >
            town
            <span
              style={{
                color: "#f46d25",
              }}
            >
              o
            </span>
          </Grid>
          <Grid item container md={10}>
            <Grid
              item
              md={12}
              textAlign="center"
              color="red"
              fontWeight="bold"
              fontSize="1.2em"
              alignContent="center"
            >
              GST Invoice
            </Grid>
            <Grid item container md={12} borderTop={1}>
              <Grid item md={12} textAlign="end" className={classes.value}>
                Original
              </Grid>
              <Grid item container md={12} borderTop={1}>
                <Grid item container md={4.5} borderRight={1}>
                  <Grid
                    item
                    md={12}
                    className={classes.label}
                    style={{ color: "red" }}
                  >
                    Intellego Tech Solutions Private Limited
                  </Grid>
                  <Grid item md={12} className={classes.value} borderTop={1}>
                    1, NEHRU ENCLAVE GMS ROAD
                  </Grid>
                  <Grid item md={12} className={classes.value} borderTop={1}>
                    DEHRADUN
                  </Grid>
                  <Grid item md={12} className={classes.value} borderTop={1}>
                    UTTARAKHAND
                  </Grid>
                  <Grid item md={12} className={classes.value} borderTop={1}>
                    9177911101, hello@towno.in
                  </Grid>
                </Grid>
                <Grid item container md={0.5} borderRight={1}>
                  <Grid
                    item
                    md={12}
                    className={classes.label}
                    style={{ color: "red" }}
                  ></Grid>
                  <Grid
                    item
                    md={12}
                    className={classes.value}
                    borderTop={1}
                  ></Grid>
                  <Grid
                    item
                    md={12}
                    className={classes.value}
                    borderTop={1}
                  ></Grid>
                  <Grid
                    item
                    md={12}
                    className={classes.value}
                    borderTop={1}
                  ></Grid>
                  <Grid
                    item
                    md={12}
                    className={classes.value}
                    borderTop={1}
                  ></Grid>
                </Grid>
                <Grid item container md={1} borderRight={1}>
                  <Grid
                    item
                    md={12}
                    className={classes.label}
                    style={{ color: "red" }}
                    textAlign="center"
                  >
                    -
                  </Grid>
                  <Grid
                    item
                    md={12}
                    textAlign="center"
                    className={classes.value}
                    borderTop={1}
                  >
                    -
                  </Grid>
                  <Grid
                    item
                    md={12}
                    textAlign="center"
                    className={classes.label}
                    borderTop={1}
                  >
                    GSTIN
                  </Grid>
                  <Grid
                    item
                    md={12}
                    textAlign="center"
                    className={classes.label}
                    borderTop={1}
                  >
                    State
                  </Grid>
                  <Grid
                    item
                    md={12}
                    textAlign="center"
                    className={classes.label}
                    borderTop={1}
                  >
                    PAN
                  </Grid>
                </Grid>
                <Grid item container md={2} borderRight={1}>
                  <Grid
                    item
                    md={12}
                    className={classes.value}
                    style={{ color: "red" }}
                    textAlign="center"
                  >
                    -
                  </Grid>
                  <Grid
                    item
                    md={12}
                    textAlign="center"
                    className={classes.value}
                    borderTop={1}
                  >
                    -
                  </Grid>
                  <Grid
                    item
                    md={12}
                    textAlign="center"
                    className={classes.value}
                    borderTop={1}
                  >
                    05AAECI1205Q1ZM
                  </Grid>
                  <Grid
                    item
                    md={12}
                    textAlign="center"
                    className={classes.value}
                    borderTop={1}
                  >
                    UTTARAKHAND
                  </Grid>
                  <Grid
                    item
                    md={12}
                    textAlign="center"
                    className={classes.value}
                    borderTop={1}
                  >
                    AAECI1205Q
                  </Grid>
                </Grid>
                <Grid item container md={1.7} borderRight={1}>
                  <Grid
                    item
                    md={12}
                    className={classes.label}
                    style={{ color: "red" }}
                    textAlign="center"
                  >
                    -
                  </Grid>
                  <Grid
                    item
                    md={12}
                    textAlign="center"
                    className={classes.label}
                    borderTop={1}
                  >
                    -
                  </Grid>
                  <Grid
                    item
                    md={12}
                    textAlign="center"
                    className={classes.label}
                    borderTop={1}
                  >
                    Invoice Date
                  </Grid>
                  <Grid
                    item
                    md={12}
                    textAlign="center"
                    className={classes.label}
                    borderTop={1}
                  >
                    Invoice No.
                  </Grid>
                  <Grid
                    item
                    md={12}
                    textAlign="center"
                    className={classes.label}
                    borderTop={1}
                  >
                    Reference No.
                  </Grid>
                </Grid>
                <Grid item container md={2.3}>
                  <Grid
                    item
                    md={12}
                    className={classes.value}
                    textAlign="center"
                  >
                    For Recipient
                  </Grid>
                  <Grid
                    item
                    md={12}
                    textAlign="center"
                    className={classes.label}
                    borderTop={1}
                  >
                    -
                  </Grid>
                  <Grid
                    item
                    md={12}
                    textAlign="center"
                    className={classes.value}
                    borderTop={1}
                  >
                    {format(
                      new Date(billedByDetails?.billingDate),
                      "dd/MM/yyyy"
                    )}
                  </Grid>
                  <Grid
                    item
                    md={12}
                    textAlign="center"
                    className={classes.value}
                    borderTop={1}
                  >
                    {invoiceId ? invoiceId : "-"}
                  </Grid>
                  <Grid
                    item
                    md={12}
                    textAlign="center"
                    className={classes.value}
                    borderTop={1}
                  >
                    {bookingID ? bookingID : "-"}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container md={12}>
          <Grid item md={12} borderBottom={1} borderTop={1} height={20}></Grid>
          <Grid item container md={12}>
            <Grid
              item
              md={5}
              container
              rowGap={1}
              borderBottom={1}
              borderRight={1}
            >
              <Grid item container md={5} borderRight={1}>
                <Grid item md={12} className={classes.label}>
                  Customer Name
                </Grid>
                <Grid item md={12} className={classes.label} borderTop={1}>
                  Customer GSTIN
                </Grid>
                <Grid item md={12} className={classes.label} borderTop={1}>
                  Place of Supply
                </Grid>
              </Grid>
              <Grid item container md={7} textAlign="center">
                <Grid item md={12} className={classes.value}>
                  {billedToDetails?.clientName}
                </Grid>
                <Grid
                  item
                  md={12}
                  className={classes.value}
                  textAlign="center"
                  borderTop={1}
                >
                  {billedToDetails?.gstNo}
                </Grid>
                <Grid
                  item
                  md={12}
                  className={classes.value}
                  textAlign="center"
                  borderTop={1}
                >
                  -
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid container item md={5} borderLeft={1} borderBottom={1}>
              <Grid item container md={5}>
                <Grid item className={classes.label} md={12}>
                  Billing Address
                </Grid>
                <Grid item className={classes.label} md={12} borderTop={1}>
                  Shipping Address
                </Grid>
                <Grid item className={classes.label} md={12} borderTop={1}>
                  Due Date
                </Grid>
              </Grid>
              <Grid item container md={7} borderLeft={1}>
                <Grid item className={classes.value} md={12}>
                  {billedToDetails?.billingAddress}
                </Grid>
                <Grid item className={classes.value} md={12} borderTop={1}>
                  {billedByDetails.shippingAddress}
                </Grid>
                <Grid item className={classes.value} md={12} borderTop={1}>
                  {format(new Date(billedByDetails.dueDate), "do MMM, yyyy")}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container md={12} marginTop={4}>
            <Grid item container md={5}>
              <Grid
                item
                md={12}
                textAlign="center"
                borderTop="1px solid #000"
                borderRight="1px solid #000"
                borderBottom="1px solid #000"
                fontWeight="bold"
                padding={1}
                className={classes.label}
              >
                Travel Details
              </Grid>
              <Grid md={12} item>
                {[
                  {
                    label: "Booking Date",
                    value: format(
                      new Date(billedByDetails?.billingDate),
                      "dd/MM/yyyy"
                    ),
                  },
                  { label: "Booking Id", value: bookingID ?? "" },
                  { label: "Destination City", value: "" },
                  {
                    label: "Lead Travellers Name",
                    value: leadTravellersName[0]?.name,
                  },
                  {
                    label: "Date of Issue",
                    value: format(new Date(dateOfIssue), "dd/MM/yyyy"),
                  },
                  { label: "Destination From", value: destinationFrom },
                  { label: "Destination To", value: destinationTo },
                  { label: "Category", value: travelModes() },
                  travelModes()?.includes("Flight") && {
                    label: "Flight No.",
                    value: flightDetails[0]?.name,
                  },
                  travelModes()?.includes("Train") && {
                    label: "Train Details",
                    value: trainDetails[0]?.name,
                  },
                  travelModes()?.includes("Bus") && {
                    label: "Bus",
                    value: busDetails[0]?.name,
                  },
                ].map(
                  (r, i) =>
                    r !== false && (
                      <Grid
                        item
                        container
                        md={12}
                        key={`${i}-${r.label}`}
                        borderRight={1}
                      >
                        <Grid
                          item
                          md={5}
                          style={{
                            fontWeight: "bold",
                            borderBottom: "1px solid #000",
                          }}
                          className={classes.label}
                        >
                          {r.label}
                        </Grid>
                        <Grid
                          style={{
                            borderLeft: "1px solid #000",
                            borderBottom: "1px solid #000",
                            wordWrap: "break-word",
                            textAlign: "center",
                          }}
                          md={7}
                          className={classes.value}
                        >
                          {r.value}
                        </Grid>
                      </Grid>
                    )
                )}
              </Grid>
            </Grid>
            <Grid item md={7}></Grid>
          </Grid>
          <Grid item container md={12} marginTop={5}>
            <Grid
              style={{
                borderTop: "1px solid #000",
                borderBottom: "1px solid #000",
              }}
              md={12}
            >
              <Grid item container md={12} style={{ background: "yellow" }}>
                {[
                  "Item",
                  "HSN/SAC",
                  "Quantity",
                  "Rate/Item (₹)",
                  "Discount (₹)",
                  "Taxable Value (₹)",
                  "IGST (₹)",
                  "CESS (₹)",
                  "Total (₹)",
                ].map((r, idx) => (
                  <Grid
                    item
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      wordWrap: "break-word",
                      borderLeft: idx != 0 && "1px solid #000",
                      borderBottom: "1px solid #000",
                    }}
                    className={classes.label}
                    md={12 / 9}
                  >
                    {r}
                  </Grid>
                ))}
              </Grid>
              <Grid item container md={12}>
                {billingInputValues.map((r, idx) => (
                  <Grid item container md={12}>
                    <Grid
                      item
                      md={12 / 9}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid #000",
                      }}
                      className={classes.value}
                    >
                      {r.bookingItem}
                    </Grid>
                    <Grid
                      item
                      md={12 / 9}
                      style={{
                        textAlign: "center",
                        border: "1px solid #000",
                      }}
                      className={classes.value}
                    >
                      {r.code}
                    </Grid>
                    <Grid
                      item
                      md={12 / 9}
                      style={{
                        textAlign: "center",
                        border: "1px solid #000",
                      }}
                      className={classes.value}
                    >
                      {r.quantity}
                    </Grid>
                    <Grid
                      item
                      md={12 / 9}
                      style={{
                        textAlign: "center",
                        border: "1px solid #000",
                      }}
                      className={classes.value}
                    >
                      {r.rate}
                    </Grid>
                    <Grid
                      item
                      md={12 / 9}
                      style={{
                        textAlign: "center",
                        border: "1px solid #000",
                      }}
                      className={classes.value}
                    ></Grid>
                    <Grid
                      item
                      md={12 / 9}
                      style={{
                        textAlign: "center",
                        border: "1px solid #000",
                      }}
                      className={classes.value}
                    >
                      {r.taxableAmt}
                    </Grid>
                    <Grid
                      item
                      md={12 / 9}
                      style={{
                        textAlign: "center",
                        border: "1px solid #000",
                      }}
                      className={classes.value}
                    >
                      {r.igst}
                    </Grid>
                    <Grid
                      item
                      md={12 / 9}
                      style={{
                        textAlign: "center",
                        border: "1px solid #000",
                      }}
                    ></Grid>
                    <Grid
                      item
                      md={12 / 9}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid #000",
                      }}
                      className={classes.value}
                    >
                      {r.taxableAmt}
                    </Grid>
                  </Grid>
                ))}
                <Grid item md={12} style={{ height: "2em" }}></Grid>
                <Grid
                  item
                  container
                  md={12}
                  style={{
                    borderTop: "1px solid #000",
                    background: "yellow",
                  }}
                >
                  {[
                    "",
                    "",
                    "",
                    billingInputValues?.reduce(
                      (curr, prev) => (curr += parseInt(prev.rate)),
                      0
                    ),
                    "",
                    totalTaxableAmount,
                    billingInputValues?.reduce(
                      (curr, prev) => (curr += parseInt(prev.igst)),
                      0
                    ),
                    "",
                    totalTaxableAmount,
                  ].map((v, idx) => (
                    <Grid
                      item
                      md={12 / 9}
                      className={classes.label}
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid #000",
                        borderLeft: idx != 0 && "1px solid #000",
                        fontWeight: "bold",
                      }}
                    >
                      {v}
                    </Grid>
                  ))}
                </Grid>
                <Grid item container md={12}>
                  {[
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    " ",
                    "Taxable Amount",
                    totalTaxableAmount,
                  ].map((r, i) => (
                    <Grid
                      item
                      md={12 / 9}
                      style={{
                        textAlign: "center",
                        border: i >= 7 && "1px solid #000",
                        fontWeight: i == 7 && "bold",
                        fontSize: "0.8rem",
                      }}
                      className={classes.label}
                    >
                      {r}
                    </Grid>
                  ))}
                </Grid>
                <Grid item container md={12}>
                  {[
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "Taxable Tax",
                    totalTaxAmount,
                  ].map((r, i) => (
                    <Grid
                      style={{
                        textAlign: "center",
                        border: i >= 7 && "1px solid #000",
                        fontWeight: i == 7 && "bold",
                        fontSize: "0.8rem",
                      }}
                      item
                      md={12 / 9}
                    >
                      {r}
                    </Grid>
                  ))}
                </Grid>
                <Grid item container md={12}>
                  {[
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "Invoice Total",
                    totalAmountIncGst,
                  ].map((r, i) => (
                    <Grid
                      style={{
                        border: i >= 7 && "1px solid #000",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                        fontWeight: i >= 7 && "bold",
                        fontSize: "0.8rem",
                      }}
                      item
                      md={12 / 9}
                    >
                      {r}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container md={12} marginTop={5}>
            <Grid item container md={5}>
              <Grid
                md={12}
                borderRight="1px solid #000"
                borderTop="1px solid #000"
                textAlign="center"
                fontWeight="bold"
                padding={0.5}
                className={classes.label}
              >
                Bank Details
              </Grid>
              <Grid item md={12} container>
                {[
                  { label: "Account Number", value: 50200019883680 },
                  { label: "IFSC", value: "HDFC0001399" },
                  { label: "Bank Name", value: "HDFC Bank" },
                  { label: "Branch Name", value: "BALLUPUR CHOWK, DEHRADUN" },
                ].map((v, i) => (
                  <Grid item container md={12}>
                    <Grid
                      item
                      md={5}
                      style={{
                        wordWrap: "break-word",
                      }}
                      className={classes.label}
                      borderTop={1}
                      borderBottom={i === 3 && 1}
                      borderRight={1}
                    >
                      {v.label}
                    </Grid>
                    <Grid
                      md={7}
                      className={classes.value}
                      item
                      style={{
                        wordWrap: "break-word",
                      }}
                      borderTop={1}
                      borderBottom={i === 3 && 1}
                      borderRight={1}
                    >
                      {v.value}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid
                item
                md={12}
                marginTop={3}
                border="1px solid #000"
                textAlign="center"
                fontWeight="bold"
                padding={0.7}
                borderLeft="none"
                borderBottom="none"
                className={classes.label}
              >
                Terms & Conditions
              </Grid>
            </Grid>
            <Grid item md={2}></Grid>
            <Grid
              item
              container
              md={5}
              border="1px solid #000"
              borderBottom="none"
              borderRight="none"
            >
              <Grid
                item
                md={12}
                style={{ wordWrap: "break-word" }}
                textAlign="center"
                alignContent="center"
                padding={1}
                height="max-content"
                borderBottom="1px solid #000"
                className={classes.label}
              >
                For Intellego Tech Solutions Private Limited,
                <p style={{ padding: 0, margin: 0 }}> Authorised Signatory</p>
              </Grid>
              <Grid item md={12}></Grid>
            </Grid>
          </Grid>
          <Grid item container md={12}>
            {[
              "Towno is the official brand for Intellego Tech Solutions Pvt Ltd.",
              "For queries, write to support@towno.in",
              "Terms & Conditions as mentioned on the Voucher Apply.",
            ].map((r) => (
              <Grid
                item
                md={12}
                className={classes.value}
                style={{ width: "100%" }}
                borderTop={1}
              >
                {r}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default GenerateInvoiceTableComponent
