import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import Api from "../../../Service/Api";
import FormikInlineCheckboxAndSwitchOnly from "../../../components/amenityComponents/inlineCheckBoxAndSwitchOnly.component";
import InlineFormikFieldWithCheckSelectAndSwitch from "../../../components/amenityComponents/inlineCheckBoxWithSelectAndSwitch.component";
import ScreenLoader from "../../../components/loadingScreen/screenLoader.component";
import { restaurantInitial } from "../../../redux/actions/amenitiesDropDownAction";

const initialValues = {
  bar: false,
  barStarred: false,
  barbeque: false,
  barbequeStarred: false,
  cafe: false,
  cafeType: "",
  cafeStarred: false,
  diningArea: false,
  diningAreaType: "",
  diningAreaStarred: false,
  kidsmeals: false,
  kidsmealStarred: false,
  restaurant: false,
  restaurantType: [],
  restaurantStarred: false,
  petFoods: false,
  petFoodStarred: false,
};

const foodAndDrinksWithSwitch = [
  {
    name: "bar",
    label: "Bar",
    switchName: "barStarred",
  },
  {
    name: "barbeque",
    label: "Barbeque",
    switchName: "barbequeStarred",
  },
  {
    name: "kidsmeals",
    label: "Kids Meal",
    switchName: "kidsmealsStarred",
  },
  {
    name: "petFoods",
    label: "Pet Food",
    switchName: "petFoodsStarred",
  },
];

const FoodAndDrinks = ({
  classes,
  handleChangePanel,
  expanded,
  setExpanded,
}) => {
  const { propertyId } = useParams();
  const [foodAndDrinks, setFoodDrinks] = useState(() => {});
  const restaurantLists = useSelector(
    (state) => state.restaurantList.restaurantLists
  );
  const data = { propertyId: propertyId };
  const uniqueid = localStorage.getItem("unique_id");
  const createdBy = localStorage.getItem("auth");
  const create = { uniqueId: uniqueid, createdBy: createdBy };
  const [loading, setLoading] = useState(() => false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChangeAccordion = () => {
    setExpanded("panel5");
  };

  const fetchFoodAndDrinks = async () => {
    setLoading(true);
    try {
      const normalData = await Api.get(
        `AmenitiesFoodAndDrinksvalue/${propertyId}`
      );
      const switchData = await Api.get(
        `AmenitiesFoodAndDrinksStarredvalue/${propertyId}`
      );
      console.log(normalData.data);
      console.log(switchData.data);
      setFoodDrinks({ ...normalData.data, ...switchData.data });
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (propertyId && expanded == "panel4") {
      fetchFoodAndDrinks();
    }
  }, [expanded]);

  useEffect(() => {
    if (propertyId && expanded == "panel4") dispatch(restaurantInitial());
  }, [expanded]);

  const foodAndDrinksWithSelect = [
    {
      name: "cafe",
      label: "Cafe",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "cafeType",
      switchName: "cafeStarred",
      dropdDownOptions: [
        { value: "24hrs", label: "24*7" },
        { value: "limited", label: "Limited" },
      ],
    },
    {
      name: "diningArea",
      label: "Dining Area",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "diningAreaType",
      switchName: "diningAreaStarred",
      dropdDownOptions: [
        { value: "24hrs", label: "24*7" },
        { value: "limited", label: "Limited" },
      ],
    },
    {
      name: "restaurant",
      label: "Restaurant",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "restaurantType",
      switchName: "restaurantStarred",
      dropdDownOptions: restaurantLists,
    },
  ];

  function onSubmit(fields, { setStatus }) {
    const id = foodAndDrinks.id;
    setStatus();

    if (fields.bar == false) {
      fields.barStarred = false;
    }
    if (fields.barbeque == false) {
      fields.barbequeStarred = false;
    }
    if (fields.cafe == false) {
      fields.cafeType = "";
      fields.cafeStarred = false;
    }
    if (fields.diningArea == false) {
      fields.diningAreaType = "";
      fields.diningAreaStarred = false;
    }
    if (fields.kidsmeals == false) {
      fields.kidsmealStarred = false;
    }
    if (fields.restaurant == false) {
      fields.restaurantType = [];
      fields.restaurantStarred = false;
    }
    if (fields.petFoods == false) {
      fields.petFoodStarred = false;
    }

    if (id) {
      updateFood(fields);
    } else {
      createFood(fields);
    }
  }

  function createStarred(fields) {
    fields.propertyId = propertyId;
    const newData = { ...fields, ...create };
    Api.post("AmenitiesFoodAndDrinksStarred", newData);
  }
  function updateStarred(fields) {
    fields.updateBy = createdBy;
    Api.put("AmenitiesFoodAndDrinksStarredupdate", fields);
  }

  function createFood(fields) {
    setLoading(true);
    fields.propertyId = propertyId;
    const newData = { ...data, ...fields, ...create };
    Api.post("AmenitiesFoodAndDrinks", newData).then((res) => {
      if (res.status === 200) {
        createStarred(fields);
        setLoading(false);
        toast.success("Successfully Created");
        handleChangeAccordion();
      }
    });
  }

  function updateFood(fields) {
    setLoading(true);
    fields.updateBy = createdBy;
    Api.put("AmenitiesFoodAndDrinksupdate", fields).then((res) => {
      if (res.status === 200) {
        updateStarred(fields);
        toast.success("Successfully Updated");
        handleChangeAccordion();
        setLoading(false);
      }
    });
  }

  return (
    <Formik
      initialValues={foodAndDrinks || initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue }) =>
        loading ? (
          <ScreenLoader open={loading} />
        ) : (
          <Form autoComplete="off">
            <Accordion
              square
              expanded={expanded === "panel4"}
              onChange={handleChangePanel("panel4")}
              className={classes.accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#f46d25",
                      background: "white",
                      borderRadius: "5px",
                    }}
                  />
                }
                className={classes.accordionSummaryStyles}
              >
                Food And Drinks
              </AccordionSummary>
              <AccordionDetails className={classes.outerSectionStyles}>
                <Grid container spacing={3}>
                  {foodAndDrinksWithSelect.map((amenity) => (
                    <InlineFormikFieldWithCheckSelectAndSwitch
                      amenity={amenity}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ))}

                  {/**Food and Drinks amenities switch only */}
                  {foodAndDrinksWithSwitch.map((amenity) => (
                    <FormikInlineCheckboxAndSwitchOnly
                      amenity={amenity}
                      values={values}
                    />
                  ))}
                </Grid>
              </AccordionDetails>
              <center>
                <Button
                  style={{ height: "30px", margin: "10px 0px" }}
                  type="submit"
                >
                  Save and Next
                </Button>
              </center>
            </Accordion>
          </Form>
        )
      }
    </Formik>
  );
};

export default FoodAndDrinks;
