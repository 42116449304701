import "./PackageCard.styles.css"
import PopularPackageMockImage from "../../assets/illustrations/mockImages/Package_Illustration.jpg"
import { currencyFormatter } from "../../utility/currencyFormatter.js"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js"
import { ContactModalContext } from "../../context/contactModal.context.jsx"
import { useContext } from "react"

const PackageCard = ({ name, content, price, imgPath }) => {
  const history = useHistory()
  const { toggleModal } = useContext(ContactModalContext)
  return (
    <div className="popular-packages-card">
      <img src={imgPath} />

      <div className="packages-card-content">
        <div className="image-badge-package">{name}</div>
        <div className="content-container-pk">{content}</div>
        <div className="price-container-pk">
          {currencyFormatter(price)}
          <span>
            / Per Person <sup>**</sup>
          </span>
        </div>
        <button className="pk-book-now-btn" onClick={toggleModal}>
          Book Now
        </button>
      </div>
    </div>
  )
}

export default PackageCard
