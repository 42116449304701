import {
  Stack,
  Button,
  Box,
  Typography,
  TextField,
  Dialog,
  Tooltip,
  IconButton,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import NotificationsNoneTwoToneIcon from "@material-ui/icons/NotificationsNoneTwoTone";
import Badge from "@material-ui/core/Badge";
import SearchIcon from "@material-ui/icons/Search";
import { styled } from "@material-ui/styles";
import BrightnessHighIcon from "@material-ui/icons/BrightnessHigh";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import WhatshotTwoToneIcon from "@material-ui/icons/WhatshotTwoTone";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import EventNoteIcon from "@material-ui/icons/EventNote";
import { Container } from "@material-ui/core";
import MobileCards from "./MobileCards";
import AddMobileLead from "./AddMobileLead";
import EditIcon from "@material-ui/icons/Edit";
import Api from "../../Service/Api";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import NotificationSound from "../../assets/sound/mixkit-bubble-pop-up-alert-notification-2357.wav";
import Loader from "./../../components/Loader/Loader";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import LeadAgentForm from "./leadAgentForm";
import LeadAgentView from "./leadAgentView";

const leads = [
  { label: "Board Lead", value: "boardLead" },
  { label: "Travel Agent Lead", value: "travelAgentLead" },
];

let followupOptions = [
  {
    label: "Hot",
    value: "followuphot",
    icon: (
      <WhatshotTwoToneIcon style={{ color: "#e71e24", fontSize: "26px" }} />
    ),
  },
  {
    label: "Warm",
    value: "followupwarm",
    icon: <BrightnessHighIcon style={{ color: "#febc12", fontSize: "26px" }} />,
  },
  {
    label: "Cold",
    value: "followupcold",
    icon: <AcUnitIcon style={{ color: "#8aceee", fontSize: "26px" }} />,
  },
];

const filterOptions = [
  { label: "All", value: "all", icon: " " },
  {
    label: "New",
    value: "new",
    icon: <FiberNewIcon style={{ color: "#fc7506", fontSize: "26px" }} />,
  },
  {
    label: "Followup",
    value: "followup",
    icon: <EventNoteIcon style={{ color: "#0000a5", fontSize: "26px" }} />,
  },
  {
    label: "Lost",
    value: "lost",
    icon: <ThumbDownIcon style={{ color: "#abaaaa", fontSize: "26px" }} />,
  },
  {
    label: "Closed",
    value: "closed",
    icon: <ThumbUpIcon style={{ color: "#1eaf1e", fontSize: "26px" }} />,
  },
  {
    label: "Warm",
    value: "followupwarm",
    icon: <BrightnessHighIcon style={{ color: "#febc12", fontSize: "26px" }} />,
  },
  {
    label: "Hot",
    value: "followuphot",
    icon: (
      <WhatshotTwoToneIcon style={{ color: "#e71e24", fontSize: "26px" }} />
    ),
  },
  {
    label: "Cold",
    value: "followupcold",
    icon: <AcUnitIcon style={{ color: "#8aceee", fontSize: "26px" }} />,
  },
];

const leadScores = [
  { label: "New", value: "new" },
  { label: "FollowUp", value: "followup" },
  { label: "Lost", value: "lost" },
  { label: "Closed", value: "closed" },
];

export default function MobileLead() {
  const [lead, setLead] = useState("boardLead");
  const [leadList, setLeadList] = useState([]);
  const [leadScoring, setLeadScoring] = useState({
    label: "New",
    value: "new",
  });
  const [leadScoringValue, setLeadScoringValue] = useState({
    label: "Hot",
    value: "followuphot",
  });
  const [lostReason, setLostReason] = useState("");
  const [openScoring, setOpenScoring] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [viewselectedDetails, setViewselectedDetails] = useState({});
  const [noOfNotifications, setNoOfNotifications] = useState(
    localStorage.getItem("noOfNotifications")
  );
  // const [noOfNotifications, setNoOfNotifications] = useState(0);
  const audioPlayer = useRef(null);
  const [filterName, setFilterName] = useState("");
  const [loading, setLoading] = useState(false);

  var uniqueid = localStorage.getItem("unique_id");
  let hasAdmin = localStorage.getItem("role");
  var isAdminRole = false;
  let hasDesignation = localStorage.getItem("designation");
  var username = localStorage.getItem("auth");
  const [openAgentLead, setOpenAgentLead] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const OpenAgentLeadDialog = async (row) => {
    setOpenAgentLead(true);
    setSelectedId(row.id);
  };

  if (
    hasAdmin === "Admin" ||
    hasAdmin === "Super Admin" ||
    hasAdmin === "Finance Team" ||
    hasAdmin === "Agent Admin" ||
    hasDesignation === "Lead Manager"
  ) {
    isAdminRole = true;
  }

  function playAudio() {}

  //Lead api data
  const Lead = () => {
    {
      /* the below api displays last 60 days boardLead records only*/
    }
    let url = `/searchboardlead/${uniqueid}`;
    if (
      hasAdmin !== "Admin" &&
      hasAdmin !== "Super Admin" &&
      hasAdmin !== "Finance Team" &&
      hasAdmin !== "Agent Admin" &&
      hasDesignation !== "Lead Manager"
    ) {
      url = `/searchboardleadEmployee/${uniqueid}/${username}`;
    }
    // if (isAdminRole === false) {
    //   url = `/boardleadEmployee/${uniqueid}/${username}`;
    // }
    setLoading(true);
    Api.get(url).then((res) => {
      console.log("boardlead|lead api data: ", res.data);
      setLeadList(res.data);
      var temp;
      if (
        hasAdmin !== "Admin" &&
        hasAdmin !== "Super Admin" &&
        hasAdmin !== "Finance Team" &&
        hasAdmin !== "Agent Admin" &&
        hasDesignation !== "Lead Manager"
      ) {
        temp = res.data.reduce((total, each) => {
          return total + each.notifyemp;
        }, 0);
      } else {
        temp = res.data.reduce((total, each) => {
          return total + each.notify;
        }, 0);
      }
      console.log(
        "MobileLead.js|Lead|noOfNotifications",
        noOfNotifications,
        temp
      );
      if (temp > noOfNotifications && audioPlayer.current != null) {
        // play notification sound
        console.log("Play Sound");
        audioPlayer.current.play();
      }
      setNoOfNotifications(temp);
      localStorage.setItem("noOfNotifications", temp);
      setLoading(false);
    });
  };

  const agentLead = () => {
    setLoading(true);
    let url = `/agentlead`;
    if (
      hasAdmin !== "Admin" &&
      hasAdmin !== "Super Admin" &&
      hasAdmin !== "Finance Team" &&
      hasAdmin !== "Agent Admin"
    ) {
      url = `/agentleademployee/${username}`;
    }
    Api.get(url).then((res) => {
      setLeadList(res.data);
      console.log("Lead Agent Data: ", res.data);
      var temp;
      if (
        hasAdmin !== "Admin" &&
        hasAdmin !== "Super Admin" &&
        hasAdmin !== "Finance Team" &&
        hasAdmin !== "Agent Admin"
      ) {
        temp = res.data.reduce((total, each) => {
          return total + each.notifyemp;
        }, 0);
      } else {
        temp = res.data.reduce((total, each) => {
          return total + each.notify;
        }, 0);
      }
      console.log(
        "MobileLead.js|Lead|noOfNotifications",
        noOfNotifications,
        temp
      );
      if (temp > noOfNotifications && audioPlayer.current != null) {
        // play notification sound
        console.log("Play Sound");
        audioPlayer.current.play();
      }
      setNoOfNotifications(temp);
      localStorage.setItem("noOfNotifications", temp);
      setLoading(false);
    });
  };

  useEffect(() => {
    lead === "boardLead" ? Lead() : agentLead();
  }, [lead]);

  useEffect(() => {
    if ("leadscoring" in viewselectedDetails) {
      setLeadScoring(
        leadScores.filter(
          (each) => each.value === viewselectedDetails.leadscoring
        )[0]
      );
      setLeadScoringValue(
        followupOptions.filter(
          (each) => each.value === viewselectedDetails.leadscoringvalue
        )[0]
      );
      setLostReason(viewselectedDetails.reason);
    } else {
      setLostReason("");
      setLeadScoring({ label: "New", value: "new" });
      setLeadScoringValue({ label: "Hot", value: "followuphot" });
    }
  }, [viewselectedDetails]);

  const sortedFilteredData = applySortFilter(
    leadList,
    getComparator("desc", "createdAt"),
    filterName
  );

  // pagination
  const totalDataLength = sortedFilteredData.length;
  const dataPerPage = 6;
  let noOfPages = Math.ceil(totalDataLength / dataPerPage);
  console.log("totalDataLength", totalDataLength, noOfPages);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  let tempDataIndex = currentPageNo * dataPerPage; //variable for min and max data range for page
  let lastDataIndex = Math.min(tempDataIndex, totalDataLength);
  let firstDataIndex = 0;
  console.log("first and last indices of data:", firstDataIndex, lastDataIndex);

  const changePageNo = (value) => {
    let tempPageNo = currentPageNo + value; //value can be 1 or -1 depending upon arrow clicked
    if (tempPageNo >= 1 && tempPageNo <= noOfPages) {
      setCurrentPageNo(tempPageNo);
    }
  };

  const loaderRef = useRef(null);
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    const scrollPosition = window.pageYOffset;

    scrollPosition > 300 ? setShowButton(true) : setShowButton(false);

    // if (scrollTop + clientHeight >= scrollHeight - 50)
    if (
      Math.ceil(window.innerHeight + document.documentElement.scrollTop) >=
      document.documentElement.offsetHeight
    ) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        changePageNo(1);
      }, 2000);
      console.log("ok");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [firstDataIndex, lastDataIndex, currentPageNo, handleScroll]);

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  // Filter variables and functionality
  const [filterValue, setFilterValue] = useState("all");
  const [openfilter, setfilter] = useState(false);

  const rowStyle = {
    default_row: {},
    active_row: {
      backgroundColor: "#f5f5f5",
    },
    filter_default_row: { cursor: "pointer", padding: "5px 10px" },
    filter_selected_row: {
      cursor: "pointer",
      color: "#000",
      padding: "5px 10px",
      backgroundColor: "#bbb",
    },
  };

  const filterGetStyle = (isActive) => {
    return isActive
      ? rowStyle.filter_selected_row
      : rowStyle.filter_default_row;
  };

  const scoreFilterFun = (selectedValue) => {
    setFilterValue(selectedValue);
    let url = null;

    if (lead === "boardLead") {
      if (isAdminRole && selectedValue === "all")
        url = `/boardlead/${uniqueid}`;
      else if (
        isAdminRole &&
        (selectedValue === "new" ||
          selectedValue === "lost" ||
          selectedValue === "followup" ||
          selectedValue === "closed")
      )
        url = `boardLeadScoring/${uniqueid}/${selectedValue}`;
      else if (
        isAdminRole &&
        (selectedValue === "followupwarm" ||
          selectedValue === "followuphot" ||
          selectedValue === "followupcold")
      )
        url = `boardLeadScoringValue/${uniqueid}/followup/${selectedValue}`;
      else if (!isAdminRole && selectedValue === "all")
        url = `boardleadEmployee/${uniqueid}/${username}`;
      else if (
        !isAdminRole &&
        (selectedValue === "new" ||
          selectedValue === "lost" ||
          selectedValue === "followup" ||
          selectedValue === "closed")
      )
        url = `boardLeadEmployeeScoring/${uniqueid}/${username}/${selectedValue}`;
      else if (
        !isAdminRole &&
        (selectedValue === "followupwarm" ||
          selectedValue === "followuphot" ||
          selectedValue === "followupcold")
      )
        url = `boardLeadEmployeeScoringValue/${uniqueid}/${username}/followup/${selectedValue}`;

      if (url !== null) {
        Api.get(url).then((res) => {
          setLeadList(res.data);
          var temp;
          if (isAdminRole === true)
            temp = res.data.reduce((total, each) => {
              return total + each.notify;
            }, 0);
          else
            temp = res.data.reduce((total, each) => {
              return total + each.notifyemp;
            }, 0);
          console.log(
            "MobileLead.js|Lead|noOfNotifications",
            noOfNotifications,
            temp
          );
          setNoOfNotifications(temp);
        });
      }
    } else {
      url = `agentleademployee/${username}`;
      if (
        hasAdmin === "Admin" ||
        hasAdmin === "Super Admin" ||
        hasAdmin === "Finance Team" ||
        hasAdmin === "Agent Admin"
      ) {
        url = `agentlead`;
      }
      console.log("From Filter: Searching For: ", selectedValue);

      if (
        selectedValue === "new" ||
        selectedValue === "lost" ||
        selectedValue === "followup" ||
        selectedValue === "closed"
      )
        url = url + `/${selectedValue}`;
      if (
        selectedValue === "followupwarm" ||
        selectedValue === "followuphot" ||
        selectedValue === "followupcold"
      )
        url = url + `/followup/${selectedValue}`;

      Api.get(url).then((res) => {
        setLeadList(res.data);
        console.log("Response Data: ", res.data);
        var temp;
        if (
          hasAdmin !== "Admin" &&
          hasAdmin !== "Super Admin" &&
          hasAdmin !== "Finance Team" &&
          hasAdmin !== "Agent Admin"
        ) {
          temp = res.data.reduce((total, each) => {
            return total + each.notifyemp;
          }, 0);
        } else {
          temp = res.data.reduce((total, each) => {
            return total + each.notify;
          }, 0);
        }
        setNoOfNotifications(temp);
      });
    }
    setfilter(false);
  };
  // Filter Functionality Ends

  //function to notify
  const notifyFun = (rowId) => {
    let url =
      lead === "boardLead"
        ? `/boardleadnotifyemp/${rowId}`
        : `/agentnotifyemp/${rowId}`;
    if (isAdminRole === true && lead === "boardLead") {
      url = `/boardleadnotify/${rowId}`;
    } else if (
      (hasAdmin === "Admin" ||
        hasAdmin === "Super Admin" ||
        hasAdmin === "Finance Team" ||
        hasAdmin === "Agent Admin") &&
      lead !== "boardLead"
    ) {
      url = `/agentnotify/${rowId}`;
    }
    Api.get(url).then((res) => {
      lead === "boardLead" ? Lead() : agentLead();
    });
  };

  // Sorting function
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    if (query) {
      return array.filter((each) => {
        return (
          (each.clientMail != null &&
            each.clientMail.toLowerCase().indexOf(query.toLowerCase()) !==
              -1) ||
          (each?.createdBy != null &&
            each?.createdBy?.toLowerCase().indexOf(query.toLowerCase()) !== -1)
        );
      });
    }

    return stabilizedThis.map((el) => el[0]);
  }

  // Change scoring values
  const onSubmitScoring = (event) => {
    let id = viewselectedDetails.id;

    //switch the url based on the lead type
    var url =
      (lead === "boardLead"
        ? "/boardleadupdatescoring/"
        : "/agenttryingbookingstatus/") + id;

    if (leadScoring.value === "followup") {
      var data = {
        id: id,
        leadscoringvalue: leadScoringValue.value,
        leadscoring: leadScoring.value,
        reason: "",
        updatedBy: username,
      };
    } else if (leadScoring.value === "lost") {
      var data = {
        id: id,
        leadscoringvalue: "",
        leadscoring: leadScoring.value,
        reason: lostReason,
        updatedBy: username,
      };
    } else {
      var data = {
        id: id,
        leadscoringvalue: "",
        leadscoring: leadScoring.value,
        reason: "",
        updatedBy: username,
      };
    }

    console.log(data);

    Api.put(url, data).then((response) => {
      lead === "boardLead" ? Lead() : agentLead();
      setOpenScoring(false);
      setViewselectedDetails({});
    });
  };

  const mouseMovedEvent = () => {
    console.log("Mouse Moved!!");
  };

  const CloseAgentLeadModal = () => {
    setOpenAgentLead(false);
    setSelectedId(0);
    agentLead();
  };

  const [openAgentLeadView, setOpenAgentLeadView] = useState(false);
  const [viewselectedAgentId, setViewselectedAgentId] = useState("");
  const OpenDialogAgentView = async (row) => {
    notifyFun(row.id);
    setOpenAgentLeadView(true);
    setViewselectedAgentId(row.id);
  };
  const CloseDialogAgentView = () => {
    setOpenAgentLeadView(false);
    setViewselectedAgentId(0);
  };

  return (
    <>
      {loading && window.innerWidth > 768 ? (
        <Loader />
      ) : (
        <Container
          style={{ paddingTop: "5em", paddingBottom: "5em" }}
          onMouseMove={mouseMovedEvent}
        >
          <Typography
            variant="h5"
            style={{ fontWeight: "bold", marginBottom: "0.4em" }}
          >
            Lead Dashboard
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            style={{ marginBottom: "0.6em" }}
          >
            <Select
              name="lead"
              defaultValue={leads[0]}
              onChange={(e) => {
                setLead(e.value);
                console.log(e.value);
              }}
              options={leads}
              styles={{
                container: (provided) => ({
                  ...provided,
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                valueContainer: (provided) => ({
                  ...provided,
                  overflow: "visible",
                }),
                placeholder: (provided, state) => ({
                  ...provided,
                  position: "absolute",
                  top:
                    state.hasValue || state.selectProps.inputValue ? -4 : "50%",
                  padding: "0px 5px",
                  transition: "top 0.1s, font-size 0.1s",
                  fontSize: "12px",
                }),
                control: (base, state) => ({
                  ...base,
                  "&:hover": { borderColor: "#f46d25" },
                  borderColor: "#f46d25",
                  boxShadow: "none",
                  width: "13.5em",
                  backgroundColor: "#f46d25",
                  color: "#fff",
                  fontSize: "18px",
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  color: "#fff",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: "#fff",
                  fontWeight: "bold",
                }),
              }}
            />
            {lead === "boardLead" && (
              <Button
                style={{
                  backgroundColor: "#333",
                  color: "#fff",
                  fontWeight: "bold",
                }}
                onClick={() => setOpenAdd(true)}
              >
                Add New
              </Button>
            )}
            <AddMobileLead
              Lead={Lead}
              open={openAdd}
              setOpenAdd={setOpenAdd}
              viewselectedDetails={viewselectedDetails}
              setViewselectedDetails={setViewselectedDetails}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            style={{ width: "100%", marginBottom: "0.6em" }}
          >
            <Stack style={{ alignContent: "left" }}>
              <OutlinedInput
                value={filterName}
                onChange={handleFilterByName}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                }
                placeholder={`Search ${
                  lead === "boardLead" ? "Client Email" : "Agent Name"
                }`}
                size="small"
                style={{ width: "15.2em", border: "1px solid #343A40" }}
              />
            </Stack>
            <Stack direction="row">
              <Stack style={{ alignContent: "right" }}>
                <Tooltip title="Lead scoring Filter">
                  <IconButton onClick={() => setfilter(true)}>
                    <i
                      className="fa fa-filter"
                      aria-hidden="true"
                      style={{
                        color: "#343A40",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <audio ref={audioPlayer} src={NotificationSound} />
                <Dialog
                  open={openfilter}
                  onClose={() => {
                    setfilter(false);
                  }}
                >
                  <Stack spacing={1} style={{ width: "15em" }}>
                    {filterOptions.map((each, index) => {
                      return (
                        <Stack
                          key={index}
                          direction="row"
                          spacing={1}
                          justifyContent="space-between"
                          alignItems="center"
                          onClick={() => scoreFilterFun(each.value)}
                          style={filterGetStyle(each.value === filterValue)}
                        >
                          <span>{each.label}</span>
                          <span>{each.icon}</span>
                        </Stack>
                      );
                    })}
                  </Stack>
                </Dialog>
              </Stack>
              <Stack>
                <Tooltip title="Notifications">
                  <IconButton>
                    <Badge badgeContent={noOfNotifications} color="error">
                      <NotificationsNoneTwoToneIcon
                        style={{
                          color: "#343A40",
                          cursor: "pointer",
                          fontSize: "30px",
                        }}
                      />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </Stack>

          <Stack spacing={2}>
            {showButton && (
              <IconButton
                style={{
                  position: "fixed",
                  top: "87%",
                  left: "80%",
                  rotate: "-90deg",
                  backgroundColor: "black",
                  color: "#f46d25",
                  height: "30px",
                  width: "30px",
                  zIndex: "1000",
                }}
                onClick={scrollToTop}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            )}
            {sortedFilteredData
              .slice(firstDataIndex, lastDataIndex)
              .map((each, index) => {
                return (
                  <MobileCards
                    key={index}
                    details={each}
                    setOpenScoring={setOpenScoring}
                    setOpenView={setOpenView}
                    setViewselectedDetails={setViewselectedDetails}
                    notifyFun={notifyFun}
                    setOpenAdd={setOpenAdd}
                    lead={lead}
                    OpenAgentLeadDialog={OpenAgentLeadDialog}
                    OpenDialogAgentView={OpenDialogAgentView}
                  />
                );
              })}
          </Stack>

          {/* lead scoring dialog */}
          <Dialog
            open={openScoring}
            onClose={() => {
              setOpenScoring(false);
              setViewselectedDetails({});
            }}
          >
            <Box style={{ width: "20em", height: "18em" }}>
              <Stack spacing={2} style={{ padding: "1em" }}>
                <Typography>Lead Scoring</Typography>
                <Select
                  name="leadScores"
                  value={leadScoring}
                  onChange={(event) => {
                    console.log(event);
                    setLeadScoring({ label: event.label, value: event.value });
                  }}
                  options={leadScores}
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                />
                <LeadScoringValue
                  leadScoring={leadScoring}
                  leadScoringValue={leadScoringValue}
                  setLeadScoringValue={setLeadScoringValue}
                  lostReason={lostReason}
                  setLostReason={setLostReason}
                />
                <Stack
                  direction="row"
                  justifyContent="space-evenly"
                  style={{ marginTop: "3em" }}
                >
                  <Button
                    style={{
                      backgroundColor: "#f46d25",
                      color: "#fff",
                      width: "7em",
                    }}
                    onClick={(event) => {
                      onSubmitScoring(event);
                    }}
                  >
                    Submit
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#222",
                      color: "#fff",
                      width: "7em",
                    }}
                    onClick={() => {
                      setOpenScoring(false);
                      setViewselectedDetails({});
                    }}
                  >
                    Back
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Dialog>

          {/* view dialog */}
          <Dialog
            open={openView}
            onClose={() => {
              setOpenView(false);
              setViewselectedDetails({});
            }}
          >
            <Box style={{ overflow: "hidden" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                style={{
                  backgroundColor: "#f46d25",
                  color: "#fff",
                  width: "100vw",
                }}
              >
                <Stack>
                  <span
                    style={{ fontWeight: "bold", padding: "1em 1em 0 1em" }}
                  >
                    {viewselectedDetails.clientName}
                  </span>
                  <span style={{ padding: "0 1em 1em 1em" }}>
                    {viewselectedDetails.clientMail}
                  </span>
                </Stack>
                <IconButton aria-label="edit">
                  <EditIcon
                    style={{
                      color: "#fff",
                      fontSize: "1em",
                      paddingRight: "0.75em",
                    }}
                    onClick={() => {
                      setOpenAdd(true);
                      setOpenView(false);
                    }}
                  ></EditIcon>
                  <CancelIcon
                    style={{
                      color: "#fff",
                      fontSize: "1em",
                      paddingRight: "3em",
                    }}
                    onClick={() => {
                      setOpenView(false);
                      setViewselectedDetails({});
                    }}
                  ></CancelIcon>
                </IconButton>
              </Stack>
              <Stack
                spacing={2}
                style={{
                  backgroundColor: "#fff",
                  padding: "1.5em 0.5em",
                  fontSize: "1em",
                  borderBottomRightRadius: "0.5em",
                  borderBottomLeftRadius: "0.5em",
                  width: "100%",
                }}
              >
                <table>
                  <tbody>
                    <tr>
                      <td>Phone No. </td>
                      <td>: {viewselectedDetails.clientMobileNo}</td>
                    </tr>
                    <tr>
                      <td>Departure City </td>
                      <td>: {viewselectedDetails.clientDepartcity}</td>
                    </tr>
                    <tr>
                      <td>Destination City</td>
                      <td>: {viewselectedDetails.destination}</td>
                    </tr>
                    <tr>
                      <td>Lead Type </td>
                      <td>: {viewselectedDetails.leadType}</td>
                    </tr>
                    <tr>
                      <td>Budget</td>
                      <td>: {viewselectedDetails.budget}</td>
                    </tr>
                    <tr>
                      <td>No of Pax</td>
                      <td>: {viewselectedDetails.noofpax}</td>
                    </tr>
                    <tr>
                      <td>No. of Nights</td>
                      <td>: {viewselectedDetails.noofnights}</td>
                    </tr>
                    <tr>
                      <td>Check In Date</td>
                      <td>
                        :{" "}
                        {viewselectedDetails.checkin &&
                          viewselectedDetails.checkin
                            .split("-")
                            .reverse()
                            .join("-")}
                      </td>
                    </tr>
                    <tr>
                      <td>Lead Assigned To</td>
                      <td>: {viewselectedDetails.leadassignto}</td>
                    </tr>
                    <tr>
                      <td>Lead Source</td>
                      <td>: {viewselectedDetails.leadsource}</td>
                    </tr>
                    <tr>
                      <td>Lead Scoring</td>
                      <Stack>
                        <td
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          :
                          {viewselectedDetails.leadscoring !==
                          "followup" ? null : (
                            <>
                              <>
                                {viewselectedDetails.leadscoringvalue !==
                                "followuphot" ? null : (
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginLeft: "2.5%",
                                    }}
                                  >
                                    Hot
                                    <WhatshotTwoToneIcon
                                      style={{
                                        color: "#e71e25",
                                        marginTop: "10%",
                                        marginLeft: "10%",
                                      }}
                                    />
                                  </span>
                                )}
                                {viewselectedDetails.leadscoringvalue !==
                                "followupwarm" ? null : (
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginLeft: "2.5%",
                                    }}
                                  >
                                    Warm
                                    <BrightnessHighIcon
                                      style={{
                                        color: "#febc12",
                                        marginLeft: "10%",
                                      }}
                                    />
                                  </span>
                                )}
                                {viewselectedDetails.leadscoringvalue !==
                                "followupcold" ? null : (
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginLeft: "2.5%",
                                    }}
                                  >
                                    Cold
                                    <AcUnitIcon
                                      style={{
                                        color: "#8aceee",
                                        marginLeft: "10%",
                                      }}
                                    />
                                  </span>
                                )}
                              </>
                            </>
                          )}
                          <>
                            {viewselectedDetails.leadscoring !==
                            "closed" ? null : (
                              <span style={{ marginLeft: "2.5%" }}>Closed</span>
                            )}
                            {viewselectedDetails.leadscoring !==
                            "lost" ? null : (
                              <span style={{ marginLeft: "2.5%" }}>Lost</span>
                            )}
                            {viewselectedDetails.leadscoring !==
                            "new" ? null : (
                              <span style={{ marginLeft: "2.5%" }}>New</span>
                            )}
                          </>
                        </td>
                      </Stack>
                    </tr>
                    {viewselectedDetails.leadscoring !== "lost" ? null : (
                      <tr>
                        <td>Reason</td>
                        <td>: {viewselectedDetails.reason}</td>
                      </tr>
                    )}

                    <tr>
                      <td>Remarks</td>
                      <td>: {viewselectedDetails.notes}</td>
                    </tr>
                  </tbody>
                </table>
              </Stack>
            </Box>
          </Dialog>

          <LeadAgentForm
            open={openAgentLead}
            selectedId={selectedId}
            onClose={CloseAgentLeadModal}
          />

          <LeadAgentView
            open={openAgentLeadView}
            onClose={CloseDialogAgentView}
            selectedIdView={viewselectedAgentId}
          />
          <>
            <div ref={loaderRef} />
            {loading && lastDataIndex < sortedFilteredData.length && (
              <div
                style={{
                  width: "100%",
                  height: "5%",
                  padding: "0",
                  margin: "0",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Loader />
              </div>
            )}
          </>
        </Container>
      )}
    </>
  );
}
// function LeadScoringValue({ leadScoring, leadScoringValue, setLeadScoringValue }) {
function LeadScoringValue(props) {
  let leadScoringValue = "";
  if (typeof props.leadScoring !== "undefined") {
    leadScoringValue = props.leadScoring.value;
  }
  if (leadScoringValue == "followup") {
    return (
      <Select
        name="followup"
        value={props.leadScoringValue}
        onChange={(event) => {
          console.log(event);
          props.setLeadScoringValue({ label: event.label, value: event.value });
        }}
        options={followupOptions}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
      />
    );
  } else if (leadScoringValue == "lost") {
    return (
      <TextField
        name="reason"
        type="text"
        value={props.lostReason}
        fullWidth
        variant="outlined"
        size="small"
        onChange={(event) => {
          console.log(event.target.value);
          props.setLostReason(event.target.value);
        }}
      />
    );
  } else {
    return <></>;
  }
}
