import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  makeStyles,
  styled,
} from "@material-ui/core"
import { useEffect, useState } from "react"
import API from "../../Service/Api"
import EditIcon from "@material-ui/icons/Edit"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "4rem 2rem",
    margin: "0rem",
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  background: "#F46D25",
  height: "40px",
}))
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: "#F46D25",
  color: "#fff",
  fontSize: "12px",
  padding: "5px",
  lineHeight: "1rem",
  minWidth: "100px",
}))

const StyledTableCellInside = styled(TableCell)(({ theme }) => ({
  padding: "5px",
  fontSize: "14px",
  lineHeight: "15px",
}))

const tableHeadValues = ["Destination", "Sub-Destination", "Page", "Actions"]

const ViewContentB2C = () => {
  const styles = useStyles()
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const fetchList = async () => {
    setLoading(true)
    try {
      const response = await API.get("getAllWebsiteContent")
      setTableData(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchList()
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value)
    setPage(0)
  }

  return (
    <div className={styles.root}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h4" color="primary">
          B2C Content Builder
        </Typography>
        <Button disableRipple={false}>Add Content</Button>
      </div>
      <TableContainer component={Paper} style={{ marginTop: "0.5%" }}>
        <Table stickyHeader size="small">
          <TableHead>
            <StyledTableRow>
              {tableHeadValues.map((value) => (
                <StyledTableCell
                  key={value}
                  style={{
                    color: "white",
                    fontSize: "15px",
                  }}
                  align="center"
                >
                  {value}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {tableData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow key={row.id} style={{ height: "40px" }}>
                    <StyledTableCellInside
                      style={{
                        textTransform: "capitalize",
                        color: "#f46d25",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      align="center"
                    >
                      {row?.destination}
                    </StyledTableCellInside>
                    <StyledTableCellInside align="center">
                      {row.subDestination ? row?.subDestination : "-"}
                    </StyledTableCellInside>
                    <StyledTableCellInside align="center">
                      {row?.page}
                    </StyledTableCellInside>

                    <StyledTableCellInside
                      align="center"
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <Tooltip title="Edit">
                        <IconButton
                          aria-label="edit"
                          style={{ padding: "0px", color: "#F46D25" }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCellInside>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={tableData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  )
}

export default ViewContentB2C
