import ContactInfo from "../../components/ContactInfo/ContactInfo.component"
import QueryForm from "../../components/QueryForm/QueryForm.component"
import styles from "./AboutUs.module.css"
import TownoFamilyCollage from "../../assets/illustrations/townoFamily/Towno _Family_Collage.svg"

const AboutUs = () => {
  return (
    <>
      <div className={styles.topSection}>
        <header className={styles.header}>About Us</header>
      </div>
      <section className={styles.section}>
        <main className={styles.main}>
          <article className={styles.article}>
            <img
              src={TownoFamilyCollage}
              className={styles.townoFamily}
              loading="lazy"
            />
            <div className={styles.container}>
              {/* <p className={styles.question}>
                Why Book a Package when you can Book an Experience?
              </p> */}
              <p>Hey!</p>
              <p className={styles.question}>
                We're Towno and we build experiential holidays that are tailored
                for you and your family.{" "}
                <i className="fa fa-heart" style={{ color: "#f46d25" }} />
              </p>
              <p className={styles.answer}>
                Since its establishment in 2016, Towno has been a trailblazer in
                pioneering personalized and experiential holidays across India.
                Our journey began with curating holidays for travelers exploring
                the quaint villages of Uttarakhand.
              </p>
              <p className={styles.answer}>
                Through dedication and innovation, we disrupted traditional
                travel norms and expanded across India, becoming one of the
                largest travel partners in Goa and Uttarakhand. With exclusive
                tie-ups with hotels and vendors, we consistently provide
                unbeatable rates, ensuring affordability without compromising
                quality.
              </p>
              <p className={styles.answer}>
                Over the years, we've expanded our offerings beyond India to
                Dubai and Thailand, fine-tuning our customization capabilities
                to cater to diverse traveler preferences. Today, Towno stands
                proud as a leading authority in personalized and experiential
                holidays, committed to redefining the art of exploration with
                each unique journey.
              </p>
              <div className={styles.footer}>
                <span>Intellego Tech Solutions Pvt Ltd</span>
                <p>1, Nehru Enclave, GMS Road, Ballupur, Dehradun - 248001</p>
              </div>
            </div>
          </article>
        </main>
        <aside className={styles.aside}>
          <ContactInfo />
          <QueryForm />
        </aside>
      </section>
    </>
  )
}

export default AboutUs
