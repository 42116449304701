import {
  FormControlLabel,
  Select,
  Switch,
  FormControl,
  Chip,
  InputLabel,
  MenuItem,
  makeStyles,
  Checkbox,
} from "@material-ui/core";
import { Grid } from "@mui/material";
import { Field } from "formik";
import DoneIcon from "@material-ui/icons/Done";

const useStyles = makeStyles((theme) => ({
  checkboxStyling: {
    "& .MuiSvgIcon-root": {
      color: "#f46d25",
      height: "18px",
      width: "18px",
    },
  },
  menuStyling: {
    fontSize: "14px",
  },
  selectLabelProps: {
    backgroundColor: "white",
    color: "#000000",
    padding: "0px 5px 5px 5px",
    borderRadius: "5px",
  },
  menuItemStyles: {
    height: "300px",
    fontSize: "14px",
  },
  selectStyle: {
    height: "35px",
    width: "100%",
    "& .MuiSelect-icon": {
      color: "#f46d25",
    },
  },
  checkBoxLabelStyles: {
    fontSize: "14px",
  },
  chipStyling: {
    margin: theme.spacing(0.5),
    height: "15px",
    fontSize: "13px",
  },
}));

const InlineCheckboxSwitchWithMultipleSelectFields = ({ amenity, values }) => {
  const selectStyles = useStyles();
  return (
    <Grid item container xs={9} alignItems="center" spacing={3}>
      <Grid item xs={3}>
        <FormControlLabel
          control={
            <Field
              checked={values[amenity.name]}
              as={Checkbox}
              name={amenity.name}
              className={selectStyles.checkboxStyling}
            />
          }
          label={
            <span className={selectStyles.checkBoxLabelStyles}>
              {amenity.label}
            </span>
          }
        />
      </Grid>
      {values[amenity.name] && (
        <>
          <Grid item xs={3}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel className={selectStyles.selectLabelProps} shrink>
                {amenity.selectLabel1}
              </InputLabel>
              <Field
                as={Select}
                name={amenity.selectFieldName1}
                value={values[amenity.selectFieldName1]}
                className={selectStyles.selectStyle}
                MenuProps={{
                  className: selectStyles.menuItemStyles,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {amenity.dropdDownOptions1.map((option) => (
                  <MenuItem
                    className={selectStyles.menuStyling}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
          {values[amenity.selectFieldName1] === "paid" &&
            amenity.selectType2 == "multipleValue" && (
              <Grid item xs={3}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel className={selectStyles.selectLabelProps} shrink>
                    {amenity.selectLabel2}
                  </InputLabel>
                  <Field
                    as={Select}
                    multiple
                    name={amenity.selectFieldName2}
                    value={values[amenity.selectFieldName2]}
                    className={selectStyles.selectStyle}
                    MenuProps={{
                      className: selectStyles.menuItemStyles,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    renderValue={(selected) => (
                      <div>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            variant="outlined"
                            color="primary"
                            deleteIcon={<DoneIcon color="primary" />}
                            className={selectStyles.chipStyling}
                          />
                        ))}
                      </div>
                    )}
                  >
                    {amenity.dropdDownOptions2.map((option) => (
                      <MenuItem
                        className={selectStyles.menuStyling}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
            )}{" "}
          {values[amenity.selectFieldName1] === "paid" &&
            amenity.selectType2 != "multipleValue" && (
              <Grid item xs={3}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel className={selectStyles.selectLabelProps} shrink>
                    {amenity.selectLabel2}
                  </InputLabel>
                  <Field
                    as={Select}
                    name={amenity.selectFieldName2}
                    value={values[amenity.selectFieldName2]}
                    className={selectStyles.selectStyle}
                    MenuProps={{
                      className: selectStyles.menuItemStyles,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {amenity.dropdDownOptions2.map((option) => (
                      <MenuItem
                        className={selectStyles.menuStyling}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </Grid>
            )}
          <Grid item xs={3}>
            <Field
              name={amenity.switchName}
              as={Switch}
              color="primary"
              checked={values[amenity.switchName]}
              size="small"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default InlineCheckboxSwitchWithMultipleSelectFields;
