import { ActionTypes } from "../actions/actionTypes";

let INITIAL_STATE = {
  termsAndConditions: "",
  cancellationPolicy: "",
  mealplanPolicy: "",
};

export const hotelLoginTermsAndConditionsAndCancellationPolicyReducer = (
  state = INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.HOTEL_LOGIN_CANCELLATION_POLICY: {
      return { ...state, cancellationPolicy: payload };
    }
    case ActionTypes.HOTEL_LOGIN_TERMS_AND_CONDITION: {
      console.log(payload);
      return {
        ...state,
        termsAndConditions: payload,
      };
    }

    case ActionTypes.HOTEL_LOGIN_MEALPLAN_POLICY: {
      return {
        ...state,
        mealplanPolicy: payload,
      };
    }

    default:
      return state;
  }
};
