import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Api from "../../Service/Api";
import StarIcon from "@material-ui/icons/Star";
import { Button, Grid, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  qualityOfYourStay: Yup.number().required("Required"),
  varietyAndQualityActivities: Yup.number().required("Required"),
  helpfulnessOfSalesAgent: Yup.number().required("Required"),
  pricingOfServices: Yup.number().required("Required"),
  easeAndEffeciencyOfBooking: Yup.number().required("Required"),
  clarityAndAccuracyOfTheInfo: Yup.number().required("Required"),
  valueForMoney: Yup.number().required("Required"),
  responsivenessOfCustomerService: Yup.number().required("Required"),
  recommend: Yup.number().required("Required"),
  overallExperience: Yup.number().required("Required"),
  concernsOrissues: Yup.string(),
  positiveExperiences: Yup.string(),
  improveServices: Yup.string(),
});

const FeedbackForm = () => {
  const [hoverValues, setHoverValues] = useState({
    qualityOfYourStay: 0,
    varietyAndQualityActivities: 0,
    helpfulnessOfSalesAgent: 0,
    pricingOfServices: 0,
    easeAndEffeciencyOfBooking: 0,
    clarityAndAccuracyOfTheInfo: 0,
    valueForMoney: 0,
    responsivenessOfCustomerService: 0,
    recommend: 0,
    overallExperience: 0,
  });
  const bookingDetails = useSelector(
    (state) => state.bookingDetails.bookingDetail
  );
  // console.log("bookingdetails", bookingDetails);

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      qualityOfYourStay: 0,
      varietyAndQualityActivities: 0,
      helpfulnessOfSalesAgent: 0,
      pricingOfServices: 0,
      easeAndEffeciencyOfBooking: 0,
      clarityAndAccuracyOfTheInfo: 0,
      valueForMoney: 0,
      responsivenessOfCustomerService: 0,
      recommend: 0,
      overallExperience: 0,
      concernsOrissues: "",
      positiveExperiences: "",
      improveServices: "",
      anyComments: "",
    },
    validationSchema: validationSchema,
    onSubmit,
  });

  async function onSubmit(values, actions) {
    // console.log("values", values);
    const Averge =
      (values.qualityOfYourStay +
        values.varietyAndQualityActivities +
        values.helpfulnessOfSalesAgent +
        values.pricingOfServices +
        values.easeAndEffeciencyOfBooking +
        values.clarityAndAccuracyOfTheInfo +
        values.valueForMoney +
        values.responsivenessOfCustomerService +
        values.recommend +
        values.overallExperience) /
      10;
    // console.log("average added", {
    //   ...values,
    //   average: Averge,
    //   bookingId: bookingDetails.bookingId,
    //   propertyId: bookingDetails.propertyId,
    // });
    const entireData = {
      ...values,
      average: Averge,
      bookingId: bookingDetails.bookingId,
      propertyId: bookingDetails.propertyId,
    };
    try {
      const res = await Api.post("bookingFeedback", entireData);
      console.log(res.status);
      if (res) {
        toast.success("Feedback Submitted Successfully");
        resetForm();
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleStarClick = (field, index) => {
    // console.log(field);
    setFieldValue(field, index);
  };

  const handleStarHover = (field, index) => {
    setHoverValues({ ...hoverValues, [field]: index });
  };

  const handleStarLeave = () => {
    setHoverValues({
      qualityOfYourStay: 0,
      varietyAndQualityActivities: 0,
      helpfulnessOfSalesAgent: 0,
      pricingOfServices: 0,
      easeAndEffeciencyOfBooking: 0,
      clarityAndAccuracyOfTheInfo: 0,
      valueForMoney: 0,
      responsivenessOfCustomerService: 0,
      recommend: 0,
      overallExperience: 0,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        alignItems="center"
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs={6} sm={6} md={8}>
          <label htmlFor="qualityOfYourStay">
            On a scale of 1 to 5, how would you rate the
            <b> quality of your stay</b>
            (accommodations, amenities, cleanliness) during your trip arranged
            by the agency?
          </label>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          {[1, 2, 3, 4, 5].map((index) => (
            <StarIcon
              key={index}
              size={24}
              style={{
                marginRight: 5,
                cursor: "pointer",
                color:
                  (hoverValues.qualityOfYourStay || values.qualityOfYourStay) >=
                  index
                    ? "gold"
                    : "grey",
              }}
              onMouseOver={() => handleStarHover("qualityOfYourStay", index)}
              onMouseLeave={handleStarLeave}
              onClick={() => handleStarClick("qualityOfYourStay", index)}
            />
          ))}
        </Grid>
        {errors.qualityOfYourStay && touched.qualityOfYourStay && (
          <div>{errors.qualityOfYourStay}</div>
        )}
      </Grid>
      <Grid
        alignItems="center"
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt="5px"
      >
        <Grid item xs={6} sm={6} md={8}>
          <label htmlFor="varietyAndQualityActivities">
            Please rate your level of satisfaction with the{" "}
            <b>variety and quality of activities and excursions</b> offered by
            the agency. (1 being very dissatisfied, 5 being very satisfied)
          </label>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          {[1, 2, 3, 4, 5].map((index) => (
            <StarIcon
              key={index}
              size={24}
              style={{
                marginRight: 5,
                cursor: "pointer",
                color:
                  (hoverValues.varietyAndQualityActivities ||
                    values.varietyAndQualityActivities) >= index
                    ? "gold"
                    : "grey",
              }}
              onMouseOver={() =>
                handleStarHover("varietyAndQualityActivities", index)
              }
              onMouseLeave={handleStarLeave}
              onClick={() =>
                handleStarClick("varietyAndQualityActivities", index)
              }
            />
          ))}
        </Grid>
        {errors.varietyAndQualityActivities &&
          touched.varietyAndQualityActivities && (
            <div>{errors.varietyAndQualityActivities}</div>
          )}
      </Grid>
      <Grid
        alignItems="center"
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt="5px"
      >
        <Grid item xs={6} sm={6} md={8}>
          <label htmlFor="helpfulnessOfSalesAgent">
            Please rate the
            <b>
              {" "}
              professionalism, knowledge, and helpfulness of the sales agent
            </b>{" "}
            who assisted you in planning your trip. (1 being poor, 5 being
            excellent)
          </label>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          {[1, 2, 3, 4, 5].map((index) => (
            <StarIcon
              key={index}
              size={24}
              style={{
                marginRight: 5,
                cursor: "pointer",
                color:
                  (hoverValues.helpfulnessOfSalesAgent ||
                    values.helpfulnessOfSalesAgent) >= index
                    ? "gold"
                    : "grey",
              }}
              onMouseOver={() =>
                handleStarHover("helpfulnessOfSalesAgent", index)
              }
              onMouseLeave={handleStarLeave}
              onClick={() => handleStarClick("helpfulnessOfSalesAgent", index)}
            />
          ))}
        </Grid>
        {errors.helpfulnessOfSalesAgent && touched.helpfulnessOfSalesAgent && (
          <div>{errors.helpfulnessOfSalesAgent}</div>
        )}
      </Grid>
      <Grid
        alignItems="center"
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt="5px"
      >
        <Grid item xs={6} sm={6} md={8}>
          <label htmlFor="pricingOfServices">
            How satisfied are you with the<b> pricing of the services </b>{" "}
            provided by the agency? (1 being too expensive, 5 being very
            affordable)
          </label>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          {[1, 2, 3, 4, 5].map((index) => (
            <StarIcon
              key={index}
              size={24}
              style={{
                marginRight: 5,
                cursor: "pointer",
                color:
                  (hoverValues.pricingOfServices || values.pricingOfServices) >=
                  index
                    ? "gold"
                    : "grey",
              }}
              onMouseOver={() => handleStarHover("pricingOfServices", index)}
              onMouseLeave={handleStarLeave}
              onClick={() => handleStarClick("pricingOfServices", index)}
            />
          ))}
        </Grid>
        {errors.pricingOfServices && touched.pricingOfServices && (
          <div>{errors.pricingOfServices}</div>
        )}
      </Grid>
      <Grid
        alignItems="center"
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt="5px"
      >
        <Grid item xs={6} sm={6} md={8}>
          <label htmlFor="easeAndEffeciencyOfBooking">
            Please rate the <b>ease and efficiency of the booking process</b>{" "}
            when arranging your trip with the agency. (1 being difficult and
            time-consuming, 5 being quick and straightforward)
          </label>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          {[1, 2, 3, 4, 5].map((index) => (
            <StarIcon
              key={index}
              size={24}
              style={{
                marginRight: 5,
                cursor: "pointer",
                color:
                  (hoverValues.easeAndEffeciencyOfBooking ||
                    values.easeAndEffeciencyOfBooking) >= index
                    ? "gold"
                    : "grey",
              }}
              onMouseOver={() =>
                handleStarHover("easeAndEffeciencyOfBooking", index)
              }
              onMouseLeave={handleStarLeave}
              onClick={() =>
                handleStarClick("easeAndEffeciencyOfBooking", index)
              }
            />
          ))}
        </Grid>
        {errors.easeAndEffeciencyOfBooking &&
          touched.easeAndEffeciencyOfBooking && (
            <div>{errors.easeAndEffeciencyOfBooking}</div>
          )}
      </Grid>
      <Grid
        alignItems="center"
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt="5px"
      >
        <Grid item xs={6} sm={6} md={8}>
          <label htmlFor="clarityAndAccuracyOfTheInfo">
            On a scale of 1 to 5, how would you rate the
            <b> clarity and accuracy of the information</b> provided by the
            agency regarding your trip?
          </label>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          {[1, 2, 3, 4, 5].map((index) => (
            <StarIcon
              key={index}
              size={24}
              style={{
                marginRight: 5,
                cursor: "pointer",
                color:
                  (hoverValues.clarityAndAccuracyOfTheInfo ||
                    values.clarityAndAccuracyOfTheInfo) >= index
                    ? "gold"
                    : "grey",
              }}
              onMouseOver={() =>
                handleStarHover("clarityAndAccuracyOfTheInfo", index)
              }
              onMouseLeave={handleStarLeave}
              onClick={() =>
                handleStarClick("clarityAndAccuracyOfTheInfo", index)
              }
            />
          ))}
        </Grid>
        {errors.clarityAndAccuracyOfTheInfo &&
          touched.clarityAndAccuracyOfTheInfo && (
            <div>{errors.clarityAndAccuracyOfTheInfo}</div>
          )}
      </Grid>
      <Grid
        alignItems="center"
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt="5px"
      >
        <Grid item xs={6} sm={6} md={8}>
          <label htmlFor="valueForMoney">
            How satisfied are you with the overall <b>value for money</b> of the
            services provided by the agency? (1 being very dissatisfied, 5 being
            very satisfied)
          </label>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          {[1, 2, 3, 4, 5].map((index) => (
            <StarIcon
              key={index}
              size={24}
              style={{
                marginRight: 5,
                cursor: "pointer",
                color:
                  (hoverValues.valueForMoney || values.valueForMoney) >= index
                    ? "gold"
                    : "grey",
              }}
              onMouseOver={() => handleStarHover("valueForMoney", index)}
              onMouseLeave={handleStarLeave}
              onClick={() => handleStarClick("valueForMoney", index)}
            />
          ))}
        </Grid>
        {errors.valueForMoney && touched.valueForMoney && (
          <div>{errors.valueForMoney}</div>
        )}
      </Grid>
      <Grid
        alignItems="center"
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt="5px"
      >
        <Grid item xs={6} sm={6} md={8}>
          <label htmlFor="responsivenessOfCustomerService">
            Please rate the
            <b>
              {" "}
              responsiveness of the agency's customer service team in addressing
              your inquiries and concerns.
            </b>{" "}
            (1 being very slow, 5 being very responsive)
          </label>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          {[1, 2, 3, 4, 5].map((index) => (
            <StarIcon
              key={index}
              size={24}
              style={{
                marginRight: 5,
                cursor: "pointer",
                color:
                  (hoverValues.responsivenessOfCustomerService ||
                    values.responsivenessOfCustomerService) >= index
                    ? "gold"
                    : "grey",
              }}
              onMouseOver={() =>
                handleStarHover("responsivenessOfCustomerService", index)
              }
              onMouseLeave={handleStarLeave}
              onClick={() =>
                handleStarClick("responsivenessOfCustomerService", index)
              }
            />
          ))}
        </Grid>
        {errors.responsivenessOfCustomerService &&
          touched.responsivenessOfCustomerService && (
            <div>{errors.responsivenessOfCustomerService}</div>
          )}
      </Grid>
      <Grid
        alignItems="center"
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt="5px"
      >
        <Grid item xs={6} sm={6} md={8}>
          <label htmlFor="recommend">
            How likely are you to <b> recommend this travel agency </b> to
            others based on your experience? (1 being very unlikely, 5 being
            very likely)
          </label>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          {[1, 2, 3, 4, 5].map((index) => (
            <StarIcon
              key={index}
              size={24}
              style={{
                marginRight: 5,
                cursor: "pointer",
                color:
                  (hoverValues.recommend || values.recommend) >= index
                    ? "gold"
                    : "grey",
              }}
              onMouseOver={() => handleStarHover("recommend", index)}
              onMouseLeave={handleStarLeave}
              onClick={() => handleStarClick("recommend", index)}
            />
          ))}
        </Grid>
        {errors.recommend && touched.recommend && <div>{errors.recommend}</div>}
      </Grid>
      <Grid
        alignItems="center"
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt="5px"
      >
        <Grid item xs={6} sm={6} md={8}>
          <label htmlFor="overallExperience">
            Please rate the <b> overall experience of your trip</b> arranged by
            the agency. (1 being very poor, 5 being excellent)
          </label>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          {[1, 2, 3, 4, 5].map((index) => (
            <StarIcon
              key={index}
              size={24}
              style={{
                marginRight: 5,
                cursor: "pointer",
                color:
                  (hoverValues.overallExperience || values.overallExperience) >=
                  index
                    ? "gold"
                    : "grey",
              }}
              onMouseOver={() => handleStarHover("overallExperience", index)}
              onMouseLeave={handleStarLeave}
              onClick={() => handleStarClick("overallExperience", index)}
            />
          ))}
        </Grid>
        {errors.overallExperience && touched.overallExperience && (
          <div>{errors.overallExperience}</div>
        )}
      </Grid>
      <Grid
        alignItems="center"
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt="5px"
      >
        <Grid item xs={6} sm={6} md={8}>
          <label htmlFor="concernsOrissues">
            Please detail any <b>concerns or issues</b> you encountered during
            your trip.
          </label>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <TextField
            className="inputbox"
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            value={values.concernsOrissues}
            placeholder="Concerns or Issues"
            name="concernsOrissues"
            id="concernsOrissues"
            fullWidth
          />
        </Grid>
        {errors.concernsOrissues && touched.concernsOrissues && (
          <div>{errors.concernsOrissues}</div>
        )}
      </Grid>
      <Grid
        alignItems="center"
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt="5px"
      >
        <Grid item xs={6} sm={6} md={8}>
          <label htmlFor="positiveExperiences">
            Kindly share any <b>highlights or positive experiences</b> from your
            journey.
          </label>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <TextField
            className="inputbox"
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            value={values.positiveExperiences}
            placeholder="Highlights or Positive Experiences"
            name="positiveExperiences"
            id="positiveExperiences"
            fullWidth
          />
        </Grid>
        {errors.positiveExperiences && touched.positiveExperiences && (
          <div>{errors.positiveExperiences}</div>
        )}
      </Grid>
      <Grid
        alignItems="center"
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt="5px"
      >
        <Grid item xs={6} sm={6} md={8}>
          <label htmlFor="improveServices">
            We value your feedback. How do you suggest we <b>improve</b> our
            services?
          </label>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <TextField
            className="inputbox"
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            value={values.improveServices}
            placeholder="Improve our Services"
            name="improveServices"
            id="improveServices"
            fullWidth
          />
        </Grid>
        {errors.improveServices && touched.improveServices && (
          <div>{errors.improveServices}</div>
        )}
      </Grid>
      <Grid
        alignItems="center"
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt="5px"
      >
        <Grid item xs={6} sm={6} md={8}>
          <label htmlFor="improveServices">Any Comments</label>
        </Grid>
        <Grid item xs={6} sm={6} md={4}>
          <TextField
            className="inputbox"
            onChange={handleChange}
            onBlur={handleBlur}
            size="small"
            value={values.anyComments}
            placeholder="Any Comments"
            name="anyComments"
            id="anyComments"
            fullWidth
          />
        </Grid>
        {errors.anyComments && touched.anyComments && (
          <div>{errors.anyComments}</div>
        )}
      </Grid>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="submit"
          sx={{
            backgroundColor: "#f46d25",
            textAlign: "center",
            color: "#FFFFFF",
            margin: "10px 0px",
            display: "flex",
            justifyContent: "center",
            textTransform: "capitalize",
            fontSize: "12px",
            padding: "8px 15px",
            borderRadius: "3px",
            "&:hover": { backgroundColor: "#f46d25!important" },
          }}
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default FeedbackForm;
