import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core"
import { Form, Formik } from "formik"
import { useState } from "react"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import { Grid } from "@mui/material"
import Api from "../../../../Service/Api"
import { useEffect } from "react"
import InlineFormikFieldWithCheckSelectAndSwitch from "../../../../components/amenityComponents/inlineCheckBoxWithSelectAndSwitch.component"
import InlineCheckboxSwitchWithMultipleSelectFields from "../../../../components/amenityComponents/inlineCheckboxSwitchAndMultpleSelectFields.component"
import FormikInlineCheckboxAndSwitchOnly from "../../../../components/amenityComponents/inlineCheckBoxAndSwitchOnly.component"
import ScreenLoader from "../../../../components/loadingScreen/screenLoader.component"
import { toast } from "react-toastify"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const initialValues = {
  airConditioning: false,
  airConditioningStarred: false,
  airConditioningType: "",
  interconnectedRoom: false,
  interconnectedRoomStarred: false,
  heater: false,
  heaterStarred: false,
  heaterType: "",
  heaterType1: "",
  housekeeping: false,
  housekeepingType: "",
  housekeepingStarred: false,
  inRoomdining: false,
  inRoomdiningStarred: false,
  inRoomdiningType: "",
  ironIroningBoard: false,
  ironIroningBoardStarred: false,
  laundryService: false,
  laundryServiceStarred: false,
  laundryServiceType: "",
  mineralWater: false,
  mineralWaterStarred: false,
  roomService: false,
  roomServiceStarred: false,
  roomServiceType: "",
  smokingRoom: false,
  smokingRoomStarred: false,
  electricKettle: false,
  electricKettleStarred: false,
  wifi: false,
  wifiStarred: false,
  wifiType: "",
  airPurifier: false,
  airPurifierStarred: false,
  sanitizers: false,
  sanitizersStarred: false,
  sanitizersType: [],
  balcony: false,
  balconyType: "",
  balconyStarred: false,
  jaccuzi: false,
  jaccuziStarred: false,
  privatePool: false,
  privatePoolStarred: false,
  terrace: false,
  terraceStarred: false,
}

const PopulatWithGuests = ({
  classes,
  handleChangePanel,
  expanded,
  setExpanded,
}) => {
  let { propertyId, displayName } = useParams()
  const [popularWithGuestsData, setPopularWithGuests] = useState(() => {})
  const [RoomAirConditioning, setRoomAirConditioning] = useState([])
  const [RoomHeater, setRoomHeater] = useState([])
  const [RoomHouseKeeping, setRoomHouseKeeping] = useState([])
  const [RoomInRoomDining, setRoomInRoomDining] = useState([])
  const [RoomRoomService, setRoomRoomService] = useState([])
  const [RoomSanitizers, setRoomSanitizers] = useState([])
  const propertyData = { propertyId: propertyId, displayName: displayName }
  let uniqueid = localStorage.getItem("unique_id")
  let createdBy = localStorage.getItem("auth")
  const create = { uniqueId: uniqueid, createdBy: createdBy }
  const [RoomBalcony, setRoomBalcony] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchPopularWithGuests = async () => {
    setLoading(true)
    try {
      const normalData = await Api.get(
        `RAmenitiesPopularWithGuestsvalue/${propertyId}/${displayName}`
      )
      const switchData = await Api.post(
        "RAmenitiesPopularWithGuestsStarredvalue",
        propertyData
      )
      setPopularWithGuests({ ...normalData.data, ...switchData.data })
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    console.log(displayName)
    if (propertyId && expanded == "panel1") {
      fetchPopularWithGuests()
    }
  }, [expanded])

  const RoomSanitizersGet = async () => {
    const roomSanitizersData = {
      type: "Room Sanitizers",
    }

    await Api.post("productdropdownonly", roomSanitizersData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomSanitizers(response.data)
    })
  }

  const RoomRoomServiceGet = async () => {
    const roomServiceData = {
      type: "Room Room Service",
    }

    await Api.post("productdropdownonly", roomServiceData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomRoomService(response.data)
    })
  }

  const RoomInRoomDiningGet = async () => {
    const diningData = {
      type: "Room In Room Dining",
    }

    await Api.post("productdropdownonly", diningData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomInRoomDining(response.data)
    })
  }
  const RoomHouseKeepingGet = async () => {
    const houseData = {
      type: "Room HouseKeeping",
    }

    await Api.post("productdropdownonly", houseData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomHouseKeeping(response.data)
    })
  }
  const RoomHeaterGet = async () => {
    const heaterData = {
      type: "Room Heater",
    }

    await Api.post("productdropdownonly", heaterData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomHeater(response.data)
    })
  }

  const RoomAirConditioningGet = async () => {
    const airData = {
      type: "Room Air Conditioning",
    }

    await Api.post("productdropdownonly", airData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomAirConditioning(response.data)
    })
  }

  const RoomBalconyGet = async () => {
    const balconyData = {
      type: "Room Other Facilities Balcony",
    }
    await Api.post("productdropdownonly", balconyData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomBalcony(response.data)
    })
  }
  useEffect(() => {
    if (propertyId && displayName && expanded === "panel1") {
      RoomAirConditioningGet()
      RoomBalconyGet()
      RoomHeaterGet()
      RoomHouseKeepingGet()
      RoomInRoomDiningGet()
      RoomRoomServiceGet()
      RoomSanitizersGet()
    }
  }, [expanded])

  const popularAmenitiesWithSelect = [
    {
      name: "airConditioning",
      label: "Air Conditioning",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "airConditioningType",
      switchName: "airConditioningStarred",
      dropdDownOptions: RoomAirConditioning.map((option) => ({
        value: option,
        label: option,
      })),
    },

    {
      name: "housekeeping",
      label: "House Keeping",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "housekeepingType",
      switchName: "housekeepingStarred",
      dropdDownOptions: RoomHouseKeeping.map((option) => ({
        value: option,
        label: option,
      })),
    },
    {
      name: "inRoomdining",
      label: "In-Room Dining",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "inRoomdiningType",
      switchName: "inRoomdiningStarred",
      dropdDownOptions: RoomInRoomDining.map((option) => ({
        value: option,
        label: option,
      })),
    },

    {
      name: "laundryService",
      label: "Laundry Service",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "laundryServiceType",
      switchName: "laundryServiceStarred",
      dropdDownOptions: [
        { value: "free", label: "Free" },
        { value: "paid", label: "Paid" },
      ],
    },
    {
      name: "roomService",
      label: "Room Service",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "roomServiceType",
      switchName: "roomServiceStarred",
      dropdDownOptions: RoomRoomService.map((option) => ({
        value: option,
        label: option,
      })),
    },
    {
      name: "wifi",
      label: "Wifi",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "wifiType",
      switchName: "wifiStarred",
      dropdDownOptions: [
        { value: "free", label: "Free" },
        { value: "paid", label: "Paid" },
      ],
    },

    {
      name: "balcony",
      label: "Balcony",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "balconyType",
      switchName: "balconyStarred",
      dropdDownOptions: RoomBalcony.map((option) => ({
        value: option,
        label: option,
      })),
    },

    {
      name: "sanitizers",
      label: "Sanitizers",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "sanitizersType",
      switchName: "sanitizersStarred",
      dropdDownOptions: RoomSanitizers,
    },
  ]

  const popularAmenitiesWithMultipleSelectFields = [
    {
      name: "heater",
      label: "Room Heater",
      select: true,
      selectLabel1: "Type",
      selectLabel2: "Location",
      selectFieldName1: "heaterType",
      selectFieldName2: "heaterType1",
      switchName: "heaterStarred",
      selectType2: "singleValue",
      dropdDownOptions1: [
        { value: "free", label: "Free" },
        { value: "paid", label: "Paid" },
      ],
      dropdDownOptions2: RoomHeater.map((option) => ({
        label: option,
        value: option,
      })),
    },
  ]

  const popularWithGuestsSwitchOnly = [
    {
      name: "jaccuzi",
      label: "Jaccuzi",
      switchName: "jaccuziStarred",
    },
    {
      name: "privatePool",
      label: "Private Pool",
      switchName: "privatePoolStarred",
    },
    {
      name: "terrace",
      label: "Terrace",
      switchName: "terraceStarred",
    },

    {
      name: "interconnectedRoom",
      label: "Interconnected Room",
      switchName: "interconnectedRoomStarred",
    },
    {
      name: "ironIroningBoard",
      label: "Iron-Ironing Board",
      switchName: "ironIroningBoardStarred",
    },
    {
      name: "mineralWater",
      label: "Mineral Water",
      switchName: "mineralWaterStarred",
    },

    {
      name: "smokingRoom",
      label: "Smoking Room",
      switchName: "smokingRoomStarred",
    },
    {
      name: "electricKettle",
      label: "Electric Kettle",
      switchName: "electricKettleStarred",
    },
    {
      name: "airPurifier",
      label: "Air Purifier",
      switchName: "airPurifierStarred",
    },
  ]
  const handleChangeAccordion = () => setExpanded("panel2")

  function onSubmit(fields, { setStatus }) {
    const id = popularWithGuestsData.id

    if (fields.airConditioning == false) {
      fields.airConditioningStarred = false
      fields.airConditioningType = ""
    }
    if (fields.interconnectedRoom == false) {
      fields.interconnectedRoomStarred = false
    }

    if (fields.heater == false) {
      fields.heaterStarred = false
      fields.heaterType = ""
      fields.heaterType1 = ""
    }
    if (fields.housekeeping == false) {
      fields.housekeepingType = ""
      fields.housekeepingStarred = false
    }
    if (fields.inRoomdining == false) {
      fields.inRoomdiningStarred = false
      fields.inRoomdiningType = ""
    }
    if (fields.ironIroningBoard == false) {
      fields.ironIroningBoardStarred = false
    }
    if (fields.laundryService == false) {
      fields.laundryServiceStarred = false
      fields.laundryServiceType = ""
    }

    if (fields.mineralWater == false) {
      fields.mineralWaterStarred = false
    }
    if (fields.roomService == false) {
      fields.roomServiceStarred = false
      fields.roomServiceType = ""
    }
    if (fields.smokingRoom == false) {
      fields.smokingRoomStarred = false
    }
    if (fields.electricKettle == false) {
      fields.electricKettleStarred = false
    }
    if (fields.wifi == false) {
      fields.wifiStarred = false
      fields.wifiType = ""
    }
    if (fields.airPurifier == false) {
      fields.airPurifierStarred = false
    }
    if (fields.sanitizers == false) {
      fields.sanitizersStarred = false
      fields.sanitizersType = []
    }
    if (fields.balcony == false) {
      fields.balconyType = ""
      fields.balconyStarred = false
    }
    if (fields.jaccuzi == false) {
      fields.jaccuziStarred = false
    }
    if (fields.privatePool == false) {
      fields.privatePoolStarred = false
    }

    if (fields.terrace == false) {
      fields.terraceStarred = false
    }
    setStatus()
    if (id) {
      updatePopularGuests(fields)
    } else {
      createPopularGuests(fields)
    }
  }

  function createStarred(fields) {
    fields.propertyId = propertyId
    fields.displayName = displayName
    const createData = { ...fields, ...create }
    Api.post("RAmenitiesPopularWithGuestsStarred", createData)
  }

  function updateStarred(fields) {
    fields.updateBy = createdBy
    Api.put("RAmenitiesPopularWithGuestsStarredupdate", fields)
  }

  function createPopularGuests(fields) {
    setLoading(true)
    fields.propertyId = propertyId
    fields.displayName = displayName
    const createData = { ...fields, ...create }

    Api.post("RAmenitiesPopularWithGuests", createData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      createStarred(fields)
      toast.success("Successfully Created")
      handleChangeAccordion()
      setLoading(false)
    })
  }

  function updatePopularGuests(fields) {
    setLoading(true)
    fields.updateBy = createdBy
    Api.put("RAmenitiesPopularWithGuestsupdate", fields, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      updateStarred(fields)
      toast.success("Successfully Updated")
      handleChangeAccordion()
      setLoading(false)
    })
  }

  return (
    <Formik
      initialValues={popularWithGuestsData || initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue }) =>
        loading ? (
          <ScreenLoader open={loading} />
        ) : (
          <Form autoComplete="off">
            <Accordion
              square
              expanded={expanded === "panel1"}
              onChange={handleChangePanel("panel1")}
              className={classes.accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#f46d25",
                      background: "white",
                      borderRadius: "5px",
                    }}
                  />
                }
                className={classes.accordionSummaryStyles}
              >
                Popular With Guests
              </AccordionSummary>
              <AccordionDetails className={classes.outerSectionStyles}>
                <Grid container spacing={1}>
                  {popularAmenitiesWithSelect.map((amenity) => (
                    <InlineFormikFieldWithCheckSelectAndSwitch
                      amenity={amenity}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                  {popularAmenitiesWithMultipleSelectFields.map((amenity) => (
                    <InlineCheckboxSwitchWithMultipleSelectFields
                      amenity={amenity}
                      values={values}
                    />
                  ))}

                  {popularWithGuestsSwitchOnly.map((amenity) => (
                    <FormikInlineCheckboxAndSwitchOnly
                      amenity={amenity}
                      values={values}
                    />
                  ))}
                </Grid>
              </AccordionDetails>
              <center>
                <Button type="submit" size="small">
                  Save And Next
                </Button>
              </center>
            </Accordion>
          </Form>
        )
      }
    </Formik>
  )
}

export default PopulatWithGuests
