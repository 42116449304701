import { ActionTypes } from "../actions/actionTypes";

const INITIAL_STATE = {
  checkIn: null,
};

export const viewRoomReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ActionTypes.ROOM_CHECK_IN_DATE: {
      console.log(payload);
      return {
        ...state,
        checkIn: payload,
      };
    }

    default:
      return state;
  }
};
