import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import Api from "../../../Service/Api";
import FormikInlineCheckboxAndSwitchOnly from "../../../components/amenityComponents/inlineCheckBoxAndSwitchOnly.component";
import InlineFormikFieldWithCheckSelectAndSwitch from "../../../components/amenityComponents/inlineCheckBoxWithSelectAndSwitch.component";
import ScreenLoader from "../../../components/loadingScreen/screenLoader.component";

const initialValues = {
  events: false,
  eventsStarred: false,
  eventsType: [],
  pub: false,
  pubStarred: false,
  photoSession: false,
  photoSessionStarred: false,
  nightClub: false,
  nightClubStarred: false,
  beachClub: false,
  beachClubStarred: false,
  galadinner: false,
  galadinnerStarred: false,
  casino: false,
  casinoStarred: false,
  casinoType: [],
  indoorgames: false,
  indoorgamesStarred: false,
  indoorgamesType: [],
  beach: false,
  beachType: [],
  beachStarred: false,
  activities: false,
  activitiesType: [],
  activitiesStarred: false,
  picnicArea: false,
  picnicAreaStarred: false,
  gameRoom: false,
  gameRoomStarred: false,
  tv: false,
  tvType: [],
  tvStarred: false,
};

const entertinmentAmenitiesSwitchOnly = [
  {
    name: "pub",
    label: "Pub",
    switchName: "pubStarred",
  },
  {
    name: "photoSession",
    label: "Selfie Point",
    switchName: "photoSessionStarred",
  },
  {
    name: "nightClub",
    label: "Night Club",
    switchName: "nightClubStarred",
  },
  {
    name: "beachClub",
    label: "Beach Club",
    switchName: "beachClubStarred",
  },

  {
    name: "galadinner",
    label: "Gala Dinner",
    switchName: "galadinnerStarred",
  },
  {
    name: "picnicArea",
    label: "Picnic Area",
    switchName: "picnicAreaStarred",
  },

  {
    name: "gameRoom",
    label: "Game Room",
    switchName: "gameRoomStarred",
  },
];

const EntertainmentAmenitites = ({
  classes,
  handleChangePanel,
  expanded,
  setExpanded,
}) => {
  const { propertyId } = useParams();
  const [entertainmentAmenities, setEntertainmentAmenities] = useState(
    () => {}
  );
  const [casinos, setCasinos] = useState([]);
  const [indoorGames, setIndoorGames] = useState([]);
  const [event, setEvent] = useState([]);
  const [beach, setBeach] = useState([]);
  const [tvs, setTvs] = useState([]);
  const [activitieDrop, setActivitieDrop] = useState([]);
  const [loading, setLoading] = useState(() => false);

  const fetchEntertainmentAmenities = async () => {
    setLoading(true);
    try {
      const normalData = await Api.get(
        `AmenitiesEntertainmentvalue/${propertyId}`
      );
      const switchData = await Api.get(
        `AmenitiesEntertainmentStarredvalue/${propertyId}`
      );

      console.log(normalData.data);
      console.log(switchData.data);

      setEntertainmentAmenities({ ...normalData.data, ...switchData.data });
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const Tvs = async () => {
    const tvsData = {
      type: "Tv",
    };
    await Api.post("productdropdownonly", tvsData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setTvs(response.data);
    });
  };

  const Events = async () => {
    const EventsData = {
      type: "Events",
    };
    await Api.post("productdropdownonly", EventsData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setEvent(response.data);
    });
  };
  const Casinos = async () => {
    const CasinosData = {
      type: "Casino",
    };
    await Api.post("productdropdownonly", CasinosData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setCasinos(response.data);
    });
  };
  const IndoorGames = async () => {
    const IndoorGamesData = {
      type: "Indoor games",
    };
    await Api.post("productdropdownonly", IndoorGamesData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      setIndoorGames(res.data);
    });
  };
  const Beachs = async () => {
    const BeachData = {
      type: "Beach",
    };
    await Api.post("productdropdownonly", BeachData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setBeach(response.data);
    });
  };

  const Activities = async () => {
    const ActivitiesData = {
      type: "Activities",
    };
    await Api.post("productdropdownonly", ActivitiesData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setActivitieDrop(response.data);
    });
  };

  const handleChangeAccordion = () => {
    setExpanded("panel8");
  };

  useEffect(() => {
    if (propertyId && expanded === "panel7") {
      Events();
      IndoorGames();
      Casinos();
      Beachs();
      Tvs();
      Activities();
    }
  }, [expanded]);

  useEffect(() => {
    if (propertyId && expanded === "panel7") {
      fetchEntertainmentAmenities();
    }
  }, [expanded]);

  const entertainmentAmenitiesWithSelectField = [
    {
      name: "events",
      label: "Events",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "eventsType",
      switchName: "eventsStarred",
      dropdDownOptions: event,
    },

    {
      name: "casino",
      label: "Casino",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "casinoType",
      switchName: "casinoStarred",
      dropdDownOptions: casinos,
    },

    {
      name: "indoorgames",
      label: "Indoor Games",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "indoorgamesType",
      switchName: "indoorgamesStarred",
      dropdDownOptions: indoorGames,
    },

    {
      name: "beach",
      label: "Beach",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "beachType",
      switchName: "beachStarred",
      dropdDownOptions: beach,
    },

    {
      name: "activities",
      label: "Activities",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "activitiesType",
      switchName: "activitiesStarred",
      dropdDownOptions: activitieDrop,
    },

    {
      name: "tv",
      label: "TV",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "tvType",
      switchName: "tvStarred",
      dropdDownOptions: tvs,
    },
  ];

  const uniqueid = localStorage.getItem("unique_id");
  const createdBy = localStorage.getItem("auth");
  const create = { uniqueId: uniqueid, createdBy: createdBy };

  function onSubmit(fields, { setStatus }) {
    const id = entertainmentAmenities.id;

    if (fields.events == false) {
      fields.eventsStarred = false;
      fields.eventsType = [];
    }
    if (fields.casino == false) {
      fields.casinoStarred = false;
      fields.casinoType = [];
    }
    if (fields.indoorgames == false) {
      fields.indoorgamesStarred = false;
      fields.indoorgamesType = [];
    }

    if (fields.beach == false) {
      fields.beachType = [];
      fields.beachStarred = false;
    }

    if (fields.activities == false) {
      fields.activitiesType = [];
      fields.activitiesStarred = false;
    }
    if (fields.tv == false) {
      fields.tvType = [];
      fields.tvStarred = false;
    }

    if (fields.pub == false) {
      fields.pubStarred = false;
    }
    if (fields.photoSession == false) {
      fields.photoSessionStarred = false;
    }
    if (fields.nightClub == false) {
      fields.nightClubStarred = false;
    }
    if (fields.beachClub == false) {
      fields.beachClubStarred = false;
    }
    if (fields.galadinner == false) {
      fields.galadinnerStarred = false;
    }

    if (fields.vehicleRentals == false) {
      fields.vehicleRentalsType = "";
      fields.vehicleRentalsType1 = "";
      fields.vehicleRentalsStarred = false;
    }

    if (fields.picnicArea == false) {
      fields.picnicAreaStarred = false;
    }
    if (fields.gameRoom == false) {
      fields.gameRoomStarred = false;
    }

    setStatus();
    if (id) {
      updateEntertainment(fields);
    } else {
      createEntertainment(fields);
    }
  }

  function createStarred(fields) {
    fields.propertyId = propertyId;
    const newData = { ...fields, ...create };
    Api.post("AmenitiesEntertainmentStarred", newData);
  }
  function updateStarred(fields) {
    fields.updateBy = createdBy;
    Api.put("AmenitiesEntertainmentStarredupdate", fields);
  }

  function createEntertainment(fields) {
    setLoading(true);
    fields.propertyId = propertyId;

    const newData = { ...fields, ...create };
    Api.post("AmenitiesEntertainment", newData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      if (res.status === 200) {
        createStarred(fields);
        toast.success("Successfully Created");
        handleChangeAccordion();
        setLoading(false);
      }
    });
  }

  function updateEntertainment(fields) {
    setLoading(true);
    fields.updateBy = createdBy;
    Api.put("AmenitiesEntertainmentupdate", fields).then((res) => {
      if (res.status === 200) {
        updateStarred(fields);
        toast.success("Successfully Updated");
        handleChangeAccordion();
        setLoading(false);
      }
    });
  }

  return (
    <Formik
      initialValues={entertainmentAmenities ?? initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue }) =>
        loading ? (
          <ScreenLoader open={loading} />
        ) : (
          <Form autoComplete="off">
            <Accordion
              square
              expanded={expanded === "panel7"}
              onChange={handleChangePanel("panel7")}
              className={classes.accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#f46d25",
                      background: "white",
                      borderRadius: "5px",
                    }}
                  />
                }
                className={classes.accordionSummaryStyles}
              >
                Entertainment
              </AccordionSummary>
              <AccordionDetails className={classes.outerSectionStyles}>
                <Grid container spacing={3}>
                  {entertainmentAmenitiesWithSelectField.map((amenity) => (
                    <InlineFormikFieldWithCheckSelectAndSwitch
                      amenity={amenity}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ))}

                  {/**Entertainment switch only */}
                  {entertinmentAmenitiesSwitchOnly.map((amenity) => (
                    <FormikInlineCheckboxAndSwitchOnly
                      amenity={amenity}
                      values={values}
                    />
                  ))}
                </Grid>
              </AccordionDetails>
              <center>
                <Button
                  style={{ height: "30px", margin: "10px 0px" }}
                  type="submit"
                >
                  Save and Next
                </Button>
              </center>
            </Accordion>
          </Form>
        )
      }
    </Formik>
  );
};

export default EntertainmentAmenitites;
