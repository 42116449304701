import { Grow, Modal, makeStyles } from "@material-ui/core"
import ReactPlayer from "react-player/youtube"

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  popUp: {
    transform: "scale(1,1)",
    transition: "all 3s",
  },
  popOut: {
    transform: "scale(0,0)",
    transition: "all 3s",
  },
}))

const VideoPlayerModal = (props) => {
  const styles = useStyles()
  const { open, handleClose, url } = props
  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={styles.modal}
      BackdropProps={{
        style: { background: "none", backdropFilter: "blur(0.5rem)" },
        timeout: 500,
      }}
      closeAfterTransition
    >
      <Grow in={open}>
        <ReactPlayer url={url} controls />
      </Grow>
    </Modal>
  )
}

export default VideoPlayerModal
