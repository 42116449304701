import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button } from "@material-ui/core";
import XLSX from "xlsx";
import Select from "react-select";
import _ from "lodash";
import { differenceInDays, format, parse } from "date-fns";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

const bookingSourceOptions = {
  hotelWebsiteChat: "Hotel Website Chat",
  justDial: "JustDial",
  agentB2B: "B2B Agent",
  fit: "FIT",
  socialMedia: "Social Media",
  incomingCall: "Incoming Call",
  holidayIq: "Holiday Iq",
  tripoto: "Tripoto",
};

const FinanceOpition = [
  { value: "bookingId", label: "Booking Id" },
  { value: "bookingStatus", label: "Booking Status" },
  { value: "clientName", label: "Client Name" },
  { value: "clientMobile", label: "Client Mobile" },
  { value: "clientEmail", label: "Client Email" },
  { value: "destinationCity", label: "Destination City" },
  { value: "bookingSource", label: "Booking Source" },
  { value: "hotelName", label: "Hotel Name" },
  { value: "hotelPhone", label: "Hotel Phone" },
  { value: "starRating", label: "Star Rating " },
  { value: "hotelAddress", label: "Hotel Address " },
  { value: "hotelEmail", label: "Hotel Email " },
  { value: "checkIn", label: "Check-In " },
  { value: "checkOut", label: "Check-Out " },
  { value: "noOfNight", label: "No of Nights " },
  { value: "amountRefunded", label: "Amount Refunded " },
  { value: "amountRetained", label: "Amount Retained " },
  { value: "revisedCommission", label: "Revised Commission " },
  { value: "totalRoomNetAmount", label: "Net Amount " },
  { value: "totalRoomSellingAmount", label: "Selling Amount " },
  { value: "totalInclusionAmount", label: "Inclusion Amount " },
  { value: "totalVendorAmount", label: "vendor Amount " },
  { value: "totalBookingAmount", label: "Booking Amount " },
  { value: "paidAmount", label: "Paid Amount " },
  { value: "profit", label: "Profit " },
  { value: "pendingAmount", label: "Pending Amount " },
  { value: "balancePayableHotel", label: "Balance Payable to Hotel " },
  { value: "vaccinated", label: "Vaccinated " },
  { value: "git", label: "Git " },
  { value: "partialPayment", label: "Partial Payment " },
  { value: "paymentType", label: "Payment Type " },
  { value: "createdAt", label: "Created At " },
  { value: "createdBy", label: "Created By " },
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  background: "#F46D25",
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: "#F46D25",
  color: "#fff",
  fontSize: "12px",
  padding: "5px",
  lineHeight: "1rem",
  minWidth: "100px",
}));

const StyledTableCellInside = styled(TableCell)(({ theme }) => ({
  padding: "5px",
  fontSize: "12px",
  lineHeight: "15px",
}));

const columns = [
  { value: "bookingId", label: "Booking Id" },
  { value: "bookingStatus", label: "Booking Status" },
  { value: "clientName", label: "Client Name" },
  { value: "clientMobile", label: "Client Mobile" },
  { value: "clientEmail", label: "Client Email" },
  { value: "destinationCity", label: "Destination City" },
  { value: "bookingSource", label: "Booking Source" },
  { value: "hotelName", label: "Hotel Name" },
  { value: "hotelPhone", label: "Hotel Phone" },
  { value: "starRating", label: "Star Rating " },
  { value: "hotelAddress", label: "Hotel Address " },
  { value: "hotelEmail", label: "Hotel Email " },
  { value: "checkIn", label: "Check-In " },
  { value: "checkOut", label: "Check-Out " },
  { value: "noOfNight", label: "No of Nights " },
  { value: "amountRefunded", label: "Amount Refunded " },
  { value: "amountRetained", label: "Amount Retained " },
  { value: "revisedCommission", label: "Revised Commission " },
  { value: "totalRoomNetAmount", label: "Net Amount " },
  { value: "totalRoomSellingAmount", label: "Selling Amount " },
  { value: "totalInclusionAmount", label: "Inclusion Amount " },
  { value: "totalVendorAmount", label: "vendor Amount " },
  { value: "totalBookingAmount", label: "Booking Amount " },
  { value: "paidAmount", label: "Paid Amount " },
  { value: "profit", label: "Profit " },
  { value: "pendingAmount", label: "Pending Amount " },
  { value: "balancePayableHotel", label: "Balance Payable to Hotel " },
  { value: "vaccinated", label: "Vaccinated " },
  { value: "git", label: "Git " },
  { value: "partialPayment", label: "Partial Payment " },
  { value: "paymentType", label: "Payment Type " },
  { value: "createdAt", label: "Created At " },
  { value: "createdBy", label: "Created By " },
];

export default function AgentFinaceReport(props) {
  const [finalReport, setFinalReport] = useState(props.reportData);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(null);

  useEffect(() => {
    setFinalReport(props.reportData);
  }, [props]);

  const HandleSort = (sort) => {
    setSort(sort);
    let sorted = _.orderBy(finalReport, [sort.value], ["asec"]);
    setFinalReport(sorted);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const downloadExcel = () => {
    const newData = finalReport.map((row) => {
      delete row.id;
      return row;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Finance");
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "Finance.xlsx");
  };

  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item md={8}>
          <b>Finance Report</b>
        </Grid>
        <Grid item md={2}>
          <div style={{ position: "relative" }}>
            <span
              style={{
                position: "absolute",
                top: "-9px",
                zIndex: "1",
                left: "12px",
                color: "#fff",
                background: "#F46D25",
                borderRadius: "4px",
                padding: "1px 4px",
                fontSize: "12px",
              }}
            >
              Sort By
            </span>
            <Select
              options={FinanceOpition}
              placeholder=""
              value={sort}
              onChange={HandleSort}
              styles={{
                container: (provided) => ({
                  ...provided,
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9,
                }),
                control: (base, state) => ({
                  ...base,
                  "&:hover": { borderColor: "#f46d25" },
                  borderColor: "#f46d25",
                  boxShadow: "none",
                }),
              }}
            />
          </div>
        </Grid>
        <Grid item md={2} style={{ textAlign: "right" }}>
          <Button size="small" onClick={downloadExcel}>
            Download As Excel
          </Button>
        </Grid>
      </Grid>

      {/* <Paper sx={{ width: "100%", overflow: "hidden" }}> */}
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <StyledTableRow>
              {columns.map((column) => (
                <StyledTableCell key={column.id} align={column.align}>
                  {column.label}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {finalReport
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow key={row.id}>
                    <StyledTableCellInside
                      style={{ textTransform: "capitalize" }}
                    >
                      {row.bookingId}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.bookingStatus}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.clientName}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.clientMobile}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.clientEmail}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.destinationCity}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {bookingSourceOptions[row.bookingSource]}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.hotelName}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.hotelPhone}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.starRating}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.hotelAddress}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.hotelEmail}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.checkIn ?? null}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.checkOut ?? null}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.noOfNight}
                      {/* {row.checkOut?differenceInDays(parse(row.checkOut,"dd-MM-yyyy",new Date()),parse(row.checkIn,"dd-MM-yyyy",new Date())):null} */}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.amountRefunded}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.amountRetained}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.revisedCommission}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.totalRoomNetAmount}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.totalRoomSellingAmount}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.totalInclusionAmount}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.totalVendorAmount}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.totalBookingAmount}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.paidAmount}
                    </StyledTableCellInside>
                    <StyledTableCellInside>{row.profit}</StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.pendingAmount}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.balancePayableHotel}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.vaccinated ? (
                        <CheckCircleIcon style={{ color: "#f46d25" }} />
                      ) : (
                        <CancelIcon style={{ color: "#666" }} />
                      )}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.git ? (
                        <CheckCircleIcon style={{ color: "#f46d25" }} />
                      ) : (
                        <CancelIcon style={{ color: "#666" }} />
                      )}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.partialPayment}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.paymentType}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.createdAt
                        ? format(
                            parse(
                              row.createdAt,
                              "yyyy-MM-dd HH:mm:ss",
                              new Date()
                            ),
                            "dd-MM-yyyy HH:mm:ss"
                          )
                        : null}
                    </StyledTableCellInside>
                    <StyledTableCellInside>
                      {row.createdBy}
                    </StyledTableCellInside>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={finalReport.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* </Paper> */}
    </div>
  );
}
