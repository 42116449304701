import { Button, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../Service/Api";
import { baseurl } from "../../Service/httpCommon";

const useStyles = makeStyles({
  root: {
    padding: "5% 1.5% 1% 1.5%",
    margin: "0",
    fontSize: "2rem",
  },
  invoiceNo: {
    color: "#f46d25",
    fontSize: "1.3rem",
    fontWeight: "bolder",
  },
  sectionHeader: {
    fontSize: window.innerWidth <= 768 ? "18px" : "25px",
    marginTop: "1.5rem",
    color: "white",
    background: "#f46d25",
    padding: "10px",
    fontWeight: "bold",
    borderRadius:
      window.innerWidth <= 768 ? "5px 5px 0px 0px" : "10px 10px 0px 0px",
  },
  title: {
    padding: "10px",
    color: "#f46d25",
    fontSize: "20px",
    fontWeight: "bold",
  },
  key: {
    fontSize: "15px",
    padding: "5px 10px 10px 10px",
    fontWeight: "bold",
  },
});

const ViewGstInvoice = () => {
  const classes = useStyles();
  let uniqueId = localStorage.getItem("unique_id");
  const { invoiceNo } = useParams();
  const [viewInvoice, setViewInvoice] = useState(() => {});

  const fectchData = async () => {
    try {
      const response = await Api.get(
        `${baseurl}gstdetails/${uniqueId}/${invoiceNo}`
      );

      console.log(response.data);
      setViewInvoice(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (invoiceNo) {
      console.log(invoiceNo);
      fectchData();
    }
  }, [invoiceNo]);

  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [width]);

  const history = useHistory();

  return (
    <div className={classes.root}>
      {width <= 768 ? (
        <div style={{ padding: "18% 2%" }}>
          <Button
            style={{
              fontWeight: "bolder",
              marginBottom: "10px",
              width: "30px",
              height: "30px",
            }}
            onClick={() => history.goBack()}
          >{`<`}</Button>
          <div
            style={{
              display: "flex",
              background: "#343A40",
              height: "fit-content",
              padding: "2%",
              borderRadius: "5px",
              marginBottom: "4%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ fontSize: "20px", color: "white", fontWeight: "bold" }}
            >
              GST Invoice
            </div>
            <div
              style={{
                fontSize: "15px",
                background: "white",
                color: "#f46d25",
                borderRadius: "5px",
                padding: "1%",
                fontWeight: "bold",
              }}
            >
              {invoiceNo}
            </div>
          </div>
          <>
            <Typography className={classes.sectionHeader}>Billed By</Typography>
            <Paper>
              <Grid container padding="10px">
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  Business Name
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ color: "#f46d25", fontSize: "16px" }}
                >
                  :
                </Grid>
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  {viewInvoice?.gstBilledBy?.businessName}
                </Grid>
              </Grid>
              <Grid container padding="10px">
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  Email
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ color: "#f46d25", fontSize: "16px" }}
                >
                  :
                </Grid>
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  {viewInvoice?.gstBilledBy?.billerEmail}
                </Grid>
              </Grid>
              <Grid container padding="10px">
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  Mobile No.
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ color: "#f46d25", fontSize: "16px" }}
                >
                  :
                </Grid>
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  {viewInvoice?.gstBilledBy?.billerContactNo}
                </Grid>
              </Grid>
              <Grid container padding="10px">
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  Due Date
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ color: "#f46d25", fontSize: "16px" }}
                >
                  :
                </Grid>
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  {viewInvoice?.gstBilledBy?.dueDate}
                </Grid>
              </Grid>
              <Grid container padding="10px">
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  Pan No
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ color: "#f46d25", fontSize: "16px" }}
                >
                  :
                </Grid>
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  {viewInvoice?.gstBilledBy?.panNo}
                </Grid>
              </Grid>
              <Grid container padding="10px">
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  Pincode
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ color: "#f46d25", fontSize: "16px" }}
                >
                  :
                </Grid>
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  {viewInvoice?.gstBilledBy?.pinCode}
                </Grid>
              </Grid>
              <Grid container padding="10px">
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  State
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ color: "#f46d25", fontSize: "16px" }}
                >
                  :
                </Grid>
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  {viewInvoice?.gstBilledBy?.state}
                </Grid>
              </Grid>
              <Grid container padding="10px">
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  Shipping Address
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ color: "#f46d25", fontSize: "16px" }}
                >
                  :
                </Grid>
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  {viewInvoice?.gstBilledBy?.shippingAddress}
                </Grid>
              </Grid>
            </Paper>
          </>
          <>
            <Typography className={classes.sectionHeader}>Billed By</Typography>
            <Paper>
              <Grid container padding="10px">
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  Client Name
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ color: "#f46d25", fontSize: "16px" }}
                >
                  :
                </Grid>
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  {viewInvoice?.gstBilledTo?.clientName}
                </Grid>
              </Grid>
              <Grid container padding="10px">
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  Email
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    color: "#f46d25",
                    fontSize: "16px",
                  }}
                >
                  :
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{ fontSize: "16px", wordWrap: "break-word" }}
                >
                  {viewInvoice?.gstBilledTo?.clientEmail}
                </Grid>
              </Grid>
              <Grid container padding="10px">
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  Mobile No.
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ color: "#f46d25", fontSize: "16px" }}
                >
                  :
                </Grid>
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  {viewInvoice?.gstBilledTo?.clientNumber}
                </Grid>
              </Grid>
              <Grid container padding="10px">
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  GST No.
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ color: "#f46d25", fontSize: "16px" }}
                >
                  :
                </Grid>
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  {viewInvoice?.gstBilledTo?.gstNo}
                </Grid>
              </Grid>
              <Grid container padding="10px">
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  Pan No
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ color: "#f46d25", fontSize: "16px" }}
                >
                  :
                </Grid>
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  {viewInvoice?.gstBilledTo?.clientPanNo}
                </Grid>
              </Grid>
              <Grid container padding="10px">
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  Pincode
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ color: "#f46d25", fontSize: "16px" }}
                >
                  :
                </Grid>
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  {viewInvoice?.gstBilledTo?.clientPinCode}
                </Grid>
              </Grid>
              <Grid container padding="10px">
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  State
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ color: "#f46d25", fontSize: "16px" }}
                >
                  :
                </Grid>
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  {viewInvoice?.gstBilledTo?.clientState}
                </Grid>
              </Grid>
              <Grid container padding="10px">
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  Billing Address
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ color: "#f46d25", fontSize: "16px" }}
                >
                  :
                </Grid>
                <Grid item xs={5} style={{ fontSize: "16px" }}>
                  {viewInvoice?.gstBilledTo?.billingAddress}
                </Grid>
              </Grid>
            </Paper>
          </>
          <>
            <Typography className={classes.sectionHeader}>
              Billing Info
            </Typography>
            <Paper>
              {viewInvoice?.gstBillingInformation?.map((bill, idx) => (
                <>
                  <>
                    <Grid container alignItems="baseline">
                      <Grid item xs={5} className={classes.key}>
                        Item Description
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={6} fontSize="15px">
                        {bill?.bookingItem}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={5} className={classes.key}>
                        HSN/SASC Code
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={6} fontSize="15px">
                        {bill?.code}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={5} className={classes.key}>
                        Rate
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={6} fontSize="15px">
                        {bill?.rate}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={5} className={classes.key}>
                        Quantity
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={6} fontSize="15px">
                        {bill?.quantity}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={5} className={classes.key}>
                        Taxable Amount
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={6} fontSize="15px">
                        {bill?.taxableAmt}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={5} className={classes.key}>
                        Tax %
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={6} fontSize="15px">
                        {parseFloat(bill?.taxPercentValue) * 100}%
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={5} className={classes.key}>
                        SGST
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={6} fontSize="15px">
                        {bill?.sgst}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={5} className={classes.key}>
                        CGST
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={6} fontSize="15px">
                        {bill?.cgst}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={5} className={classes.key}>
                        Total Tax
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={6} fontSize="15px">
                        {parseFloat(bill?.taxPercentValue) *
                          parseFloat(bill?.rate)}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={5} className={classes.key}>
                        Amount Inc. GST
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={6} fontSize="15px">
                        {bill?.amountIncGst}
                      </Grid>
                    </Grid>
                  </>
                  {idx < viewInvoice?.gstBillingInformation?.length - 1 && (
                    <hr
                      style={{
                        border: "0.1px solid #f46d25",
                        margin: "10px 10px",
                      }}
                    ></hr>
                  )}
                </>
              ))}
            </Paper>
            <>
              <Typography className={classes.sectionHeader}>
                Terms And Conditions
              </Typography>
              <Paper style={{ padding: "10px", fontSize: "15px" }}>
                {viewInvoice?.termsAndCondition}
              </Paper>
            </>
            <>
              <Typography className={classes.sectionHeader}>Notes</Typography>
              <Paper style={{ padding: "10px", fontSize: "15px" }}>
                {viewInvoice?.addNotes}
              </Paper>
            </>
          </>
        </div>
      ) : (
        <>
          <Typography className={classes.invoiceNo}>#{invoiceNo}</Typography>
          <>
            <Typography className={classes.sectionHeader}>
              Billing Details
            </Typography>
            <Paper>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className={classes.title}>Billed By</Typography>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      Hotel Name
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={3} fontSize="15px">
                      {viewInvoice?.gstBilledBy?.businessName}
                    </Grid>
                  </Grid>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      Email
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={3} fontSize="15px">
                      {viewInvoice?.gstBilledBy?.billerEmail}
                    </Grid>
                  </Grid>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      Mobile No.
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={3} fontSize="15px">
                      {viewInvoice?.gstBilledBy?.billerContactNo}
                    </Grid>
                  </Grid>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      Billing Date
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={3} fontSize="15px">
                      {viewInvoice?.gstBilledBy?.billingDate}
                    </Grid>
                  </Grid>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      Due Date
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={3} fontSize="15px">
                      {viewInvoice?.gstBilledBy?.dueDate}
                    </Grid>
                  </Grid>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      Pan No.
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={3} fontSize="15px">
                      {viewInvoice?.gstBilledBy?.panNo}
                    </Grid>
                  </Grid>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      Pincode
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={3} fontSize="15px">
                      {viewInvoice?.gstBilledBy?.pinCode}
                    </Grid>
                  </Grid>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      State
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={3} fontSize="15px">
                      {viewInvoice?.gstBilledBy?.state}
                    </Grid>
                  </Grid>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      Shipping Address
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={4} fontSize="15px">
                      {viewInvoice?.gstBilledBy?.shippingAddress}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Typography className={classes.title}>Billed To</Typography>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      Client Name
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={3} fontSize="15px">
                      <span style={{ width: "100%" }}>
                        {viewInvoice?.gstBilledTo?.clientName}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      Email
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={3} fontSize="15px">
                      <span style={{ width: "100%" }}>
                        {viewInvoice?.gstBilledTo?.clientEmail}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      Booking ID
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={3} fontSize="15px">
                      <span style={{ width: "100%" }}>
                        {viewInvoice?.bookingId}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      Mobile No.
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={3} fontSize="15px">
                      <span style={{ width: "100%" }}>
                        {viewInvoice?.gstBilledTo?.clientNumber}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      GST No.
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={3} fontSize="15px">
                      <span style={{ width: "100%" }}>
                        {viewInvoice?.gstBilledTo?.gstNo}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      Pan No.
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={3} fontSize="15px">
                      <span style={{ width: "100%" }}>
                        {viewInvoice?.gstBilledTo?.clientPanNo}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      Pincode
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={3} fontSize="15px">
                      <span style={{ width: "100%" }}>
                        {" "}
                        {viewInvoice?.gstBilledTo?.clientPinCode}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      State
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={3} fontSize="15px">
                      <span style={{ width: "100%" }}>
                        {viewInvoice?.gstBilledTo?.clientState}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="baseline">
                    <Grid item xs={3} className={classes.key}>
                      Billing Address
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      color="#f46d25"
                      fontSize="15px"
                      fontWeight="bold"
                    >
                      :
                    </Grid>
                    <Grid item xs={4} fontSize="15px">
                      <span style={{ width: "100%" }}>
                        {viewInvoice?.gstBilledTo?.billingAddress}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Typography className={classes.sectionHeader}>
              Billing Info
            </Typography>
            <Paper>
              {viewInvoice?.gstBillingInformation?.map((bill, idx) => (
                <>
                  <>
                    <Grid container alignItems="baseline">
                      <Grid item xs={2} className={classes.key}>
                        Item Description
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={9} fontSize="15px">
                        {bill?.bookingItem}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={2} className={classes.key}>
                        HSN/SASC Code
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={9} fontSize="15px">
                        {bill?.code}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={2} className={classes.key}>
                        Rate
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={9} fontSize="15px">
                        {bill?.rate}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={2} className={classes.key}>
                        Quantity
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={9} fontSize="15px">
                        {bill?.quantity}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={2} className={classes.key}>
                        Taxable Amount
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={9} fontSize="15px">
                        {bill?.taxableAmt}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={2} className={classes.key}>
                        Tax %
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={9} fontSize="15px">
                        {parseFloat(bill?.taxPercentValue) * 100}%
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={2} className={classes.key}>
                        SGST
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={9} fontSize="15px">
                        {bill?.sgst}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={2} className={classes.key}>
                        CGST
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={9} fontSize="15px">
                        {bill?.cgst}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={2} className={classes.key}>
                        Total Tax
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={9} fontSize="15px">
                        {parseFloat(bill?.taxPercentValue) *
                          parseFloat(bill?.rate)}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="baseline">
                      <Grid item xs={2} className={classes.key}>
                        Amount Inc. GST
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        color="#f46d25"
                        fontSize="15px"
                        fontWeight="bold"
                      >
                        :
                      </Grid>
                      <Grid item xs={9} fontSize="15px">
                        {bill?.amountIncGst}
                      </Grid>
                    </Grid>
                  </>
                  {idx < viewInvoice?.gstBillingInformation?.length - 1 && (
                    <hr
                      style={{
                        border: "0.1px solid #f46d25",
                        margin: "10px 10px",
                      }}
                    ></hr>
                  )}
                </>
              ))}
            </Paper>
            <>
              <Typography className={classes.sectionHeader}>
                Terms And Conditions
              </Typography>
              <Paper style={{ padding: "10px", fontSize: "15px" }}>
                {viewInvoice?.termsAndCondition}
              </Paper>
            </>
            <>
              <Typography className={classes.sectionHeader}>Notes</Typography>
              <Paper style={{ padding: "10px", fontSize: "15px" }}>
                {viewInvoice?.addNotes}
              </Paper>
            </>
          </>
        </>
      )}
    </div>
  );
};

export default ViewGstInvoice;
