import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slide,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  makeStyles,
  styled,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Api from "../../../Service/Api";
import { baseurl } from "../../../Service/httpCommon";

import { filter, transform } from "lodash";

import { twnButtonStyles } from "../../../utils/townoStyle";
import Loader from "../../../components/Loader/Loader";
import HotelBookingListTable from "./hotelBookingListTable";
import TownoBookingListTable from "./townoBookingListTable";
import { Grid } from "@mui/material";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { addDays, format, subDays } from "date-fns";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

{
  /*Function for sorting records starts*/
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_hotelBooking) =>
        _hotelBooking.clientName.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _hotelBooking.bookingId.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _hotelBooking.createdBy.toLowerCase().indexOf(query.toLowerCase()) !==
          -1 ||
        _hotelBooking.clientEmail.toLowerCase().indexOf(query.toLowerCase()) !==
          -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
{
  /*Function for sorting records ends*/
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  background: "#F46D25",
  height: "40px",
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: "#F46D25",
  color: "#fff",
  fontSize: "12px",
  padding: "5px",
  lineHeight: "1rem",
  minWidth: "100px",
}));

const StyledTableCellInside = styled(TableCell)(({ theme }) => ({
  padding: "5px",
  fontSize: "14px",
  lineHeight: "15px",
}));

const HotelBookingList = () => {
  const classes = useStyles();
  const history = useHistory();
  const [hotelBookingList, setHotelBookingList] = useState([]);
  var uniqueId = localStorage.getItem("unique_id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(() => false);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [width]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };

  const tableHeadValues = [
    "Booking ID",
    "Client Name",
    "Check-In",
    "Check-Out",
    "Agent Name",
    "Nights",
    "Action",
  ];

  const [searchToolbar, setSearchToolbar] = useState(() => ({
    searchByBookingId: "",
    searchByAgentName: "",
    searchByMailId: "",
    searchByPhoneNumber: "",
    searchByClientName: "",
    searchByAgentName: "",
    bookingDateFrom: null,
    bookingDateTo: null,
  }));

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [textToSearch, setTextToSearch] = useState("");
  const [tableType, setTableType] = useState(() => "bookedByHotel");
  const [agentDropDown, setAgentDropDown] = useState(() => []);
  const [errorFrom, setErrorFrom] = useState(false);
  const [errorTo, setErrorTo] = useState(false);

  const tableTypeSwapper = (e, value) => {
    setTableType(value);
    setSearchToolbar({
      ...searchToolbar,
      searchByAgentName: "",
      searchByBookingId: "",
      searchByClientName: "",
      searchByMailId: "",
      searchByPhoneNumber: "",
      bookingDateFrom: null,
      bookingDateTo: null,
    });
    setTextToSearch("");
  };

  const fetchListData = async () => {
    setLoading(() => true);
    let url =
      tableType === "bookedByHotel"
        ? `getHotelBooking`
        : `getHotelBookingFromTowno`;
    try {
      const response = await Api.get(`${url}/${uniqueId}`);
      console.log(response.data);
      setHotelBookingList(response.data);

      if (tableType !== "bookedByHotel") {
        const { data } = await Api.get(
          `agentListForHotelLoginDropdown/${uniqueId}`
        );
        console.log(data);
        const agentDropdownOptions = data.map((agent) => ({
          label: agent,
          value: agent,
        }));

        setAgentDropDown(agentDropdownOptions);
      }

      setLoading(() => false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchListData();
  }, [tableType]);

  const filteredHotelBookingList = applySortFilter(
    hotelBookingList,
    getComparator(order, orderBy),
    textToSearch
  );

  const searchBarHandler = (e) => {
    const { name, value } = e.target;
    setSearchToolbar({ ...searchToolbar, [name]: value });
  };

  const handleSubmitSearch = async (e) => {
    e.preventDefault();
    if (searchToolbar.bookingDateFrom !== null) {
      if (searchToolbar.bookingDateTo == null) {
        setErrorTo(true);
      } else {
        setErrorTo(false);
      }
    }
    if (searchToolbar.bookingDateTo !== null) {
      if (searchToolbar.bookingDateFrom == null) {
        setErrorFrom(true);
      } else {
        setErrorFrom(false);
      }
    }

    if (
      searchToolbar.bookingDateFrom !== null &&
      searchToolbar.bookingDateTo !== null
    ) {
      setLoading(true);
      let url =
        tableType === "bookedByHotel"
          ? "getHotelBookingOfDaterange"
          : "getHotelBookingFromTownoOfDaterange";
      const response = await Api.get(
        `${baseurl}${url}/${uniqueId}/${searchToolbar.bookingDateFrom}/${searchToolbar.bookingDateTo}`
      );

      console.log(response.data);
      setHotelBookingList(response.data);
      setLoading(false);
    }

    setTextToSearch(
      searchByAgentName ||
        searchByBookingId ||
        searchByClientName ||
        searchByPhoneNumber ||
        searchByMailId
    );
  };

  const {
    searchByAgentName,
    searchByBookingId,
    searchByClientName,
    searchByPhoneNumber,
    searchByMailId,
  } = searchToolbar;

  return (
    <div style={twnButtonStyles.allPages}>
      <Paper
        style={{ margin: "10px 0px", padding: "10px", background: "#eaeaea" }}
      >
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              name="searchByBookingId"
              label="Booking ID"
              value={searchToolbar.searchByBookingId}
              onChange={searchBarHandler}
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="searchByClientName"
              label="Client Name"
              value={searchToolbar.searchByClientName}
              onChange={searchBarHandler}
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="searchByMailId"
              label="Email"
              value={searchToolbar.searchByMailId}
              onChange={searchBarHandler}
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="searchByPhoneNumber"
              label="Mobile No"
              value={searchToolbar.searchByPhoneNumber}
              onChange={searchBarHandler}
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
          {tableType === "townoBookings" && (
            <Grid item xs={3}>
              <FormControl
                variant="outlined"
                style={{ width: "100%", height: "40px", background: "white" }}
              >
                <InputLabel
                  shrink
                  style={{
                    backgroundColor: "white",
                    color: "#f46d25",
                    padding: "0px 5px 5px 5px",
                    borderRadius: "5px",
                  }}
                >
                  Agent Name
                </InputLabel>
                <Select
                  name="searchByAgentName"
                  value={searchToolbar.searchByAgentName}
                  label="Tax %"
                  style={{
                    height: "40px",
                    width: "100%",
                    fontSize: "13px",
                  }}
                  onChange={searchBarHandler}
                >
                  {agentDropDown.map((agent) => (
                    <MenuItem value={agent.value} style={{ fontSize: "13px" }}>
                      {agent.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item md={3}>
              <div style={{ position: "relative" }}>
                <DatePicker
                  label="Booking Date From"
                  inputVariant="outlined"
                  fullWidth
                  size="small"
                  animateYearScrolling
                  format="dd/MM/yyyy"
                  variant="inline"
                  autoOk="true"
                  value={searchToolbar.bookingDateFrom}
                  onChange={(date) => {
                    console.log(format(new Date(date), "yyyy-MM-dd"));
                    setSearchToolbar({
                      ...searchToolbar,
                      bookingDateFrom: format(new Date(date), "yyyy-MM-dd"),
                      searchByAgentName: "",
                      searchByMailId: "",
                      searchByBookingId: "",
                      searchByPhoneNumber: "",
                    });
                  }}
                />
                <CalendarTodayIcon className={classes.icon} />
              </div>
              {errorFrom ? (
                <div style={{ color: "red" }}>Booking Date From Required</div>
              ) : null}
            </Grid>
            <Grid item md={3}>
              <div style={{ position: "relative" }}>
                <DatePicker
                  label="Booking Date To"
                  inputVariant="outlined"
                  size="small"
                  fullWidth
                  format="dd/MM/yyyy"
                  animateYearScrolling
                  variant="inline"
                  autoOk="true"
                  value={searchToolbar.bookingDateTo}
                  onChange={(date) => {
                    console.log(format(new Date(date), "yyyy-MM-dd"));
                    setSearchToolbar({
                      ...searchToolbar,
                      bookingDateTo: format(new Date(date), "yyyy-MM-dd"),
                      searchByAgentName: "",
                      searchByMailId: "",
                      searchByBookingId: "",
                      searchByPhoneNumber: "",
                    });
                  }}
                  maxDate={addDays(new Date(searchToolbar.bookingDateFrom), 90)}
                />
                <CalendarTodayIcon className={classes.icon} />
              </div>
              {errorTo ? (
                <div style={{ color: "red" }}>Booking Date To Required</div>
              ) : null}
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button onClick={handleSubmitSearch}>Search</Button>
          <Button
            style={{ background: "black", marginLeft: "10px" }}
            onClick={() => {
              setSearchToolbar({
                ...searchToolbar,
                searchByAgentName: "",
                searchByBookingId: "",
                searchByClientName: "",
                searchByMailId: "",
                searchByPhoneNumber: "",
                bookingDateFrom: null,
                bookingDateTo: null,
              });
              setTextToSearch("");
              fetchListData();
            }}
          >
            Reset
          </Button>
        </div>
      </Paper>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Paper
          square
          style={{ borderRadius: "5px", border: "1px solid #f46d25" }}
        >
          <Tabs
            value={tableType}
            indicatorColor="secondary"
            onChange={tableTypeSwapper}
            style={{ borderRadius: "5px", color: "black" }}
          >
            <Tab
              label="Hotel Booking List"
              value="bookedByHotel"
              style={{
                background: tableType === "bookedByHotel" && "#f46d25",
                color: tableType === "bookedByHotel" && "white",
                transform: tableType === "bookedByHotel" && "scale(1.1)",
                fontWeight: tableType === "bookedByHotel" && "bold",
                transition: "transform 0.1s",
                overflow: tableType === "bookedByHotel" && "visible",
                top: tableType === "bookedByHotel" && "10",
              }}
            />
            <Tab
              label="TownO Booking List"
              value="townoBookings"
              style={{
                background: tableType === "townoBookings" && "#f46d25",
                color: tableType === "townoBookings" && "white",
                transform: tableType === "townoBookings" && "scale(1.1)",
                fontWeight: tableType === "townoBookings" && "bold",
                transition: "transform 0.1s",
                overflow: tableType === "townoBookings" && "visible",
                top: tableType === "townoBookings" && "10",
              }}
            />
          </Tabs>
        </Paper>

        <div style={{ display: "flex" }}>
          <Button
            style={{ height: "40px", ...twnButtonStyles.orangeBtn }}
            onClick={() => history.push("/hotelNewBooking")}
          >
            New Booking
          </Button>
        </div>
      </div>
      <>
        {loading ? (
          <Loader />
        ) : (
          <TableContainer component={Paper} style={{ marginTop: "0.5%" }}>
            <Table stickyHeader size="small">
              <TableHead>
                <StyledTableRow>
                  {tableHeadValues.map((value) => (
                    <StyledTableCell
                      key={value}
                      style={{
                        color: "white",

                        fontSize: "15px",
                      }}
                      align="center"
                    >
                      {value}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {hotelBookingList &&
                  filteredHotelBookingList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return tableType === "bookedByHotel" ? (
                        <HotelBookingListTable row={row} />
                      ) : (
                        <TownoBookingListTable row={row} />
                      );
                    })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={filteredHotelBookingList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </>
    </div>
  );
};

export default HotelBookingList;

const useStyles = makeStyles({
  root: {
    // padding: "100px 0px",
    // margin: "0px 30px",
    padding: "5% 0.5% 1% 1.5%",
    "@media (max-width: 767px)": {
      margin: "0px 10px",
    },
  },

  table: {
    backgroundColor: "#f46d25",
    color: "white",
  },

  icon: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    position: "absolute",
    right: "15px",
    top: "5px",
    color: "#f46d25",
  },

  plus: {
    cursor: "pointer",
    color: "#f46d25",
    position: "relative",
    top: "7px",
    "@media (max-width: 767px)": {
      fontSize: "18px",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "40px",
    color: "#f46d25",
    margin: "0px",
  },
  titles: {
    padding: "10px",
    fontSize: "28px",
    fontWeight: "lighter",
    color: "#fff",
    background: "#f46d25",
    margin: "20px 10px 10px 0px",
    borderRadius: "8px 8px 0px 0px",
  },
  formControl: {
    minWidth: "60px",
    height: "40px",
    background: "white",
  },
});
