import React, { useState, useEffect } from 'react';
import { Grid, Paper, Stack } from '@mui/material';
import { twnButtonStyles } from '../../utils/townoStyle';
import _ from "lodash";
import Api from "../../Service/Api";
import MultiLineAgentChart from './AgentWiseChart';
import './agentDashboard.css';
import UpcomingAgentCheckin from "./UpcomingAgentCheckin";
import UpcomingAgentPayment from "./UpcomingAgentPayment";
import AgentCountBox from './AgentCountBox';

// CHART FILTER STYLE 
const LHBPFilterStyle = { backgroundColor: '#fff', border: '1px solid #F46D25', color: '#111', padding: '0.4em', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', cursor: 'pointer' }
const LHBPFilterSelectedStyle = { backgroundColor: '#F46D25', fontWeight: 'bold', border: '1px solid #F46D25', color: '#fff', padding: '0.4em', borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px', cursor: 'pointer' }
const RHBPFilterSelectedStyle = { backgroundColor: '#F46D25', fontWeight: 'bold', border: '1px solid #F46D25', color: '#fff', borderTopRightRadius: '4px', borderBottomRightRadius: '4px', padding: '0.4em', cursor: 'pointer' }
const RHBPFilterStyle = { backgroundColor: '#fff', border: '1px solid #F46D25', color: '#111', borderTopRightRadius: '4px', borderBottomRightRadius: '4px', padding: '0.4em', cursor: 'pointer' }

export default function AgentDashboard() {
    const Auth = localStorage.getItem("auth");
    const hasAdmin = localStorage.getItem("role");
    const uniqueId = localStorage.getItem("unique_id");
    const notAdmin = _.indexOf(["Agent Admin"], hasAdmin, 0) === -1

    // AGENT REVENUE LINE CHART 
    const [agentRevenueFilter, setAgentRevenueFilter] = useState('month');
    const [agentRevenueData, setAgentRevenueData] = useState([]);

    // Api and Function for Agent Revenue starts
    const AgentRevenueApiFun = () => {
        var url = `TravelAgentDashboardBookingRevenue/${uniqueId}/${agentRevenueFilter}`
        if (notAdmin) { url = `TravelAgentStaffDashboardBookingRevenue/${uniqueId}/${agentRevenueFilter}/${Auth}` }

        Api.get(url).then((res) => {
            console.log("response", res, url)
            if (notAdmin) { setAgentRevenueData(res.data); }
            else { setAgentRevenueData(res.data); }
        })
    }
    useEffect(() => {
        AgentRevenueApiFun();
    }, [agentRevenueFilter]);
    // Api and Function for Agent Revenue ends

    return (
        <div style={{ ...twnButtonStyles.allPages, paddingTop: '5.7%' }}>

            {/* Count Box  */}
            <AgentCountBox />

            {/* Agent Chart  */}
            <Grid item sm={12} xs={12} justifyContent="space-between" alignItems="center">
                <div>
                    <div className="accordion">
                        <div className="accordion-title">
                            <div style={{ color: "#fff", backgroundColor: '#f46d25', fontWeight: "600" }}>
                                Agent Wise Revenue
                            </div>
                        </div>

                        <Stack direction='row' justifyContent='right' alignContent='right' style={{ backgroundColor: '#fff', padding: '1em 1em 0 0' }}>
                            {/* {
                                notAdmin ?
                                    <> */}
                            <button onClick={() => setAgentRevenueFilter("previousMonth")} style={agentRevenueFilter === "previousMonth" ? LHBPFilterSelectedStyle : LHBPFilterStyle}>Previous Month</button>
                            <button onClick={() => setAgentRevenueFilter("month")} style={agentRevenueFilter === "month" ? RHBPFilterSelectedStyle : RHBPFilterStyle}>Present Month</button>
                            {/* </>
                                    :
                                    <>
                                        <button onClick={() => setAgentRevenueFilter("today")} style={agentRevenueFilter === "today" ? LHBPFilterSelectedStyle : LHBPFilterStyle}>Today</button>
                                        <button onClick={() => setAgentRevenueFilter("week")} style={agentRevenueFilter === "week" ? CHBPFilterSelectedStyle : CHBPFilterStyle}>Week</button>
                                        <button onClick={() => setAgentRevenueFilter("month")} style={agentRevenueFilter === "month" ? RHBPFilterSelectedStyle : RHBPFilterStyle}>Month</button>
                                    </>
                            } */}

                        </Stack>

                        <div className='accordion-content' style={{ height: '40vh', padding: '0.5em' }}>
                            <MultiLineAgentChart agentRevenueData={agentRevenueData} notAdmin={notAdmin} />
                        </div>
                    </div>
                </div>
            </Grid>

            {/* UpcomingAgentPayment  */}
            <div><UpcomingAgentPayment /></div>

            {/* UpcomingAgentCheckin  */}
            <div style={{ marginTop: '1.3%', paddingBottom: '3%' }}><UpcomingAgentCheckin /></div>
        </div>
    );
}
