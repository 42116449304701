import { ReactComponent as ConnectionLostIllustration } from "./connectionLost.svg";
const ConnectionLost = () => (
  <section
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",

      marginTop: "150px",
    }}
  >
    <ConnectionLostIllustration style={{ width: "300px" }} />
    <h3 style={{ color: "#f46d25" }}>Network lost!!!</h3>
    <p>Check your internet connection</p>
  </section>
);

export default ConnectionLost;
