import { Button, Grid, IconButton, Paper } from "@material-ui/core"
import DownloadIcon from "@material-ui/icons/GetApp"
import VisibilityIcon from "@material-ui/icons/Visibility"
import EditIcon from "@material-ui/icons/Create"
import { format, parse, parseISO } from "date-fns"
import { enGB } from "date-fns/locale"

import { Link, useHistory } from "react-router-dom/cjs/react-router-dom"

export const formattedDate = (date) => {
  return parseISO(date)
}

const PackageCard = ({ pack }) => {
  let hasAdmin = localStorage.getItem("role")
  let checkAgent = localStorage.getItem("agent")
  const bookingDate = format(formattedDate(pack.createdAt), "d MMM, yyyy")
  const tripDate = format(
    parse(pack.checkIn, "dd-MM-yyyy", new Date()),
    "dd MMMM, yyyy",
    { locale: enGB }
  )
  const history = useHistory()

  return (
    <div style={{ paddingBottom: "5%" }}>
      <div
        style={{
          display: "flex",
          background: "#f46d25",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: "10px 10px 0px 0px",
          height: "35px",
          padding: "1%",
        }}
      >
        <h4 style={{ color: "white" }}>{pack.packageId}</h4>
        <div
          style={{
            width: "30%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            style={{
              color: "#343A40",
              border: "50%",
              background: "white",
              height: "20px",
              width: "20px",
            }}
          >
            <DownloadIcon style={{ height: "18px" }} />
          </IconButton>

          <IconButton
            style={{
              color: "#343A40",
              border: "50%",
              background: "white",
              height: "20px",
              width: "20px",
            }}
            onClick={() =>
              history.push(
                `/konnect/viewPackageOrQuotation/package/${pack.packageId}`
              )
            }
          >
            <VisibilityIcon style={{ height: "18px" }} />
          </IconButton>
          <IconButton
            style={{
              color: "#343A40",
              border: "50%",
              background: "white",
              height: "20px",
              width: "20px",
            }}
            onClick={() =>
              history.push(
                hasAdmin === "Agent Admin" || checkAgent === "Agent"
                  ? `/konnect/agentCreatePackageOrQuotation/package/${true}/${
                      pack.packageId
                    }`
                  : `/konnect/createPackageOrQuotation/package/${true}/${
                      pack.packageId
                    }`,
                { displayConvert: false }
              )
            }
          >
            <EditIcon style={{ height: "18px" }} />
          </IconButton>
        </div>
      </div>
      <Paper
        style={{
          display: "flex",
          borderRadius: "0px 0px 10px 10px",
          padding: "1%",
        }}
      >
        <Grid container xs={6}>
          <Grid item style={{ fontSize: "12px", width: "100%" }}>
            <span style={{ fontWeight: "bold" }}>Package :</span> {pack.name}
          </Grid>
          <Grid item style={{ fontSize: "12px", width: "100%" }}>
            <span style={{ fontWeight: "bold" }}>Name :</span> {pack.clientName}
          </Grid>
          <Grid item style={{ fontSize: "12px", width: "100%" }}>
            <span style={{ fontWeight: "bold" }}>No. :</span>{" "}
            {pack.clientMobileNo}
          </Grid>
        </Grid>
        <Grid container xs={6}>
          <Grid item style={{ fontSize: "12px", width: "100%" }}>
            <span style={{ fontWeight: "bold" }}>Booking Date :</span>{" "}
            {bookingDate}
          </Grid>
          <Grid item style={{ fontSize: "12px", width: "100%" }}>
            <span style={{ fontWeight: "bold" }}>Trip Date :</span> {tripDate}
          </Grid>
          <Grid item style={{ fontSize: "12px", width: "100%" }}>
            <span style={{ fontWeight: "bold" }}>Lead Score :</span>{" "}
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default PackageCard
