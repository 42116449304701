import { useLocation } from "react-router-dom"
import usePathProvider from "../../hooks/usePathProvider.hook"
import "./Directory.styles.css"

const Directory = () => {
  const { pathname } = useLocation()
  const { path } = usePathProvider(pathname)

  return <div className="directory-container">{path}</div>
}

export default Directory
