import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  styled,
} from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Api from "../../Service/Api"
import { baseurl } from "../../Service/httpCommon"
import EditIcon from "@material-ui/icons/Edit"
import { filter } from "lodash"
import SearchIcon from "@material-ui/icons/SearchOutlined"
import ClearIcon from "@material-ui/icons/Clear"
import { twnButtonStyles } from "../../utils/townoStyle"

import { Visibility } from "@material-ui/icons"
import { ReactComponent as GSTInvoiceLogo } from "../../assets/logo/GST_logo.svg"
import ScreenLoader from "../../components/loadingScreen/screenLoader.component"
import GSTVoucherPDF from "../../assets/Icons/voucherIcons/GST.png"
import html2pdf from "html2pdf.js"
import ReactDOMServer from "react-dom/server"

import { toast } from "react-toastify"
import { format } from "date-fns"
import XLSX from "xlsx"
import PropTypes from "prop-types"

{
  /*Function for sorting records starts*/
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  if (query) {
    return filter(
      array,
      (_travelAgentList) =>
        _travelAgentList.travelAgencyName
          .toLowerCase()
          .indexOf(query.toLowerCase()) !== -1
      //   ||
      // _invoicedata.hotelName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    )
  }
  return stabilizedThis.map((el) => el[0])
}
{
  /*Function for sorting records ends*/
}

const TravelAgentList = () => {
  const classes = useStyles()
  const history = useHistory()
  const [agentListData, setAgentListData] = useState(() => [])
  const uniqueId = localStorage.getItem("unique_id")
  const auth = localStorage.getItem("auth")
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)

  const [excelFile, setExcelFile] = useState(() => null)
  const [agentJsonData, setAgentJsonData] = useState(() => null)

  const [loading, setLoading] = useState(false)
  const [openUploadDialog, setOpenUploadDialog] = useState(() => false)
  const [trackUploadProgress, setTrackUploadProgress] = useState(() => 0)
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [width])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value)
    setPage(0)
  }

  const fetchListData = async () => {
    setLoading(true)
    try {
      const response = await Api.get(`${baseurl}travelagency`)
      console.log(response.data)
      setAgentListData(response.data)
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchListData()
  }, [])

  const tableHeadValues = [
    "Agency Name",
    "Created At",
    "Designation",
    "Onboarded By",
    "Referred By",
    "Action",
  ]

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    background: "#F46D25",
    height: "40px",
  }))
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    background: "#F46D25",
    color: "#fff",
    fontSize: "12px",
    padding: "5px",
    lineHeight: "1rem",
    minWidth: "100px",
  }))

  const StyledTableCellInside = styled(TableCell)(({ theme }) => ({
    padding: "5px",
    fontSize: "14px",
    lineHeight: "15px",
  }))
  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("createdAt")
  const [textToSearch, setTextToSearch] = useState("")

  const filteredTravelAgentDataList = applySortFilter(
    agentListData,
    getComparator(order, orderBy),
    textToSearch
  )

  return (
    <div style={twnButtonStyles.allPages}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <h1 style={twnButtonStyles.xlFonts}>Travel Agent List</h1>
        <div style={{ display: "flex" }}>
          <div
            style={{
              position: "absolute",
              display: "flex",
              marginLeft: "-50px",
            }}
          >
            <Slide direction="left" in={search} mountOnEnter unmountOnExit>
              <TextField
                type="search"
                variant="outlined"
                value={textToSearch}
                onChange={(e) => setTextToSearch(e.target.value)}
                placeholder="Search by Agent Name"
                InputProps={{
                  style: {
                    height: "32px",
                    marginLeft: "-205px",
                    position: "absolute",
                    width: "230px",
                    borderRadius: "5px 10px 10px 5px",
                  },
                }}
              />
            </Slide>
            <IconButton
              onClick={() => {
                setSearch(!search)
                search === false && setTextToSearch("")
              }}
              style={{
                background: "#F46D25",
                color: "white",
                marginRight: "10px",
                width: "32px",
                height: "32px",
                padding: "10px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            >
              {search ? <ClearIcon /> : <SearchIcon />}
            </IconButton>
          </div>

          <>
            <input
              name="image"
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => {
                const selectedFile = e.target.files[0]
                console.log(e.target.files[0])
                setExcelFile(selectedFile)
                const reader = new FileReader()
                reader.onload = (e) => {
                  const fileContent = e.target.result
                  const workBook = XLSX.read(fileContent, { type: "binary" })

                  const sheetName = workBook.SheetNames[0]
                  let jsonData = XLSX.utils.sheet_to_json(
                    workBook.Sheets[sheetName]
                  )
                  jsonData = jsonData.map((data) => ({
                    id: "",
                    ...data,
                    uniqueId: uniqueId,
                    createdBy: auth,
                  }))
                  setAgentJsonData(jsonData)
                  console.log(jsonData)
                }
                reader.readAsBinaryString(selectedFile)
                setOpenUploadDialog(true)
              }}
              onClick={(e) => (e.target.value = null)}
              style={{ display: "none" }}
              id="excelFile"
            />
            <label htmlFor="excelFile">
              <Button
                variant="contained"
                component="span"
                color="primary"
                style={{
                  height: "40px",
                  ...twnButtonStyles.orangeBtn,
                  marginRight: "10px",
                }}
              >
                Upload List
              </Button>
            </label>
          </>

          <Button
            style={{ height: "40px", ...twnButtonStyles.orangeBtn }}
            onClick={() => {
              history.push("/konnect/onboardTravelAgentForm")
            }}
          >
            Add New
          </Button>
        </div>
      </div>
      {loading ? (
        <ScreenLoader open={loading} />
      ) : (
        <TableContainer component={Paper} style={{ marginTop: "0.5%" }}>
          <Table stickyHeader size="small">
            <TableHead>
              <StyledTableRow>
                {tableHeadValues.map((value) => (
                  <StyledTableCell
                    key={value}
                    style={{
                      color: "white",

                      fontSize: "15px",
                    }}
                    align="center"
                  >
                    {value}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {agentListData &&
                filteredTravelAgentDataList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow key={row.id} style={{ height: "40px" }}>
                        <StyledTableCellInside
                          style={{
                            textTransform: "capitalize",
                            color: "#f46d25",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          align="center"
                        >
                          <span>{row.travelAgencyName}</span>
                        </StyledTableCellInside>
                        <StyledTableCellInside align="center">
                          {format(new Date(row.createdAt), "dd MMM yy")}
                        </StyledTableCellInside>
                        <StyledTableCellInside align="center">
                          {row.designation}
                        </StyledTableCellInside>
                        <StyledTableCellInside align="center">
                          {row.onBoardedBy}
                        </StyledTableCellInside>

                        <StyledTableCellInside align="center">
                          {row.agentReferredBy}
                        </StyledTableCellInside>

                        <StyledTableCellInside
                          align="center"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          <Tooltip title="Edit">
                            <IconButton
                              aria-label="edit"
                              style={{ padding: "0px", color: "#F46D25" }}
                              onClick={() =>
                                history.push(
                                  `/onboardTravelAgentForm/${row.id}`
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCellInside>
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredTravelAgentDataList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
      <Dialog
        open={openUploadDialog}
        onClose={() => {
          setOpenUploadDialog(false)
          setTrackUploadProgress(0)
        }}
      >
        <DialogTitle style={{ fontSize: "14px", fontWeight: "bold" }}>
          {"Upload Agent List"}
        </DialogTitle>
        <DialogContent>
          The sheet contains {agentJsonData?.length} records.
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={async () => {
              setLoading(true)
              agentJsonData?.forEach(async (agency) => {
                try {
                  const { data } = await Api.post("travelagency", agency)
                } catch (error) {
                  toast.error("Unknown Error")
                }
              })
              toast.success("New Agents Added Successfully")
              fetchListData()
              setOpenUploadDialog(false)
              setLoading(false)
            }}
          >
            Upload
          </Button>
          <Button
            size="small"
            style={{ background: "black" }}
            onClick={() => {
              setOpenUploadDialog(false)
              setTrackUploadProgress(0)
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default TravelAgentList

const useStyles = makeStyles(() => ({
  root: {
    // padding: "100px 0px",
    // margin: "0px 30px",
    padding: "5% 0.5% 1% 1.5%",
    "@media (max-width: 767px)": {
      margin: "0px 10px",
    },
  },

  table: {
    backgroundColor: "#f46d25",
    color: "white",
  },

  icon: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    position: "absolute",
    right: "15px",
    top: "5px",
    color: "#f46d25",
  },

  plus: {
    cursor: "pointer",
    color: "#f46d25",
    position: "relative",
    top: "7px",
    "@media (max-width: 767px)": {
      fontSize: "18px",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "40px",
    color: "#f46d25",
    margin: "0px",
  },
  titles: {
    padding: "10px",
    fontSize: "28px",
    fontWeight: "lighter",
    color: "#fff",
    background: "#f46d25",
    margin: "20px 10px 10px 0px",
    borderRadius: "8px 8px 0px 0px",
  },
  formControl: {
    minWidth: "60px",
    height: "40px",
    background: "white",
  },
}))

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="primary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  )
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
}
