import { useEffect, useState } from "react"
import { capitalizeFirstLetterOfAString } from "../utility/capitalizeFirstLetterOfString"

const usePathProvider = (pathName) => {
  const [path, setPath] = useState(pathName)

  useEffect(() => {
    console.log(path)
    const alteredPath = path?.split("/")

    const filteredPath = alteredPath
      .map(capitalizeFirstLetterOfAString)
      .filter((n) => n !== "")

    const finalPath = filteredPath
    setPath(finalPath)
    // console.log({ filteredPath })
  }, [])

  return path
}

export default usePathProvider
