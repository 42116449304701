import { Card, Grid, Stack, Typography } from "@mui/material";
import { twnButtonStyles } from "../../utils/townoStyle";

const cardLabel = { padding: '0.5em 0.7em', color: '#111', fontWeight: '500' };
const cardValue = { padding: '0.5em 1em', color: '#111' };

export default function PreviewOnboard({onboardData}) {
    return (
        <div style={{ padding: '2% 1%', fontSize: '14px', backgroundColor: '#F7F7F7' }}>
            <Typography variant="h5" component="h5" style={twnButtonStyles.xlFonts}>Onboard Details</Typography>
            <Card style={{ marginTop: '9px' }}>
                <div style={{ backgroundColor: '#f46d25', color: '#fff', padding: '0.5em 1em', fontSize: '18px', fontWeight: '500' }}>Basic Details</div>
                <Stack style={{ padding: "0.5em" }}>
                    <Stack direction='row' spacing={2} style={{ lineHeight: '0.3', padding: '0 0.5em' }}>
                        <Grid container>
                            <Grid item xs={4} style={cardLabel}>Company / Hotel Name</Grid>
                            <Grid item xs={8} style={cardValue}>: {onboardData.travelAgencyName}</Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={4} style={cardLabel}>City</Grid>
                            <Grid item xs={8} style={cardValue}>: {onboardData.city}</Grid>
                        </Grid>
                    </Stack>
                    <Stack direction='row' spacing={2} style={{ lineHeight: '0.3', padding: '0 0.5em' }}>
                        <Grid container>
                            <Grid item xs={4} style={cardLabel}>Agent Reffered By</Grid>
                            <Grid item xs={8} style={cardValue}>: {onboardData.agentRefferedBy}</Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={4} style={cardLabel}>Venue Details</Grid>
                            <Grid item xs={8} style={cardValue}>: {onboardData.venueDetails}</Grid>
                        </Grid>
                    </Stack>
                    <Stack direction='row' spacing={2} style={{ lineHeight: '0.3', padding: '0 0.5em' }}>
                        <Grid container>
                            <Grid item xs={4} style={cardLabel}>Area of Operation:</Grid>
                            <Grid item xs={8} style={cardValue}>: {onboardData.areaOfOperation}</Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={4} style={cardLabel}>Type</Grid>
                            <Grid item xs={8} style={cardValue}>: {onboardData.type}</Grid>
                        </Grid>
                    </Stack>
                    <Stack direction='row' spacing={2} style={{ lineHeight: '0.3', padding: '0 0.5em' }}>
                        <Grid container>
                            <Grid item xs={4} style={cardLabel}>Name</Grid>
                            <Grid item xs={8} style={cardValue}>: {onboardData.name}</Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={4} style={cardLabel}>Designation</Grid>
                            <Grid item xs={8} style={cardValue}>: {onboardData.designation}</Grid>
                        </Grid>
                    </Stack>
                    <Stack direction='row' spacing={2} style={{ lineHeight: '0.3', padding: '0 0.5em' }}>
                        <Grid container>
                            <Grid item xs={4} style={cardLabel}>Contact Number 1</Grid>
                            <Grid item xs={8} style={cardValue}>: {onboardData.mobile1}</Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={4} style={cardLabel}>Contact Number 2</Grid>
                            <Grid item xs={8} style={cardValue}>: {onboardData.mobile2}</Grid>
                        </Grid>
                    </Stack>
                    <Stack direction='row' spacing={2} style={{ lineHeight: '0.3', padding: '0 0.5em' }}>
                        <Grid container>
                            <Grid item xs={4} style={cardLabel}>Phone</Grid>
                            <Grid item xs={8} style={cardValue}>: {onboardData.phone}</Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={4} style={cardLabel}>Mail</Grid>
                            <Grid item xs={8} style={cardValue}>: {onboardData.mail}</Grid>
                        </Grid>
                    </Stack>                
                    <Stack direction='row' spacing={2} style={{ lineHeight: '0.3', padding: '0 0.5em' }}>
                        <Grid container>
                            <Grid item xs={4} style={cardLabel}>Mail 1</Grid>
                            <Grid item xs={8} style={cardValue}>: {onboardData.mail1}</Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={4} style={cardLabel}>Mail 2</Grid>
                            <Grid item xs={8} style={cardValue}>: {onboardData.mail2}</Grid>
                        </Grid>
                    </Stack>
                    <Stack direction='row' spacing={2} style={{ lineHeight: '0.3', padding: '0 0.5em' }}>
                        <Grid container>
                            <Grid item xs={4} style={cardLabel}>Mail 3</Grid>
                            <Grid item xs={8} style={cardValue}>: {onboardData.mail3}</Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={4} style={cardLabel}>Website Address</Grid>
                            <Grid item xs={8} style={cardValue}>: {onboardData.websiteAddress}</Grid>
                        </Grid>
                    </Stack>
                    <Stack direction='row' spacing={2} style={{ lineHeight: '0.3', padding: '0 0.5em' }}>
                        <Grid container>
                            <Grid item xs={4} style={cardLabel}>Promoting Destination</Grid>
                            <Grid item xs={8} style={cardValue}>: {onboardData.promotingDestination}</Grid>
                        </Grid>
                        <Grid container></Grid>
                    </Stack>
                </Stack>
            </Card>
        </div>
    );
}