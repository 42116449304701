import KeyPointCard from "../KeyPointCard/KeyPointCard.component"
import "./KeyPoints.styles.css"
import Customized from "../../assets/icons/key-points/Custom.png"
import Experience from "../../assets/icons/key-points/Experience.png"
import TrustWorthy from "../../assets/icons/key-points/Trust.png"

const keyPointsData = [
  {
    header: "Customized",
    content:
      "Experience the perfect getaway with our tailored holiday packages, designed to match your preferences and create lasting memories.",
    imagePath: Customized,
  },
  {
    header: "Experiential",
    content:
      "Our experiential holiday packages are carefully designed to bring you unique local flavour and personalized moments.",
    imagePath: Experience,
  },
  {
    header: "Trustworthy",
    content:
      "Discover peace of mind with our exclusive network of hotel partners, ensuring the highest standards of hospitality.",
    imagePath: TrustWorthy,
  },
]

const KeyPoints = () => {
  console.log()
  return (
    <section className="key-points-section">
      <div className="key-points-container">
        {keyPointsData.map((point, idx) => (
          <KeyPointCard key={`${point.header} ${idx}`} {...point} />
        ))}
      </div>
    </section>
  )
}

export default KeyPoints
