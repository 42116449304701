import DateFnsUtils from "@date-io/date-fns"
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { Grid, Rating } from "@mui/material"
import { useState } from "react"
import { useEffect } from "react"
import API from "../../../Service/Api"
import Select from "react-select"
import { addDays, format } from "date-fns"
import Slider from "react-slick"
import { baseurl } from "../../../Service/httpCommon"
import RatingMail from "../../OrderBooking/RatingMail"
import { Link, useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import AgentHotelCopyPropertyCardSkeleton from "../../../components/skeletonComponents/agentHotelCopyPropertyCardSkeleton.component"

const useStyles = makeStyles(() => ({
  root: {
    top: "0",
    padding: "90px 0px",
  },
  selectStyle: {
    width: "100%",
    height: "35px",
    borderColor: "#000000",
    "& .MuiSelect-icon": {
      color: "#f46d25",
    },
  },
  topBorder: {
    position: "absolute",
    top: "-9px",
    zIndex: "10",
    left: "12px",
    color: "rgb(0 0 0 / 54%)",
    background: "#fff",
    borderRadius: "4px",
    padding: "1px 4px",
    fontSize: "12px",
  },
  icon: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    position: "absolute",
    right: "15px",
    top: "5px",
    color: "#f46d25",
  },
  sectionStyle: {
    marginTop: "30px",
  },
  headerStyle: {
    fontSize: "20px",
    color: "#343A40",
    fontWeight: "bolder",
  },
  mphLabelStyling: {
    background: "#343A40",
    color: "white",
    width: "fit-content",
    position: "absolute",
    zIndex: "1000",
    padding: "5px",
    marginTop: "10px",
    borderRadius: "0px 5px 5px 0px",
    fontWeight: "bold",
    height: "fit-content",
    fontSize: "13px",
  },
}))

export const StyledRating = withStyles({
  iconFilled: {
    color: "#FFFFFF",
    boxShadow: "10px",
  },
})(Rating)

const AgentLoginHomeMobileUI = () => {
  const classes = useStyles()
  const uniqueId = localStorage.getItem("unique_id")

  const [cityOptions, setCityOptions] = useState([])
  const [hotelOptions, setHotelOptions] = useState([])

  const [city, setCity] = useState({})
  const [checkIn, setCheckIn] = useState(null)
  const [checkOut, setCheckOut] = useState(null)
  const [hotel, setHotel] = useState({})
  const [adult, setAdult] = useState("")
  const [child, setChild] = useState("")
  const [deviceInnerWidth, setDeviceInnerWidth] = useState(window.innerWidth)

  const [popularHotels, setPopularHotels] = useState([])
  const [dealofweek, setDealofweek] = useState([])
  const [LastMinute, setLastMinute] = useState([])
  const [bestSelling, setBestSelling] = useState([])
  const [loading, setLoading] = useState(() => false)

  const fetchDealsOfTheWeek = async () => {
    setLoading(true)
    try {
      const { data } = await API.get(`agentdealoftheweekactive`)
      setDealofweek(data)
      setLoading(false)
    } catch (error) {
      toast.error(error)
      setLoading(false)
    }
  }

  const fetchPupularhotels = async () => {
    try {
      const { data } = await API.get("agentpopularactive")
      setPopularHotels(data)
    } catch (error) {
      toast.error(error)
    }
  }

  const fetchLastMinuteDeals = async () => {
    try {
      const { data } = await API.get("agentlastminutedealactive")
      setLastMinute(data)
      console.log("last minute deals", data)
    } catch (error) {
      toast.error(error)
    }
  }

  const fetchBestSellingDestinations = async () => {
    try {
      const { data } = await API.get("agentbestsellingdestinationsactive")
      setBestSelling(data)
      console.log(data)
    } catch (error) {
      toast.error(error)
    }
  }

  useEffect(() => {
    fetchPupularhotels()
    fetchDealsOfTheWeek()
    fetchLastMinuteDeals()
    fetchBestSellingDestinations()
  }, [])

  const handleWindowSizeChange = () => {
    setDeviceInnerWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  const getCityOptions = () => {
    API.get(`agentcitypropertylist/${uniqueId}`).then((res) => {
      var options = res.data.map((each) => {
        return { label: each, value: each }
      })
      setCityOptions(options)
    })
  }

  const getHotelOptions = () => {
    let url = `propertybasicpropertydetailsall`
    API.get(url).then((res) => {
      var options = res.data.map((each) => {
        return { label: each.displayName, value: each.propertyId }
      })
      setHotelOptions(options)
    })
  }

  useEffect(() => {
    getCityOptions()
    getHotelOptions()
  }, [])

  const selectStyles = {
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    control: (base, state) => ({
      ...base,
      "&:hover": { borderColor: "#f46d25" },
      borderColor: "#343A40",
      boxShadow: "none",
      width: "100%",
      height: "25px",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: "absolute",
      top: state.hasValue || state.selectProps.inputValue ? "-15%" : "50%",
      background: "#fff",
      padding: "0px 5px",
      transition: "top 0.1s, font-size 0.2s",
      fontSize: "17px",
    }),
  }

  const settings = {
    dots: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    width: "100%",
    lazyLoad: true,
    slidesToScroll: 1,
    arrows: false,
    infinite: popularHotels.length > 1,
  }

  const lastMinuteDealsSettings = {
    dots: false,
    slidesToShow: 1.8,
    autoplay: false,
    width: "151px",
    infinite: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
  }

  const history = useHistory()

  return (
    <section className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={1} padding="0px 18px">
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={6} position="relative">
              <span className={classes.topBorder}>City</span>
              <Select
                name="city"
                className={classes.select}
                value={city}
                onChange={(e) => {
                  setCity(e)
                  console.log(e.value)
                  API.get(`propertycitynameidlist/${e.value}`).then((res) => {
                    let options = res.data.map((hotel) => ({
                      label: hotel.displayName,
                      value: hotel.propertyId,
                    }))
                    setHotel({ label: "", value: "" })
                    setHotelOptions(options)
                    console.log(res.data)
                  })
                }}
                placeholder=""
                options={cityOptions}
                styles={selectStyles}
              />
            </Grid>
            <Grid item xs={6} position="relative">
              <span className={classes.topBorder}>Hotel Name</span>
              <Select
                name="propertyId"
                className={classes.select}
                value={hotel}
                onChange={(e) => setHotel(e)}
                placeholder=""
                options={hotelOptions}
                styles={selectStyles}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={6}>
              <DatePicker
                label="Check-In"
                inputVariant="outlined"
                fullWidth
                size="small"
                animateYearScrolling
                format="dd/MM/yyyy"
                variant="inline"
                autoOk="true"
                value={checkIn}
                minDate={format(new Date(), "yyyy-MM-dd")}
                onChange={(e) => setCheckIn(e)}
                style={{
                  borderRadius: "5px",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="Check-Out"
                inputVariant="outlined"
                size="small"
                fullWidth
                format="dd/MM/yyyy"
                animateYearScrolling
                variant="inline"
                autoOk="true"
                minDate={
                  checkIn != null && format(addDays(checkIn, 1), "yyyy-MM-dd")
                }
                disabled={checkIn == null ? true : false}
                value={checkOut}
                onChange={(e) => setCheckOut(e)}
                style={{
                  borderRadius: "5px",
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={6}>
              <TextField
                name="adult"
                value={adult}
                variant="outlined"
                size="small"
                label="Adults"
                inputMode="numeric"
                onChange={(e) => setAdult(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="children"
                value={child}
                variant="outlined"
                size="small"
                label="Children"
                inputMode="numeric"
                onChange={(e) => setChild(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <center>
              <Link
                to={{
                  pathname: "/konnect/agentHotelCopy",
                  state: {
                    city: city,
                    checkIn: checkIn,
                    checkOut: checkOut,
                    hotel: hotel,
                    adult: adult,
                    child: child,
                  },
                }}
              >
                <Button size="small">Search</Button>
              </Link>
            </center>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>

      <section className={classes.sectionStyle}>
        <center className={classes.headerStyle}>Most Popular Hotels</center>
        <Grid container marginTop="10px">
          <Grid item xs={12}>
            <Slider {...settings}>
              {popularHotels.map((hotel, idx) => (
                <Card
                  key={idx}
                  onClick={() =>
                    history.push(`/konnect/hotelView/${hotel.propertyId}`)
                  }
                >
                  {hotel.topLeftLabel && (
                    <Typography className={classes.mphLabelStyling}>
                      {hotel.topLeftLabel}
                    </Typography>
                  )}

                  <CardActionArea
                    style={{ background: "rgba(196, 196, 196, 0.21)" }}
                  >
                    <CardMedia
                      component="img"
                      image={`${baseurl}getimage/${hotel.image}`}
                      height="200px"
                    />
                    <CardContent>
                      <Typography
                        variant="h7"
                        color="primary"
                        style={{ fontWeight: "bold" }}
                      >
                        {hotel.propertyName}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        onClick={() =>
                          history.push(`/konnect/hotelView/${hotel.propertyId}`)
                        }
                      >
                        Rs.{hotel.minmumprice} / <small> Per Night</small>
                      </Button>
                    </CardActions>
                  </CardActionArea>
                </Card>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </section>

      <section className={classes.sectionStyle} style={{ padding: "0px 18px" }}>
        <center className={classes.headerStyle}>Deals Of The Week</center>
        {loading ? (
          <AgentHotelCopyPropertyCardSkeleton length={3} />
        ) : (
          <Grid container spacing={2}>
            {dealofweek.map((deal) => (
              <Grid item xs={12}>
                <Card
                  onClick={() =>
                    history.push(`/konnect/hotelView/${deal.propertyId}`)
                  }
                >
                  <span
                    style={{
                      display: "flex",
                      position: "absolute",
                      zIndex: "500",
                      marginTop: "10px",
                    }}
                  >
                    {
                      <StyledRating
                        value={deal.starRate}
                        max={Number(deal.starRate)}
                        size="small"
                      />
                    }
                  </span>

                  <CardActionArea
                    style={{ background: "rgba(196, 196, 196, 0.21)" }}
                  >
                    <CardMedia
                      component="img"
                      image={`${baseurl}getimage/${deal.image}`}
                      height="116px"
                      style={{ borderRadius: "10px" }}
                    />
                    <CardContent style={{ paddingRight: "0px" }}>
                      <Grid container spacing={1}>
                        <Grid
                          item
                          container
                          spacing={1}
                          display="flex"
                          justifyContent="space-between"
                          xs={12}
                        >
                          <Grid item xs={7}>
                            <Typography
                              color="primary"
                              style={{
                                fontWeight: "bold",
                                fontSize: "clamp(13px,10vw,17px)",
                              }}
                            >
                              {deal.propertyName}
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography
                              style={{
                                color: "#343A40",
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                            >
                              Rs. {deal.minmumprice} /<small>Per Night</small>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          spacing={1}
                          display="flex"
                          justifyContent="space-around"
                        >
                          <Grid item xs={7}>
                            <Typography
                              variant="h8"
                              style={{ color: "#343A40", fontWeight: "bold" }}
                            >
                              {deal.city}
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Button size="small">Search Rooms</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </section>

      <section className={classes.sectionStyle}>
        <center
          className={classes.headerStyle}
          style={{ marginBottom: "10px" }}
        >
          Last Minute Deals
        </center>

        <Slider {...lastMinuteDealsSettings}>
          {LastMinute.map((deal) => (
            <div>
              <Card
                style={{ marginLeft: "10px" }}
                onClick={() =>
                  history.push(`/konnect/hotelView/${deal.propertyId}`)
                }
              >
                <Typography
                  style={{
                    background: "#343A40",
                    color: "white",
                    width: "fit-content",
                    position: "absolute",
                    zIndex: "1000",
                    padding: "5px",
                    marginTop: "10px",
                    borderRadius: "0px 5px 5px 0px",
                    height: "fit-content",
                    fontSize: "clamp(11px,10vw,12px)",
                  }}
                >
                  {deal.city}
                </Typography>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    image={`${baseurl}getimage/${deal.image}`}
                    height="97px"
                    style={{ borderRadius: "10px" }}
                  />
                  <CardContent
                    style={{
                      padding: "7px",
                      borderRadius: "0px 0px 10px 10px",
                    }}
                  >
                    <Typography
                      color="primary"
                      style={{
                        fontWeight: "bold",
                        fontSize: "clamp(10px,10vw,12px)",
                      }}
                    >
                      {deal.propertyName}
                    </Typography>
                    <span style={{ display: "flex", alignItems: "baseline" }}>
                      <Typography
                        color="rgba(52, 58, 64, 1)"
                        style={{ fontWeight: "bold", fontSize: "12px" }}
                      >
                        Rs. {deal.minmumprice}
                      </Typography>
                      <small
                        style={{
                          fontWeight: "bold",
                          marginLeft: "2px",
                          fontSize: "8px",
                        }}
                      >
                        / Per Night
                      </small>
                    </span>
                    <Grid
                      container
                      spacing={1}
                      display="flex"
                      alignItems="baseline"
                    >
                      <Grid item xs={8}>
                        <Typography
                          color="primary"
                          style={{ fontSize: "clamp(11px,10vw,11px)" }}
                        >
                          {deal.offerVaild}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          style={{
                            background: "#f46d25",
                            color: "white",
                            fontWeight: "bold",
                            padding: "3px",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: "5px",
                          }}
                        >
                          <span style={{ fontSize: "9px" }}>
                            {deal.offerPercentage}% off
                          </span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          ))}
        </Slider>
      </section>
      <section className={classes.sectionStyle} style={{ padding: "0px 18px" }}>
        <center className={classes.headerStyle}>
          Best Selling Destinations
        </center>
        <Grid container spacing={3} marginTop="0px">
          {bestSelling.map((destination) => (
            <Grid item xs={6}>
              <Card style={{ borderRadius: "5px" }}>
                <Typography
                  style={{
                    background: "#343A40",
                    color: "white",
                    width: "fit-content",
                    position: "absolute",
                    zIndex: "1000",
                    padding: "5px",
                    marginTop: "6px",
                    borderRadius: "0px 5px 5px 0px",
                    height: "fit-content",
                    fontSize: "clamp(8px,10vw,9px)",
                  }}
                >
                  {destination.city}
                </Typography>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    image={`${baseurl}getimage/${destination.image}`}
                    height="77px"
                    style={{ width: "100%" }}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </section>
    </section>
  )
}

export default AgentLoginHomeMobileUI
