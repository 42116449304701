import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  styled,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { format, parseISO } from "date-fns";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";

const StyledTableCellInside = styled(TableCell)(({ theme }) => ({
  padding: "5px",
  fontSize: "14px",
  lineHeight: "15px",
}));

const HotelBookingListTable = ({ row }) => {
  const history = useHistory();
  const checkIn = new Date(row?.checkIn);
  const checkOut = new Date(row?.checkOut);

  return (
    <TableRow key={row.id} style={{ height: "40px" }}>
      <StyledTableCellInside
        style={{
          textTransform: "capitalize",
          color: "#f46d25",
          fontWeight: "bold",
        }}
        align="center"
      >
        <span
          onClick={() => history.push(`/hotelBookingVoucher/${row.bookingId}`)}
          style={{ cursor: "pointer" }}
        >
          {row.bookingId}
        </span>
      </StyledTableCellInside>
      <StyledTableCellInside align="center">
        {row.clientName}
      </StyledTableCellInside>
      <StyledTableCellInside align="center">
        {row?.checkIn}
      </StyledTableCellInside>
      <StyledTableCellInside align="center">
        {row?.checkOut}
      </StyledTableCellInside>
      <StyledTableCellInside align="center">
        {row.createdBy}
      </StyledTableCellInside>

      <StyledTableCellInside align="center">
        {row.noOfNight}
      </StyledTableCellInside>
      <StyledTableCellInside align="center" style={{ fontSize: "12px" }}>
        <Tooltip title="Edit">
          <IconButton
            aria-label="edit"
            style={{ padding: "0px", color: "#F46D25" }}
            onClick={() => history.push(`/hotelNewBooking/${row.bookingId}`)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </StyledTableCellInside>
    </TableRow>
  );
};

export default HotelBookingListTable;
