import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core"
import { useEffect, useState } from "react"
import PropertyRoomDetails from "./propertyRoomDetailsComponents/roomDetails.component"
import PropertyMedia from "./propertyMediaComponent/propertyMedia.component"
import PropertyTermsConditionsAndPolicies from "./propertyTermsAndConditionsAndPolicy/propertyTermsConditionsAndPolicy.component"
import SnackbarComponent from "../../components/snakbarNotify/snackbar.component"
import PropertybankDetailsForm from "./propertyBankDetailsForm/propertyBankDetailsForm.component"
import AddNewRoomForm from "./propertyRoomDetailsComponents/addNewRoomForm.component"
import PropertyDetails from "./propertyDetailsV2/propertyDetails.component"
import PropertyAmenitiesForm from "./propertyAmenities/propertyAmenitiesForm.component"
import Select from "react-select"
import {
  Link,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min"
import { useDispatch, useSelector } from "react-redux"
import {
  propertyBasicData,
  propertyRoomList,
} from "../../redux/actions/propertyAction"
import PropertyRoomAmenities from "./propertyRoomDetailsComponents/propertyRoomAmenities.component"
import { CloseOutlined } from "@material-ui/icons"
import { toast } from "react-toastify"

import API from "../../Service/Api"
import {
  switchClonePropertyDialogOpen,
  switchCloneTrmAndCdnDialogOpen,
} from "../../redux/actions/clonePropertyAmenities.action"

let DEFAULT_PROPERTY_ID = "20211113084644LaCa"

const useStyles = makeStyles(() => ({
  root: {
    margin: "0",
    padding: "50px 24px",
  },
  title: {
    color: "#f46d25",
  },
  propertyButton: {
    background: "#000000",
  },
  headerStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonGroupButtons: {
    width: "150px",
    background: "#F0F0F0",
    border: "1px solid #D2D2D2",
  },
  buttonLabel: {
    color: "#000000",
    fontWeight: "bold",
  },

  tableHeaderRoom: {
    background: "#F0F0F0",
    borderBottom: "1px solid #D2D2D2",
  },

  tableStyle: {
    borderRadius: "10px",
  },
  tableBody: {
    height: "300px",
    border: "1px solid #D2D2D2",
  },
  tableContainer: {
    border: "1px solid #D2D2D2",
  },
}))

const PropertyV2 = () => {
  const classes = useStyles()
  const [viewSectionIndex, setViewSectionIndex] = useState(0)
  const [openAmenititesCloneModal, setOpenAmenititesCloneModal] =
    useState(false)
  const [propertyDropDown, setPropertyDropDown] = useState([])
  const [propertyOption, setPropertyOption] = useState(null)

  const { propertyId } = useParams()
  const propertyBasicDatas = useSelector(
    (state) => state.propertyBasicData.propertyBasicDatas
  )

  const roomListDetails = useSelector(
    ({ roomListDetails }) => roomListDetails.roomList
  )

  const dispatch = useDispatch()

  const { path, url } = useRouteMatch()
  const history = useHistory()
  console.log("propertyRoute:", { url, path })

  const roomListDetailsLength = useSelector(
    ({ roomListDetails }) => roomListDetails.roomList.length
  )

  const role = localStorage.getItem("role")

  console.log(roomListDetailsLength)

  useEffect(() => {
    if (propertyId) {
      dispatch(propertyRoomList(propertyId))
    }
  }, [propertyId])

  const buttonLabels = [
    {
      label: "Property Details",
      path: `${url}`,
      buttonDisabled: false,
    },
    {
      label: "Room Details",
      path: `/konnect/property/${propertyId}/propertyRoomList`,
      buttonDisabled:
        propertyId != typeof undefined && propertyId != "" && propertyId != null
          ? false
          : true,
    },
    {
      label: "Bank Details",
      path: `/konnect/property/${propertyId}/propertyBankDetails`,
      buttonDisabled:
        propertyId != typeof undefined &&
        propertyId != "" &&
        propertyId != null &&
        roomListDetailsLength > 0
          ? false
          : true,
    },
    {
      label: "Amenities",
      path: `/konnect/property/${propertyId}/propertyAmenities`,
      buttonDisabled:
        propertyId != typeof undefined &&
        propertyId != "" &&
        propertyId != null &&
        roomListDetailsLength > 0
          ? false
          : true,
    },
    {
      label: "Media",
      path: `/konnect/property/${propertyId}/propertyMedia`,
      buttonDisabled:
        propertyId != typeof undefined &&
        propertyId != "" &&
        propertyId != null &&
        roomListDetailsLength > 0
          ? false
          : true,
    },
    {
      label: "T&C / Policies",
      path: `/konnect/property/${propertyId}/propertyTermsAndConditions`,
      buttonDisabled:
        propertyId != typeof undefined &&
        propertyId != "" &&
        propertyId != null &&
        roomListDetailsLength > 0
          ? false
          : true,
    },
  ]

  useEffect(() => {
    if (propertyId) {
      dispatch(propertyBasicData(propertyId))
    }
  }, [propertyId])

  const handleNext = () => {
    const newIndex = (viewSectionIndex + 1) % buttonLabels.length
    setViewSectionIndex(newIndex)
    history.push(buttonLabels[newIndex].path)
  }

  const enableSectionOptionalButton = {
    [`/konnect/property/${propertyId}/propertyRoomList`]: (
      <Link to={`${url}/roomDetails`}>
        <Button style={{ height: "30px" }}>Add New Room</Button>,
      </Link>
    ),

    [`/konnect/property/${propertyId}/propertyAmenities`]: (
      <Button
        style={{
          height: "30px",
          background: "#000000",
          wordWrap: "break-word",
          textOverflow: "ellipsis",
          wordBreak: "break-word",
        }}
        onClick={async () => {
          dispatch(switchClonePropertyDialogOpen())
        }}
      >
        Clone Amenities
      </Button>
    ),

    [`/konnect/property/${propertyId}/propertyTermsAndConditions`]: (
      <Button
        style={{
          height: "30px",
          background: "#000000",
          wordWrap: "break-word",
          textOverflow: "ellipsis",
          wordBreak: "break-word",
        }}
        onClick={async () => {
          console.log("hi")
          dispatch(switchCloneTrmAndCdnDialogOpen())
          console.log("hello")
        }}
      >
        Clone T&C
      </Button>
    ),
  }[window.location.pathname]

  return (
    <section className={classes.root}>
      <header className={classes.headerStyle}>
        <h2 className={classes.title}>
          {propertyBasicDatas.displayName ?? "Add Property"}
        </h2>
        <Button
          className={classes.propertyButton}
          onClick={() => {
            const url =
              role === "Hotel Admin"
                ? `/konnect/hotelView/${propertyId}`
                : "/konnect/propertyList"
            sessionStorage.clear()
            history.push(url)
          }}
        >
          {role !== "Hotel Admin" ? "Property List" : "Back to Hotel View"}
        </Button>
      </header>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <ButtonGroup size="small">
          {buttonLabels.map((button, index) => (
            <Button
              key={index}
              className={classes.buttonGroupButtons}
              style={{
                background:
                  button.path == window.location.pathname
                    ? "#f46d25"
                    : "#F0F0F0",
              }}
              onClick={() => {
                history.push(button.path)
              }}
              disabled={button.buttonDisabled}
            >
              <span
                className={classes.buttonLabel}
                style={{
                  color:
                    button.path == window.location.pathname ? "white" : "black",
                }}
              >
                {button.label}
              </span>
            </Button>
          ))}
        </ButtonGroup>
        {enableSectionOptionalButton}
      </div>
      {/* <>{viewSectionNavigator}</> */}
      <Switch>
        <Route
          exact
          path={`${path}/propertyRoomList`}
          component={PropertyRoomDetails}
        />
        <Route exact path={`${path}`} component={PropertyDetails} />
        <Route
          exact
          path={`${path}/propertyBankDetails`}
          component={PropertybankDetailsForm}
        />
        <Route
          exact
          path={`${path}/propertyAmenities`}
          component={PropertyAmenitiesForm}
        />
        <Route exact path={`${path}/propertyMedia`} component={PropertyMedia} />
        <Route
          exact
          path={`${path}/propertyTermsAndConditions`}
          component={PropertyTermsConditionsAndPolicies}
        />
        <Route
          exact
          path={`${path}/roomDetails/:displayName?`}
          component={AddNewRoomForm}
        />
        <Route
          exact
          path={`${path}/roomDetails/:displayName/roomAmenities`}
          component={PropertyRoomAmenities}
        />
      </Switch>

      <footer style={{ display: "flex", justifyContent: "flex-end" }}>
        {viewSectionIndex < buttonLabels.length - 1 && (
          <Button
            disabled={
              propertyId != typeof undefined &&
              propertyId != "" &&
              propertyId != null
                ? false
                : true
            }
            onClick={handleNext}
            style={{ height: "30px", marginTop: "20px" }}
          >
            Next
          </Button>
        )}
      </footer>
    </section>
  )
}

export default PropertyV2
