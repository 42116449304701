import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core"
import { useEffect, useState } from "react"

import _ from "lodash"
import { useDispatch, useSelector } from "react-redux"
import {
  Link,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min"
import { ReactComponent as EditIconCustom } from "../../../assets/Icons/editCustomIcon.svg"
import ScreenLoader from "../../../components/loadingScreen/screenLoader.component"
import { propertyRoomList } from "../../../redux/actions/propertyAction"
import { toast } from "react-toastify"
import Select from "react-select"

import API from "../../../Service/Api"
import { ActionTypes } from "../../../redux/actions/actionTypes"

let DEFAULT_PROPERTY_ID = "20211113084644LaCa"

const useStyles = makeStyles(() => ({
  tableHeaderRoom: {
    background: "#F0F0F0",
    borderBottom: "1px solid #D2D2D2",
  },
  outerSectionStyles: {
    marginTop: "19px",
  },

  tableStyle: {
    borderRadius: "10px",
  },
  tableBody: {
    height: "fit-content",
  },
  tableContainer: {
    border: "1px solid #D2D2D2",
    borderRadius: "5px",
  },
  tableCellStyle: {
    border: "none",
    textAlign: "justify",
    padding: "10px",
  },
}))

const roomDetailsTableHeader = ["S No.", "Room Name", "Description", "Action"]

const PropertyRoomDetails = ({ setAddNewRoom }) => {
  const classes = useStyles()
  const uniqueId = localStorage.getItem("unique_id")
  const { currentUser } = useSelector(({ userReducer }) => userReducer)
  const { propertyId } = useParams()
  const roomListDetails = useSelector(
    ({ roomListDetails }) => roomListDetails.roomList
  )

  const loading = useSelector(({ roomListDetails }) => roomListDetails.loading)
  const dispatch = useDispatch()
  const history = useHistory()
  const { path, url } = useRouteMatch()

  const [uploadingData, setUploadingData] = useState(false)
  const [propertyDropDown, setPropertyDropDown] = useState([])
  const [propertyOption, setPropertyOption] = useState(null)

  const fetchPropertyDropdown = async () => {
    try {
      const { data } = await API.get(
        `propertydisplaynameall/${localStorage.getItem("unique_id")}`
      )
      const options = data?.map((option) => ({
        label: option.displayName,
        value: option?.propertyId,
      }))
      setPropertyDropDown(options)
    } catch (error) {
      toast.error("unable to fetch the property list")
    }
  }

  useEffect(async () => {
    if (_.isEmpty(roomListDetails)) {
      fetchPropertyDropdown()
    }
  }, [])

  const popularWithGuestsRA = async (displayName) => {
    try {
      const normalData = await API.get(
        `RAmenitiesPopularWithGuestsvalue/${propertyId}/${displayName}`
      )
      const switchData = await API.post(
        "RAmenitiesPopularWithGuestsStarredvalue",
        { propertyId, displayName }
      )

      return { ...normalData.data, ...switchData.data }
    } catch (error) {
      console.error(error)
      return
    }
  }

  const spreadDefaultValues = async () => {
    setUploadingData((prevState) => !prevState)
    try {
      const { data } = await API.get(
        `propertyamenitiesroomallvalue/${propertyOption}`
      )

      console.log({ data })

      const alteredData = data.map((room) => ({
        ...room,
        propertyId: propertyId,
        createdBy: localStorage.getItem("auth"),
        uniqueId,
        updatedBy: "",
        updatedAt: null,
        createdAt: null,
        id: "",
      }))
      console.log({ alteredData })

      alteredData?.forEach(async (roomType) => {
        try {
          // const popularWithGuests = await popularWithGuestsRA(
          //   roomType.displayName
          // );
          // console.log(popularWithGuests);

          const res = await API.post("propertyamenitiesroom", roomType)
        } catch (error) {
          toast.error(error.message)
        }
      })
      setTimeout(() => {
        window.open(`/konnect/property/${propertyId}/propertyRoomList`, "_self")
      }, 2000)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setUploadingData(false)
    }
  }

  useEffect(() => {
    console.log(url)
    if (propertyId) {
      dispatch(propertyRoomList(propertyId))
    }
  }, [propertyId, roomListDetails.length])

  return (
    <section className={classes.outerSectionStyles}>
      {_.isEmpty(roomListDetails) ? (
        <center>
          <h2>Please click on the below button to add room.</h2>

          <Button
            style={{ height: "30px" }}
            onClick={() =>
              history.push(`/konnect/property/${propertyId}/roomDetails`)
            }
            size="small"
          >
            Add New Room
          </Button>
          <p>
            <b>OR</b>
          </p>
          <p>Select any property below to clone the room details</p>
          <Select
            value={propertyDropDown.find(
              (option) => option.value == propertyOption
            )}
            placeholder="Select any property"
            options={propertyDropDown}
            onChange={(option) => setPropertyOption(option.value)}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                width: "300px",
                marginBottom: "10px",
              }),
              container: (provided, state) => ({
                ...provided,
                width: "300px",
                height: state.focused && "350px",
              }),
            }}
          />
          <Button
            size="small"
            style={{
              marginLeft: "15px",
              height: "30px",
              background: "#000000",
            }}
            onClick={spreadDefaultValues}
            disabled={uploadingData}
          >
            Clone Room Data
          </Button>
        </center>
      ) : (
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.tableStyle}>
            <TableHead className={classes.tableHeaderRoom}>
              <TableRow>
                {roomDetailsTableHeader.map((room, index) => (
                  <TableCell key={index} style={{ fontWeight: "bold" }}>
                    {room}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {loading ? (
              <ScreenLoader open={loading} />
            ) : (
              <TableBody className={classes.tableBody}>
                {roomListDetails.map((room, idx) => (
                  <TableRow key={room.id}>
                    <TableCell
                      className={classes.tableCellStyle}
                      style={{ fontWeight: "bold" }}
                    >
                      {idx + 1}.
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      {room.visibleName}
                    </TableCell>
                    <TableCell
                      className={classes.tableCellStyle}
                      style={{ width: "50%" }}
                    >
                      {room.longDescription}
                    </TableCell>
                    <TableCell className={classes.tableCellStyle}>
                      <IconButton
                        title="edit"
                        onClick={() => {
                          history.push(
                            `/konnect/property/${propertyId}/roomDetails/${room.displayName}`
                          )
                        }}
                      >
                        <EditIconCustom
                          style={{ marginLeft: "10%", cursor: "pointer" }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}
    </section>
  )
}

export default PropertyRoomDetails
