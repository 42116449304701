import styles from "./DestinationThingsTodo.module.css"
import globalStyles from "../../../../globalStyles.module.css"
import { useContext, useLayoutEffect } from "react"
import { DestinationLayoutContext } from "../../../../layout/DestinationLayout/Destination.layout"
import QueryForm from "../../../../components/QueryForm/QueryForm.component"
import AsideDestinationNavbar from "../../../../components/AsideDestinationNavbar/AsideDestinationNavbar.component"
import ContactInfo from "../../../../components/ContactInfo/ContactInfo.component"
import ImageAndContentCard from "../../../../components/ImageAndContentCard/ImageAndContentCard.component"
import parser from "html-react-parser"
import BiggerGroupBannerPost from "../../../../components/BiggerGroupBanner/BiggerGroupBanner.component"

import Rafting1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Rafting1.jpg"
import Rafting2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/rafting.jpg"

import Bungee1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Bungee jumping in Rishikesh1.jpg"
import Bungee2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Bungee jumping in Rishikesh2.jpg"
import Bungee3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Bungee jumping in Rishikesh3.jpg"
import Bungee4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Bungee jumping in Rishikesh4.jpg"
import Bungee5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Bungee jumping in Rishikesh5.jpg"

import Camping1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Camping in Uttarakhand1.jpg"
import Camping2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Camping in Uttarakhand2.jpg"
import Camping3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Camping in Uttarakhand3.jpg"
import Camping4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Camping in Uttarakhand4.jpg"
import Camping5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Camping in Uttarakhand5.jpg"

import JeepSafari1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Jeep Safari in Jim Corbett National Park1.jpg"
import JeepSafari2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Jeep Safari in Jim Corbett National Park2.jpg"
import JeepSafari3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Jeep Safari in Jim Corbett National Park3.jpg"
import JeepSafari4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Jeep Safari in Jim Corbett National Park4.jpg"
import JeepSafari5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Jeep Safari in Jim Corbett National Park5.jpg"

import ValleyOfFlowers1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Visit to the Valley of Flowers National Park1.jpg"
import ValleyOfFlowers2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Visit to the Valley of Flowers National Park2.jpg"
import ValleyOfFlowers3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Visit to the Valley of Flowers National Park3.jpg"
import ValleyOfFlowers4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Visit to the Valley of Flowers National Park4.jpg"
import ValleyOfFlowers5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Visit to the Valley of Flowers National Park5.jpg"

import BoatRide1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Boat ride in Naini Lake1.jpg"
import BoatRide2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Boat ride in Naini Lake2.jpg"
import BoatRide3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Boat ride in Naini Lake3.jpg"
import BoatRide4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Boat ride in Naini Lake4.jpg"
import BoatRide5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Boat ride in Naini Lake5.jpg"

import Yoga1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Learn yoga in Rishikesh1.jpg"
import Yoga2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Learn yoga in Rishikesh2.jpg"
import Yoga3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Learn yoga in Rishikesh3.jpg"
import Yoga4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Learn yoga in Rishikesh4.jpg"
import Yoga5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Learn yoga in Rishikesh5.jpg"

import EveningAarti1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Evening Aarti in Haridwar and Rishikesh1.jpg"
import EveningAarti2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Evening Aarti in Haridwar and Rishikesh2.jpg"
import EveningAarti3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Evening Aarti in Haridwar and Rishikesh3.jpg"
import EveningAarti4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Evening Aarti in Haridwar and Rishikesh4.jpg"
import EveningAarti5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Evening Aarti in Haridwar and Rishikesh5.jpeg"

import Paragliding1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Paragliding in Uttarakhand 1.jpg"
import Paragliding2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Paragliding in Uttarakhand 2.jpg"
import Paragliding3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Paragliding in Uttarakhand 3.jpg"
import Paragliding4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Paragliding in Uttarakhand 4.jpg"
import Paragliding5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Paragliding in Uttarakhand 5.jpg"

import Chardham1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Chardham-Yatra.jpeg"
import Chardham2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Chardham-package-cost.jpg"

import ChoptaTrek1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Chopta Chandrashila Trek1.jpg"
import ChoptaTrek2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Chopta Chandrashila Trek2.jpg"
import ChoptaTrek3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Chopta Chandrashila Trek4.jpg"
import ChoptaTrek4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Chopta Chandrashila Trek5.jpg"

import HaarKiDoonTrek1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Har ki Doon Trek1.jpg"
import HaarKiDoonTrek2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Har ki Doon Trek2.jpg"
import HaarKiDoonTrek3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Har ki Doon Trek3.jpg"
import HaarKiDoonTrek4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Har ki Doon Trek5.jpg"

import NagTibbaTrek1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Nag Tibba Trek 1.jpg"
import NagTibbaTrek2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Nag Tibba Trek 2.jpg"
import NagTibbaTrek3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Nag Tibba Trek 3.jpg"
import NagTibbaTrek4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Nag Tibba Trek 4.jpg"
import NagTibbaTrek5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Nag Tibba Trek 5.jpg"

import RoopkundTrek1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Roopkund Trek1.jpg"
import RoopkundTrek2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Roopkund Trek2.jpg"
import RoopkundTrek3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Roopkund Trek3.jpg"
import RoopkundTrek4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Roopkund Trek4.jpg"
import RoopkundTrek5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Roopkund Trek5.jpg"

import KaukiPassTrek1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Kuari Pass Trek1.jpg"
import KaukiPassTrek2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Kuari Pass Trek2.jpg"
import KaukiPassTrek3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Kuari Pass Trek3.jpg"
import KaukiPassTrek4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Kuari Pass Trek4.jpg"
import KaukiPassTrek5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Kuari Pass Trek5.jpg"

import KemptyFalls1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Visit To Kempty Falls in Mussoorie1.jpg"
import KemptyFalls2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Visit To Kempty Falls in Mussoorie2.jpg"
import KemptyFalls3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Visit To Kempty Falls in Mussoorie3.jpg"
import KemptyFalls4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Visit To Kempty Falls in Mussoorie4.jpg"
import KemptyFalls5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Visit To Kempty Falls in Mussoorie5.jpg"

import RajajiNationalPark1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Visit to Rajaji National Park1.jpg"
import RajajiNationalPark2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Visit to Rajaji National Park2.jpg"
import RajajiNationalPark3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Visit to Rajaji National Park3.jpg"
import RajajiNationalPark4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Visit to Rajaji National Park4.jpg"
import RajajiNationalPark5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Visit to Rajaji National Park5.jpg"

import RobbersCave1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Robber’s cave in Dehradun1.jpg"
import RobbersCave2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Robber’s cave in Dehradun2.jpg"
import RobbersCave3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Robber’s cave in Dehradun3.jpg"
import RobbersCave4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Robber’s cave in Dehradun4.jpg"
import RobbersCave5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Robber’s cave in Dehradun5.jpg"

import SahastradharaFalls1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Sahastradhara waterfall in Dehradun1.png"
import SahastradharaFalls2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Sahastradhara waterfall in Dehradun2.jpg"
import SahastradharaFalls3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Sahastradhara waterfall in Dehradun3.jpg"
import SahastradharaFalls4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Sahastradhara waterfall in Dehradun4.jpg"
import SahastradharaFalls5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Sahastradhara waterfall in Dehradun5.jpg"

import Skiing1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Skiing in Auli1.jpg"
import Skiing2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Skiing in Auli2.jpg"
import Skiing3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Skiing in Auli3.jpeg"
import Skiing4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Skiing in Auli4.jpg"
import Skiing5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Skiing in Auli5.jpg"

import BeatlesAshram1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Beatles Ashram in Rishikesh1.jpg"
import BeatlesAshram2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Beatles Ashram in Rishikesh2.jpg"
import BeatlesAshram3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Beatles Ashram in Rishikesh3.jpeg"
import BeatlesAshram4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Beatles Ashram in Rishikesh4.jpg"
import BeatlesAshram5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Beatles Ashram in Rishikesh5.jpg"

import AskotMusk1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Askot Musk Deer Sanctuary1.jpg"
import AskotMusk2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Askot Musk Deer Sanctuary2.jpg"
import AskotMusk3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Askot Musk Deer Sanctuary3.jpg"
import AskotMusk4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Askot Musk Deer Sanctuary4.jpg"
import AskotMusk5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Askot Musk Deer Sanctuary5.jpg"

import Shopping1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Shopping on Mall Road Mussoorie1.jpg"
import Shopping2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Shopping on Mall Road Mussoorie2.jpg"
import Shopping3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Shopping on Mall Road Mussoorie3.jpg"
import Shopping4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Shopping on Mall Road Mussoorie4.jpg"
import Shopping5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Shopping on Mall Road Mussoorie5.jpg"

import EatingMaggie1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Eating Maggi on the way from Dehradun to Mussoorie1.jpg"
import EatingMaggie2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Eating Maggi on the way from Dehradun to Mussoorie2.jpg"
import EatingMaggie3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Eating Maggi on the way from Dehradun to Mussoorie3.jpg"
import EatingMaggie4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Eating Maggi on the way from Dehradun to Mussoorie5.jpg"

import CapturingHalls1 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Capturing the halls of FRI Dehradun1.jpg"
import CapturingHalls2 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Capturing the halls of FRI Dehradun2.png"
import CapturingHalls3 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Capturing the halls of FRI Dehradun3.jpg"
import CapturingHalls4 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Capturing the halls of FRI Dehradun4.jpg"
import CapturingHalls5 from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to do/Capturing the halls of FRI Dehradun5.jpeg"
import DestinationSubLayout from "../../../../layout/DestinationSubLayout/DestinationSub.layout"

const todos = [
  {
    title: "Rafting in Rishikesh",
    content: `Rafting across the river Ganga as you navigate the rapids from grade 1 to grade 4 is every adventure seekers dream. One of the most popular Uttarakhand attractions, you can book a rafting adventure by itself or you can combine it with an overnight camping treat which will also let you do other activities such as Rappelling, Rock Climbing, Trekking and Kayaking.
<p>The best time to go rafting is from March to June in the summer season and October to November before the water get extremely freezing.  Rafting is suspended during the monsoon months of July to august as the rains become torrential and the river water becomes unpredictable.</p>`,

    src: [Rafting1, Rafting2],
  },
  {
    title: "Bungee jumping in Rishikesh",
    content: `An extreme adventure sport, bungee jumping is fast becoming very popular amongst youngsters and is definitely one of the most sought-after things to do in Uttarakhand. Located at Mohan Chatti in Rishikesh, bungee jumping is conducted over a tributary of the river Ganga amongst a scenic view of the mountains and river.
<p>This is one of the best adventure activities in India and definitely not recommended for the faint hearted as it sets your heartbeat on fire as well as sends adrenaline to your blood.</p>`,
    src: [Bungee1, Bungee2, Bungee3, Bungee4, Bungee5],
  },
  {
    title: "Camping in Uttarakhand",
    content: ` One of the best and most economical ways to spend time in nature is to camp in its cradle. From Rishikesh to Dhanaulti to Chopta and Meadows such as  Dayara Bugyal and Roopkund,  camping is one of the most popular Uttarakhand attractions.  Camping can provide you with proximity to nature as well as an opportunity to unwind away from the city.  Whether you plan it as a weekend getaway for family friends or couples, camping trips leave everyone rejuvenated. The summer months from March to June are perfect for camping as the sky is clear which gives you up great panoramic view`,
    src: [Camping1, Camping2, Camping3, Camping4, Camping5],
  },
  {
    title: "Jeep Safari in Jim Corbett National Park",
    content: `One of the oldest national parks in India, Jim Corbett National Park is home to the Royal Bengal Tiger along with 400 species of plants and 500 species of birds. You can also spot elephants, leopards, and Himalayan black bears. If you are wondering what to do in Uttarakhand, a jungle safari should be on top of your list. The best way to visit the park is during the months of February to March when the animals come out of hibernation and the plants and flora are in full bloom.`,
    src: [JeepSafari1, JeepSafari2, JeepSafari3, JeepSafari4, JeepSafari5],
  },
  {
    title: "Visit to the Valley of Flowers National Park",
    content: `The Valley of Flowers National Park is a UNESCO World Heritage site. Located in Chamoli district of Uttarakhand near Hemkund Sahib, the valley has millions of species of flowers and is one of the internationally renowned<b>things to do in Uttarakhand</b>.  Most of these flora bloom only during the monsoon season. The best time to visit this Valley is during the months of June to September. Located at an altitude of 15000 feet, the trek to this valley is moderately difficult but definitely worth the effort as you get to visit the diversity of flowers. The valley is also home to the Hemkund Sahib Gurdwara, which is one of the holiest sites for Sikhs in India.  `,
    src: [
      ValleyOfFlowers1,
      ValleyOfFlowers2,
      ValleyOfFlowers3,
      ValleyOfFlowers4,
      ValleyOfFlowers5,
    ],
  },
  {
    title: "Boat ride in Naini Lake",
    content: `The unique mango-shaped Naini lake is located in the heart of the hill station of Nainital and is one of the most famous places in Nainital. The view of the mountains surrounding Nainital should not be missed and the best way to absorb the beauty is to go for a boat ride to the lake. The boat ride in Nainital is one of the famous tourist attractions in Uttarakhand since time immemorial. The boat ride is often suspended during the monsoon and during the winter months when the lake begins to freeze.  Therefore, it is best enjoy the boat ride during the summer months.`,
    src: [BoatRide1, BoatRide2, BoatRide3, BoatRide4, BoatRide5],
  },
  {
    title: "Learn yoga in Rishikesh",
    content: `Apart from being the Adventure Capital of India, Rishikesh is also known as the yoga capital of the world.  With a number of Ashrams and experienced Gurus who practice different forms of yoga, Rishikesh is a perfect place for beginners as well as advanced yoga enthusiasts. The international yoga festival that is held in March attracts tourists from the world over who flock to the city to learn the intricacies of yoga. If you’re aiming for a stress-free life and more control over your mind and body, yoga should be top of your <b>list of things to do in Uttarakhand</b>. `,
    src: [Yoga1, Yoga2, Yoga3, Yoga4, Yoga5],
  },
  {
    title: "Evening Aarti in Haridwar and Rishikesh",
    content: `As the sun goes down every evening in Haridwar, Ganga aarti is performed at the bank of river Ganga at Har ki Pauri. A similar ritual is carried out at Rishikesh as well. Accompanied by chants and hymns, the Ganga Aarti produces a mystifying and reverberating presence as the crowd joins in mesmerized by the priests. If you are looking at what to do in Uttarakhand, attending the Ganga Arti in Haridwar or Rishikesh should be on your bucket list! Irrespective of your religious beliefs, the Aarti leave one and all mesmerized. `,
    src: [
      EveningAarti1,
      EveningAarti2,
      EveningAarti3,
      EveningAarti4,
      EveningAarti5,
    ],
  },
  {
    title: "Paragliding in Uttarakhand ",
    content: `If you enjoy the experience of soaring through the sky and adventure is your forte, then you must head to Ranikhet on Mukteshwar in Uttarakhand for a paragliding adventure. Experts will guide you through your adventure making it both safe, exhilarating, and a popular activity to do in Uttarakhand. The best time to go paragliding is during the summer months of March to June during monsoon and winter paragliding suction suspended`,
    src: [Paragliding1, Paragliding2, Paragliding3, Paragliding4, Paragliding5],
  },
  {
    title: "Char Dham Yatra ",
    content: `Rightly named as the Land of the Gods, Uttarakhand is home to the four Dhams which are important to the Hindu Traveller. Yamunotri, Gangotri, Badrinath, and Kedarnath are the four dams that make the perfect pilgrimage if you have a religious mind and an adventurous spirit. The Char Dham yatra with its scenic view, religious significance, and temples make it one of the most sought-after things to do in Uttarakhand.`,
    src: [Chardham1, Chardham2],
  },
  {
    title: "Chopta Chandrashila Trek",
    content: `The Chandrashila Trek is one of the most coveted treks in the trekking community because of its difficulty and the views.  Once you cross the Tungnath temple, the Trek offers scenic views of the Nanda Devi, Kedarnath. Trishul and Chaukhamba. The Tungnath temple is known to be the highest Temple in the Himalayas and is one of the most popular Uttarakhand Attractions.`,
    src: [ChoptaTrek1, ChoptaTrek2, ChoptaTrek3, ChoptaTrek4],
  },
  {
    title: "Har ki Doon Trek",
    content: `One of the popular treks in the Himalayas, this moderately difficult trek is one of the popular things to do in Uttarakhand. Completed over a 7-day period you can also see the rare scenery as you traverse the tree-covered mountains. The trek culminates in Har ki Doon, a sacred place for Hindus and it is believed that the Pandavas ascended to Heaven at this very destination.`,
    src: [HaarKiDoonTrek1, HaarKiDoonTrek2, HaarKiDoonTrek3, HaarKiDoonTrek4],
  },
  {
    title: "Nag Tibba Trek",
    content: `Popularly known as the Mountain of The Serpent, Nag Tibba is one of the very popular and shorter treks on the list of things to do in Uttarakhand. It is easily done and is known for its beautiful views during sunset and sunrise.`,
    src: [
      NagTibbaTrek1,
      NagTibbaTrek2,
      NagTibbaTrek3,
      NagTibbaTrek4,
      NagTibbaTrek5,
    ],
  },
  {
    title: "Roopkund Trek",
    content: `Also known as the Skeleton Lake Trek, the Roopkund trek is extremely challenging. If you are an adventure lover, then this trek is your answer to what to do in Uttarakhand. The best time to complete this is during the winter when you can view the snow-capped peaks and the bugyal grasslands.`,
    src: [
      RoopkundTrek1,
      RoopkundTrek2,
      RoopkundTrek3,
      RoopkundTrek4,
      RoopkundTrek5,
    ],
  },
  {
    title: "Kuari Pass Trek ",
    content: `One of the easy treks, this Uttarakhand attraction is also called as Curzon trek as Lord Curzon was the first one to complete the trek in 1905. It provides spectacular views of the Dronagiri, Nanda Devi, and Kedarnath peaks.`,
    src: [
      KaukiPassTrek1,
      KaukiPassTrek2,
      KaukiPassTrek3,
      KaukiPassTrek4,
      KaukiPassTrek5,
    ],
  },
  {
    title: "Visit To Kempty Falls in Mussoorie",
    content: `An ideal place for a day picnic, Kempty Falls is extremely close to Mussoorie and situated on the Mussoorie-Dehradun highway. Relax as you see the waterfall and enjoy a steaming hot plate of Maggi as you take in the scenery in this Uttarakhand attraction.`,
    src: [KemptyFalls1, KemptyFalls2, KemptyFalls3, KemptyFalls4, KemptyFalls5],
  },
  {
    title: "Visit to Rajaji National Park",
    content: `An amazing wildlife sanctuary in Uttarakhand spread over more than 800 km2 the Rajaji National Park is situated near the Shivalik mountains with diverse fauna and flora, a mix of Sal and Alpine forests, 23 species of mammals, and 315 species of birds. Rajaji National Park is one of the top things to do in Uttarakhand for wildlife enthusiasts.`,
    src: [
      RajajiNationalPark1,
      RajajiNationalPark2,
      RajajiNationalPark3,
      RajajiNationalPark4,
      RajajiNationalPark5,
    ],
  },
  {
    title: "Robber’s cave in Dehradun",
    content: `Traditionally named the Robber’s Cave because robbers allegedly used the cave to hide stolen goods. This is a fun place to visit if you are visiting Dehradun and are looking for ideas for a day trip. `,
    src: [RobbersCave1, RobbersCave2, RobbersCave3, RobbersCave4, RobbersCave5],
  },
  {
    title: "Sahastradhara waterfall in Dehradun",
    content: `Famous for its sulfur water springs, the water is allegedly endowed with medicinal properties and can cure skin ailments, This waterfall is located about 10 km from Dehradun. The best time to visit this waterfall is during the monsoon or after the monsoon during the month of September to November when it is full of water.`,
    src: [
      SahastradharaFalls1,
      SahastradharaFalls2,
      SahastradharaFalls3,
      SahastradharaFalls4,
      SahastradharaFalls5,
    ],
  },

  {
    title: "Skiing in Auli",
    content: `The perfect destination for winter sports with its amazing slopes covered with snow, Auli tops the list of things to do in Uttarakhand when it comes to winter sports. January to March is the perfect time to take part in winter sports surrounded by mountains or chats and open the other forests all is the perfect place to trials king in India `,
    src: [Skiing1, Skiing2, Skiing3, Skiing4, Skiing5],
  },
  {
    title: "Beatles Ashram in Rishikesh",
    content: `Also known as Chaurasi Kutia, the Beatles Ashram is located on the bank of the river Ganga in Rishikesh. It used to be the training center for students of Maharishi Mahesh Yogi and was abandoned in 1990. The Ashram is renowned because the famous musical band Beatles stayed here and studied transcendental meditation. Tribute to the musical geniuses in the form of murals adorn the walls of the Ashram. The Ashram is a shrine to the Beatles and is recommended among things to do in Uttarakhand for Beatles fans across the world`,
    src: [
      BeatlesAshram1,
      BeatlesAshram2,
      BeatlesAshram3,
      BeatlesAshram4,
      BeatlesAshram5,
    ],
  },

  {
    title: "Askot Musk Deer Sanctuary",
    content: `Located at Askot, which is about 50 kilometers from Pithoragarh and designed to protect the Askot Musk deer, the Sanctuary also is home to the Bengal tiger, the Indian leopard the Himalayan Jungle cat, and the Himalayan brown bear. The Pancholi peak can be seen from the sanctuary. The best time to visit this Sanctuary during your Uttarakhand trip is during the summer months during the winter most of the sanctuary is covered with snow.`,
    src: [AskotMusk1, AskotMusk2, AskotMusk3, AskotMusk4, AskotMusk5],
  },
  {
    title: "Shopping on Mall Road Mussoorie",
    content: `One of the most renowned places to shop in Uttarakhand is a stretch of road known as Mall Road. Overlooking a great view, the beautiful Mall Road extends from Picture Palace in the east to the Library in the west. The stretch is home to various small and large restaurants, food establishments as well as shopping points. From intricately carved woodwork to locally made woollens, and even low-cost electronics, you can shop till you drop for souvenirs at this local market.`,
    src: [Shopping1, Shopping2, Shopping3, Shopping4, Shopping5],
  },
  {
    title: "Eating Maggi on the way from Dehradun to Mussoorie",
    content: `Every traveler's dream is to eat piping hot Maggi as you look over the mountains and inhale the clean air. As you travel from Dehradun to Mussoorie, there are numerous Maggi points, where you can stop and bring your culinary dream to a reality on Uttarakhand trip.`,
    src: [EatingMaggie1, EatingMaggie2, EatingMaggie3, EatingMaggie4],
  },
  {
    title: "Capturing the halls of FRI Dehradun",
    content: `An architectural wonder, the Forest Reserve Institute of India is not only one of its kind but also a photographer’s dream.`,
    src: [
      CapturingHalls1,
      CapturingHalls2,
      CapturingHalls3,
      CapturingHalls4,
      CapturingHalls5,
    ],
  },
]

const DestinationThingsTodo = () => {
  const { setBanner } = useContext(DestinationLayoutContext)

  useLayoutEffect(() => {
    setBanner("thingsTodo")
  }, [])

  return (
    <DestinationSubLayout>
      <main className={globalStyles.main}>
        <article className={globalStyles.topMostSection}>
          <header>
            Things To <span>Do</span>
          </header>
          <div className={globalStyles.content}>
            With its panoramic views of the Himalayas, lush green meadows
            flowing rivers and streams, Uttarakhand is fast growing to be one of
            the most popular tourist destinations in India. Whether you’re
            looking for an action-packed weekend or an adventurous trek, a
            wildlife getaway surrounded by pine and deodar forest or just bask
            in the blessings of the Gods in the Himalayas, Uttarakhand is the
            perfect destination for you.{" "}
            <p>
              There are an abundant number of things to do in Uttarakhand and
              places to visit for everyone:
            </p>
          </div>
        </article>
        <article className={styles.todoList}>
          {todos.map((todo, idx) => (
            <ImageAndContentCard key={idx + 1}>
              <ImageAndContentCard.Title>
                {idx + 1}. {todo.title}
              </ImageAndContentCard.Title>
              <ImageAndContentCard.Content>
                {parser(todo.content)}
              </ImageAndContentCard.Content>
              <div className={styles.imageList}>
                {todo.src.map((_, i) => (
                  <div className={styles.imageItem} key={i}>
                    <img src={_} />
                  </div>
                ))}
              </div>
            </ImageAndContentCard>
          ))}
        </article>
        <BiggerGroupBannerPost />
      </main>
    </DestinationSubLayout>
  )
}

export default DestinationThingsTodo
