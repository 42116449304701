import { useContext, useRef } from "react"
import QueryForm from "../QueryForm/QueryForm.component"
import styles from "./ContactModal.module.css"

import { Backdrop, Grow, Modal } from "@material-ui/core"
import { ContactModalContext } from "../../context/contactModal.context"

const ContactModal = () => {
  const { open, onClose } = useContext(ContactModalContext)
  const modalRef = useRef(null)
  return (
    <Modal
      className={styles.modal}
      open={open}
      onClose={onClose}
      BackdropProps={{
        style: { background: "none", backdropFilter: "blur(0.5rem)" },
        timeout: 500,
      }}
      closeAfterTransition
    >
      <Grow in={open}>
        <div className={styles.modalBody}>
          <p className={styles.header1}>Can't find what you are looking for?</p>
          <p className={styles.header2}>Drop in the details below</p>
          <QueryForm />
        </div>
      </Grow>
    </Modal>
  )
}

export default ContactModal
