/* eslint-disable eqeqeq */
import DateFnsUtils from "@date-io/date-fns"
import {
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Visibility, VisibilityOff } from "@material-ui/icons/"
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { format } from "date-fns"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { DatePicker } from "formik-material-ui-pickers"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import * as Yup from "yup"
import Api from "../../Service/Api"
import { baseurl } from "../../Service/httpCommon"
import Profile from "../../assets/pictures/profile.jpg"
import {
  branchOnlyInitial,
  designationOnlyInitial,
} from "../../redux/actions/dropsAction"
import {
  staffListInitial,
  staffclearFormInitial,
  staffUpdateInitial,
  staffdetailsInitial,
} from "../../redux/actions/staffAction"
import { twnButtonStyles } from "../../utils/townoStyle"
import "./Staff.css"
import Thumb from "./Thumb"
import Checkbox from "@material-ui/core/Checkbox"
import Loader from "../../components/Loader/Loader"

var CryptoJS = require("crypto-js")

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: "94vh",
    maxHeight: "100vh",
    minWidth: "70%",
    padding: "20px 40px",
    position: "absolute",
    margin: "0px",
    right: "0",
    zIndex: "1000",
  },
  error: {
    color: "red",
  },
  heading: {
    fontSize: "24px",
  },
  create: {
    background: "#5cb85c",
    color: "#fff",
    margin: "5px",
  },
}))

const initialValues = {
  id: "",
  singleFile: "",
  uniqueId: "",
  profile: "",
  branchId: "",
  role: "",
  designation: "",
  genderType: "",
  firstName: "",
  middleName: "",
  lastName: "",
  nickName: "",
  dob: "",
  mobile: "",
  altMobile: "",
  phone: "",
  mail: "",
  altMail: "",
  username: "",
  revenue: false,
}

export default function StaffForm(props) {
  const classes = useStyles()
  let hasAdmin = localStorage.getItem("role")
  const [staffError, setStaffError] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [staffErrorUsername, setStaffErrorUsername] = useState(false)
  const [userNameValid, setUserNameValid] = useState(false)
  const [emailValid, setEmailValid] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)
  var uniqueid = localStorage.getItem("unique_id")
  var createdBy = localStorage.getItem("auth")
  const create = { uniqueId: uniqueid, createdBy: createdBy }
  const dispatch = useDispatch()
  // const roleOnlyList = useSelector((state) => state.roleOnlyList);
  const [roleOnlyLists, setRoleOnlyLists] = useState([])
  const designationOnlyList = useSelector((state) => state.designationOnlyList)
  const { designationOnlyLists } = designationOnlyList
  const branchOnlyList = useSelector((state) => state.branchOnlyList)
  const { branchOnlyLists } = branchOnlyList
  const staffDetails = useSelector((state) => state.staffDetails)
  const { staffDetail, loading } = staffDetails
  const { onClose, selectedId, open } = props
  const { decryptpassword, setDecryptpassword } = useState("")

  useEffect(() => {
    dispatch(designationOnlyInitial(uniqueid))
    dispatch(branchOnlyInitial(uniqueid))
    // dispatch(roleOnlyInitial(uniqueid));
    if (selectedId) {
      dispatch(staffdetailsInitial(selectedId))
    }
  }, [selectedId, dispatch, uniqueid])

  useEffect(() => {
    let url = `/roleonly/${uniqueid}`
    if (hasAdmin === "Agent Admin") {
      url = `agentroleonly/${uniqueid}`
    }
    Api.get(url).then((res) => {
      setRoleOnlyLists(res.data)
    })
  }, [])

  async function onSubmit(fields, { setStatus }) {
    setSubmitting(true)
    const id = selectedId
    setStatus()
    if (id) {
      await updateStaff(fields)
    } else {
      await createStaff(fields)
    }
  }

  async function createStaff(fields) {
    if (fields.dob != "" && fields.dob != null) {
      fields.dob = format(fields.dob, "yyyy-MM-dd")
    }
    const formData = new FormData()
    formData.append("id", 0)
    formData.append("uniqueId", uniqueid)
    formData.append("branchId", fields.branchId)
    formData.append("role", fields.role)
    formData.append("username", fields.username)
    formData.append("designation", fields.designation)
    formData.append("genderType", fields.genderType)
    formData.append("firstName", fields.firstName)
    formData.append("middleName", fields.middleName)
    formData.append("lastName", fields.lastName)
    formData.append("nickName", fields.nickName)
    formData.append("dob", fields.dob)
    formData.append("mobile", fields.mobile)
    formData.append("altMobile", fields.altMobile)
    formData.append("phone", fields.phone)
    formData.append("mail", fields.mail)
    formData.append("altMail", fields.altMail)
    formData.append("city", fields.city)
    formData.append("nationality", fields.nationality)
    formData.append("address", fields.address)
    formData.append("createdBy", createdBy)
    formData.append("password", "")
    formData.append("image", fields.singleFile)
    formData.append("revenue", fields.revenue)
    Api.post("staff-details", formData).then((res) => {
      onClose(true)
      toast.success("New Staff added successfully")
      dispatch(staffListInitial(uniqueid))
    })
    // await setTimeout(() => {
    //   window.open("/konnect/staff", "_self")
    // }, 2000)
  }

  function updateStaff(fields) {
    if (fields.dob != "" && fields.dob != null) {
      fields.dob = format(fields.dob, "yyyy-MM-dd")
    }
    const formData = new FormData()
    formData.append("id", fields.id)
    formData.append("uniqueId", uniqueid)
    formData.append("branchId", fields.branchId)
    formData.append("role", fields.role)
    formData.append("username", fields.username)
    formData.append("designation", fields.designation)
    formData.append("password", fields.password)
    formData.append("genderType", fields.genderType)
    formData.append("firstName", fields.firstName)
    formData.append("middleName", fields.middleName)
    formData.append("lastName", fields.lastName)
    formData.append("nickName", fields.nickName)
    formData.append("dob", fields.dob)
    formData.append("mobile", fields.mobile)
    formData.append("altMobile", fields.altMobile)
    formData.append("phone", fields.phone)
    formData.append("mail", fields.mail)
    formData.append("altMail", fields.altMail)
    formData.append("city", fields.city)
    formData.append("nationality", fields.nationality)
    formData.append("address", fields.address)
    formData.append("createdBy", createdBy)
    formData.append("image", fields.singleFile)
    formData.append("revenue", fields.revenue)

    console.log({ fields })
    try {
      Api.post("staff-details", formData).then(async (res) => {
        onClose(true)
        toast.success("Update Successfull")
        // await setTimeout(() => {
        //   window.open("/staff", "_self")
        // }, 2000)
        dispatch(staffListInitial(uniqueid))
      })
    } catch (error) {
      console.log(error)
    } finally {
      setSubmitting(false)
    }
    dispatch(staffUpdateInitial(fields, uniqueid))
    onClose(true)
  }

  const handleClose = () => {
    onClose(true)
    dispatch(staffclearFormInitial())
    setStaffError(false)
    setStaffErrorUsername(false)
  }

  const validationSchema = Yup.object({
    // branchId: Yup.string().required("Branch ID Required"),
    dob: Yup.date().required("D.O.B Required"),
    mail: Yup.string()
      .email("Enter correct Format")
      .strict()
      .trim()
      .required("Email is Required"),
    role: Yup.string().required("Role Required"),
    username: Yup.string().required("Role Required"),
    designation: Yup.string().required("Designation Required"),
    firstName: Yup.string().min(3, "Too Short!").required("FirstName Required"),
    lastName: Yup.string().required("LastName Required"),
    mobile: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Invalid Mobile")
      .min(10, "Invalid Mobile Number")
      .max(10, "Invalid Mobile Number")
      .required("Mobile No required"),
  })

  const validationStaff = Yup.object({
    username: Yup.string().required("Role Required"),
  })

  const validCheck = async (values) => {
    if (values) {
      // const valid = { username: values, id: staffDetail.id };
      // Api.post("staffcheck/" + uniqueid, valid).then((res) => {
      console.log("staffDetail: ", staffDetail)
      let url = "staffemailexist/" + values
      console.log("url: ", url)
      if (staffDetail.id) {
        url = "staffemailexist/" + values + "/" + staffDetail.id
      }
      Api.get(url).then((res) => {
        if (res.data == "Invalid") {
          setStaffError(true)
        } else {
          setStaffError(false)
        }
      })
    }
    const nameField = { username: values }
    const isValid = await validationStaff.isValid(nameField)
    setEmailValid(!isValid)
  }
  const validUsernameCheck = async (values) => {
    if (values) {
      // const valid = { username: values, id: staffDetail.id };
      // Api.post("staffcheck/" + uniqueid, valid).then((res) => {
      console.log("staffDetail: ", staffDetail)
      let url = "staffusernamecheck/" + values
      console.log("url: ", url)
      if (staffDetail.id) {
        url = "staffusernamecheck/" + values + "/" + staffDetail.id
      }
      Api.get(url).then((res) => {
        if (res.data == "Invalid") {
          setStaffErrorUsername(true)
        } else {
          setStaffErrorUsername(false)
        }
      })
    }
    const nameField = { username: values }
    const isValid = await validationStaff.isValid(nameField)
    setUserNameValid(!isValid)
  }

  if (loading) return <Loader />

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Dialog
          classes={{ paper: classes.dialogPaper }}
          open={open}
          onClose={handleClose}
          fullWidth
        >
          <Typography
            variant="h5"
            component="h5"
            style={{ ...twnButtonStyles.lgFonts, color: "#f46d25" }}
          >
            {staffDetail.id ? "Edit Staff" : "Add New Staff"}
          </Typography>
          <Formik
            initialValues={staffDetail || initialValues}
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={validationSchema}
          >
            {({ values, isValid, setFieldValue, isSubmitting }) => {
              {
                console.log(Object.keys(values).length)
                console.log(Object.keys(staffDetail).length)
              }

              return (
                <Form autoComplete="off">
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12}>
                      <div className="avatar">
                        {values.singleFile ? (
                          <Thumb file={values.singleFile} />
                        ) : (
                          <>
                            {staffDetail.profile !== null &&
                            staffDetail.profile !== undefined ? (
                              <img
                                src={`${baseurl}getimage/${staffDetail.profile}`}
                                alt=""
                              />
                            ) : (
                              <img src={Profile} alt="" />
                            )}
                          </>
                        )}
                        <span>
                          <AddAPhotoIcon
                            style={{ color: "#F46D25" }}
                          ></AddAPhotoIcon>
                          <p>Change</p>

                          <input
                            type="file"
                            id="file_up"
                            name="singleFile"
                            onChange={(event) => {
                              setFieldValue(
                                "singleFile",
                                event.currentTarget.files[0]
                              )
                            }}
                            accept="image/*"
                          />
                        </span>
                      </div>
                    </Grid>

                    <Grid item xs={10} sm={4} lg={3}>
                      <Field
                        as={TextField}
                        name="firstName"
                        value={values.firstName || ""}
                        label="First Name"
                        fullWidth
                        required
                        size="small"
                      />
                      <ErrorMessage name="firstName">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={10} sm={4} lg={3}>
                      <Field
                        as={TextField}
                        name="middleName"
                        value={
                          values.middleName !== typeof undefined
                            ? values.middleName
                            : ""
                        }
                        label="Middle Name"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={10} sm={4} lg={3}>
                      <Field
                        as={TextField}
                        name="lastName"
                        value={values.lastName || ""}
                        label="Last Name"
                        fullWidth
                        required
                        size="small"
                      />
                      <ErrorMessage name="lastName">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={10} sm={4} lg={3}>
                      <Field
                        as={TextField}
                        name="username"
                        value={values.username || ""}
                        label="Display Name"
                        fullWidth
                        onBlur={() => validUsernameCheck(values.username)}
                        required
                        size="small"
                      />
                      {staffErrorUsername ? (
                        <span style={{ color: "red" }}>
                          Display Name Already There!
                        </span>
                      ) : null}

                      <ErrorMessage name="username">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={10} sm={4} lg={3}>
                      <FormControl fullWidth>
                        <InputLabel>Role*</InputLabel>
                        <Field
                          name="role"
                          label="Role"
                          as={Select}
                          size="small"
                          value={values.role || ""}
                        >
                          {roleOnlyLists.map((role) => {
                            return (
                              <MenuItem key={role} value={role}>
                                {role}
                              </MenuItem>
                            )
                          })}
                        </Field>
                        <ErrorMessage name="role">
                          {(error) => (
                            <div className={classes.error}>{error}</div>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    </Grid>
                    <Grid item xs={10} sm={4} lg={3}>
                      <FormControl fullWidth>
                        <InputLabel>Designation*</InputLabel>
                        <Field
                          name="designation"
                          label="Designation"
                          as={Select}
                          size="small"
                          value={values.designation || ""}
                        >
                          {designationOnlyLists.map((designation) => {
                            return (
                              <MenuItem key={designation} value={designation}>
                                {designation}
                              </MenuItem>
                            )
                          })}
                        </Field>
                      </FormControl>
                      <ErrorMessage name="designation">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={10} sm={4} lg={3}>
                      {/* {hasAdmin === 'Agent Admin' ?
                        <Field
                          as={TextField}
                          name="branchId"
                          value={values.branchId || ""}
                          label="BranchId"
                          fullWidth
                          size="small"
                        /> : */}
                      <FormControl fullWidth>
                        <InputLabel>Branch</InputLabel>
                        <Field
                          name="branchId"
                          label="BranchId"
                          as={Select}
                          size="small"
                          value={values.branchId || ""}
                        >
                          {branchOnlyLists.map((branchId) => {
                            return (
                              <MenuItem key={branchId} value={branchId}>
                                {branchId}
                              </MenuItem>
                            )
                          })}
                        </Field>
                        {/* <ErrorMessage name="branchId">
                          {(error) => (
                            <div className={classes.error}>{error}</div>
                          )}
                        </ErrorMessage> */}
                      </FormControl>
                      {/* } */}
                    </Grid>
                    <Grid item xs={10} sm={4} lg={3}>
                      <FormControl fullWidth>
                        <InputLabel>Gender</InputLabel>
                        <Field
                          name="genderType"
                          label="Gender"
                          as={Select}
                          size="small"
                          value={values.genderType || ""}
                        >
                          <MenuItem value="male">Male</MenuItem>
                          <MenuItem value="female">Female</MenuItem>
                        </Field>
                      </FormControl>
                    </Grid>

                    <Grid item xs={10} sm={4} lg={3} size="small">
                      <Field
                        as={TextField}
                        name="nickName"
                        label="Nick Name"
                        fullWidth
                        value={
                          values.nickName !== typeof undefined
                            ? values.nickName
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={10} sm={4} lg={3}>
                      <Field
                        name="dob"
                        component={DatePicker}
                        autoOk
                        label="DOB"
                        value={values.dob || ""}
                        size="small"
                        required
                        format="dd/MM/yyyy"
                        maxDate={new Date()}
                        inputVariant="standard"
                        variant="inline"
                      />
                    </Grid>
                    <Grid item xs={10} sm={4} lg={3}>
                      <Field
                        as={TextField}
                        value={values.mobile || ""}
                        name="mobile"
                        label="Mobile"
                        fullWidth
                        required
                        size="small"
                      />
                      <ErrorMessage name="mobile">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={10} sm={4} lg={3}>
                      <Field
                        as={TextField}
                        value={
                          values.altMobile !== typeof undefined
                            ? values.altMobile
                            : ""
                        }
                        name="altMobile"
                        label="Alt Mobile"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={10} sm={4} lg={3}>
                      <Field
                        name="phone"
                        value={
                          values.phone !== typeof undefined ? values.phone : ""
                        }
                        label="Phone"
                        as={TextField}
                        fullWidth
                        size="small"
                      />
                      <ErrorMessage name="phone">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={10} sm={4} lg={3}>
                      <Field
                        as={TextField}
                        name="mail"
                        value={values.mail || ""}
                        onBlur={() => validCheck(values.mail)}
                        label="Email"
                        fullWidth
                        required
                        size="small"
                      />
                      {staffError ? (
                        <span style={{ color: "red" }}>
                          Email Already There!
                        </span>
                      ) : null}
                      <ErrorMessage name="mail">
                        {(error) => (
                          <div className={classes.error}>{error}</div>
                        )}
                      </ErrorMessage>
                    </Grid>

                    {staffDetail.id && (
                      <Grid item xs={10} sm={4} lg={3}>
                        <Field
                          as={TextField}
                          name="password"
                          label="Password"
                          autoComplete="off"
                          autoCorrect="off"
                          spellCheck="off"
                          value={values.password || ""}
                          fullWidth
                          required
                          size="small"
                          type={showPassword ? "text" : "password"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <ErrorMessage name="password">
                          {(error) => (
                            <div className={classes.error}>{error}</div>
                          )}
                        </ErrorMessage>
                      </Grid>
                    )}
                    <Grid item xs={10} sm={4} lg={3}>
                      <Field
                        name="altMail"
                        value={
                          values.altMail !== typeof undefined
                            ? values.altMail
                            : ""
                        }
                        label="Alt Email"
                        as={TextField}
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    {console.log(values.revenue)}
                    <Grid item xs={4}>
                      <Field
                        as={FormControlLabel}
                        control={
                          <Field
                            as={Checkbox}
                            checked={values?.revenue}
                            color="primary"
                            onChange={(e) => {
                              console.log(e.target.checked)
                              setFieldValue("revenue", e.target.checked)
                            }}
                            name="revenue"
                            // onChange={(e) => {
                            //   console.log(e.target.checked)
                            //   setFieldValue("revenue", e.target.checked)
                            // }}
                          />
                        }
                        label="ACTIVE SALES"
                      />
                    </Grid>

                    <Grid item sm={12} style={{ textAlign: "center" }}>
                      <Button
                        type="submit"
                        className={classes.button}
                        disabled={
                          !isValid ||
                          userNameValid ||
                          staffError ||
                          staffErrorUsername
                        }
                      >
                        {staffDetail.id ? "Update" : "Create"}
                      </Button>

                      <Button
                        color="secondary"
                        onClick={handleClose}
                        style={{
                          background: "#121212",
                          color: "#fff",
                          margin: "10px",
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Dialog>
      </MuiPickersUtilsProvider>
    </>
  )
}
