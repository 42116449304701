import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Grid,
  InputAdornment,
  Slide,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import React, { useEffect, useReducer, useState } from "react"
import Select, { components } from "react-select"
// import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextareaAutosize from "@mui/base/TextareaAutosize"

import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import DateFnsUtils from "@date-io/date-fns"
import { makeStyles } from "@material-ui/core/styles"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import DeleteIcon from "@material-ui/icons/Delete"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { styled } from "@material-ui/styles"
import { format, parse } from "date-fns"
import addDays from "date-fns/addDays"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Api from "../../Service/Api"
import { getPaymentModeOptions } from "../../redux/actions/commonFeatureDropdown.action"
import { twnButtonStyles } from "../../utils/townoStyle"
import Loader from "./../../components/Loader/Loader"
import ClientRefresh from "./ClientRefresh"
import Preview from "./Preview"
import RefundPage from "./RefundPage"
import { Checkbox, FormControlLabel } from "@material-ui/core"

const { ValueContainer, Placeholder } = components

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  )
}

const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value,
  }
}

const objectReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value,
  }
}

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
)

const validMobileRegex = RegExp(
  // /^(\+\d{1,3}[- ]?)?\d{10}$/i
  /^(\+\d{1,3}[- ]?)?\d{5}[- ]?\d{5}$/i
)

const validateForm = (errors) => {
  let valid = true
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false))
  return valid
}

const HotelCategory = [
  { label: "1 Star", value: "1Star" },
  { label: "2 Star", value: "2Star" },
  { label: "3 Star", value: "3Star" },
  { label: "4 Star", value: "4Star" },
  { label: "5 Star", value: "5Star" },
]
const TransferTripOptions = [
  { label: "Pickup", value: "Pickup" },
  { label: "Drop", value: "Drop" },
  { label: "Round", value: "Round" },
]
const TransferTypeOptions = [
  { label: "Private", value: "Private" },
  { label: "SIC", value: "SIC" },
]
const TransferVehicleOptions = [
  { label: "Hatchback", value: "Hatchback" },
  { label: "SUV", value: "SUV" },
  { label: "Sedan", value: "Sedan" },
  { label: "12 Seater", value: "12 Seater" },
  { label: "24 Seater", value: "24 Seater" },
  { label: "48 Seater", value: "48 Seater" },
]

const buttonStyle = {
  fontSize: "15px",
  fontWeight: "bold",
  background: "#111",
  border: 0,
  borderColor: "#111",
  cursor: "pointer",
  borderTopRightRadius: "4px",
  borderBottomRightRadius: "4px",
  borderBottomLeftRadius: "4px",
  borderTopLeftRadius: "4px",
  borderWidth: " thin",
  color: "#fff",
  textAlign: "center",
  padding: "0.5em",
}

// const paymentModeOptions = [
//   { value: "cash", label: "Cash" },
//   { value: "upi", label: "UPI" },
//   { value: "BankTransfer", label: "Bank Transfer" },
//   { value: "Instamojo", label: "Instamojo" },
//   { value: "creditnote", label: "Credit Note" },
// ];

// lead pax

const selectStyle = {
  container: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  control: (base, state) => ({
    ...base,
    "&:hover": { borderColor: "#f46d25" },
    borderColor: "#f46d25",
    boxShadow: "none",
    width: "100%",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: "absolute",
    top: state.hasValue || state.selectProps.inputValue ? -7 : "50%",
    // background: "#fff",
    padding: "0px 5px",
    transition: "top 0.1s, font-size 0.1s",
    fontSize: "12px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: "visible",
  }),
  option: (provided, state) => ({
    ...provided,
    width: "100%",
  }),
}

const useStyles = makeStyles(() => ({
  root: {
    // padding: "100px 0px",
    // margin: "0px 30px",
    padding: "5% 0.5% 1% 1.5%",
    "@media (max-width: 767px)": {
      padding: "25% 5% 20% 5%",
    },
  },
  icon: {
    fill: "currentColor",
    width: "1em",
    // height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    position: "absolute",
    right: "15px",
    top: "5px",
    color: "#f46d25",
  },
  plus: {
    cursor: "pointer",
    color: "#f46d25",
    position: "relative",
    top: "7px",
    "@media (max-width: 767px)": {
      fontSize: "18px",
    },
  },
  topBorder: {
    position: "absolute",
    top: "-9px",
    zIndex: "10",
    left: "12px",
    color: "rgb(0 0 0 / 54%)",
    background: "#f1f1f1",
    borderRadius: "4px",
    padding: "0px 4px",
    fontSize: "12px",
  },
}))

//   lead pax ends
const PackageOrQuotation = (isPackage) => {
  return isPackage ? "Package" : "Quotation"
}
const CreateOrUpdate = (id) => {
  return id === undefined ? "Create" : "Update"
}

const getTransferVehicleOptions = (transferType) => {
  if (transferType === "Private") {
    return [
      { label: "Sedan", value: "Sedan" },
      { label: "Hatchback", value: "Hatchback" },
      { label: "SUV", value: "SUV" },
    ]
  } else if (transferType === "SIC") {
    return [
      { label: "12 Seater", value: "12 Seater" },
      { label: "24 Seater", value: "24 Seater" },
      { label: "48 Seater", value: "48 Seater" },
    ]
  } else {
    return []
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />
})

const checkFields = [
  "destination",
  "name",
  "clientName",
  "clientMobileNo",
  "noOfAdults",
  "noOfChildren",
  "checkIn",
  "checkOut",
  "totalGrossAmount",
  "comission",
]

export default function CreatePackage(props) {
  const dispatch = useDispatch()
  const [clientLists, setClientLists] = useState([])
  const [expanded, setExpanded] = useState("panel1")
  const [open, setOpen] = useState(false)
  const [selectedId] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  var uniqueid = localStorage.getItem("unique_id")
  var createdBy = localStorage.getItem("auth")
  const [formData, setFormData] = useReducer(formReducer, {
    id: "",
    uniqueId: uniqueid,
    createdBy: createdBy,
    destination: "",
    name: "",
    clientName: "",
    clientMobileNo: "",
    noOfAdults: "",
    noOfChildren: "0",
    checkIn: "",
    checkOut: "",
    totalGrossAmount: "",
    corporate: false,
    amountPaid: "",
    comission: "",
    paymentMode: "",
    paymentRefNo: " ",
    packagePaymentDetails: [],
  })

  //t&c,cancellation
  const [pkgTrmCndData, setPkgTrmCndData] = useReducer(objectReducer, {
    id: "",
    teamCondition: "",
    cancellationPolicy: "",
  })
  //t&c,cancellation

  const [totalAmountPaid, setTotalAmountPaid] = useState(0)
  const [packagePaymentDetails, setPackagePaymentDetails] = useReducer(
    objectReducer,
    { id: "", paidAmount: "", paymentMode: "", paymentRefNo: "" }
  )
  const classes = useStyles()

  const [error, setError] = useState({
    destination: "",
    name: "",
    clientName: "",
    clientMobileNo: "",
    noOfAdults: "",
    noOfChildren: "",
    checkIn: "",
    checkOut: "",
    totalGrossAmount: "",
    amountPaid: "",
    comission: "",
  })
  const [openPreview, setOpenPreview] = useState(false)
  let minCheckInDate = formData.checkIn
    ? formData.checkIn < new Date()
      ? formData.checkIn
      : new Date()
    : new Date()
  let minCheckOutDate = formData.checkIn
    ? addDays(formData.checkIn, 1)
    : addDays(new Date(), 1)

  let { pkgOrQtn, displayLeads, id } = useParams()

  let isPackage = pkgOrQtn === "package" ? true : false
  displayLeads = displayLeads === "true" ? true : false

  const MobileGet = sessionStorage.getItem("mobile")

  const validateField = (name, value) => {
    let temp_error = error
    switch (name) {
      case "destination":
      case "name":
      case "clientName":
        temp_error[name] = value.length < 3 ? `${name} required` : ""
        break
      case "clientMobileNo":
        temp_error[name] = validMobileRegex.test(value)
          ? ""
          : "Enter a valid Mobile Number"
        break
      case "noOfAdults":
      case "noOfChildren":
      case "noOfRooms":
      case "noOfNights":
        temp_error[name] = /^\+?\d+$/.test(value)
          ? ""
          : "Enter a valid positive no."
        break
      case "totalGrossAmount":
      case "amountPaid":
      case "comission":
        temp_error[name] = /^\+?\d+$/.test(value) ? "" : "Enter valid amount"
        break
    }
    setError(temp_error)
  }

  const validateAllFields = () => {
    let valid = true
    let formDataCheck = Object.entries(formData)
    formDataCheck = formDataCheck.filter((e) => {
      return checkFields.includes(e[0])
    })
    Object.values(formDataCheck).forEach(
      (val) => val[1].length <= 0 && (valid = false)
    )
    console.log(formDataCheck, valid)
    return valid
  }

  useEffect(() => {
    let previousPaidAmt = formData.packagePaymentDetails.reduce(
      (target, each) => {
        return target + each.paidAmount / 1
      },
      0
    )
    setTotalAmountPaid(previousPaidAmt + packagePaymentDetails.paidAmount / 1)
    return () => {}
  }, [packagePaymentDetails])

  // Run while Editing we get id and fetch data for that id
  useEffect(() => {
    if (id) {
      setLoading(true)
      Api.get(
        `/getpackageorquotationid/${uniqueid}/${PackageOrQuotation(
          isPackage
        )}/${id}`
      ).then((res) => {
        // console.log("PACKAGE DETAILS: ", res.data);
        // setting all form data values
        Object.entries(res.data).forEach(([key, value]) => {
          if (key === "checkIn" || key === "checkOut") {
            value = parse(value, "dd-MM-yyyy", new Date())
          }
          setFormData({ name: key, value: value })
        })
        //setting leadpax only for package and not for quotation,hotles,transfer, activity
        if (isPackage) {
          setLeadPax(res.data.leadPax)
        }
        setHotelInput(res.data.hotelDetails)
        setTransferInput(res.data.transferDetails)
        setActivityInput(res.data.activityDetails)
        {
          res.data.packagePaymentDetails != undefined &&
            setTotalAmountPaid(
              res.data.packagePaymentDetails.reduce((target, each) => {
                return target + each.paidAmount / 1
              }, 0)
            )
        }
        // setting terms and conditions
        Object.entries(res.data.packageTeamCondition).forEach(
          ([key, value]) => {
            console.log(key, value)
            setPkgTrmCndData({ target: { name: key, value: value } })
          }
        )

        setAmountRefunded(res.data.amountRefunded)
        setAmountRetained(res.data.amountRetained)
        setRevisedCommission(res.data.revisedCommission)
        if (res.data.amountRefunded > 0) {
          setShowRefund(true)
        }
        setLoading(false)
      })
    } else {
      if (isPackage) {
        Api.get(
          `agenthoteltermandcondition/${uniqueid}/packageTermCondition`
        ).then((res) => {
          setPkgTrmCndData({
            target: { name: "teamCondition", value: res.data },
          })
        })
        Api.get(
          `agenthoteltermandcondition/${uniqueid}/packageCancellationPolicy`
        ).then((res) => {
          setPkgTrmCndData({
            target: { name: "cancellationPolicy", value: res.data },
          })
        })
      } else {
        Api.get(
          `agenthoteltermandcondition/${uniqueid}/quotatioTermCondition`
        ).then((res) => {
          setPkgTrmCndData({
            target: { name: "teamCondition", value: res.data },
          })
        })
        Api.get(
          `agenthoteltermandcondition/${uniqueid}/quotationCancellationPolicy`
        ).then((res) => {
          setPkgTrmCndData({
            target: { name: "cancellationPolicy", value: res.data },
          })
        })
      }
    }
  }, [id])

  useEffect(() => {
    setFormData({ name: "type", value: PackageOrQuotation(isPackage) })
  }, [])

  // lead pax starts
  const [leadPax, setLeadPax] = useState([
    {
      id: "",
      leadPaxName: "",
      leadPaxMobile: "",
      leadPaxAltNo: "",
      leadPaxEmail: "",
    },
  ])
  let validLeadPax = leadPax.filter((e) => {
    return e.leadPaxName != ""
  })
  const handleRemoveClickLeadPax = (index) => {
    const list = [...leadPax]
    list.splice(index, 1)
    setLeadPax(list)
  }

  const handleAddClickLeadPax = () => {
    setLeadPax([
      ...leadPax,
      {
        id: "",
        leadPaxName: "",
        leadPaxMobile: "",
        leadPaxAltNo: "",
        leadPaxEmail: "",
      },
    ])
  }

  const handleLeadPaxChange = (e, index) => {
    const { name, value } = e.target
    const list = [...leadPax]
    list[index][name] = value
    // validateField(name, value);
    setLeadPax(list)
  }
  //   lead pax ends

  // hotel details starts
  const [hotelInput, setHotelInput] = useState([
    { id: "", hotelName: "", hotelCategory: "", noOfRooms: "", noOfNights: "" },
  ])

  let validHotelInput = hotelInput.filter((e) => {
    return e.hotelName != ""
  })

  const handleRemoveClickHotel = (index) => {
    const list = [...hotelInput]
    list.splice(index, 1)
    setHotelInput(list)
  }

  const handleAddClickHotel = () => {
    setHotelInput([
      ...hotelInput,
      {
        id: "",
        hotelName: "",
        hotelCategory: "",
        noOfRooms: "",
        noOfNights: "",
      },
    ])
  }

  const handleHotelChange = (e, index) => {
    const { name, value } = e.target
    const list = [...hotelInput]
    list[index][name] = value
    // validateField(name, value);
    setHotelInput(list)
  }

  const handleHotelSelectChange = (name, value, index) => {
    const list = [...hotelInput]
    list[index][name] = value
    setHotelInput(list)
  }
  // hotel details ends

  // transfer details starts
  const [transferInput, setTransferInput] = useState([
    {
      id: "",
      transferFrom: "",
      transferTo: "",
      transferTrip: "",
      transferType: "",
      transferVehicle: "",
    },
  ])
  let validTransferInput = transferInput.filter((e) => {
    return e.transferFrom != ""
  })

  const handleRemoveClickTransfer = (index) => {
    const list = [...transferInput]
    list.splice(index, 1)
    setTransferInput(list)
  }

  const handleAddClickTransfer = () => {
    setTransferInput([
      ...transferInput,
      {
        id: "",
        transferFrom: "",
        transferTo: "",
        transferTrip: "",
        transferType: "",
        transferVehicle: "",
      },
    ])
  }

  const handleTransferChange = (e, index) => {
    // //console.log("e",e,index);
    const { name, value } = e.target
    const list = [...transferInput]
    list[index][name] = value
    // validateField(name, value);
    setTransferInput(list)
  }
  const handleTransferSelectChange = (name, value, index) => {
    const list = [...transferInput]
    list[index][name] = value
    setTransferInput(list)
  }
  // transfer details ends

  // activity details starts
  const [activityInput, setActivityInput] = useState([
    { id: "", city: "", activity: "" },
  ])
  let validActvityInput = activityInput.filter((e) => {
    return e.city != ""
  })

  const handleRemoveClickActivity = (index) => {
    const list = [...activityInput]
    list.splice(index, 1)
    setActivityInput(list)
  }

  const handleAddClickActivity = () => {
    setActivityInput([...activityInput, { id: "", city: "", activity: "" }])
  }

  const handleActivityChange = (e, index) => {
    const { name, value } = e.target
    const list = [...activityInput]
    list[index][name] = value
    // validateField(name, value);
    setActivityInput(list)
  }
  // activity details ends

  // terms,conditions and cancel ends
  const handleFormChange = (event) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    })
    validateField(event.target.name, event.target.value)
  }

  const handleChangeClient = (option) => {
    console.log(option)
    //console.log("option:",option);
    setFormData({
      name: "clientName",
      value: option.value,
    })
    setFormData({
      name: "clientMobileNo",
      value: option.clientMobileNo,
    })
    // var found = clientLists.filter(function (item) {
    //     return item.id === option.id;})
  }

  const handleFormDateChange = (name, e) => {
    setFormData({
      name: name,
      value: e,
    })
  }

  useEffect(() => {
    Api.get(`/clientall/${uniqueid}`).then((res) => {
      setClientLists(res.data)
    })
  }, [])

  // DropDown Bind Values Map
  const Clientoptions =
    clientLists &&
    clientLists.map((client) => {
      let combine = `${client.firstName}\t${client.lastName}\t\t\t\t\t(${client.mail})`
      let combine1 = `${client.firstName}\t${client.lastName}`
      return {
        label: combine,
        value: combine1,
        id: client.id,
        clientMobileNo: client.mobile,
      }
    })

  useEffect(async () => {
    // console.log(MobileGet);
    // if (MobileGet !== null) {
    //   var found = clientLists.filter(function (item) {
    //     return item.mobile === MobileGet;
    //   });
    //   let MailData = found.map((item) => ({
    //     label: `${item.firstName}\t${item.lastName}\t\t\t\t\t(${item.mail})`,
    //     value: `${item.firstName}\t${item.lastName}`,
    //     id: item.id,
    //     clientMobileNo: item.mobile,
    //   }));
    //   if (MailData.length > 0) {
    //     handleChangeClient(MailData[0]);
    //     sessionStorage.removeItem("mobile");
    //   }
    // }
    if ((await clientLists.length) > 0 && MobileGet !== null) {
      let found = await Clientoptions.find(
        (client, idx) => MobileGet === client.clientMobileNo
      )

      handleChangeClient(found)
      sessionStorage.setItem("mobile", null)
    }
  }, [MobileGet, clientLists])

  const handleSubmit = async () => {
    await setIsClicked(true)
    if (validateForm(error) && validateAllFields()) {
      let postData = {
        ...formData,
        leadPax: leadPax,
        hotelDetails: hotelInput,
        transferDetails: transferInput,
        activityDetails: activityInput,
        packageTeamCondition: pkgTrmCndData,
        packagePaymentDetails: [
          ...formData.packagePaymentDetails,
          packagePaymentDetails,
        ],
        amountPaid: totalAmountPaid,
      }

      if (typeof postData["checkIn"] === "object") {
        let checkInString = format(postData["checkIn"], "dd-MM-yyyy")
        postData["checkIn"] = checkInString
      }
      if (typeof postData["checkOut"] === "object") {
        let checkOutString = format(postData["checkOut"], "dd-MM-yyyy")
        postData["checkOut"] = checkOutString
      }
      console.log(
        "agentCreatePackageOrQuotation|handleSubmit|POSTDATA: ",
        postData
      )

      let url =
        localStorage.getItem("role") === "Hotel Admin"
          ? "hotelCreatePackageOrQuotation"
          : "createPackageOrQuotation"
      Api.post(url, postData).then((res) => {
        console.log("createPackageOrQuotation", postData)
        toast.success(
          `${PackageOrQuotation(isPackage)} ${CreateOrUpdate(id)} Successfull`
        )
      })

      await setTimeout(() => {
        window.open(`/konnect/packageOrQuotation/${pkgOrQtn}`, "_self")
      }, 2000)
    } else {
      toast.error("Please correct Errors")
    }
  }

  const history = useHistory()

  const handleSubmitPackage = () => {
    let postData = {
      ...formData,
      leadPax: leadPax,
      hotelDetails: hotelInput,
      transferDetails: transferInput,
      activityDetails: activityInput,
      type: "Package",
      packageTeamCondition: pkgTrmCndData,
      packagePaymentDetails: [
        ...formData.packagePaymentDetails,
        packagePaymentDetails,
      ],
      amountPaid: totalAmountPaid,
    }
    if (typeof postData["checkIn"] === "object") {
      let checkInString = format(postData["checkIn"], "dd-MM-yyyy")
      postData["checkIn"] = checkInString
    }
    if (typeof postData["checkOut"] === "object") {
      let checkOutString = format(postData["checkOut"], "dd-MM-yyyy")
      postData["checkOut"] = checkOutString
    }
    console.log("POSTATA: ", postData)
    Api.post("createPackageOrQuotation", postData).then((res) => {
      toast.success("Converted to Package Successfully")
      history.push("/konnect/packageOrQuotation/package")
    })
    // history.push({ pathname: "/package/true" })
    // window.open("/packageOrQuotation/package", "_self");
  }

  // refund starts
  const [amountRefunded, setAmountRefunded] = useState(0)
  const [amountRetained, setAmountRetained] = useState(0)
  const [revisedCommission, setRevisedCommission] = useState(0)
  const [showRefund, setShowRefund] = useState(false)
  const [openRefund, setOpenRefund] = useState(false)

  const handleRefund = () => {
    const postData = {
      uniqueId: uniqueid,
      packageId: id,
      amountRefunded: amountRefunded,
      amountRetained: amountRetained,
      revisedCommission: revisedCommission,
    }
    // console.log("postdata|refund: ", postData);
    setOpenRefund(false)
    setShowRefund(true)
    Api.post("cancelPackage", postData).then((res) => {
      toast.success("Cancellation Success Updated")
    })
  }
  // refund ends

  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [width])

  const CustomTextField = styled(TextField)({
    "& .MuiInputBase-input::placeholder": {
      color: "#181818",
      opacity: "1",
      fontSize: "18px",
    },
  })

  const [expandedAccordion, setExpandedAccordion] = useState(0)

  const handleAccordionChange = (index) => {
    setExpandedAccordion(index)
  }

  const { paymentModeOptions } = useSelector(
    ({ paymentModeOptionsReducer }) => paymentModeOptionsReducer
  )

  useEffect(() => {
    dispatch(getPaymentModeOptions(uniqueid))
  }, [])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {loading ? (
        <Loader />
      ) : width <= 768 ? (
        <div className={classes.root}>
          {props.location.state.displayConvert ? (
            <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
              Convert to Package
            </Typography>
          ) : (
            <Typography style={{ fontWeight: "bold", fontSize: "20px" }}>
              {CreateOrUpdate(id)} {PackageOrQuotation(isPackage)}
            </Typography>
          )}
          <Accordion
            style={{
              borderRadius: "6px",
              marginBottom: "10px",
              marginTop: "-15%",
              boxShadow: "none",
            }}
            expanded={expandedAccordion === 0}
            onChange={() => handleAccordionChange(0)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              style={{
                background: "#343A40",
                color: "#fff",
                borderRadius: "6px",
                marginTop: "20%",
              }}
            >
              <Typography className={classes.title}>Basic Details</Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                paddingBottom: "20px",
                background: "#EEF1F3",
                borderRadius: "10px",
              }}
            >
              <Grid container>
                <Grid item xs={12} style={{ paddingBottom: "15px" }}>
                  <CustomTextInlineField
                    name="name"
                    label={PackageOrQuotation(isPackage) + " Name*"}
                    onChange={handleFormChange}
                    value={formData.name}
                    error={error}
                  />
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        fontSize: "16px",
                      }}
                    >
                      <span className={classes.topBorder}>Client Name *</span>
                      {clientLists && (
                        <Select
                          name="clientName"
                          isSearchable
                          defaultValue={formData.clientName}
                          value={Clientoptions.filter(
                            (i) => i.value === formData.clientName
                          )}
                          options={Clientoptions}
                          onChange={handleChangeClient}
                          isDisabled={id ? true : false}
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          styles={selectStyle}
                          error={error}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <AddCircleOutlineIcon
                      style={{
                        fontSize: "20px",
                        color: "#f46d25",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpen(true)
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} style={{ marginTop: "5px" }}>
                  <Grid item xs={12}>
                    <CustomTextInlineField
                      name="clientMobileNo"
                      label="Client Number*"
                      onChange={handleFormChange}
                      value={formData.clientMobileNo}
                      error={error}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextInlineField
                      name="destination"
                      label="Destination*"
                      value={formData.destination}
                      onChange={handleFormChange}
                      error={error}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <DatePicker
                      animateYearScrolling
                      autoOk="true"
                      cancelLabel={false}
                      format="dd-MM-yyyy"
                      fullWidth
                      inputVariant="outlined"
                      label="Check-In"
                      minDate={minCheckInDate}
                      okLabel={false}
                      onChange={(e) => handleFormDateChange("checkIn", e)}
                      required
                      size="small"
                      value={formData.checkIn ? formData.checkIn : null}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <DatePicker
                      required
                      label="Check-Out"
                      value={formData.checkOut ? formData.checkOut : null}
                      inputVariant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) => handleFormDateChange("checkOut", e)}
                      format="dd-MM-yyyy"
                      autoOk
                      cancelLabel={false}
                      okLabel={false}
                      // minDate={checkin1 ? checkin1 : new Date()}
                      minDate={minCheckOutDate}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <CustomTextInlineField
                      name="noOfAdults"
                      label="No of Adults*"
                      onChange={handleFormChange}
                      value={formData.noOfAdults}
                      error={error}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="noOfChildren"
                      fullWidth
                      variant="outlined"
                      label="No of Children*"
                      size="small"
                      onChange={handleFormChange}
                      value={formData.noOfChildren}
                      sx={{
                        "& .MuiOutlinedInput-input": { padding: "20px 14px" },
                        "& .MuiInputBase-input": { height: 0 },
                        "& .MuiFormLabel-root.Mui-error": { color: "#999" },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </AccordionDetails>
            <Typography
              style={{
                fontWeight: "bold",
                marginLeft: "5%",
                marginTop: "10px",
              }}
            >
              Lead Pax
            </Typography>
            <AccordionDetails
              style={{
                paddingTop: "20px",
                background: "#EEF1F3",
                borderRadius: "10px",
              }}
            >
              {leadPax.map((x, i) => (
                <Grid container spacing={1} key={i}>
                  <Grid item xs={6}>
                    <span style={{ marginTop: "5px" }}>{i + 1}.</span> &nbsp;
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "end" }}>
                    {leadPax.length !== 1 && (
                      <DeleteIcon
                        onClick={() => handleRemoveClickLeadPax(i)}
                        className={classes.plus}
                      />
                    )}
                    {leadPax.length - 1 === i && (
                      <AddCircleOutlineIcon
                        onClick={handleAddClickLeadPax}
                        size="small"
                        className={classes.plus}
                      />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextInlineField
                      name="leadPaxName"
                      label="Name"
                      value={x.leadPaxName}
                      onChange={(e) => handleLeadPaxChange(e, i)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextInlineField
                      name="leadPaxMobile"
                      label="Mobile No"
                      value={x.leadPaxMobile}
                      onChange={(e) => handleLeadPaxChange(e, i)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextInlineField
                      name="leadPaxAltNo"
                      label="Alt Mobile"
                      value={x.leadPaxAltNo}
                      onChange={(e) => handleLeadPaxChange(e, i)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextInlineField
                      name="leadPaxEmail"
                      label="Email"
                      value={x.leadPaxEmail}
                      onChange={(e) => handleLeadPaxChange(e, i)}
                    />
                  </Grid>
                </Grid>
              ))}
            </AccordionDetails>
            <Typography
              style={{
                fontWeight: "bold",
                marginLeft: "5%",
                marginTop: "10px",
              }}
            >
              Itinerary
            </Typography>
            <AccordionDetails
              style={{
                paddingTop: "20px",
                background: "#EEF1F3",
                borderRadius: "10px",
              }}
            >
              <TextareaAutosize
                name="itinerary"
                onChange={handleFormChange}
                style={{
                  maxWidth: "99.5%",
                  minHeight: "4em",
                  minWidth: "99.4%",
                  fontSize: "15px",
                  borderColor: "#f46d25",
                  borderRadius: "8px",
                }}
                defaultValue={formData.itinerary}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              borderRadius: "6px",
              marginBottom: "10px",
              marginTop: "-15%",
              boxShadow: "none",
            }}
            expanded={expandedAccordion === 1}
            onChange={() => handleAccordionChange(1)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              style={{
                background: "#343A40",
                color: "#fff",
                borderRadius: "6px",
                marginTop: "20%",
              }}
            >
              <Typography className={classes.title}>Package Details</Typography>
            </AccordionSummary>
            <Typography
              style={{
                fontWeight: "bold",
                marginLeft: "5%",
                marginTop: "10px",
              }}
            >
              Hotel Details
            </Typography>
            <AccordionDetails
              style={{
                paddingTop: "20px",
                background: "#EEF1F3",
                borderRadius: "10px",
              }}
            >
              {hotelInput.map((x, i) => (
                <Grid container spacing={1} key={i}>
                  <Grid item xs={6}>
                    <span style={{ marginTop: "5px" }}>{i + 1}.</span>
                  </Grid>
                  {/* <Grid item sm={12} style={{ textAlign: "flex-end"}}> */}
                  <Grid item xs={6} direction="row" textAlign="end">
                    {hotelInput.length !== 1 && (
                      <DeleteIcon
                        onClick={() => handleRemoveClickHotel(i)}
                        className={classes.plus}
                        size="small"
                      />
                    )}
                    {hotelInput.length - 1 === i && (
                      <AddCircleOutlineIcon
                        onClick={handleAddClickHotel}
                        size="small"
                        className={classes.plus}
                      />
                    )}
                  </Grid>
                  {/* </Grid> */}
                  <Grid item xs={6}>
                    <CustomTextInlineField
                      name="hotelName"
                      style={{ width: "100%" }}
                      label="Hotel Name"
                      value={x.hotelName}
                      onChange={(e) => handleHotelChange(e, i)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        fontSize: "16px",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          fontSize: "16px",
                        }}
                      >
                        <span className={classes.topBorder}>
                          Hotel Category
                        </span>
                      </div>
                      <Select
                        name="hotelCategory"
                        placeholder=""
                        value={HotelCategory.filter(
                          (i) => i.value === x.hotelCategory
                        )}
                        options={HotelCategory}
                        onChange={(e) =>
                          handleHotelSelectChange("hotelCategory", e.value, i)
                        }
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        styles={selectStyle}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextInlineField
                      name="noOfRooms"
                      label="No Of Rooms"
                      value={x.noOfRooms}
                      onChange={(e) => handleHotelChange(e, i)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextInlineField
                      name="noOfNights"
                      label="No of Nights"
                      value={x.noOfNights}
                      onChange={(e) => handleHotelChange(e, i)}
                    />
                  </Grid>
                </Grid>
              ))}
            </AccordionDetails>
            <Typography
              style={{
                fontWeight: "bold",
                marginLeft: "5%",
                marginTop: "10px",
              }}
            >
              Transfer Details
            </Typography>
            <AccordionDetails
              style={{
                paddingTop: "20px",
                background: "#EEF1F3",
                borderRadius: "10px",
              }}
            >
              {transferInput.map((x, i) => (
                <Grid container spacing={1} key={i}>
                  <Grid item xs={6}>
                    <span style={{ marginTop: "5px" }}>{i + 1}.</span> &nbsp;
                  </Grid>
                  <Grid item xs={6} textAlign="end">
                    {transferInput.length !== 1 && (
                      <DeleteIcon
                        onClick={() => handleRemoveClickTransfer(i)}
                        className={classes.plus}
                      />
                    )}
                    {transferInput.length - 1 === i && (
                      <AddCircleOutlineIcon
                        onClick={handleAddClickTransfer}
                        size="small"
                        className={classes.plus}
                      />
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextInlineField
                      name="transferFrom"
                      style={{ width: "100%" }}
                      label="From"
                      value={x.transferFrom}
                      onChange={(e) => handleTransferChange(e, i)}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <CustomTextInlineField
                      name="transferTo"
                      label="To"
                      value={x.transferTo}
                      onChange={(e) => handleTransferChange(e, i)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        fontSize: "16px",
                      }}
                    >
                      <span className={classes.topBorder}>Trip</span>
                      <Select
                        name="transferTrip"
                        placeholder=""
                        value={TransferTripOptions.filter(
                          (i) => i.value === x.transferTrip
                        )}
                        options={TransferTripOptions}
                        onChange={(e) =>
                          handleTransferSelectChange("transferTrip", e.value, i)
                        }
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        styles={selectStyle}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        fontSize: "16px",
                      }}
                    >
                      <span className={classes.topBorder}>Type</span>
                      <Select
                        name="transferType"
                        placeholder=""
                        value={TransferTypeOptions.filter(
                          (i) => i.value === x.transferType
                        )}
                        options={TransferTypeOptions}
                        onChange={(e) =>
                          handleTransferSelectChange("transferType", e.value, i)
                        }
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        styles={selectStyle}
                      />
                    </div>
                  </Grid>

                  {/* <Grid style={{ paddingLeft: "16px", marginTop: "15px" }} width="100%"> */}
                  <Grid item xs={6} paddingLeft="16px">
                    <div
                      style={{
                        position: "relative",
                        width: "97%",
                        fontSize: "16px",
                      }}
                    >
                      <span className={classes.topBorder}>Vehicle</span>
                      <Select
                        name="transferVehicle"
                        placeholder=""
                        value={TransferVehicleOptions.filter(
                          (i) => i.value === x.transferVehicle
                        )}
                        options={getTransferVehicleOptions(x.transferType)}
                        onChange={(e) =>
                          handleTransferSelectChange(
                            "transferVehicle",
                            e.value,
                            i
                          )
                        }
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        styles={selectStyle}
                      />
                    </div>
                  </Grid>
                  {/* </Grid> */}
                </Grid>
              ))}
            </AccordionDetails>
            <Typography
              style={{
                fontWeight: "bold",
                marginLeft: "5%",
                marginTop: "10px",
              }}
            >
              Activity Details
            </Typography>
            <AccordionDetails
              style={{
                paddingTop: "20px",
                background: "#EEF1F3",
                borderRadius: "10px",
              }}
            >
              {activityInput.map((x, i) => (
                <Grid container spacing={1} key={i}>
                  <Grid item xs={6}>
                    <span style={{ marginTop: "5px" }}>{i + 1}.</span> &nbsp;
                  </Grid>
                  <Grid item xs={6} textAlign="end">
                    {activityInput.length !== 1 && (
                      <DeleteIcon
                        onClick={() => handleRemoveClickActivity(i)}
                        className={classes.plus}
                      />
                    )}
                    {activityInput.length - 1 === i && (
                      <AddCircleOutlineIcon
                        onClick={handleAddClickActivity}
                        size="small"
                        className={classes.plus}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextInlineField
                      name="city"
                      label="City"
                      value={x.city}
                      onChange={(e) => handleActivityChange(e, i)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomTextInlineField
                      name="activity"
                      label="Activity"
                      value={x.activity}
                      onChange={(e) => handleActivityChange(e, i)}
                    />
                  </Grid>
                </Grid>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              borderRadius: "6px",
              marginBottom: "10px",
              marginTop: "-15%",
              boxShadow: "none",
            }}
            expanded={expandedAccordion === 2}
            onChange={() => handleAccordionChange(2)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              style={{
                background: "#343A40",
                color: "#fff",
                borderRadius: "6px",
                marginTop: "20%",
              }}
            >
              <Typography className={classes.title}>Payment Details</Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                paddingTop: "20px",
                background: "#EEF1F3",
                borderRadius: "10px",
              }}
            >
              <Grid container spacing={1}>
                {displayLeads ? (
                  <Grid item xs={12}>
                    <DualTextField
                      label="Total Gross Amount *"
                      name="totalGrossAmount"
                      value={formData.totalGrossAmount}
                      changeFn={handleFormChange}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <DualTextField
                      label="Total Gross Amount *"
                      name="totalGrossAmount"
                      value={formData.totalGrossAmount}
                      changeFn={handleFormChange}
                    />
                  </Grid>
                )}
                {id && isPackage ? (
                  <Grid item xs={12}>
                    <DualTextField
                      label="Total Amount Paid"
                      name="totalAmountPaid"
                      value={totalAmountPaid}
                      changeFn={() => {}}
                      disabled={true}
                    />
                  </Grid>
                ) : (
                  <Grid></Grid>
                )}
                {displayLeads ? (
                  <Grid item xs={12}>
                    <DualTextField
                      label="Commission *"
                      name="comission"
                      value={formData.comission}
                      changeFn={handleFormChange}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <DualTextField
                      label="Commission *"
                      name="comission"
                      value={formData.comission}
                      changeFn={handleFormChange}
                    />
                  </Grid>
                )}
                {displayLeads && (
                  <Grid item xs={12}>
                    <DualTextField
                      label="Amount Paid *"
                      name="paidAmount"
                      value={packagePaymentDetails.paidAmount}
                      changeFn={setPackagePaymentDetails}
                    />
                  </Grid>
                )}
                {displayLeads && (
                  <Grid item xs={12}>
                    <div>
                      <DualTextField
                        label="Payment Mode"
                        name="paymentMode"
                        value=""
                        changeFn={(option) =>
                          setPackagePaymentDetails({
                            target: {
                              name: "paymentMode",
                              value: option.label,
                            },
                          })
                        }
                        options={paymentModeOptions}
                        fullWidth={true}
                      />
                    </div>
                  </Grid>
                )}
                {displayLeads && (
                  <Grid container item xs={12}>
                    <DualTextField
                      label="Reference Number"
                      name="paymentRefNo"
                      value={packagePaymentDetails.paymentRefNo}
                      changeFn={setPackagePaymentDetails}
                      inputValue={false}
                    />
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              borderRadius: "6px",
              marginBottom: "10px",
              marginTop: "-15%",
              boxShadow: "none",
            }}
            expanded={expandedAccordion === 3}
            onChange={() => handleAccordionChange(3)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              style={{
                background: "#343A40",
                color: "#fff",
                borderRadius: "6px",
                marginTop: "20%",
              }}
            >
              <Typography className={classes.title}> T&C Policy</Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                paddingTop: "20px",
                background: "#EEF1F3",
                borderRadius: "10px",
              }}
            >
              <TextareaAutosize
                name="teamCondition"
                onChange={setPkgTrmCndData}
                defaultValue={pkgTrmCndData.teamCondition}
                style={{
                  maxWidth: "99.5%",
                  minHeight: "4em",
                  minWidth: "99.4%",
                  fontSize: "15px",
                  borderColor: "#f46d25",
                  borderRadius: "8px",
                }}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              borderRadius: "6px",
              marginBottom: "10px",
              marginTop: "-15%",
              boxShadow: "none",
            }}
            expanded={expandedAccordion === 4}
            onChange={() => handleAccordionChange(4)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              style={{
                background: "#343A40",
                color: "#fff",
                borderRadius: "6px",
                marginTop: "20%",
              }}
            >
              <Typography className={classes.title}>
                {" "}
                Cancellation Policy
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                paddingTop: "20px",
                background: "#EEF1F3",
                borderRadius: "10px",
              }}
            >
              <TextareaAutosize
                name="cancellationPolicy"
                onChange={setPkgTrmCndData}
                defaultValue={pkgTrmCndData.cancellationPolicy}
                style={{
                  maxWidth: "99.5%",
                  minHeight: "4em",
                  minWidth: "99.4%",
                  fontSize: "15px",
                  borderColor: "#f46d25",
                  borderRadius: "8px",
                }}
              />
            </AccordionDetails>
          </Accordion>
          <Grid item xs={12} textAlign="end">
            {validateForm(error) && validateAllFields() ? (
              props.location.state.displayConvert ? (
                <button
                  onClick={handleSubmitPackage}
                  style={{ ...buttonStyle, backgroundColor: "#f46d25" }}
                >
                  Save as Package
                </button>
              ) : isClicked ? (
                <button style={twnButtonStyles.disabledBtn}>
                  <i className="fa fa-refresh fa-spin"></i>
                  Next
                </button>
              ) : (
                <button
                  onClick={handleSubmit}
                  style={twnButtonStyles.orangeBtn}
                >
                  Next
                </button>
              )
            ) : (
              <button style={twnButtonStyles.disabledBtn}>Next</button>
            )}
          </Grid>
          <ClientRefresh
            open={open}
            onClose={() => {
              setOpen(false)
            }}
            selectedId={selectedId}
            BookingLocal={"yes"}
            pkgOrQtn={pkgOrQtn}
            displayLeads={displayLeads}
          />
        </div>
      ) : (
        <div style={{ padding: "5% 1%" }}>
          {/* <Typography style={{ color: '#f46d25', fontWeight: 'bold',fontSize: '32px', fontFamily: 'Segoe UI' }}>{CreateOrUpdate(id)} {PackageOrQuotation(isPackage)}</Typography> */}
          {props.location.displayConvert ? (
            <Typography
              variant="h5"
              component="h5"
              style={twnButtonStyles.xlFonts}
            >
              Convert to Package
            </Typography>
          ) : (
            <Typography
              variant="h5"
              component="h5"
              style={twnButtonStyles.xlFonts}
            >
              {CreateOrUpdate(id)} {PackageOrQuotation(isPackage)}
            </Typography>
          )}

          {/* Basic Details Accordian Starts  */}
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            style={{ margin: "5px 0" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              id="panel1a-header"
              style={{
                backgroundColor: "#f46d25",
                color: "#fff",
                marginTop: "0.8%",
                height: "0.5em",
              }}
            >
              <Typography variant="h5" style={{ padding: "0%" }}>
                Basic Details
              </Typography>
            </AccordionSummary>

            {/* Basic Info  */}
            <AccordionDetails style={{ backgroundColor: "#eee" }}>
              <Typography variant="h6" style={{ color: "#f46d25" }}>
                Basic Info
              </Typography>
              <Grid container>
                <Grid item xs={3}></Grid>
              </Grid>
              <Stack style={{ marginTop: "0.5em", width: "100%" }}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      fontSize: "16px",
                    }}
                  >
                    <span className={classes.topBorder}>Client Name*</span>
                    {clientLists && (
                      <Select
                        name="clientName"
                        isSearchable
                        defaultValue={formData.clientName}
                        value={Clientoptions.filter(
                          (i) => i.value === formData.clientName
                        )}
                        options={Clientoptions}
                        onChange={handleChangeClient}
                        isDisabled={id ? true : false}
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                        styles={selectStyle}
                        error={error}
                      />
                    )}
                  </div>
                  <AddCircleOutlineIcon
                    style={{
                      fontSize: "20px",
                      color: "#f46d25",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpen(true)
                    }}
                  />
                  <div style={{ width: "100%" }}>
                    <CustomTextInlineField
                      name="clientMobileNo"
                      label="Client Number*"
                      onChange={handleFormChange}
                      value={formData.clientMobileNo}
                      error={error}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <CustomTextInlineField
                      name="destination"
                      label="Destination*"
                      value={formData.destination}
                      onChange={handleFormChange}
                      error={error}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <CustomTextInlineField
                      name="name"
                      label={PackageOrQuotation(isPackage) + " Name*"}
                      onChange={handleFormChange}
                      value={formData.name}
                      error={error}
                    />
                  </div>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                  marginTop="15px"
                >
                  <div style={{ width: "100%" }}>
                    <CustomTextInlineField
                      name="noOfAdults"
                      label="No of Adults*"
                      onChange={handleFormChange}
                      value={formData.noOfAdults}
                      error={error}
                    />
                  </div>
                  <div></div>
                  <div></div>
                  <div style={{ width: "100%" }}>
                    <TextField
                      name="noOfChildren"
                      fullWidth
                      variant="outlined"
                      label="No of Children*"
                      size="small"
                      onChange={handleFormChange}
                      value={formData.noOfChildren}
                      sx={{
                        "& .MuiOutlinedInput-input": { padding: "20px 14px" },
                        "& .MuiInputBase-input": { height: 0 },
                        "& .MuiFormLabel-root.Mui-error": { color: "#999" },
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <DatePicker
                      animateYearScrolling
                      autoOk="true"
                      cancelLabel={false}
                      format="dd-MM-yyyy"
                      fullWidth
                      inputVariant="outlined"
                      label="Check-In"
                      minDate={minCheckInDate}
                      okLabel={false}
                      onChange={(e) => handleFormDateChange("checkIn", e)}
                      required
                      size="small"
                      value={formData.checkIn ? formData.checkIn : null}
                    />
                  </div>
                  {/* {minCheckInDate} */}
                  <div style={{ width: "100%" }}>
                    <DatePicker
                      required
                      label="Check-Out"
                      value={formData.checkOut ? formData.checkOut : null}
                      inputVariant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) => handleFormDateChange("checkOut", e)}
                      format="dd-MM-yyyy"
                      autoOk
                      cancelLabel={false}
                      okLabel={false}
                      // minDate={checkin1 ? checkin1 : new Date()}
                      minDate={minCheckOutDate}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="corporate"
                          color="primary"
                          size="small"
                          checked={formData.corporate}
                        />
                      }
                      label="Corporate"
                      onChange={(e) => {
                        const { name, checked } = e.target
                        setFormData({ name, value: checked })
                      }}
                    />
                  </div>
                </Stack>
              </Stack>
            </AccordionDetails>

            {/* Lead Pax only if package */}
            {displayLeads ? (
              <>
                <AccordionDetails style={{ backgroundColor: "#eee" }}>
                  <Typography
                    variant="h6"
                    style={{
                      color: "#f46d25",
                      paddingTop: "1%",
                      lineHeight: "0",
                    }}
                  >
                    Lead Pax
                  </Typography>
                  {leadPax.map((x, i) => (
                    <Grid container spacing={1} key={i}>
                      <Grid item sm={12} style={{ textAlign: "end" }}>
                        {leadPax.length !== 1 && (
                          <DeleteIcon
                            onClick={() => handleRemoveClickLeadPax(i)}
                            className={classes.plus}
                          />
                        )}
                        {leadPax.length - 1 === i && (
                          <AddCircleOutlineIcon
                            onClick={handleAddClickLeadPax}
                            size="small"
                            className={classes.plus}
                          />
                        )}
                      </Grid>
                      <Grid item sm={3}>
                        <div style={{ display: "flex" }}>
                          <span style={{ marginTop: "5px" }}>{i + 1}.</span>{" "}
                          &nbsp;
                          <CustomTextInlineField
                            name="leadPaxName"
                            label="Name"
                            value={x.leadPaxName}
                            onChange={(e) => handleLeadPaxChange(e, i)}
                          />
                        </div>
                      </Grid>
                      <Grid item sm={3}>
                        <CustomTextInlineField
                          name="leadPaxMobile"
                          label="Mobile No"
                          value={x.leadPaxMobile}
                          onChange={(e) => handleLeadPaxChange(e, i)}
                        />
                      </Grid>
                      <Grid item sm={3}>
                        <CustomTextInlineField
                          name="leadPaxAltNo"
                          label="Alt Mobile"
                          value={x.leadPaxAltNo}
                          onChange={(e) => handleLeadPaxChange(e, i)}
                        />
                      </Grid>
                      <Grid item sm={3}>
                        <CustomTextInlineField
                          name="leadPaxEmail"
                          label="Email"
                          value={x.leadPaxEmail}
                          onChange={(e) => handleLeadPaxChange(e, i)}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </AccordionDetails>
              </>
            ) : null}

            {/* Itinerary  */}
            <AccordionDetails style={{ backgroundColor: "#eee" }}>
              <Typography
                variant="h6"
                style={{ color: "#f46d25", marginBottom: "0.5%" }}
              >
                Itinerary
              </Typography>
              <TextareaAutosize
                name="itinerary"
                onChange={handleFormChange}
                style={{
                  maxWidth: "99.5%",
                  minHeight: "4em",
                  minWidth: "99.4%",
                  fontSize: "15px",
                  borderColor: "#f46d25",
                }}
                defaultValue={formData.itinerary}
              />
            </AccordionDetails>
          </Accordion>
          {/* Basic Details Accordian Ends  */}

          {/* Package or Quotation Details Starts  */}
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            style={{ marginTop: "0.3%" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              style={{
                backgroundColor: "#f46d25",
                color: "#fff",
                marginTop: "0.5%",
                height: "0.5em",
              }}
            >
              <Typography variant="h5">
                {PackageOrQuotation(isPackage)} Details
              </Typography>
            </AccordionSummary>

            {/* Hotel Details  */}
            <AccordionDetails style={{ backgroundColor: "#eee" }}>
              <Typography
                variant="h6"
                style={{
                  color: "#f46d25",
                  marginBottom: "-5px",
                  paddingTop: "1%",
                  lineHeight: "0",
                }}
              >
                Hotel Details
              </Typography>
              <Grid container spacing={1}>
                {hotelInput.map((x, i) => (
                  <Stack spacing={1} width="100%" key={i}>
                    {/* <Grid item sm={12} style={{ textAlign: "flex-end"}}> */}
                    <Stack direction="row" justifyContent="flex-end">
                      {hotelInput.length !== 1 && (
                        <DeleteIcon
                          onClick={() => handleRemoveClickHotel(i)}
                          className={classes.plus}
                        />
                      )}
                      {hotelInput.length - 1 === i && (
                        <AddCircleOutlineIcon
                          onClick={handleAddClickHotel}
                          size="small"
                          className={classes.plus}
                        />
                      )}
                    </Stack>
                    {/* </Grid> */}
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="space-evenly"
                    >
                      <div style={{ display: "flex", width: "100%" }}>
                        <span style={{ marginTop: "5px" }}>{i + 1}.</span>{" "}
                        &nbsp;
                        <CustomTextInlineField
                          name="hotelName"
                          style={{ width: "100%" }}
                          label="Hotel Name"
                          value={x.hotelName}
                          onChange={(e) => handleHotelChange(e, i)}
                        />
                      </div>
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          fontSize: "16px",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            fontSize: "16px",
                          }}
                        >
                          <span className={classes.topBorder}>
                            Hotel Category
                          </span>
                        </div>
                        <Select
                          name="hotelCategory"
                          placeholder=""
                          value={HotelCategory.filter(
                            (i) => i.value === x.hotelCategory
                          )}
                          options={HotelCategory}
                          onChange={(e) =>
                            handleHotelSelectChange("hotelCategory", e.value, i)
                          }
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          styles={selectStyle}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <CustomTextInlineField
                          name="noOfRooms"
                          label="No Of Rooms"
                          value={x.noOfRooms}
                          onChange={(e) => handleHotelChange(e, i)}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <CustomTextInlineField
                          name="noOfNights"
                          label="No of Nights"
                          value={x.noOfNights}
                          onChange={(e) => handleHotelChange(e, i)}
                        />
                      </div>
                    </Stack>
                  </Stack>
                ))}
              </Grid>
            </AccordionDetails>

            {/* Transfer Details  */}
            <AccordionDetails style={{ backgroundColor: "#eee" }}>
              <Typography
                variant="h6"
                style={{
                  color: "#f46d25",
                  marginBottom: "-5px",
                  paddingTop: "1%",
                  lineHeight: "0",
                }}
              >
                Transfer Details
              </Typography>
              <Grid container spacing={1}>
                {transferInput.map((x, i) => (
                  <Stack spacing={1} width="100%" key={i}>
                    <Stack direction="row" justifyContent="flex-end">
                      {transferInput.length !== 1 && (
                        <DeleteIcon
                          onClick={() => handleRemoveClickTransfer(i)}
                          className={classes.plus}
                        />
                      )}
                      {transferInput.length - 1 === i && (
                        <AddCircleOutlineIcon
                          onClick={handleAddClickTransfer}
                          size="small"
                          className={classes.plus}
                        />
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                    >
                      <div style={{ display: "flex", width: "100%" }}>
                        <span style={{ marginTop: "5px" }}>{i + 1}.</span>{" "}
                        &nbsp;
                        <CustomTextInlineField
                          name="transferFrom"
                          style={{ width: "100%" }}
                          label="From"
                          value={x.transferFrom}
                          onChange={(e) => handleTransferChange(e, i)}
                        />
                      </div>
                      <div style={{ width: "100%" }}>
                        <CustomTextInlineField
                          name="transferTo"
                          label="To"
                          value={x.transferTo}
                          onChange={(e) => handleTransferChange(e, i)}
                        />
                      </div>
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          fontSize: "16px",
                        }}
                      >
                        <span className={classes.topBorder}>Trip</span>
                        <Select
                          name="transferTrip"
                          placeholder=""
                          value={TransferTripOptions.filter(
                            (i) => i.value === x.transferTrip
                          )}
                          options={TransferTripOptions}
                          onChange={(e) =>
                            handleTransferSelectChange(
                              "transferTrip",
                              e.value,
                              i
                            )
                          }
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          styles={selectStyle}
                        />
                      </div>
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          fontSize: "16px",
                        }}
                      >
                        <span className={classes.topBorder}>Type</span>
                        <Select
                          name="transferType"
                          placeholder=""
                          value={TransferTypeOptions.filter(
                            (i) => i.value === x.transferType
                          )}
                          options={TransferTypeOptions}
                          onChange={(e) =>
                            handleTransferSelectChange(
                              "transferType",
                              e.value,
                              i
                            )
                          }
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          styles={selectStyle}
                        />
                      </div>
                    </Stack>
                    {/* <Stack style={{ paddingLeft: "16px", marginTop: "15px" }} width="100%"> */}
                    <Grid container item xs={3} paddingLeft="16px">
                      <div
                        style={{
                          position: "relative",
                          width: "97%",
                          fontSize: "16px",
                        }}
                      >
                        <span className={classes.topBorder}>Vehicle</span>
                        <Select
                          name="transferVehicle"
                          placeholder=""
                          value={TransferVehicleOptions.filter(
                            (i) => i.value === x.transferVehicle
                          )}
                          options={getTransferVehicleOptions(x.transferType)}
                          onChange={(e) =>
                            handleTransferSelectChange(
                              "transferVehicle",
                              e.value,
                              i
                            )
                          }
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          styles={selectStyle}
                        />
                      </div>
                    </Grid>
                    {/* </Stack> */}
                  </Stack>
                ))}
              </Grid>
            </AccordionDetails>

            {/* Activity Details */}
            <AccordionDetails style={{ backgroundColor: "#eee" }}>
              <Typography
                variant="h6"
                style={{
                  color: "#f46d25",
                  marginBottom: "-5px",
                  paddingTop: "1%",
                  lineHeight: "0",
                }}
              >
                Activity Details
              </Typography>
              {activityInput.map((x, i) => (
                <Grid container spacing={1} key={i}>
                  <Grid item sm={12} style={{ textAlign: "end" }}>
                    {activityInput.length !== 1 && (
                      <DeleteIcon
                        onClick={() => handleRemoveClickActivity(i)}
                        className={classes.plus}
                      />
                    )}
                    {activityInput.length - 1 === i && (
                      <AddCircleOutlineIcon
                        onClick={handleAddClickActivity}
                        size="small"
                        className={classes.plus}
                      />
                    )}
                  </Grid>
                  <Grid item sm={6}>
                    <div style={{ display: "flex" }}>
                      <span style={{ marginTop: "5px" }}>{i + 1}</span> &nbsp;
                      <CustomTextInlineField
                        name="city"
                        label="City"
                        value={x.city}
                        onChange={(e) => handleActivityChange(e, i)}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={6}>
                    <CustomTextInlineField
                      name="activity"
                      label="Activity"
                      value={x.activity}
                      onChange={(e) => handleActivityChange(e, i)}
                    />
                  </Grid>
                </Grid>
              ))}
            </AccordionDetails>
          </Accordion>
          {/* Package Details Ends  */}

          {/* Payments Details Starts  */}
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            style={{ marginTop: "0.3%" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              id="panel3a-header"
              style={{
                backgroundColor: "#f46d25",
                color: "#fff",
                marginTop: "0.5%",
                height: "0.5em",
              }}
            >
              <Typography variant="h5">Payment Details</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                {displayLeads ? (
                  <Grid item xs={4}>
                    <DualTextField
                      label="Total Gross Amount *"
                      name="totalGrossAmount"
                      value={formData.totalGrossAmount}
                      changeFn={handleFormChange}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <DualTextField
                      label="Total Gross Amount *"
                      name="totalGrossAmount"
                      value={formData.totalGrossAmount}
                      changeFn={handleFormChange}
                    />
                  </Grid>
                )}
                {id && isPackage ? (
                  <Grid item xs={4}>
                    <DualTextField
                      label="Total Amount Paid"
                      name="totalAmountPaid"
                      value={totalAmountPaid}
                      changeFn={() => {}}
                      disabled={true}
                    />
                  </Grid>
                ) : (
                  <Grid></Grid>
                )}
                {displayLeads ? (
                  <Grid item xs={4}>
                    <DualTextField
                      label="Commission *"
                      name="comission"
                      value={formData.comission}
                      changeFn={handleFormChange}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={6}>
                    <DualTextField
                      label="Commission *"
                      name="comission"
                      value={formData.comission}
                      changeFn={handleFormChange}
                    />
                  </Grid>
                )}
                {displayLeads && (
                  <Grid item xs={4}>
                    <DualTextField
                      label="Amount Paid *"
                      name="paidAmount"
                      value={packagePaymentDetails.paidAmount}
                      changeFn={setPackagePaymentDetails}
                    />
                  </Grid>
                )}
                {displayLeads && (
                  <Grid item xs={4}>
                    <div>
                      <DualTextField
                        label="Payment Mode"
                        name="paymentMode"
                        value=""
                        changeFn={(option) =>
                          setPackagePaymentDetails({
                            target: {
                              name: "paymentMode",
                              value: option.label,
                            },
                          })
                        }
                        options={paymentModeOptions}
                        fullWidth={true}
                      />
                    </div>
                  </Grid>
                )}
                {displayLeads && (
                  <Grid container item xs={4}>
                    <DualTextField
                      label="Reference Number"
                      name="paymentRefNo"
                      value={packagePaymentDetails.paymentRefNo}
                      changeFn={setPackagePaymentDetails}
                      inputValue={false}
                    />
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>

            {/* Payment Table  */}
            {id && isPackage && formData.packagePaymentDetails && (
              <AccordionDetails
                style={{
                  border: "1px solid #111",
                  margin: "10px",
                  backgroundColor: "#fff",
                }}
              >
                <Grid container spacing={0}>
                  <Grid item xs={3}>
                    <span
                      style={{
                        color: "#f46d25",
                        fontSize: "18px",
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      Date
                    </span>
                  </Grid>
                  <Grid item xs={3}>
                    <span
                      style={{
                        color: "#f46d25",
                        fontSize: "18px",
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      Amount Paid
                    </span>
                  </Grid>
                  <Grid item xs={3}>
                    <span
                      style={{
                        color: "#f46d25",
                        fontSize: "18px",
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      Payment Mode
                    </span>
                  </Grid>
                  <Grid item xs={3}>
                    <span
                      style={{
                        color: "#f46d25",
                        fontSize: "18px",
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      Reference Number
                    </span>
                  </Grid>
                  {formData.packagePaymentDetails.map((each, index) => {
                    return (
                      <>
                        <Grid
                          item
                          xs={3}
                          style={{
                            color: "#111",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                        >
                          {each.createdAt
                            ? format(
                                parse(
                                  each.createdAt,
                                  "yyyy-MM-dd HH:mm:ss",
                                  new Date()
                                ),
                                "dd MMM yy, hh:mm a"
                              )
                            : null}
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          style={{
                            color: "#111",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                        >
                          {each.paidAmount}
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          style={{
                            color: "#111",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                        >
                          {each.paymentMode}
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          style={{
                            color: "#111",
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                        >
                          {each.paymentRefNo}
                        </Grid>
                      </>
                    )
                  })}
                </Grid>
              </AccordionDetails>
            )}
          </Accordion>
          {/* Payment Details Ends  */}

          {/* Terms and Conditions Starts */}
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            style={{ marginTop: "0.3%" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              aria-controls="panel4a-content"
              id="panel4a-header"
              style={{
                backgroundColor: "#f46d25",
                color: "#fff",
                marginTop: "0.5%",
                height: "0.5em",
              }}
            >
              <Typography variant="h5">Terms and Conditions</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#eee" }}>
              <CKEditor
                editor={ClassicEditor}
                name="teamCondition"
                data={pkgTrmCndData.teamCondition}
                onChange={(e, editor) => {
                  const data = editor.getData()
                  setPkgTrmCndData({
                    target: { name: "teamCondition", value: data },
                  })
                }}
              />

              {/* <TextareaAutosize
                name="teamCondition"
                onChange={setPkgTrmCndData}
                defaultValue={pkgTrmCndData.teamCondition}
                style={{
                  maxWidth: "99.5%",
                  minHeight: "4em",
                  minWidth: "99.4%",
                  fontSize: "15px",
                  borderColor: "#f46d25",
                }}
              /> */}
            </AccordionDetails>
          </Accordion>
          {/* Terms and Conditions Ends */}

          {/* Cancellation Policy Starts */}
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            style={{ marginTop: "0.3%" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              aria-controls="panel5a-content"
              id="panel5a-header"
              style={{
                backgroundColor: "#f46d25",
                color: "#fff",
                marginTop: "0.5%",
                height: "0.5em",
              }}
            >
              <Typography variant="h5">Cancellation Policy</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#eee" }}>
              <CKEditor
                editor={ClassicEditor}
                name="cancellationPolicy"
                data={pkgTrmCndData.cancellationPolicy}
                onChange={(e, editor) => {
                  const data = editor.getData()
                  setPkgTrmCndData({
                    target: { name: "cancellationPolicy", value: data },
                  })
                }}
              />

              {/* <TextareaAutosize
                name="cancellationPolicy"
                onChange={setPkgTrmCndData}
                defaultValue={pkgTrmCndData.cancellationPolicy}
                style={{
                  maxWidth: "99.5%",
                  minHeight: "4em",
                  minWidth: "99.4%",
                  fontSize: "15px",
                  borderColor: "#f46d25",
                }}
              /> */}
            </AccordionDetails>
          </Accordion>
          {/* Cancellation Policy Ends */}

          {/* Refund Details Starts  */}
          {showRefund ? (
            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
              style={{ marginTop: "0.3%" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                aria-controls="panel6a-content"
                id="panel6a-header"
                style={{
                  backgroundColor: "#f46d25",
                  color: "#fff",
                  marginTop: "0.5%",
                  height: "0.5em",
                }}
              >
                <Typography variant="h5">Refund Details</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ backgroundColor: "#eee" }}>
                <Stack direction="row">
                  <Grid container spacing={2}>
                    <Grid item lg={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={{
                          color: "#138128",
                          borderColor: "#deffde",
                        }}
                        InputLabelProps={{
                          shrink: false,
                          style: {
                            color: "#f46d25",
                          },
                        }}
                        label={"Amount Refunded"}
                        disabled
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <TextField
                        name="amountRefunded"
                        variant="outlined"
                        fullWidth
                        style={{
                          backgroundColor: "#ffe2d7",
                          marginLeft: "-20px",
                        }}
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                        value={amountRefunded}
                        disabled
                        autoComplete="off"
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: "#fff",
                            background: "#f46d25",
                            borderRadius: "4px",
                            padding: "2px 4px",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item lg={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={{
                          color: "#138128",
                          borderColor: "#deffde",
                        }}
                        InputLabelProps={{
                          shrink: false,
                          style: {
                            color: "#f46d25",
                          },
                        }}
                        label={"Amount Retained"}
                        disabled
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <TextField
                        name="amountRetained"
                        value={amountRetained}
                        disabled
                        variant="outlined"
                        fullWidth
                        style={{
                          backgroundColor: "#ffe2d7",
                          marginLeft: "-20px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                        size="small"
                        autoComplete="off"
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: "#fff",
                            background: "#f46d25",
                            borderRadius: "4px",
                            padding: "2px 4px",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item lg={6}>
                      <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        style={{
                          color: "#138128",
                          borderColor: "#deffde",
                        }}
                        InputLabelProps={{
                          shrink: false,
                          style: {
                            color: "rgb(19, 129, 40)",
                          },
                        }}
                        label={"Revised Commission"}
                        disabled
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <TextField
                        name="revisedCommission"
                        value={revisedCommission}
                        disabled
                        variant="outlined"
                        fullWidth
                        style={{
                          backgroundColor: "#deffde",
                          marginLeft: "-20px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                        size="small"
                        autoComplete="off"
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: "#fff",
                            background: "#f46d25",
                            borderRadius: "4px",
                            padding: "2px 4px",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </AccordionDetails>
            </Accordion>
          ) : null}
          {/* Refund Details Ends  */}
          <br />

          {/* Action Buttons Starts */}
          <Stack direction="row" spacing={2} justifyContent="center">
            {validateForm(error) &&
            validateAllFields() &&
            showRefund === false ? (
              <button
                onClick={() => setOpenPreview(true)}
                style={twnButtonStyles.orangeBtn}
              >
                Preview
              </button>
            ) : (
              <button style={twnButtonStyles.disabledBtn}>Preview</button>
            )}
            {id && showRefund === false && isPackage === true && (
              <button
                style={twnButtonStyles.orangeBtn}
                onClick={() => {
                  setOpenRefund(true)
                }}
              >
                Cancel {PackageOrQuotation(isPackage)}
              </button>
            )}
            <Link
              to={{ pathname: `/konnect/packageOrQuotation/${pkgOrQtn}` }}
              style={twnButtonStyles.linkBlackBtn}
            >
              Cancel
            </Link>
          </Stack>
          {/* Action Buttons Ends */}

          {/* Add New Client on Plus Icon  */}
          <ClientRefresh
            open={open}
            onClose={() => {
              setOpen(false)
            }}
            selectedId={selectedId}
            BookingLocal={"yes"}
            pkgOrQtn={pkgOrQtn}
            displayLeads={displayLeads}
          />

          {/* Preview Dialog Starts  */}
          <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            sx={{ maxHeight: "100vh" }}
            open={openPreview}
            onClose={() => setOpenPreview(false)}
            TransitionComponent={Transition}
          >
            <div>
              <Preview
                data={{
                  ...formData,
                  leadPax: validLeadPax,
                  hotelDetails: validHotelInput,
                  transferDetails: validTransferInput,
                  activityDetails: validActvityInput,
                  type: PackageOrQuotation(isPackage),
                  displayLeads: displayLeads,
                  packageTeamCondition: pkgTrmCndData,
                  packagePaymentDetails: packagePaymentDetails,
                }}
                isPackage={isPackage}
              />
            </div>
            <div style={{ backgroundColor: "#eee" }}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                style={{ margin: "1%" }}
              >
                {validateForm(error) && validateAllFields() ? (
                  props.location.displayConvert ? (
                    <button
                      onClick={handleSubmitPackage}
                      style={{ ...buttonStyle, backgroundColor: "#f46d25" }}
                    >
                      Save as Package
                    </button>
                  ) : isClicked ? (
                    <button style={twnButtonStyles.disabledBtn}>
                      <i className="fa fa-refresh fa-spin"></i>
                      Submit
                    </button>
                  ) : (
                    <button
                      onClick={handleSubmit}
                      style={twnButtonStyles.orangeBtn}
                    >
                      Submit
                    </button>
                  )
                ) : (
                  <button style={twnButtonStyles.disabledBtn}>Submit</button>
                )}
                <button
                  style={{ ...twnButtonStyles.blackBtn, width: "80px" }}
                  autoFocus
                  color="inherit"
                  onClick={() => setOpenPreview(false)}
                >
                  Edit
                </button>
              </Stack>
            </div>
          </Dialog>
          {/* Preview Dialog Ends  */}

          {/* Refund Dialog Starts  */}
          <RefundPage
            openRefund={openRefund}
            closeFn={() => setOpenRefund(false)}
            handleRefund={handleRefund}
            totalGrossAmount={formData.totalGrossAmount}
            totalAmountPaid={totalAmountPaid}
            comission={formData.comission}
            amountRefunded={amountRefunded}
            setAmountRefunded={setAmountRefunded}
            amountRetained={amountRetained}
            setAmountRetained={setAmountRetained}
            revisedCommission={revisedCommission}
            setRevisedCommission={setRevisedCommission}
          />
          {/* Refund Dialog Ends  */}
        </div>
      )}
    </MuiPickersUtilsProvider>
  )
}

function CustomTextInlineField(props) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          name={props.name}
          fullWidth={true}
          label={props.label}
          focused={true}
          value={props.value}
          variant="outlined"
          size="small"
          onChange={props.onChange}
          disabled={props.disabled}
          sx={{
            // '& .MuiInputBase-input': { height: 1 },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              { borderWidth: "1px", borderColor: "#f46d25" },
            "& .MuiFormLabel-root.Mui-focused": { color: "#999" },
          }}
        />
      </Grid>
      {props.error[props.name] && props.error[props.name].length > 0 && (
        <Grid item xs={12}>
          <span style={{ color: "#ff0000" }}>{props.error[props.name]}</span>
        </Grid>
      )}
    </Grid>
  )
}

CustomTextInlineField.defaultProps = { error: {} }

export function DualTextField(props) {
  return (
    <Grid container>
      <Grid item xs={6}>
        <DualTextFieldLabel label={props.label} fullWidth={props.fullWidth} />
      </Grid>
      <Grid item xs={6}>
        {props.options ? (
          <DualTextFieldSelect
            options={props.options}
            changeFn={props.changeFn}
          ></DualTextFieldSelect>
        ) : (
          <DualTextFieldValue
            name={props.name}
            value={props.value}
            changeFn={props.changeFn}
            disabled={props.disabled}
            inputValue={props.inputValue}
          />
        )}
      </Grid>
    </Grid>
  )
}

DualTextField.defaultProps = {
  options: null,
  fullWidth: true,
  disabled: false,
  inputValue: true,
}

function DualTextFieldLabel(props) {
  return (
    <TextField
      variant="outlined"
      size="small"
      fullWidth={props.fullWidth}
      style={{
        color: "#138128",
        borderColor: "#deffde",
      }}
      InputLabelProps={{
        style: {
          color: "#f46d25",
          marginTop: window.innerWidth <= 768 ? "0" : "-8px",
          fontSize: window.innerWidth <= 768 ? "13px" : "15px",
        },
      }}
      label={props.label}
      disabled
      // sx={{ '& .MuiInputBase-input': { height: 1 }, }}
    />
  )
}

function DualTextFieldValue(props) {
  const InputProps = {
    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
  }
  return (
    <TextField
      name={props.name}
      data-testid={props.name}
      variant="outlined"
      fullWidth
      disabled={props.disabled}
      style={{
        backgroundColor:
          props.name === "paymentRefNo"
            ? "#ffffff"
            : "#ffe2d7" && props.name === "comission"
            ? "#deffde"
            : "#ffe2d7",
        marginLeft: window.innerWidth <= 768 ? "0px" : "-20px",
      }}
      InputProps={props.inputValue ? InputProps : {}}
      size="small"
      value={props.value}
      onChange={props.changeFn}
      autoComplete="off"
      // sx={{ '& .MuiInputBase-input': { height: 1 } }}
    />
  )
}

function DualTextFieldSelect(props) {
  return (
    <div
      style={{
        borderColor: "#f46d25",
        width: "100%",
        marginLeft: window.innerWidth <= 768 ? "0" : "-20px",
      }}
    >
      <Select
        placeholder="Select"
        options={props.options}
        onChange={props.changeFn}
      />
    </div>
  )
}
