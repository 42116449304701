import {
  FormControlLabel,
  Checkbox,
  Switch,
  makeStyles,
} from "@material-ui/core"
import { Grid } from "@mui/material"
import { Field } from "formik"

const useStyles = makeStyles((theme) => ({
  checkboxStyling: {
    "& .MuiSvgIcon-root": {
      color: "#f46d25",
      height: "18px",
      width: "18px",
    },
  },
  menuStyling: {
    fontSize: "14px",
  },
  selectLabelProps: {
    backgroundColor: "white",
    color: "#000000",
    padding: "0px 5px 5px 5px",
    borderRadius: "5px",
  },
  menuItemStyles: {
    height: "300px",
    fontSize: "14px",
  },
  selectStyle: {
    height: "35px",
    width: "100%",
    "& .MuiSelect-icon": {
      color: "#f46d25",
    },
  },
  checkBoxLabelStyles: {
    fontSize: "14px",
  },
}))
const FormikInlineCheckboxAndSwitchOnly = ({ amenity, values }) => {
  const classes = useStyles()
  console.log({ values })

  return (
    <Grid item container xs={9} alignItems="center" spacing={3}>
      <Grid item xs={6}>
        <Field
          as={FormControlLabel}
          control={
            <Field
              as={Checkbox}
              checked={values[amenity.name]}
              name={amenity.name}
              className={classes.checkboxStyling}
            />
          }
          label={
            <span className={classes.checkBoxLabelStyles}>{amenity.label}</span>
          }
        />
      </Grid>
      {values[amenity.name] && (
        <Grid item xs={6}>
          <Field
            name={amenity.switchName}
            as={Switch}
            color="primary"
            checked={values[amenity.switchName]}
            size="small"
          />
        </Grid>
      )}
    </Grid>
  )
}

export default FormikInlineCheckboxAndSwitchOnly
