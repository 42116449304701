import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import MockImage from "../../assets/illustrations/mockImages/Screen Shot 2020-02-15 at 11.04 1.png"
import { currencyFormatter } from "../../utility/currencyFormatter"
import "./HotelCard.styles.css"
import { ContactModalContext } from "../../context/contactModal.context"
import { useContext } from "react"
const HotelCard = ({
  imageUrl,
  location,
  hotelName,
  rating,
  reviews,
  price,
}) => {
  const history = useHistory()
  const { toggleModal } = useContext(ContactModalContext)
  return (
    <div className="most-popular-hotel-card" onClick={toggleModal}>
      <span className="image-badge">Featured</span>
      <img className="card-image-container" src={imageUrl} alt={location} />
      <div className="card-body">
        <div className="card-header">
          <small>{location}</small>
          <div className="hotel-name">{hotelName}</div>
        </div>{" "}
        <div className="card-footer">
          <small>
            <div>
              <span className="rating-icon">&#9733;</span> {rating}{" "}
              <span className="no-of-reviews">{reviews} reviews</span>
            </div>
            {/* <span className="price-container">
              Starts from{" "}
              <span className="starts-from">
                {currencyFormatter(price || 0)}
              </span>
            </span> */}
          </small>
        </div>
      </div>
    </div>
  )
}

export default HotelCard
