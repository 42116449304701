import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Grid } from "@mui/material";
import Api from "../../../Service/Api";
import PopulatWithGuests from "./propertyRoomAmenities/popularWithGuests.component";
import RoomBathroomAmenities from "./propertyRoomAmenities/propertyRoomBathRoomAmenities.component";
import PropertyRoomFeatures from "./propertyRoomAmenities/propertRoomFeatures.component";
import PropertyRoomMediaAndEnteratinment from "./propertyRoomAmenities/propertyRoomMediaAndEntertainment.component";
import PropertyRoomPhoto from "./propertyRoomAmenities/propertyRoomPhoto.component";
import PropertyRoomKitchenAppliances from "./propertyRoomAmenities/propertyRoomKitchenAppliances.component";

const useStyles = makeStyles((theme) => ({
  accordionSummaryStyles: {
    background: "#f46D25",
    color: "white",
    fontSize: "17px",
    maxHeight: "42px",
    minHeight: "42px",
    borderRadius: "5px 5px 0px 0px",
    overflow: "hidden",

    "&.Mui-expanded": {
      maxHeight: "42px",
      minHeight: "42px",
    },
  },
  accordionStyles: {
    boxShadow: "none",
    border: "1px solid #D2D2D2",
    borderRadius: "5px",
    marginBottom: "10px",
  },
  outerSectionStyles: {
    marginTop: "19px",
  },
  headerStyles: {
    fontSize: "18px",
    color: "#f46d25",
    fontWeight: "bold",
  },
}));

const PropertyRoomAmenities = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState("panel1");

  const handleChangePanel = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  let { propertyId, displayName } = useParams();

  const [popularWithGuestsData, setPopularWithGuests] = useState(() => {});
  const [RoomAirConditioning, setRoomAirConditioning] = useState([]);
  const [RoomHeater, setRoomHeater] = useState([]);
  const [RoomHouseKeeping, setRoomHouseKeeping] = useState([]);
  const [RoomInRoomDining, setRoomInRoomDining] = useState([]);
  const [RoomRoomService, setRoomRoomService] = useState([]);
  const [RoomSanitizers, setRoomSanitizers] = useState([]);
  const propertyData = { propertyId: propertyId, displayName: displayName };
  let uniqueid = localStorage.getItem("unique_id");
  let createdBy = localStorage.getItem("auth");
  const create = { uniqueId: uniqueid, createdBy: createdBy };
  const [RoomBalcony, setRoomBalcony] = useState([]);
  const [loading, setLoading] = useState(false);

  return (
    <section className={classes.outerSectionStyles}>
      <header className={classes.headerStyles}>Room Amenities</header>
      <PopulatWithGuests
        classes={classes}
        expanded={expanded}
        handleChangePanel={handleChangePanel}
        setExpanded={setExpanded}
      />
      <RoomBathroomAmenities
        classes={classes}
        expanded={expanded}
        handleChangePanel={handleChangePanel}
        setExpanded={setExpanded}
      />
      <PropertyRoomFeatures
        classes={classes}
        expanded={expanded}
        handleChangePanel={handleChangePanel}
        setExpanded={setExpanded}
      />
      <PropertyRoomMediaAndEnteratinment
        classes={classes}
        expanded={expanded}
        handleChangePanel={handleChangePanel}
        setExpanded={setExpanded}
      />
      <PropertyRoomPhoto
        classes={classes}
        expanded={expanded}
        handleChangePanel={handleChangePanel}
        setExpanded={setExpanded}
      />
      <PropertyRoomKitchenAppliances
        classes={classes}
        expanded={expanded}
        handleChangePanel={handleChangePanel}
        setExpanded={setExpanded}
      />
    </section>
  );
};

export default PropertyRoomAmenities;
