import PhoneIcon from "../../assets/icons/contact-icons/phone.svg"
import MailIcon from "../../assets/icons/contact-icons/mail.svg"

import styles from "./ContactInfo.module.css"

const ContactInfo = () => {
  return (
    <div className={styles.contactCard}>
      <div className={styles.contactCardHeader}>
        Get assistance for easy booking.
      </div>
      <p className={styles.contactInfo}>
        <img src={PhoneIcon} />
        <a href="tel:+918006800727" className={styles.info}>
          +91 8006 800 727
        </a>
      </p>
      <p className={styles.contactInfo}>
        <img src={MailIcon} />
        <a href="mailto:hello@towno.in" className={styles.info}>
          hello@towno.in
        </a>
      </p>
    </div>
  )
}

export default ContactInfo
