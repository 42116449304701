import Api from "../../Service/Api";
import { ActionTypes } from "./actionTypes";

let role = localStorage.getItem("role");

export const bookingDetialInitial = (id, uniqueid) => async (dispatch) => {
  dispatch({
    type: ActionTypes.BOOKINGDETIAL_LIST_REQUEST,
  });

  let url =
    role === "Hotel Admin" ? "bookingDetailsForHotelLogin" : "bookingdetails";

  await Api.get(`${url}/${uniqueid}/${id}`).then((res) => {
    dispatch({
      type: ActionTypes.BOOKINGDETIAL_LIST_SUCCESS,
      payload: res.data,
    });
  });
};

export const historyListInitial = (uniqueid, id) => async (dispatch) => {
  dispatch({
    type: ActionTypes.HISTROY_LIST_REQUEST,
  });
  await Api.get(`bookinghistory/${uniqueid}/${id}`).then((res) => {
    dispatch({
      type: ActionTypes.HISTROY_LIST_SUCCESS,
      payload: res.data,
    });
  });
};
