import styles from "./BiggerGroupBanner.module.css"
import BiggerGroupBanner from "../../assets/illustrations/destination/banner_images/bigger_grp_banner.png"
import { useContext } from "react"
import { ContactModalContext } from "../../context/contactModal.context"
const BiggerGroupBannerPost = () => {
  const { toggleModal } = useContext(ContactModalContext)

  return (
    <div className={styles.offerBannerContainer}>
      <img src={BiggerGroupBanner} className={styles.offerBanner} />
      <div className={styles.offerContent}>
        <p className={styles.offerPrimaryTitle}>Bigger Group?</p>
        <p className={styles.offerSecondaryTitle}>
          Get special Offers upto <span>50% Off !</span>
        </p>
        <p className={styles.offerDescription}>
          We create unforgettable adventures, customised for your group.
        </p>
        <button
          type="button"
          className={styles.callbackButton}
          onClick={toggleModal}
        >
          Get A CallBack
        </button>
      </div>
    </div>
  )
}

export default BiggerGroupBannerPost
