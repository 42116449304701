import parser from "html-react-parser"
import Air from "../../../../assets/illustrations/destination/mock_card_images/travel_by_air.png"
import Rail from "../../../../assets/illustrations/destination/mock_card_images/travel_by_rail.png"
import Road from "../../../../assets/illustrations/destination/mock_card_images/travel_by_road.png"
import BiggerGroupBannerPost from "../../../../components/BiggerGroupBanner/BiggerGroupBanner.component"
import ImageAndContentCard from "../../../../components/ImageAndContentCard/ImageAndContentCard.component"
import globalStyles from "../../../../globalStyles.module.css"
import styles from "./DestinationTourPackages.module.css"

import HaridwarImg from "../../../../assets/illustrations/destination/where-you-headed/Haridwar.jpg"
import RishikeshImg from "../../../../assets/illustrations/destination/where-you-headed/Rishikesh2.jpg"
import NainitalImg from "../../../../assets/illustrations/destination/where-you-headed/nanital.jpg"

import { Fragment } from "react"
import DestinationSubLayout from "../../../../layout/DestinationSubLayout/DestinationSub.layout"

const tourPackages = [
  {
    src: "",
    title: "Best Time to Visit",
    content: `The best time to organize a Uttarakhand trip is in the summer or winter. 
<p>During summers tourists can explore the buffet of hill stations on Uttarakhand's platter from Nainital to Mussoorie, Ranikhet, Almora, and Kausani. From March to June, temperatures remain between 20°C - 35°C and weather remains pleasant, which is a welcome sight compared to the scorching heat in the rest of India.</p>
<p>During the winter months from October to February, temperatures remain between 3°C - 15°C and often drop below zero in extreme altitudes and Himalayan peaks. The majority of the state receives snowfall making it even more surreal and picturesque.</p>
During monsoon months from July to September, it is better to avoid travel to Uttarakhand as the state receives torrential rains. The weather is extremely humid and hilly regions are prone to landslides.
`,
  },
  {
    src: "",
    title: "Location",
    content: `Most of Uttarakhand is either mountainous or covered by forests and a minority by plains. It is surrounded by the states of Himachal Pradesh in the north-west and Uttar Pradesh in the south. On the northern side, it shares its international borders with Nepal and China.`,
  },
]

const travelModes = [
  {
    imgSrc: Air,
    title: "By Air",
    content: `There are two domestic airports in Uttarakhand. The Jolly Grant Airport in Dehradun is located about 22 km from the city. The airport at Pantnagar serves the hill stations of Nainital, Ranikhet, and Almora.`,
  },
  {
    imgSrc: Rail,
    title: "By Rail",
    content: `A convenient way to start your Uttarakhand trip is to reach Nainital, Dehradun, or Haridwar. The Kathgodam railway station which is located 35 km from Nainital is the last terminal of the North East railways.</p>`,
  },
  {
    imgSrc: Road,
    title: "By Bus",
    content: `The reason Uttarakhand tour packages are so popular is that roads in Uttarakhand are well connected. The state has more than 1300 km of national highways and 1500 km of state highways. Whether you want to take the bus, drive yourself, or hire a taxi, the joy of traveling by road in this beautiful state is unparalleled. If you are traveling from Delhi, the easiest way to travel remains the road. You can opt for Volvo or state transport buses from ISBT, which will take you to the numerous bus stations during your Uttarakhand travel. You can also take the Shatabdi or Nanda Devi Express, both extremely efficient trains that connect Delhi with the capital city of Dehradun.`,
  },
]

const places = [
  {
    title: "Nainital",
    content: `One of the most popular hill stations in India, Nainital lies in the cradle of mountains with a beautiful lake at its center. Enjoy a boat ride in the lake along with a trip to the surrounding lakes in the district. One of the most visited hill stations in India for families as well as couples, Nainital is a staple of all Uttarakhand holiday packages. This district of lakes with its surrounding lakes such as Naukuchia Taal, Bhimtal, Sattal, and Kuruptal are all definitely must-see places in Uttarakhand.  The Lakesides are all sprawling with food stalls and restaurants, which serve you scrumptious food with a view. The Nainital Zoo is loved by children of all ages and animals should definitely be visited if you are accompanied by little ones. You can shop in the Tibetan market for both woollen and trendy clothes. Also recommended is a visit to the governor's house, which is often known as India's very own Buckingham Palace.`,

    src: NainitalImg,
  },
  {
    title: "Rishikesh",
    content: `The adventure capital of India, Rishikesh is famous for adrenalizing activities such as Bungee jumping, Rafting, and Camping. You can also go Cliff jumping, Rock climbing, and kayaking as part of Uttarakhand Holiday Packages. Bungee jumping in Rishikesh is a unique experience as it is an 83-meter-high jump above the river. Rishikesh is also known for its yoga and meditation centers and it is rightly sometimes called the world capital of yoga. Popular tourist attractions such as Ram Jhula and Lakshman Jhula are a must-visit for every tourist during his/her Uttarakhand travel. The city also has its share of cafes with a view, where you can enjoy a calm evening with your loved ones. You can also find ashrams dedicated to yoga as well as the famous Beatles' ashram dedicated to the famous music band. `,

    src: RishikeshImg,
  },
  {
    title: "Haridwar",
    content: `A trip to the holy city of Haridwar will cleanse you of your bad karma. If you’re traveling with your family, the mesmerizing Aarti at the bank of River Ganga makes this a complete Uttarakhand holiday package. Haridwar and Rishikesh are often considered twin national heritage cities. The crowded bylanes of Haridwar make for a unique experience when you visit the city. There is an array of temples starting from the Hilltop temple of Mansa Devi to the Maya Devi temple on the river bank and Chandi Devi temple on Neel Parvat which are attractive to everyone with a spiritual bent. The street food of Haridwar is spicy, tempting, and scrumptious, especially the Aloo puri and Kachori, which will leave you panting but asking for more.`,

    src: HaridwarImg,
  },
  //   {
  //     title: "Dehradun",
  //     content: `The capital city of Uttarakhand, Dehradun is strategically located in the Doon Valley on the foothills of the Himalayas, flanked between the river Ganges on one side and the river Yamuna on other side. It is also one of Uttarkhand’s famous tourist places.

  // <p>You can see the city of Mussoorie, located just 40 km away, sparkling against the hills in the form of scintillating Mussoorie Lights at night. Also popular amongst tourists are Dehradun’s cafes with their quaint outdoors and elaborate menus. The Forest Research Institute is the oldest and biggest forest-based training institute with its intricate colonial architecture is also a must-visit for all photographers and an amazing place to see in Uttarakhand.</p>`,
  //     src: DehradunImg,
  //   },
  //   {
  //     title: "Mussoorie",
  //     content: `A composite portrait of breath-taking landscapes, Mussoorie is definitely one of the top 5 places to visit in Uttarakhand. You can hike towards Happy Valley from Library Head or plan for a longer trek to the Nag Tibba Summit. Either ways, you can easily lose yourself in the captivating backdrop of mountains and valleys. Another popular tourist destination in Uttarakhand is Kempty Falls, the perfect place for a day picnic, just 13 km from Mussoorie.
  //    <p> You should also definitely plan to visit Mall Road, as it is the ultimate shopping destination for tourists for woollen clothes, wooden artefacts and even cheap electronic goods. With an endless line of stalls, roadside vendors and restaurants, the road is heaven for foodies and shopaholics alike.</p>`,

  //     src: MussoorieImg,
  //   },

  //   {
  //     title: "Jim Corbett National Park",
  //     content: `Home to the Royal Bengal Tiger and over 500+ birds as well as other plants and animals, Jim Corbett is a perfect place to visit in Uttarakhand for nature enthusiasts and photographers. You can opt for a jeep Safari that will take you deep in the jungle with an experienced guide or you can plan your own excursions, with permission of course.

  // <p>For those planning sightseeing in Uttarakhand, a visit to Jim Corbett is definitely recommended. Beyond the Jungle Safari and Elephant ride, a 66 feet high waterfall within Corbett is another attraction and so is the Girija Temple dedicated to Goddess Parvati. Another place to visit is the Kosi river, which provides water to wildlife in the Park. The Kois river does not enter the park, but runs close to the park boundaries and provides a great view of the Shivalik Hills against the flowing water. You can also go fishing in the fishing camps of Kosi for Mahseer.</p>`,
  //     src: JimCorbettImg,
  //   },

  //   {
  //     title: "Char Dham - Yamunotri, Gangotri, Badrinath and Kedarnath",
  //     content: `The best of spiritual treats is the Char Dham Yatra, a trip to four sacred places called as Char Dham, can help you attain Moksha (salvation).<p>

  // Historically known as the origin of Yamuna river, Yamunotri is one of the most famous Uttarakhand tourist spots and pilgrimages. Similarly, Gangotri is the origin of the river Ganga. While Badrinath and Gangotri are easily accessible by road, Kedarnath and Yamunotri require a much more concentrated effort from travellers.</p>`,
  //     src: ChardhamImg,
  //   },
]

const activities = [
  {
    src: "",
    title: "Jeep Safari in Jim Corbett National Park",
    content: `Jeep Safari is the best way to explore the Jim Corbett National Park and is the highlight of a Uttarakhand trip package. The park is open to customers throughout the year however the best time to enjoy the Jeep Safari is from November to June. A few of the zones of the park such as the Bijrani zone are closed during monsoon as the roads get flooded. It is important to take proper permission before entering the forest and wear covered clothes which will protect you from mosquito and other insect bites.`,
  },
  {
    src: "",
    title: "Char Dham Yatra",
    content: `An amalgamation of four pilgrimage sites in India, Badrinath, Kedarnath, Gangotri, and Yamunotri, the Char Dham Yatra has great significance in the Hindu religion.  These four spots in the Garhwal Himalayas are mentioned with great respect in the Hindu Vedas and Puranas.  It is because of the presence of these four tourist spots that Uttarakhand is famously known as the land of gods. Yamunotri and Gangotri are dedicated to the Goddesses Yamuna and Ganga respectively, Kedarnath is a shrine to Lord Shiva and Badrinath is dedicated to Lord Vishnu. The Char Dham Yatra is one of the most popular Uttarakhand tour packages. The flowing rivers shrines, caves as well as temples make these exciting tourist spots for everyone.<p>Out of these, Kedarnath is considered to be the holiest temple and the most prestigious pilgrimage for Hindus, whereas Badrinath is the most visited pilgrimage amongst all locations due to its easier access.</p>`,
  },
  {
    src: "",
    title: "Rafting in Rishikesh",
    content: `Rafting in Rishikesh is a wonderful adventure activity for people of all ages. Navigating through the rapids of the river Ganga along with a guide is not only safe but also a great team-building activity for corporates and large groups. The river rafting rapids stretch from Kaudiyala to Lakshman Jhula (36 km) and have more than 13 major rapids. You can choose these according to your expertise or you can choose to just kayak in the river as part of your Uttarakhand tour packages. Also available are activities such as Cliff jumping, Rock climbing, and various other water sports.`,
  },
]

const others = [
  {
    src: "",
    title: "Traveling With Family",
    content: `A Uttarakhand trip is the perfect gift for your family because it has something for everyone. From religious and pilgrimage sites to temples and shrines, which cater to the elderly, to adventure activities such as bungee jumping, rafting, and trekking which are attractive to youngsters, Uttarakhand tour packages cater to the entire family. Leisure destinations such as Mussoorie, Nainital, and Ranikhet are perfect for family bonding and sharing quality time, Trips to Jim Corbett National Park, Rajaji National Park, and Binsar Wildlife Sanctuary are also perfect for Uttarakhand Holiday Packages for the entire family. A visit to the High Altitude Zoo in Nainital which is located 6900 feet above sea level and hosts several endangered Himalayan species is another activity that is extremely popular with families.  Boating through the Naini lake and enjoying Lakeside savouries as you take home tons of jams and squashes to consume later, is definitely the highlight of Uttarakhand travel for the children and the entire family.`,
  },
  {
    src: "",
    title: "Travelling With Your Partner",
    content: `If you are travelling with your loved ones, then Uttarakhand is the perfect destination for you.  From Mussoorie, the Queen of The Hills to Nainital with its sprawling lakes to watching sunsets across the mountains, there is no better way to spend your time with a partner than on a Uttarakhand Holiday Package. Mussoorie is extremely popular as a honeymoon destination. While in Mussoorie you can take a trip to the Benog Wildlife Sanctuary climb to the top of Lal Tibba or simply enjoy a romantic boat ride in Mussoorie Lake. <p>Auli is known as the Switzerland of India, with its scenic views and exhilarating skiing and winter sports. This is also an excellent destination if you are travelling with your partner. You can book an Uttarakhand tour package with a romantic Gondola ride take a tour of the Chenab Lake or simply sit and enjoy the mesmerizing view of Nanda Devi, Hathi Parvat, Trishul, and Nikanth mountains. Newer destinations such as Lansdowne and Kausani are also popular with couples. If you are a couple who loves adventure then you can plan your perfect Uttarakhand trip with adventure activities across Rishikesh and Kanatal or Nag tibba and Har Ki Doon treks across the mountains. Nainital is also popular amongst couples from boating in the Naini Lake to catch the panoramic views From Naina Peak to walking up to the tiffin top and taking home candles made by local artists, Nainital has a lot to offer if you are travelling with your loved one.</p>`,
  },
  {
    src: "",
    title: "Shopping in Uttarakhand",
    content: `Shopping remains an important part of all Uttarakhand tourism packages because when the trip is over, you take back home travel memories as well as inspiration for more travels. From locally made Woollens to intricately carved shawls to indigenously produced jams and squashes, you have plenty to choose from during your Uttarakhand trip. You can also shop for delicately carved woodwork and local crafts and take them home from Uttarakhand.`,
  },
  {
    src: "",
    title: "Must-Have Food In Uttarakhand",
    content: `Uttarakhand cuisine is extremely flavorsome and aromatic. Full of local spices and cooked slowly over the fire the food consumed during your Uttarakhand trip has a delicate flavor and an exquisite taste. The people of Uttarakhand are partial to lentils specially the ones which are locally grown such as Gehet and Bhatt and make various preparations out of these such as Mandwa Ki Roti, Dubuk and Chainsoo, and many more. Accompaniments like Bhang ki chutney with its fresh Aroma and tangy taste is also a must-try while you are on your Uttarakhand holiday package.
Street food and food from roadside vendors is also extremely popular in Uttarakhand. From momos to soups and Maggi, you can find an assortment of snacks available for ready consumption throughout your journey specially during winter.
`,
  },
]

const DestinationTourPackages = () => {
  return (
    <DestinationSubLayout>
      <main className={globalStyles.main}>
        <section className={globalStyles.topMostSection}>
          <header>
            Uttarakhand <span>Tour Packages</span>
          </header>
          <div className={globalStyles.content}>
            The land of mythology and mountains, Uttarakhand is incredibly
            popular amongst tourists for its scenic terrain, culturally rich
            pilgrimage sites as well as an array of hill stations.
            <p>
              <b>Uttarakhand tour packages</b> not only provide tourists an
              opportunity to explore religious sites such as Haridwar but also
              exposure to nature's most beautiful creations in the form of
              Yamnotri and Gangotri. You can also include on your{" "}
              <b>Uttarakhand trip</b> hill stations that are endowed with
              beautiful lakes such as Nainital or those that embrace the
              mountains and valleys such as Mussoorie.
            </p>
            <b>Uttarakhand travel</b> is incomplete without a visit to a
            wildlife sanctuary in Jim Corbett or sampling adventure in the form
            of rafting at Rishikesh or skiing at Auli. Whether you’re traveling
            with family, your significant other, or your friends, a Uttarakhand
            package can cater to each one of your holiday desires.
          </div>
        </section>
        <article className={styles.contentList}>
          {tourPackages.map((f, idx) => (
            <ImageAndContentCard key={idx}>
              <ImageAndContentCard.Title>{f.title}</ImageAndContentCard.Title>
              <ImageAndContentCard.Content>
                {parser(f.content)}
              </ImageAndContentCard.Content>
            </ImageAndContentCard>
          ))}
        </article>

        <article className={styles.travelModes}>
          <ImageAndContentCard.Title>How To Reach ?</ImageAndContentCard.Title>

          <div className={styles.travelCardList}>
            {travelModes.map((mode, idx) => (
              <div className={styles.travelCard} key={idx}>
                <img src={mode.imgSrc} />
                <div className={styles.description}>
                  <h3>{mode.title}</h3>
                  <div className={styles.instructions}>
                    {parser(mode.content)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </article>
        <hr className={styles.hr} />

        <section className={globalStyles.topMostSection}>
          <header style={{ color: "#f46d25" }}>
            Popular Places in Uttarakhand
          </header>
          <div className={globalStyles.content}>
            Uttarakhand tour packages include an assortment of leisure,
            cultural, and adventure activities for tourists in the state. From
            hill stations to wildlife sanctuaries to pilgrimage sites, there are
            plenty of exciting places to visit during your Uttarakhand travel:
          </div>

          <div className={styles.placeToVisitList}>
            {places.map((place, idx) => (
              <Fragment key={idx + 1}>
                <ImageAndContentCard>
                  <ImageAndContentCard.Title>
                    {idx + 1}. {place.title}
                  </ImageAndContentCard.Title>
                  <ImageAndContentCard.Image src={place.src} />
                  <ImageAndContentCard.Content
                    title={`${idx + 1}. ${place.title}`}
                  >
                    {parser(place.content)}
                  </ImageAndContentCard.Content>
                </ImageAndContentCard>
                {idx < places.length - 1 && <hr className={styles.hr} />}
              </Fragment>
            ))}
          </div>
        </section>
        <hr className={styles.hr} />

        <section
          className={globalStyles.topMostSection}
          style={{ marginTop: "3rem" }}
        >
          <header style={{ color: "#f46d25" }}>
            Popular Activities and Things to Do in Uttarakhand
          </header>
          <div
            className={styles.placeToVisitList}
            style={{ marginTop: "2rem" }}
          >
            {activities.map((place, idx) => (
              <ImageAndContentCard>
                <ImageAndContentCard.Title>
                  {idx + 1}. {place.title}
                </ImageAndContentCard.Title>
                {/* <ImageAndContentCard.Image src={place.src} /> */}
                <ImageAndContentCard.Content
                  title={`${idx + 1}. ${place.title}`}
                >
                  {parser(place.content)}
                </ImageAndContentCard.Content>
              </ImageAndContentCard>
            ))}
            <hr className={styles.hr} />
          </div>

          <div
            className={styles.placeToVisitList}
            style={{ marginTop: "2rem" }}
          >
            {others.map((place, idx) => (
              <ImageAndContentCard>
                <ImageAndContentCard.Title>
                  {place.title}
                </ImageAndContentCard.Title>
                {/* <ImageAndContentCard.Image src={place.src} /> */}
                <ImageAndContentCard.Content>
                  {parser(place.content)}
                </ImageAndContentCard.Content>
              </ImageAndContentCard>
            ))}
          </div>
        </section>

        <BiggerGroupBannerPost />
      </main>
    </DestinationSubLayout>
  )
}

export default DestinationTourPackages
