import React, { useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button } from "@material-ui/core";
import XLSX from "xlsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from '@material-ui/icons/Cancel';
import { format, parse } from "date-fns";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    background: "#F46D25",
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    background: "#F46D25",
    color: "#fff",
    fontSize: "13px",

    lineHeight: "1rem",
    minWidth: "100px",
}));

const StyledTableCellInside = styled(TableCell)(({ theme }) => ({
    fontSize: "13px",
    lineHeight: "15px",
}));

const columns = [
    { id: "hotelName", label: "Hotel Name" },
    { id: "hotelEmail", label: "Hotel Email" },
    { id: "HotelPhNo", label: "Hotel Ph. No" },
    { id: "HotelAddress", label: "Hotel Address" },
    { id: "city", label: "City" },
    { id: "starCategory", label: "Star Category" },
    { id: "totalNoOfRooms", label: " Rooms " },
    { id: "checkinTime", label: "Check-In Time" },
    { id: "checkoutTime", label: "Check-Out Time" },
    { id: "checkIn24", label: "24 Hrs CheckIn" },
    { id: "couplefriendly", label: "Couple Friendly" },
    { id: "beachnearby", label: "Beach Nearby" },
    { id: "swimingpool", label: "Swimming Pool" },
    { id: "petFriendly", label: "Pet Friendly" },
    { id: "conferencehall", label: "Conference Hall" },
    { id: "kidsplayarea", label: "Kids Play Area" },
    { id: "designatorforwedding", label: "Destination for Wedding" },
    { id: "isspa", label: "Spa" },
    { id: "jacuzzi", label: "Jacuzzi" },
    { id: "amenties", label: "Amenities" },
    { id: "status", label: "Status" },
    { id: "createdBy", label: "Created By" },
    { id: "createdAt", label: "Created At" },
];

export default function TotalAgentHotels(props) {
    const { reportData } = props;

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const downloadExcel = () => {
        const newData = reportData.map((row) => {
            delete row.id;
            return row;
        });
        const workSheet = XLSX.utils.json_to_sheet(newData);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Hotel");
        // const buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workBook, "Hotel.xlsx");
    };

    return (
        <div>
            <div
                style={{
                    width: "50%",
                    display: "inline-block",
                    margin: "10px 0px",
                    color: "#F46D25",
                }}
            >
                {" "}
                <b>Total Hotels Report</b>
            </div>
            <div
                style={{
                    width: "50%",
                    display: "inline-block",
                    textAlign: "end",
                    margin: "10px 0px",
                }}
            >
                <Button size="small" onClick={downloadExcel}>
                    Download As Excel
                </Button>
            </div>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <StyledTableRow>
                                {columns.map((column) => (
                                    <StyledTableCell key={column.id} align={column.align}>
                                        {column.label}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {reportData
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => {
                                    return (
                                        <TableRow key={row.id}>
                                            <StyledTableCellInside
                                                style={{ textTransform: "capitalize" }}
                                            >
                                                {row.hotelName}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside>
                                                {row.hotelEmail}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside>
                                                {row.hotelContact}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside>
                                                {row.hotelAddress}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside>
                                                {row.city}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside>
                                                {row.starCategory}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside>
                                                {row.totalNoOfRooms}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside>
                                                {row.checkinTime}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside>
                                                {row.checkoutTime}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside style={{textAlign:"center"}}>
                                                {row.checkIn24 === true ? <CheckCircleIcon style={{ color: "#f46d25" }}/>:<CancelIcon style={{color: "#666"}}/>}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside style={{textAlign:"center"}}>
                                                {row.couplefriendly === true ? <CheckCircleIcon style={{ color: "#f46d25" }}/>:<CancelIcon style={{color: "#666"}}/>}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside style={{textAlign:"center"}}>
                                                {row.beachnearby === true ? <CheckCircleIcon style={{ color: "#f46d25" }}/>:<CancelIcon style={{color: "#666"}}/>}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside style={{textAlign:"center"}}>
                                                {row.swimingpool === true ? <CheckCircleIcon style={{ color: "#f46d25" }}/>:<CancelIcon style={{color: "#666"}}/>}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside style={{textAlign:"center"}}>
                                                {row.petFriendly === true ? <CheckCircleIcon style={{ color: "#f46d25" }}/>:<CancelIcon style={{color: "#666"}}/>}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside style={{textAlign:"center"}}>
                                                {row.conferencehall === true ? <CheckCircleIcon style={{ color: "#f46d25" }}/>:<CancelIcon style={{color: "#666"}}/>}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside style={{textAlign:"center"}}>
                                                {row.kidsplayarea === true ? <CheckCircleIcon style={{ color: "#f46d25" }}/>:<CancelIcon style={{color: "#666"}}/>}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside style={{textAlign:"center"}}>
                                                {row.designatorforwedding === true ? <CheckCircleIcon style={{ color: "#f46d25" }}/>:<CancelIcon style={{color: "#666"}}/>}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside style={{textAlign:"center"}}>
                                                {row.isspa === true ? <CheckCircleIcon style={{ color: "#f46d25" }}/>:<CancelIcon style={{color: "#666"}}/>}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside style={{textAlign:"center"}}>
                                                {row.jacuzzi === true ? <CheckCircleIcon style={{ color: "#f46d25" }}/>:<CancelIcon style={{color: "#666"}}/>}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside>
                                                {row.amenties}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside>
                                                {row.status}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside>
                                                {row.createdBy}
                                            </StyledTableCellInside>
                                            <StyledTableCellInside>
                                                {format(parse(row.createdAt,"yyyy-MM-dd HH:mm:ss",new Date()),"dd-MM-yyyy HH:mm:ss")}
                                            </StyledTableCellInside>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={reportData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
