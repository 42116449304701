import styles from "./trekkingLayout.module.css"
import BannerOne from "../../assets/illustrations/destination/banner_images/destination_bg_1.png"

const TrekkingLayout = ({ children }) => {
  return (
    <section className={styles.layout}>
      <header className={styles.header}>
        <img className={styles.headerImage} src={BannerOne} />
        <div className={styles.headerTitle}>
          <div>Valley Of Flowers</div>
          <div>Trek</div>
        </div>
      </header>
      <main className={styles.main}>{children}</main>
    </section>
  )
}

export default TrekkingLayout
