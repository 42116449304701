import { Card, Grid, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import htmlParser from "html-react-parser";
import React from "react";
import { formatter } from "../../utils/formatNumber";
import { twnButtonStyles } from "../../utils/townoStyle";

const cardLabel = { padding: "0.5em 1em", color: "#111", fontWeight: "500" };
const cardValue = { padding: "0.5em 1em", color: "#111" };

function removeSpaceNewline(text) {
  if (text) {
    let a = text.split("\n");
    return a.join(" | ");
  } else {
    return "";
  }
}

export default function Preview({ data, isPackage }) {
  console.log(
    "CreatePackage.Preview| Preview|data: ",
    data,
    "itinerary:",
    data.itinerary
  );

  return (
    <div
      style={{ padding: "2% 1%", fontSize: "14px", backgroundColor: "#F7F7F7" }}
    >
      <Typography variant="h5" component="h5" style={twnButtonStyles.xlFonts}>
        {data.type} Details
      </Typography>
      <Card style={{ marginTop: "9px" }}>
        <div
          style={{
            backgroundColor: "#f46d25",
            color: "#fff",
            padding: "0.5em 1em",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Basic Details
        </div>
        <Stack>
          <Stack
            direction="row"
            spacing={1}
            style={{ lineHeight: "0.3", padding: "0.5em 0.5em 0 0.5em" }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Destination
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.destination}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                {data.type} Name
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.name}
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            style={{ lineHeight: "0.3", padding: "0 0.5em " }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                ClientName
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.clientName}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Mobile No
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.clientMobileNo}
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            style={{ lineHeight: "0.3", padding: "0 0.5em 0 0.5em" }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Adults
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.noOfAdults}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Children
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.noOfChildren}
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            style={{ lineHeight: "0.3", padding: "0 0.5em 0.5em 0.5em" }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Check-In
              </Grid>
              <Grid item xs={8} style={cardValue}>
                :{" "}
                {data.checkIn
                  ? format(new Date(data.checkIn), "dd-MM-yyyy")
                  : null}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Check-Out
              </Grid>
              <Grid item xs={8} style={cardValue}>
                :{" "}
                {data.checkOut
                  ? format(new Date(data.checkOut), "dd-MM-yyyy")
                  : null}
              </Grid>
            </Grid>
          </Stack>
        </Stack>
      </Card>

      {data.displayLeads && data.leadPax.length > 0 && (
        <>
          <Card style={{ marginTop: "9px" }}>
            <div
              style={{
                backgroundColor: "#f46d25",
                color: "#fff",
                fontWeight: "500",
                padding: "0.5em 1em",
                fontSize: "18px",
              }}
            >
              Lead Pax Details
            </div>
            {data.leadPax.map((each, index) => {
              return (
                <>
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{
                      lineHeight: "0.3",
                      padding: "0.5em 0.5em 0 0.5em",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={4} style={cardLabel}>
                        Name
                      </Grid>
                      <Grid item xs={8} style={cardValue}>
                        : {each.leadPaxName}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={4} style={cardLabel}>
                        Mobile No
                      </Grid>
                      <Grid item xs={8} style={cardValue}>
                        : {each.leadPaxMobile}
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{
                      lineHeight: "0.3",
                      padding: "0 0.5em 0.5em 0.5em",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={4} style={cardLabel}>
                        Alt No
                      </Grid>
                      <Grid item xs={8} style={cardValue}>
                        : {each.leadPaxAltNo}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={4} style={cardLabel}>
                        Email
                      </Grid>
                      <Grid item xs={8} style={cardValue}>
                        : {each.leadPaxEmail}
                      </Grid>
                    </Grid>
                  </Stack>
                </>
              );
            })}
          </Card>
        </>
      )}

      {data.hotelDetails.length > 0 && (
        <>
          <Card style={{ marginTop: "9px" }}>
            {" "}
            <div
              style={{
                backgroundColor: "#f46d25",
                color: "#fff",
                fontWeight: "500",
                padding: "0.5em 1em",
                fontSize: "18px",
              }}
            >
              Hotel Details
            </div>
            {data.hotelDetails.map((each, index) => {
              return (
                <>
                  <Card>
                    <Stack
                      direction="row"
                      spacing={1}
                      style={{
                        lineHeight: "0.3",
                        padding: "0.5em 0.5em 0 0.5em",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Name
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.hotelName}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Category
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.hotelCategory}
                        </Grid>
                      </Grid>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      style={{
                        lineHeight: "0.3",
                        padding: "0 0.5em 0.5em 0.5em",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Rooms
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.noOfRooms}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Nights
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.noOfNights}
                        </Grid>
                      </Grid>
                    </Stack>
                  </Card>
                </>
              );
            })}
          </Card>
        </>
      )}

      {data.transferDetails.length > 0 && (
        <>
          <Card style={{ marginTop: "9px" }}>
            {" "}
            <div
              style={{
                backgroundColor: "#f46d25",
                color: "#fff",
                fontWeight: "500",
                padding: "0.5em 1em",
                fontSize: "18px",
              }}
            >
              Transfer Details
            </div>
            {data.transferDetails.map((each, index) => {
              return (
                <>
                  <Card>
                    <Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{
                          lineHeight: "0.3",
                          padding: "0.5em 0.5em 0 0.5em",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            From
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.transferFrom}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            To
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.transferTo}
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{
                          lineHeight: "0.3",
                          padding: "0 0.5em 0em 0.5em",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Trip
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.transferTrip}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Type
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.transferType}
                          </Grid>
                        </Grid>
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      style={{
                        lineHeight: "0.3",
                        padding: "0 0.5em 0.5em 0.5em",
                      }}
                    >
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Vehicle
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.transferVehicle ? each.transferVehicle : null}
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}></Grid>
                    </Stack>
                  </Card>
                </>
              );
            })}
          </Card>
        </>
      )}

      {data.activityDetails.length > 0 && (
        <>
          <Card style={{ marginTop: "9px" }}>
            {" "}
            <div
              style={{
                backgroundColor: "#f46d25",
                color: "#fff",
                padding: "0.5em 1em",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              Activity Details
            </div>
            {data.activityDetails.map((each, index) => {
              return (
                <>
                  <Card>
                    <Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ lineHeight: "0.3", padding: "0.5em" }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            City
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.city}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Activity
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.activity}
                          </Grid>
                        </Grid>
                      </Stack>
                    </Stack>
                  </Card>
                </>
              );
            })}
          </Card>
        </>
      )}

      <Card style={{ marginTop: "9px" }}>
        {" "}
        <div
          style={{
            backgroundColor: "#f46d25",
            color: "#fff",
            padding: "0.5em 1em",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Payment Details
        </div>
        {isPackage ? (
          <Stack>
            <Stack
              direction="row"
              spacing={1.5}
              style={{ lineHeight: "0.3", padding: "0.5em 0.5em 0em 0.5em" }}
            >
              <Grid container>
                <Grid item xs={4} style={cardLabel}>
                  Total Amount
                </Grid>
                <Grid item xs={8} style={cardValue}>
                  : {formatter.format(data.totalGrossAmount)}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4} style={cardLabel}>
                  Amount Paid
                </Grid>
                <Grid item xs={8} style={cardValue}>
                  : {formatter.format(data.packagePaymentDetails.paidAmount)}
                </Grid>
              </Grid>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              style={{ lineHeight: "0.3", padding: "0 0.5em 0em 0.5em" }}
            >
              <Grid container>
                <Grid item xs={4} style={cardLabel}>
                  Commission
                </Grid>
                <Grid item xs={8} style={cardValue}>
                  : {formatter.format(data.comission)}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4} style={cardLabel}>
                  Payment Mode
                </Grid>
                <Grid item xs={8} style={cardValue}>
                  : {data.packagePaymentDetails.paymentMode}
                </Grid>
              </Grid>
            </Stack>
            <Stack
              direction="row"
              spacing={1}
              style={{ lineHeight: "0.3", padding: "0 0.5em 0.5em 0.5em" }}
            >
              <Grid container>
                <Grid item xs={4} style={cardLabel}>
                  Reference No.
                </Grid>
                <Grid item xs={8} style={cardValue}>
                  : {data.packagePaymentDetails.paymentRefNo}
                </Grid>
              </Grid>
              <Grid container></Grid>
            </Stack>
          </Stack>
        ) : (
          <Stack
            direction="row"
            spacing={1}
            style={{ lineHeight: "0.3", padding: "0.5em" }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Total Amount
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {formatter.format(data.totalGrossAmount)}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Commission
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {formatter.format(data.comission)}
              </Grid>
            </Grid>
          </Stack>
        )}
      </Card>

      <Card style={{ marginTop: "9px" }}>
        {" "}
        <div
          style={{
            backgroundColor: "#f46d25",
            color: "#fff",
            padding: "0.5em 1em",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Itinerary
        </div>
        <Stack direction="row" spacing={1} style={{ padding: "0.5em 1em" }}>
          {data.itinerary != null && data.itinerary != "" && (
            <Stack
              direction="row"
              spacing={5}
              style={{ padding: "0 0.5em 0.5em 0.5em" }}
            >
              {removeSpaceNewline(data.itinerary)}
            </Stack>
          )}
        </Stack>
      </Card>

      <Card style={{ marginTop: "9px" }}>
        {" "}
        <div
          style={{
            backgroundColor: "#f46d25",
            color: "#fff",
            padding: "0.5em 1em",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Terms And Conditions
        </div>
        <Stack direction="row" spacing={1} style={{ padding: "0.5em 1em" }}>
          {htmlParser(data.packageTeamCondition.teamCondition)}
        </Stack>
      </Card>

      <Card style={{ marginTop: "9px" }}>
        {" "}
        <div
          style={{
            backgroundColor: "#f46d25",
            color: "#fff",
            padding: "0.5em 1em",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Cancellation Policy
        </div>
        <Stack direction="row" spacing={1} style={{ padding: "0.5em 1em" }}>
          {htmlParser(data.packageTeamCondition.cancellationPolicy)}
        </Stack>
      </Card>
    </div>
  );
}
