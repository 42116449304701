import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core"
import RoomFeatures from "../../../Property/Rooms/Amenities/RoomFeatures"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import { useEffect } from "react"
import Api from "../../../../Service/Api"
import { useState } from "react"
import { Form, Formik } from "formik"
import { Grid } from "@mui/material"
import InlineFormikFieldWithCheckSelectAndSwitch from "../../../../components/amenityComponents/inlineCheckBoxWithSelectAndSwitch.component"
import FormikInlineCheckboxAndSwitchOnly from "../../../../components/amenityComponents/inlineCheckBoxAndSwitchOnly.component"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import ScreenLoader from "../../../../components/loadingScreen/screenLoader.component"
import { toast } from "react-toastify"

const initialValues = {
  induction: false,
  inductionStarred: false,
  kitchenette: false,
  kitchenetteStarred: false,
  kitchenetteType: "",
  refrigerator: false,
  refrigeratorStarred: false,
  dishwashing: false,
  dishwashingType: "",
  dishwashingStarred: false,
  glasses: false,
  glassesStarred: false,
  glassesType: [],
  cookingBasics: false,
  cookingBasicsStarred: false,
  cookingBasicsType: [],
  assortedChocolates: false,
  assortedChocolatesType: "",
  assortedChocolatesStarred: false,
  cake: false,
  cakeStarred: false,
  cakeType: "",
  champagne: false,
  champagneType: "",
  champagneStarred: false,
  cookies: false,
  cookiesType: "",
  cookiesStarred: false,
  drinks: false,
  drinksType: "",
  drinksStarred: false,
  fruitBasket: false,
  fruitBasketStarred: false,
  fruitBasketType: "",
  fruitWine: false,
  fruitWineStarred: false,
  fruitWineType: "",
  snackBasket: false,
  snackBasketStarred: false,
  snackBasketType: "",
  sparklingWine: false,
  sparklingWineStarred: false,
  sparklingWineType: "",
  bBQGrill: false,
  bBQGrillType: "",
  bBQGrillStarred: false,
  cookButlerService: false,
  cookButlerServiceStarred: false,
  cookButlerServiceType: "",
  cookiePlatter: false,
  cookiePlatterStarred: false,
  cookiePlatterType: "",
  beer: false,
  beerType: "",
  beerStarred: false,
}

const PropertyRoomKitchenAppliances = ({
  classes,
  handleChangePanel,
  expanded,
}) => {
  const { propertyId, displayName } = useParams()
  const uniqueid = localStorage.getItem("unique_id")
  const createdBy = localStorage.getItem("auth")
  const create = { uniqueId: uniqueid, createdBy: createdBy }
  const [roomKitchenAppliances, setRoomKitchenAppliances] = useState(() => {})
  const [RoomKitchenKitchenette, setRoomKitchenKitchenette] = useState([])
  const [RoomKitchenDishwashing, setRoomKitchenDishwashing] = useState([])
  const [RoomKitchenGlasses, setRoomKitchenGlasses] = useState([])
  const [RoomKitchenCookingBasics, setRoomKitchenCookingBasics] = useState([])
  const [loading, setLoading] = useState(false)
  const commonOption = [
    { value: "free", label: "Free" },
    { value: "paid", label: "Paid" },
  ]
  const history = useHistory()

  const RoomKitchenKitchenetteGet = async () => {
    const kitchenetteData = {
      type: "Room Kitchen Kitchenette",
    }
    await Api.post("productdropdownonly", kitchenetteData).then((response) => {
      setRoomKitchenKitchenette(response.data)
    })
  }

  const RoomKitchenDishwashingGet = async () => {
    const dishData = {
      type: "Room Kitchen Dishwashing",
    }
    await Api.post("productdropdownonly", dishData).then((response) => {
      setRoomKitchenDishwashing(response.data)
    })
  }

  const RoomKitchenGlassesGet = async () => {
    const glassData = {
      type: "Room Kitchen Glasses",
    }
    await Api.post("productdropdownonly", glassData).then((response) => {
      setRoomKitchenGlasses(response.data)
    })
  }

  const RoomKitchenCookingBasicsGet = async () => {
    const cookbasicData = {
      type: "Room Kitchen Cooking Basics",
    }
    await Api.post("productdropdownonly", cookbasicData).then((response) => {
      setRoomKitchenCookingBasics(response.data)
    })
  }

  useEffect(() => {
    if (propertyId && displayName && expanded === "panel6") {
      RoomKitchenKitchenetteGet()
      RoomKitchenDishwashingGet()
      RoomKitchenGlassesGet()
      RoomKitchenCookingBasicsGet()
    }
  }, [expanded])

  const fetchRoomKitchenAmenities = async () => {
    setLoading(true)
    try {
      const normalData = await Api.get(
        `RAmenitiesKitchenFoodvalue/${propertyId}/${displayName}`
      )
      const switchData = await Api.get(
        `RAmenitiesKitchenFoodStarredvalue/${propertyId}/${displayName}`
      )

      console.log(normalData.data)
      console.log(switchData.data)

      setRoomKitchenAppliances({ ...normalData.data, ...switchData.data })
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (propertyId && displayName && expanded === "panel6") {
      fetchRoomKitchenAmenities()
    }
  }, [expanded])

  const kitchenAppliancesWithSelect = [
    {
      name: "kitchenette",
      label: "Kitchenette",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "kitchenetteType",
      switchName: "kitchenetteStarred",
      dropdDownOptions: RoomKitchenKitchenette.map((option) => ({
        value: option,
        label: option,
      })),
    },
    {
      name: "dishwashing",
      label: "Dish Washing",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "dishwashingType",
      switchName: "dishwashingStarred",
      dropdDownOptions: RoomKitchenDishwashing.map((option) => ({
        value: option,
        label: option,
      })),
    },
    {
      name: "glasses",
      label: "Glasses",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "glassesType",
      switchName: "glassesStarred",
      dropdDownOptions: RoomKitchenGlasses,
    },

    {
      name: "cookingBasics",
      label: "Cooking Basics",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "cookingBasicsType",
      switchName: "cookingBasicsStarred",
      dropdDownOptions: RoomKitchenCookingBasics,
    },

    {
      name: "assortedChocolates",
      label: "Assorted Chocolate",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "assortedChocolatesType",
      switchName: "assortedChocolatesStarred",
      dropdDownOptions: commonOption,
    },

    {
      name: "cake",
      label: "Cake",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "cakeType",
      switchName: "cakeStarred",
      dropdDownOptions: commonOption,
    },
    {
      name: "champagne",
      label: "Champagne",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "champagneType",
      switchName: "champagneStarred",
      dropdDownOptions: commonOption,
    },
    {
      name: "cookies",
      label: "Cookies",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "cookiesType",
      switchName: "cookiesStarred",
      dropdDownOptions: commonOption,
    },
    {
      name: "drinks",
      label: "Drinks",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "drinksType",
      switchName: "drinksStarred",
      dropdDownOptions: commonOption,
    },
    {
      name: "fruitBasket",
      label: "Fruit Basket",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "fruitBasketType",
      switchName: "fruitBasketStarred",
      dropdDownOptions: commonOption,
    },
    {
      name: "fruitWine",
      label: "Fruit Wine",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "fruitWineType",
      switchName: "fruitWineStarred",
      dropdDownOptions: commonOption,
    },

    {
      name: "snackBasket",
      label: "Snack Basket",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "snackBasketType",
      switchName: "snackBasketStarred",
      dropdDownOptions: commonOption,
    },
    {
      name: "sparklingWine",
      label: "Sparkling Wine",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "sparklingWineType",
      switchName: "sparklingWineStarred",
      dropdDownOptions: commonOption,
    },

    {
      name: "bBQGrill",
      label: "BBQ Grill",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "bBQGrillType",
      switchName: "bBQGrillStarred",
      dropdDownOptions: commonOption,
    },

    {
      name: "cookButlerService",
      label: "Cook Butler Service",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "cookButlerServiceType",
      switchName: "cookButlerServiceStarred",
      dropdDownOptions: commonOption,
    },
    {
      name: "cookiePlatter",
      label: "Cookie Platter",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "cookiePlatterType",
      switchName: "cookiePlatterStarred",
      dropdDownOptions: commonOption,
    },
    {
      name: "beer",
      label: "Beer",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "beerType",
      switchName: "beerStarred",
      dropdDownOptions: commonOption,
    },
  ]

  const kitchenAppliancesSwitchOnly = [
    {
      name: "induction",
      label: "Induction",
      switchName: "inductionStarred",
    },

    {
      name: "refrigerator",
      label: "Refrigerator",
      switchName: "refrigeratorStarred",
    },
  ]

  function onSubmit(fields, { setStatus }) {
    const id = roomKitchenAppliances.id
    const cook = []
    const glass = []

    if (fields.induction == false) {
      fields.inductionStarred = false
    }

    if (fields.kitchenette == false) {
      fields.kitchenetteStarred = false
      fields.kitchenetteType = ""
    }
    if (fields.refrigerator == false) {
      fields.refrigeratorStarred = false
    }

    if (fields.dishwashing == false) {
      fields.dishwashingStarred = false
      fields.dishwashingType = ""
    }
    if (fields.glasses == false) {
      fields.glassesStarred = false
      fields.glassesType = []
    }
    if (fields.cookingBasics == false) {
      fields.cookingBasicsStarred = false
      fields.cookingBasicsType = []
    }
    if (fields.assortedChocolates == false) {
      fields.assortedChocolatesType = ""
      fields.assortedChocolatesStarred = false
    }
    if (fields.cake === false) {
      fields.cakeStarred = false
      fields.cakeType = ""
    }

    if (fields.champagne == false) {
      fields.champagneType = ""
      fields.champagneStarred = false
    }
    if (fields.cookies == false) {
      fields.cookiesType = ""
      fields.cookiesStarred = false
    }

    if (fields.drinks == false) {
      fields.drinksType = ""
      fields.drinksStarred = false
    }

    if (fields.fruitBasket == false) {
      fields.fruitBasketStarred = false
      fields.fruitBasketType = ""
    }

    if (fields.fruitWine == false) {
      fields.fruitWineStarred = false
      fields.fruitWineType = ""
    }

    if (fields.snackBasket == false) {
      fields.snackBasketStarred = false
      fields.snackBasketType = ""
    }
    if (fields.sparklingWine == false) {
      fields.sparklingWineStarred = false
      fields.sparklingWineType = ""
    }
    if (fields.bBQGrill == false) {
      fields.bBQGrillType = ""
      fields.bBQGrillStarred = false
    }
    if (fields.cookButlerService == false) {
      fields.cookButlerServiceStarred = false
      fields.cookButlerServiceType = ""
    }
    if (fields.cookiePlatter == false) {
      fields.cookiePlatterStarred = false
      fields.cookiePlatterType = ""
    }
    if (fields.beer == false) {
      fields.beerType = ""
      fields.beerStarred = false
    }
    setStatus()
    if (id) {
      updateKitchen(fields)
    } else {
      createKitchen(fields)
    }
  }

  function createStarred(fields) {
    fields.propertyId = propertyId
    fields.displayName = displayName
    const newData = { ...fields, ...create }
    Api.post("RAmenitiesKitchenFoodStarred", newData)
  }

  function updateStarred(fields) {
    fields.updateBy = createdBy
    Api.put("RAmenitiesKitchenFoodStarredupdate", fields)
  }

  function createKitchen(fields) {
    fields.propertyId = propertyId
    fields.displayName = displayName
    const newData = { ...fields, ...create }
    setLoading(true)
    Api.post("RAmenitiesKitchenFood", newData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      createStarred(fields)
      toast.success("Successfully Created")
      setLoading(false)
      window.open(
        `/konnect/property/${res.data.propertyId}/propertyRoomList`,
        "_self"
      )
    })
  }
  function updateKitchen(fields) {
    fields.updateBy = createdBy
    setLoading(true)
    Api.put("RAmenitiesKitchenFoodupdate", fields, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      updateStarred(fields)
      toast.success("Successfully Updated")
      setLoading(false)
      window.open(
        `/konnect/property/${res.data.propertyId}/propertyRoomList`,
        "_self"
      )
    })
  }

  return (
    <Formik
      initialValues={roomKitchenAppliances || initialValues}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) =>
        loading ? (
          <ScreenLoader open={loading} />
        ) : (
          <Form autoComplete="off">
            <Accordion
              square
              expanded={expanded === "panel6"}
              onChange={handleChangePanel("panel6")}
              className={classes.accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#f46d25",
                      background: "white",
                      borderRadius: "5px",
                    }}
                  />
                }
                className={classes.accordionSummaryStyles}
              >
                Kitchen Appliances
              </AccordionSummary>
              <AccordionDetails className={classes.outerSectionStyles}>
                <Grid container spacing={1}>
                  {kitchenAppliancesWithSelect.map((amenity) => (
                    <InlineFormikFieldWithCheckSelectAndSwitch
                      amenity={amenity}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ))}

                  {kitchenAppliancesSwitchOnly.map((amenity) => (
                    <FormikInlineCheckboxAndSwitchOnly
                      amenity={amenity}
                      values={values}
                    />
                  ))}
                </Grid>
              </AccordionDetails>
              <center>
                <Button type="submit" size="small">
                  Save and Next
                </Button>
              </center>
            </Accordion>
          </Form>
        )
      }
    </Formik>
  )
}

export default PropertyRoomKitchenAppliances
