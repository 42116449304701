const OtherLoginInvoiceCard = ({ src, title }) => {
  return (
    <figure style={{ marginLeft: "0" }}>
      <img src={src} style={{ width: "154px", height: "45px" }} />
      <figcaption
        style={{
          fontSize: "18px",
          color: "#f46d25",
          fontWeight: "bolder",
          marginTop: "0",
        }}
      >
        {title}
      </figcaption>
    </figure>
  );
};
export default OtherLoginInvoiceCard;
