import { ReactComponent as TownoLogo } from "../../assets/logo/towno_logo_new.svg";

const TownoGstInfoCard = () => (
  <div style={{ marginLeft: "-37px" }}>
    <figure>
      <TownoLogo />
      <figcaption
        style={{
          fontSize: "18px",
          color: "#f46d25",
          fontWeight: "bolder",
        }}
      >
        Intellego Tech Solution Pvt. Ltd.
      </figcaption>
    </figure>
  </div>
);

export default TownoGstInfoCard;
