import React, { useState, useEffect } from "react"
import { Typography, Grid, Dialog, Stack, Card } from "@mui/material"
import { Link } from "react-router-dom"
import Api from "../../Service/Api"
import { format, differenceInDays } from "date-fns"
import { useParams } from "react-router-dom"
import { twnButtonStyles } from "../../utils/townoStyle"
import { parse } from "date-fns/esm"
import { formatter } from "../../utils/formatNumber"
import ViewPackageMobileUI from "./ViewPackageMobileUI"

const cardLabel = { padding: "0.5em 1em", color: "#111", fontWeight: "500" }
const cardValue = { padding: "0.5em 1em", color: "#111" }

function removeSpaceNewline(text) {
  if (text) {
    let a = text.split("\n")
    return a.join(" | ").trim()
  } else {
    return ""
  }
}

const HotelCategory = {
  "1Star": "1 Star",
  "2Star": "2 Star",
  "3Star": "3 Star",
  "4Star": "4 Star",
  "5Star": "5 Star",
}
const TransferTripOptions = [
  { label: "Pickup", value: "Pickup" },
  { label: "Drop", value: "Drop" },
  { label: "Round", value: "Round" },
]
const TransferTypeOptions = [
  { label: "Private", value: "Private" },
  { label: "SIC", value: "SIC" },
]
const TransferVehicleOptions = [
  { label: "Hatchback", value: "Hatchback" },
  { label: "SUV", value: "SUV" },
  { label: "Sedan", value: "Sedan" },
  { label: "12 Seater", value: "12 Seater" },
  { label: "24 Seater", value: "24 Seater" },
  { label: "48 Seater", value: "48 Seater" },
]

const PackageOrQuotation = (isPackage) => {
  return isPackage ? "Package" : "Quotation"
}

export default function ViewPackage(props) {
  var uniqueid = localStorage.getItem("unique_id")
  // var [data, setData] = useState({ "id": "", "type": "", "name": "", "clientName": "", "clientMobileNo": "", "noOfAdults": "", "createdAt": "", "hotelDetails": [], 'leadPax': [], 'transferDetails': [], 'activityDetails': [] });
  var [data, setData] = useState({
    hotelDetails: [],
    leadPax: [],
    transferDetails: [],
    activityDetails: [],
  })

  let { pkgOrQtn, id } = useParams()
  let isPackage = pkgOrQtn === "package" ? true : false
  let validLeadPax =
    data.leadPax != null
      ? data.leadPax.filter((e) => {
          return e.leadPaxName != ""
        })
      : 0
  let validHotelInput = data.hotelDetails.filter((e) => {
    return e.hotelName != ""
  })
  let validTransferInput = data.transferDetails.filter((e) => {
    return e.transferFrom != ""
  })
  let validActvityInput = data.activityDetails.filter((e) => {
    return e.city != ""
  })
  const [totalAmountPaid, setTotalAmountPaid] = useState(0)

  useEffect(() => {
    Api.get(
      `/getpackageorquotationid/${uniqueid}/${PackageOrQuotation(
        isPackage
      )}/${id}`
    ).then((res) => {
      console.log("all data", res.data)
      setData(res.data)
      setTotalAmountPaid(
        res.data.packagePaymentDetails.reduce((target, each) => {
          return target + each.paidAmount / 1
        }, 0)
      )
    })
  }, [])

  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [width])

  return (
    <div
      style={{
        ...twnButtonStyles.allPages,
        paddingTop: width <= 768 ? "58px" : "75px",
      }}
    >
      {width <= 768 && data ? (
        <ViewPackageMobileUI />
      ) : (
        <>
          <Typography
            variant="h5"
            component="h5"
            style={twnButtonStyles.xlFonts}
          >
            #{isPackage ? data.packageId : data.quotationId}{" "}
            {data.amountRefunded > 0 && <>is Cancelled</>}
          </Typography>

          {/* basic details  */}
          <Card style={{ marginTop: "9px", boxShadow: "none" }}>
            <Typography
              style={{
                ...twnButtonStyles.headerStyle,
                padding: "10px 0 0 5px",
                height: "32px",
              }}
            >
              Basic Details
            </Typography>
            <Stack>
              <Stack
                direction="row"
                spacing={1}
                style={{ lineHeight: "0.3", padding: "0.5em 0.5em 0 0.5em" }}
              >
                <Grid container>
                  <Grid item xs={4} style={cardLabel}>
                    {PackageOrQuotation(isPackage)} Name
                  </Grid>
                  <Grid item xs={8} style={cardValue}>
                    : {data.name}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} style={cardLabel}>
                    Destination Name
                  </Grid>
                  <Grid item xs={8} style={cardValue}>
                    : {data.destination}
                  </Grid>
                </Grid>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                style={{ lineHeight: "0.3", padding: "0 0.5em " }}
              >
                <Grid container>
                  <Grid item xs={4} style={cardLabel}>
                    Client Name
                  </Grid>
                  <Grid item xs={8} style={cardValue}>
                    : {data.clientName}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} style={cardLabel}>
                    Phone No
                  </Grid>
                  <Grid item xs={8} style={cardValue}>
                    : {data.clientMobileNo}
                  </Grid>
                </Grid>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                style={{ lineHeight: "0.3", padding: "0 0.5em " }}
              >
                <Grid container>
                  <Grid item xs={4} style={cardLabel}>
                    Duration
                  </Grid>
                  <Grid item xs={8} style={cardValue}>
                    :{" "}
                    {differenceInDays(
                      parse(data.checkOut, "dd-MM-yyyy", new Date()),
                      parse(data.checkIn, "dd-MM-yyyy", new Date())
                    )}{" "}
                    days
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} style={cardLabel}>
                    Booking Date
                  </Grid>
                  <Grid item xs={8} style={cardValue}>
                    :{" "}
                    {data.createdAt
                      ? format(
                          parse(
                            data.createdAt,
                            "yyyy-MM-dd HH:mm:ss",
                            new Date()
                          ),
                          "dd MMM yy"
                        )
                      : null}
                  </Grid>
                </Grid>
              </Stack>
              <Stack
                Stack
                direction="row"
                spacing={1}
                style={{ lineHeight: "0.3", padding: "0 0.5em " }}
              >
                <Grid container>
                  <Grid item xs={4} style={cardLabel}>
                    Check-In
                  </Grid>
                  <Grid item xs={8} style={cardValue}>
                    :{" "}
                    {data.checkIn
                      ? format(
                          parse(data.checkIn, "dd-MM-yyyy", new Date()),
                          "dd MMM yy"
                        )
                      : null}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} style={cardLabel}>
                    Check-Out
                  </Grid>
                  <Grid item xs={8} style={cardValue}>
                    :{" "}
                    {data.checkOut
                      ? format(
                          parse(data.checkOut, "dd-MM-yyyy", new Date()),
                          "dd MMM yy"
                        )
                      : null}
                  </Grid>
                </Grid>
              </Stack>
              <Stack
                Stack
                direction="row"
                spacing={1}
                style={{ lineHeight: "0.3", padding: "0 0.5em 0.5em 0.5em" }}
              >
                <Grid container>
                  <Grid item xs={4} style={cardLabel}>
                    Adults
                  </Grid>
                  <Grid item xs={8} style={cardValue}>
                    : {data.noOfAdults}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} style={cardLabel}>
                    Children
                  </Grid>
                  <Grid item xs={8} style={cardValue}>
                    : {data.noOfChildren}
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          </Card>

          {/* lead details  */}
          {validLeadPax.length > 0 && (
            <>
              <Card style={{ marginTop: "9px", boxShadow: "none" }}>
                <Typography
                  style={{
                    ...twnButtonStyles.headerStyle,
                    padding: "10px 0 0 5px",
                    height: "32px",
                  }}
                >
                  Lead Pax
                </Typography>
                {validLeadPax.map((each, index) => {
                  return (
                    <>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{
                          lineHeight: "0.3",
                          padding: "0.5em 0.5em 0 0.5em",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Name
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.leadPaxName}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Mobile No
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.leadPaxMobile}
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{
                          lineHeight: "0.3",
                          padding: "0 0.5em 0.5em 0.5em",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Alt No
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.leadPaxAltNo}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Email
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.leadPaxEmail}
                          </Grid>
                        </Grid>
                      </Stack>
                    </>
                  )
                })}
              </Card>
            </>
          )}

          {/* hotel details  */}
          {validHotelInput.length > 0 && (
            <>
              <Card style={{ marginTop: "9px", boxShadow: "none" }}>
                <Typography
                  style={{
                    ...twnButtonStyles.headerStyle,
                    padding: "10px 0 0 5px",
                    height: "32px",
                  }}
                >
                  Hotel Details
                </Typography>
                {validHotelInput.map((each, index) => {
                  return (
                    <>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{
                          lineHeight: "0.3",
                          padding: "0.5em 0.5em 0 0.5em",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Name
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.hotelName}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Category
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.hotelCategory}
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{
                          lineHeight: "0.3",
                          padding: "0 0.5em 0.5em 0.5em",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Rooms
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.noOfRooms}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Nights
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.noOfNights}
                          </Grid>
                        </Grid>
                      </Stack>
                    </>
                  )
                })}
              </Card>
            </>
          )}

          {/* transfer details  */}
          {validTransferInput.length > 0 && (
            <>
              <Card style={{ marginTop: "9px", boxShadow: "none" }}>
                <Typography
                  style={{
                    ...twnButtonStyles.headerStyle,
                    padding: "10px 0 0 5px",
                    height: "32px",
                  }}
                >
                  Tranfer Details
                </Typography>
                {validTransferInput.map((each, index) => {
                  return (
                    <>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{
                          lineHeight: "0.3",
                          padding: "0.5em 0.5em 0 0.5em",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            From
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.transferFrom}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            To
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.transferTo}
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{
                          lineHeight: "0.3",
                          padding: "0 0.5em 0em 0.5em",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Trip
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.transferTrip}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Type
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.transferType}
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{
                          lineHeight: "0.3",
                          padding: "0 0.5em 0.5em 0.5em",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Vehicle
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.transferVehicle}
                          </Grid>
                        </Grid>
                        <Grid container></Grid>
                      </Stack>
                    </>
                  )
                })}
              </Card>
            </>
          )}

          {/* activity details  */}
          {validActvityInput.length > 0 && (
            <>
              <Card style={{ marginTop: "9px", boxShadow: "none" }}>
                <Typography
                  style={{
                    ...twnButtonStyles.headerStyle,
                    padding: "10px 0 0 5px",
                    height: "32px",
                  }}
                >
                  Activity Details
                </Typography>
                {validActvityInput.map((each, index) => {
                  return (
                    <>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ lineHeight: "0.3", padding: "0.5em " }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            City
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.city}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Activity
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.activity}
                          </Grid>
                        </Grid>
                      </Stack>
                    </>
                  )
                })}
              </Card>
            </>
          )}

          {/* payment details  */}
          <Card style={{ marginTop: "9px", boxShadow: "none" }}>
            <Typography
              style={{
                ...twnButtonStyles.headerStyle,
                padding: "10px 0 0 5px",
                height: "32px",
              }}
            >
              Payment Details
            </Typography>
            <Stack>
              <Stack
                direction="row"
                spacing={1.5}
                style={{ lineHeight: "0.3", padding: "0.5em" }}
              >
                <Grid container>
                  <Grid item xs={5} style={cardLabel}>
                    Total Gross Amount
                  </Grid>
                  <Grid item xs={7} style={cardValue}>
                    : {formatter.format(data.totalGrossAmount)}
                  </Grid>
                </Grid>
                <br />
                {isPackage ? (
                  <Grid container>
                    <Grid item xs={5} style={cardLabel}>
                      Total Amount Paid
                    </Grid>
                    <Grid item xs={7} style={cardValue}>
                      : {formatter.format(totalAmountPaid)}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container></Grid>
                )}
                <br />
                <Grid container>
                  <Grid item xs={5} style={cardLabel}>
                    Commission
                  </Grid>
                  <Grid item xs={7} style={cardValue}>
                    : {formatter.format(data.comission)}
                  </Grid>
                </Grid>
                {/* <Grid container item xs={12}>
                            <></>
                        </Grid> */}
              </Stack>
              {/* <Stack spacing={1.5} style={{ lineHeight: '0.3', padding: '0.5em' }}> */}
              {isPackage && (
                <Grid
                  container
                  spacing={0}
                  style={{ border: "2px solid #aaa", padding: "1% 0 1% 2.5%" }}
                >
                  <Grid item xs={3}>
                    <span
                      style={{
                        color: "#f46d25",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Date
                    </span>
                  </Grid>
                  <Grid item xs={3}>
                    <span
                      style={{
                        color: "#f46d25",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Amount Paid
                    </span>
                  </Grid>
                  <Grid item xs={3}>
                    <span
                      style={{
                        color: "#f46d25",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Payment Mode
                    </span>
                  </Grid>
                  <Grid item xs={3}>
                    <span
                      style={{
                        color: "#f46d25",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Reference Number
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <br />
                  </Grid>
                  {data.packagePaymentDetails &&
                    data.packagePaymentDetails.map((each, index) => {
                      return (
                        <>
                          <Grid
                            item
                            xs={3}
                            style={{ color: "#111", fontSize: "14px" }}
                          >
                            {each.createdAt
                              ? format(
                                  parse(
                                    each.createdAt,
                                    "yyyy-MM-dd HH:mm:ss",
                                    new Date()
                                  ),
                                  "dd MMM yy, hh:mm a"
                                )
                              : null}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            style={{ color: "#111", fontSize: "14px" }}
                          >
                            {formatter.format(each.paidAmount)}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            style={{ color: "#111", fontSize: "14px" }}
                          >
                            {each.paymentMode}
                          </Grid>
                          <Grid
                            item
                            xs={3}
                            style={{ color: "#111", fontSize: "14px" }}
                          >
                            {each.paymentRefNo}
                          </Grid>
                        </>
                      )
                    })}
                </Grid>
              )}
              {/* </Stack> */}
            </Stack>
          </Card>

          {/* new payment */}
          {data.amountRefunded > 0 ? (
            <Card style={{ marginTop: "9px", boxShadow: "none" }}>
              <Typography
                style={{
                  ...twnButtonStyles.headerStyle,
                  padding: "10px 0 0 5px",
                  height: "32px",
                }}
              >
                Updated Payment Details
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                style={{ lineHeight: "1", padding: "0.5em " }}
              >
                <Grid container>
                  <Grid item xs={4} style={cardLabel}>
                    Total Amount Paid
                  </Grid>
                  <Grid item xs={8} style={cardValue}>
                    : {formatter.format(totalAmountPaid)}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} style={cardLabel}>
                    Total Amount Refunded
                  </Grid>
                  <Grid item xs={8} style={cardValue}>
                    : {formatter.format(data.amountRefunded)}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4} style={cardLabel}>
                    Total Amount After Refund
                  </Grid>
                  <Grid item xs={8} style={cardValue}>
                    : {formatter.format(totalAmountPaid - data.amountRefunded)}
                  </Grid>
                </Grid>
              </Stack>
            </Card>
          ) : null}
          {/* new payment */}

          {/* itinerary */}
          <Card Card style={{ marginTop: "9px", boxShadow: "none" }}>
            <Typography
              style={{
                ...twnButtonStyles.headerStyle,
                padding: "10px 0 0 5px",
                height: "32px",
              }}
            >
              Itinerary
            </Typography>
            {data.itinerary != null && data.itinerary != "" && (
              <Stack
                direction="row"
                spacing={10}
                style={{ lineHeight: "1", padding: " 0.5em " }}
              >
                {data.itinerary}
              </Stack>
            )}
          </Card>
          {/* itinerary */}

          {/* refund details */}
          {data.amountRefunded > 0 && (
            <Card style={{ marginTop: "9px", boxShadow: "none" }}>
              <Typography
                style={{
                  ...twnButtonStyles.headerStyle,
                  padding: "10px 0 0 5px",
                  height: "32px",
                }}
              >
                Refund Details
              </Typography>
              <Stack
                direction="row"
                spacing={1.5}
                style={{ lineHeight: "0.3", padding: "0.5em" }}
              >
                <Grid container>
                  <Grid item xs={5} style={cardLabel}>
                    Amount Refunded
                  </Grid>
                  <Grid item xs={7} style={cardValue}>
                    : {formatter.format(data.amountRefunded)}
                  </Grid>
                </Grid>
                <br />
                <Grid container>
                  <Grid item xs={5} style={cardLabel}>
                    Amount Retained
                  </Grid>
                  <Grid item xs={7} style={cardValue}>
                    : {formatter.format(data.amountRetained)}
                  </Grid>
                </Grid>
                <br />
                <Grid container>
                  <Grid item xs={5} style={cardLabel}>
                    Revised Commission
                  </Grid>
                  <Grid item xs={7} style={cardValue}>
                    : {formatter.format(data.revisedCommission)}
                  </Grid>
                </Grid>
              </Stack>
            </Card>
          )}
          <br />

          <Stack justifyContent="center" alignItems="center">
            <Link
              to={{ pathname: `/konnect/packageOrQuotation/${pkgOrQtn}` }}
              style={twnButtonStyles.linkOrangeBtn}
            >
              Back to {PackageOrQuotation(isPackage)}
            </Link>
          </Stack>
          <br />
        </>
      )}
    </div>
  )
}
