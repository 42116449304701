import { Button } from "@material-ui/core"
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { format } from "date-fns"
import React, { useState } from "react"
import XLSX from "sheetjs-style"
import { currencyFormatter } from "../../B2C_Konnect/utility/currencyFormatter"
import Loader from "../../components/Loader/Loader"
import { formatCurrency } from "../../utils/formatNumber"

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  background: "#F46D25",
}))
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: "#F46D25",
  color: "#fff",
  fontSize: "12px",
  padding: "5px",
  lineHeight: "1rem",
  minWidth: "100px",
}))

const StyledTableCellInside = styled(TableCell)(({ theme }) => ({
  padding: "5px",
  fontSize: "12px",
  lineHeight: "15px",
}))

const TABLE_HEAD_CONST = [
  {
    id: "bookingId",
    label: "Booking Id",
  },
  {
    id: "Booking Date",
    label: "Booking Date",
  },
  { id: "checkinDate", label: "Check-In Date" },
  {
    id: "Booking Amount",
    label: "Revenue Generated for the Hotel",
  },
  {
    id: "noOfRoomNights",
    label: "Number of Room Nights",
  },
]

export default function HotelProductivityReport(props) {
  const {
    reportData: { list, total },
    loading,
    additionalParams,
  } = props
  const [finalReport, setFinalReport] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  //!

  const [tableHead, setTableHead] = useState(TABLE_HEAD_CONST)

  //!

  // useEffect(() => {
  //   setFinalReport(props.reportData)
  // }, [props])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const downloadInExcel = () => {
    const summaryData = [total].map((t) => ({
      ...t,
      revenue: currencyFormatter(t.revenue),
    }))
    const hotelProductivityData = list?.map((row) => {
      return {
        ...row,
        bookingDate: format(new Date(row?.bookingDate), "dd-MM-yyyy"),
        checkInDate: format(new Date(row?.checkInDate), "dd-MM-yyyy"),
        revenue: currencyFormatter(row.revenue),
      }
    })

    const title = [
      {
        A: "Report Type",
        B: "Hotel Productivity Report",
      },
      { A: "Hotel Name", B: additionalParams["Hotel Name"] },
      {
        A: `${additionalParams.queryBy}`,
        B: additionalParams[additionalParams.queryBy],
      },
    ]

    let summaryTable = [
      {
        A: "Number of Bookings",
        B: "Total Revenue Generated (₹)",
        C: "Total Room Nights Generated",
      },
    ]

    let hotelProductivityTable = [
      {
        A: "Booking Id",
        B: "Booking Date",
        C: "Check-In Date",
        D: "Revenue Generated for Hotel (₹)",
        E: "Number of Room Nights",
      },
    ]

    summaryData.forEach((r) => {
      summaryTable = [
        ...summaryTable,
        { A: r.bookingcount, B: r.revenue, C: r.nights },
      ]
    })

    hotelProductivityData.forEach((r) => {
      hotelProductivityTable = [
        ...hotelProductivityTable,
        {
          A: r.bookingID,
          B: r.bookingDate,
          C: r.checkInDate,
          D: r.revenue,
          E: r.nights,
        },
      ]
    })

    const excelSheetData = title
      .concat([" "])
      .concat([" "])
      .concat([{ A: "Summary" }])
      .concat(summaryTable)
      .concat([" "])
      .concat([" "])
      .concat({ A: "Bookings Details" })
      .concat(hotelProductivityTable)

    const workBook = XLSX.utils.book_new()

    const sheet = XLSX.utils.json_to_sheet(excelSheetData, {
      skipHeader: true,
    })

    sheet["!cols"] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 6 },
      { wch: 30 },
      { wch: 30 },
    ]

    sheet["A1"].s = {
      font: {
        bold: true,
      },
    }

    sheet["A2"].s = {
      // set the style for target cell
      font: {
        bold: true,
      },
    }

    sheet["A3"].s = {
      // set the style for target cell
      font: {
        bold: true,
      },
    }

    sheet["A6"].s = {
      // set the style for target cell
      font: {
        bold: true,
        sz: 24,
      },
    }
    sheet["A11"].s = {
      // set the style for target cell
      font: {
        bold: true,
        sz: 24,
      },
    }

    const headerCells = ["A7", "B7", "D7", "A12", "B12", "D12", "E12", "F12"]

    headerCells.forEach((c) => {
      sheet[c].s = {
        // set the style for target cell
        font: {
          bold: true,
          sz: 12,
        },
      }
    })

    const columns = (length, rowNo, type = "summary") => {
      return type != "summary"
        ? [
            Array.from({ length }, (_, i) => `A${rowNo + i}`),
            Array.from({ length }, (_, i) => `B${rowNo + i}`),
            Array.from({ length }, (_, i) => `D${rowNo + i}`),
            Array.from({ length }, (_, i) => `E${rowNo + i}`),
            Array.from({ length }, (_, i) => `F${rowNo + i}`),
          ]
        : [
            Array.from({ length }, (_, i) => `A${rowNo + i}`),
            Array.from({ length }, (_, i) => `B${rowNo + i}`),
            Array.from({ length }, (_, i) => `D${rowNo + i}`),
          ]
    }

    console.log(columns(summaryData.length, 8))

    columns(summaryData.length, 8).forEach((c) => {
      c.forEach((r) => {
        sheet[r].s = {
          alignment: {
            horizontal: "left",
          },
        }
      })
    })

    columns(hotelProductivityData.length, 13, "revenue").forEach((c) => {
      c.forEach((r) => {
        sheet[r].s = {
          alignment: {
            horizontal: "left",
          },
        }
      })
    })

    XLSX.utils.book_append_sheet(workBook, sheet, "Hotel_Productivity_Report")
    const wopts = { bookType: "xlsx", type: "binary" }

    XLSX.write(workBook, wopts)
    XLSX.writeFile(
      workBook,
      `Hotel_Productivity_Report_${format(new Date(), "d-MM-yyyy")}_${
        additionalParams.queryBy
      }.xlsx`
    )
  }

  if (loading) return <Loader />

  return (
    <div>
      <Grid container marginBottom={10}>
        <Grid item container md={12}>
          <Grid item md={10}></Grid>
          <Grid item container md={2} spacing={2}>
            <Grid item md={12} style={{ textAlign: "right" }}>
              <Button style={{ width: "100%" }} onClick={downloadInExcel}>
                Download Report
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container spacing={2} marginBottom={"10px"}>
          <Grid item md={10}>
            <b>Summary</b>
          </Grid>
        </Grid>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <StyledTableRow>
                  {[
                    "Number of Bookings",
                    "Total Revenue Generated",
                    "Total Room Nights Generated",
                  ].map((column) => (
                    <StyledTableCell align={"center"}>{column}</StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <StyledTableCellInside
                    style={{
                      textTransform: "capitalize",
                      color: "#f46d25",
                      fontWeight: "bold",
                    }}
                    align="center"
                  >
                    {total?.bookingcount}
                  </StyledTableCellInside>
                  <StyledTableCellInside align="center">
                    {currencyFormatter(total?.revenue)}
                  </StyledTableCellInside>
                  <StyledTableCellInside align="center">
                    {total?.nights}
                  </StyledTableCellInside>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>{" "}
      </Grid>

      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item md={7}>
          <b>Hotel Productivity Report</b>
        </Grid>

        {/* <Grid item md={2} style={{ textAlign: "right", marginLeft: "24%" }}>
          <Button onClick={downloadExcel}>Download As Excel</Button>
        </Grid> */}
      </Grid>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <StyledTableRow>
                {tableHead.map((column) => (
                  <StyledTableCell key={column.id} align={"center"}>
                    {column.label}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {list
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow key={row.id}>
                      <StyledTableCellInside
                        style={{
                          textTransform: "capitalize",
                          color: "#f46d25",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        {row.bookingID}
                      </StyledTableCellInside>
                      <StyledTableCellInside align="center">
                        {format(new Date(row.bookingDate), "do, MMM yyyy")}
                      </StyledTableCellInside>

                      <StyledTableCellInside align="center">
                        {format(new Date(row.checkInDate), "do, MMM yyyy")}
                      </StyledTableCellInside>
                      <StyledTableCellInside align="center">
                        {formatCurrency(row.revenue)}
                      </StyledTableCellInside>
                      <StyledTableCellInside align="center">
                        {row.nights}
                      </StyledTableCellInside>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={list?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
