import React, { useState, useEffect, useCallback } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Grid,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { Button } from "@material-ui/core"
import XLSX from "xlsx"
import Select from "react-select"
import _ from "lodash"
import { currencyFormatter } from "../../B2C_Konnect/utility/currencyFormatter"
import moment from "moment"
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  background: "#F46D25",
}))
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: "#F46D25",
  color: "#fff",
  fontSize: "12px",
  padding: "5px",
  lineHeight: "1rem",
  minWidth: "100px",
}))

const StyledTableCellInside = styled(TableCell)(({ theme }) => ({
  padding: "5px",
  fontSize: "12px",
  lineHeight: "15px",
}))

const TABLE_HEAD_CONST = [
  {
    id: "Name",
    label: "Name",
  },
  {
    id: "Date1",
    label: "Date1",
  },
  {
    id:"revenue1",
    label:"Revenue1"
  },
  {
    id:"date2",
    label:"Date2"
  },
  {
    id:"revenue2",
    label:"Revenue2"
  },
  {
    id:"delta",
    label:"Delta"
  },
  {
    id:"%",
    label:"%"
  },
]

const StaffRevenueCompareReport = (props) => {
  const [finalReport, setFinalReport] = useState(props.reportData)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [tableHead, setTableHead] = useState(TABLE_HEAD_CONST)
  useEffect(() => {
    setFinalReport(props.reportData)
    console.log(Object.values(props.reportData))
  }, [props])
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const formattedDate = (date) => {
    if (date.includes(" to ")) {
      const [startDate, endDate] = date.split(" to ");
      const formattedStartDate = moment(startDate).format("Do MMMM, YYYY");
      const formattedEndDate = moment(endDate).format("Do MMMM, YYYY");
      return `${formattedStartDate} - ${formattedEndDate}`;
    }
    else if(moment(date, "MM-YYYY", true).isValid()){
      const parsedDate = moment(date, "MM-YYYY");
      const formattedDate = parsedDate.format("MMMM YYYY");
      return formattedDate;
    }
    else {
      const parsedDate = moment(date, "YYYY-MM-DD", true); 
      if (parsedDate.isValid()) {
        const formattedDate = parsedDate.format("DD-MM-YYYY");
        return formattedDate;
      } else {
        return date; 
      }
    }
  };

  

  const downloadExcel = () => {
    const newData = finalReport.map((row) => {
      return {
        ...row,
        // dates: formattedDate(props?.agentRevenueDuartion, row?.dates),
      }
    })
    //console.log(newData);
    const workSheet = XLSX.utils.json_to_sheet(newData)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, "StaffRevenueComparisionReport")
    // const buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
    XLSX.writeFile(workBook, "StaffRevenueComparisionReport.xlsx")
  }

  // const parsedDate = parse("01-10-2023", "dd-M-yyyy", new Date())
  // const formattedDate = format(parsedDate, "yyyy")
  // console.log(formattedDate)

  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item md={7}>
          <b>Staff Revenue Comparsion Report</b>
        </Grid>

        <Grid item md={2} style={{ textAlign: "right", marginLeft: "24%" }}>
          <Button onClick={downloadExcel}>Download As Excel</Button>
        </Grid>
      </Grid>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <StyledTableRow>
                {tableHead.map((column) => (
                  <StyledTableCell key={column.id} align={"center"}>
                    {column.label}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {finalReport
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow key={row.id}>
                      <StyledTableCellInside
                        style={{
                          textTransform: "capitalize",
                          color: "#f46d25",
                          fontWeight: "bold",
                        }}
                        align="center"
                      >
                        {row?.name}
                      </StyledTableCellInside>
                      <StyledTableCellInside align="center">
                        {formattedDate(row?.date1)}
                      </StyledTableCellInside>
                      <StyledTableCellInside align="center">
                        {currencyFormatter(row?.revenue1)}
                      </StyledTableCellInside>
                      <StyledTableCellInside align="center">
                        {row?.date2?formattedDate(row?.date2):"N.A"}
                      </StyledTableCellInside>
                      <StyledTableCellInside align="center">
                      {row?.revenue2?currencyFormatter(row?.revenue2):currencyFormatter(0)}
                      </StyledTableCellInside>
                      <StyledTableCellInside align="center">
                        {row?.delta?currencyFormatter(row?.delta):currencyFormatter(0)}
                      </StyledTableCellInside>
                      <StyledTableCellInside align="center">
                        {row['%']?Number(row['%']).toFixed(2)+"%":0+"%"}
                      </StyledTableCellInside>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={finalReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}

export default StaffRevenueCompareReport
