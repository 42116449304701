import React, { useState } from "react";
import { Box, Button, FormControl, Grid, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import styles from "./ContactAndEnrollPage.module.scss";
import Api from "../../../Service/Api";
import { toast } from "react-toastify";

const NewContactForm = () => {
  const [captcha, setCaptcha] = useState(generateCaptcha());

  function generateCaptcha() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      const index = Math.floor(Math.random() * characters.length);
      captcha += characters.charAt(index);
    }
    return captcha;
  }
  const schema = yup
    .object({
      firstName: yup.string().required("This Field is required"),
      lastName: yup.string().required("This Field is required"),
      number: yup
        .string()
        .matches(/^[0-9]{10}$/, "Mobile number is not valid")
        .required("Mobile number is required"),
      mail: yup
        .string()
        .matches(
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
          "Email is not valid"
        )
        .required("Email is required"),
      captcha: yup
        .string()
        .test("match", "Please enter the correct captcha", function (value) {
          return value === captcha;
        })
        .required("Please enter the captcha"),
    })
    .required();

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      number: "",
      mail: "",
      captcha: "",
    },
    validationSchema: schema,
    onSubmit,
  });

  async function onSubmit(values, actions) {
    console.log("values", values);
    if (values) {
      const formData = {
        firstName: values.firstName,
        lastName: values.lastName,
        mail: values.mail,
        number: values.number,
      };

      Api.post("innerCircleContactForm", JSON.stringify(formData), {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            actions.resetForm({
              values: {
                firstName: "",
                lastName: "",
                number: "",
                mail: "",
                captcha: "",
              },
            });
            toast.success("Submitted Successfully");
            setCaptcha(generateCaptcha());
          }
        })
        .catch((error) => {
          toast.error("Something went wrong in Server", error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    } else {
      setCaptcha(generateCaptcha());
    }
  }
  return (
    <div>
      <div className={styles.contactHeadingBox}>
        <h1>Get A Callback</h1>
        <p>Our team will connect with you shortly</p>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={6} md={6} sm={6}>
            <FormControl sx={{ width: "100%" }} fullWidth>
              <TextField
                className="inputbox"
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                value={values.firstName}
                placeholder="First Name"
                name="firstName"
                id="firstName"
                sx={{
                  borderRadius: "7px",
                  "& input": {
                    backgroundColor: "rgba(19, 22, 24, 0.77)",
                    borderRadius: "7px",
                    color: "#f5f5f5",
                  },
                  "& input::placeholder": {
                    color: "rgba(220, 220, 220, 1)",
                  },
                }}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
            </FormControl>
            {/* {errors.firstName && touched.firstName && (
                <span style={{ color: "red" }}>{errors.firstName}</span>
              )} */}
          </Grid>
          <Grid item xs={6} md={6} sm={6}>
            <FormControl fullWidth>
              <TextField
                className="inputbox"
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                value={values.lastName}
                placeholder="Last Name"
                name="lastName"
                id="lastName"
                sx={{
                  "& input": {
                    backgroundColor: "rgba(19, 22, 24, 0.77)",
                    borderRadius: "7px",
                    color: "#f5f5f5",
                  },
                  "& input::placeholder": {
                    color: "rgba(220, 220, 220, 1)",
                  },
                }}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </FormControl>
            {/* {errors.lastName && touched.lastName && (
                <span style={{ color: "red" }}>{errors.lastName}</span>
              )} */}
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          sx={{ mt: "5px" }}
        >
          <Grid item xs={6} md={6} sm={6}>
            <FormControl fullWidth>
              <TextField
                className="inputbox"
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                value={values.mail}
                placeholder="Mail Id"
                name="mail"
                id="mail"
                sx={{
                  "& input": {
                    backgroundColor: "rgba(19, 22, 24, 0.77)",
                    borderRadius: "7px",
                    color: "#f5f5f5",
                  },
                  "& input::placeholder": {
                    color: "rgba(220, 220, 220, 1)",
                  },
                }}
                error={touched.mail && Boolean(errors.mail)}
                helperText={touched.mail && errors.mail}
              />
            </FormControl>
            {/* {errors.mail && touched.mail && (
                <span style={{ color: "red" }}>{errors.mail}</span>
              )} */}
          </Grid>
          <Grid item xs={6} md={6} sm={6}>
            <FormControl fullWidth>
              <TextField
                className="inputbox"
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                value={values.number}
                placeholder="Number"
                name="number"
                id="number"
                sx={{
                  "& input": {
                    backgroundColor: "rgba(19, 22, 24, 0.77)",
                    borderRadius: "7px",
                    color: "#f5f5f5",
                  },
                  "& input::placeholder": {
                    color: "rgba(220, 220, 220, 1)",
                  },
                }}
                error={touched.number && Boolean(errors.number)}
                helperText={touched.number && errors.number}
              />
            </FormControl>
            {/* {errors.number && touched.number && (
                <span style={{ color: "red" }}>{errors.number}</span>
              )} */}
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 2 }}
          sx={{ mt: "5px" }}
        >
          <Grid item xs={6} md={6} sm={6}>
            <Box
              sx={{
                // backgroundColor: "rgba(19, 22, 24, 0.77)",
                border: "0.5px solid rgba(19, 22, 24, 0.77)",
                borderRadius: "7px",
                color: "#f5f5f5",
                padding: "10px",

                textAlign: "center",

                opacity: "1",
                fontSize: "16px",
                textShadow: "1px 2px 2px grey",
                fontWeight: 500,
              }}
            >
              {captcha}
            </Box>
          </Grid>
          <Grid item xs={6} md={6} sm={6}>
            <Box>
              <FormControl fullWidth>
                <TextField
                  className="inputbox"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  size="small"
                  value={values.captcha}
                  placeholder="Enter the captcha"
                  name="captcha"
                  id="captcha"
                  sx={{
                    "& input": {
                      backgroundColor: "rgba(19, 22, 24, 0.77)",
                      borderRadius: "7px",
                      color: "#f5f5f5",
                    },
                    "& input::placeholder": {
                      color: "rgba(220, 220, 220, 1)",
                    },
                  }}
                  error={touched.captcha && Boolean(errors.captcha)}
                  helperText={touched.captcha && errors.captcha}
                />
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              background: "rgba(10, 29, 47, 1)",
              textAlign: "center",
              color: "#FFFFFF",
              mt: "15px",
              textTransform: "capitalize",
              fontSize: "16px",
              padding: "8px 15px",
              borderRadius: "7px",
              width: "100%",
              "&:hover": {
                background: "rgba(10, 29, 47, 1)",
              },
            }}
            type="submit"
          >
            Submit
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default NewContactForm;
