import Api from "../../Service/Api";
import { ActionTypes } from "./actionTypes";

export const hotelLoginTermsAndConditions =
  (propertyId) => async (dispatch) => {
    await Api.get(`termsandconditionvalue/${propertyId}`).then((res) => {
      console.log(res.data.message);
      dispatch({
        type: ActionTypes.HOTEL_LOGIN_TERMS_AND_CONDITION,
        payload: res.data.message,
      });
    });
  };

export const hotelLoginCancellationPolicy =
  (propertyId) => async (dispatch) => {
    await Api.get(`cancelationpolicyvalue/${propertyId}`).then((res) => {
      console.log(res.data.message);
      dispatch({
        type: ActionTypes.HOTEL_LOGIN_CANCELLATION_POLICY,
        payload: res.data.message,
      });
    });
  };

export const hotelLoginMealplanPolicy = (propertyId) => async (dispatch) => {
  await Api.get(`mealplantermsandconditionsvalue/${propertyId}`).then((res) => {
    console.log(res.data.message);
    dispatch({
      type: ActionTypes.HOTEL_LOGIN_MEALPLAN_POLICY,
      payload: res.data.message,
    });
  });
};
