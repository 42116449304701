import { useContext } from "react"
import styles from "./DestinationCard.module.css"
import { ContactModalContext } from "../../context/contactModal.context"

const DestinationCard = ({ src, destination }) => {
  const { toggleModal } = useContext(ContactModalContext)
  return (
    <div className={styles.destinationCard} onClick={toggleModal}>
      <img src={src} className={styles.destinationBackground} />
      <div className={styles.destinationContainer}>
        <span className={styles.destination}>{destination}</span>
      </div>
    </div>
  )
}

export default DestinationCard
