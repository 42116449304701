import styles from "./DestinationCardV2.styles.module.css"

const DestinationCardV2 = ({ children, className, ...restProps }) => {
  return (
    <div className={className} {...restProps}>
      {children}
    </div>
  )
}

const ImageContainer = (props) => {
  return <img {...props} />
}

const CardFooterVariant1 = ({ children, className }) => {
  return <footer className={className}>{children}</footer>
}

DestinationCardV2.Image = ImageContainer
DestinationCardV2.Footer = CardFooterVariant1

export default DestinationCardV2
