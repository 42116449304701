import { Button, makeStyles, TextField } from "@material-ui/core"
import { Grid } from "@mui/material"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min"
import {
  propertyBankCreate,
  propertyBankData,
  propertyBankUpdate,
} from "../../../redux/actions/propertyAction"
import * as Yup from "yup"

const useStyles = makeStyles(() => ({
  sectionHeader: {
    fontSize: "17px",
    fontWeight: "bold",
    paddingBottom: "7px",
  },
  innerSectionStyles: {
    border: "1px solid #D2D2D2",
    padding: "15px",
    borderRadius: "5px",
  },
  outerSectionStyles: {
    marginTop: "19px",
  },
  inputField: {
    width: "100%",
  },
  labelProps: {
    color: "#000000",
  },
  inputProps: {
    height: "40px",
  },
  error: {
    color: "red",
  },
}))

const initialValues = {
  accountNo: null,
  accountHolderName: "",
  branchName: "",
  iFSCCode: "",
  branchCode: "",
  bankname: "",
  bankCode: "",
  panNumber: "",
  nameOnPanCard: "",
  gst: "",
  swiftCode: "",
  upiId: "",
}

const PropertybankDetailsForm = () => {
  const classes = useStyles()
  const { propertyId } = useParams()
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  let uniqueid = localStorage.getItem("unique_id")
  let createdBy = localStorage.getItem("auth")
  const history = useHistory()

  const create = {
    uniqueId: uniqueid,
    createdBy: createdBy,
    propertyId: propertyId,
  }

  const propertyBankDetails = useSelector(
    ({ propertyBankData }) => propertyBankData.propertyBankDatas
  )

  useEffect(() => {
    if (propertyId) {
      dispatch(propertyBankData(propertyId))
    }
    console.log(path)
  }, [propertyId])

  const validationSchema = Yup.object({
    accountNo: Yup.number().required("Account No Required"),
    accountHolderName: Yup.string().required("Account Holder Name Required"),
    branchName: Yup.string().required("Branch Name Required"),
    iFSCCode: Yup.string().required("IFSC Code Required"),
    bankname: Yup.string().required("Bank Name Required"),
    panNumber: Yup.string().required("PAN No Required"),
    nameOnPanCard: Yup.string().required("PAN Name  Required"),
  })

  const [loading, setLoading] = useState(() => false)

  function onSubmit(fields, { setStatus }) {
    setLoading(true)
    const id = propertyBankDetails.id
    setStatus()
    if (id) {
      console.log({ fields })
      updateBank(fields)
    } else {
      createBank(fields)
    }
  }

  function createBank(fields) {
    var createData = { ...fields, ...create }
    dispatch(propertyBankCreate(createData, history))
    setLoading(false)
  }

  function updateBank(fields) {
    fields.updateBy = createdBy
    dispatch(propertyBankUpdate(fields, history))
    setLoading(false)
  }

  return (
    <Formik
      initialValues={propertyBankDetails || initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, isValid }) => (
        <Form autoComplete="off">
          <section className={classes.outerSectionStyles}>
            <header className={classes.sectionHeader}>Bank Details</header>
            <section className={classes.innerSectionStyles}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Field
                    name="accountNo"
                    as={TextField}
                    type="number"
                    value={values.accountNo}
                    inputMode="numeric"
                    variant="outlined"
                    className={classes.inputField}
                    InputProps={{ className: classes.inputProps }}
                    label="Account Number"
                    InputLabelProps={{
                      shrink: "true",
                      className: classes.labelProps,
                    }}
                    required
                    onWheel={(e) => e.target.blur()}
                  />
                  <ErrorMessage name="accountNo">
                    {(error) => <div className={classes.error}>{error}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="accountHolderName"
                    as={TextField}
                    type="text"
                    value={values.accountHolderName}
                    variant="outlined"
                    className={classes.inputField}
                    InputProps={{ className: classes.inputProps }}
                    label="Account Holder Name"
                    InputLabelProps={{
                      shrink: "true",
                      className: classes.labelProps,
                    }}
                    required
                  />
                  <ErrorMessage name="accountHolderName">
                    {(error) => <div className={classes.error}>{error}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="branchName"
                    as={TextField}
                    type="text"
                    value={values.branchName}
                    variant="outlined"
                    className={classes.inputField}
                    InputProps={{ className: classes.inputProps }}
                    label="Branch Name"
                    InputLabelProps={{
                      shrink: "true",
                      className: classes.labelProps,
                    }}
                    required
                  />
                  <ErrorMessage name="branchName">
                    {(error) => <div className={classes.error}>{error}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="iFSCCode"
                    as={TextField}
                    type="text"
                    value={values.iFSCCode}
                    variant="outlined"
                    className={classes.inputField}
                    InputProps={{ className: classes.inputProps }}
                    label="IFSC Code"
                    InputLabelProps={{
                      shrink: "true",
                      className: classes.labelProps,
                    }}
                    required
                  />
                  <ErrorMessage name="iFSCCode">
                    {(error) => <div className={classes.error}>{error}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="branchCode"
                    as={TextField}
                    type="text"
                    variant="outlined"
                    value={values.branchCode}
                    className={classes.inputField}
                    InputProps={{ className: classes.inputProps }}
                    label="Branch Code"
                    InputLabelProps={{
                      shrink: "true",
                      className: classes.labelProps,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="bankname"
                    as={TextField}
                    type="text"
                    value={values.bankname}
                    variant="outlined"
                    className={classes.inputField}
                    InputProps={{ className: classes.inputProps }}
                    label="Bank Name"
                    InputLabelProps={{
                      shrink: "true",
                      className: classes.labelProps,
                    }}
                    required
                  />
                  <ErrorMessage name="bankname">
                    {(error) => <div className={classes.error}>{error}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="bankCode"
                    as={TextField}
                    type="text"
                    value={values.bankCode}
                    variant="outlined"
                    className={classes.inputField}
                    InputProps={{ className: classes.inputProps }}
                    label="Bank Code"
                    InputLabelProps={{
                      shrink: "true",
                      className: classes.labelProps,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="panNumber"
                    as={TextField}
                    type="text"
                    value={values.panNumber}
                    variant="outlined"
                    className={classes.inputField}
                    InputProps={{ className: classes.inputProps }}
                    label="PAN No."
                    InputLabelProps={{
                      shrink: "true",
                      className: classes.labelProps,
                    }}
                    required
                  />
                  <ErrorMessage name="panNumber">
                    {(error) => <div className={classes.error}>{error}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="nameOnPanCard"
                    as={TextField}
                    type="text"
                    value={values.nameOnPanCard}
                    variant="outlined"
                    className={classes.inputField}
                    InputProps={{ className: classes.inputProps }}
                    label="Name on PAN card"
                    InputLabelProps={{
                      shrink: "true",
                      className: classes.labelProps,
                    }}
                    required
                  />
                  <ErrorMessage name="nameOnPanCard">
                    {(error) => <div className={classes.error}>{error}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="gst"
                    as={TextField}
                    type="text"
                    value={values.gst}
                    variant="outlined"
                    className={classes.inputField}
                    InputProps={{ className: classes.inputProps }}
                    label="GST No."
                    InputLabelProps={{
                      shrink: "true",
                      className: classes.labelProps,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="swiftCode"
                    as={TextField}
                    type="text"
                    value={values.swiftCode}
                    variant="outlined"
                    className={classes.inputField}
                    InputProps={{ className: classes.inputProps }}
                    label="Swift Code"
                    InputLabelProps={{
                      shrink: "true",
                      className: classes.labelProps,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="upiId"
                    as={TextField}
                    type="text"
                    value={values.upiId}
                    variant="outlined"
                    className={classes.inputField}
                    InputProps={{ className: classes.inputProps }}
                    label="UPI ID"
                    InputLabelProps={{
                      shrink: "true",
                      className: classes.labelProps,
                    }}
                    placeholder="UPI_ID@paytm"
                  />
                </Grid>
              </Grid>
            </section>
          </section>
          <center>
            <Button
              style={{ height: "30px", margin: "10px 0px" }}
              type="submit"
              disabled={!isValid}
            >
              Save
            </Button>
          </center>
        </Form>
      )}
    </Formik>
  )
}

export default PropertybankDetailsForm
