import { Button, IconButton, makeStyles } from "@material-ui/core"
import { Form, Formik } from "formik"
import { useState } from "react"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import Api from "../../../Service/Api"
import { ReactComponent as EditCustomIcon } from "../../../assets/Icons/editCustomIcon.svg"
import ScreenLoader from "../../../components/loadingScreen/screenLoader.component"
import PropertyRoomRentForm from "../propertyRoomDetailsComponents/propertyRoomRentForm.component"

const useStyles = makeStyles(() => ({
  root: {
    padding: "70px 22px 0px 24px",
    margin: "0px",
  },
  headerStyle: {
    backgroundColor: "#f46d25",
    color: "white",
    padding: "5px",
    fontWeight: "bold",
    fontSize: "18px",
    borderRadius: "5px",
    marginBottom: "-10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  innerSectionStyles: {
    border: "1px solid #C4C4C4",
    padding: "10px",
    borderRadius: "5px",
    marginBottom: "15px",
  },
}))

const EditPropertyRoomRent = () => {
  const classes = useStyles()
  const { propertyId } = useParams()
  const [roomData, setRoomData] = useState([])
  const [loading, setLoading] = useState(false)

  const [pointerEvents, setPointerEvents] = useState({})
  console.log(pointerEvents)
  const history = useHistory()

  const fetchPropertyRoomData = async () => {
    setLoading(true)
    try {
      const { data } = await Api.get(
        `propertyamenitiesroomallvalue/${propertyId}`
      )
      console.log(data)
      setRoomData(data)
      setPointerEvents(
        data?.reduce((acc, room) => {
          acc[room.id] = "none"
          return acc
        }, {})
      )
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  useState(() => {
    fetchPropertyRoomData()
  }, [])

  const toggleEdit = (roomId) => {
    setPointerEvents((prevPointerEvents) => ({
      ...prevPointerEvents,
      [roomId]: prevPointerEvents[roomId] === "none" ? "all" : "none",
    }))
  }

  const onSubmit = (fields) => {
    console.log(fields)
    setLoading(true)
    fields.updateBy = localStorage.getItem("auth")
    Api.put("propertyamenitiesroomupdate", fields)
      .then((res) => {
        toast.success("Successfully Updated")
        console.log(fields)
        toggleEdit(fields.id)
        setLoading(false)
        fetchPropertyRoomData()
      })
      .catch((error) => toast.error(error.message))
  }

  return (
    <section className={classes.root}>
      <>
        {loading ? (
          <ScreenLoader open={loading} />
        ) : (
          <>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {" "}
              <Button
                size="small"
                style={{ background: "#000000", marginBottom: "15px" }}
                onClick={() => {
                  history.push("/konnect/propertyList")
                }}
              >
                Property List
              </Button>
            </div>
            <>
              {roomData.map((room, idx) => (
                <section style={{ marginBottom: "20px" }} key={room.id}>
                  <header className={classes.headerStyle}>
                    <>{room.visibleName}</>
                    <IconButton
                      style={{ padding: "0" }}
                      onClick={() => toggleEdit(room.id)}
                    >
                      <EditCustomIcon
                        style={{
                          background: "white",
                          padding: "3px",
                          borderRadius: "3px",
                        }}
                      />
                    </IconButton>
                  </header>
                  <Formik
                    initialValues={room}
                    enableReinitialize
                    onSubmit={onSubmit}
                  >
                    {({ values }) => (
                      <Form
                        autoComplete="off"
                        style={{
                          pointerEvents: pointerEvents[room?.id] || "none",
                          opacity:
                            pointerEvents[room?.id] == "none" ? "0.3" : "1",
                        }}
                      >
                        <PropertyRoomRentForm values={values} />

                        <center>
                          {pointerEvents[room?.id] !== "none" && (
                            <Button
                              size="small"
                              type="submit"
                              style={{ margin: "10px" }}
                            >
                              Save
                            </Button>
                          )}
                        </center>
                      </Form>
                    )}
                  </Formik>
                  {idx < roomData.length - 1 && (
                    <hr
                      width="100%"
                      color="#f46d25"
                      style={{ marginTop: "20px" }}
                    />
                  )}
                </section>
              ))}
            </>
          </>
        )}
      </>
    </section>
  )
}

export default EditPropertyRoomRent
