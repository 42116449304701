/**components import */
import Header from "../Header/header.component"

/**assets import */
import TownoLogo from "../../assets/logo/towno.fontImage.svg"

/**styles import */
import "./Navbar.styles.css"
// import { Link, NavLink } from "react-router-dom"
import {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import NavIcon from "../NavIcon/NavIcon.component"
import { SideNavbarContext } from "../../context/sideNavBarContext"
import {
  Link,
  NavLink,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min"
import _ from "lodash"
import { ContactModalContext } from "../../context/contactModal.context"
import { AddCircleOutlined, RemoveCircleOutlined } from "@material-ui/icons"
import { IconButton, Menu, MenuItem, withStyles } from "@material-ui/core"
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow"

const leftHandSideNavLinks = [
  {
    name: "Hotels",
    path: "/hotels",
  },
  { name: "Destinations", path: "" },
  { name: "Group/Corporate", path: "" },
  { name: "Weddings", path: "" },
]

const rightHandSideNavLinks = [
  {
    name: "Blog",
    path: "https://blog.towno.in/",
    open: "newTab",
  },
  { name: "About Us", path: "/aboutUs" },
  { name: "Contact Us", path: "/contactUs" },
]

const dataMap = new Map([
  [
    "Uttarakhand",
    [
      {
        title: "Hill Stations",
        navlinks: [{ name: "Nainital", link: "/uttarakhand" }],
      },
      {
        title: "Religious Places",
        navlinks: [],
      },
      {
        title: "Pilgrimage Trials",
        navlinks: [],
      },
      {
        title: "Historical Sites",
        navlinks: [],
      },
      { title: "Most Visited 2023", navlinks: [] },
    ],
  ],
])

const mobData = [
  {
    title: "Uttarakhand",
    subTitle: [
      {
        title: "Hill Stations",
        navlinks: [{ name: "Nainital", link: "/uttarakhand" }],
      },
      {
        title: "Religious Places",
        navlinks: [],
      },
      {
        title: "Pilgrimage Trials",
        navlinks: [],
      },
      {
        title: "Historical Sites",
        navlinks: [],
      },
      { title: "Most Visited 2023", navlinks: [] },
    ],
  },
  {
    title: "Goa",
    subTitle: [
      {
        title: "Hill Stations",
        navlinks: [],
      },
      {
        title: "Religious Places",
        navlinks: [],
      },
      {
        title: "Pilgrimage Trials",
        navlinks: [],
      },
      {
        title: "Historical Sites",
        navlinks: [],
      },
      { title: "Most Visited 2023", navlinks: [] },
    ],
  },
]

const StyledMenuItem = withStyles((theme) => ({}))(MenuItem)

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false)
  const { openSideNavbar, setOpenSideNavbar } = useContext(SideNavbarContext)
  const history = useHistory()
  const { toggleModal } = useContext(ContactModalContext)
  const [expandDropdown, setExpandDropDown] = useState(false)
  const currentDropDown = useRef(null)
  const [width, setWidth] = useState(window.innerWidth)
  const [anchorEl, setAnchorEl] = useState(null)
  const [subCategoryAnchorEl, setSubCategoryAnchorEl] = useState(null)

  const [categoryType, setCategoryType] = useState("")

  const [activeDestination, setActiveDestination] = useState("Uttarakhand")
  const [expandSubCategory, setExpandSubCategory] = useState(false)

  const { destinationTitle } = useContext(SideNavbarContext)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    document.addEventListener("resize", handleResize)
    return () => document.removeEventListener("resize", handleResize)
  }, [width])

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0
      setScrolled(isScrolled)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const dropdownTitles = Array.from(dataMap.keys())

  const dropDownCategories = useMemo(
    () => dataMap.get(activeDestination),
    [activeDestination]
  )

  const handleCloseDropdown = () => setAnchorEl(null)

  return (
    <div className="navbar-container">
      <nav className={`navbar-root ${scrolled ? "scrolled" : ""}`}>
        <NavLink to="/">
          <img
            className="towno-logo"
            src={TownoLogo}
            style={{ cursor: "pointer" }}
          />
        </NavLink>

        <div data-sideNav={openSideNavbar} className={"link-container"}>
          <ul
            className={
              openSideNavbar ? "side-navbar-links" : "left-side-navlinks"
            }
          >
            {leftHandSideNavLinks.map((nav) => (
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  flexDirection: "column",
                }}
              >
                {!_.isEqual(nav.name, "Destinations") ? (
                  <li
                    key={nav?.path}
                    onClick={(e) => {
                      if (
                        _.isEmpty(nav.path) &&
                        !_.isEqual(nav.name, "Destinations")
                      ) {
                        toggleModal()
                      }
                    }}
                    className="nav-item"
                  >
                    <NavLink
                      to={!_.isEmpty(nav.path) && nav.path}
                      className={(isActive) =>
                        isActive && !_.isEmpty(nav.path) ? "activeLink" : ""
                      }
                    >
                      {nav.name}
                    </NavLink>
                  </li>
                ) : (
                  <>
                    <li
                      key={nav?.path}
                      onClick={(e) => {
                        currentDropDown.current = nav.name
                        setExpandDropDown((c) => !c)
                        setAnchorEl(e.currentTarget)
                      }}
                      className="nav-item"
                      style={{ cursor: "pointer" }}
                    >
                      {_.capitalize(destinationTitle)}

                      <span
                        className={`expand-icon ${
                          expandDropdown ? "rotate" : ""
                        }`}
                      >
                        {">"}
                      </span>
                    </li>
                    {expandDropdown && (
                      <ul
                        className={`destination-dropdown ${
                          width > 769 ? "absolute" : ""
                        }`}
                      >
                        <li
                          style={{
                            display: "flex",
                            gap: "1rem",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            onClick={() => {
                              setExpandDropDown((c) => !c)
                            }}
                            className="title-container"
                          >
                            <span
                              className="destination-category-title"
                              onClick={() =>
                                window.open("/uttarakhand", "_blank")
                              }
                            >
                              Uttarakhand
                            </span>
                            <IconButton
                              className={`expand-sub-category ${
                                expandSubCategory ? "expand" : ""
                              }`}
                              onClick={(e) => {
                                e.stopPropagation()
                                setExpandSubCategory((c) => !c)
                              }}
                              size="small"
                              disableRipple
                              disableFocusRipple
                              disableTouchRipple
                            >
                              {"›"}
                            </IconButton>
                          </div>
                          {expandSubCategory && (
                            <ul style={{ padding: "0rem", listStyle: "none" }}>
                              <li
                                className="destination-category-title-sub-title"
                                key={1}
                              >
                                Nainital
                              </li>
                              <li
                                className="destination-category-title-sub-title"
                                key={2}
                              >
                                Massourie
                              </li>
                            </ul>
                          )}
                        </li>
                      </ul>
                    )}
                  </>
                )}
              </div>
            ))}
          </ul>

          <ul
            className={
              openSideNavbar ? "side-navbar-links" : "right-side-navlinks"
            }
          >
            {rightHandSideNavLinks.map((nav, i) =>
              nav.path === "" || nav.open === "newTab" ? (
                <li
                  key={nav.path}
                  onClick={() => {
                    openSideNavbar && setOpenSideNavbar(false)
                    if (nav.open === "newTab") {
                      window.open(nav.path, "_blank")
                    } else {
                      toggleModal()
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {nav.name}
                </li>
              ) : (
                <li key={nav.path} onClick={() => setOpenSideNavbar(false)}>
                  <NavLink
                    to={nav.path}
                    key={nav.path}
                    className={(isActive) =>
                      isActive && nav.path !== "" ? "activeLink" : ""
                    }
                  >
                    {nav.name}
                  </NavLink>
                </li>
              )
            )}
            <NavLink className="login-btn" to="/konnect" target="_blank">
              Konnect
            </NavLink>
          </ul>
        </div>
        {<NavIcon />}
      </nav>
      {/* <div className="scroll-watcher"></div> */}
    </div>
  )
}

export default Navbar
