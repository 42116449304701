import { useContext, useEffect } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import DestinationCardV2 from "../../../../components/DestinationNew/DestinationCard_V2/DestinationCardV2.component"
import QueryForm from "../../../../components/QueryForm/QueryForm.component"
import { DestinationLayoutContext } from "../../../../layout/DestinationLayout/Destination.layout"
import styles from "./DestinationSubCity.styles.module.css"

import DoubleArrowIcon from "@material-ui/icons/DoubleArrow"
import PlanningNextTripBanner from "../../../../components/DestinationNew/PlanningNextTripBanner/PlanningNextTripBanner.component"

import DehradunImg from "../../../../assets/illustrations/destination/where-you-headed/Dehradun.jpg"
import HaridwarImg from "../../../../assets/illustrations/destination/where-you-headed/Haridwar.jpg"
import MussoorieImg from "../../../../assets/illustrations/destination/where-you-headed/Mussoorie.jpg"
import RishikeshImg from "../../../../assets/illustrations/destination/where-you-headed/Rishikesh2.jpg"
import TungnathTempleImg from "../../../../assets/illustrations/destination/where-you-headed/Tungnath-Temple-by-Abhinav-Tunait-www.flickr.comphotos103602929@N08.jpg"
import UkhimathImg from "../../../../assets/illustrations/destination/where-you-headed/Ukhimath.jpg"

const whereYouHeadedSrc = [
  { src: HaridwarImg, name: "Haridwar", link: "haridwar" },
  { src: DehradunImg, name: "Dehradun", link: "Dehradun" },
  { src: MussoorieImg, name: "Mussoorie", link: "haridwar" },
  { src: RishikeshImg, name: "Rishikesh", link: "haridwar" },
  { src: TungnathTempleImg, name: "Tungnath Temple", link: "haridwar" },
  { src: UkhimathImg, name: "Ukhimath", link: "haridwar" },
  // { src: AuliImg, name: "Auli", link: "haridwar" },
  // { src: NainitalImg, name: "Nainital", link: "haridwar" },
]

const DestinationSubCity = () => {
  // console.log("welcome to subcity")
  const { subCity } = useParams()

  const { setBanner } = useContext(DestinationLayoutContext)

  useEffect(() => {
    setBanner("subCity")
  }, [])

  return (
    <>
      <section className={styles.topMostSection}>
        <div className={styles.content}>
          With its unique mix of snow-clad mountains, lush-green limitless
          meadows and dense forests, Uttarakhand is indeed unique tourist
          destination. Whether you're looking for adventure, leisure or deep
          introspection, there are plenty of places to visit in Uttarakhand.
          <p>
            Popular amongst domestic as well as foreign travellers, Uttarakhand
            is one of the fastest-growing destinations in India. You can take a
            jeep safari through the covert forests of Jim Corbett to spot the
            Royal Bengal Tiger or you can trek through the alpine meadows and
            budgyals at Auli or you can simply enjoy sit back and relax and
            enjoy the breath-taking view of the snow-clad Himalayas from the
            Queen of The Hills, Mussoorie. 
          </p>
        </div>
        <QueryForm />
      </section>

      <section className={styles.destinationsInState}>
        <header className={styles.header}>
          Destinations in <span>Uttarakhand</span>
        </header>
        <div className={styles.destinationInStateCardList}>
          {whereYouHeadedSrc.map((_, i) => {
            return (
              <DestinationCardV2 className={styles.cardVariantOne} key={i}>
                <DestinationCardV2.Image src={_.src} />
                <DestinationCardV2.Footer>{_.name}</DestinationCardV2.Footer>
              </DestinationCardV2>
            )
          })}
        </div>
        <div className={styles.seeMore}>
          SEE MORE
          <DoubleArrowIcon color="primary" />
        </div>
      </section>

      <section className={styles.destinationsInState}>
        <header className={styles.header}>
          Most Trending <span>Destination</span>
        </header>
        <div className={styles.destinationInStateCardList}>
          {whereYouHeadedSrc
            .filter((_, i) => i < 5)
            .map((_, i) => {
              return (
                <DestinationCardV2 className={styles.cardVariantOne} key={i}>
                  <DestinationCardV2.Image src={_.src} />
                  <DestinationCardV2.Footer>{_.name}</DestinationCardV2.Footer>
                </DestinationCardV2>
              )
            })}
        </div>
      </section>

      <PlanningNextTripBanner />
    </>
  )
}

export default DestinationSubCity
