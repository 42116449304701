import { Link } from "react-router-dom"
import PackageCard from "../PackageCard/PackageCard.component"
import "./MostPopularPackages.styles.css"
import { useEffect, useRef, useState } from "react"
import PackageData from "../../mock/packageData.json"
import DubaiPk from "../../assets/illustrations/package/Dubai Package.png"
import ThailandPk from "../../assets/illustrations/package/Thailand Package.png"
import UttarakhandPk from "../../assets/illustrations/package/Uttarakhand Package.png"

const packageData = [
  {
    name: "Dubai",
    imgPath: DubaiPk,
    content:
      "Experience the enchanting Dubai skyline with our super saver budget Dubai package",
    price: "43000",
  },
  {
    name: "Thailand",
    imgPath: ThailandPk,
    content:
      "Book customized Thailand trip packages with exciting deals & offers.",
    price: "45500",
  },
  {
    name: "Uttarakhand",
    imgPath: UttarakhandPk,
    content:
      "Book now for an unforgettable journey amidst the pristine landscapes of Uttarakhand.",
    price: "33000",
  },
]

const MostPopularPackages = () => {
  const popularPackegeRef = useRef(null)
  const [isIntersecting, setIsIntersecting] = useState(false)
  const [isTitleIntersecting, setIsTitleIntersecting] = useState(false)

  const titleRef = useRef(null)

  useEffect(() => {
    const options = {
      threshold: 0.1,
    }
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setIsIntersecting(true)
      }
    }, options)

    const element = popularPackegeRef.current.querySelector(
      ".most-popular-packages-container-home"
    )

    observer.observe(element)

    if (isIntersecting) {
      element.classList.add("show")
    }

    return () => {
      observer.disconnect()
    }
  }, [isIntersecting])

  // useEffect(() => {
  //   const options = {
  //     threshold: 1,
  //   }
  //   const observer = new IntersectionObserver(([entry]) => {
  //     if (entry.isIntersecting) {
  //       setIsTitleIntersecting(true)
  //     }
  //   }, options)

  //   const element = titleRef.current

  //   console.log(element)

  //   observer.observe(element)

  //   if (isTitleIntersecting) {
  //     // element.style.opacity = "0.1"
  //     element.classList.add("show-header")
  //   }

  //   return () => {
  //     // element.classList.remove("show-header")
  //     observer.disconnect()
  //   }
  // }, [isTitleIntersecting])

  return (
    <>
      <section
        ref={popularPackegeRef}
        className="section-most-popular-packages"
      >
        <header id="header">
          <div className="title-container">
            <div className="primary-text">
              Best Selling <span className="secondary-text">Packages</span>
            </div>
            <Link>View All</Link>
          </div>
        </header>
        <main className="main">
          <div className="most-popular-packages-container-home">
            {packageData.map((p, i) => (
              <PackageCard {...p} key={`${p.name}-${i}`} />
            ))}
          </div>
        </main>
        <em
          style={{
            color: "#fff",
            wordWrap: "break-word",
            fontSize: "clamp(0.7rem,4vw,0.9rem)",
          }}
        >
          **subject to availability, season, terms and conditions apply.
        </em>
      </section>
    </>
  )
}

export default MostPopularPackages
