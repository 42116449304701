import { Paper, makeStyles } from "@material-ui/core";
import { Grid } from "@mui/material";
import parser from "html-react-parser";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import API from "../../Service/Api";
import { ReactComponent as ItineraryIllustration } from "../../assets/illustrations/itineraryIllustration.svg";
import TownoLogo from "../../assets/logo/towno_logo.png";
import ScreenLoader from "../../components/loadingScreen/screenLoader.component";
import ItineraryDayPlanner from "./itineraryDayPlanner.component";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "20px 0px",
  },
  paperStyle: {
    maxWidth: "50%",
    margin: "0 auto",
    width: "1050px",
    height: "1080px",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: "0px",
    padding: "18px",
    background: "rgba(249, 245, 241, 1)",
  },
  indexBorderStyling: {
    border: "1px solid #f46d25",
    width: "100%",
    height: "100%",
  },
  timelineContainer: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "flex",
    padding: theme.spacing(2),
  },
}));

const ItineraryVoucher = () => {
  const classes = useStyles();
  const { itineraryId } = useParams();
  const unique_id = localStorage.getItem("unique_id");
  const [itineraryData, setItineraryData] = useState({});
  const [loading, setLoading] = useState(false);
  const [itineraryTnC, setItineraryTnC] = useState("");

  const fetchItineraryData = async () => {
    setLoading(true);
    try {
      const { data } = await API.get(
        `getitinerary/${unique_id}/${itineraryId}`
      );
      console.log(data);

      const itineraryTnC = await API.get(
        `commonfeaturemenu/${unique_id}/itineraryTermsAndConditions`
      );
      console.log({ itineraryTnC });

      setItineraryData(data);
      setItineraryTnC(itineraryTnC.data[0].name);
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    return itineraryId && fetchItineraryData();
  }, []);

  const recordsPerPage = 4;
  const totalRecords = itineraryData?.itineraryDataRequest?.length;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  const pages = Math.ceil(
    itineraryData?.itineraryDataRequest?.length / recordsPerPage
  );

  return (
    <section className={classes.root}>
      {loading ? (
        <ScreenLoader open={loading} />
      ) : (
        <>
          <Paper className={classes.paperStyle}>
            <section className={classes.indexBorderStyling}>
              <Grid container justifyContent="center" marginTop="174px">
                <figure
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={TownoLogo}
                    alt="Towno Logo"
                    width="350"
                    height="135"
                    loading="lazy"
                  />
                  <figcaption
                    style={{
                      color: "#f46d25",
                      fontSize: "30px",
                      fontWeight: "700",
                    }}
                  >
                    {itineraryId}
                  </figcaption>
                  <figcaption style={{ fontSize: "45px", fontWeight: "700" }}>
                    {itineraryData?.itineraryName}
                  </figcaption>
                  <figcaption style={{ fontSize: "26px", fontWeight: "500" }}>
                    {itineraryData?.itineraryDestination}
                  </figcaption>
                </figure>
                <ItineraryIllustration />
              </Grid>
            </section>
            <div
              style={{
                height: "18px",
                background: "#000000",
              }}
            ></div>
          </Paper>

          {Array.from({ length: totalPages }, (_, pageIndex) => (
            <ItineraryDayPlanner
              key={pageIndex}
              classes={classes}
              itineraryData={{
                itineraryDataRequest: itineraryData?.itineraryDataRequest.slice(
                  pageIndex * recordsPerPage,
                  (pageIndex + 1) * recordsPerPage
                ),
              }}
              length={itineraryData?.itineraryDataRequest?.length}
              recordsPerPage={recordsPerPage}
            />
          ))}
        </>
      )}
      <Paper variant="outlined" className={classes.paperStyle}>
        <section
          style={{
            border: "1px solid #f46d25",
            padding: "5px 0px 0px 5px",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <header
            style={{
              marginLeft: "40px",
              fontSize: "25px",
              marginTop: "20px",
              fontWeight: "bold",
              flex: "0 1 auto",
            }}
          >
            Terms and Conditions
          </header>
          <main
            style={{
              margin: "0px 40px",
              marginTop: "0px",
              textAlign: "top",
              flex: "1 3 auto",
            }}
          >
            {parser(itineraryTnC)}
          </main>
          <footer>
            <figure>
              <img src={TownoLogo} width="150px" loading="lazy" />
              <figcaption>+91 7900369391 | +91 7900369391</figcaption>
              <figcaption>deepak@towno.in | hello@towno.in</figcaption>
              <figcaption>www.towno.in</figcaption>
            </figure>
          </footer>
        </section>
      </Paper>
    </section>
  );
};

export default ItineraryVoucher;
