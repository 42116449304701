import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  makeStyles,
} from "@material-ui/core"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import DoneIcon from "@material-ui/icons/Done"
import { Grid } from "@mui/material"
import { Field, Form, Formik } from "formik"
import { useEffect, useState } from "react"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import Api from "../../../Service/Api"
import InlineFormikFieldWithCheckSelectAndSwitch from "../../../components/amenityComponents/inlineCheckBoxWithSelectAndSwitch.component"
import ScreenLoader from "../../../components/loadingScreen/screenLoader.component"
import { Autocomplete } from "@material-ui/lab"

const useStyles = makeStyles((theme) => ({
  checkboxStyling: {
    "& .MuiSvgIcon-root": {
      color: "#f46d25",
      height: "18px",
      width: "18px",
    },
  },
  menuStyling: {
    fontSize: "14px",
  },
  selectLabelProps: {
    backgroundColor: "white",
    color: "#000000",
    padding: "0px 5px 5px 5px",
    borderRadius: "5px",
  },
  menuItemStyles: {
    height: "300px",
    fontSize: "14px",
  },
  selectStyle: {
    height: "35px",
    width: "100%",
    "& .MuiSelect-icon": {
      color: "#f46d25",
    },
  },
  checkBoxLabelStyles: {
    fontSize: "14px",
  },
  chipStyling: {
    margin: theme.spacing(0.5),
    height: "15px",
    fontSize: "13px",
  },
}))

const initialValues = {
  airportTransfers: false,
  airportTransfersType: "",
  airportTransfersType1: [],
  airportTransfersStarred: false,
  railwayStationTransfers: false,
  railwayStationTransfersType: "",
  railwayStationTransfersType1: [],
  railwayStationTransfersStarred: false,
  busStationtransfers: false,
  busStationtransfersType: "",
  busStationtransfersType1: [],
  busStationtransfersStarred: false,
  shuttleService: false,
  shuttleServiceType: "",
  shuttleServiceStarred: false,
  privatetrasfer: false,
  privatetrasferType: "",
  privatetrasferStarred: false,
  vehicleRentals: false,
  vehicleRentalsType: "",
  vehicleRentalsType1: [],
  vehicleRentalsStarred: false,
}

const TransferAmenities = ({ classes, handleChangePanel, expanded }) => {
  const selectStyles = useStyles()
  const { propertyId } = useParams()
  const [transferData, setTransferData] = useState(() => {})
  const [BusStationTransfersType, setBusStationTransfersType] = useState([])
  const [RailwayStationTransfersType, setRailwayStationTransfersType] =
    useState([])
  const [AirportTransfersType, setAirportTransfersType] = useState([])
  const [vehicle, setVehicle] = useState([])
  const uniqueid = localStorage.getItem("unique_id")
  const createdBy = localStorage.getItem("auth")
  const create = { uniqueId: uniqueid, createdBy: createdBy }
  const [loading, setLoading] = useState(() => false)
  const history = useHistory()

  const fetchTransferData = async () => {
    setLoading(true)
    try {
      const normalData = await Api.get(`AmenitiesTransfersvalue/${propertyId}`)
      const switchData = await Api.get(
        `AmenitiesTransfersStarredvalue/${propertyId}`
      )

      console.log(normalData.data)
      console.log(switchData.data)
      setTransferData({ ...normalData.data, ...switchData.data })
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (propertyId && expanded === "panel10") {
      fetchTransferData()
    }
  }, [expanded])

  const vehicles = async () => {
    const vehicleData = {
      type: "Vehicle Rentals",
    }
    await Api.post("productdropdownonly", vehicleData).then((response) => {
      setVehicle(response.data)
    })
  }

  const BusGet = async () => {
    const busData = {
      type: "Bus Station Transfers Type",
    }
    await Api.post("productdropdownonly", busData).then((response) => {
      setBusStationTransfersType(response.data)
    })
  }
  const RailGet = async () => {
    const railData = {
      type: "Railway Station Transfers Type",
    }
    await Api.post("productdropdownonly", railData).then((response) => {
      setRailwayStationTransfersType(response.data)
    })
  }
  const AirGet = async () => {
    const airData = {
      type: "Airport Transfers Type",
    }
    await Api.post("productdropdownonly", airData).then((response) => {
      setAirportTransfersType(response.data)
    })
  }
  useEffect(() => {
    //dropdown option getters
    if (propertyId && expanded === "panel10") {
      AirGet()
      BusGet()
      RailGet()
      vehicles()
    }
  }, [expanded])

  const securitySelectOnly = [
    {
      name: "shuttleService",
      label: "Shuttle Service",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "shuttleServiceType",
      switchName: "shuttleServiceStarred",
      dropdDownOptions: [
        { value: "free", label: "Free" },
        { value: "paid", label: "Paid" },
      ],
    },

    {
      name: "privatetrasfer",
      label: "Private Transfer",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "privatetrasferType",
      switchName: "privatetrasferStarred",
      dropdDownOptions: [
        { value: "free", label: "Free" },
        { value: "paid", label: "Paid" },
      ],
    },
  ]

  const transferMultipleSelectFields = [
    {
      name: "airportTransfers",
      label: "Airport Transfer",
      select: true,
      selectLabel1: "Type",
      selectLabel2: "Location",
      selectFieldName1: "airportTransfersType",
      selectFieldName2: "airportTransfersType1",
      switchName: "airportTransfersStarred",
      dropdDownOptions1: [
        { value: "free", label: "Free" },
        { value: "paid", label: "Paid" },
      ],
      dropdDownOptions2: AirportTransfersType,
    },
    {
      name: "railwayStationTransfers",
      label: "Railway Station Transfer",
      select: true,
      selectLabel1: "Type",
      selectLabel2: "Location",
      selectFieldName1: "railwayStationTransfersType",
      selectFieldName2: "railwayStationTransfersType1",
      switchName: "railwayStationTransfersStarred",
      dropdDownOptions1: [
        { value: "free", label: "Free" },
        { value: "paid", label: "Paid" },
      ],
      dropdDownOptions2: RailwayStationTransfersType,
    },

    {
      name: "busStationtransfers",
      label: "Bus Transfer",
      select: true,
      selectLabel1: "Type",
      selectLabel2: "Location",
      selectFieldName1: "busStationtransfersType",
      selectFieldName2: "busStationtransfersType1",
      switchName: "busStationtransfersStarred",
      dropdDownOptions1: [
        { value: "free", label: "Free" },
        { value: "paid", label: "Paid" },
      ],
      dropdDownOptions2: BusStationTransfersType,
    },
    {
      name: "vehicleRentals",
      label: "Vehicle Rentals",
      select: true,
      selectLabel1: "Type",
      selectLabel2: "Location",
      selectFieldName1: "vehicleRentalsType",
      selectFieldName2: "vehicleRentalsType1",
      switchName: "vehicleRentalsStarred",
      dropdDownOptions1: [
        { value: "free", label: "Free" },
        { value: "paid", label: "Paid" },
      ],
      dropdDownOptions2: vehicle,
    },
  ]

  function onSubmit(fields, { setStatus }) {
    const id = transferData.id

    if (fields.airportTransfers == false) {
      fields.airportTransfersType = ""
      fields.airportTransfersType1 = []
      fields.airportTransfersStarred = false
    }

    if (fields.railwayStationTransfers == false) {
      fields.railwayStationTransfersType = ""
      fields.railwayStationTransfersType1 = []
      fields.railwayStationTransfersStarred = false
    }

    if (fields.busStationtransfers == false) {
      fields.busStationtransfersType = ""
      fields.busStationtransfersType1 = []
      fields.busStationtransfersStarred = false
    }
    if (fields.shuttleService == false) {
      fields.shuttleServiceType = ""
      fields.shuttleServiceStarred = false
    }
    if (fields.privatetrasfer == false) {
      fields.privatetrasferType = ""
      fields.privatetrasferStarred = false
    }

    if (fields.vehicleRentals == false) {
      fields.vehicleRentalsType = ""
      fields.vehicleRentalsType1 = []
      fields.vehicleRentalsStarred = false
    }
    setStatus()
    if (id) {
      updateTransfer(fields)
    } else {
      createTransfer(fields)
    }
  }

  function createStarred(fields) {
    const CreateData = {
      privatetrasferStarred: fields.privatetrasferStarred,
      airportTransfersStarred: fields.airportTransfersStarred,
      shuttleServiceStarred: fields.shuttleServiceStarred,
      busStationtransfersStarred: fields.busStationtransfersStarred,
      railwayStationTransfersStarred: fields.railwayStationTransfersStarred,
    }
    fields.propertyId = propertyId
    const newData = { ...fields, ...create }
    Api.post("AmenitiesTransfersStarred", newData)
  }
  function updateStarred(fields) {
    fields.updateBy = createdBy
    Api.put("AmenitiesTransfersStarredupdate", fields)
  }

  function createTransfer(fields) {
    setLoading(true)
    fields.propertyId = propertyId
    const newData = { ...fields, ...create }
    Api.post("AmenitiesTransfers", newData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      if (res.status === 200) {
        createStarred(fields)
        toast.success("Successfully Created")
        setLoading(false)
        history.push(`/konnect/property/${propertyId}/propertyMedia`)
      }
    })
  }

  function updateTransfer(fields) {
    setLoading(true)
    fields.updateBy = createdBy
    Api.put("AmenitiesTransfersupdate", fields, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      if (res.status === 200) {
        updateStarred(fields)
        toast.success("Successfully Updated")
        setLoading(false)
        history.push(`/konnect/property/${propertyId}/propertyMedia`)
      }
    })
  }

  return (
    <Formik
      initialValues={transferData || initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue }) =>
        loading ? (
          <ScreenLoader open={loading} />
        ) : (
          <Form autoComplete="off">
            <Accordion
              square
              expanded={expanded === "panel10"}
              onChange={handleChangePanel("panel10")}
              className={classes.accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#f46d25",
                      background: "white",
                      borderRadius: "5px",
                    }}
                  />
                }
                className={classes.accordionSummaryStyles}
              >
                Travel Transfers
              </AccordionSummary>
              <AccordionDetails className={classes.outerSectionStyles}>
                <Grid container spacing={3}>
                  {transferMultipleSelectFields.map((amenity) => (
                    <Grid item container xs={9} alignItems="center" spacing={3}>
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={
                            <Field
                              checked={values[amenity.name]}
                              as={Checkbox}
                              name={amenity.name}
                              className={selectStyles.checkboxStyling}
                            />
                          }
                          label={
                            <span className={selectStyles.checkBoxLabelStyles}>
                              {amenity.label}
                            </span>
                          }
                        />
                      </Grid>
                      {values[amenity.name] && (
                        <>
                          <Grid item xs={3}>
                            <FormControl
                              variant="outlined"
                              style={{ width: "100%" }}
                            >
                              <InputLabel
                                className={selectStyles.selectLabelProps}
                                shrink
                              >
                                {amenity.selectLabel1}
                              </InputLabel>
                              <Field
                                as={Select}
                                name={amenity.selectFieldName1}
                                value={values[amenity.selectFieldName1]}
                                className={selectStyles.selectStyle}
                                MenuProps={{
                                  className: selectStyles.menuItemStyles,
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                              >
                                {amenity.dropdDownOptions1.map((option) => (
                                  <MenuItem
                                    className={selectStyles.menuStyling}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Field>
                            </FormControl>
                          </Grid>
                          {values[amenity.selectFieldName1] === "paid" && (
                            <Grid item xs={5}>
                              {/* <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                              >
                                <InputLabel
                                  className={selectStyles.selectLabelProps}
                                  shrink
                                >
                                  {amenity.selectLabel2}
                                </InputLabel>
                                <Field
                                  as={Select}
                                  multiple
                                  name={amenity.selectFieldName2}
                                  value={values[amenity.selectFieldName2]}
                                  className={selectStyles.selectStyle}
                                  MenuProps={{
                                    className: selectStyles.menuItemStyles,
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                  }}
                                  renderValue={(selected) => (
                                    <div>
                                      {selected.map((value) => (
                                        <Chip
                                          key={value}
                                          label={value}
                                          variant="outlined"
                                          color="primary"
                                          deleteIcon={
                                            <DoneIcon color="primary" />
                                          }
                                          className={selectStyles.chipStyling}
                                        />
                                      ))}
                                    </div>
                                  )}
                                >
                                  {amenity.dropdDownOptions2.map((option) => (
                                    <MenuItem
                                      className={selectStyles.menuStyling}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Field>
                              </FormControl> */}
                              <Field
                                as={Autocomplete}
                                multiple //returns the boolean value for determine the multiple values select field
                                name={amenity.selectFieldName2}
                                value={values[amenity.selectFieldName2]}
                                limitTags={2}
                                style={{ height: "30px" }}
                                className={selectStyles.selectStyle}
                                options={amenity.dropdDownOptions2}
                                filterSelectedOptions
                                onChange={(event, newValue) => {
                                  setFieldValue(
                                    `${amenity.selectFieldName2}`,
                                    newValue
                                  )
                                }}
                                getOptionLabel={(option) => option}
                                size="small"
                                renderTags={(tagValue, getTagProps) =>
                                  tagValue.map((option, index) => (
                                    <Chip
                                      label={option}
                                      variant="outlined"
                                      color="primary"
                                      className={selectStyles.chipStyling}
                                      size="small"
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    label={amenity.selectLabel}
                                    size="small"
                                  />
                                )}
                              />
                            </Grid>
                          )}
                          <Grid item xs={1}>
                            <Field
                              name={amenity.switchName}
                              as={Switch}
                              color="primary"
                              checked={values[amenity.switchName]}
                              size="small"
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  ))}
                  {securitySelectOnly.map((amenity) => (
                    <InlineFormikFieldWithCheckSelectAndSwitch
                      amenity={amenity}
                      values={values}
                    />
                  ))}

                  {/**Security switch only */}
                  {/* {securitySwitchOnly.map((amenity) => (
                  <FormikInlineCheckboxAndSwitchOnly
                    amenity={amenity}
                    values={values}
                  />
                ))} */}
                </Grid>
              </AccordionDetails>
              <center>
                <Button
                  style={{ height: "30px", margin: "10px 0px" }}
                  type="submit"
                >
                  Save and Next
                </Button>
              </center>
            </Accordion>
          </Form>
        )
      }
    </Formik>
  )
}

export default TransferAmenities
