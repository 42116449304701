import { NavLink } from "react-router-dom"
import styles from "./AsideDestinationNavbar.module.css"

const links = [
  {
    title: "Tourism",
    // isHeader: true,
    navLink: "/uttarakhand/tourism",
  },
  { title: "Places To Visit", navLink: "/uttarakhand/placesToStay" },
  { title: "Tour Pacakges", navLink: "/uttarakhand/tourPackages" },
  { title: "Best Time To Visit", navLink: "/uttarakhand/bestTimeToVisit" },
  { title: "Things To Do", navLink: "/uttarakhand/thingsTodo" },
  {
    title: "How To Reach",
    // isHeader: true,
    navLink: "/uttarakhand/howToReach",
  },
  { title: "Key Facts", navLink: "/uttarakhand/keyFacts" },
  {
    title: "Shopping & Entertainment",
    navLink: "/uttarakhand/shoppingAndEntertainment",
  },
  { title: "Cuisine", navLink: "/uttarakhand/cuisine" },
  { title: "Things To Carry", navLink: "/uttarakhand/thingsToCarry" },
  { title: "Festivals and Events", navLink: "/uttarakhand/festivalsAndEvents" },
]

const AsideDestinationNavbar = () => {
  return (
    <div className={styles.container}>
      <ul className={styles.ul}>
        {links.map((link) => (
          <li key={link.title} className={styles.link}>
            <NavLink
              exact
              to={link?.navLink}
              className={(isActive) =>
                `${styles.navLink} ${isActive ? styles.active : ""}`
              }
            >
              {link.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AsideDestinationNavbar
