import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { ExpandMore, SearchSharp } from "@material-ui/icons";
import MuiPhoneNumber from "material-ui-phone-number";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { DatePicker } from "@material-ui/pickers";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import { Grid } from "@mui/material";
import { useState } from "react";

const useStyles = makeStyles({
  root: {
    margin: "20% 3%",
    paddingBottom: "20%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  createButton: {
    background: "#343A40",
    fontSize: "18px",
    height: "50%",
  },
  searchField: {
    width: "100%",
  },
  icon: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    position: "absolute",
    right: "2px",
    top: "5px",
    color: "#f46d25",
  },
  helperText: {
    backgroundColor: "#eaeaea",
    padding: "0",
    margin: "0",
    width: "100%",
    height: "100%",
  },
  plus: {
    cursor: "pointer",
    color: "#f46d25",
    fontSize: "20px",
  },
  formControl: {
    height: "40px",
    background: "white",
  },
});

const DualTextField = (props) => (
  <Grid container>
    <Grid item xs={7}>
      <TextField
        variant="outlined"
        style={props.labelStyle}
        InputProps={props.InputLableFieldProps}
        value={props.labelValue}
        disabled
      />
    </Grid>
    <Grid item xs={5}>
      <TextField
        name={props.name}
        variant="outlined"
        style={props.valueStyle}
        value={props.value}
        InputProps={props.InputValueFieldProps}
        disabled={props.disabled}
        onChange={props.onChange}
      />
    </Grid>
  </Grid>
);

const GenerateInvoiceMobileUI = ({
  billedByDetails,
  taxOptions,
  handleInputBlur,
  handleChangeBillerInputs,
  errors,
  setBilledByDetails,
  handleBillingDateChange,
  handleDueDateChange,
  handleScroll,
  billedToDetails,
  setBilledToDetails,
  handleChangeClientInputs,
  billingInputValues,
  handleDeleteBillingInfo,
  handleAddBillingInfo,
  handleChangeTaxInputs,
  totalTaxableAmount,
  totalTaxAmount,
  totalAmountIncGst,
  termAndConditions,
  addNotes,
  handleTermsConditionsAndNotes,
  handleSubmit,
  handleChangeBookingID,
  bookingID,
  handleSearch,
  invoiceId,
}) => {
  const classes = useStyles();
  const [expandedAccordion, setExpandedAccordion] = useState(0);

  const handleAccordionChange = (index) => {
    setExpandedAccordion(index);
  };
  return (
    <div className={classes.root}>
      <>
        <h2> New Invoice</h2>
        <Box>
          <TextField
            name="bookingId"
            onChange={handleChangeBookingID}
            value={bookingID}
            type="search"
            variant="outlined"
            InputProps={{
              style: {
                height: "40px",
                borderRadius: "10px 0px 0px 10px",
              },
            }}
            style={{ width: "80%" }}
            placeholder="Booking ID"
            onBlur={handleInputBlur}
            error={Boolean(errors.bookingId)}
            helperText={errors.bookingId}
          />
          <IconButton
            style={{
              height: "40px",
              position: "absolute",
              width: "70px",
              borderRadius: "0px 10px 10px 0px",
              fontSize: "40px",
              background: "#343A40",
            }}
            onClick={handleSearch}
          >
            <SearchSharp style={{ color: "#f46d25" }} />
          </IconButton>
        </Box>
        <Accordion
          style={{ borderRadius: "6px", boxShadow: "none", paddingTop: "20px" }}
          expanded={expandedAccordion === 0}
          onChange={() => handleAccordionChange(0)}
        >
          <AccordionSummary
            expandIcon={<ExpandMore style={{ color: "#fff" }} />}
            style={{
              background: "#343A40",
              color: "#fff",
              borderRadius: "6px",
            }}
          >
            <Typography>Basic Details</Typography>
          </AccordionSummary>
          <Typography
            style={{
              paddingLeft: "15px",
              fontWeight: "bold",
              marginTop: "10px",
            }}
          >
            Billed By
          </Typography>
          <AccordionDetails
            style={{
              display: "flex",
              flexDirection: "column",
              background: "#EEF1F3",
              borderRadius: "10px",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextField
                  name="businessName"
                  value={billedByDetails.businessName}
                  label="Business Name"
                  variant="outlined"
                  size="small"
                  style={{
                    alignSelf: "flex-start",
                  }}
                  InputProps={{ style: { height: "40px" } }}
                  onChange={handleChangeBillerInputs}
                  onBlur={handleInputBlur}
                  error={Boolean(errors.businessName)}
                  helperText={errors.businessName}
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                      color: "#f46d25",
                      padding: "0px 5px 5px 5px",
                      borderRadius: "5px",
                    },
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="panNo"
                  value={billedByDetails.panNo.toUpperCase()}
                  label="PAN No"
                  variant="outlined"
                  size="small"
                  style={{
                    alignSelf: "flex-start",
                  }}
                  InputProps={{ style: { height: "40px" } }}
                  onChange={handleChangeBillerInputs}
                  onBlur={handleInputBlur}
                  error={Boolean(errors.panNo)}
                  helperText={errors.panNo}
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                      color: "#f46d25",
                      padding: "0px 5px 5px 5px",
                      borderRadius: "5px",
                    },
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <MuiPhoneNumber
                  name="billerContactNo"
                  value={billedByDetails.billerContactNo}
                  label="Contact Number"
                  type="tel"
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    alignSelf: "flex-start",
                  }}
                  InputProps={{ style: { height: "40px" } }}
                  onChange={(value) =>
                    setBilledByDetails({
                      ...billedByDetails,
                      billerContactNo: value,
                    })
                  }
                  onBlur={handleInputBlur}
                  error={Boolean(errors.billerContactNo)}
                  helperText={errors.billerContactNo}
                  defaultCountry="in"
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                    style: { marginLeft: "0%", marginTop: "0%" },
                  }}
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                      color: "#f46d25",
                      padding: "0px 5px 0px 5px",
                      borderRadius: "5px",
                    },
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="billerEmail"
                  value={billedByDetails.billerEmail}
                  label="Email ID"
                  type="email"
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    alignSelf: "flex-start",
                  }}
                  InputProps={{ style: { height: "40px" } }}
                  onChange={handleChangeBillerInputs}
                  onBlur={handleInputBlur}
                  error={Boolean(errors.billerEmail)}
                  helperText={errors.billerEmail}
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                      color: "#f46d25",
                      padding: "0px 5px 5px 5px",
                      borderRadius: "5px",
                    },
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <DatePicker
                    name="billingDate"
                    value={billedByDetails.billingDate}
                    required
                    label="Billing Date"
                    inputVariant="outlined"
                    fullWidth
                    size="small"
                    animateYearScrolling
                    format="dd/MM/yyyy"
                    variant="inline"
                    disablePast="true"
                    autoOk="true"
                    onChange={handleBillingDateChange}
                    onBlur={handleInputBlur}
                    error={Boolean(errors.billingDate)}
                    helperText={errors.billingDate}
                    FormHelperTextProps={{
                      classes: { root: classes.helperText },
                    }}
                    InputLabelProps={{
                      style: {
                        backgroundColor: "white",
                        color: "#f46d25",
                        padding: "0px 5px 5px 5px",
                        borderRadius: "5px",
                      },
                      shrink: true,
                    }}
                  />
                  <DateRangeIcon className={classes.icon} />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <DatePicker
                    name="dueDate"
                    value={billedByDetails.dueDate}
                    required
                    label="Due Date"
                    inputVariant="outlined"
                    fullWidth
                    size="small"
                    animateYearScrolling
                    format="dd/MM/yyyy"
                    variant="inline"
                    disablePast="true"
                    autoOk="true"
                    onChange={handleDueDateChange}
                    onBlur={handleInputBlur}
                    error={Boolean(errors.dueDate)}
                    helperText={errors.dueDate}
                    FormHelperTextProps={{
                      classes: { root: classes.helperText },
                    }}
                    InputLabelProps={{
                      style: {
                        backgroundColor: "white",
                        color: "#f46d25",
                        padding: "0px 5px 5px 5px",
                        borderRadius: "5px",
                      },
                      shrink: true,
                    }}
                  />
                  <DateRangeIcon className={classes.icon} />
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="shippingAddress"
                  value={billedByDetails.shippingAddress}
                  label="Shipping Address"
                  type="text"
                  variant="outlined"
                  size="small"
                  style={{ width: "100%", alignSelf: "flex-start" }}
                  InputProps={{ style: { height: "40px" } }}
                  onChange={handleChangeBillerInputs}
                  onBlur={handleInputBlur}
                  error={Boolean(errors.shippingAddress)}
                  helperText={errors.shippingAddress}
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                      color: "#f46d25",
                      padding: "0px 5px 5px 5px",
                      borderRadius: "5px",
                    },
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="pinCode"
                  value={billedByDetails.pinCode}
                  label="Pincode"
                  type="number"
                  onWheel={handleScroll}
                  variant="outlined"
                  size="small"
                  style={{
                    alignSelf: "flex-start",
                  }}
                  InputProps={{ style: { height: "40px" } }}
                  onChange={handleChangeBillerInputs}
                  onBlur={handleInputBlur}
                  error={Boolean(errors.pinCode)}
                  helperText={errors.pinCode}
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                      color: "#f46d25",
                      padding: "0px 5px 5px 5px",
                      borderRadius: "5px",
                    },
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  name="state"
                  value={billedByDetails.state}
                  label="State"
                  type="text"
                  variant="outlined"
                  size="small"
                  style={{
                    alignSelf: "flex-start",
                  }}
                  InputProps={{ style: { height: "40px" } }}
                  onChange={handleChangeBillerInputs}
                  onBlur={handleInputBlur}
                  error={Boolean(errors.state)}
                  helperText={errors.state}
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                      color: "#f46d25",
                      padding: "0px 5px 5px 5px",
                      borderRadius: "5px",
                    },
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
          <Typography
            style={{
              paddingLeft: "15px",
              fontWeight: "bold",
              marginTop: "10px",
            }}
          >
            Billed To
          </Typography>
          <AccordionDetails
            style={{
              display: "flex",
              flexDirection: "column",
              background: "#EEF1F3",
              borderRadius: "10px",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  name="clientName"
                  value={billedToDetails.clientName}
                  label="Client Name"
                  variant="outlined"
                  size="small"
                  style={{ alignSelf: "flex-start", width: "100%" }}
                  InputProps={{ style: { height: "40px" } }}
                  onChange={handleChangeClientInputs}
                  onBlur={handleInputBlur}
                  error={Boolean(errors.clientName)}
                  helperText={errors.clientName}
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                      color: "#f46d25",
                      padding: "0px 5px 5px 5px",
                      borderRadius: "5px",
                    },
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <MuiPhoneNumber
                  name="clientNumber"
                  value={billedToDetails.clientNumber}
                  label="Contact Number"
                  type="tel"
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",
                    alignSelf: "flex-start",
                  }}
                  InputProps={{ style: { height: "40px" } }}
                  onChange={(value) =>
                    setBilledToDetails({
                      ...billedToDetails,
                      clientNumber: value,
                    })
                  }
                  defaultCountry="in"
                  onBlur={handleInputBlur}
                  error={Boolean(errors.clientNumber)}
                  helperText={errors.clientNumber}
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                    style: { marginLeft: "0%", marginTop: "0%" },
                  }}
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                      color: "#f46d25",
                      padding: "0px 5px 0px 5px",
                      borderRadius: "5px",
                    },
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="clientEmail"
                  value={billedToDetails.clientEmail}
                  label=" Client Email ID"
                  type="email"
                  variant="outlined"
                  size="small"
                  style={{
                    width: "100%",

                    alignSelf: "flex-start",
                  }}
                  InputProps={{ style: { height: "40px" } }}
                  onChange={handleChangeClientInputs}
                  onBlur={handleInputBlur}
                  error={Boolean(errors.clientEmail)}
                  helperText={errors.clientEmail}
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                      color: "#f46d25",
                      padding: "0px 5px 5px 5px",
                      borderRadius: "5px",
                    },
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="gstNo"
                  value={billedToDetails.gstNo}
                  label="GSTN"
                  type="text"
                  variant="outlined"
                  size="small"
                  style={{
                    alignSelf: "flex-start",
                  }}
                  InputProps={{ style: { height: "40px" } }}
                  onChange={handleChangeClientInputs}
                  onBlur={handleInputBlur}
                  error={Boolean(errors.gstNo)}
                  helperText={errors.gstNo}
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                      color: "#f46d25",
                      padding: "0px 5px 5px 5px",
                      borderRadius: "5px",
                    },
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="clientPanNo"
                  value={billedToDetails.clientPanNo}
                  label=" PAN No"
                  type="text"
                  variant="outlined"
                  size="small"
                  style={{
                    alignSelf: "flex-start",
                  }}
                  InputProps={{ style: { height: "40px" } }}
                  onChange={handleChangeClientInputs}
                  onBlur={handleInputBlur}
                  error={Boolean(errors.clientPanNo)}
                  helperText={errors.clientPanNo}
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                      color: "#f46d25",
                      padding: "0px 5px 5px 5px",
                      borderRadius: "5px",
                    },
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="billingAddress"
                  value={billedToDetails.billingAddress}
                  label="Billing Address"
                  type="text"
                  variant="outlined"
                  size="small"
                  style={{ width: "100%", alignSelf: "flex-start" }}
                  InputProps={{ style: { height: "40px" } }}
                  onChange={handleChangeClientInputs}
                  onBlur={handleInputBlur}
                  error={Boolean(errors.billingAddress)}
                  helperText={errors.billingAddress}
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                      color: "#f46d25",
                      padding: "0px 5px 5px 5px",
                      borderRadius: "5px",
                    },
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="clientPinCode"
                  value={billedToDetails.clientPinCode}
                  label="Pincode"
                  onWheel={handleScroll}
                  type="number"
                  variant="outlined"
                  size="small"
                  style={{
                    alignSelf: "flex-start",
                  }}
                  InputProps={{ style: { height: "40px" } }}
                  onChange={handleChangeClientInputs}
                  onBlur={handleInputBlur}
                  error={Boolean(errors.clientPinCode)}
                  helperText={errors.clientPinCode}
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                      color: "#f46d25",
                      padding: "0px 5px 5px 5px",
                      borderRadius: "5px",
                    },
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="clientState"
                  value={billedToDetails.clientState}
                  label="State"
                  type="text"
                  variant="outlined"
                  size="small"
                  style={{
                    alignSelf: "flex-start",
                  }}
                  InputProps={{ style: { height: "40px" } }}
                  onChange={handleChangeClientInputs}
                  onBlur={handleInputBlur}
                  error={Boolean(errors.clientState)}
                  helperText={errors.clientState}
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                      color: "#f46d25",
                      padding: "0px 5px 5px 5px",
                      borderRadius: "5px",
                    },
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ borderRadius: "6px", boxShadow: "none", paddingTop: "20px" }}
          expanded={expandedAccordion === 1}
          onChange={() => handleAccordionChange(1)}
        >
          <AccordionSummary
            expandIcon={<ExpandMore style={{ color: "#fff" }} />}
            style={{
              background: "#343A40",
              color: "#fff",
              borderRadius: "6px",
            }}
          >
            <Typography>Billing Info</Typography>
          </AccordionSummary>
          <Typography
            style={{
              paddingLeft: "15px",
              fontWeight: "bold",
              marginTop: "10px",
            }}
          >
            Hotel Details
          </Typography>
          <AccordionDetails
            style={{
              display: "flex",
              flexDirection: "column",
              background: "#EEF1F3",
              borderRadius: "10px",
            }}
          >
            {billingInputValues &&
              billingInputValues.map((bill, i) => (
                <Grid container spacing={1}>
                  <Grid
                    container
                    spacing={1}
                    style={{ margin: "0", padding: "20px 0px" }}
                  >
                    <Grid container>
                      <Grid item xs={6}>
                        {i + 1}.
                      </Grid>
                      <Grid item xs={6} textAlign="end">
                        {billingInputValues.length !== 1 && (
                          <DeleteIcon
                            className={classes.plus}
                            onClick={() => handleDeleteBillingInfo(i)}
                          />
                        )}
                        {billingInputValues.length - 1 === i && (
                          <AddCircleOutlineIcon
                            onClick={handleAddBillingInfo}
                            className={classes.plus}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={7}>
                      <TextField
                        name="bookingItem"
                        type="text"
                        label="Item Description"
                        value={bill.bookingItem}
                        InputProps={{ style: { height: "40px" } }}
                        variant="outlined"
                        onChange={(e) => handleChangeTaxInputs(i, e)}
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 5px 5px",
                            borderRadius: "5px",
                          },
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        name="code"
                        label="HSN/SASC Code"
                        value={bill.code}
                        InputProps={{ style: { height: "40px" } }}
                        variant="outlined"
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 5px 5px",
                            borderRadius: "5px",
                            fontSize: "13px",
                          },
                          shrink: true,
                        }}
                        onChange={(e) => handleChangeTaxInputs(i, e)}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        name="rate"
                        label="Rate"
                        value={bill.rate}
                        type="number"
                        onWheel={handleScroll}
                        InputProps={{ style: { height: "40px" } }}
                        variant="outlined"
                        onChange={(e) =>
                          handleChangeTaxInputs(i, e, bill.quantity)
                        }
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 5px 5px",
                            borderRadius: "5px",
                          },
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        name="quantity"
                        label="Quantity"
                        value={bill.quantity}
                        type="number"
                        onWheel={handleScroll}
                        InputProps={{ style: { height: "40px" } }}
                        variant="outlined"
                        onChange={(e) => handleChangeTaxInputs(i, e, bill.rate)}
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 5px 5px",
                            borderRadius: "5px",
                          },
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        name="taxableAmt"
                        label="Taxable Amount"
                        type="number"
                        onWheel={handleScroll}
                        value={bill.taxableAmt}
                        InputProps={{ style: { height: "40px" } }}
                        variant="outlined"
                        disabled
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 5px 5px",
                            borderRadius: "5px",
                          },
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        style={{ width: "100%" }}
                      >
                        <InputLabel
                          shrink
                          style={{
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 5px 5px",
                            borderRadius: "5px",
                          }}
                        >
                          Tax %
                        </InputLabel>
                        <Select
                          name="taxPercentValue"
                          value={bill.taxPercentValue}
                          label="Tax %"
                          style={{
                            height: "40px",
                            width: "100%",
                            fontSize: "13px",
                          }}
                          onChange={(e) =>
                            handleChangeTaxInputs(i, e, bill.taxableAmt)
                          }
                        >
                          {taxOptions.map((tax) => (
                            <MenuItem
                              value={tax.value}
                              style={{ fontSize: "13px" }}
                            >
                              {tax.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {billedByDetails.state.toLowerCase() !==
                    billedToDetails.clientState.toLowerCase() ? (
                      <Grid item xs={12}>
                        <TextField
                          name="igst"
                          label="IGST"
                          value={bill.igst}
                          type="number"
                          disabled
                          onWheel={handleScroll}
                          InputProps={{ style: { height: "40px" } }}
                          style={{ width: "100%", marginRight: "15px" }}
                          variant="outlined"
                          InputLabelProps={{
                            style: {
                              backgroundColor: "white",
                              color: "#f46d25",
                              padding: "0px 5px 5px 5px",
                              borderRadius: "5px",
                            },
                            shrink: true,
                          }}
                        />
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={6}>
                          <TextField
                            name="cgst"
                            label="CGST"
                            value={bill.cgst}
                            type="number"
                            onWheel={handleScroll}
                            InputProps={{ style: { height: "40px" } }}
                            variant="outlined"
                            disabled
                            InputLabelProps={{
                              style: {
                                backgroundColor: "white",
                                color: "#f46d25",
                                padding: "0px 5px 5px 5px",
                                borderRadius: "5px",
                              },
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            name="sgst"
                            label="SGST"
                            value={bill.sgst}
                            type="number"
                            disabled
                            onWheel={handleScroll}
                            InputProps={{ style: { height: "40px" } }}
                            variant="outlined"
                            InputLabelProps={{
                              style: {
                                backgroundColor: "white",
                                color: "#f46d25",
                                padding: "0px 5px 5px 5px",
                                borderRadius: "5px",
                              },
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12}>
                      <TextField
                        name="amountIncGst"
                        label="Amount (Inc. GST)"
                        value={bill.amountIncGst}
                        disabled
                        type="number"
                        InputProps={{ style: { height: "40px" } }}
                        variant="outlined"
                        style={{ width: "100%" }}
                        InputLabelProps={{
                          style: {
                            backgroundColor: "white",
                            color: "#f46d25",
                            padding: "0px 5px 5px 5px",
                            borderRadius: "5px",
                          },
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </AccordionDetails>
          <AccordionDetails
            style={{
              display: "flex",
              flexDirection: "column",
              background: "#EEF1F3",
              borderRadius: "10px",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "200px",
                justifyContent: "space-between",
              }}
            >
              <DualTextField
                labelValue="Total Taxable Amount"
                labelStyle={{ fontSize: "12px" }}
                value={parseFloat(totalTaxableAmount)}
                InputLableFieldProps={{
                  style: {
                    border: "1px solid #f46d25",
                    height: "40px",
                    fontSize: "13px",
                  },
                }}
                InputValueFieldProps={{
                  style: {
                    backgroundColor: "#ffe2d7",
                    fontWeight: "bold",
                    height: "40px",
                    border: "1px solid #f46d25",
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                  },
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
                disabled
              />
              <DualTextField
                labelValue="Total Tax"
                labelStyle={{ fontSize: "12px" }}
                value={parseFloat(totalTaxAmount)}
                InputLableFieldProps={{
                  style: {
                    border: "1px solid #f46d25",
                    height: "40px",
                    fontSize: "13px",
                  },
                }}
                InputValueFieldProps={{
                  style: {
                    backgroundColor: "#ffe2d7",
                    fontWeight: "bold",
                    height: "40px",
                    border: "1px solid #f46d25",
                  },
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
                disabled
              />
              <DualTextField
                labelValue="Total Amount Inc GST"
                value={parseFloat(totalAmountIncGst)}
                InputLableFieldProps={{
                  style: {
                    border: "1px solid #f46d25",
                    height: "40px",
                    fontSize: "13px",
                  },
                }}
                InputValueFieldProps={{
                  style: {
                    backgroundColor: "#ffe2d7",
                    fontWeight: "bold",
                    height: "40px",
                    border: "1px solid #f46d25",
                  },
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
                disabled
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ borderRadius: "6px", boxShadow: "none" }}
          expanded={expandedAccordion === 2}
          onChange={() => handleAccordionChange(2)}
        >
          <AccordionSummary
            expandIcon={<ExpandMore style={{ color: "#fff" }} />}
            style={{
              background: "#343A40",
              color: "#fff",
              borderRadius: "6px",
            }}
          >
            <Typography>T&C Policy</Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              display: "flex",
              flexDirection: "column",
              background: "#EEF1F3",
              borderRadius: "10px",
              paddingTop: "20px",
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  name="termsAndConditions"
                  value={termAndConditions}
                  onChange={handleTermsConditionsAndNotes}
                  label="T&C"
                  placeholder="Terms & Conditions"
                  variant="outlined"
                  style={{ width: "100%" }}
                  InputProps={{
                    style: { height: "80px", paddingTop: "40px" },
                  }}
                  multiline
                  rows={4}
                  onBlur={handleInputBlur}
                  error={Boolean(errors.termsAndConditions)}
                  helperText={errors.termsAndConditions}
                  FormHelperTextProps={{
                    classes: { root: classes.helperText },
                  }}
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                      color: "#f46d25",
                      padding: "0px 5px 5px 5px",
                      borderRadius: "5px",
                    },
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="addNotes"
                  value={addNotes}
                  onChange={handleTermsConditionsAndNotes}
                  label="Notes"
                  placeholder="Add Notes"
                  variant="outlined"
                  InputProps={{ style: { height: "60px" } }}
                  style={{
                    width: "100%",
                    marginTop: "20px",
                  }}
                  InputLabelProps={{
                    style: {
                      backgroundColor: "white",
                      color: "#f46d25",
                      padding: "0px 5px 5px 5px",
                      borderRadius: "5px",
                    },
                    shrink: true,
                  }}
                  multiline
                  rows={2}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
      <div style={{ textAlign: "end", paddingTop: "20px" }}>
        <Button onClick={handleSubmit}>
          {invoiceId ? "Update" : "Generate"} Invoice
        </Button>
      </div>
    </div>
  );
};

export default GenerateInvoiceMobileUI;
