import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import {
  Button,
  Card,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core"
import { AddAPhotoRounded } from "@material-ui/icons"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import { Grid } from "@mui/material"
import { Field, FieldArray, Form, Formik } from "formik"
import { useEffect, useState } from "react"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import ScreenLoader from "../../components/loadingScreen/screenLoader.component.js"
import API from "../../Service/Api"
import { baseurl } from "../../Service/httpCommon"
import Thumb from "../Staff/Thumb"

const useStyles = makeStyles(() => ({
  root: {
    margin: "0px",
    padding: "60px 20px 20px 25px ",
  },
  sectionHeader: {
    color: "#f46d25",
    marginBottom: "20px",
  },
  inputLabelProps: {
    color: "#000000",
  },
  cardStyles: {
    height: "165px",
    width: "100%",
    border: "1px solid #C4C4C4",
    borderRadius: "3px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#EEF1F3",
    "&:hover": {
      cursor: "pointer",

      "& img": {
        opacity: "0.4",
        transform: "scale(1.1)",
        transition: "transform 6s ease-in",
      },
      "& label": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        cursor: "pointer",
      },
    },
  },
  labelStyling: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
  },
  labelStyling2: {
    display: "none",
  },
}))

const ItineraryBuilder = () => {
  const classes = useStyles()
  const { itineraryId } = useParams()
  const history = useHistory()
  const unique_id = localStorage.getItem("unique_id")
  const auth = localStorage.getItem("auth")
  const [itineraryData, setItineraryData] = useState({
    itineraryName: "",
    itineraryDestination: "",
    itineraryDataRequest: [{ id: "", data: "", imagePath: null, type: "" }],
  })
  const [deletedRecords, setDeletedRecords] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchEditItineraryData = async () => {
    setLoading(true)
    try {
      const { data } = await API.get(`getitinerary/${unique_id}/${itineraryId}`)
      console.log(data)
      setItineraryData(data)
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    return itineraryId && fetchEditItineraryData()
  }, [])

  const onSubmit = async (fields) => {
    const postData1 = {
      id: itineraryId ? itineraryId : "",
      itineraryName: fields.itineraryName,
      itineraryDestination: fields.itineraryDestination,
      uniqueId: unique_id,
      createdBy: auth,
    }
    console.log(postData1)
    let itineraryDataRequest = fields.itineraryDataRequest
    console.log(itineraryDataRequest)

    console.log(deletedRecords)

    try {
      const itineraryResponse = await API.post("createitinerary", postData1)
      console.log(itineraryResponse.data)
      itineraryDataRequest.forEach(async (itinerary, idx) => {
        console.log(itinerary)
        let formData = new FormData()
        formData.append("id", itinerary.id ? itinerary.id : 0)
        formData.append("uniqueId", unique_id)
        formData.append("data", itinerary.data)
        formData.append("imagePath", itinerary.imagePath)
        formData.append(
          "itineraryId",
          itineraryId ? itineraryId : itineraryResponse.data
        )
        formData.append("type", idx + 1)
        formData.append("createdby", auth)
        try {
          const response = await API.post("createitinerarydata", formData)
          console.log(response)
        } catch (error) {
          toast.error(error.message)
        }
      })

      /**API for deleted records for existing itinerary starts */
      itineraryId &&
        deletedRecords &&
        deletedRecords.forEach(async (record) => {
          const { id } = record
          const res = await API.delete(
            `deleteitinerary/${unique_id}/${itineraryId}/${id}`
          )
        })
      /**API for deleted records for existing itinerary ends*/

      toast.success(itineraryId ? "Itinerary Updated" : "New Itinerary Created")
      history.push(`/itineraryList`)
    } catch (error) {
      toast.error(error.message)
    }
    // API.post("createitinerary", postData1)
    //   .then((res) => {
    //     console.log(res.data);

    //     itineraryDataRequest.forEach((element, index) => {
    //       let formData = new FormData();
    //       formData.append("id", element.id ? element.id : 0);
    //       formData.append("uniqueId", unique_id);
    //       formData.append("data", element.data);
    //       formData.append("imagePath", element.imagePath);
    //       formData.append("itineraryId", res.data);
    //       formData.append("createdby", auth);

    //       API.post("createitinerarydata", formData)
    //         .then((res) => console.log(res.data))
    //         .catch((error) => console.error(error));
    //     });

    //     setSubmitting(false);
    //     toast.success(
    //       itineraryId ? "Itinerary Updated" : "New Itinerary Created"
    //     );
    //     history.push(`/itineraryList`);
    //   })
    //   .catch((error) => {
    //     setSubmitting(false);
    //     toast.error(error.message);
    //   });
  }

  return (
    <section className={classes.root}>
      <h2 className={classes.sectionHeader}>Itinerary Builder</h2>
      <Formik
        initialValues={itineraryData}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, isSubmitting }) =>
          loading ? (
            <ScreenLoader open={loading} />
          ) : (
            <Form autoComplete="off">
              <Grid container>
                <Grid item container xs={12} spacing={2} marginBottom="50px">
                  <Grid item xs={3}>
                    <Field
                      as={TextField}
                      name="itineraryName"
                      label="Itinerary Name"
                      variant="outlined"
                      value={values.itineraryName}
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                        className: classes.inputLabelProps,
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      as={TextField}
                      name="itineraryDestination"
                      label="Destination"
                      variant="outlined"
                      value={values.itineraryDestination}
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                        className: classes.inputLabelProps,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <FieldArray
                name="itineraryDataRequest"
                render={(arrayHelpers) => (
                  <Grid container>
                    {values.itineraryDataRequest.map((itinerary, idx) => (
                      <Grid item container xs={12} key={idx}>
                        <Grid
                          item
                          container
                          xs={12}
                          alignItems="center"
                          justifyContent="space-between"
                          display="flex"
                          marginBottom={2}
                        >
                          <div style={{ fontWeight: "bold" }}>
                            Day {idx + 1}
                          </div>
                          {values.itineraryDataRequest.length !== 1 && (
                            <>
                              <IconButton
                                onClick={() => {
                                  arrayHelpers.remove(idx)
                                  let deletedItem =
                                    values.itineraryDataRequest.find(
                                      (item, index) => index == idx
                                    )
                                  setDeletedRecords([
                                    ...deletedRecords,
                                    deletedItem,
                                  ])
                                }}
                              >
                                <DeleteForeverIcon color="primary" />
                              </IconButton>
                            </>
                          )}
                        </Grid>

                        <Grid
                          item
                          container
                          xs={12}
                          spacing={1}
                          alignItems="center"
                          justifyContent="center"
                          border="1px solid #C4C4C4"
                          borderRadius="5px"
                          padding="0px 7px 8px 0px"
                        >
                          <Grid
                            item
                            container
                            xs={2}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                            spacing={2}
                          >
                            <Grid
                              item
                              xs={12}
                              textAlign="center"
                              fontWeight={`bold`}
                            >
                              Itinerary Photo
                            </Grid>
                            <Grid item xs={12}>
                              <Card className={classes.cardStyles}>
                                {itinerary.imagePath &&
                                typeof itinerary.imagePath == "object" &&
                                itinerary.imagePath != null ? (
                                  <Thumb
                                    file={itinerary.imagePath}
                                    height="100%"
                                    width="100%"
                                  />
                                ) : (
                                  typeof itinerary.imagePath == "string" && (
                                    <img
                                      src={`${baseurl}getimage/${itinerary?.imagePath}`}
                                      alt="Itinerary"
                                      height="100%"
                                      width="100%"
                                    />
                                  )
                                )}

                                <input
                                  type="file"
                                  accept="image/*"
                                  id={`imageInput_${idx}`}
                                  name={`itineraryDataRequest[${idx}].imagePath`}
                                  style={{ display: "none" }}
                                  onChange={(e) => {
                                    console.log(e.currentTarget.files[0])
                                    setFieldValue(
                                      `itineraryDataRequest[${idx}].imagePath`,
                                      e.target.files[0]
                                    )
                                  }}
                                />
                                <label
                                  htmlFor={`imageInput_${idx}`}
                                  className={
                                    itinerary.imagePath != null
                                      ? classes.labelStyling2
                                      : classes.labelStyling
                                  }
                                >
                                  <IconButton
                                    style={{
                                      cursor: "pointer",
                                      padding: "0",
                                    }}
                                    variant="raised"
                                    component="span"
                                  >
                                    <AddAPhotoRounded
                                      color="primary"
                                      style={{
                                        visibility: "visible",
                                        zIndex: "10000",
                                      }}
                                    />
                                  </IconButton>
                                  {itinerary.imagePath != null && (
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Change Image
                                    </p>
                                  )}
                                </label>
                              </Card>
                            </Grid>
                          </Grid>
                          <Grid item xs={10}>
                            <Field
                              as={CKEditor}
                              editor={ClassicEditor}
                              name={`itineraryDataRequest[${idx}].data`}
                              data={itinerary.data}
                              onReady={(editor) => {
                                editor.editing.view.change((writer) => {
                                  writer.setStyle(
                                    "height",
                                    "165px",
                                    editor.editing.view.document.getRoot()
                                  )
                                })
                              }}
                              onChange={(e, editor) => {
                                const data = editor.getData()
                                console.log(data)
                                setFieldValue(
                                  `itineraryDataRequest[${idx}].data`,
                                  data
                                )
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                    <Grid
                      item
                      container
                      xs={12}
                      display="flex"
                      justifyContent="center"
                      marginTop="30px"
                    >
                      <Button
                        style={{ background: "#000000" }}
                        onClick={() =>
                          arrayHelpers.push({ data: "", imagePath: null })
                        }
                        size="small"
                      >
                        Add Another Day
                      </Button>
                      <Button
                        size="small"
                        type="submit"
                        style={{ marginLeft: "15px" }}
                        disabled={isSubmitting}
                      >
                        {itineraryId ? "Update Itinerary" : "Continue"}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              />
            </Form>
          )
        }
      </Formik>
    </section>
  )
}

export default ItineraryBuilder
