import { makeStyles } from "@material-ui/core/styles"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import DeleteIcon from "@material-ui/icons/Delete"
import { Box, Grid, Stack, TextField } from "@mui/material"
import React, { useEffect } from "react"
import Select, { components } from "react-select"

const { ValueContainer, Placeholder } = components
const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  )
}

const useStyles = makeStyles(() => ({
  root: {
    padding: "5% 0.5% 1% 1.5%",
    "@media (max-width: 767px)": {
      margin: "0px 10px",
    },
  },
  plus: {
    cursor: "pointer",
    color: "#f46d25",
    position: "relative",
    top: "7px",
    "@media (max-width: 767px)": {
      fontSize: "18px",
    },
  },
  title: {
    fontWeight: "600",
    fontSize: "18px",
    color: "#f46d25",
    margin: "0px",
  },
}))

export default function BasicDetailPage({
  tabValue,
  setOpen,
  Clientoptions,
  basicInput,
  basicInputError,
  handleRemoveClickBasic,
  handleAddClickBasic,
  handleBasicChange,
  handleChangeClient,
  transferId,
  defaultvalue,
  clientLists,
  bookingDetail,
  bookingId,
}) {
  const classes = useStyles()

  useEffect(async () => {
    //Handling the client change
    const handleChange = (e) => {
      handleChangeClient(e, defaultvalue)
    }
    console.log("basic details", sessionStorage.getItem("mobile"))
    if (
      sessionStorage.getItem("mobile") !== null &&
      (await clientLists.length) > 0 &&
      !transferId
    ) {
      handleChange()
      sessionStorage.setItem("mobile", null)
    }
  }, [defaultvalue])

  return (
    <Stack style={{ padding: "0.4em 0.5em" }}>
      {basicInput.map((x, i) => (
        <Grid container spacing={2} key={i}>
          <Grid item sm={6}>
            {i == 0 && <p className={classes.title}>Basic Details</p>}
          </Grid>

          <Grid item sm={6} style={{ textAlign: "end", fontSize: "14px" }}>
            {basicInput.length !== 1 && i > 0 && (
              <DeleteIcon
                onClick={() => handleRemoveClickBasic(i)}
                className={classes.plus}
              />
            )}
            {basicInput.length - 1 === i && (
              <AddCircleOutlineIcon
                onClick={handleAddClickBasic}
                size="small"
                className={classes.plus}
              />
            )}
          </Grid>

          <div
            style={{
              width: "2%",
              textAlign: "center",
              marginTop: "1em",
              marginLeft: "5px",
            }}
          >
            {" "}
            {i + 1 + "."}
          </div>
          <div style={{ width: "97%", marginTop: "1em" }}>
            <Box>
              <Grid container spacing={2}>
                {i === 0 ? (
                  transferId || bookingId ? (
                    <Grid item xs={3}>
                      <CustomBasic
                        name="clientName"
                        label="Client Name"
                        value={x.clientName}
                        onChange={(e) => handleBasicChange(e, i)}
                        disabled={true}
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={2.7}>
                        <Select
                          isSearchable
                          name="clientName"
                          placeholder="Client Name"
                          value={Clientoptions.filter((i) => {
                            return i.value === x.clientName
                          })}
                          options={Clientoptions}
                          onChange={(e) => handleChangeClient(i, e)}
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                            control: (base, state) => ({
                              ...base,
                              "&:hover": { borderColor: "#f46d25" },
                              borderColor: "#f46d25",
                              boxShadow: "none",
                              width: "100%",
                            }),
                            placeholder: (provided, state) => ({
                              ...provided,
                              position: "relative",
                              top:
                                state.hasValue || state.selectProps.inputValue
                                  ? -10
                                  : "50%",
                              background: "#fff",
                              padding: "0px 5px",
                              transition: "top 0.1s, font-size 0.1s",
                              fontSize: "14px",
                            }),
                            container: (provided) => ({
                              ...provided,
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              overflow: "visible",
                            }),
                          }}
                        />
                        <span style={{ color: "#ff0000" }}>
                          {basicInputError[i]["clientName"]}
                        </span>
                      </Grid>
                      <Grid item xs={0.3}>
                        <AddCircleOutlineIcon
                          style={{
                            fontSize: "20px",
                            color: "#f46d25",
                            cursor: "pointer",
                            paddingTop: "0.5em",
                          }}
                          onClick={() => {
                            setOpen(true)
                          }}
                        />
                      </Grid>
                    </>
                  )
                ) : (
                  <Grid item xs={3}>
                    <CustomBasic
                      name="clientName"
                      label="Client Name"
                      value={x.clientName}
                      onChange={(e) => handleBasicChange(e, i)}
                      tabValue={tabValue}
                    />
                  </Grid>
                )}
                {i === 0 ? (
                  <Grid item xs={3}>
                    <CustomBasic
                      name="clientMobileNo"
                      label="Mobile No"
                      value={x.clientMobileNo}
                      disabled={true}
                      tabValue={tabValue}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={3}>
                    <CustomBasic
                      name="clientMobileNo"
                      label="Mobile No"
                      value={x.clientMobileNo}
                      onChange={(e) => handleBasicChange(e, i)}
                      tabValue={tabValue}
                    />
                  </Grid>
                )}

                {i === 0 ? (
                  <Grid item xs={3}>
                    <CustomBasic
                      name="clientEmail"
                      label="Email"
                      value={x.clientEmail}
                      variant="outlined"
                      disabled={true}
                      tabValue={tabValue}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={3}>
                    <CustomBasic
                      name="clientEmail"
                      label="Email"
                      value={x.clientEmail}
                      onChange={(e) => handleBasicChange(e, i)}
                      tabValue={tabValue}
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <CustomBasic
                    name="clientAltNo"
                    label="Alternate No"
                    value={x.clientAltNo}
                    onChange={(e) => handleBasicChange(e, i)}
                    tabValue={tabValue}
                  />
                </Grid>
              </Grid>
            </Box>
          </div>
        </Grid>
      ))}
    </Stack>
  )
}

export function CustomBasic(props) {
  let hasAdmin = localStorage.getItem("role")
  let checkAgent = localStorage.getItem("agent")
  return (
    <>
      {hasAdmin === "Agent Admin" || checkAgent === "Agent" ? (
        props.tabValue != 2 ? (
          <TextField
            name={props.name}
            fullWidth
            label={props.label}
            value={props.value}
            variant="outlined"
            size="small"
            sx={{
              "& .MuiInputBase-input": { height: 0 },
              backgroundColor: "#fff",
            }}
            onChange={props.onChange}
            disabled={props.disabled}
            InputProps={{
              style: { height: window.innerWidth <= 768 && "40px" },
            }}
          />
        ) : (
          <TextField
            name={props.name}
            fullWidth
            label={props.label}
            value={props.value}
            variant="outlined"
            size="small"
            style={{ backgroundColor: "#fff" }}
            onChange={props.onChange}
            disabled={props.disabled}
            InputProps={{
              style: { height: window.innerWidth <= 768 && "40px" },
            }}
          />
        )
      ) : (
        <TextField
          name={props.name}
          fullWidth
          label={props.label}
          value={props.value}
          variant="outlined"
          size="small"
          style={{ backgroundColor: "#fff" }}
          onChange={props.onChange}
          disabled={props.disabled}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ style: { height: window.innerWidth <= 768 && "40px" } }}
        />
      )}
    </>
  )
}

CustomBasic.defaultProps = {
  disabled: false,
  tabValue: 0,
}
