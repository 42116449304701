import React, { useEffect, useState } from 'react'
import { filter } from "lodash";
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  IconButton,
  Tooltip,
  Switch,
  Typography,
} from "@material-ui/core";

import "jspdf-autotable";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import Api from "../../../Service/Api";
import { baseurl } from "../../../Service/httpCommon";
import { Box, Button, Grid, InputBase, Paper, TableHead } from '@mui/material';
import { toast } from 'react-toastify';
import AddandEditFavouriteDestination from './AddandEditFavouriteDestination';


const TABLE_HEAD = [
  { id: "profile", label: "Image",align: "left", minWidth: 100 },
  { id: "destination", label: "Destination",align: "left", minWidth: 100 },
  {
    id: "action",
    label: "Action",
    minWidth: 80,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const FavouriteDestination = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [searchValue,setSearchValue]=useState("")
  const [favouriteDestinationsData, setFavouriteDestinationData] = useState([]);

  
  const FavouriteDestinationAll = () => {
    Api.get("allFavouriteDestinations").then((res) => {
        setFavouriteDestinationData(res.data);
    });
  };

  const OpenDialog = (data) => {
    setOpen(true);
    // eslint-disable-next-line eqeqeq
    if (data.id !== "" && data.id !== undefined && data.id !== null) {
      setSelectedId(data.id);
    }
  };

  const CloseDialog = () => {
    setOpen(false);
    FavouriteDestinationAll();
    setSelectedId("")
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  

  const ActivateOrDeactivatePopularPackages=(id)=>{
    Api.get(`activateOrDecativateFavouriteDestinations/${id}`).then((res) => {
      if(res.status===200){
        FavouriteDestinationAll()
      }
      if(res.data.status===true){
        toast.success("Activated Successfully")
        FavouriteDestinationAll()
      }
      if(res.data.status===false){
        toast.success("Deactivated Successfully")
        FavouriteDestinationAll()
      }
    })
  }

  useEffect(() => {
    FavouriteDestinationAll();
  }, []);


 
  return (
    <div style={{ padding: "5% 1% 1% 1%" }}>
      <Box>
        <Grid sx={{alignItems:"center"}} container spacing={1}>
          <Grid item md={9} sm={12} xs={12}>
          <p  style={{fontWeight:"bold",fontSize:"22px",color:"#f46d25"}}>
            Favourite Destination
          </p>
          </Grid>
          <Grid item md={3} sm={12} xs={12}>
            <Box sx={{display:"flex",flexWrap:"wrap",gap:"10px"}} >
            <Box>
            <Paper
              sx={{
                display: "flex",
                alignItems: "center",
                width: 250,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search google maps" }}
                value={searchValue}
                onChange={(e) => {
                  // console.log(e.target.value)
                  setSearchValue(e.target.value);
                }}
              />
              <IconButton type="button" aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            </Box>
            <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={()=>OpenDialog()}
                  style={{backgroundColor:"#f46d25",padding:"8px 15px"}}
                >
                  Add New
                </Button>
            </Box>
          </Box>
          </Grid>
        </Grid>

        {/* -------------------Table--------------- */}
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            mt: "10px",
            backgroundColor: "#f7f7fc",
          }}
          className="scrollbar"
        >
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        backgroundColor: "#f46d25",
                        fontWeight: 600,
                        color: "#FFFFFF",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {/* {loader && (
                <>
                  <Loader />
                </>
              )} */}
              { Object.keys(favouriteDestinationsData).length > 0 &&  (
                <TableBody>
                  {favouriteDestinationsData
                    .filter((item) => {
                      const searchTerm = searchValue.toLowerCase();
                      return (
                        item.city.toLowerCase().includes(searchTerm)
                      );
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, id) => {
                      return (
                        <TableRow
                          sx={{ cursor: "pointer", backgroundColor: "#FFFFFF" }}
                          key={item.id}
                          tabIndex={-1}
                        >
                          <TableCell
                            align="left"
                            sx={{ color: "#444444", fontSize: "14px" }}
                          >
                            <img
                            src={`${baseurl}getimage/${item.image}`}
                            alt="profile"
                            style={{
                              width: "150px",
                              height: "80px",
                            }}
                          />
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ color: "#444444", fontSize: "14px" }}
                          >
                            {item.city}
                          </TableCell>
                          <TableCell align="left" sx={{ color: "#D83F87" }}>
                            {item.status && <IconButton
                            aria-label="edit"
                            onClick={() => {
                              OpenDialog(item);
                            }}
                            style={{ padding: "0px", color: "#F46D25" }}
                          >
                            <EditIcon />
                          </IconButton>}
                          <Tooltip title="Active">
                          <Switch
                            color="primary"
                            size="small"
                            checked={item.status}
                            onChange={() => {
                              ActivateOrDeactivatePopularPackages(item.id);
                            }}
                          />
                        </Tooltip>
                            
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={favouriteDestinationsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ backgroundColor: "#FFFFFF" }}
          />
        </Paper>
      </Box>
      <AddandEditFavouriteDestination 
        open={open}
        onClose={CloseDialog}
        selectedId={selectedId}
      />
  </div>
  )
}

export default FavouriteDestination

