import styles from "./FooterLinks.module.css"
import { ReactComponent as TownoLogo } from "../../assets/logo/towno.fontImage.svg"
import { NavLink } from "react-router-dom/cjs/react-router-dom.min"
import { format } from "date-fns"

import { ReactComponent as FbIcon } from "../../assets/icons/header-icons/facebook.svg"
import { ReactComponent as InstaIcon } from "../../assets/icons/header-icons/instagram.svg"
import { ReactComponent as TwitterIcon } from "../../assets/icons/header-icons/twitter.svg"
import { ReactComponent as YoutubeIcon } from "../../assets/icons/header-icons/youtube.svg"

const footerLinks = [
  { name: "Home", path: "/" },
  { name: "Privacy Policy", path: "/privacy" },
  { name: "Terms and Conditions", path: "/terms" },
  { name: "Refund Policy", path: "/refund" },
  { name: "Inner Circle", path: "/innerCircle", target: "_blank" },
]

const MEDIA_INFO = [
  {
    id: 1,
    Component: FbIcon,
    alt: "facebook",
    link: "https://www.facebook.com/ExploreWithTowno",
  },
  {
    id: 2,
    Component: InstaIcon,
    alt: "instagram",
    link: "https://www.instagram.com/explorewithtowno/",
  },
  {
    id: 3,
    Component: TwitterIcon,
    alt: "twitter",
    link: "https://www.twitter.com/",
  },
  {
    id: 4,
    Component: YoutubeIcon,
    alt: "youtube",
    link: "https://www.youtube.com/@explorewithtowno",
  },
]

const FooterLinks = () => {
  return (
    <div className={styles.footerContainer}>
      <footer className={styles.footer}>
        <figure className={styles.figure}>
          <>
            {" "}
            <TownoLogo className={styles.townoLogo} />
          </>
          <figcaption>
            <p>Towno - Unique Experiential Holidays</p>
            <p>
              We offer personalized experiential travel packages hosted by our
              handpicked local experts. Every Towno holiday is customized to
              help you discover places in previously unexplored ways.
            </p>
          </figcaption>
        </figure>
        <ul className={styles.navLinks}>
          {footerLinks.map((link, i) => (
            <li
              key={`${link.name}-${i}`}
              className={link.target && styles.innerCircle}
            >
              <NavLink to={link.path} target={link?.target}>
                {link.name}
              </NavLink>
            </li>
          ))}
        </ul>
        <div className={styles.contactInfo}>
          {/* <p className={styles.address}>
            159 New, Old 159-83, Tilak Road, Dehradun, Uttarakhand - 248001
          </p> */}
          <p className={styles.address}>
            No.1, Nehru Enclave, GMS Road, Ballupur, Dehradun - 248001
          </p>
          <address className={styles.connect}>
            <a href="mailto:hello@towno.in" className={styles.contactMail}>
              hello@towno.in
            </a>
            <a href="tel:+918006800727" className={styles.contactMobile}>
              +91 8006 800 727
            </a>
          </address>
        </div>
      </footer>
      <hr className={styles.horizontalLine} />
      <div className={styles.secondaryContainer}>
        <span>
          © {format(new Date(), "yyyy")} All Right Reserved By Intellego Tech
          Solutions Pvt Ltd.
        </span>
        <span className={styles.iconContainer}>
          {MEDIA_INFO.map(({ Component, id, link }) => (
            <Component
              className={styles.icon}
              key={id}
              onClick={() => window.open(link, "_blank")}
            />
          ))}
        </span>
      </div>
    </div>
  )
}

export default FooterLinks
