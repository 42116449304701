import { Button, Paper, makeStyles } from "@material-ui/core"
import { useEffect, useState } from "react"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import API from "../../Service/Api"
import { Grid } from "@mui/material"
import { toast } from "react-toastify"
import { format } from "date-fns"
import { formatCurrency } from "../../utils/formatNumber"
import ScreenLoader from "../../components/loadingScreen/screenLoader.component"

const useStyles = makeStyles(() => ({
  root: {
    margin: "0px",
    padding: "5rem 1.7rem",
  },
  header: {
    fontSize: "1.6rem",
    fontWeight: "bold",
    color: "#f46d25",
  },
  article: {
    marginTop: "1rem",
  },
  sectionHeader: {
    fontSize: "1.6rem",
    fontWeight: "bold",
    background: "#f46d25",
    padding: "0.4rem 1rem",
    borderRadius: "0.3rem 0.3rem 0rem 0rem",
    color: "#fff",
  },
  paper: {
    borderRadius: "0px 0px 4px 4px",
    padding: "0.4rem 1rem",
  },
}))

const ViewActivity = () => {
  const classes = useStyles()
  const { activityId } = useParams()
  const [activityData, setActivityData] = useState({})
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const fetchActivity = async () => {
    setLoading(true)
    try {
      const res = await API.get(`getActivityBooking/${activityId}`, {})
      if (res.status == 200) {
        setActivityData(res.data)
      }
    } catch (error) {
      toast.error("Unable to fetch the data.")
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    document.title = "View Activity"
    fetchActivity()
  }, [])

  return (
    <section className={classes.root}>
      {loading ? (
        <ScreenLoader open={loading} />
      ) : (
        <>
          <header className={classes.header}>
            {" "}
            #{activityData?.activityId}
          </header>
          <main>
            <article className={classes.article}>
              <header className={classes.sectionHeader}>Basic Details</header>
              <Paper className={classes.paper}>
                <Grid container spacing={1}>
                  <Grid item container lg={4} md={4}>
                    <Grid item lg={3} md={4} fontWeight="bold">
                      Client Name
                    </Grid>
                    <Grid item lg={0.5} md={0.5} fontWeight={"bold"}>
                      :
                    </Grid>
                    <Grid item lg={8.5} md={7.5}>
                      {activityData?.clientName}
                    </Grid>
                  </Grid>
                  <Grid item container lg={4} md={4}>
                    <Grid item lg={3} md={4} fontWeight="bold">
                      Phone No.
                    </Grid>
                    <Grid item lg={0.5} md={0.5} fontWeight={"bold"}>
                      :
                    </Grid>
                    <Grid item lg={8.5} md={7.5}>
                      {activityData?.clientNumber}
                    </Grid>
                  </Grid>{" "}
                  <Grid item container lg={4} md={4}>
                    <Grid item lg={3} md={3} fontWeight="bold">
                      Email
                    </Grid>
                    <Grid item lg={0.5} md={0.5} fontWeight={"bold"}>
                      :
                    </Grid>
                    <Grid
                      item
                      lg={8.5}
                      md={8.5}
                      style={{ wordWrap: "break-word" }}
                    >
                      {activityData?.clientEmail}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </article>
            {activityData?.activityBookingLeadPaxs?.length > 0 && (
              <article className={classes.article}>
                <header className={classes.sectionHeader}>
                  Lead Pax Details
                </header>
                <Paper className={classes.paper}>
                  <Grid container>
                    {activityData.activityBookingLeadPaxs.map((pax) => (
                      <Grid
                        item
                        container
                        md={12}
                        spacing={1}
                        key={pax.activityId}
                      >
                        <Grid item container md={4}>
                          <Grid item md={3} fontWeight="bold">
                            Name
                          </Grid>
                          <Grid item md={0.5} fontWeight="bold">
                            :
                          </Grid>
                          <Grid item md={8.5}>
                            {pax.name}
                          </Grid>
                        </Grid>
                        <Grid item container md={4}>
                          <Grid item md={3} fontWeight="bold">
                            Phone No
                          </Grid>
                          <Grid item md={0.5} fontWeight="bold">
                            :
                          </Grid>
                          <Grid item md={8.5}>
                            {pax.mobile}
                          </Grid>
                        </Grid>
                        <Grid item container md={4}>
                          <Grid item md={4} fontWeight="bold">
                            Alt Mobile
                          </Grid>
                          <Grid item md={0.5} fontWeight="bold">
                            :
                          </Grid>
                          <Grid item md={7.5}>
                            {pax.altMobile}
                          </Grid>
                        </Grid>
                        <Grid item container md={4}>
                          <Grid item md={3} fontWeight="bold">
                            Email
                          </Grid>
                          <Grid item md={0.5} fontWeight="bold">
                            :
                          </Grid>
                          <Grid
                            item
                            md={8.5}
                            style={{ wordWrap: "break-word" }}
                          >
                            {pax.email}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>
              </article>
            )}
            <article className={classes.article}>
              <header className={classes.sectionHeader}>
                Activity Details
              </header>
              <Paper className={classes.paper}>
                <Grid container>
                  {activityData?.activityBookingActivityDetails?.map(
                    (activity, idx) => (
                      <>
                        <Grid
                          item
                          container
                          md={12}
                          spacing={1}
                          key={activity?.id}
                        >
                          <Grid item container md={4}>
                            <Grid item md={5} fontWeight="bold">
                              Name
                            </Grid>
                            <Grid item md={0.5} fontWeight="bold">
                              :
                            </Grid>
                            <Grid item md={6.5}>
                              {activity?.activityName}
                            </Grid>
                          </Grid>
                          <Grid item container md={4}>
                            <Grid item md={3} fontWeight="bold">
                              Type
                            </Grid>
                            <Grid item md={0.5} fontWeight="bold">
                              :
                            </Grid>
                            <Grid item md={8.5}>
                              {activity?.activityType}
                            </Grid>
                          </Grid>
                          <Grid item container md={4}>
                            <Grid item md={4} fontWeight="bold">
                              Phone No
                            </Grid>
                            <Grid item md={0.5} fontWeight="bold">
                              :
                            </Grid>
                            <Grid item md={7.5}>
                              {activity?.number}
                            </Grid>
                          </Grid>
                          <Grid item container md={4}>
                            <Grid item md={5} fontWeight="bold">
                              Date of Activity
                            </Grid>
                            <Grid item md={0.5} fontWeight="bold">
                              :
                            </Grid>
                            <Grid item md={6.5}>
                              {format(
                                new Date(activity?.dateOfActivity),
                                "do, MMM yyyy"
                              )}
                            </Grid>
                          </Grid>
                          <Grid item container md={4}>
                            <Grid item md={3} fontWeight="bold">
                              No Of Pax
                            </Grid>
                            <Grid item md={0.5} fontWeight="bold">
                              :
                            </Grid>
                            <Grid item md={8.5}>
                              {activity?.noOfPax}
                            </Grid>
                          </Grid>

                          <Grid item container md={4}>
                            <Grid item md={4} fontWeight="bold">
                              Email
                            </Grid>
                            <Grid item md={0.5} fontWeight="bold">
                              :
                            </Grid>
                            <Grid
                              item
                              md={7}
                              style={{ wordWrap: "break-word" }}
                            >
                              {activity?.email}
                            </Grid>
                          </Grid>
                        </Grid>
                        {idx <
                          activityData?.activityBookingActivityDetails?.length -
                            1 && (
                          <hr
                            style={{
                              border: "1px solid #f46d25",
                              width: "95%",
                              margin: "1rem",
                            }}
                          />
                        )}
                      </>
                    )
                  )}
                </Grid>
              </Paper>
            </article>
            <article className={classes.article}>
              <header className={classes.sectionHeader}>Payment Details</header>
              <Paper className={classes.paper}>
                <Grid container>
                  <Grid item container md={12} spacing={1}>
                    <Grid item md={12} fontWeight={"bold"} color={"#f46d35"}>
                      Payment Breakup
                    </Grid>
                    <Grid item container md={3.85}>
                      <Grid item md={5} fontWeight={"bold"}>
                        Total Amount
                      </Grid>
                      <Grid item md={0.5}>
                        :
                      </Grid>
                      <Grid item md={6.5}>
                        {formatCurrency(activityData?.totalAmount)}
                      </Grid>
                    </Grid>
                    <Grid item md={0.2} color="#f46d25">
                      |
                    </Grid>
                    <Grid item container md={3.85}>
                      <Grid item md={7} fontWeight={"bold"}>
                        Net Payout to Vendor
                      </Grid>
                      <Grid item md={0.5}>
                        :
                      </Grid>
                      <Grid item md={4.5}>
                        {formatCurrency(activityData?.netPayoutToVendor)}
                      </Grid>
                    </Grid>
                    <Grid item md={0.2} color="#f46d25">
                      |
                    </Grid>
                    <Grid item container md={3.85}>
                      <Grid item md={5} fontWeight={"bold"}>
                        Commission
                      </Grid>
                      <Grid item md={0.5}>
                        :
                      </Grid>
                      <Grid item md={4.5}>
                        {formatCurrency(activityData?.comission)}
                      </Grid>
                    </Grid>
                  </Grid>

                  <hr
                    style={{
                      border: "1px solid #f46d25",
                      width: "95%",
                      margin: "1rem",
                    }}
                  />

                  <Grid item container md={12} spacing={2}>
                    <Grid item md={12} fontWeight={"bold"} color={"#f46d35"}>
                      Transaction Summary
                    </Grid>
                    <Grid item container md={3.85}>
                      <Grid item md={6.5} fontWeight={"bold"}>
                        Amount Recieved
                      </Grid>
                      <Grid item md={0.5}>
                        :
                      </Grid>
                      <Grid item md={4.5}>
                        {formatCurrency(activityData?.amountReceived)}
                      </Grid>
                    </Grid>
                    <Grid item md={0.2} color="#f46d25">
                      |
                    </Grid>
                    <Grid item container md={3.85}>
                      <Grid item md={7} fontWeight={"bold"}>
                        Payment Mode
                      </Grid>
                      <Grid item md={0.5}>
                        :
                      </Grid>
                      <Grid item md={4.5}>
                        {activityData?.paymentMode}
                      </Grid>
                    </Grid>

                    <Grid item md={0.2} color="#f46d25">
                      |
                    </Grid>

                    <Grid item container md={3.85}>
                      <Grid item md={5} fontWeight={"bold"}>
                        Payment Type
                      </Grid>
                      <Grid item md={0.5}>
                        :
                      </Grid>
                      <Grid item md={4.5}>
                        {activityData?.paymentType}
                      </Grid>
                    </Grid>
                    <Grid item container md={3.85}>
                      <Grid item md={6.5} fontWeight={"bold"}>
                        Reference Number
                      </Grid>
                      <Grid item md={0.5}>
                        :
                      </Grid>
                      <Grid item md={4.5}>
                        {activityData?.refNumber}
                      </Grid>
                    </Grid>
                    <Grid item md={0.2} color="#f46d25">
                      |
                    </Grid>
                    <Grid item container md={5} spacing={1}>
                      <Grid item md={9} fontWeight={"bold"}>
                        Balance Payable On Arrival (BPAH)
                      </Grid>
                      <Grid item md={0.5}>
                        :
                      </Grid>
                      <Grid item md={2.5}>
                        {formatCurrency(activityData?.bpah)}
                      </Grid>
                    </Grid>
                  </Grid>

                  <hr
                    style={{
                      border: "1px solid #f46d25",
                      width: "95%",
                      margin: "1rem",
                    }}
                  />

                  <Grid item container md={12} spacing={1}>
                    <Grid item md={12} fontWeight={"bold"} color={"#f46d35"}>
                      Booking P&L
                    </Grid>
                    <Grid item container md={5.8}>
                      <Grid item md={7} fontWeight={"bold"}>
                        Total Gross Amount Projected
                      </Grid>
                      <Grid item md={0.4}>
                        :
                      </Grid>
                      <Grid item md={4}>
                        {formatCurrency(activityData?.totalGrossAmount)}
                      </Grid>
                    </Grid>

                    <Grid item md={0.4} color="#f46d25">
                      |
                    </Grid>
                    <Grid item container md={5.8}>
                      <Grid item md={7} fontWeight={"bold"}>
                        Profit After Tax
                      </Grid>
                      <Grid item md={0.4}>
                        :
                      </Grid>
                      <Grid item md={4}>
                        {formatCurrency(activityData?.profitAfterTax)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </article>
            <center>
              <Button
                style={{ color: "#000" }}
                onClick={() => history.push("/konnect/activityList")}
              >
                Back
              </Button>
            </center>
          </main>
        </>
      )}
    </section>
  )
}

export default ViewActivity
