import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  makeStyles,
} from "@material-ui/core"
import { AddAPhotoOutlined } from "@material-ui/icons"
import DeleteIcon from "@material-ui/icons/Delete"
import { useEffect, useState } from "react"
import ReactPlayer from "react-player"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import Api from "../../../Service/Api"
import { baseurl } from "../../../Service/httpCommon"

const useStyles = makeStyles(() => ({
  sectionStyles: {
    border: "1px solid #C4C4C4",
    padding: "10px",
    borderRadius: "5px",
  },
  outerSectionStyles: {
    marginTop: "19px",
  },
  cardStyles: {
    height: "140px",
    width: "177px",
    border: "1px solid #C4C4C4",
    borderRadius: "3px",
  },
  inputStyles: {
    height: "40px",
    width: "60%",
  },
  sectionHeader: {
    fontSize: "17px",
    fontWeight: "bold",
    paddingBottom: "7px",
  },
  card: {
    marginTop: "50px",
    marginRight: "15px",
    width: "300px",
  },
}))

const PropertyMedia = () => {
  const classes = useStyles()
  const [imagePath, setImagePath] = useState("")
  const [openDialogUpload, setOpenDialogUpload] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [imageArray, setImageArray] = useState(() => [])
  const [videoUrlArray, setVideoUrlArray] = useState(() => [])
  const { propertyId } = useParams()
  const [openDelete, setOpenDelete] = useState(() => false)
  const [selectedDeleteId, setSelectedDeleteId] = useState("")

  const [openVideoDialog, setOpenVideoDialog] = useState(() => false)
  const [videoUrl, setVideoUrl] = useState("")

  const fetchVideoUrlData = async () => {
    const { data } = await Api.get(`propertyamenitiesvideosvalue/${propertyId}`)
    console.log(data)
    setVideoUrlArray(data)
  }

  useEffect(() => {
    if (propertyId != null) {
      fetchVideoUrlData()
    }
  }, [loading, openVideoDialog])

  const handleDelete = (id) => {
    setOpenDelete(true)
    setSelectedDeleteId(id)
  }

  const handleDeleteVideo = (id) => {
    setOpenVideoDialog(true)
    setSelectedDeleteId(id)
  }

  const handleCloseDelete = () => {
    setOpenDelete(false)
  }

  const deleteImage = () => {
    Api.delete("propertyamenitiesphoto/" + selectedDeleteId, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        toast.success("Successfully Deleted")
        setOpenDelete(false)
      })
      .catch((error) => {
        setOpenDelete(false)
        toast.error("An unexpected error occured")
      })
  }

  const fetchPropertyImageArray = async () => {
    const { data } = await Api.get(`propertyamenitiesphotovalue/${propertyId}`)
    console.log(data)
    setImageArray(data)
  }

  useEffect(() => {
    if (propertyId != null) {
      fetchPropertyImageArray()
    }
  }, [loading, openDelete])

  const handleUploadImage = (e) => {
    console.log(e.target.files)
    setImagePath(e.target.files[0])
    setOpenDialogUpload(true)
  }

  const handleCloseUploadDialog = () => {
    setImagePath("")
    setOpenDialogUpload(false)
  }

  const addNew = async () => {
    setLoading(true)
    const uniqueid = localStorage.getItem("uniqueid")
    const createdBy = localStorage.getItem("auth")
    const formData = new FormData()
    formData.append("image", imagePath)
    formData.append("propertyId", propertyId)
    formData.append("uniqueId", uniqueid)
    formData.append("createdBy", createdBy)
    console.log(formData)
    await Api.post("propertyamenitiesphotoAWS", formData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        toast.success("Successfully Upload")
        console.log(response)
        setOpenDialogUpload(false)
        setLoading(false)
        // fetchPropertyImageArray();
      })
      .catch((error) => {
        setLoading(false)
        toast.warning("An Unexpexted occured")
      })
  }

  const deleteVideo = () => {
    Api.delete("propertyamenitiesvideos/" + selectedDeleteId, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      setOpenVideoDialog(false)
      toast.success("Successfully Deleted")
    })
  }

  const handleVideoSubmit = async () => {
    setLoading(true)
    const create = {
      uniqueId: localStorage.getItem("unique_id"),
      createdBy: localStorage.getItem("auth"),
      propertyId: propertyId,
    }
    let createData = { name: videoUrl, ...create }
    Api.post("propertyamenitiesvideos", createData).then((res) => {
      if (res) {
        toast.success("Successfully Uploded")
        console.log(res.data)
        setLoading(false)
      }
    })
    setVideoUrl("")
  }

  return (
    <>
      <section className={classes.outerSectionStyles}>
        {" "}
        <header className={classes.sectionHeader}>Photos</header>
        <section className={classes.sectionStyles} style={{ display: "flex" }}>
          <div
            className={classes.cardStyles}
            style={{
              background: "#EEF1F3",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              type="file"
              accept="image/*"
              id="imageInput"
              style={{ display: "none" }}
              onChange={handleUploadImage}
              onClick={(e) => (e.target.value = null)}
            />
            <label htmlFor="imageInput">
              <IconButton
                style={{ cursor: "pointer", padding: "0" }}
                variant="raised"
                component="span"
              >
                {/* <AddCircleIcon
                  color="primary"
                  style={{ cursor: "pointer", width: "34px", height: "34px" }}
                /> */}
                <AddAPhotoOutlined
                  color="primary"
                  style={{ cursor: "pointer", width: "34px", height: "34px" }}
                />
              </IconButton>
            </label>
          </div>
          {imageArray.map((image) => {
            return (
              <div key={image.id} style={{ display: "inline-block" }}>
                <Card
                  className={classes.cardStyles}
                  style={{ marginLeft: "10px" }}
                >
                  <DeleteIcon
                    onClick={() => {
                      handleDelete(image.id)
                    }}
                    style={{
                      position: "absolute",
                      color: "#f46d25",
                      background: "white",
                      borderRadius: "3px",
                      cursor: "pointer",
                    }}
                  />

                  {/* <CardContent> */}
                  <img
                    src={`${baseurl}getimage/${image.imagepath}`}
                    alt="HotelPhoto"
                    className={classes.cardStyles}
                  />
                  {/* </CardContent> */}
                </Card>
              </div>
            )
          })}
        </section>
      </section>
      <section style={{ marginTop: "20px" }}>
        <header className={classes.sectionHeader}>Video</header>
        <section className={classes.sectionStyles} style={{ display: "flex" }}>
          <TextField
            variant="outlined"
            type="url"
            value={videoUrl}
            onChange={(e) => {
              setVideoUrl(e.target.value)
              console.log(e.target.value)
            }}
            inputMode="url"
            InputProps={{
              style: {
                height: "38px",
                width: "689px",
              },
            }}
            placeholder="video url"
            name="name"
          />
          <Button
            style={{ height: "38px", marginLeft: "20px", width: "160px" }}
            onClick={handleVideoSubmit}
          >
            Save
          </Button>
        </section>
        {videoUrlArray.map((video) => {
          return (
            <div key={video.id} style={{ display: "inline-block" }}>
              <Card className={classes.card}>
                <CardHeader
                  action={
                    <DeleteIcon
                      onClick={() => {
                        handleDeleteVideo(video.id)
                      }}
                      style={{ color: "#f46d25" }}
                    />
                  }
                />
                <CardContent>
                  <ReactPlayer
                    controls
                    url={video.name}
                    width="100%"
                    height="100%"
                  />
                </CardContent>
              </Card>
            </div>
          )
        })}
      </section>

      <Dialog
        open={openDialogUpload}
        onClose={handleCloseUploadDialog}
        maxWidth="md"
      >
        <DialogTitle>Image Upload</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Click on the upload button below
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <Button disabled variant="contained" color="primary">
              <i
                className="fa fa-refresh fa-spin"
                style={{
                  marginRight: "8px",
                }}
              ></i>
              Upload
            </Button>
          ) : (
            <Button
              onClick={() => addNew()}
              variant="contained"
              color="primary"
            >
              Upload
            </Button>
          )}

          <Button
            onClick={handleCloseUploadDialog}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDelete} onClose={handleCloseDelete} maxWidth="md">
        <DialogTitle>Delete Image</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>Are You Want Delete Image ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={deleteImage}>
            Delete
          </Button>
          <Button
            onClick={handleCloseDelete}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openVideoDialog} onClose={() => setOpenVideoDialog(false)}>
        <DialogTitle id="alert-dialog-title">Delete Video </DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => deleteVideo()}
          >
            Delete
          </Button>
          <Button
            onClick={() => setOpenVideoDialog(false)}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PropertyMedia
