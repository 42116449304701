import { useEffect, useState } from "react"
import Api from "../../Service/Api"
import { useParams } from "react-router-dom"
import { useRef } from "react"
import { Button, Card, Table, TableBody, TableRow } from "@material-ui/core"
import { TableCell } from "@mui/material"
import { baseurl } from "../../Service/httpCommon"
import { format } from "date-fns"
import _ from "lodash"
import parse from "html-react-parser"
import { toast, ToastContainer } from "react-toastify"

const CopyTransferPageKGL = (props) => {
  var uniqueid = localStorage.getItem("unique_id")
  const tableRef = useRef(null)
  const [copySuccess, setCopySuccess] = useState("Copy")
  var [data, setData] = useState({
    id: "",
    type: "",
    transferId: "",
    createdAt: "",
    basicDetails: [],
    flightDetails: [],
    trainDetails: [],
    cabDetails: [],
    busDetails: [],
  })
  let { transferId } = useParams()
  const [cancelationpolicy, setCancelationpolicy] = useState({})
  const [termsandcondition, setTermsandcondition] = useState({})

  const [logoUrl, setLogoUrl] = useState("")

  useEffect(() => {
    Api.get(`getAgentLogo/${uniqueid}`).then((res) => {
      console.log("getAgentLogo|response: ", res.data)
      setLogoUrl(res.data)
    })
  }, [])

  useEffect(() => {
    Api.get(`/transferDetail/${uniqueid}/${transferId}`).then((res) => {
      console.log("copyTransferPageId :", res.data)
      setData(res.data)
    })
    Api.get(
      `agenthoteltermandcondition/${uniqueid}/transferTermCondition`
    ).then((res) => {
      setTermsandcondition(res.data)
    })

    Api.get(
      `agenthoteltermandcondition/${uniqueid}/transferCancellationPolicy`
    ).then((res) => {
      setCancelationpolicy(res.data)
      console.log("setCancelationpolicy", res.data)
    })
  }, [])

  const copyToClip = async () => {
    let range = document.createRange()
    range.selectNodeContents(tableRef.current)
    let sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
    document.execCommand("Copy")
    sel.removeAllRanges()
    //paste the copied data to mailto body
    document.addEventListener("paste", function (event) {
      var clipText = event.clipboardData.getData("Text")
      window.location = `mailto:?subject=I wanted you to see this site&body=${clipText}`
    })
    await setCopySuccess("Copy")
    await toast.success("Copied Successfully!")
  }

  var flightTempAmountArray = data.flightDetails.map((x, i) => {
    return /^\+?\d+$/.test(x.flightAmount) ? x.flightAmount : 0
  })
  var flightTempTotalAmount = flightTempAmountArray.reduce((total, num) => {
    return total + num / 1
  }, 0)
  var trainTempAmountArray = data.trainDetails.map((x, i) => {
    return /^\+?\d+$/.test(x.trainAmount) ? x.trainAmount : 0
  })
  var trainTempTotalAmount = trainTempAmountArray.reduce((total, num) => {
    return total + num / 1
  }, 0)
  var cabTempAmountArray = data.cabDetails.map((x, i) => {
    return /^\+?\d+$/.test(x.cabAmount) ? x.cabAmount : 0
  })
  var cabTempTotalAmount = cabTempAmountArray.reduce((total, num) => {
    return total + num / 1
  }, 0)
  var busTempAmountArray = data.busDetails.map((x, i) => {
    return /^\+?\d+$/.test(x.busAmount) ? x.busAmount : 0
  })
  var busTempTotalAmount = busTempAmountArray.reduce((total, num) => {
    return total + num / 1
  }, 0)

  return (
    <div
      style={{
        margin: "20px 0px",
      }}
    >
      <div
        ref={tableRef}
        style={{
          maxWidth: "50%",
          margin: "0 auto",
          width: "100%",
          fontSize: "14px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#111",
            width: "100%",
          }}
        >
          <Table sx={{ width: "100%", padding: "10px" }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "50%" }}>
                  <img
                    src={`${baseurl}getimage/${logoUrl}`}
                    alt="logo"
                    style={{
                      width: "6em",
                      height: "1.8em",
                      paddingTop: "0.4em",
                    }}
                  />
                </TableCell>

                <TableCell
                  style={{
                    color: "#fff",
                    fontWeight: "bold",
                    textAlign: "right",
                    paddingTop: "1em",
                  }}
                >
                  {data.transferId}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        {/* basic details starts */}
        <Card
          style={{
            borderRadius: "0",
            backgroundColor: "#fff",
            width: "auto",
            boxShadow: "none",
          }}
        >
          {" "}
          <h3
            style={{
              width: "100%",
              backgroundColor: "#f46d25",
              fontSize: "20px",
              color: "#fff",
              margin: "0",
              padding: "5px 0px 5px ",
            }}
          >
            <span style={{ marginLeft: "15px" }}>Basic Details</span>
          </h3>
          {data.basicDetails.map((each) => (
            <Table sx={{ width: "100%", padding: "10px" }}>
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{ width: "50%", border: "none", paddingTop: "10px" }}
                  >
                    Name
                  </TableCell>
                  <TableCell sx={{ border: "none", paddingTop: "10px" }}>
                    : {each.clientName}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{ width: "50%", border: "none", paddingTop: "0" }}
                  >
                    Mobile No
                  </TableCell>
                  <TableCell sx={{ border: "none", paddingTop: "0" }}>
                    : {each.clientMobileNo}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{ width: "50%", border: "none", paddingTop: "0" }}
                  >
                    Alt No
                  </TableCell>
                  <TableCell sx={{ border: "none", paddingTop: "0" }}>
                    : {each.clientAltNo}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{ width: "50%", border: "none", paddingTop: "0" }}
                  >
                    Email
                  </TableCell>
                  <TableCell sx={{ border: "none", paddingTop: "0" }}>
                    : {each.clientEmail}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ))}
        </Card>
        {/* basic details ends */}
        {/* flight details starts */}
        {flightTempTotalAmount > 0 && (
          <Card
            style={{
              borderRadius: "0",
              backgroundColor: "#fff",
              width: "auto",
              boxShadow: "none",
            }}
          >
            {data.flightDetails.length > 0 ? (
              <>
                <h3
                  style={{
                    width: "100%",
                    backgroundColor: "#f46d25",
                    fontSize: "20px",
                    color: "#fff",
                    margin: "0",
                    padding: "5px 0px 5px ",
                  }}
                >
                  <span style={{ marginLeft: "15px" }}>Flight Details</span>
                </h3>{" "}
                {data.flightDetails.map((each) => (
                  <Table sx={{ width: "100%", padding: "10px" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "50%",
                            border: "none",
                            paddingTop: "10px",
                          }}
                        >
                          Trip
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "10px" }}>
                          : {each.flightTrip}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Name
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.flightName}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Depart Date
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          :{" "}
                          {each.flightDepartDate
                            ? format(
                                new Date(each.flightDepartDate),
                                "dd MMM yy"
                              )
                            : null}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          To
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.flightTo}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          From
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.flightFrom}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Return Date
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          :{" "}
                          {each.flightReturnDate
                            ? format(
                                new Date(each.flightReturnDate),
                                "dd MMM yy"
                              )
                            : null}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Pnr
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.flightPnr}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Adults
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.flightAdults}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Children
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.flightChild}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Amount
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.flightAmount}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ))}
              </>
            ) : null}
          </Card>
        )}
        {/* flight details ends */}
        {/* train details starts */}
        {trainTempTotalAmount > 0 && (
          <Card
            style={{
              borderRadius: "0",
              backgroundColor: "#fff",
              width: "auto",
              boxShadow: "none",
            }}
          >
            {data.trainDetails.length > 0 ? (
              <>
                <h3
                  style={{
                    width: "100%",
                    backgroundColor: "#f46d25",
                    fontSize: "20px",
                    color: "#fff",
                    margin: "0",
                    padding: "5px 0px 5px ",
                  }}
                >
                  <span style={{ marginLeft: "15px" }}>Train Details</span>
                </h3>
                {data.trainDetails.map((each) => (
                  <Table sx={{ width: "100%", padding: "10px" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "50%",
                            border: "none",
                            paddingTop: "10px",
                          }}
                        >
                          From
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "10" }}>
                          : {each.trainFrom}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          To
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.trainTo}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Depart Date
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          :{" "}
                          {each.trainDepartDate
                            ? format(
                                new Date(each.trainDepartDate),
                                "dd MMM yy"
                              )
                            : null}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Pnr
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.trainPnr}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Adults
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.trainAdults}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Children
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.trainChild}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Amount
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.trainAmount}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Commission
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.trainComission}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ))}
              </>
            ) : null}
          </Card>
        )}
        {/* train details ends */}
        {/* Cab Details starts */}
        {cabTempTotalAmount > 0 && (
          <Card
            style={{
              borderRadius: "0",
              backgroundColor: "#fff",
              width: "auto",
              boxShadow: "none",
            }}
          >
            {data.cabDetails.length > 0 ? (
              <>
                {" "}
                <h3
                  style={{
                    width: "100%",
                    backgroundColor: "#f46d25",
                    fontSize: "20px",
                    color: "#fff",
                    margin: "0",
                    padding: "5px 0px 5px ",
                  }}
                >
                  <span style={{ marginLeft: "15px" }}>Cab Details</span>
                </h3>
                {data.cabDetails.map((each) => (
                  <Table sx={{ width: "100%", padding: "10px" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "50%",
                            border: "none",
                            paddingTop: "10px",
                          }}
                        >
                          From
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "10px" }}>
                          : {each.cabFrom}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          To
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.cabTo}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Trip
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.cabTrip}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Type
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.cabType}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Vehicle
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.cabVehicle}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Adults
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.cabAdults}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Children
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.cabChild}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Amount
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.cabAmount}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Commission
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.cabCommission}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ))}
              </>
            ) : null}
          </Card>
        )}
        {/* cab details ends */}

        {/* Bus Details  */}
        {busTempTotalAmount > 0 && (
          <Card
            style={{
              borderRadius: "0",
              backgroundColor: "#fff",
              width: "auto",
              boxShadow: "none",
            }}
          >
            {data.busDetails.length > 0 ? (
              <>
                {" "}
                <h3
                  style={{
                    width: "100%",
                    backgroundColor: "#f46d25",
                    fontSize: "20px",
                    color: "#fff",
                    margin: "0",
                    padding: "5px 0px 5px ",
                  }}
                >
                  <span style={{ marginLeft: "15px" }}>Bus Details</span>
                </h3>
                {data.busDetails.map((each) => (
                  <Table sx={{ width: "100%", padding: "10px" }}>
                    <TableBody>
                      {" "}
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "50%",
                            border: "none",
                            paddingTop: "10px",
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "10px" }}>
                          : {each.busName}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Seat No
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.busSeatNo}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          From
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.busFrom}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          To
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.busTo}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Adults
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.busAdults}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Children
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.busChild}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Amount
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.busAmount}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ))}
              </>
            ) : null}
          </Card>
        )}
        {/* bus details ends */}

        {/**Hotel details Starts */}
        <Card
          style={{
            borderRadius: "0",
            backgroundColor: "#fff",
            width: "auto",
            boxShadow: "none",
          }}
        >
          {data?.hotelDetails?.length > 0 ? (
            <>
              <h3
                style={{
                  width: "100%",
                  backgroundColor: "#f46d25",
                  fontSize: "20px",
                  color: "#fff",
                  margin: "0",
                  padding: "5px 0px 5px ",
                }}
              >
                <span style={{ marginLeft: "15px" }}>Hotel Details</span>
              </h3>{" "}
              {data.hotelDetails.map((each) => (
                <Table sx={{ width: "100%", padding: "10px" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          width: "50%",
                          border: "none",
                          paddingTop: "10px",
                        }}
                      >
                        Hotel Name
                      </TableCell>
                      <TableCell sx={{ border: "none", paddingTop: "10px" }}>
                        : {each.hotelName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ width: "50%", border: "none", paddingTop: "0" }}
                      >
                        Hotel Detination
                      </TableCell>
                      <TableCell sx={{ border: "none", paddingTop: "0" }}>
                        : {each.hotelDestination}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ width: "50%", border: "none", paddingTop: "0" }}
                      >
                        No.of Adults
                      </TableCell>
                      <TableCell sx={{ border: "none", paddingTop: "0" }}>
                        : {each.hotelAdults}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ width: "50%", border: "none", paddingTop: "0" }}
                      >
                        No.of Children
                      </TableCell>
                      <TableCell sx={{ border: "none", paddingTop: "0" }}>
                        : {each.hotelChild}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ width: "50%", border: "none", paddingTop: "0" }}
                      >
                        Check-In
                      </TableCell>
                      <TableCell sx={{ border: "none", paddingTop: "0" }}>
                        : {format(new Date(each.hotelCheckIn), "dd MMM, yyyy")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{ width: "50%", border: "none", paddingTop: "0" }}
                      >
                        Check-In
                      </TableCell>
                      <TableCell sx={{ border: "none", paddingTop: "0" }}>
                        :{" "}
                        {format(new Date(each?.hotelCheckOut), "dd MMM, yyyy")}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              ))}
            </>
          ) : null}
        </Card>
        {/**Hotel Details End */}

        {/* cancellation policy starts */}
        <Card
          style={{
            borderRadius: "0",
            backgroundColor: "#fff",
            width: "auto",
            boxShadow: "none",
          }}
        >
          <h3
            style={{
              width: "100%",
              backgroundColor: "#f46d25",
              fontSize: "20px",
              color: "#fff",
              margin: "0",
              padding: "5px 0px 5px ",
            }}
          >
            <span style={{ marginLeft: "15px" }}>Cancellation Policy</span>
          </h3>
          <div
            style={{
              width: "100%",
              padding: "1em",
              fontWeight: "500",
              lineHeight: "1.2",
            }}
          >
            <p style={{ width: "95%", textAlign: "justify" }}>
              {_.isEmpty(cancelationpolicy) ? null : parse(cancelationpolicy)}
            </p>
          </div>
        </Card>
        {/* cancellation policy ends */}

        {/* terms & conditions starts */}
        <Card
          style={{
            borderRadius: "0",
            backgroundColor: "#fff",
            width: "auto",
            boxShadow: "none",
          }}
        >
          <h3
            style={{
              width: "100%",
              backgroundColor: "#f46d25",
              fontSize: "20px",
              color: "#fff",
              margin: "0",
              padding: "5px 0px 5px ",
            }}
          >
            <span style={{ marginLeft: "15px" }}>Terms & Conditions</span>
          </h3>
          <div
            style={{
              width: "100%",
              padding: "1em",
              fontWeight: "500",
              lineHeight: "1.2",
              textAlign: "justify",
            }}
          >
            <p style={{ width: "95%", textAlign: "justify" }}>
              {_.isEmpty(termsandcondition) ? null : parse(termsandcondition)}
            </p>
          </div>
        </Card>
      </div>
      {document.queryCommandSupported("copy") && (
        <div style={{ textAlign: "center", padding: "1em" }}>
          <Button color="primary" onClick={copyToClip}>
            {copySuccess}
          </Button>
          <ToastContainer />
        </div>
      )}
    </div>
  )
}

export default CopyTransferPageKGL
