import { DialogTitle } from "@material-ui/core";
import {
  Avatar,
  Button,
  Container,
  Drawer,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Api from "../../../Service/Api";
import * as yup from "yup";
import { baseurl } from "../../../Service/httpCommon";
import { toast } from "react-toastify";



const AddandEditFavouriteDestination = ({ open, onClose, selectedId }) => {
  var uniqueid = localStorage.getItem("unique_id");
  var createdBy = localStorage.getItem("auth");
  const [isHovered, setHovered] = useState(false);
  const [individualData, setIndividualData] = useState({});
  const schema = yup.object({
    image:  yup
    .mixed()
    .test("fileOrString", "Image is required", (value) => {
      return value instanceof File || typeof value === 'string';
    })
    .test("fileFormat", "Only jpg or png allowed", (value) => {
      if (value instanceof File) {
        return ["image/jpeg", "image/png"].includes(value.type);
      }
      return true;
    }),
    city: yup.string().required("This Field is Required"),
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
      // setFieldValue("image", file);
      // setTouched({ image: true });
      if (file) {
        const fileSizeInKB = file.size / 1024;
        if (fileSizeInKB <= 600) {
          setFieldValue("image", file);
          setTouched({ image: true });
        } else {
          toast.error("File size exceeds the limit of 600KB");
        }
      }
  };

  const handleOpenDialog = () => {
    setTouched({ image: true });
  };

  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
    setTouched,
    resetForm
  } = useFormik({
    initialValues: {
      image: null,
      city: "",
    },
    validationSchema: schema,
    onSubmit,
  });

  async function onSubmit(values, actions) {
    console.log("values", values);
    if(selectedId){
      const formData = new FormData();
      formData.append("id", individualData.id);
      formData.append("city", values.city);
      formData.append("image", values.image);
      formData.append("createdBy", createdBy);
      Api.post("favouriteDestinations", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        if (res.status === 200){
          resetForm()
          onClose();
          toast.success("Updated Successfully");
        }
      });
    }else{
      const formData = new FormData();
      formData.append("id", 0);
      formData.append("city", values.city);
      formData.append("image", values.image);
      formData.append("createdBy", createdBy);
      Api.post("favouriteDestinations", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        if (res.status === 200){
          resetForm()
          onClose();
          toast.success("Added Successfully");
        }
      });
    }
  }

  const handleClose = () => {
    resetForm();
    onClose();
  };
  useEffect(() => {
    if (selectedId) {
      Api.get(`favouriteDestinationsIndivisualDetail/${selectedId}`).then((res) => {
        console.log("individualdata",res.data)
        setIndividualData(res.data)
        setFieldValue("image",`${baseurl}getimage/${res.data.image}`)
        setFieldValue("city",res.data.city)
      })
    }
  },[selectedId])
  return (
    <div>
      <Drawer
        PaperProps={{
          sx: {
            width: "600px" ? "80%" : "250px",
          },
        }}
        anchor="right"
        open={open}
        onClose={handleClose}
      >
        <Container sx={{ p: 2 }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                textAlign="center"
              >
                <label htmlFor="upload-input">
                  <span
                    style={{
                      position: "relative",
                      display: "inline-block",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                  >
                    {typeof values.image==="object" ?<Avatar
                      src={values.image && URL.createObjectURL(values.image)}
                      alt="Selected Image"
                      sx={{
                        width: 150,
                        height: 150,
                        borderRadius: "50%",
                        // mt: 2,
                      }}
                    />:<>
                    {
                      (individualData.image || individualData.image!=="" || individualData.image!==undefined) && <Avatar sx={{
                        width: 150, 
                        height: 150,
                        borderRadius: "50%",
                        // mt: 2,
                      }} src={`${baseurl}getimage/${individualData.image}`}/>
                    } 
                    </>}
                    <div
                      style={{
                        position: "absolute",
                        top: "80%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        background: "#e8e8e8",
                        width: "150px",
                        paddingBottom:"15px",
                        visibility: isHovered ? "visible" : "hidden",
                      }}
                    >
                      <IconButton
                        color="primary"
                        component="div"
                        sx={{ color: "#F46D25", fontSize: 20 }}
                      >
                        <AddAPhotoIcon />
                      </IconButton>
                      <p style={{margin:0,fontSize:"12px"}}>Change</p>
                    </div>
                  </span>
                  <input
                    type="file"
                    name="image"
                    id="upload-input"
                    onChange={handleFileChange}
                    accept="image/*"
                    style={{ display: "none" }}
                    error={touched.topLeftLabel && Boolean(errors.topLeftLabel)}
                  />
                </label>
                <div style={{ textAlign: "center",mt:"25px" }}>
                     <p> Maximum 600kb file</p>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1} mt="20px">
              <Grid sx={{display:"flex",justifyContent:"center",margin:"auto"}} item md={6} xs={12} sm={6}>
              <FormControl fullWidth>
                  <FormLabel
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#f46d25",
                      textAlign:"center"
                    }}
                    className="formlabel"
                  >
                   Destination
                  </FormLabel>
                  <TextField
                    className="inputbox"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="small"
                    value={values.city}
                    placeholder="City"
                    name="city"
                    id="city"
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid sx={{ textAlign: "center" }} container spacing={1} mt="10px">
              <Grid item md={12} xs={12} sm={12}>
                <Button
                  type="submit"
                  style={{ background: "#f46d25", color: "#fff" }}
                >
                  Submit
                </Button>
                <Button
                  onClick={handleClose}
                  style={{
                    background: "#121212",
                    color: "#fff",
                    margin: "10px",
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Drawer>
    </div>
  );
};

export default AddandEditFavouriteDestination;
