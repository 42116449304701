import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core"
import RoomFeatures from "../../../Property/Rooms/Amenities/RoomFeatures"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import { useEffect } from "react"
import Api from "../../../../Service/Api"
import { useState } from "react"
import { Form, Formik } from "formik"
import { Grid } from "@mui/material"
import InlineFormikFieldWithCheckSelectAndSwitch from "../../../../components/amenityComponents/inlineCheckBoxWithSelectAndSwitch.component"
import InlineCheckboxSwitchWithMultipleSelectFields from "../../../../components/amenityComponents/inlineCheckboxSwitchAndMultpleSelectFields.component"
import FormikInlineCheckboxAndSwitchOnly from "../../../../components/amenityComponents/inlineCheckBoxAndSwitchOnly.component"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import ScreenLoader from "../../../../components/loadingScreen/screenLoader.component"

const intialValues = {
  closet: false,
  closetStarred: false,
  clothesRack: false,
  clothesRackStarred: false,
  hangers: false,
  hangersStarred: false,
  blackoutCurtains: false,
  blackoutCurtainsStarred: false,
  centerTable: false,
  centerTableStarred: false,
  chargingPoints: false,
  chargingPointsType: [],
  chargingPointsStarred: false,
  couch: false,
  couchStarred: false,
  diningTable: false,
  diningTableStarred: false,
  fireplace: false,
  fireplaceStarred: false,
  miniBar: false,
  miniBarType: "",
  miniBarType1: "",
  miniBarStarred: false,
  miniFridge: false,
  miniFridgeStarred: false,
  mirror: false,
  mirrorStarred: false,
  sofa: false,
  sofaType: [],
  sofaStarred: false,
  telephone: false,
  telephoneType: "",
  telephoneStarred: false,
  woodenFloors: false,
  woodenFloorsStarred: false,
  workDesk: false,
  workDeskStarred: false,
  readinglamp: false,
  readinglampStarred: false,
  pillowmenu: false,
  pillowmenuStarred: false,
  livingArea: false,
  livingAreaStarred: false,
  diningArea: false,
  diningAreaStarred: false,
  seatingArea: false,
  seatingAreaStarred: false,
  intercom: false,
  intercomStarred: false,
  intercomType: "",
  chair: false,
  chairStarred: false,
  washingMachine: false,
  washingMachineStarred: false,
  blanket: false,
  blanketStarred: false,
  blanketType: [],
  cushions: false,
  cushionsStarred: false,
  pillows: false,
  pillowsTypes: "",
  pillowsStarred: false,
  alarmClock: false,
  alarmClockStarred: false,
  mosquitoNet: false,
  mosquitoNetStarred: false,
  safetySecurity: false,
  safetySecurityType: [],
  safetySecurityStarred: false,
  childCare: false,
  childCareType: [],
  childCareStarred: false,
}

const PropertyRoomFeatures = ({
  classes,
  handleChangePanel,
  expanded,
  setExpanded,
}) => {
  const { propertyId, displayName } = useParams()
  const data = { propertyId: propertyId, displayName: displayName }
  const uniqueid = localStorage.getItem("unique_id")
  const createdBy = localStorage.getItem("auth")
  const create = { uniqueId: uniqueid, createdBy: createdBy }
  const [RoomFeaturesChargingPoints, setRoomFeaturesChargingPoints] = useState(
    []
  )
  const [RoomFeaturesMiniBar, setRoomFeaturesMiniBar] = useState([])
  const [safety, setSafety] = useState([])
  const [RoomFeaturesSofa, setRoomFeaturesSofa] = useState([])
  const [RoomFeaturesTelephone, setRoomFeaturesTelephone] = useState([])
  const [RoomFeaturesIntercom, setRoomFeaturesIntercom] = useState([])
  const [RoomBedBlanketBlanket, setRoomBedBlanketBlanket] = useState([])
  const [RoomBedBlanketPillows, setRoomBedBlanketPillows] = useState([])
  const [roomChild, setRoomChild] = useState([])
  const [loading, setLoading] = useState(false)
  const [roomFeaturesData, setRoomFeaturesData] = useState(() => {})

  const fetchRoomFeatures = async () => {
    setLoading(true)
    try {
      const normalData = await Api.get(
        `RAmenitiesFeaturesvalue/${propertyId}/${displayName}`
      )
      const switchData = await Api.get(
        `RAmenitiesFeaturesStarredvalue/${propertyId}/${displayName}`
      )

      console.log(normalData.data)
      console.log(switchData.data)

      setRoomFeaturesData({ ...normalData.data, ...switchData.data })
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  const handleChangeAccordion = () => setExpanded("panel4")

  useEffect(() => {
    console.log(displayName)
    if (propertyId && displayName && expanded === "panel3") {
      fetchRoomFeatures()
    }
  }, [expanded])

  const childGet = async () => {
    const childData = {
      type: "Childcare service",
    }
    await Api.post("productdropdownonly", childData).then((res) => {
      setRoomChild(res.data)
    })
  }

  const RoomSafetyGet = async () => {
    const safteyData = {
      type: "Room Safety and Security Security",
    }
    await Api.post("productdropdownonly", safteyData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      setSafety(res.data)
    })
  }

  const RoomBedBlanketBlanketGet = async () => {
    const blanketData = {
      type: "Room Beds and Blanket Blanket",
    }
    await Api.post("productdropdownonly", blanketData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomBedBlanketBlanket(response.data)
    })
  }

  const RoomBedBlanketPillowsGet = async () => {
    const pillowsData = {
      type: "Room Beds and Blanket Pillows",
    }
    await Api.post("productdropdownonly", pillowsData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomBedBlanketPillows(response.data)
    })
  }

  const RoomFeaturesChargingPointsGet = async () => {
    const chargeData = {
      type: "Room Features Charging Points",
    }
    await Api.post("productdropdownonly", chargeData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomFeaturesChargingPoints(response.data)
    })
  }
  const RoomFeaturesMiniBarGet = async () => {
    const barData = {
      type: "Room Features Mini Bar",
    }
    await Api.post("productdropdownonly", barData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomFeaturesMiniBar(response.data)
    })
  }
  const RoomFeaturesSofaGet = async () => {
    const sofaData = {
      type: "Room Features Sofa",
    }
    await Api.post("productdropdownonly", sofaData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomFeaturesSofa(response.data)
    })
  }
  const RoomFeaturesTelephoneGet = async () => {
    const telephoneData = {
      type: "Room Features Telephone",
    }
    await Api.post("productdropdownonly", telephoneData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomFeaturesTelephone(response.data)
    })
  }
  const RoomFeaturesIntercomGet = async () => {
    const interData = {
      type: "Room Features Intercom",
    }
    await Api.post("productdropdownonly", interData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomFeaturesIntercom(response.data)
    })
  }

  useEffect(() => {
    if (propertyId && displayName && expanded === "panel3") {
      RoomFeaturesChargingPointsGet()
      RoomFeaturesMiniBarGet()
      RoomFeaturesSofaGet()
      RoomFeaturesTelephoneGet()
      RoomFeaturesIntercomGet()
      RoomBedBlanketPillowsGet()
      RoomBedBlanketBlanketGet()
      RoomSafetyGet()
      childGet()
    }
  }, [expanded])

  const roomFeaturesWithSelect = [
    {
      name: "safetySecurity",
      label: "Safety",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "safetySecurityType",
      switchName: "safetySecurityStarred",
      dropdDownOptions: safety,
    },
    {
      name: "childCare",
      label: "Child Care",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "childCareType",
      switchName: "childCareStarred",
      dropdDownOptions: roomChild,
    },

    {
      name: "chargingPoints",
      label: "Charging Points",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "chargingPointsType",
      switchName: "chargingPointsStarred",
      dropdDownOptions: RoomFeaturesChargingPoints,
    },
    {
      name: "sofa",
      label: "Sofa",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "sofaType",
      switchName: "sofaStarred",
      dropdDownOptions: RoomFeaturesSofa,
    },

    {
      name: "telephone",
      label: "Telephone",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "telephoneType",
      switchName: "telephoneStarred",
      dropdDownOptions: RoomFeaturesTelephone.map((option) => ({
        value: option,
        label: option,
      })),
    },

    {
      name: "intercom",
      label: "Intercom",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "intercomType",
      switchName: "intercomStarred",
      dropdDownOptions: RoomFeaturesIntercom.map((option) => ({
        value: option,
        label: option,
      })),
    },
    {
      name: "blanket",
      label: "Blanket",
      select: true,
      selectType: "multiple",
      selectLabel: "Type",
      selectFieldName: "blanketType",
      switchName: "blanketStarred",
      dropdDownOptions: RoomBedBlanketBlanket,
    },
    {
      name: "pillows",
      label: "Pillows",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "pillowsType",
      switchName: "pillowsStarred",
      dropdDownOptions: RoomBedBlanketPillows.map((option) => ({
        value: option,
        label: option,
      })),
    },
  ]

  const roomFacilitiesWithMultipleSelectFields = [
    {
      name: "miniBar",
      label: "Mini Bar",
      select: true,
      selectLabel1: "Type",
      selectLabel2: "Location",
      selectFieldName1: "miniBarType",
      selectFieldName2: "miniBarType1",
      switchName: "miniBarStarred",
      selectType2: "singleValue",
      dropdDownOptions1: [
        { value: "free", label: "Free" },
        { value: "paid", label: "Paid" },
      ],
      dropdDownOptions2: RoomFeaturesMiniBar.map((option) => ({
        label: option,
        value: option,
      })),
    },
  ]

  const roomFeaturesSwitchOnly = [
    {
      name: "couch",
      label: "Couch",
      switchName: "couchStarred",
    },

    {
      name: "diningTable",
      label: "Dining Table",
      switchName: "diningTableStarred",
    },
    {
      name: "fireplace",
      label: "Fireplace",
      switchName: "fireplaceStarred",
    },

    {
      name: "miniFridge",
      label: "Mini Fridge",
      switchName: "miniFridgeStarred",
    },

    {
      name: "mirror",
      label: "Mirror",
      switchName: "mirrorStarred",
    },

    {
      name: "woodenFloors",
      label: "Wooden Floor",
      switchName: "woodenFloorsStarred",
    },

    {
      name: "workDesk",
      label: "Work Desk",
      switchName: "workDeskStarred",
    },
    {
      name: "readinglamp",
      label: "Reading Lamp",
      switchName: "readinglampStarred",
    },

    {
      name: "pillowmenu",
      label: "Pillow Menu",
      switchName: "pillowmenuStarred",
    },
    {
      name: "livingArea",
      label: "Living Area",
      switchName: "livingAreaStarred",
    },

    {
      name: "diningArea",
      label: "Dining Area",
      switchName: "diningAreaStarred",
    },

    {
      name: "seatingArea",
      label: "Seating Area",
      switchName: "seatingAreaStarred",
    },

    {
      name: "chair",
      label: "Chair",
      switchName: "chairStarred",
    },
    {
      name: "washingMachine",
      label: "Washing Machine",
      switchName: "washingMachineStarred",
    },

    {
      name: "cushions",
      label: "Cushions",
      switchName: "cushionsStarred",
    },

    {
      name: "alarmClock",
      label: "Alarm Clock",
      switchName: "alarmClockStarred",
    },

    {
      name: "mosquitoNet",
      label: "Mosquito Net",
      switchName: "mosquitoNetStarred",
    },
    {
      name: "closet",
      label: "Closet",
      switchName: "closetStarred",
    },

    {
      name: "clothesRack",
      label: "Clothes Rack",
      switchName: "clothesRackStarred",
    },

    {
      name: "hangers",
      label: "Hangers",
      switchName: "hangersStarred",
    },

    {
      name: "blackoutCurtains",
      label: "Blackout Curtains",
      switchName: "blackoutCurtainsStarred",
    },
    {
      name: "centerTable",
      label: "Center Table",
      switchName: "centerTableStarred",
    },
  ]

  function onSubmit(fields, { setStatus }) {
    const id = roomFeaturesData.id
    const charging = []
    const sof = []
    const blank = []
    const safe = []
    const child = []

    if (fields.closet == false) {
      fields.closetStarred = false
    }
    if (fields.clothesRack == false) {
      fields.clothesRackStarred = false
    }
    if (fields.hangers == false) {
      fields.hangersStarred = false
    }
    if (fields.blackoutCurtains == false) {
      fields.blackoutCurtainsStarred = false
    }

    if (fields.centerTable == false) {
      fields.centerTableStarred = false
    }
    if (fields.chargingpoints == false) {
      fields.chargingPointsType = []
      fields.chargingpointsStarred = false
    }
    if (fields.couch == false) {
      fields.couchStarred = false
    }
    if (fields.diningTable == false) {
      fields.diningTableStarred = false
    }
    if (fields.fireplace == false) {
      fields.fireplaceStarred = false
    }
    if (fields.miniBar == false) {
      fields.miniBarType = ""
      fields.miniBarType1 = ""
      fields.miniBarStarred = false
    }
    if (fields.miniFridge == false) {
      fields.miniFridgeStarred = false
    }
    if (fields.mirror == false) {
      fields.mirrorStarred = false
    }
    if (fields.sofa == false) {
      fields.sofaType = []
      fields.sofaStarred = false
    }
    if (fields.telephone == false) {
      fields.telephoneType = ""
      fields.telephoneStarred = false
    }
    if (fields.woodenFloors == false) {
      fields.woodenFloorsStarred = false
    }
    if (fields.workDesk == false) {
      fields.workDeskStarred = false
    }
    if (fields.readinglamp == false) {
      fields.readinglampStarred = false
    }
    if (fields.pillowmenu == false) {
      fields.pillowmenuStarred = false
    }
    if (fields.livingArea == false) {
      fields.livingAreaStarred = false
    }
    if (fields.diningArea == false) {
      fields.diningAreaStarred = false
    }
    if (fields.seatingArea == false) {
      fields.seatingAreaStarred = false
    }

    if (fields.intercom == false) {
      fields.intercomStarred = false
      fields.intercomType = ""
    }

    if (fields.chair == false) {
      fields.chairStarred = false
    }

    if (fields.washingMachine == false) {
      fields.washingMachineStarred = false
    }
    if (fields.blanket == false) {
      fields.blanketStarred = false
      fields.blanketType = []
    }
    if (fields.cushions == false) {
      fields.cushionsStarred = false
    }
    if (fields.pillows == false) {
      fields.pillowsTypes = ""
      fields.pillowsStarred = false
    }
    if (fields.alarmClock == false) {
      fields.alarmClockStarred = false
    }
    if (fields.mosquitoNet == false) {
      fields.mosquitoNetStarred = false
    }
    if (fields.safetySecurity == false) {
      fields.safetySecurityType = ""
      fields.safetySecurityStarred = false
    }
    if (fields.childCare == false) {
      fields.childCareType = []
      fields.childCareStarred = false
    }
    setStatus()
    if (id) {
      updateRoomFeature(fields)
    } else {
      createRoomFeature(fields)
    }
  }
  function createStarred(fields) {
    fields.propertyId = propertyId
    fields.displayName = displayName
    const newData = { ...fields, ...create }
    Api.post("RAmenitiesFeaturesStarred", newData)
  }

  function updateStarred(fields) {
    fields.updateBy = createdBy
    Api.put("RAmenitiesFeaturesStarredupdate", fields)
  }

  function createRoomFeature(fields) {
    setLoading(true)
    fields.propertyId = propertyId
    fields.displayName = displayName
    const createData = { ...fields, ...create }
    console.log(createData)
    Api.post("RAmenitiesFeatures", createData).then((res) => {
      createStarred(fields)
      toast.success("Successfully Created")
      handleChangeAccordion()
      setLoading(false)
    })
  }
  function updateRoomFeature(fields) {
    fields.updateBy = createdBy
    setLoading(true)
    Api.put("RAmenitiesFeaturesupdate", fields).then((res) => {
      updateStarred(fields)
      toast.success("Successfully Updated")
      handleChangeAccordion()
      setLoading(false)
    })
  }

  return (
    <Formik
      initialValues={roomFeaturesData || intialValues}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) =>
        loading ? (
          <ScreenLoader open={loading} />
        ) : (
          <Form autoComplete="off">
            <Accordion
              square
              expanded={expanded === "panel3"}
              onChange={handleChangePanel("panel3")}
              className={classes.accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#f46d25",
                      background: "white",
                      borderRadius: "5px",
                    }}
                  />
                }
                className={classes.accordionSummaryStyles}
              >
                Room Features
              </AccordionSummary>
              <AccordionDetails className={classes.outerSectionStyles}>
                <Grid container spacing={1}>
                  {roomFeaturesWithSelect.map((amenity) => (
                    <InlineFormikFieldWithCheckSelectAndSwitch
                      amenity={amenity}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                  {roomFacilitiesWithMultipleSelectFields.map((amenity) => (
                    <InlineCheckboxSwitchWithMultipleSelectFields
                      amenity={amenity}
                      values={values}
                    />
                  ))}
                  {roomFeaturesSwitchOnly.map((amenity) => (
                    <FormikInlineCheckboxAndSwitchOnly
                      amenity={amenity}
                      values={values}
                    />
                  ))}
                </Grid>
              </AccordionDetails>
              <center>
                <Button type="submit" size="small">
                  Save and Next
                </Button>
              </center>
            </Accordion>
          </Form>
        )
      }
    </Formik>
  )
}

export default PropertyRoomFeatures
