import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Button, Dialog, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { React, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Api from "../../Service/Api";
import { twnButtonStyles } from "../../utils/townoStyle";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    width: 400,
    borderRadius: "0px",
    padding: "20px",
    borderRadius: "5px",
  },
  error: {
    color: "red",
  },
  heading: {
    fontSize: "24px",
    margin: "20px 0px",
  },
}));

export default function CommonForm({
  open,
  onClose,
  selectedRow,
  selectedOption,
  fetchData,
}) {
  const classes = useStyles();
  var uniqueid = localStorage.getItem("unique_id");
  var createdBy = localStorage.getItem("auth");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [submitActive, setSubmitActive] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [commonData, setCommonData] = useState([]);

  var uniqueid = localStorage.getItem("unique_id");

  const onClickFun = () => {
    const id = selectedRow.id;
    if (id) {
      updateRecord();
    } else {
      createRecord();
    }
  };

  const resetClose = () => {
    setName("");
    setDescription("");
    onClose();
  };

  const createRecord = () => {
    // POST API CALL
    const requestOptions = {
      createdBy: createdBy,
      name: name,
      description: description,
      uniqueId: uniqueid,
      menu: selectedOption.value,
    };

    if (name === "" && name.length === 0) {
      setIsDisabled(true);
      return;
    }

    Api.post("commonfeature/", requestOptions).then((res) => {
      setIsDisabled(true);
      fetchData();
      setName("");
      setDescription("");
      onClose();
      setIsDisabled(false);
      toast.success("Created Successful");
    });
  };

  const updateRecord = () => {
    console.log(
      "from commonform:",
      name,
      description,
      name === "",
      description === ""
    );
    console.log("from commonform2:", selectedRow);
    var updatedName = name === "" ? selectedRow.name : name;
    var updatedDescription =
      description === "" ? selectedRow.description : description;
    console.log("from commonform3:", updatedName, updatedDescription);
    const requestBody = {
      id: selectedRow.id,
      createdBy: selectedRow.createdBy,
      name: updatedName,
      description: updatedDescription,
      uniqueId: uniqueid,
      menu: selectedOption.value,
      status: "1",
      updatedBy: createdBy,
      updatedAt: null,
    };

    if (updatedName === "" && updatedName.length === 0) {
      setIsDisabled(true);
      return;
    }

    Api.put("commonfeature/" + selectedRow.id, requestBody).then((data) => {
      setIsDisabled(true);
      fetchData();
      setName("");
      setDescription("");
      onClose();
      setIsDisabled(false);
      toast.success("Update Successful");
    });
  };

  useEffect(() => {
    if (
      name.trim() !== "" ||
      (selectedRow.name !== undefined && selectedRow.name.trim() !== "")
    ) {
      console.log("name: ", name, " selectedRow.name:", selectedRow.name);
      setSubmitActive(true);
    } else {
      setSubmitActive(false);
    }
  }, [name, description]);

  return (
    <>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        open={open}
        onClose={resetClose}
        maxWidth="xs"
        fullWidth
        style={{ overflow: "auto" }}
      >
        {/* <Typography variant="h5" component="h5" className={classes.heading}> */}
        <Typography
          variant="h5"
          component="h5"
          style={{ ...twnButtonStyles.lgFonts, color: "#f46d25" }}
        >
          {selectedRow.id ? "Edit Feature" : "Add Feature"}
        </Typography>{" "}
        <br />
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <TextField
              id="menu"
              label="DropDown Menu"
              variant="outlined"
              required
              fullWidth
              disabled
              size="small"
              defaultValue={selectedOption.label}
            />
          </Grid>

          {selectedOption.ckTextEditor ? (
            <Grid item sm={12}>
              <CKEditor
                editor={ClassicEditor}
                name="name"
                data={selectedRow?.name}
                onChange={(e, editor) => {
                  const data = editor.getData();
                  console.log(data);
                  setName(data);
                }}
              />
            </Grid>
          ) : (
            <Grid item sm={12}>
              <TextField
                id="name"
                label="DropDown Name"
                autoFocus
                variant="outlined"
                required
                fullWidth
                size="small"
                defaultValue={selectedRow.name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Grid>
          )}

          <Grid item sm={12}>
            <TextField
              id="description"
              label="Description"
              variant="outlined"
              fullWidth
              size="small"
              defaultValue={selectedRow.description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Grid>

          <Grid item sm={12} style={{ textAlign: "center" }}>
            {submitActive ? (
              <Button
                type="submit"
                className={classes.button}
                onClick={onClickFun}
              >
                {selectedRow.id ? "Update" : "Create"}
              </Button>
            ) : (
              <Button disabled type="submit" className={classes.button}>
                {selectedRow.id && selectedRow.name ? "Update" : "Create"}
              </Button>
            )}

            <Button
              color="secondary"
              onClick={() => {
                resetClose();
                setSubmitActive(false);
              }}
              style={{
                background: "#121212",
                color: "#fff",
                margin: "10px",
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
