import {
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from "@material-ui/core"
import DoneIcon from "@material-ui/icons/Done"
import { Field } from "formik"
import { Autocomplete } from "@material-ui/lab"

const useStyles = makeStyles((theme) => ({
  menuStyling: {
    fontSize: "14px",
  },
  selectLabelProps: {
    backgroundColor: "white",
    color: "#000000",
    padding: "0px 5px 5px 5px",
    borderRadius: "5px",
  },
  menuItemStyles: {
    height: "300px",
    fontSize: "14px",
  },
  selectStyle: {
    height: "35px",
    width: "100%",
    "& .MuiSelect-icon": {
      color: "#f46d25",
    },
  },
  checkBoxLabelStyles: {
    fontSize: "14px",
  },
  chipStyling: {
    margin: theme.spacing(0.5),
    height: "15px",
    fontSize: "13px",
  },
}))

const FormikMultipleValueSelectField = ({ values, amenity, setFieldValue }) => {
  const classes = useStyles()
  return (
    <>
      <Field
        as={Autocomplete}
        multiple //returns the boolean value for determine the multiple values select field
        name={amenity.selectFieldName}
        value={values[amenity.selectFieldName]}
        limitTags={2}
        style={{ height: "30px" }}
        className={classes.selectStyle}
        options={amenity?.dropdDownOptions}
        filterSelectedOptions
        onChange={(event, newValue) => {
          setFieldValue(`${amenity.selectFieldName}`, newValue)
        }}
        getOptionLabel={(option) => option}
        size="small"
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option}
              variant="outlined"
              color="primary"
              className={classes.chipStyling}
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            label={amenity.selectLabel}
            size="small"
          />
        )}
      />
      {/* <FormControl variant="outlined" style={{ width: "100%" }}>
        <InputLabel className={classes.selectLabelProps} shrink>
          {amenity.selectLabel}
        </InputLabel>
        <Field
          as={Select}
          multiple //returns the boolean value for determine the multiple values select field
          name={amenity.selectFieldName}
          value={values[amenity.selectFieldName]}
          className={classes.selectStyle}
          MenuProps={{
            className: classes.menuItemStyles,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          renderValue={(selected) => (
            <div>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  variant="outlined"
                  color="primary"
                  deleteIcon={<DoneIcon color="primary" />}
                  className={classes.chipStyling}
                />
              ))}
            </div>
          )}
        >
          {amenity?.dropdDownOptions.map((option) => (
            <MenuItem className={classes.menuStyling} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Field>
      </FormControl> */}
    </>
  )
}

export default FormikMultipleValueSelectField
