import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core"
import { Form, Formik } from "formik"
import { useState } from "react"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import { Grid } from "@mui/material"
import Api from "../../../../Service/Api"
import { useEffect } from "react"
import InlineFormikFieldWithCheckSelectAndSwitch from "../../../../components/amenityComponents/inlineCheckBoxWithSelectAndSwitch.component"
import FormikInlineCheckboxAndSwitchOnly from "../../../../components/amenityComponents/inlineCheckBoxAndSwitchOnly.component"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { toast } from "react-toastify"
import ScreenLoader from "../../../../components/loadingScreen/screenLoader.component"

const initialValues = {
  bathtub: false,
  bathtubStarred: false,
  bubbleBath: false,
  bubbleBathStarred: false,
  dentalKit: false,
  dentalKitStarred: false,
  geyserWaterheater: false,
  geyserWaterheaterStarred: false,
  hairdryer: false,
  hairdryerStarred: false,
  hotColdWater: false,
  hotColdWaterStarred: false,
  slippers: false,
  slippersStarred: false,
  shower: false,
  showerStarred: false,
  showerType: [],
  toiletPapers: false,
  toiletPapersStarred: false,
  toiletries: false,
  toiletriesType: [],
  toiletriesStarred: false,
  sanitaryBin: false,
  sanitaryBinStarred: false,
  showerCap: false,
  showerCapStarred: false,
  towels: false,
  towelsType: [],
  towelsStarred: false,
  bodyScrub: false,
  bodyScrubStarred: false,
  bodyWrap: false,
  bodyWrapStarred: false,
  hammam: false,
  hammamStarred: false,
  bathrobes: false,
  bathrobesStarred: false,
  dustbins: false,
  dustbinsStarred: false,
  westernToiletSeat: false,
  westernToiletSeatStarred: false,
  showercubicle: false,
  showercubicleStarred: false,
  shavingMirror: false,
  shavingMirrorStarred: false,
  adaptedbath: false,
  adaptedbathStarred: false,
  bidet: false,
  bidetStarred: false,
  toiletwithgrabrails: false,
  toiletwithgrabrailsStarred: false,
}

const RoomBathroomAmenities = ({
  classes,
  handleChangePanel,
  expanded,
  setExpanded,
}) => {
  let { propertyId, displayName } = useParams()
  const data = { propertyId: propertyId, displayName: displayName }
  var uniqueid = localStorage.getItem("unique_id")
  var createdBy = localStorage.getItem("auth")
  const create = { uniqueId: uniqueid, createdBy: createdBy }
  const [bathroomAmenities, setBathroomAmenities] = useState(() => [])
  const [RoomBathroomShower, setRoomBathroomShower] = useState([])
  const [RoomBathroomToiletries, setRoomBathroomToiletries] = useState([])
  const [RoomBathroomTowels, setRoomBathroomTowels] = useState([])
  const [loading, setLoading] = useState(() => false)
  const fetchRoomBathroomAmenities = async () => {
    setLoading(true)

    try {
      const normalData = await Api.get(
        `RAmenitiesBathroomvalue/${propertyId}/${displayName}`
      )
      const switchData = await Api.get(
        `RAmenitiesBathroomStarredvalue/${propertyId}/${displayName}`
      )

      console.log(normalData.data)
      console.log(switchData.data)

      setBathroomAmenities({ ...normalData.data, ...switchData.data })
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (propertyId && displayName && expanded == "panel2") {
      fetchRoomBathroomAmenities()
    }
  }, [expanded])

  const RoomBathroomShowerGet = async () => {
    const bathroomData = {
      type: "Room Bathroom Shower",
    }
    await Api.post("productdropdownonly", bathroomData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomBathroomShower(response.data)
    })
  }

  const RoomBathroomToiletriesGet = async () => {
    const bathroomData = {
      type: "Room Bathroom Toiletries",
    }
    await Api.post("productdropdownonly", bathroomData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomBathroomToiletries(response.data)
    })
  }

  const RoomBathroomTowelsGet = async () => {
    const towelsData = {
      type: "Room Bathroom Towels",
    }
    await Api.post("productdropdownonly", towelsData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setRoomBathroomTowels(response.data)
    })
  }
  useEffect(() => {
    if (propertyId && displayName && expanded == "panel2") {
      RoomBathroomShowerGet()
      RoomBathroomToiletriesGet()
      RoomBathroomTowelsGet()
    }
  }, [expanded])

  const bathroomAmenitiesWithSelect = [
    {
      name: "shower",
      label: "Shower",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "showerType",
      switchName: "showerStarred",
      dropdDownOptions: RoomBathroomShower,
    },
    {
      name: "toiletries",
      label: "Toiletries",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "toiletriesType",
      switchName: "toiletriesStarred",
      dropdDownOptions: RoomBathroomToiletries,
    },
    {
      name: "towels",
      label: "Towels",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "towelsType",
      switchName: "towelsStarred",
      dropdDownOptions: RoomBathroomTowels,
    },
  ]

  const bathroomAmenitiesSwitchOnly = [
    {
      name: "bathtub",
      label: "Bath Tub",
      switchName: "bathtubStarred",
    },

    {
      name: "bubbleBath",
      label: "Bubble Bath",
      switchName: "bubbleBathStarred",
    },

    {
      name: "dentalKit",
      label: "Dental Kit",
      switchName: "dentalKitStarred",
    },
    {
      name: "geyserWaterheater",
      label: "Geyser / Waterheater",
      switchName: "geyserWaterheaterStarred",
    },
    {
      name: "hairdryer",
      label: "Hair Dryer",
      switchName: "hairdryerStarred",
    },

    {
      name: "hotColdWater",
      label: "Hot / Cold Water",
      switchName: "hotColdWaterStarred",
    },
    {
      name: "slippers",
      label: "Slippers",
      switchName: "slippersStarred",
    },
    {
      name: "toiletPapers",
      label: "Toilet Papers",
      switchName: "toiletPapersStarred",
    },
    {
      name: "sanitaryBin",
      label: "Sanitary Bin",
      switchName: "sanitaryBinStarred",
    },
    {
      name: "showerCap",
      label: "Shower Cap",
      switchName: "showerCapStarred",
    },
    {
      name: "bodyScrub",
      label: "Body Scrub",
      switchName: "bodyScrubStarred",
    },
    {
      name: "bodyWrap",
      label: "Body Wrap",
      switchName: "bodyWrapStarred",
    },
    {
      name: "bathrobes",
      label: "Bathrobes",
      switchName: "bathrobesStarred",
    },
    {
      name: "dustbins",
      label: "Dustbins",
      switchName: "dustbinsStarred",
    },
    {
      name: "westernToiletSeat",
      label: "Western Toilet Seat",
      switchName: "westernToiletSeatStarred",
    },

    {
      name: "showercubicle",
      label: "Shower Cubicle",
      switchName: "showercubicleStarred",
    },
    {
      name: "shavingMirror",
      label: "Shaving Mirror",
      switchName: "shavingMirrorStarred",
    },
    {
      name: "adaptedbath",
      label: "Attached Bath",
      switchName: "adaptedbathStarred",
    },
    {
      name: "bidet",
      label: "Bidet",
      switchName: "bidetStarred",
    },
    {
      name: "toiletwithgrabrails",
      label: "Toilet With Grabrails",
      switchName: "toiletwithgrabrailsStarred",
    },
  ]

  const handleChangeAccordion = () => setExpanded("panel3")

  function onSubmit(fields, { setStatus }) {
    const id = bathroomAmenities.id
    const show = []
    const toilet = []
    const tow = []

    setStatus()

    if (fields.bathtub == false) {
      fields.bathtubStarred = false
    }
    if (fields.bubbleBath == false) {
      fields.bubbleBathStarred = false
    }
    if (fields.dentalKit == false) {
      fields.dentalKitStarred = false
    }
    if (fields.geyserWaterheater == false) {
      fields.geyserWaterheaterStarred = false
    }
    if (fields.hairdryer == false) {
      fields.hairdryerStarred = false
    }
    if (fields.hotColdWater == false) {
      fields.hotColdWaterStarred = false
    }
    if (fields.slippers == false) {
      fields.slippersStarred = false
    }

    if (fields.shower == false) {
      fields.showerStarred = false
      fields.showerType = []
    }
    if (fields.toiletPapers == false) {
      fields.toiletPapersStarred = false
    }
    if (fields.toiletries == false) {
      fields.toiletriesType = []
      fields.toiletriesStarred = false
    }
    if (fields.sanitaryBin == false) {
      fields.sanitaryBinStarred = false
    }
    if (fields.showerCap == false) {
      fields.showerCapStarred = false
    }
    if (fields.towels == false) {
      fields.towelsType = []
      fields.towelsStarred = false
    }
    if (fields.bodyScrub == false) {
      fields.bodyScrubStarred = false
    }
    if (fields.bodyWrap == false) {
      fields.bodyWrapStarred = false
    }
    if (fields.hammam == false) {
      fields.hammamStarred = false
    }
    if (fields.bathrobes == false) {
      fields.bathrobesStarred = false
    }
    if (fields.dustbins == false) {
      fields.dustbinsStarred = false
    }
    if (fields.westernToiletSeat == false) {
      fields.westernToiletSeatStarred = false
    }
    if (fields.showercubicle == false) {
      fields.showercubicleStarred = false
    }
    if (fields.shavingMirror == false) {
      fields.shavingMirrorStarred = false
    }
    if (fields.adaptedbath == false) {
      fields.adaptedbathStarred = false
    }
    if (fields.bidet == false) {
      fields.bidetStarred = false
    }
    if (fields.toiletwithgrabrails == false) {
      fields.toiletwithgrabrailsStarred = false
    }
    if (id) {
      updateBathroom(fields)
    } else {
      createBathroom(fields)
    }
  }

  function createStarred(fields) {
    fields.propertyId = propertyId
    fields.displayName = displayName
    const newData = { ...fields, ...create }
    Api.post("RAmenitiesBathroomStarred", newData)
  }

  function updateStarred(fields) {
    fields.updateBy = createdBy
    Api.put("RAmenitiesBathroomStarredupdate", fields)
  }

  function createBathroom(fields) {
    setLoading(true)
    fields.propertyId = propertyId
    fields.displayName = displayName
    const createData = { ...fields, ...create }
    Api.post("RAmenitiesBathroom", createData).then((res) => {
      createStarred(fields)
      toast.success("Successfully Created")
      handleChangeAccordion()
      setLoading(false)
    })
  }

  function updateBathroom(fields) {
    setLoading(true)
    fields.updateBy = createdBy
    Api.put("RAmenitiesBathroomupdate", fields).then((res) => {
      updateStarred(fields)
      toast.success("Successfully Updated")
      handleChangeAccordion()
      setLoading(false)
    })
  }

  return (
    <Formik
      initialValues={bathroomAmenities || initialValues}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) =>
        loading ? (
          <ScreenLoader open={loading} />
        ) : (
          <Form autoComplete="off">
            <Accordion
              square
              expanded={expanded === "panel2"}
              onChange={handleChangePanel("panel2")}
              className={classes.accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#f46d25",
                      background: "white",
                      borderRadius: "5px",
                    }}
                  />
                }
                className={classes.accordionSummaryStyles}
              >
                Bathroom
              </AccordionSummary>
              <AccordionDetails className={classes.outerSectionStyles}>
                <Grid container spacing={1}>
                  {bathroomAmenitiesWithSelect.map((amenity) => (
                    <InlineFormikFieldWithCheckSelectAndSwitch
                      amenity={amenity}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ))}
                  {/* {popularAmenitiesWithMultipleSelectFields.map((amenity) => (
                  <InlineCheckboxSwitchWithMultipleSelectFields
                    amenity={amenity}
                    values={values}
                  />
                ))} */}

                  {bathroomAmenitiesSwitchOnly.map((amenity) => (
                    <FormikInlineCheckboxAndSwitchOnly
                      amenity={amenity}
                      values={values}
                    />
                  ))}
                </Grid>
              </AccordionDetails>
              <center>
                <Button type="submit" size="small">
                  Save and Next
                </Button>
              </center>
            </Accordion>
          </Form>
        )
      }
    </Formik>
  )
}

export default RoomBathroomAmenities
