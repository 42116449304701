import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Switch from "@material-ui/core/Switch";
import { styled } from "@mui/material/styles";
import { Button } from "@material-ui/core";
import XLSX from "xlsx";
import Select from "react-select";
import _ from "lodash";
import { format } from "date-fns";

const FinanceOpition = [
  {
    id: "bookingId",
    label: "Booking Id",
  },
  {
    id: "hotelName",
    label: "Hotel Name",
  },
  {
    id: "agentName",
    label: "Agent Name",
  },
  { id: "customerName", label: "Client Name" },
  {
    id: "checkin",
    label: "Check In Date",
  },
  {
    id: "checkout",
    label: "Check Out Date",
  },
  {
    id: "bookingDate",
    label: "Booking Date",
  },
  {
    id: "noOfRooms",
    label: "No of Rooms",
  },
  {
    id: "noOfAdults",
    label: "No of Adults",
  },

  {
    id: "noOfChildren",
    label: "Number of Children",
  },
  {
    id: "paymentRecievingDate",
    label: "Payment Recieving Date",
  },
  {
    id: "paymentMode",
    label: "Payment Mode",
  },
  {
    id: "paymentReferenceNumber",
    label: "Payment Reference Number",
  },
  {
    id: "amountRecievedByTowno",
    label: "Amount Recieved By Towno ",
  },
  {
    id: "roomrentamount",
    label: "Total Room Rent",
  },
  {
    id: "net",
    label: "Net Booking Amount",
  },
  {
    id: "totalinclusionAmount",
    label: "Total inclusion Amount",
  },
  {
    id: "balancepayabletoTowno",
    label: "Balance payable to Towno",
  },
  {
    id: "customerToPayToHotel",
    label: "Customer To Pay To Hotel",
  },
  {
    id: "townoPayToHotel",
    label: "Towno Pay To The Hotel",
  },
  {
    id: "townotopaytoVendor",
    label: "Towno to pay to Vendor",
  },
  {
    id: "gross",
    label: "Gross Booking Amount",
  },
  {
    id: "townoProfit",
    label: "Towno Profit",
  },
  {
    id: "projector",
    label: "Projector Amount",
  },
  {
    id: "profitaftertax",
    label: "Profit After Tax",
  },
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  background: "#F46D25",
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: "#F46D25",
  color: "#fff",
  fontSize: "12px",
  padding: "5px",
  lineHeight: "1rem",
  minWidth: "100px",
}));

const StyledTableCellInside = styled(TableCell)(({ theme }) => ({
  padding: "5px",
  fontSize: "12px",
  lineHeight: "15px",
}));

const TABLE_HEAD_CONST = [
  {
    id: "bookingId",
    label: "Booking Id",
  },
  {
    id: "hotelName",
    label: "Hotel Name",
  },
  {
    id: "agentName",
    label: "Agent Name",
  },
  { id: "customerName", label: "Client Name" },
  {
    id: "checkin",
    label: "Check In Date",
  },
  {
    id: "checkout",
    label: "Check Out Date",
  },
  {
    id: "bookingDate",
    label: "Booking Date",
  },

  {
    id: "paymentRecievingDate",
    label: "Payment Recieving Date",
  },
  {
    id: "amountRecievedByTowno",
    label: "Amount Recieved By Towno ",
  },

  {
    id: "townoPayToHotel",
    label: "Towno Pay To The Hotel",
  },

  {
    id: "gross",
    label: "Gross Booking Amount",
  },

  {
    id: "profitaftertax",
    label: "Profit After Tax",
  },
];

const filters = [
  {
    id: "numberofRooms",
    label: "No of Rooms",
    selected: false,
  },
  {
    id: "numberofAdults",
    label: "No of Adults",
    selected: false,
  },
  {
    id: "numberofChildren",
    label: "Number of Children",
    selected: false,
  },
  {
    id: "paymentMode",
    label: "Payment Mode",
    selected: false,
  },
  {
    id: "paymentReferenceNumber",
    label: "Payment Reference Number",
    selected: false,
  },
  {
    id: "townoProfit",
    label: "Towno Profit",
    selected: false,
  },
  {
    id: "projectorAmount",
    label: "Projector Amount",
    selected: false,
  },
  {
    id: "townotopaytoVendor",
    label: "Towno to pay to Vendor",
    selected: false,
  },
  {
    id: "roomrentamount",
    label: "Total Room Rent",
    selected: false,
  },
  {
    id: "netBookingAmount",
    label: "Net Booking Amount",
    selected: false,
  },
  {
    id: "totalInclusionAmount",
    label: "Total inclusion Amount",
    selected: false,
  },
  {
    id: "balancepayabletoTowno",
    label: "Balance payable to Towno",
    selected: false,
  },
  {
    id: "customerToPayToHotel",
    label: "Customer To Pay To Hotel",
    selected: false,
  },
];

export default function FinanceReport(props) {
  const [finalReport, setFinalReport] = useState(props.reportData);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(null);

  //!
  const [open, setOpen] = useState(false);

  const [tableHead, setTableHead] = useState(TABLE_HEAD_CONST);

  const [newTable, setNewTable] = useState([
    {
      id: "numberofRooms",
      label: "No of Rooms",
      selected: false,
    },
    {
      id: "numberofAdults",
      label: "No of Adults",
      selected: false,
    },
    {
      id: "numberofChildren",
      label: "Number of Children",
      selected: false,
    },
    {
      id: "paymentMode",
      label: "Payment Mode",
      selected: false,
    },
    {
      id: "paymentReferenceNumber",
      label: "Payment Reference Number",
      selected: false,
    },
    {
      id: "townoProfit",
      label: "Towno Profit",
      selected: false,
    },
    {
      id: "projectorAmount",
      label: "Projected Amount",
      selected: false,
    },
    {
      id: "townotopaytoVendor",
      label: "Towno to pay to Vendor",
      selected: false,
    },
    {
      id: "roomrentamount",
      label: "Total Room Rent",
      selected: false,
    },
    {
      id: "netBookingAmount",
      label: "Net Booking Amount",
      selected: false,
    },
    {
      id: "totalInclusionAmount",
      label: "Total inclusion Amount",
      selected: false,
    },
    {
      id: "balancepayabletoTowno",
      label: "Balance payable to Towno",
      selected: false,
    },
    {
      id: "customerToPayToHotel",
      label: "Customer To Pay To Hotel",
      selected: false,
    },
  ]);
  const [headValues, setHeadValues] = useState([
    {
      id: "numberofRooms",
      label: "No of Rooms",
      selected: false,
    },
    {
      id: "numberofAdults",
      label: "No of Adults",
      selected: false,
    },
    {
      id: "numberofChildren",
      label: "Number of Children",
      selected: false,
    },
    {
      id: "paymentMode",
      label: "Payment Mode",
      selected: false,
    },
    {
      id: "paymentReferenceNumber",
      label: "Payment Reference Number",
      selected: false,
    },
    {
      id: "townoProfit",
      label: "Towno Profit",
      selected: false,
    },
    {
      id: "projectorAmount",
      label: "Projected Amount",
      selected: false,
    },
    {
      id: "townotopaytoVendor",
      label: "Towno to pay to Vendor",
      selected: false,
    },
    {
      id: "roomrentamount",
      label: "Total Room Rent",
      selected: false,
    },
    {
      id: "netBookingAmount",
      label: "Net Booking Amount",
      selected: false,
    },
    {
      id: "totalInclusionAmount",
      label: "Total inclusion Amount",
      selected: false,
    },
    {
      id: "balancepayabletoTowno",
      label: "Balance payable to Towno",
      selected: false,
    },
    {
      id: "customerToPayToHotel",
      label: "Customer To Pay To Hotel",
      selected: false,
    },
  ]);
  const handleChange = (i) => {
    console.log(":Index:", i);
    const values = [...headValues];
    values[i].selected = !values[i].selected;

    setHeadValues(values);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //!

  useEffect(() => {
    setFinalReport(props.reportData);
  }, [props]);

  const HandleSort = (sort) => {
    setSort(sort);
    let sorted = _.orderBy(finalReport, [sort.value], ["asec"]);
    setFinalReport(sorted);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const downloadExcel = () => {
    const newData = finalReport.map((row) => {
      delete row.id;
      return row;
    });
    //console.log(newData);
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Finance");
    // const buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "Finance.xlsx");
  };

  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item md={7}>
          <b>Finance Report</b>
        </Grid>
        <Grid item md={2}>
          <div style={{ position: "relative" }}>
            <span
              style={{
                position: "absolute",
                top: "-9px",
                zIndex: "1",
                left: "12px",
                color: "#fff",
                background: "#F46D25",
                borderRadius: "4px",
                padding: "1px 4px",
                fontSize: "12px",
              }}
            >
              Sort By
            </span>
            <Select
              options={FinanceOpition}
              placeholder=""
              value={sort}
              onChange={HandleSort}
              styles={{
                container: (provided) => ({
                  ...provided,
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9,
                }),
                control: (base, state) => ({
                  ...base,
                  "&:hover": { borderColor: "#f46d25" },
                  borderColor: "#f46d25",
                  boxShadow: "none",
                }),
              }}
            />
          </div>
        </Grid>
        <Grid item md={2} style={{ textAlign: "right" }}>
          <Button onClick={downloadExcel}>Download As Excel</Button>
        </Grid>
        <Grid item md={1} style={{ textAlign: "right" }}>
          <Button onClick={() => setOpen(true)}>Filter</Button>
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          style={{
            backgroundColor: "#000",
            color: "#fff",
            textAlign: "center",
            padding: 4,
          }}
        >
          <Typography variant="h6">Column Settings</Typography>
        </div>
        <DialogContent>
          <Grid container spacing={2}>
            {headValues.map((item, i) => (
              <React.Fragment key={i}>
                <Grid item xs={3}>
                  <Typography variant="body1" sx={{ fontWeight: 800 }}>
                    {item.label}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Switch
                    checked={item.selected}
                    onChange={() => handleChange(i)}
                    // name="checkedB"
                    inputProps={{ role: "switch" }}
                    color="primary"
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              const values = headValues.filter(
                (item) => item.selected === true
              );
              // const columns=JSON.parse(JSON.stringify(headValues))
              const columns = _.cloneDeep(headValues);
              setTableHead([...TABLE_HEAD_CONST, ...values]);
              setNewTable(columns);
              handleClose();
            }}
            color="primary"
          >
            Submit
          </Button>
          <Button
            onClick={() => {
              setHeadValues(_.cloneDeep(newTable));
              handleClose();
            }}
            color="secondary"
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <StyledTableRow>
                {tableHead.map((column) => (
                  <StyledTableCell key={column.id} align={column.align}>
                    {column.label}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {finalReport
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow key={row.id}>
                      <StyledTableCellInside
                        style={{ textTransform: "capitalize" }}
                      >
                        {row.bookingId}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.hotelName}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.agentName}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.customerName}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.checkInDate !== null &&
                        (row.checkInDate || "").trim() !== ""
                          ? format(new Date(row.checkInDate), "dd-MM-yyyy")
                          : ""}
                        {/* {format(new Date(row.checkInDate), "dd-MM-yyyy")} */}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.checkOutDate !== null &&
                        (row.checkOutDate || "").trim() !== ""
                          ? format(new Date(row.checkOutDate), "dd-MM-yyyy")
                          : ""}
                        {/* {format(new Date(row.checkOutDate), "dd-MM-yyyy")} */}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.bookingDate !== null &&
                        (row.bookingDate || "").trim() !== ""
                          ? format(new Date(row.bookingDate), "dd-MM-yyyy")
                          : ""}
                        {/* {format(new Date(row.bookingDate), "dd-MM-yyyy")} */}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.paymentRecievingDate !== null &&
                        (row.paymentRecievingDate || "").trim() !== ""
                          ? format(
                              new Date(row.paymentRecievingDate),
                              "dd-MM-yyyy"
                            )
                          : ""}
                        {/* {format(new Date(row.paymentRecievingDate), "dd-MM-yyyy")} */}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.amountRecievedByTowno}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.townoPayToHotel}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.grossBookingAmount}
                      </StyledTableCellInside>

                      <StyledTableCellInside>
                        {row.townoProfitTax}
                      </StyledTableCellInside>
                      {newTable
                        .filter?.((item) => item.selected === true)
                        ?.map((val, i) => {
                          return (
                            <StyledTableCellInside key={i}>
                              {row?.[val.id]}
                            </StyledTableCellInside>
                          );
                        })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={finalReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
