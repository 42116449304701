import { Button, makeStyles, TextField } from "@material-ui/core"
import { Grid } from "@mui/material"
import { Field, Form, Formik } from "formik"
import { useEffect, useState } from "react"
import API from "../../Service/Api"
import { toast } from "react-toastify"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import { baseurl } from "../../Service/httpCommon"
import ScreenLoader from "../../components/loadingScreen/screenLoader.component"
const useStyles = makeStyles(() => ({
  root: {
    margin: "0px",
    padding: "75px 18px 70px 25px",
  },
  pageHeader: {
    fontSize: "clamp(25px,10vw,16px)",
    color: "#f46d25",
    fontWeight: "bold",
  },
  sectionHeader: {
    background: "#f46d25",
    color: "white",
    fontWeight: "bold",
    padding: "9px 18px ",
    borderRadius: "6px",
    fontSize: "18px",
    marginBottom: "20px",
  },
  sectionStyles: {
    marginTop: "15px",
  },
  inputLabelProps: {
    color: "black",
  },
  checkboxStyling: {
    "& .MuiSvgIcon-root": {
      color: "#f46d25",
    },
  },
  innerSectionStyles: {
    border: "1px solid #C4C4C4",
    padding: "10px",
    borderRadius: "5px",
  },
  innerSectionHeader: {
    fontWeight: "bold",
    color: "#000000",
    margin: "10px 0px",
  },
  error: {
    marginBottom: "3px",
    color: "red",
    textDecoration: "italic",
  },
}))

const OnBoardTravelAgents = () => {
  const classes = useStyles()
  const uniqueID = localStorage.getItem("unique_id")
  const auth = localStorage.getItem("auth")
  const { id } = useParams()
  const [loading, setLoading] = useState(() => false)
  const history = useHistory()

  const [formInitialData, setFormInitialData] = useState(() => ({
    travelAgencyName: "",
    travelAgentNo: "",
    travelAgentAltNo: "",
    mailID: "",
    altMailID: "",
    website: "",
    designation: "",
    pocName: "",
    onBoardedBy: "",
    agentReferredBy: "",
    type: "",
    city: "",
    venueDetails: "",
  }))

  const fetchAgentData = async () => {
    setLoading(true)
    try {
      const { data } = await API.get(`${baseurl}travelagency/${id}`)
      setFormInitialData(data)
      setLoading(false)
    } catch (error) {
      toast.error("Failed to detch the data")
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      fetchAgentData()
    }
  }, [])

  const onSubmit = async (fields) => {
    const postData = {
      id: id ? id : "",
      uniqueId: uniqueID,
      ...fields,
      createdBy: id ? fields.createdBy : auth,
    }
    try {
      const data = await API.post("travelagency", postData)
      console.log(data)
      toast.success(
        id ? "Agent Record Updated" : "Agent Onboarded Successfully"
      )
      history.push("/konnect/agencyList")
    } catch (error) {
      toast.error(error)
    }
  }

  return (
    <section className={classes.root}>
      <header className={classes.pageHeader}>Onboard Travel Agents</header>
      <section className={classes.sectionStyles}>
        <header className={classes.sectionHeader}>Basic Details</header>
        <Formik
          initialValues={formInitialData}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({ handleChange, values }) =>
            loading ? (
              <ScreenLoader open={loading} />
            ) : (
              <>
                <Form>
                  <section className={classes.innerSectionStyles}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Field
                          as={TextField}
                          name="travelAgencyName"
                          label="Travel Agency Name"
                          type="text"
                          value={values.travelAgencyName}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          InputLabelProps={{
                            className: classes.inputLabelProps,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          as={TextField}
                          name="travelAgentNo"
                          label="Number"
                          type="text"
                          value={values.travelAgentNo}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          InputLabelProps={{
                            className: classes.inputLabelProps,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          as={TextField}
                          name="travelAgentAltNo"
                          label="Alt. Number"
                          type="text"
                          value={values.travelAgentAltNo}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          InputLabelProps={{
                            className: classes.inputLabelProps,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          as={TextField}
                          name="mailID"
                          label="Email ID"
                          type="email"
                          value={values.mailID}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          InputLabelProps={{
                            className: classes.inputLabelProps,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          as={TextField}
                          name="altMailID"
                          label="Alt. Email ID"
                          type="email"
                          value={values.altMailID}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          InputLabelProps={{
                            className: classes.inputLabelProps,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          as={TextField}
                          name="website"
                          label="Website"
                          type="text"
                          value={values.website}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          InputLabelProps={{
                            className: classes.inputLabelProps,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          as={TextField}
                          name="designation"
                          label="Designation"
                          type="text"
                          value={values.designation}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          InputLabelProps={{
                            className: classes.inputLabelProps,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          as={TextField}
                          name="pocName"
                          label="POC Name"
                          type="text"
                          value={values.pocName}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          InputLabelProps={{
                            className: classes.inputLabelProps,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          as={TextField}
                          name="onBoardedBy"
                          label="OnBoarded By"
                          type="text"
                          value={values.onBoardedBy}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          InputLabelProps={{
                            className: classes.inputLabelProps,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          as={TextField}
                          name="agentReferredBy"
                          label="Agent Referred By"
                          type="text"
                          value={values.agentReferredBy}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          InputLabelProps={{
                            className: classes.inputLabelProps,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          as={TextField}
                          name="type"
                          label="Type"
                          type="text"
                          value={values.type}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          InputLabelProps={{
                            className: classes.inputLabelProps,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          as={TextField}
                          name="city"
                          label="City"
                          type="text"
                          value={values.city}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          InputLabelProps={{
                            className: classes.inputLabelProps,
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          as={TextField}
                          name="venueDetails"
                          label="Venue Details"
                          type="text"
                          value={values.venueDetails}
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          InputLabelProps={{
                            className: classes.inputLabelProps,
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </section>
                  <center>
                    <Button
                      size="small"
                      type="submit"
                      style={{ marginTop: "20px" }}
                    >
                      {id ? "Update" : "Submit"}
                    </Button>
                    <Button
                      onClick={() => history.goBack()}
                      size="small"
                      style={{
                        background: "black",
                        marginLeft: "20px",
                        marginTop: "20px",
                      }}
                    >
                      Back
                    </Button>
                  </center>
                </Form>
              </>
            )
          }
        </Formik>
      </section>
    </section>
  )
}

export default OnBoardTravelAgents
