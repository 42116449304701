import {
  Button,
  IconButton,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  makeStyles,
  styled,
} from "@material-ui/core"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import Api from "../../Service/Api"
import { baseurl } from "../../Service/httpCommon"
import EditIcon from "@material-ui/icons/Edit"
import { filter } from "lodash"
import SearchIcon from "@material-ui/icons/SearchOutlined"
import ClearIcon from "@material-ui/icons/Clear"
import { twnButtonStyles } from "../../utils/townoStyle"

import { Visibility } from "@material-ui/icons"
import { ReactComponent as GSTInvoiceLogo } from "../../assets/logo/GST_logo.svg"
import ScreenLoader from "../../components/loadingScreen/screenLoader.component"
import GSTVoucherPDF from "../../assets/Icons/voucherIcons/GST.png"
import html2pdf from "html2pdf.js"
import ReactDOMServer from "react-dom/server"
import FileCopyIcon from "@material-ui/icons/FileCopy"

import { toast } from "react-toastify"
import { format } from "date-fns"
import CopyActivity from "./copyActivity.component"
import DownloadActivityVoucher from "./activityPdf.component"

{
  /*Function for sorting records starts*/
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  if (query) {
    return filter(
      array,
      (_activitydata) =>
        _activitydata.clientName.toLowerCase().indexOf(query.toLowerCase()) !==
        -1
      //   ||
      // _invoicedata.hotelName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    )
  }
  return stabilizedThis.map((el) => el[0])
}
{
  /*Function for sorting records ends*/
}

const ActivityList = () => {
  const classes = useStyles()
  const history = useHistory()
  const [activityListData, setActivityListData] = useState([])
  var uniqueId = localStorage.getItem("unique_id")
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)

  const [loading, setLoading] = useState(false)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [width])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value)
    setPage(0)
  }

  const fetchListData = async () => {
    setLoading(true)
    try {
      const response = await Api.get(
        `${baseurl}getActivityBookingsAll/${uniqueId}`
      )

      console.log(response.data)
      setActivityListData(response.data)
      setLoading(false)
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchListData()
  }, [])

  const tableHeadValues = [
    "Activity No.",
    "Client Name",
    "Client Number",
    "Created Date",
    "Total Amount",
    "Action",
  ]

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    background: "#F46D25",
    height: "40px",
  }))
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    background: "#F46D25",
    color: "#fff",
    fontSize: "12px",
    padding: "5px",
    lineHeight: "1rem",
    minWidth: "100px",
  }))

  const StyledTableCellInside = styled(TableCell)(({ theme }) => ({
    padding: "5px",
    fontSize: "14px",
    lineHeight: "15px",
  }))
  const [order, setOrder] = useState("desc")
  const [orderBy, setOrderBy] = useState("createdAt")
  const [textToSearch, setTextToSearch] = useState("")

  const filteredActivityDataList = applySortFilter(
    activityListData,
    getComparator(order, orderBy),
    textToSearch
  )

  const [isDownloading, setIsDownloading] = useState(false)

  const fetchActivity = async (id) => {
    setLoading(true)
    try {
      const res = await Api.get(`getActivityBooking/${id}`, {})
      if (res.status == 200) {
        return res.data
      }
    } catch (error) {
      toast.error("Unable to fetch the data.")
    } finally {
      setLoading(false)
    }
  }

  const uniqueid = localStorage.getItem("unique_id")

  const createActivityPDF = async (id, fileName) => {
    setIsDownloading(true)
    try {
      const activityData = await fetchActivity(id)
      const termsandcondition = await Api.get(
        `agenthoteltermandcondition/${uniqueid}/transferTermCondition`
      ).then((res) => res.data)

      const cancelationpolicy = await Api.get(
        `agenthoteltermandcondition/${uniqueid}/transferCancellationPolicy`
      ).then((res) => res.data)

      let pdfComponent = await (
        <DownloadActivityVoucher
          activityData={activityData}
          cancelationpolicy={cancelationpolicy}
          termsandcondition={termsandcondition}
        />
      )

      let jsxString = await ReactDOMServer.renderToStaticMarkup(pdfComponent)

      const pdfOptions = {
        // margin: 10,
        padding: "0",
        filename: fileName,
        image: { type: "jpeg", quality: 1 },
        html2canvas: { scale: 3, useCORS: true },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      }

      const element = document.createElement("div")
      element.innerHTML = jsxString

      await html2pdf().from(element).set(pdfOptions).save()
      setIsDownloading(false)
    } catch (error) {
      console.error("Error creating PDF:", error)
      setIsDownloading(false)
      alert(error)
    }
  }

  return (
    <div style={twnButtonStyles.allPages}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <h1 style={twnButtonStyles.xlFonts}>Activity List</h1>
        <div style={{ display: "flex" }}>
          <div
            style={{
              position: "absolute",
              display: "flex",
              marginLeft: "-50px",
            }}
          >
            <Slide direction="left" in={search} mountOnEnter unmountOnExit>
              <TextField
                type="search"
                variant="outlined"
                value={textToSearch}
                onChange={(e) => setTextToSearch(e.target.value)}
                placeholder="Search by Client"
                InputProps={{
                  style: {
                    height: "32px",
                    marginLeft: "-205px",
                    position: "absolute",
                    width: "230px",
                    borderRadius: "5px 10px 10px 5px",
                  },
                }}
              />
            </Slide>
            <IconButton
              onClick={() => {
                setSearch(!search)
                search === false && setTextToSearch("")
              }}
              style={{
                background: "#F46D25",
                color: "white",
                marginRight: "10px",
                width: "32px",
                height: "32px",
                padding: "10px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            >
              {search ? <ClearIcon /> : <SearchIcon />}
            </IconButton>
          </div>

          <Button
            style={{ height: "40px", ...twnButtonStyles.orangeBtn }}
            onClick={() => {
              sessionStorage.clear()
              history.push("/konnect/activity")
            }}
          >
            Create New Activity
          </Button>
        </div>
      </div>
      <TableContainer component={Paper} style={{ marginTop: "0.5%" }}>
        <Table stickyHeader size="small">
          <TableHead>
            <StyledTableRow>
              {tableHeadValues.map((value) => (
                <StyledTableCell
                  key={value}
                  style={{
                    color: "white",

                    fontSize: "15px",
                  }}
                  align="center"
                >
                  {value}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {activityListData &&
              filteredActivityDataList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow key={row.id} style={{ height: "40px" }}>
                      <StyledTableCellInside
                        style={{
                          textTransform: "capitalize",
                          color: "#f46d25",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        align="center"
                      >
                        <span>{row.activityId}</span>
                      </StyledTableCellInside>
                      <StyledTableCellInside align="center">
                        {row.clientName}
                      </StyledTableCellInside>
                      <StyledTableCellInside align="center">
                        {row.clientNumber}
                      </StyledTableCellInside>
                      <StyledTableCellInside align="center">
                        {format(new Date(row.createdAt), "dd MMM yy")}
                      </StyledTableCellInside>

                      <StyledTableCellInside align="center">
                        {row.totalAmount}
                      </StyledTableCellInside>

                      <StyledTableCellInside
                        align="center"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        <Tooltip title="Edit">
                          <IconButton
                            aria-label="edit"
                            style={{ padding: "0px", color: "#F46D25" }}
                            onClick={() =>
                              history.push(`/konnect/activity/${row.id}`)
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <IconButton
                          style={{
                            padding: "0px",
                            color: "#F46D25",
                            marginLeft: "0.6rem",
                          }}
                          title="view"
                          aria-label="view"
                          onClick={() => {
                            history.push(`/konnect/viewActivity/${row.id}`)
                          }}
                        >
                          <Visibility />
                        </IconButton>
                        <IconButton
                          style={{ padding: "0px", color: "#F46D25" }}
                          aria-label="Activity Voucher"
                          title="Download Activity"
                          onClick={() => createActivityPDF(row.id, `${row.id}`)}
                        >
                          <GSTInvoiceLogo
                            style={{
                              height: "25px",
                              width: "100%",
                            }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            window.open(`/konnect/copyActivity/${row.id}`)
                          }
                          style={{ padding: "0px", marginLeft: "0.5rem" }}
                        >
                          <Tooltip title="Copy Activity" arrow>
                            <FileCopyIcon
                              style={{
                                color: "#f46d25",
                                cursor: "pointer",
                              }}
                            />
                          </Tooltip>
                        </IconButton>
                      </StyledTableCellInside>
                    </TableRow>
                  )
                })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredActivityDataList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <ScreenLoader open={loading || isDownloading} />
    </div>
  )
}

export default ActivityList

const useStyles = makeStyles(() => ({
  root: {
    // padding: "100px 0px",
    // margin: "0px 30px",
    padding: "5% 0.5% 1% 1.5%",
    "@media (max-width: 767px)": {
      margin: "0px 10px",
    },
  },

  table: {
    backgroundColor: "#f46d25",
    color: "white",
  },

  icon: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    position: "absolute",
    right: "15px",
    top: "5px",
    color: "#f46d25",
  },

  plus: {
    cursor: "pointer",
    color: "#f46d25",
    position: "relative",
    top: "7px",
    "@media (max-width: 767px)": {
      fontSize: "18px",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "40px",
    color: "#f46d25",
    margin: "0px",
  },
  titles: {
    padding: "10px",
    fontSize: "28px",
    fontWeight: "lighter",
    color: "#fff",
    background: "#f46d25",
    margin: "20px 10px 10px 0px",
    borderRadius: "8px 8px 0px 0px",
  },
  formControl: {
    minWidth: "60px",
    height: "40px",
    background: "white",
  },
}))
