import { createContext, useContext, useState } from "react"
import { CaptchContext } from "./captcha.context"

export const ContactModalContext = createContext({
  open: false,
  toggleModal: () => null,
  onClose: () => null,
})

export const ContactModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false)

  const { setValid } = useContext(CaptchContext)

  const toggleModal = () => {
    setOpen((c) => !c)
  }

  const onClose = () => {
    setValid(false)
    setOpen(false)
  }

  return (
    <ContactModalContext.Provider value={{ open, toggleModal, onClose }}>
      {children}
    </ContactModalContext.Provider>
  )
}
