import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import Api from "../../../Service/Api";
import FormikInlineCheckboxAndSwitchOnly from "../../../components/amenityComponents/inlineCheckBoxAndSwitchOnly.component";
import InlineFormikFieldWithCheckSelectAndSwitch from "../../../components/amenityComponents/inlineCheckBoxWithSelectAndSwitch.component";
import ScreenLoader from "../../../components/loadingScreen/screenLoader.component";

const initialValues = {
  disinfection: false,
  disinfectionStarred: false,
  disinfectionType: [],
  ppekit: false,
  ppekitStarred: false,
  contactlessroomservice: false,
  contactlessroomserviceStarred: false,
  thermalscreening: false,
  thermalscreeningStarred: false,
  sanitizersinstalled: false,
  sanitizersinstalledStarred: false,
  contactlesscheckin: false,
  contactlesscheckinStarred: false,
  disinfectantwipes: false,
  disinfectantwipesStarred: false,
  disinfectantwipesType: "",
  sanitizers: false,
  sanitizersStarred: false,
  sanitizersType: "",
};

const safetyAndHygieneSwitchOnly = [
  {
    name: "ppekit",
    label: "PPE",
    switchName: "ppekitStarred",
  },
  {
    name: "contactlessroomservice",
    label: "Contactless Room Service",
    switchName: "contactlessroomserviceStarred",
  },

  {
    name: "thermalscreening",
    label: "Thermal Screening",
    switchName: "thermalscreeningStarred",
  },
  {
    name: "sanitizersinstalled",
    label: "Sanitizers Installed",
    switchName: "sanitizersinstalledStarred",
  },

  {
    name: "contactlesscheckin",
    label: "Contactless Check-In",
    switchName: "contactlesscheckinStarred",
  },
];

const SafetyAndHygiene = ({
  classes,
  handleChangePanel,
  expanded,
  setExpanded,
}) => {
  const uniqueid = localStorage.getItem("unique_id");
  const createdBy = localStorage.getItem("auth");
  const create = { uniqueId: uniqueid, createdBy: createdBy };
  const { propertyId } = useParams();
  const [safetyAndHygiene, setSafetyAndHygiene] = useState(() => {});
  const [disinfection, setDisinfection] = useState([]);

  const Disinfection = async () => {
    const DisinfectionData = {
      type: "Disinfection",
    };
    await Api.post("productdropdownonly", DisinfectionData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response) => {
      setDisinfection(response.data);
    });
  };

  useEffect(() => {
    if (propertyId && expanded === "panel8") {
      Disinfection();
    }
  }, [expanded]);

  const handleChangeAccordion = () => {
    setExpanded("panel9");
  };

  const fetchSafetyAndHygiene = async () => {
    setLoading(true);
    try {
      const normalData = await Api.get(
        `AmenitiesSafetyAndHygienevalue/${propertyId}`
      );
      const switchData = await Api.get(
        `AmenitiesSafetyAndHygieneStarredvalue/${propertyId}`
      );
      console.log(normalData.data);
      console.log(switchData.data);
      setSafetyAndHygiene({ ...normalData.data, ...switchData.data });
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (propertyId && expanded === "panel8") {
      fetchSafetyAndHygiene();
    }
  }, [expanded]);

  const safetyAndHygieneSelectOnly = [
    {
      name: "disinfection",
      label: "Disinfection",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "disinfectionType",
      switchName: "disinfectionStarred",
      dropdDownOptions: disinfection,
    },

    {
      name: "disinfectantwipes",
      label: "Disinfectant Wipes",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "disinfectantwipesType",
      switchName: "disinfectantwipesStarred",
      dropdDownOptions: [
        { value: "free", label: "Free" },
        { value: "paid", label: "Paid" },
      ],
    },

    {
      name: "sanitizers",
      label: "Sanitizers",
      select: true,
      selectType: "singleValue",
      selectLabel: "Type",
      selectFieldName: "sanitizersType",
      switchName: "sanitizersStarred",
      dropdDownOptions: [
        { value: "free", label: "Free" },
        { value: "paid", label: "Paid" },
      ],
    },
  ];

  const [loading, setLoading] = useState(() => false);

  function onSubmit(fields, { setStatus }) {
    const id = safetyAndHygiene.id;

    if (fields.disinfection == false) {
      fields.disinfectionStarred = false;
      fields.disinfectionType = [];
    }
    if (fields.ppekit == false) {
      fields.ppekit = false;
      fields.ppekitStarred = false;
    }
    if (fields.contactlessroomservice == false) {
      fields.contactlessroomserviceStarred = false;
    }
    if (fields.thermalscreening == false) {
      fields.thermalscreeningStarred = false;
    }
    if (fields.sanitizersinstalled == false) {
      fields.sanitizersinstalledStarred = false;
    }
    if (fields.contactlesscheckin == false) {
      fields.contactlesscheckinStarred = false;
    }
    if (fields.disinfectantwipes == false) {
      fields.disinfectantwipesStarred = false;
      fields.disinfectantwipesType = "";
      fields.disinfectantwipesType1 = "";
    }
    if (fields.sanitizers == false) {
      fields.sanitizersStarred = false;
      fields.sanitizersType = "";
      fields.sanitizersType1 = "";
    }
    setStatus();
    if (id) {
      updateSafe(fields);
    } else {
      createSafe(fields);
    }
  }

  function createStarred(fields) {
    fields.propertyId = propertyId;
    const newData = { ...fields, ...create };
    Api.post("AmenitiesSafetyAndHygieneStarred", newData);
  }

  function updateStarred(fields) {
    fields.updateBy = createdBy;
    Api.put("AmenitiesSafetyAndHygieneStarredupdate", fields);
  }

  function createSafe(fields) {
    setLoading(true);
    fields.propertyId = propertyId;
    const newData = { ...fields, ...create };
    Api.post("AmenitiesSafetyAndHygiene", newData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      if (res.status === 200) {
        createStarred(fields);
        toast.success("Successfully Created");
        handleChangeAccordion();
        setLoading(false);
      }
    });
  }

  function updateSafe(fields) {
    setLoading(true);
    fields.updateBy = createdBy;
    Api.put("AmenitiesSafetyAndHygieneupdate", fields, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    }).then((res) => {
      if (res) {
        updateStarred(fields);
        toast.success("Successfully Updated");
        handleChangeAccordion();
        setLoading(false);
      }
    });
  }

  return (
    <Formik
      initialValues={safetyAndHygiene || initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue }) =>
        loading ? (
          <ScreenLoader open={loading} />
        ) : (
          <Form autoComplete="off">
            <Accordion
              square
              expanded={expanded === "panel8"}
              onChange={handleChangePanel("panel8")}
              className={classes.accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#f46d25",
                      background: "white",
                      borderRadius: "5px",
                    }}
                  />
                }
                className={classes.accordionSummaryStyles}
              >
                Safety & Hygiene
              </AccordionSummary>
              <AccordionDetails className={classes.outerSectionStyles}>
                <Grid container spacing={3}>
                  {safetyAndHygieneSelectOnly.map((amenity) => (
                    <InlineFormikFieldWithCheckSelectAndSwitch
                      amenity={amenity}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ))}

                  {/**Safety and Hygiene switch only */}
                  {safetyAndHygieneSwitchOnly.map((amenity) => (
                    <FormikInlineCheckboxAndSwitchOnly
                      amenity={amenity}
                      values={values}
                    />
                  ))}
                </Grid>
              </AccordionDetails>
              <center>
                <Button
                  style={{ height: "30px", margin: "10px 0px" }}
                  type="submit"
                >
                  Save and Next
                </Button>
              </center>
            </Accordion>
          </Form>
        )
      }
    </Formik>
  );
};

export default SafetyAndHygiene;
