import React from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Line, Bar } from "react-chartjs-2"
// import faker from 'faker';
import { format } from "date-fns"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export const options = {
  responsive: true,
  elements: {
    line: {
      tension: 0.5, // disables bezier curves
    },
  },
  maintainAspectRatio: false,
  interaction: {
    // mode: 'index' as const,
    mode: "index",
    intersect: false,
  },
  stacked: false,
  scales: {
    y: {
      //   type: 'linear' as const,
      type: "linear",
      display: true,
      // position: 'left' as const,
      position: "left",
      ticks: {
        color: "#f46d25",
      },
    },
    y1: {
      // type: 'linear' as const,
      type: "linear",
      display: true,
      // position: 'right' as const,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        color: "#00aa00",
      },

      rtl: true,
    },
  },
}

export default function MultiLineAgentChart({ agentRevenueData, notAdmin }) {
  let role = localStorage.getItem("role")

  // const month =

  const revenueData = agentRevenueData.map((e) => {
    return e.totalrevenue
  })
  const profitAfterTaxData = agentRevenueData.map((e) => {
    return e.profitaftertax
  })
  let labels = agentRevenueData.map((e) => {
    return e.name
  })

  const totalRevenueMap = new Map()

  agentRevenueData?.forEach((agent) => {
    if (totalRevenueMap.has(agent.name)) {
      totalRevenueMap.set(
        agent.name,
        totalRevenueMap.get(agent.name) + Number(agent.totalrevenue)
      )
    } else {
      totalRevenueMap.set(agent.name, Number(agent.totalrevenue))
    }
  })

  let keys = Array.from(totalRevenueMap.keys())

  const revenueValues = Array.from(totalRevenueMap.values())

  const profitAfterTaxMap = new Map()

  agentRevenueData?.forEach((agent) => {
    if (profitAfterTaxMap.has(agent.name)) {
      profitAfterTaxMap.set(
        agent.name,
        profitAfterTaxMap.get(agent.name) + Number(agent.profitaftertax)
      )
    } else {
      profitAfterTaxMap.set(agent.name, Number(agent.profitaftertax))
    }
  })

  const profitAfterTaxValues = Array.from(profitAfterTaxMap.values())

  console.log({ profitAfterTaxValues })

  console.log({ agentRevenueData })

  if (notAdmin) {
    keys = agentRevenueData.map((e) => {
      return format(new Date(e.name), "d")
    })
  }

  if (localStorage.getItem("role") === "Hotel Admin") {
    labels = agentRevenueData.map((agent) => agent.name)
  }

  console.log({ revenueData, profitAfterTaxData, labels })
  console.log({ keys, revenueValues, profitAfterTaxValues })

  const data = {
    labels: keys,
    datasets: [
      {
        label: "Total Revenue",
        data: revenueValues,
        borderColor: "#f46d25",
        backgroundColor: "#f46d2555",
        yAxisID: "y",
        position: "left",
      },
      {
        label: "Profit After Tax",
        data: profitAfterTaxValues,
        borderColor: "#00aa00",
        backgroundColor: "#00aa0055",
        yAxisID: "y1",
        position: "right",
      },
    ],
  }

  return <Line options={options} data={data} />
}
