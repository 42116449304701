import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";

const AgentHotelCopyPropertyCardSkeleton = ({ length }) => {
  const [width, setWidth] = useState(() => window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.removeEventListener("resize", handleWindowSizeChange);
  }, [width]);

  return (
    <Grid container spacing={3}>
      {Array.from({ length: length }, (value) =>
        width <= 768 ? (
          <Grid item container xs={12} display="flex" spacing={1} key={value}>
            <Card style={{ width: "100%" }}>
              <CardActionArea
                style={{ background: "rgba(196, 196, 196, 0.21)" }}
              >
                <CardMedia height="116px" style={{ borderRadius: "10px" }}>
                  <Skeleton width="100%" height="100%" />
                </CardMedia>
                <CardContent style={{ paddingRight: "0px" }}>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      container
                      spacing={1}
                      display="flex"
                      justifyContent="space-between"
                      xs={12}
                    >
                      <Grid item xs={7}>
                        <Typography
                          color="primary"
                          style={{
                            fontWeight: "bold",
                            fontSize: "clamp(13px,10vw,17px)",
                          }}
                        >
                          <Skeleton width="100%" height="100%" />
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography
                          style={{
                            color: "#343A40",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          <Skeleton width="100%" height="100%" />
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      spacing={1}
                      display="flex"
                      justifyContent="space-around"
                    >
                      <Grid item xs={7}>
                        <Typography
                          variant="h8"
                          style={{ color: "#343A40", fontWeight: "bold" }}
                        >
                          <Skeleton width="100%" height="100%" />
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Button size="small">
                          <Skeleton width="100%" height="100%" />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ) : (
          <Grid item container xs={12} display="flex" spacing={1}>
            <Grid item xs={4}>
              <Skeleton
                height={148}
                width={295}
                variant="rect"
                animation="wave"
              />
            </Grid>
            <Grid item container xs={8} display="flex" flexDirection="column">
              <Typography variant="h4">
                <Skeleton width="60%" />
                <Skeleton width="30%" />
              </Typography>

              <div style={{ display: "flex" }}>
                <Typography variant="button" style={{ marginRight: "15px" }}>
                  <Skeleton width={90} height={60} />
                </Typography>
                <Typography variant="button">
                  <Skeleton width={90} height={60} />
                </Typography>
              </div>
            </Grid>
          </Grid>
        )
      )}
    </Grid>
  );
};

export default AgentHotelCopyPropertyCardSkeleton;
