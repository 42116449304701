import React from "react"
import { styled } from "@mui/material/styles"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import {
  Stack,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Tooltip,
  IconButton,
  Alert,
  Snackbar,
} from "@mui/material"
import EditIcon from "@material-ui/icons/Edit"
import VisibilityIcon from "@material-ui/icons/Visibility"
import { Link } from "react-router-dom"
import { format, differenceInDays, parse } from "date-fns"
import TablePagination from "@mui/material/TablePagination"
import { formatter } from "../../utils/formatNumber"
import { twnButtonStyles } from "../../utils/townoStyle"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import BlockIcon from "@material-ui/icons/Block"
import { ReactComponent as CustomerVoucherIcon } from "../../assets/logo/Customer Voucher.svg"
import Api from "../../Service/Api"
import { useState } from "react"
import ScreenLoader from "../../components/loadingScreen/screenLoader.component"
import moment from "moment"
import PackagePDF from "../../components/pdfVoucherComponents/PackagePDF.component"
import html2pdf from "html2pdf.js"
import ReactDOMServer from "react-dom/server"
import PackagePDFIcon from "../../assets/Icons/voucherIcons/Package.png"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f46d25",
    color: "#fff",
    fontSize: 14,
    // fontWeight: 'bold'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    backgroundColor: "#fff",
    boxShadow: "none",
    borderRadius: "5px",
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  border: "1px solid #eee",
}))

export default function PackageTable({ tableData }) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  let hasAdmin = localStorage.getItem("role")
  let checkAgent = localStorage.getItem("agent")

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const unique_id = localStorage.getItem("unique_id")
  const [isDownloading, setIsDownloading] = useState(false)

  const DownloadPackage = (data) => {
    setIsDownloading(true)
    console.log(data)
    let data1 = data.packageId
    const link = document.createElement("a")
    link.target = "_blank"
    link.download = `${data1}.pdf`
    Api.get(`packagegenpdf/${unique_id}/${data.packageId}`, {
      responseType: "blob",
    })
      .then((res) => {
        console.log(res.status)
        link.href = URL.createObjectURL(new Blob([res.data], { type: "pdf" }))
        link.click()
        setIsDownloading(false)
      })
      .catch((error) => {
        console.log(error)
        if (error) {
          setIsDownloading(false)
          alert(error)
        }
      })
  }

  const PackageOrQuotation = (isPackage) => {
    return isPackage ? "Package" : "Quotation"
  }

  const createPackagePDF = async (id, fileName) => {
    setIsDownloading(true)
    try {
      const logoUrl = await Api.get(`getAgentLogo/${unique_id}`)
      const pdfData = await Api.get(
        `/getpackageorquotationid/${unique_id}/${PackageOrQuotation(
          "isPackage"
        )}/${id}`
      )

      let dateDuration
      if (pdfData.data.checkOut) {
        let d1 = moment(pdfData.data.checkIn, "DD-MM-YYYY")
        let d2 = moment(pdfData.data.checkOut, "DD-MM-YYYY")
        dateDuration = d2.diff(d1, "days")
      }

      let leadPax = await pdfData.data.leadPax
      let hotelDetails = await pdfData.data.hotelDetails
      let transferDetails = await pdfData.data.transferDetails
      let activityDetails = await pdfData.data.activityDetails

      let pdfComponent = (
        <PackagePDF
          logoUrl={logoUrl.data}
          data={pdfData.data}
          duration={dateDuration}
          leadPax={leadPax}
          hotelDetails={hotelDetails}
          transferDetails={transferDetails}
          activityDetails={activityDetails}
          isPackage={true}
        />
      )
      let jsxString = await ReactDOMServer.renderToStaticMarkup(pdfComponent)

      const pdfOptions = {
        // margin: 10,
        filename: fileName,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          scale: 2,
          useCORS: true,
        },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      }

      const element = document.createElement("div")
      element.innerHTML = jsxString

      await html2pdf().from(element).set(pdfOptions).save()
      setIsDownloading(false)
    } catch (error) {
      console.error("Error creating PDF:", error)
      setIsDownloading(false)
      alert(error)
    }
  }

  return (
    <>
      <div>
        <Table size="small">
          <TableHead style={twnButtonStyles.headerStyle}>
            <TableRow>
              <StyledTableCell style={{ borderTopLeftRadius: "5px" }}>
                Package Id
              </StyledTableCell>
              <StyledTableCell align="left">Package Name</StyledTableCell>
              <StyledTableCell align="left">Client Name</StyledTableCell>
              <StyledTableCell align="left">Phone No.</StyledTableCell>
              {hasAdmin === "Admin" ||
              hasAdmin === "Super Admin" ||
              hasAdmin === "Finance Team" ||
              hasAdmin === "Agent Admin" ? (
                <StyledTableCell align="left">Agent Name</StyledTableCell>
              ) : null}
              <StyledTableCell align="left">Duration</StyledTableCell>
              <StyledTableCell align="left">Booking Date</StyledTableCell>
              <StyledTableCell align="left">Trip Date</StyledTableCell>
              <StyledTableCell align="left">Total Amount</StyledTableCell>
              <StyledTableCell
                align="left"
                style={{ borderTopRightRadius: "5px" }}
              >
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {tableData.length > 0 ? (
            <TableBody>
              {tableData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">
                      {row.packageId}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.clientName}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.clientMobileNo}
                    </StyledTableCell>
                    {hasAdmin === "Admin" ||
                    hasAdmin === "Super Admin" ||
                    hasAdmin === "Finance Team" ||
                    hasAdmin === "Agent Admin" ? (
                      <StyledTableCell align="left">
                        {row.createdBy.replace(/^\w/, (c) => c.toUpperCase())}
                      </StyledTableCell>
                    ) : null}
                    <StyledTableCell>
                      {row.checkOut && row.checkIn
                        ? differenceInDays(
                            parse(row.checkOut, "dd-MM-yyyy", new Date()),
                            parse(row.checkIn, "dd-MM-yyyy", new Date())
                          )
                        : null}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.createdAt
                        ? format(
                            parse(
                              row.createdAt,
                              "yyyy-MM-dd HH:mm:ss",
                              new Date()
                            ),
                            "dd MMM yy"
                          )
                        : null}
                    </StyledTableCell>
                    <StyledTableCell>
                      {row.checkIn
                        ? format(
                            parse(row.checkIn, "dd-MM-yyyy", new Date()),
                            "dd MMM yy"
                          )
                        : null}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {formatter.format(row.totalGrossAmount)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.amountRefunded > 0 ? (
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="left"
                          style={{ color: "#f46d25" }}
                        >
                          <BlockIcon
                            style={{ color: "#f46d25", fontWeight: "bold" }}
                          />
                          <Link
                            to={{
                              pathname: `/konnect/viewPackageOrQuotation/package/${row.packageId}`,
                            }}
                          >
                            <Tooltip title="View" arrow>
                              <VisibilityIcon style={{ color: "#f46d25" }} />
                            </Tooltip>
                          </Link>
                        </Stack>
                      ) : (
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="left"
                          style={{ color: "#f46d25" }}
                        >
                          <div
                            onClick={() => {
                              window.open(
                                hasAdmin === "Agent Admin" ||
                                  checkAgent === "Agent"
                                  ? `/konnect/agentCreatePackageOrQuotation/package/${true}/${
                                      row.packageId
                                    }`
                                  : `/konnect/createPackageOrQuotation/package/${true}/${
                                      row.packageId
                                    }`,
                                "_self",
                                `isPackage:true, id:row.packageId, displayLeads:true`
                              )
                            }}
                          >
                            <Tooltip title="Edit" arrow>
                              <EditIcon
                                style={{ color: "#f46d25", cursor: "pointer" }}
                              />
                            </Tooltip>
                          </div>
                          <Link
                            to={{
                              pathname: `/konnect/viewPackageOrQuotation/package/${row.packageId}`,
                            }}
                          >
                            <Tooltip title="View" arrow>
                              <VisibilityIcon style={{ color: "#f46d25" }} />
                            </Tooltip>
                          </Link>
                          <div
                            onClick={() => {
                              window.open(
                                `/konnect/copyPage/package/${row.packageId}`
                              )
                            }}
                          >
                            <Tooltip title="Copy Package" arrow>
                              <FileCopyIcon
                                style={{ color: "#f46d25", cursor: "pointer" }}
                              />
                            </Tooltip>
                          </div>
                          {/* <IconButton
                            style={{ color: "#f46d25", padding: "0" }}
                            title="Download Package "
                            onClick={() => DownloadPackage(row)}
                          >
                            <CustomerVoucherIcon style={{ height: "25px" }} />
                          </IconButton> */}
                          <IconButton
                            onClick={() =>
                              createPackagePDF(
                                `${row.packageId}`,
                                `${row.packageId}_PK.pdf`
                              )
                            }
                            style={{ padding: "0" }}
                            title="Download Package Voucher"
                          >
                            <img
                              src={PackagePDFIcon}
                              style={{
                                height: "20px",
                                width: "auto",
                                padding: "0",
                              }}
                            />
                          </IconButton>
                        </Stack>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          ) : (
            "Search Not Found"
          )}
        </Table>
      </div>

      <TablePagination
        component="div"
        count={tableData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ScreenLoader open={isDownloading} />
    </>
  )
}
