import styles from "./DestinationThingsToCarry.module.css"
import globalStyles from "../../../../globalStyles.module.css"
import { Fragment, useContext, useLayoutEffect } from "react"
import { DestinationLayoutContext } from "../../../../layout/DestinationLayout/Destination.layout"
import QueryForm from "../../../../components/QueryForm/QueryForm.component"
import ContactInfo from "../../../../components/ContactInfo/ContactInfo.component"
import AsideDestinationNavbar from "../../../../components/AsideDestinationNavbar/AsideDestinationNavbar.component"
import ImageAndContentCard from "../../../../components/ImageAndContentCard/ImageAndContentCard.component"
import parser from "html-react-parser"
import BiggerGroupBannerPost from "../../../../components/BiggerGroupBanner/BiggerGroupBanner.component"
import MockImage from "../../../../assets/illustrations/mockImages/Dubai.jpg"
import InlineImageAndContentCard from "../../../../components/InlineImageAndContentCard/InlineImageAndContent.component"

import DocumentsImage from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to carry/documents.jpg"
import ClothingImage from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to carry/Clothing.jpg"
import HealthAndHygieneImage from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to carry/Health & Hygiene Products.jpg"
import ElectronicsImage from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to carry/Electronics.jpg"
import PersonalCareImage from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to carry/Personal Care & Food Products.png"
import CashImage from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to carry/Cash.jpg"
import OthersImage from "../../../../assets/illustrations/destination/drive-download-20240318T043358Z-001/Things to carry/pack light and carry a backpack.jpg"
import DestinationSubLayout from "../../../../layout/DestinationSubLayout/DestinationSub.layout"

const thingsToCarry = [
  {
    src: DocumentsImage,
    title: "Documents",
    content:
      "If you are an international visitor, your Uttarakhand Checklist must include your passport and visa. If you are a domestic tourist, your checklist for Uttarakhand trip will need to produce a Photo identification card issued by the Government of India that contains your given name as well as address. Please remember that a PAN card is not considered a valid ID. Valid documents are one of the most important things to carry to Uttarakhand.",
  },
  {
    src: ClothingImage,
    title: "Clothing",
    content: `<p>The list of things to carry while travelling to Uttarakhand must be planned according to the weather. During the summer months from March to June, temperatures range from 20°c to 35°c and can soar a bit higher. Your Uttarakhand trip packing list must carry loose cotton clothes for the summer. Avoid any clothes that are body hugging or synthetic materials that will increase sweating. During monsoons, please carry a raincoat and an umbrella. </p>

During the winter seasons, temperatures range from 3°c to 15°c and your Uttarakhand packing list must contain plenty of woollens. Sweaters, mufflers, gloves and thermals must be at the top of the checklist for Uttarakhand.
`,
  },
  {
    src: HealthAndHygieneImage,
    title: "Health & Hygiene Products ",
    content:
      "A personal toilet kit including toilet paper, hand sanitizer, toothpaste and a brush are important parts of what to carry for Uttarakhand trip. The sun can often be harsh and we also recommend a lip balm, a sunscreen with SPF 40+. During winter your list of what should I carry for Uttarakhand trip must also include a moisturizer or cold cream. We also recommend that you should carry an antifungal cream as a travel essential for Uttarakhand if you plan to go trekking and are prone to sweating.",
  },
  {
    src: ElectronicsImage,
    title: "Electronics ",
    content:
      "If you are wondering what to carry while travelling to Uttarakhand, a fully charged mobile should definitely be on the list. If you want to carry home priceless memories of the Uttarakhand trip, a camera with a memory card is another recommendation. Also, make sure you carry a fully charged power bank for your trip.",
  },
  {
    src: PersonalCareImage,
    title: "Personal Care & Food Products ",
    content:
      "When you’re looking for things to carry while travelling to Uttarakhand, bottled water is definitely a must. While traversing the interiors of the state, especially during winters, water can be a life saviour. If you plan to go on treks, you can also carry dry fruits, chocolates or bars for quick energy supplements.",
  },
  {
    src: CashImage,
    title: "Cash ",
    content:
      "While most establishments carry credit card machines or support wallet services such as Paytm or PhonePe, smaller vendors and hawkers may not support this. Cash should be an important item on the Uttarakhand travel checklist.",
  },
  {
    src: OthersImage,
    title: "Others ",
    content:
      "Make sure you pack light and carry a backpack as you traverse this mountainous terrain, this essential tip from the checklist for Uttarakhand travel will ensure that you have an enjoyable trip.",
  },
]

const DestinationThingsCarry = () => {
  const { setBanner } = useContext(DestinationLayoutContext)

  useLayoutEffect(() => {
    setBanner("thingsToCarry")
  }, [])

  return (
    <DestinationSubLayout>
      <main className={globalStyles.main}>
        <article className={globalStyles.topMostSection}>
          <header>
            Things To <span>Carry</span>
          </header>
          <div
            className={globalStyles.content}
            style={{ marginBottom: "2rem" }}
          >
            If you are planning a trip to this state, you will need to carefully
            plan a <b>packing list for Uttarakhand trip</b>.
            <b>A lot of what things to carry</b> to Uttarakhand would depend on
            the time of your trip and the weather.
          </div>
        </article>
        <div className={styles.cuisineList}>
          {thingsToCarry.map((_, i) => (
            <Fragment key={i}>
              <InlineImageAndContentCard>
                <InlineImageAndContentCard.Image src={_.src} size="medium" />
                <InlineImageAndContentCard.Content title={_.title}>
                  {parser(_.content)}
                </InlineImageAndContentCard.Content>
              </InlineImageAndContentCard>
              <hr className={styles.hr} />
            </Fragment>
          ))}
        </div>
        <div
          className={styles.pagination}
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <span
            style={{ color: "#f46d25", fontWeight: "bold", cursor: "pointer" }}
          >
            Next
          </span>
        </div>
        <BiggerGroupBannerPost />
      </main>
    </DestinationSubLayout>
  )
}

export default DestinationThingsCarry
