import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import Api from "../../Service/Api";
import _ from "lodash";
import parse from "html-react-parser";
import moment from "moment";
import {
  Card,
  Paper,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { baseurl } from "../../Service/httpCommon";
import { Button, TableBody, makeStyles } from "@material-ui/core";
import { twnButtonStyles } from "../../utils/townoStyle";
import { format } from "date-fns";
import { formatter } from "../../utils/formatNumber";
import { toast } from "react-toastify";

const PackageOrQuotation = (isPackage) => {
  return isPackage ? "Package" : "Quotation";
};

function removeSpaceNewline(text) {
  if (text) {
    let a = text.split("\n");
    return a.join(" | ");
  } else {
    return "";
  }
}

const useStyles = makeStyles({
  root: {
    "& .MuiTableCell-root": {
      border: "none !important",
    },
  },
});
const CopyPageKGL = (props) => {
  let uniqueid = localStorage.getItem("unique_id");
  const tableRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState("Copy");
  let [data, setData] = useState({
    id: "",
    type: "",
    name: "",
    clientName: "",
    clientMobileNo: "",
    noOfAdults: "",
    hotelDetails: [],
    leadPax: [],
    transferDetails: [],
    activityDetails: [],
    packageTeamCondition: {},
  });
  let { pkgOrQtn, id } = useParams();
  let isPackage = pkgOrQtn === "package" ? true : false;
  const [logoUrl, setLogoUrl] = useState("");
  const [duration, setDuration] = useState();

  useEffect(() => {
    //fetching agenLogoUrl
    Api.get(`getAgentLogo/${uniqueid}`).then((res) => {
      //   console.log("getAgentLogo|response: ", res.data);
      setLogoUrl(res.data);
    });

    Api.get(
      `/getpackageorquotationid/${uniqueid}/${PackageOrQuotation(
        isPackage
      )}/${id}`
    ).then((res) => {
      setData(res.data);
      if (res.data.checkOut) {
        // parse date and calculate diff between the dates
        let d1 = moment(res.data.checkIn, "DD-MM-YYYY");
        let d2 = moment(res.data.checkOut, "DD-MM-YYYY");
        console.log("differenceInDays:", d2, d1);
        const dateDuration = d2.diff(d1, "days"); // differenceInDays(d2, d1)
        console.log("duration|differenceInDays: ", dateDuration);
        setDuration(dateDuration);
      } else {
        return "";
      }
    });
  }, []);

  const copyToClip = async () => {
    let range = document.createRange();
    range.selectNodeContents(tableRef.current);
    let sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    document.execCommand("Copy");
    sel.removeAllRanges();
    //paste the copied data to mailto body
    document.addEventListener("paste", function (event) {
      var clipText = event.clipboardData.getData("Text");
      window.location = `mailto:?subject=I wanted you to see this site&body=${clipText}`;
    });
    await setCopySuccess("Copy");
    await toast.success("Copied Successfully!");
  };
  //
  let validLeadPax =
    data.leadPax != null
      ? data.leadPax.filter((e) => {
          return e.leadPaxName != "";
        })
      : 0;
  let validHotelInput = data.hotelDetails.filter((e) => {
    return e.hotelName !== "";
  });
  let validTransferInput = data.transferDetails.filter((e) => {
    return e.transferFrom !== "";
  });
  let validActvityInput = data.activityDetails.filter((e) => {
    return e.city !== "";
  });

  const classes = useStyles();

  return (
    <div
      style={{
        margin: "20px 0px",
      }}
    >
      <div
        ref={tableRef}
        style={{
          maxWidth: "50%",
          margin: "0 auto",
          width: "100%",
          fontSize: "14px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#111",
            width: "100%",
          }}
        >
          <Table sx={{ width: "100%" }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "50%" }}>
                  <img
                    src={`${baseurl}getimage/${logoUrl}`}
                    alt="logo"
                    style={{
                      width: "6em",
                      height: "1.8em",
                      paddingTop: "0.4em",
                    }}
                  />
                </TableCell>
                {isPackage ? (
                  <TableCell
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      textAlign: "right",
                      paddingTop: "1em",
                    }}
                  >
                    {data.packageId}
                  </TableCell>
                ) : (
                  <TableCell
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      textAlign: "right",
                      paddingTop: "1em",
                    }}
                  >
                    {data.quotationId}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </div>
        {/* basic details starts */}
        <Card
          style={{
            borderRadius: "0",
            backgroundColor: "#fff",
            width: "auto",
            boxShadow: "none",
          }}
        >
          <h3
            style={{
              width: "100%",
              backgroundColor: "#f46d25",
              fontSize: "20px",
              color: "#fff",
              margin: "0",
              padding: "5px 0px 5px",
            }}
          >
            <span style={{ marginLeft: "15px" }}>Basic Details</span>
          </h3>
          <Table sx={{ width: "100%", padding: "10px" }}>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ width: "50%", border: "none", paddingTop: "10px" }}
                >
                  {PackageOrQuotation(isPackage)} Name
                </TableCell>
                <TableCell
                  sx={{
                    border: "none",
                    paddingTop: "10px",
                    maxWidth: "30%",
                  }}
                >
                  : {data.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ width: "50%", border: "none", paddingTop: "0" }}
                >
                  Destination Name
                </TableCell>
                <TableCell sx={{ border: "none", paddingTop: "0" }}>
                  : {data.destination}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ width: "50%", border: "none", paddingTop: "0" }}
                >
                  Client Name
                </TableCell>
                <TableCell sx={{ border: "none", paddingTop: "0" }}>
                  : {data.clientName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ width: "50%", border: "none", paddingTop: "0" }}
                >
                  Phone No
                </TableCell>
                <TableCell sx={{ border: "none", paddingTop: "0" }}>
                  : {data.clientMobileNo}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ width: "50%", border: "none", paddingTop: "0" }}
                >
                  Duration
                </TableCell>
                <TableCell sx={{ border: "none", paddingTop: "0" }}>
                  : {duration}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ width: "50%", border: "none", paddingTop: "0" }}
                >
                  Booking Date
                </TableCell>
                <TableCell sx={{ border: "none", paddingTop: "0" }}>
                  :{" "}
                  {data.createdAt
                    ? format(new Date(data.createdAt), "dd-MM-yyyy")
                    : null}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ width: "50%", border: "none", paddingTop: "0" }}
                >
                  Check-In
                </TableCell>
                <TableCell sx={{ border: "none", paddingTop: "0" }}>
                  : {data.checkIn ?? null}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ width: "50%", border: "none", paddingTop: "0" }}
                >
                  Check-Out
                </TableCell>
                <TableCell sx={{ border: "none", paddingTop: "0" }}>
                  : {data.checkOut ?? null}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ width: "50%", border: "none", paddingTop: "0" }}
                >
                  Adults
                </TableCell>
                <TableCell sx={{ border: "none", paddingTop: "0" }}>
                  : {data.noOfAdults}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ width: "50%", border: "none", paddingTop: "0" }}
                >
                  Children
                </TableCell>
                <TableCell sx={{ border: "none", paddingTop: "0" }}>
                  : {data.noOfChildren}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
        {/* basic details ends */}

        {/* lead pax starts */}
        {validLeadPax.length > 0 && (
          <Card
            style={{
              borderRadius: "0",
              backgroundColor: "#fff",
              width: "auto",
              boxShadow: "none",
            }}
          >
            {data.leadPax ? (
              <>
                <h3
                  style={{
                    width: "100%",
                    backgroundColor: "#f46d25",
                    fontSize: "20px",
                    color: "#fff",
                    margin: "0",
                    padding: "5px 0px 5px",
                  }}
                >
                  <span style={{ marginLeft: "15px" }}>Lead Pax Details</span>
                </h3>
                {data.leadPax.map((each) => (
                  <Table sx={{ width: "100%", padding: "10px" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "50%",
                            border: "none",
                            paddingTop: "10px",
                          }}
                        >
                          Name
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "10px" }}>
                          : {each.leadPaxName}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Mobile No
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.leadPaxMobile}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Alt No
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.leadPaxAltNo}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{ width: "50%", border: "none", paddingTop: "0" }}
                        >
                          Email
                        </TableCell>
                        <TableCell sx={{ border: "none", paddingTop: "0" }}>
                          : {each.leadPaxEmail}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ))}
              </>
            ) : null}
          </Card>
        )}
        {/* lead pax ends */}

        {/* hotel details starts */}
        {validHotelInput.length > 0 && (
          <Card
            style={{
              borderRadius: "0",
              backgroundColor: "#fff",
              width: "auto",
              boxShadow: "none",
            }}
          >
            <h3
              style={{
                width: "100%",
                backgroundColor: "#f46d25",
                fontSize: "20px",
                color: "#fff",
                margin: "0",
                padding: "5px 0px 5px",
              }}
            >
              <span style={{ marginLeft: "15px" }}>Hotel Details</span>
            </h3>
            {data.hotelDetails.map((each) => (
              <Table sx={{ width: "100%", padding: "10px" }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{ width: "50%", border: "none", paddingTop: "10px" }}
                    >
                      Hotel Name
                    </TableCell>
                    <TableCell sx={{ border: "none", paddingTop: "10px" }}>
                      : {each.hotelName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ width: "50%", border: "none", paddingTop: "0" }}
                    >
                      Hotel Category
                    </TableCell>
                    <TableCell sx={{ border: "none", paddingTop: "0" }}>
                      : {each.hotelCategory}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ width: "50%", border: "none", paddingTop: "0" }}
                    >
                      Rooms
                    </TableCell>
                    <TableCell sx={{ border: "none", paddingTop: "0" }}>
                      : {each.noOfRooms}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ width: "50%", border: "none", paddingTop: "0" }}
                    >
                      Nights
                    </TableCell>
                    <TableCell sx={{ border: "none", paddingTop: "0" }}>
                      : {each.noOfNights}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ))}
          </Card>
        )}
        {/* hotel details ends */}

        {/* transfer details starts */}
        {validTransferInput.length > 0 && (
          <Card
            style={{
              borderRadius: "0",
              backgroundColor: "#fff",
              width: "auto",
              boxShadow: "none",
            }}
          >
            <h3
              style={{
                width: "100%",
                backgroundColor: "#f46d25",
                fontSize: "20px",
                color: "#fff",
                margin: "0",
                padding: "5px 0px 5px",
              }}
            >
              <span style={{ marginLeft: "15px" }}>Transfer Details</span>
            </h3>
            {data.transferDetails.map((each) => (
              <Table sx={{ width: "100%", padding: "10px" }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{ width: "50%", border: "none", paddingTop: "10px" }}
                    >
                      From
                    </TableCell>
                    <TableCell sx={{ border: "none", paddingTop: "10px" }}>
                      : {each.transferFrom}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ width: "50%", border: "none", paddingTop: "0" }}
                    >
                      To
                    </TableCell>
                    <TableCell sx={{ border: "none", paddingTop: "0" }}>
                      : {each.transferTo}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ width: "50%", border: "none", paddingTop: "0" }}
                    >
                      Trip
                    </TableCell>
                    <TableCell sx={{ border: "none", paddingTop: "0" }}>
                      : {each.transferTrip}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ width: "50%", border: "none", paddingTop: "0" }}
                    >
                      Type
                    </TableCell>
                    <TableCell sx={{ border: "none", paddingTop: "0" }}>
                      : {each.transferType}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ width: "50%", border: "none", paddingTop: "0" }}
                    >
                      Vehicle
                    </TableCell>
                    <TableCell sx={{ border: "none", paddingTop: "0" }}>
                      : {each.transferVehicle}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ))}
          </Card>
        )}
        {/* transfer details ends */}

        {/* activity details starts */}
        {validActvityInput.length > 0 && (
          <Card
            style={{
              borderRadius: "0",
              backgroundColor: "#fff",
              width: "auto",
              boxShadow: "none",
            }}
          >
            <h3
              style={{
                width: "100%",
                backgroundColor: "#f46d25",
                fontSize: "20px",
                color: "#fff",
                margin: "0",
                padding: "5px 0px 5px",
              }}
            >
              <span style={{ marginLeft: "15px" }}>Activity Details</span>
            </h3>
            {data.activityDetails.map((each) => (
              <Table sx={{ width: "100%", padding: "10px" }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{ width: "50%", border: "none", paddingTop: "10px" }}
                    >
                      City
                    </TableCell>
                    <TableCell sx={{ border: "none", paddingTop: "10px" }}>
                      : {each.city}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ width: "50%", border: "none", paddingTop: "0" }}
                    >
                      Activity
                    </TableCell>
                    <TableCell sx={{ border: "none", paddingTop: "0" }}>
                      : {each.activity}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            ))}
          </Card>
        )}
        {/* activity details ends */}

        {/* payment details starts */}
        <Card
          style={{
            borderRadius: "0",
            backgroundColor: "#fff",
            width: "auto",
            boxShadow: "none",
          }}
        >
          <h3
            style={{
              width: "100%",
              backgroundColor: "#f46d25",
              fontSize: "20px",
              color: "#fff",
              margin: "0",
              padding: "5px 0px 5px",
            }}
          >
            <span style={{ marginLeft: "15px" }}>Payment Summary</span>
          </h3>
          <Table sx={{ width: "100%", padding: "10px" }}>
            <TableBody>
              <TableRow sx={{ width: "100%" }}>
                <TableCell
                  sx={{ width: "50%", border: "none", paddingTop: "10px" }}
                >
                  Total Gross Amount
                </TableCell>
                <TableCell sx={{ border: "none", paddingTop: "10px" }}>
                  : {formatter.format(data.totalGrossAmount)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  sx={{ width: "50%", border: "none", paddingTop: "0" }}
                >
                  Amount Paid
                </TableCell>
                <TableCell sx={{ border: "none", paddingTop: "0" }}>
                  : {formatter.format(data.amountPaid)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
        {/* payment details ends */}
        {/* itinerary */}
        {data.itinerary ? (
          <Card style={{ marginTop: "9px" }}>
            <h3
              style={{
                width: "100%",
                backgroundColor: "#f46d25",
                fontSize: "20px",
                color: "#fff",
                margin: "0",
                padding: "5px 0px 5px",
              }}
            >
              <span style={{ marginLeft: "15px" }}>Itinerary</span>
            </h3>
            <Stack direction="row" spacing={1} style={{ padding: "0.5em 1em" }}>
              {data.itinerary != null && data.itinerary != "" && (
                <Stack
                  direction="row"
                  spacing={5}
                  style={{ padding: "0 0.5em 0.5em 0.5em" }}
                >
                  {removeSpaceNewline(data.itinerary)}
                </Stack>
              )}
            </Stack>
          </Card>
        ) : null}
        {/* cancellation policy starts */}
        <Card
          style={{
            borderRadius: "0",
            backgroundColor: "#fff",
            width: "auto",
            boxShadow: "none",
          }}
        >
          <h3
            style={{
              width: "100%",
              backgroundColor: "#f46d25",
              fontSize: "20px",
              color: "#fff",
              margin: "0",
              borderRadius: "0",
              padding: "5px 0px 5px",
            }}
          >
            <span style={{ marginLeft: "15px" }}>Cancellation Policy</span>
          </h3>
          <div
            style={{
              width: "100%",
              padding: "1em",
              fontWeight: "500",
              lineHeight: "1.2",
            }}
          >
            {/* {_.isEmpty(cancelationpolicy)
                        ? null
                        : parse(cancelationpolicy)} */}
            <p style={{ width: "95%", textAlign: "justify" }}>
              {_.isEmpty(data.packageTeamCondition.cancellationPolicy)
                ? null
                : parse(data.packageTeamCondition.cancellationPolicy)}
            </p>
          </div>
        </Card>
        {/* cancellation policy ends */}

        {/* terms & conditions starts */}
        <Card
          style={{
            borderRadius: "0",
            backgroundColor: "#fff",
            width: "auto",
            boxShadow: "none",
          }}
        >
          <h3
            style={{
              width: "100%",
              backgroundColor: "#f46d25",
              fontSize: "20px",
              color: "#fff",
              margin: "0",
              padding: "5px 0px 5px",
            }}
          >
            <span style={{ marginLeft: "15px" }}>Terms & Conditions</span>
          </h3>
          <div
            style={{
              width: "100%",
              padding: "1em",
              fontWeight: "500",
              lineHeight: "1.2",
            }}
          >
            <p style={{ width: "95%", textAlign: "justify" }}>
              {_.isEmpty(data.packageTeamCondition.teamCondition)
                ? null
                : parse(data.packageTeamCondition.teamCondition)}
            </p>
          </div>
        </Card>
      </div>
      {document.queryCommandSupported("copy") && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "1em",
          }}
        >
          <Button
            color="primary"
            onClick={copyToClip}
            style={twnButtonStyles.orangeBtn}
          >
            {copySuccess}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CopyPageKGL;
