export const baseurl = "https://towno.in/api/v1/";

//export const baseurl = 'http://128.199.25.87:8081/api/v1/';
//export const baseurl = 'http://15.207.47.158:8081/api/v1/';
//https://demo.towno.in/
//http://localhost:8081/

export const loginbaseurl = "https://towno.in/";

export const websocketbaseurl = "https://towno.in/api/v1/websocket/ws-endpoint";

export const s3BaseUrl =
  "https://theboardindexpage.s3.ap-south-1.amazonaws.com/";

export const logoBaseUrl =
  "https://theboardindexpage.s3.ap-south-1.amazonaws.com/crmtowno";
