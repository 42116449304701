import { Backdrop, makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import TownoLogo from "../../assets/logo/towno_white.png";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const ScreenLoader = ({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop
      className={classes.backdrop}
      open={open}
      style={{ display: "flex", flexDirection: "column" }}
    >
      {/* <CircularProgress color="inherit" /> */}
      {/* <TownoLogo /> */}
      <img
        src={TownoLogo}
        alt="logo"
        style={{ width: "150px", height: "45px" }}
      />
    </Backdrop>
  );
};

export default ScreenLoader;
