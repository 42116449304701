import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  Grid,
  TextField,
  Button,
  CircularProgress,
  Tooltip,
  IconButton,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  InputAdornment,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateRangeIcon from "@material-ui/icons/DateRange";
import "./Bluk.css";
import axios from "axios";
import Api from "../../Service/Api";
import { baseurl } from "../../Service/httpCommon";
import { format } from "date-fns";
import { useParams, useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { toast } from "react-toastify";
import CheckIcon from "@material-ui/icons/Check";
import {
  AddCircleOutlineOutlined,
  DeleteForeverOutlined,
  ErrorOutline,
} from "@material-ui/icons";
import UpdateIcon from "@material-ui/icons/Update";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "100px",
    "& > *": {
      margin: "20px 20px 0px 20px",
      width: theme.spacing(16),
      height: "auto",
    },
  },
  icon: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    position: "absolute",
    right: "15px",
    top: "5px",
    color: "#f46d25",
  },
  button: {
    background: "#F46D25",
    marginBottom: "-15px",
    "@media(max-width:767px)": {
      background: "#343A40",
    },
  },
  paper: {
    width: "100%",
  },
  headingr: {
    background: "#fff",
    marginTop: "15px",
    color: "#000",
    paddingLeft: "20px",
    paddingBottom: "20px",
    fontSize: "28px",
    paddingRight: "30px",
  },
  headingd: {
    background: "#fff",
    marginTop: "15px",
    fontWeight: "bold",
    color: "#000",
    paddingBottom: "2px",
    fontSize: "20px",
    paddingRight: "30px",
  },
  heading: {
    borderBottom: "1px solid #ADD8E6",
    background: "#fff",
    marginTop: "15px",
    color: "#F46D25",
    paddingBottom: "5px",
    paddingLeft: "30px",
    fontSize: "32px",
    paddingRight: "30px",
  },
  layout: {
    margin: "40px 20px",
    display: "flex",
    paddingLeft: "80px",
    paddingRight: "30px",
  },
  label: {
    lineHeight: "2",
    fontSize: "18px",
    fontWeight: "blod",
    color: "#0000009e",
  },
}));

const dayCheck = [
  { id: "sun", name: "Sunday" },
  { id: "mon", name: "Monday" },
  { id: "tue", name: "Tuesday" },
  { id: "wed", name: "Wednesday" },
  { id: "thu", name: "Thursday" },
  { id: "fri", name: "Friday" },
  { id: "sat", name: "Saturday" },
];

export default function Bulkupdate() {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { propertyId } = useParams();

  const [bulkList, setBulkList] = useState([]);
  const [bulkUpdateData, setBulkUpdateData] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      propertyId,
      roomRentDetails: [],
    },
  ]);
  let history = useHistory();
  const [inputs, setInputs] = useState([
    "sun",
    "mon",
    "tue",
    "wed",
    "thu",
    "fri",
    "sat",
  ]);
  const [roomRent, setRoomRent] = useState([]);
  const handleroom = (e, index, id) => {
    // var result = [...bulkList];
    const result1 = bulkUpdateData.map((x, idx) => {
      if (index == idx) {
        return {
          ...x,
          roomRentDetails: x.roomRentDetails.map((room) => {
            if (id == room.id) {
              return { ...room, roomRent: e.target.value };
            }
            return room;
          }),
        };
      }
      return x;
    });
    // var found = result1.filter(function (item) {
    //   return item.id === id;
    // });
    console.log(result1);
    setBulkUpdateData(result1);
  };

  const onChangestart = (idx) => (date) => {
    const updatedData = bulkUpdateData.map((data, index) => {
      if (idx === index) {
        return {
          ...data,
          startDate: date,
        };
      }
      return data;
    });

    setBulkUpdateData(updatedData);
  };
  const onChangeend = (idx) => (date) => {
    const updatedData = bulkUpdateData.map((data, index) => {
      if (idx === index) {
        return {
          ...data,
          endDate: date,
        };
      }
      return data;
    });

    setBulkUpdateData(updatedData);
  };

  const [currentId, setCurrentId] = useState(null);
  const [currentParentRow, setCurrentParentRow] = useState(null);
  const [responseStatus, setResponseStatus] = useState(null);
  const [disableButton, setDisableButton] = useState(false);

  const addNewDatesField = () => {
    const updatedData = [
      ...bulkUpdateData,
      {
        startDate: new Date(),
        endDate: new Date(),
        propertyId,
        roomRentDetails: [],
      },
    ];
    setBulkUpdateData(updatedData);
  };

  const deleteDateFields = (index) => () => {
    const updatedData = bulkUpdateData.filter((data, i) => i != index);
    setBulkUpdateData(updatedData);
  };

  const updateRent = async (bulkUpdateDataIndex, roomId) => {
    setCurrentId(roomId);
    setCurrentParentRow(bulkUpdateDataIndex);
    setDisableButton(true);

    console.log({ bulkUpdateDataIndex, roomId });

    const updateObject = bulkUpdateData.find(
      (_, i) => i == bulkUpdateDataIndex
    );

    const roomRentDetails = updateObject.roomRentDetails.find(
      (room) => room.id == roomId
    );

    const startDate = format(updateObject.startDate, "yyyy-MM-dd");
    let endDate = format(updateObject?.endDate, "yyyy-MM-dd");

    // console.log(bulkList);

    // const found = bulkList.find((room) => room.id === id);

    const updateRoomData = {
      propertyId,
      startDate,
      endDate,
      days: inputs,
      displayName: roomRentDetails?.displayName,
      roomRent: roomRentDetails?.roomRent,
    };

    console.log({ updateRoomData });

    try {
      const response = await Api.post("blukupdate", updateRoomData);
      if (response.status != 200) {
        await setTimeout(() => {
          setResponseStatus("error");
        }, 2000);
        return;
      }
    } catch (error) {
      await setTimeout(() => {
        setResponseStatus("error");
      }, 2000);
    }

    setTimeout(() => {
      setCurrentId(null);
      setCurrentParentRow(null);
    }, 1000);

    setResponseStatus(null);

    await setTimeout(() => {
      setDisableButton(false);
    }, 2000);
  };

  useEffect(() => {
    BlukViewGet();
  }, [bulkUpdateData.length]);

  const BlukViewGet = async () => {
    await Api.get(`bulkupdateview/${propertyId}`).then((res) => {
      const updatedData = bulkUpdateData.map((data) => {
        return { ...data, roomRentDetails: res.data };
      });
      setBulkUpdateData(updatedData);
    });
  };
  return (
    <>
      <div className={classes.root}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Paper className={classes.paper} variant="outlined">
            <h4 className={classes.heading}>
              Bulk Update{" "}
              <ArrowBackIcon
                onClick={history.goBack}
                style={{ float: "right" }}
              />
            </h4>

            <div className={classes.layout}>
              <Grid container spacing={4}>
                <p
                  style={{ margin: "0px", marginBottom: "10px" }}
                  className={classes.headingd}
                >
                  Select Date to Update Rate
                </p>
                {bulkUpdateData.map((data, idx) => (
                  <Grid
                    key={idx}
                    item
                    container
                    xs={12}
                    style={{ display: "flex", alignItems: "center" }}
                    spacing={2}
                  >
                    <Grid item xs={4}>
                      <div style={{ position: "relative" }}>
                        <DatePicker
                          required
                          label="Start Date"
                          inputVariant="outlined"
                          fullWidth
                          size="small"
                          value={data.startDate}
                          onChange={onChangestart(idx)}
                          animateYearScrolling
                          format="dd/MM/yyyy"
                          variant="inline"
                          disablePast="true"
                          autoOk="true"
                        />
                        <DateRangeIcon className={classes.icon} />
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div style={{ position: "relative" }}>
                        <DatePicker
                          required
                          label="End Date"
                          inputVariant="outlined"
                          fullWidth
                          size="small"
                          value={data.endDate}
                          onChange={onChangeend(idx)}
                          animateYearScrolling
                          format="dd/MM/yyyy"
                          variant="inline"
                          disablePast="true"
                          autoOk="true"
                        />
                        <DateRangeIcon className={classes.icon} />
                      </div>
                    </Grid>
                    <Grid item container xs={2}>
                      {idx === bulkUpdateData.length - 1 && (
                        <Grid item xs={2}>
                          <Tooltip title="Add another row">
                            <IconButton onClick={addNewDatesField}>
                              <AddCircleOutlineOutlined color="primary" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                      {idx !== bulkUpdateData.length - 1 && (
                        <Grid item xs={2}>
                          <Tooltip title="Delete row">
                            <IconButton onClick={deleteDateFields(idx)}>
                              <DeleteForeverOutlined color="primary" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <p
                    style={{ margin: "0px", marginBottom: "10px" }}
                    className={classes.headingd}
                  >
                    Select Days
                  </p>
                  <div className="weeks">
                    {dayCheck.map((day) => (
                      <div className="round" key={day.id}>
                        <input
                          type="checkbox"
                          value={day.id}
                          defaultChecked={inputs.includes(day.id)}
                          onClick={() => {
                            const index = inputs.findIndex(
                              (item) => item === day.id
                            );
                            if (~index) {
                              setInputs([
                                ...inputs.slice(0, index),
                                ...inputs.slice(index + 1),
                              ]);
                            } else {
                              setInputs([...inputs, day.id]);
                            }
                          }}
                        />
                        <label>{day.name}</label>
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={classes.layout}>
              <Grid container spacing={1}>
                {bulkUpdateData.map((data, indexOfBulk) => (
                  <Grid key={indexOfBulk} item container xs={12}>
                    <Grid item xs={12}>
                      <Grid
                        item
                        container
                        xs={12}
                        style={{
                          background: "#f46d25",
                          color: "white",
                          padding: "5px",
                          borderRadius: "7px 7px 0px 0px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Grid item>
                          From:{" "}
                          <b>{format(data?.startDate, "iii do, MMM yyyy")}</b>
                        </Grid>{" "}
                        <Grid item>
                          To:
                          <b> {format(data?.endDate, "iii do, MMM yyyy")}</b>
                        </Grid>
                      </Grid>
                      <Grid item container xs={12}>
                        {data?.roomRentDetails.map((roomData, idx) => (
                          <Grid
                            key={roomData.id}
                            item
                            container
                            xs={12}
                            alignItems="center"
                          >
                            <Grid item xs={1} style={{ textAlign: "center" }}>
                              {idx + 1}
                            </Grid>
                            <Grid item xs={5}>
                              {roomData.visibleName}
                            </Grid>
                            <Grid item xs={5}>
                              <TextField
                                name="roomRent"
                                label={roomData.visibleName}
                                fullWidth
                                type="number"
                                value={roomData.roomRent || null}
                                autoComplete="off"
                                onChange={(e) =>
                                  handleroom(e, indexOfBulk, roomData.id)
                                }
                                size="small"
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {!disableButton && (
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            updateRent(indexOfBulk, roomData.id)
                                          }
                                        >
                                          <UpdateIcon color="primary" />
                                        </IconButton>
                                      )}
                                      {currentId === roomData.id &&
                                        currentParentRow == indexOfBulk &&
                                        (responseStatus !== null ? (
                                          <CircularProgress
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                              marginLeft: "10px",
                                              color: "red",
                                            }}
                                          />
                                        ) : responseStatus == "error" ? (
                                          <ErrorOutline
                                            color="error"
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                            }}
                                          />
                                        ) : (
                                          <CheckIcon
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                              marginLeft: "10px",
                                              color: "white",
                                              background: "#4CAF50",
                                              borderRadius: "50%",
                                            }}
                                          />
                                        ))}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </div>
          </Paper>
        </MuiPickersUtilsProvider>
      </div>
    </>
  );
}

// <Grid container spacing={4}>
//   <h4 className={classes.headingr}>Room Rates</h4>
//   <Grid
//     container
//     spacing={4}
//     style={{ marginTop: "-55px", paddingLeft: "20px" }}
//   >
//     <Grid item sm={8}>
//       {bulkList.map((roomData) => {
//         return (
//           <div key={roomData.id}>
//             <Grid container spacing={4}>
//               <Grid item sm={6}>
//                 <TextField
//                   name="roomRent"
//                   label={roomData.visibleName}
//                   fullWidth
//                   type="text"
//                   value={roomData.roomRent || ""}
//                   autoComplete="off"
//                   onChange={(e) => handleroom(e, roomData.id)}
//                   size="small"
//                   margin="dense"
//                   variant="outlined"
//                 />
//               </Grid>
//               <Grid
//                 item
//                 sm={4}
//                 direction="row"
//                 style={{ display: "flex", alignItems: "center" }}
//               >
//                 <Button
//                   onClick={() => updateRent(roomData.id)}
//                   disabled={disableButton}
//                 >
//                   UPDATE
//                 </Button>
//                 <>
//                   {currentId === roomData.id &&
//                     (responseStatus !== null ? (
//                       <CircularProgress
//                         style={{
//                           width: "20px",
//                           height: "20px",
//                           marginLeft: "10px",
//                           color: "red",
//                         }}
//                       />
//                     ) : (
//                       <CheckIcon
//                         style={{
//                           width: "20px",
//                           height: "20px",

//                           marginLeft: "10px",
//                           color: "white",
//                           background: "#4CAF50",
//                           borderRadius: "50%",
//                         }}
//                       />
//                     ))}
//                 </>
//               </Grid>
//             </Grid>
//           </div>
//         );
//       })}
//       <br />
//     </Grid>
//   </Grid>
// </Grid>;
