import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select as SelectTravelMode,
  TextField,
  TextareaAutosize,
  Typography,
  makeStyles,
  styled,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import Api from "../../Service/Api";
import { twnButtonStyles } from "../../utils/townoStyle";
import AgentFlightForm from "./AgentFlightForm";
import AgentTrainForm from "./AgentTrainForm";
import { CustomBasic } from "./BasicDetailPage";
import FlightForm from "./FlightForm";
import RoadForm from "./RoadForm";
import TrainForm from "./TrainForm";

const useStyles = makeStyles({
  root: {
    padding: "20% 3%",
    paddingBottom: "20%",
  },
  title: {
    fontSize: "25px",
    fontWeight: "bold",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  createButton: {
    background: "#343A40",
    fontSize: "18px",
    height: "50%",
  },
  searchField: {
    width: "100%",
  },
  select: {
    "&:before": {
      border: "none",
    },
    "&:after": {
      border: "none",
    },
  },
});

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input::placeholder": {
    color: "#181818",
    opacity: "1",
    fontSize: "18px",
  },

  "& .MuiOutlinedInput-root": {
    border: "#f46d25",
  },
});

const travelby = [
  { label: "Flight", value: "flight" },
  { label: "Road", value: "road" },
  { label: "Train", value: "train" },
];

const DualTextField = (props) => (
  <Grid container>
    <Grid item xs={7}>
      <TextField
        variant="outlined"
        style={props.labelStyle}
        InputProps={props.InputLableFieldProps}
        value={props.labelValue}
        disabled
      />
    </Grid>
    <Grid item xs={5}>
      <TextField
        name={props.name}
        variant="outlined"
        style={props.valueStyle}
        value={props.value}
        InputProps={props.InputValueFieldProps}
        disabled={props.disabled}
        onChange={props.onChange}
      />
    </Grid>
  </Grid>
);

const TransferFormMobileUI = ({
  tabValue,
  setOpen,
  basicInput,
  basicInputError,
  handleRemoveClickBasic,
  handleAddClickBasic,
  handleBasicChange,
  handleChangeClient,
  transferId,
  defaultvalue,
  hasAdmin,
  checkAgent,
  flightInput,
  setFlightInput,
  flightInputError,
  setFlightInputError,
  trainInput,
  setTrainInput,
  trainInputError,
  setTrainInputError,
  classes,
  cabInput,
  setCabInput,
  busInput,
  setBusInput,
  cabInputError,
  setCabInputError,
  busInputError,
  setBusInputError,
  transferPaymentDetails,
  setTransferPaymentDetails,
  formData,
  totalAmount,
  commission,
  setPkgTrmCndData,
  pkgTrmCndData,
  isClicked,
  handleSubmit,
  validatePreview,
  validateAllFields,
  open,
  setDeFaultValue,
  paymentModeOptions,
}) => {
  const classesMob = useStyles();
  const [transportMode, setTransportMode] = useState(travelby[0].value);
  var uniqueid = localStorage.getItem("unique_id");

  const handleChangeTravelMode = (e) => {
    console.log(e.target.value);
    setTransportMode(e.target.value);
  };

  const [clientLists, setClientLists] = useState([]);

  useEffect(() => {
    Api.get(`/clientall/${uniqueid}`).then((res) => {
      setClientLists(res.data);
    });
  }, [open]);

  const Clientoptions =
    clientLists &&
    clientLists.map((client) => {
      let combine = `${client.firstName} ${client.lastName} (${client.mail})`;
      let combine1 = `${client.firstName} ${client.lastName}`;
      return {
        label: combine,
        value: combine1,
        id: client.id,
        clientMobileNo: client.mobile,
        clientEmail: client.mail,
      };
    });

  useEffect(async () => {
    //Handling the client change
    const handleChange = (e) => {
      handleChangeClient(e, defaultvalue);
    };
    console.log("basic details", sessionStorage.getItem("mobile"));
    if (
      sessionStorage.getItem("mobile") !== null &&
      (await clientLists.length) > 0
    ) {
      handleChange();
      sessionStorage.setItem("mobile", null);
    }
  }, [defaultvalue]);

  const [expandedAccordion, setExpandedAccordion] = useState(0);

  const handleAccordionChange = (index) => {
    setExpandedAccordion(index);
  };

  return (
    <div className={classesMob.root}>
      <span className={classesMob.title}>
        {transferId ? "Update " : "New "}Transfer
      </span>
      <Accordion
        style={{
          borderRadius: "6px",
          marginBottom: "10px",
          marginTop: "-15%",
          boxShadow: "none",
        }}
        expanded={expandedAccordion === 0}
        onChange={() => handleAccordionChange(0)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
          style={{
            background: "#343A40",
            color: "#fff",
            borderRadius: "6px",
            marginTop: "20%",
          }}
        >
          <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
            Basic Details
          </Typography>
        </AccordionSummary>
        <Typography style={{ paddingLeft: "15px", fontWeight: "bold" }}>
          Client details
        </Typography>
        <AccordionDetails
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#EEF1F3",
            borderRadius: "10px",
          }}
        >
          {basicInput.map((x, i) => (
            <Grid container style={{ paddingBottom: "15px" }}>
              <Grid container>
                <Grid item xs={2}>
                  {i + 1}.
                </Grid>
                <Grid
                  item
                  xs={10}
                  style={{ textAlign: "end", fontSize: "14px" }}
                >
                  {basicInput.length !== 1 && i >= 0 && (
                    <DeleteIcon
                      onClick={() => handleRemoveClickBasic(i)}
                      style={{ color: "#f46d25" }}
                    />
                  )}
                  {basicInput.length - 1 === i && (
                    <AddCircleOutlineIcon
                      onClick={handleAddClickBasic}
                      size="small"
                      style={{ color: "#f46d25" }}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                {i === 0 ? (
                  transferId ? (
                    <Grid item xs={12}>
                      <CustomBasic
                        name="clientName"
                        label="Client Name"
                        value={x.clientName}
                        onChange={(e) => handleBasicChange(e, i)}
                        disabled={true}
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={10}>
                        <Select
                          isSearchable
                          name="clientName"
                          placeholder="Client Name"
                          value={Clientoptions.filter(
                            (i) => i.value === x.clientName
                          )}
                          options={Clientoptions}
                          onChange={(e) => handleChangeClient(i, e)}
                          components={{
                            ValueContainer: CustomValueContainer,
                          }}
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                            control: (base, state) => ({
                              ...base,
                              "&:hover": { borderColor: "#f46d25" },
                              borderColor: "#f46d25",
                              boxShadow: "none",
                              width: "100%",
                            }),
                            placeholder: (provided, state) => ({
                              ...provided,
                              position: "relative",
                              top:
                                state.hasValue || state.selectProps.inputValue
                                  ? -10
                                  : "50%",
                              background: "#fff",
                              padding: "0px 5px",
                              transition: "top 0.1s, font-size 0.1s",
                              fontSize: "14px",
                            }),
                            container: (provided) => ({
                              ...provided,
                            }),
                            valueContainer: (provided) => ({
                              ...provided,
                              overflow: "visible",
                            }),
                          }}
                        />
                        <span style={{ color: "#ff0000" }}>
                          {basicInputError[i]["clientName"]}
                        </span>
                      </Grid>

                      <Grid item xs={2}>
                        <AddCircleOutlineIcon
                          style={{
                            fontSize: "20px",
                            color: "#f46d25",
                            cursor: "pointer",
                            paddingTop: "0.5em",
                          }}
                          onClick={() => {
                            setOpen(true);
                          }}
                        />
                      </Grid>
                    </>
                  )
                ) : (
                  <Grid item xs={12}>
                    <CustomBasic
                      name="clientName"
                      label="Client Name"
                      value={x.clientName}
                      onChange={(e) => handleBasicChange(e, i)}
                      tabValue={tabValue}
                    />
                  </Grid>
                )}
                {i === 0 ? (
                  <Grid item xs={12}>
                    <CustomBasic
                      name="clientMobileNo"
                      label="Mobile No"
                      value={x.clientMobileNo}
                      disabled={true}
                      tabValue={tabValue}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <CustomBasic
                      name="clientMobileNo"
                      label="Mobile No"
                      value={x.clientMobileNo}
                      onChange={(e) => handleBasicChange(e, i)}
                      tabValue={tabValue}
                    />
                  </Grid>
                )}

                {i === 0 ? (
                  <Grid item xs={12}>
                    <CustomBasic
                      name="clientEmail"
                      label="Email"
                      value={x.clientEmail}
                      variant="outlined"
                      disabled={true}
                      tabValue={tabValue}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <CustomBasic
                      name="clientEmail"
                      label="Email"
                      value={x.clientEmail}
                      onChange={(e) => handleBasicChange(e, i)}
                      tabValue={tabValue}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <CustomBasic
                    name="clientAltNo"
                    label="Alternate No"
                    value={x.clientAltNo}
                    onChange={(e) => handleBasicChange(e, i)}
                    tabValue={tabValue}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          borderRadius: "6px",
          marginBottom: "10px",
          marginTop: "-15%",
          boxShadow: "none",
        }}
        expanded={expandedAccordion === 1}
        onChange={() => handleAccordionChange(1)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
          style={{
            background: "#343A40",
            color: "#fff",
            borderRadius: "6px",
            marginTop: "20%",
          }}
        >
          <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
            Transfer Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <SelectTravelMode
                style={{ height: "40px", width: "100%" }}
                onChange={handleChangeTravelMode}
                value={transportMode}
              >
                {travelby.map((mode) => (
                  <MenuItem value={mode.value}>{mode.label}</MenuItem>
                ))}
              </SelectTravelMode>
            </FormControl>
          </Grid>
        </AccordionDetails>
        {transportMode === "flight" && (
          <AccordionDetails
            style={{
              paddingBottom: "20px",
              background: "#EEF1F3",
              borderRadius: "10px",
            }}
          >
            {hasAdmin === "Agent Admin" || checkAgent === "Agent" ? (
              <AgentFlightForm
                flightInput={flightInput}
                setFlightInput={setFlightInput}
                flightInputError={flightInputError}
                setF
                lightInputError={setFlightInputError}
                classes={classes}
              />
            ) : (
              <FlightForm
                flightInput={flightInput}
                setFlightInput={setFlightInput}
                flightInputError={flightInputError}
                setFlightInputError={setFlightInputError}
                classes={classes}
              />
            )}
          </AccordionDetails>
        )}

        {transportMode === "train" && (
          <AccordionDetails
            style={{
              paddingBottom: "20px",
              background: "#EEF1F3",
              borderRadius: "10px",
            }}
          >
            {hasAdmin === "Agent Admin" || checkAgent === "Agent" ? (
              <AgentTrainForm
                trainInput={trainInput}
                setTrainInput={setTrainInput}
                trainInputError={trainInputError}
                setTrainInputError={setTrainInputError}
                classes={classes}
              />
            ) : (
              <TrainForm
                trainInput={trainInput}
                setTrainInput={setTrainInput}
                trainInputError={trainInputError}
                setTrainInputError={setTrainInputError}
                classes={classes}
              />
            )}
          </AccordionDetails>
        )}

        {transportMode === "road" && (
          <AccordionDetails
            style={{
              paddingBottom: "20px",
              background: "#EEF1F3",
              borderRadius: "10px",
            }}
          >
            <RoadForm
              cabInput={cabInput}
              setCabInput={setCabInput}
              busInput={busInput}
              setBusInput={setBusInput}
              cabInputError={cabInputError}
              setCabInputError={setCabInputError}
              busInputError={busInputError}
              setBusInputError={setBusInputError}
              classes={classes}
            />
          </AccordionDetails>
        )}
      </Accordion>
      <Accordion
        style={{
          borderRadius: "6px",
          marginBottom: "10px",
          marginTop: "-15%",
          boxShadow: "none",
        }}
        expanded={expandedAccordion === 2}
        onChange={() => handleAccordionChange(2)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
          style={{
            background: "#343A40",
            color: "#fff",
            borderRadius: "6px",
            marginTop: "20%",
          }}
        >
          <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
            Payment Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            paddingBottom: "20px",
            background: "#EEF1F3",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "250px",
              justifyContent: "space-between",
            }}
          >
            <DualTextField
              labelValue="Total Amount"
              disabled
              InputValueFieldProps={{
                style: {
                  backgroundColor: "#ffe2d7",
                  fontWeight: "bold",
                  height: "40px",
                  border: "1px solid #f46d25",
                },
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
              value={totalAmount}
              InputLableFieldProps={{
                style: { border: "1px solid #f46d25", height: "40px" },
              }}
            />
            <DualTextField
              labelValue="Commission"
              disabled
              InputLableFieldProps={{
                style: { border: "1px solid #f46d25", height: "40px" },
              }}
              InputValueFieldProps={{
                style: {
                  backgroundColor: "#b9f6ca",
                  fontWeight: "bold",
                  height: "40px",
                  border: "1px solid #f46d25",
                },
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
              value={commission}
            />
            <DualTextField
              name="paidAmount"
              labelValue="Amount Paid"
              InputLableFieldProps={{
                style: { border: "1px solid #f46d25", height: "40px" },
              }}
              InputValueFieldProps={{
                style: {
                  backgroundColor: "#ffe2d7",
                  fontWeight: "bold",
                  height: "40px",
                },
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
              value={transferPaymentDetails.paidAmount}
              onChange={setTransferPaymentDetails}
            />

            <Select
              placeholder="Payment Mode"
              options={paymentModeOptions}
              onChange={(option) =>
                setTransferPaymentDetails({
                  target: { name: "paymentMode", value: option.label },
                })
              }
              styles={{
                container: (provided) => ({
                  ...provided,
                }),
                control: (base) => ({
                  ...base,
                  border: "1px solid #f46d25",
                  width: "100%",
                }),
              }}
            />

            <DualTextField
              name="paymentRefNo"
              labelValue="Reference Number"
              InputLableFieldProps={{
                style: { border: "1px solid #f46d25", height: "40px" },
              }}
              InputValueFieldProps={{
                style: {
                  backgroundColor: "#ffe2d7",
                  fontWeight: "bold",
                  height: "40px",
                },
              }}
              value={transferPaymentDetails.paymentRefNo}
              onChange={setTransferPaymentDetails}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          borderRadius: "6px",
          marginBottom: "10px",
          marginTop: "-15%",
          boxShadow: "none",
        }}
        expanded={expandedAccordion === 3}
        onChange={() => handleAccordionChange(3)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
          style={{
            background: "#343A40",
            color: "#fff",
            borderRadius: "6px",
            marginTop: "20%",
          }}
        >
          <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
            T&C Policy
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            paddingBottom: "20px",
            background: "#EEF1F3",
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          <TextareaAutosize
            name="teamCondition"
            onChange={setPkgTrmCndData}
            defaultValue={pkgTrmCndData.teamCondition}
            style={{
              maxWidth: "99.5%",
              minHeight: "4em",
              minWidth: "98.5%",
              fontSize: "15px",
              borderColor: "#f46d25",
              borderRadius: "10px",
            }}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          borderRadius: "6px",
          marginBottom: "10px",
          marginTop: "-15%",
          boxShadow: "none",
        }}
        expanded={expandedAccordion === 4}
        onChange={() => handleAccordionChange(4)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
          style={{
            background: "#343A40",
            color: "#fff",
            borderRadius: "6px",
            marginTop: "20%",
          }}
        >
          <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
            Cancellation Policy
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            paddingBottom: "20px",
            background: "#EEF1F3",
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          <TextareaAutosize
            name="cancellationPolicy"
            onChange={setPkgTrmCndData}
            defaultValue={pkgTrmCndData.cancellationPolicy}
            style={{
              maxWidth: "100%",
              minHeight: "4em",
              minWidth: "100%",
              fontSize: "15px",
              borderColor: "#f46d25",
              borderRadius: "10px",
            }}
          />
        </AccordionDetails>
      </Accordion>
      <div style={{ textAlign: "end" }}>
        {isClicked ? (
          <button style={twnButtonStyles.disabledBtn} disabled>
            <i className="fa fa-refresh fa-spin" />
            {transferId ? "Update Now" : "Book Now"}
          </button>
        ) : (
          <button onClick={validatePreview} style={twnButtonStyles.orangeBtn}>
            {transferId ? "Update Now" : "Book Now"}
          </button>
        )}
      </div>
    </div>
  );
};

export default TransferFormMobileUI;
