import React, { useState, useEffect } from "react"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Grid,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { Button } from "@material-ui/core"
import XLSX from "xlsx"
import Select from "react-select"
import _ from "lodash"
import { format, parse } from "date-fns"
import { formatter } from "../../utils/formatNumber"

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  background: "#F46D25",
}))
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  background: "#F46D25",
  color: "#fff",
  fontSize: "12px",
  padding: "5px",
  lineHeight: "1rem",
  minWidth: "100px",
}))

const StyledTableCellInside = styled(TableCell)(({ theme }) => ({
  padding: "5px",
  fontSize: "12px",
  lineHeight: "15px",
}))

const AgentWiseOpition = [
  { value: "bookingId", label: "Booking Id" },
  { value: "bookingSource", label: "Booking Source" },
  { value: "hotelName", label: "Hotel Name" },
  { value: "profit", label: "Profit" },
  { value: "bookingStatus", label: "Booking Status" },
  { value: "agentName", label: "Agent Name" },
  { value: "netBookingAmount", label: "Net Amount" },
  { value: "amountRefunded", label: "Amount Refunded" },
  { value: "amountRetained", label: "Amount Retained" },
  { value: "revisedCommission", label: "Revised Commission" },
  { value: "bookingDate", label: "Booking Date" },
  { value: "customerName", label: "Customer Name" },
  { value: "checkInDate", label: "Check-In" },
  { value: "checkOutDate", label: "Check-Out" },
  { value: "grossBookingAmount", label: "Gross Amount" },
  { value: "amountRecieved", label: "Amount Recieved" },
]

const columns = [
  { value: "bookingId", label: "Booking Id" },
  { value: "bookingSource", label: "Booking Source" },
  { value: "hotelName", label: "Hotel Name" },
  { value: "profit", label: "Profit" },
  { value: "bookingStatus", label: "Booking Status" },
  { value: "agentName", label: "Agent Name" },
  { value: "netBookingAmount", label: "Net Amount" },
  { value: "amountRefunded", label: "Amount Refunded" },
  { value: "amountRetained", label: "Amount Retained" },
  { value: "revisedCommission", label: "Revised Commission" },
  { value: "bookingDate", label: "Booking Date" },
  { value: "customerName", label: "Customer Name" },
  { value: "checkInDate", label: "Check-In" },
  { value: "checkOutDate", label: "Check-Out" },
  { value: "grossBookingAmount", label: "Gross Amount" },
  { value: "amountRecieved", label: "Amount Recieved" },
]

export default function AgentWiseHotelReport(props) {
  const [finalReport, setFinalReport] = useState(props.reportData)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState(null)

  useEffect(() => {
    setFinalReport(props.reportData)
  }, [props])

  const HandleSort = (sort) => {
    setSort(sort)
    let sorted = _.orderBy(finalReport, [sort.value], ["asec"])
    setFinalReport(sorted)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const downloadExcel = () => {
    const Data = finalReport.map((row) => {
      row.amountRecievedByTowno = Number(row.amountRecievedByTowno)
      row.grossBookingAmount = Number(row.grossBookingAmount)
      row.netBookingAmount = Number(row.netBookingAmount)
      row.townoProfit = Number(row.townoProfit)
      return row
    })
    console.log(Data)
    const newData = Data.map((row) => {
      delete row.id
      return row
    })
    const workSheet = XLSX.utils.json_to_sheet(newData)
    const workBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workBook, workSheet, "Agent Wise Sales Report")
    // const buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
    XLSX.writeFile(workBook, "AgentWiseSalesReport.xlsx")
  }

  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: "10px" }}>
        <Grid item md={8}>
          <b>Agent Wise Sales Report</b>
        </Grid>
        <Grid item md={2}>
          <div style={{ position: "relative" }}>
            <span
              style={{
                position: "absolute",
                top: "-9px",
                zIndex: "1",
                left: "12px",
                color: "#fff",
                background: "#F46D25",
                borderRadius: "4px",
                padding: "1px 4px",
                fontSize: "12px",
              }}
            >
              Sort By
            </span>
            <Select
              options={AgentWiseOpition}
              placeholder=""
              value={sort}
              onChange={HandleSort}
              styles={{
                container: (provided) => ({
                  ...provided,
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9,
                }),
                control: (base, state) => ({
                  ...base,
                  "&:hover": { borderColor: "#f46d25" },
                  borderColor: "#f46d25",
                  boxShadow: "none",
                }),
              }}
            />
          </div>
        </Grid>
        <Grid item md={2} style={{ textAlign: "right" }}>
          <Button onClick={downloadExcel}>Download As Excel</Button>
        </Grid>
      </Grid>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <StyledTableRow>
                {columns.map((column) => (
                  <StyledTableCell key={column.id} align={column.align}>
                    {column.label}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {finalReport
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow key={row.id}>
                      <StyledTableCellInside>
                        {row.bookingId}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.bookingSource}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.hotelName}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.profit}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.bookingStatus}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.agentName}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.netBookingAmount
                          ? formatter.format(row.netBookingAmount)
                          : null}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.amountRefunded
                          ? formatter.format(row.amountRefunded)
                          : null}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.amountRetained
                          ? formatter.format(row.amountRetained)
                          : null}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.revisedCommission
                          ? formatter.format(row.revisedCommission)
                          : null}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.bookingDate
                          ? format(
                              parse(row.bookingDate, "yyyy-MM-dd", new Date()),
                              "dd MMM yy"
                            )
                          : null}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.customerName}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.checkInDate}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.checkOutDate}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.grossBookingAmount
                          ? formatter.format(row.grossBookingAmount)
                          : null}
                      </StyledTableCellInside>
                      <StyledTableCellInside>
                        {row.amountRecieved
                          ? formatter.format(row.amountRecieved)
                          : null}
                      </StyledTableCellInside>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={finalReport.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
