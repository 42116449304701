import { useEffect } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export const ErrorFallback = () => {
  return (
    <div>
      <h1>An unexpected error occured.</h1>

      <p>
        <em>
          Please try again after some time.{" "}
          <span
            style={{
              textDecoration: "none",
              color: "#000000",
              cursor: "pointer",
            }}
          >
            Go Back
          </span>
        </em>
      </p>
    </div>
  )
}
