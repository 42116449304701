import { useRef, useState } from "react"
import ContactInfo from "../../components/ContactInfo/ContactInfo.component"
import QueryForm from "../../components/QueryForm/QueryForm.component"
import globalStyles from "../../globalStyles.module.css"
import VideoPlayerModal from "../../components/VideoPlayerModal/VideoPlayerModal.component"
import { useSelector } from "react-redux"

const DestinationSubLayout = ({ children }) => {
  const sectionContainerRef = useRef(null)
  const [isIntersecting, setIsIntersecting] = useState(false)

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.isIntersecting) {
  //         setIsIntersecting(true)
  //       }
  //     },
  //     { threshold: 0.5 }
  //   )

  //   const element = sectionContainerRef.current.querySelector("[data-type]")

  //   observer.observe(element)

  //   if (isIntersecting) {
  //     console.log({ element })
  //     console.log("hey")
  //     element.style.setProperty("--show", "flex")
  //   }

  //   return () => {
  //     observer.disconnect()
  //   }
  // }, [])

  return (
    <section
      className={globalStyles.sectionContainer}
      ref={sectionContainerRef}
    >
      {children}
      <aside className={globalStyles.aside}>
        <ContactInfo />
        <QueryForm />
      </aside>
    </section>
  )
}

export default DestinationSubLayout
