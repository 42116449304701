import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from "@material-ui/core";
import { Field } from "formik";

const useStyles = makeStyles((theme) => ({
  menuStyling: {
    fontSize: "14px",
  },
  selectLabelProps: {
    backgroundColor: "white",
    color: "#000000",
    padding: "0px 5px 5px 5px",
    borderRadius: "5px",
  },
  menuItemStyles: {
    height: "300px",
    fontSize: "14px",
  },
  selectStyle: {
    height: "35px",
    width: "100%",
    "& .MuiSelect-icon": {
      color: "#f46d25",
    },
  },
  checkBoxLabelStyles: {
    fontSize: "14px",
  },
  chipStyling: {
    margin: theme.spacing(0.5),
    height: "15px",
    fontSize: "13px",
  },
}));

const FormikSingleValueSelectField = ({ amenity, values }) => {
  const classes = useStyles();
  return (
    <Field as={FormControl} variant="outlined" style={{ width: "100%" }}>
      <InputLabel className={classes.selectLabelProps} shrink>
        {amenity.selectLabel}
      </InputLabel>
      <Field
        as={Select}
        name={amenity.selectFieldName}
        value={values[amenity.selectFieldName]}
        className={classes.selectStyle}
        MenuProps={{
          className: classes.menuItemStyles,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {amenity?.dropdDownOptions.map((option) => (
          <MenuItem className={classes.menuStyling} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Field>
    </Field>
  );
};

export default FormikSingleValueSelectField;
