import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  makeStyles,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Grid } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import Api from "../../../Service/Api";
import FormikInlineCheckboxAndSwitchOnly from "../../../components/amenityComponents/inlineCheckBoxAndSwitchOnly.component";
import InlineFormikFieldWithCheckSelectAndSwitch from "../../../components/amenityComponents/inlineCheckBoxWithSelectAndSwitch.component";
import ScreenLoader from "../../../components/loadingScreen/screenLoader.component";
import {
  gymInitial,
  salonInitial,
  spaInitial,
  steamInitial,
} from "../../../redux/actions/amenitiesDropDownAction";

const useStyles = makeStyles(() => ({
  checkboxStyling: {
    "& .MuiSvgIcon-root": {
      color: "#f46d25",
      height: "18px",
      width: "18px",
    },
  },
  menuStyling: {
    fontSize: "14px",
  },
  selectLabelProps: {
    backgroundColor: "white",
    color: "#000000",
    padding: "0px 5px 5px 5px",
    borderRadius: "5px",
  },
  menuItemStyles: {
    height: "300px",
    fontSize: "14px",
  },
  selectStyle: {
    height: "35px",
    width: "100%",
    "& .MuiSelect-icon": {
      color: "#f46d25",
    },
  },
  checkBoxLabelStyles: {
    fontSize: "14px",
  },
}));

const initialValues = {
  yoga: false,
  yogaStarred: false,
  gym: false,
  gymType: [],
  gymStarred: false,
  firstaid: false,
  firstaidStarred: false,
  reflexology: false,
  reflexologyStarred: false,
  meditationRoom: false,
  meditationRoomStarred: false,
  bridalmakup: false,
  bridalmakupStarred: false,
  bridalgromming: false,
  bridalgrommingStarred: false,
  salon: false,
  salonType: [],
  salonStarred: false,
  spa: false,
  spaStarred: false,
  spaType: [],
  steamSauna: false,
  steamSaunaType: "",
  steamSaunaType1: "",
  steamSaunaStarred: false,
  medicalCenter: false,
  medicalCenterStarred: false,
  doctorOnCall: false,
  doctorOnCallStarred: false,
  medicalAssistance: false,
  medicalAssistanceStarred: false,
};

const healthAndBeautySwitchOnly = [
  {
    name: "reflexology",
    label: "Reflexology",
    switchName: "reflexologyStarred",
  },
  {
    name: "yoga",
    label: "Yoga",
    switchName: "yogaStarred",
  },
  {
    name: "firstaid",
    label: "First-aid Services",
    switchName: "firstaidStarred",
  },
  {
    name: "meditationRoom",
    label: "Meditation Room",
    switchName: "meditationRoomStarred",
  },
  {
    name: "medicalCenter",
    label: "Medical Center",
    switchName: "medicalCenterStarred",
  },
  {
    name: "doctorOnCall",
    label: "Doctor On Call",
    switchName: "doctorOnCallStarred",
  },

  {
    name: "medicalAssistance",
    label: "Medical Assistance",
    switchName: "medicalAssistanceStarred",
  },
  {
    name: "bridalmakup",
    label: "Bridal Makeup",
    switchName: "bridalmakupStarred",
  },
  {
    name: "bridalgromming",
    label: "Bridal Grooming",
    switchName: "bridalgrommingStarred",
  },
];

const HealthAndBeautyAmenities = ({
  classes,
  handleChangePanel,
  expanded,
  setExpanded,
}) => {
  const selectStyles = useStyles();
  const { propertyId } = useParams();
  const [healthAndBeauty, setHealthAndBeauty] = useState(() => {});
  const [healthAndBeautySwitch, setHealthAndBeautySwitch] = useState(() => {});
  const steamLists = useSelector((state) => state.steamList.steamLists);
  const salonLists = useSelector((state) => state.salonList.salonLists);
  const spaLists = useSelector((state) => state.spaList.spaLists);
  const gymLists = useSelector((state) => state.gymList.gymLists);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(() => false);
  const data = { propertyId: propertyId };
  var uniqueid = localStorage.getItem("unique_id");
  var createdBy = localStorage.getItem("auth");
  const create = { uniqueId: uniqueid, createdBy: createdBy };

  useEffect(() => {
    if (propertyId && expanded == "panel5") {
      dispatch(gymInitial());
      dispatch(steamInitial());
      dispatch(salonInitial());
      dispatch(spaInitial());
    }
  }, [expanded]);

  const fetchHealthAndBeauty = async () => {
    setLoading(true);
    try {
      const normalData = await Api.get(
        `AmenitiesHealthAndBeautyvalue/${propertyId}`
      );
      const switchData = await Api.get(
        `AmenitiesHealthAndBeautyStarredvalue/${propertyId}`
      );
      console.log(normalData.data);
      console.log(switchData.data);
      setHealthAndBeauty({ ...normalData.data, ...switchData.data });
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (propertyId && expanded == "panel5") {
      fetchHealthAndBeauty();
    }
  }, [expanded]);

  const healthAndBeautyWithSelect = [
    {
      name: "gym",
      label: "Gym / Fitness Center",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "gymType",
      switchName: "gymStarred",
      dropdDownOptions: gymLists,
    },
    {
      name: "salon",
      label: "Salon",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "salonType",
      switchName: "salonStarred",
      dropdDownOptions: salonLists,
    },
    {
      name: "spa",
      label: "Spa",
      select: true,
      selectType: "multipleValue",
      selectLabel: "Type",
      selectFieldName: "spaType",
      switchName: "spaStarred",
      dropdDownOptions: spaLists,
    },
  ];

  const healthAndBeautyWithMultipleSelectFields = [
    {
      name: "steamSauna",
      label: "SteamSauna",
      select: true,
      selectLabel1: "Type",
      selectLabel2: "For",
      selectFieldName1: "steamSaunaType",
      selectFieldName2: "steamSaunaType1",
      dropdDownOptions1: [
        { value: "free", label: "Free" },
        { value: "paid", label: "Paid" },
      ],
      dropdDownOptions2: steamLists.map((steam) => ({
        value: steam,
        label: steam,
      })),

      switchName: "steamSaunaStarred",
    },
  ];

  const handleChangeAccordion = () => {
    setExpanded("panel6");
  };

  function onSubmit(fields, { setStatus }) {
    const id = healthAndBeauty.id;

    if (fields.yoga == false) {
      fields.yogaStarred = false;
    }
    if (fields.gym == false) {
      fields.gymType = [];
      fields.gymStarred = false;
    }
    if (fields.firstaid == false) {
      fields.firstaidStarred = false;
    }
    if (fields.reflexology == false) {
      fields.reflexologyStarred = false;
    }
    if (fields.meditationRoom == false) {
      fields.meditationRoomStarred = false;
    }

    if (fields.bridalmakup == false) {
      fields.bridalmakupStarred = false;
    }
    if (fields.bridalgromming == false) {
      fields.bridalgrommingStarred = false;
    }
    if (fields.salon == false) {
      fields.salonType = [];
      fields.salonStarred = false;
    }
    if (fields.spa == false) {
      fields.spaStarred = false;
      fields.spaType = [];
    }
    if (fields.steamSauna == false) {
      fields.steamSaunaType = "";
      fields.steamSaunaType1 = "";
      fields.steamSaunaStarred = false;
    }
    if (fields.medicalCenter == false) {
      fields.medicalCenterStarred = false;
    }
    if (fields.doctorOnCall == false) {
      fields.doctorOnCallStarred = false;
    }
    if (fields.medicalAssistance == false) {
      fields.medicalAssistanceStarred = false;
    }

    setStatus();
    if (id) {
      updateHealth(fields);
    } else {
      createHealth(fields);
    }
  }

  function createStarred(fields) {
    const newData = { ...data, ...fields, ...create };
    Api.post("AmenitiesHealthAndBeautyStarred", newData);
  }
  function updateStarred(fields) {
    fields.updateBy = createdBy;
    Api.put("AmenitiesHealthAndBeautyStarredupdate", fields);
  }

  function createHealth(fields) {
    setLoading(true);
    fields.propertyId = propertyId;
    const newData = { ...data, ...fields, ...create };
    Api.post("AmenitiesHealthAndBeauty", newData).then((res) => {
      if (res) {
        createStarred(fields);
        toast.success("Successfully Created");
        handleChangeAccordion();
        setLoading(false);
      }
    });
  }

  function updateHealth(fields) {
    setLoading(true);
    fields.updateBy = createdBy;
    Api.put("AmenitiesHealthAndBeautyupdate", fields).then((res) => {
      if (res) {
        updateStarred(fields);
        toast.success("Successfully Updated");
        handleChangeAccordion();
        setLoading(false);
      }
    });
  }

  return (
    <Formik
      initialValues={healthAndBeauty || initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue }) =>
        loading ? (
          <ScreenLoader open={loading} />
        ) : (
          <Form autoComplete="off">
            <Accordion
              square
              expanded={expanded === "panel5"}
              onChange={handleChangePanel("panel5")}
              className={classes.accordionStyles}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowDropDownIcon
                    style={{
                      color: "#f46d25",
                      background: "white",
                      borderRadius: "5px",
                    }}
                  />
                }
                className={classes.accordionSummaryStyles}
              >
                Health And Beauty
              </AccordionSummary>
              <AccordionDetails className={classes.outerSectionStyles}>
                <Grid container spacing={3}>
                  {healthAndBeautyWithSelect.map((amenity) => (
                    <InlineFormikFieldWithCheckSelectAndSwitch
                      amenity={amenity}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  ))}

                  {/**Fields with multiple Select Field */}
                  {healthAndBeautyWithMultipleSelectFields.map((amenity) => (
                    <Grid item container xs={9} alignItems="center" spacing={3}>
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={
                            <Field
                              value={values[amenity.name]}
                              as={Checkbox}
                              name={amenity.name}
                              className={selectStyles.checkboxStyling}
                            />
                          }
                          label={
                            <span className={selectStyles.checkBoxLabelStyles}>
                              {amenity.label}
                            </span>
                          }
                        />
                      </Grid>
                      {values[amenity.name] && (
                        <>
                          <Grid item xs={3}>
                            <FormControl
                              variant="outlined"
                              style={{ width: "100%" }}
                            >
                              <InputLabel
                                className={selectStyles.selectLabelProps}
                                shrink
                              >
                                {amenity.selectLabel1}
                              </InputLabel>
                              <Field
                                as={Select}
                                name={amenity.selectFieldName1}
                                value={values[amenity.selectFieldName1]}
                                className={selectStyles.selectStyle}
                                MenuProps={{
                                  className: selectStyles.menuItemStyles,
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                              >
                                {amenity.dropdDownOptions1.map((option) => (
                                  <MenuItem
                                    className={selectStyles.menuStyling}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Field>
                            </FormControl>
                          </Grid>
                          {values[amenity.selectFieldName1] === "paid" && (
                            <Grid item xs={3}>
                              <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                              >
                                <InputLabel
                                  className={selectStyles.selectLabelProps}
                                  shrink
                                >
                                  {amenity.selectLabel2}
                                </InputLabel>
                                <Field
                                  as={Select}
                                  name={amenity.selectFieldName2}
                                  value={values[amenity.selectFieldName2]}
                                  className={selectStyles.selectStyle}
                                  MenuProps={{
                                    className: selectStyles.menuItemStyles,
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                  }}
                                >
                                  {amenity.dropdDownOptions2.map((option) => (
                                    <MenuItem
                                      className={selectStyles.menuStyling}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Field>
                              </FormControl>
                            </Grid>
                          )}
                          <Grid item xs={3}>
                            <Field
                              name={amenity.switchName}
                              as={Switch}
                              color="primary"
                              checked={values[amenity.switchName]}
                              size="small"
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  ))}

                  {/**Food and Drinks amenities switch only */}
                  {healthAndBeautySwitchOnly.map((amenity) => (
                    <FormikInlineCheckboxAndSwitchOnly
                      amenity={amenity}
                      values={values}
                    />
                  ))}
                </Grid>
              </AccordionDetails>
              <center>
                <Button
                  style={{ height: "30px", margin: "10px 0px" }}
                  type="submit"
                >
                  Save and Next
                </Button>
              </center>
            </Accordion>
          </Form>
        )
      }
    </Formik>
  );
};

export default HealthAndBeautyAmenities;
