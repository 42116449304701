import React, { useState, useReducer, useEffect } from "react"
import { Stack, Typography, Grid, Card, Button, Paper } from "@mui/material"
import { format, parse } from "date-fns"
import { twnButtonStyles } from "../../utils/townoStyle"
import Api from "../../Service/Api"
import { Link, useParams } from "react-router-dom"
import { formatter } from "../../utils/formatNumber"

const cardLabel = { padding: "0.5em 0.7em", color: "#111", fontWeight: "500" }
const cardValue = { padding: "0.5em 1em", color: "#111" }
const hrStyle = { backgroundColor: "#111", margin: "1em 0", height: "0.1em" }

const bookingSourceOptions = {
  hotelWebsiteChat: "Hotel Website Chat",
  justDial: "JustDial",
  agentB2B: "B2B Agent",
  fit: "FIT",
  socialMedia: "Social Media",
  incomingCall: "Incoming Call",
  holidayIq: "Holiday Iq",
  tripoto: "Tripoto",
}

const mealPlanOptions = {
  ep: "EP",
  cp: "CP",
  map: "MAP",
  ap: "AP",
}

export default function AgentBookingView({ totalAmountPaid }) {
  var uniqueid = localStorage.getItem("unique_id")
  let { bookingId } = useParams()
  var [data, setData] = useState({
    id: "",
    bookingId: "",
    clientName: "",
    clientMobile: "",
    clientEmail: "",
    destinationCity: "",
    bookingSource: "",
    hotelName: "",
    hotelName: "",
    hotelName: "",
    starRating: "",
    hotelAddress: "",
    hotelEmail: "",
    checkIn: "",
    checkOut: "",
    totalRoomNetAmount: "",
    totalRoomSellingAmount: "",
    totalInclusionAmount: "",
    totalVendorAmount: "",
    totalBookingAmount: "",
    paidAmount: "",
    profit: "",
    pendingAmount: "",
    bookingStatus: "",
    balancePayableHotel: "",
    vaccinated: "",
    git: "",
    partialPayment: "",
    paymentMode: "",
    paymentRefNo: "",
    paymentType: "",
    agentBookingLeads: [],
    agentBookingRoomCategories: [],
    agentBookingInclusions: [],
    agentBookingPayments: [],
    agentBookingTeamCondition: {},
  })
  console.log("Preview|formData|data: ", data)

  let validLeadPax =
    data.agentBookingLeads != null
      ? data.agentBookingLeads.filter((e) => {
          return e.name != ""
        })
      : 0
  // let validTransferInput = data.transferDetails.filter((e) => { return e.transferFrom != "" });
  // let validActvityInput = data.activityDetails.filter((e) => { return e.city != "" })

  useEffect(() => {
    Api.get(`/agentBookingById/${uniqueid}/${bookingId}`).then((res) => {
      console.log("all data", res.data)
      setData(res.data)
    })
  }, [])

  return (
    <div
      style={{
        padding: "5% 1%",
        fontSize: "14px",
        backgroundColor: "#F7F7F7",
      }}
    >
      {/* <Typography style={{ color: '#f46d25', fontWeight: 'bold',fontSize: '32px', fontFamily: 'Segoe UI' }}>{data.type} Details</Typography> */}
      {data.amountRefunded > 0 ? (
        <Typography
          variant="h5"
          component="h5"
          style={{ fonstSize: "18px", fontWeight: "bold", color: "#111" }}
        >
          #{data.bookingId} has been Cancelled
        </Typography>
      ) : (
        <Typography variant="h5" component="h5" style={twnButtonStyles.xlFonts}>
          #{data.bookingId}
        </Typography>
      )}
      <Card style={{ marginTop: "9px" }}>
        <div
          style={{
            backgroundColor: "#f46d25",
            color: "#fff",
            padding: "0.5em 1em",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Basic Details
        </div>
        <Stack style={{ padding: "0.5em 0" }}>
          <Stack
            direction="row"
            spacing={1}
            style={{ lineHeight: "0.3", padding: "0 0.5em" }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Name
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.clientName}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Email
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.clientEmail}
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            style={{ lineHeight: "0.3", padding: "0 0.5em" }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Mobile No.
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.clientMobile}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Destination
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.destinationCity}
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            style={{ lineHeight: "0.3", padding: "0 0.5em" }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Hotel
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.hotelName}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Hotel Email
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.hotelEmail}
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            style={{ lineHeight: "0.3", padding: "0 0.5em" }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Check-In
              </Grid>
              <Grid item xs={8} style={cardValue}>
                :{" "}
                {data.checkIn
                  ? format(new Date(data.checkIn), "dd MMM yy")
                  : null}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Check-Out
              </Grid>
              <Grid item xs={8} style={cardValue}>
                :{" "}
                {data.checkOut
                  ? format(new Date(data.checkOut), "dd MMM yy")
                  : null}
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            style={{ lineHeight: "0.3", padding: "0 0.5em" }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Booking Source
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {bookingSourceOptions[data.bookingSource]}
              </Grid>
            </Grid>
            <Grid container></Grid>
          </Stack>
        </Stack>
      </Card>

      {/* lead details  */}
      {data.validLeadPax && (
        <>
          <Card style={{ marginTop: "9px" }}>
            <div
              style={{
                backgroundColor: "#f46d25",
                color: "#fff",
                fontWeight: "500",
                padding: "0.5em 1em",
                fontSize: "18px",
              }}
            >
              Lead Pax Details
            </div>
            {data.validLeadPax.map((each, index) => {
              return (
                <>
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{ lineHeight: "0.3", padding: "0.5em " }}
                  >
                    <Grid container>
                      <Grid item xs={5} style={cardLabel}>
                        Name
                      </Grid>
                      <Grid item xs={7} style={cardValue}>
                        : {each.name}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={5} style={cardLabel}>
                        Mobile No
                      </Grid>
                      <Grid item xs={7} style={cardValue}>
                        : {each.mobile}
                      </Grid>
                    </Grid>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    style={{ lineHeight: "0.3", padding: "0.5em " }}
                  >
                    <Grid container>
                      <Grid item xs={5} style={cardLabel}>
                        Alt No
                      </Grid>
                      <Grid item xs={7} style={cardValue}>
                        : {each.altMobile}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={3} style={cardLabel}>
                        Email
                      </Grid>
                      <Grid item xs={9} style={cardValue}>
                        : {each.email}
                      </Grid>
                    </Grid>
                  </Stack>
                </>
              )
            })}
          </Card>
        </>
      )}

      {/* room details  */}
      {data.agentBookingRoomCategories && (
        <>
          <Card style={{ marginTop: "9px" }}>
            {" "}
            <div
              style={{
                backgroundColor: "#f46d25",
                color: "#fff",
                fontWeight: "500",
                padding: "0.5em 1em",
                fontSize: "18px",
              }}
            >
              Room Details
            </div>
            {data.agentBookingRoomCategories.map((each, index) => {
              return (
                <>
                  <Card>
                    <Stack style={{ padding: "0.5em 0" }}>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ lineHeight: "0.3", padding: "0 0.5em" }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Room Type
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.roomType}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Adults
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.adult}
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ lineHeight: "0.3", padding: "0 0.5em" }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Children
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.child}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Rooms
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {each.rooms}
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ lineHeight: "0.3", padding: "0 0.5em" }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Meal Plan
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {mealPlanOptions[each.mealplan]}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Selling Price
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {formatter.format(each.sellingPrice)}
                          </Grid>
                        </Grid>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ lineHeight: "0.3", padding: "0 0.5em" }}
                      >
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Net Price
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {formatter.format(each.netPrice)}
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={4} style={cardLabel}>
                            Total Price
                          </Grid>
                          <Grid item xs={8} style={cardValue}>
                            : {formatter.format(each.totalNetPrice)}
                          </Grid>
                        </Grid>
                      </Stack>
                    </Stack>
                  </Card>
                </>
              )
            })}
          </Card>
        </>
      )}

      {/* inclusion details  */}
      {data.agentBookingInclusions && (
        <>
          <Card style={{ marginTop: "9px" }}>
            {" "}
            <div
              style={{
                backgroundColor: "#f46d25",
                color: "#fff",
                fontWeight: "500",
                padding: "0.5em 1em",
                fontSize: "18px",
              }}
            >
              Inclusion Details
            </div>
            {data.agentBookingInclusions.map((each, index) => {
              return (
                <>
                  <Card key={index}>
                    <Stack
                      direction="row"
                      spacing={1}
                      style={{ lineHeight: "0.3", padding: "0.5em " }}
                    >
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Name
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {each.inclusion}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Selling Price
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {formatter.format(each.sellingPrice)}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={4} style={cardLabel}>
                          Net to Vendor
                        </Grid>
                        <Grid item xs={8} style={cardValue}>
                          : {formatter.format(each.vendorPrice)}
                        </Grid>
                      </Grid>
                    </Stack>
                  </Card>
                </>
              )
            })}
          </Card>
        </>
      )}

      <Card style={{ marginTop: "9px" }}>
        {" "}
        <div
          style={{
            backgroundColor: "#f46d25",
            color: "#fff",
            padding: "0.5em 1em",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Payment Details
        </div>
        <Stack style={{ padding: "0.5em 0" }}>
          <Stack
            direction="row"
            spacing={1}
            style={{ lineHeight: "0.3", padding: "0 0.5em " }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Room Rent
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {formatter.format(data.totalRoomSellingAmount)}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Inclusion Amount
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {formatter.format(data.totalInclusionAmount)}
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            style={{ lineHeight: "0.3", padding: "0 0.5em " }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Booking Amount
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {formatter.format(data.totalBookingAmount)}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Net Payout
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {formatter.format(data.totalRoomNetAmount)}
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            style={{ lineHeight: "0.3", padding: "0 0.5em " }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Commission
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {formatter.format(data.profit)}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Amount Received
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {formatter.format(data.paidAmount)}
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            style={{ lineHeight: "0.3", padding: "0 0.5em " }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Payment Mode
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {data.paymentMode}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                BPAH
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {formatter.format(data.balancePayableHotel)}
              </Grid>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            style={{ lineHeight: "0.3", padding: "0 0.5em " }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Balance Payable
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {formatter.format(data.partialPayment)}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={5} style={cardLabel}>
                Reference No.
              </Grid>
              <Grid item xs={7} style={cardValue}>
                : {data.paymentRefNo}
              </Grid>
            </Grid>
          </Stack>
        </Stack>
        <Grid
          container
          spacing={0}
          style={{ border: "2px solid #aaa", padding: "1% 0 1% 2.5%" }}
        >
          <Grid item xs={3}>
            <span
              style={{
                color: "#f46d25",
                fontSize: "16px",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              Date
            </span>
          </Grid>
          <Grid item xs={3}>
            <span
              style={{
                color: "#f46d25",
                fontSize: "16px",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              Amount Paid
            </span>
          </Grid>
          <Grid item xs={3}>
            <span
              style={{
                color: "#f46d25",
                fontSize: "16px",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              Payment Mode
            </span>
          </Grid>
          <Grid item xs={3}>
            <span
              style={{
                color: "#f46d25",
                fontSize: "16px",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              Reference Number
            </span>
          </Grid>
          <Grid item xs={12}></Grid>
          {data.agentBookingPayments &&
            data.agentBookingPayments.map((each, index) => {
              return (
                <Grid
                  container
                  style={{
                    marginTop: "5px",
                    color: "#111",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  <Grid item xs={3}>
                    {each.createdAt
                      ? format(
                          parse(
                            each.createdAt,
                            "yyyy-MM-dd HH:mm:ss",
                            new Date()
                          ),
                          "dd MMM yy, hh:mm a"
                        )
                      : null}
                  </Grid>
                  <Grid item xs={3}>
                    {formatter.format(each.paidAmount)}
                  </Grid>
                  <Grid item xs={3}>
                    {each.paymentMode}
                  </Grid>
                  <Grid item xs={3}>
                    {each.paymentRefNo}
                  </Grid>
                </Grid>
              )
            })}
        </Grid>
      </Card>

      {/* new payment */}
      {data.amountRefunded > 0 ? (
        <Card style={{ marginTop: "9px" }}>
          {" "}
          <div
            style={{
              backgroundColor: "#f46d25",
              color: "#fff",
              padding: "0.5em 1em",
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            Updated Payment Details
          </div>
          <Stack
            direction="row"
            spacing={2}
            style={{ lineHeight: "1", padding: "0.5em " }}
          >
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Total Amount Recieved
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {formatter.format(data.paidAmount)}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Total Amount Refunded
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {formatter.format(data.amountRefunded)}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} style={cardLabel}>
                Total Amount After Refund
              </Grid>
              <Grid item xs={8} style={cardValue}>
                : {formatter.format(data.paidAmount - data.amountRefunded)}
              </Grid>
            </Grid>
          </Stack>
        </Card>
      ) : null}
      {/* new payment */}
      <Card style={{ marginTop: "9px" }}>
        {" "}
        <div
          style={{
            backgroundColor: "#f46d25",
            color: "#fff",
            padding: "0.5em 1em",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Terms And Conditions
        </div>
        <Stack direction="row" spacing={1} style={{ padding: "0.5em 1em" }}>
          {data.agentBookingTeamCondition.teamCondition}
        </Stack>
      </Card>

      <Card style={{ marginTop: "9px" }}>
        {" "}
        <div
          style={{
            backgroundColor: "#f46d25",
            color: "#fff",
            padding: "0.5em 1em",
            fontSize: "18px",
            fontWeight: "500",
          }}
        >
          Cancellation Policy
        </div>
        <Stack direction="row" spacing={1} style={{ padding: "0.5em 1em" }}>
          {data.agentBookingTeamCondition.cancellationPolicy}
        </Stack>
      </Card>

      {data.amountRefunded > 0 ? (
        <Card style={{ marginTop: "9px", boxShadow: "none" }}>
          <Typography
            style={{
              ...twnButtonStyles.headerStyle,
              padding: "10px 0 0 5px",
              height: "32px",
            }}
          >
            Refund Details
          </Typography>
          <Stack
            direction="row"
            spacing={1.5}
            style={{ lineHeight: "0.3", padding: "0.5em" }}
          >
            <Grid container>
              <Grid item xs={5} style={cardLabel}>
                Amount Refunded
              </Grid>
              <Grid item xs={7} style={cardValue}>
                : {formatter.format(data.amountRefunded)}
              </Grid>
            </Grid>
            <br />
            <Grid container>
              <Grid item xs={5} style={cardLabel}>
                Amount Retained
              </Grid>
              <Grid item xs={7} style={cardValue}>
                : {formatter.format(data.amountRetained)}
              </Grid>
            </Grid>
            <br />
            <Grid container>
              <Grid item xs={5} style={cardLabel}>
                Revised Commission
              </Grid>
              <Grid item xs={7} style={cardValue}>
                : {formatter.format(data.revisedCommission)}
              </Grid>
            </Grid>
          </Stack>
        </Card>
      ) : null}
      <br />
      <Stack justifyContent="center" alignItems="center">
        <Link
          to={{ pathname: "/konnect/agentBookings" }}
          style={twnButtonStyles.linkBlackBtn}
        >
          Back
        </Link>
      </Stack>
    </div>
  )
}
